import { Grid } from "../../types/Grid";
import { ceilToNearest, floorToNearest } from "../numbers/round";

export function makeGrid(
  minx: number,
  miny: number,
  maxx: number,
  maxy: number,
  cellSize: number
): Grid<Float32Array> {
  minx = floorToNearest(minx, cellSize);
  miny = floorToNearest(miny, cellSize);
  maxx = ceilToNearest(maxx, cellSize);
  maxy = ceilToNearest(maxy, cellSize);

  const cols = (maxx - minx) / cellSize;

  const rows = (maxy - miny) / cellSize;

  const data = new Float32Array(cols * rows);
  data.fill(NaN);

  return {
    rows,
    cols,
    data,
    cellSize,
    extent: { minx, miny, maxx, maxy },
  };
}
