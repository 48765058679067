export class FilterCodelists {
  static Years (val) {
    var cl = [];

    var toYear = new Date().getFullYear();
    for (val; val <= toYear; val++) {
      cl.push({
        value: val,
        label: val.toString()
      });
    }
    return cl;
  }

  static Weeks (minWeek = 1) {
    let wks = [];
    for (var w = minWeek; w <= 53; w++) {
      wks.push({
        label: `Uke ${w}`,
        value: +w
      });
    }
    return wks;
  }

  static MonthNames = [
    'Januar',
    'Februar',
    'Mars',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Desember'
  ];

  static MonthsInYear () {
    var cl = [];

    for (var m = 1; m <= 12; m++) {
      cl.push({
        value: m,
        label: this.MonthNames[m - 1]
      });
    }
    return cl;
  }

  static HourOfDay () {
    var cl = [];

    for (var t = 0; t < 24; t++) {
      cl.push({
        value: t,
        label: t.toString().length === 1 ? '0' + t + ':00' : t + ':00'
      });
    }
    return cl;
  }

  static PlusMinusHours () {
    var cl = [];

    for (var i = 1; i <= 12; i++) {
      cl.push({
        value: i,
        label: i.toString().length === 1 ? '+/- 0' + i + ' t' : '+/- ' + i + ' t'
      });
    }

    return cl;
  }
}
