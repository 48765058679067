import './BarChartRace.scss';
import * as d3 from 'd3v7';

export const _barX = (x) => {
  return (d) => {
    return x(0) + 1;
  };
};

export const _barY = (y) => {
  return (d) => {
    return y(d.rank) + 5;
  };
};

export const _barWidth = (x) => {
  return (d) => {
    let w = x(d.value) - x(0) - 1;
    return w > 0 ? w : 1;
  };
};

export const _barHeight = (y, barPadding) => {
  return (d) => {
    return y(1) - y(0) - barPadding;
  };
};

export const _drawBars = (x, y, barPadding, tickDuration, transition = false) => {
  return (sel) => {
    let ne = sel.append('rect')
      .attr('class', d => `bar ${d.name.replace(/\s/g, '_')}`)
      .attr('x', _barX(x))
      .attr('y', _barY(y))
      .attr('width', _barWidth(x))
      .attr('height', _barHeight(y, barPadding))
      .style('fill', d => d.colour);
    if (transition) {
      ne.transition()
        .duration(tickDuration)
        .ease(d3.easeLinear)
        .attr('y', _barY(y));
    }
  };
};

export const _translateXY = (x, y, labelPadding) => {
  return (d, i, n) => {
    let dx;
    if (n[i].getComputedTextLength() < (x(d.value) - labelPadding)) {
      dx = x(d.value) - labelPadding;
    } else {
      dx = x(d.value) + labelPadding;
    }
    let dy = y(d.rank) + 5 + ((y(1) - y(0)) / 2) + 1;
    return `translate(${dx} ${dy})`;
  };
};

export const _textAnchor = (x) => {
  return (d, i, n) => {
    if (n[i].getComputedTextLength() < (x(d.value) - 8)) {
      return 'end';
    } else {
      return 'start';
    }
  };
};

export const _textSpan = (x, labelFontSize, valueLabelFormat) => {
  return (sel) => {
    let em = Math.ceil(labelFontSize * 1.2);

    // Add name
    sel.append('tspan')
      .attr('font-size', `${labelFontSize}px`)
      .attr('x', 0)
      .attr('dy', -em * 0.5)
      .attr('class', 'name')
      .text(d => d.name);

    // Add value
    sel.append('tspan')
      .attr('font-size', `${labelFontSize}px`)
      .attr('x', 0)
      .attr('dy', em)
      .attr('class', 'value')
      .text(d => valueLabelFormat(d.value));
  };
};

export const _drawLabels = (x, y, className, labelPadding, labelFontSize, valueLabelFormat) => {
  return (sel) => {
    sel.append('text')
      .call(_textSpan(x, labelFontSize, valueLabelFormat))
      .attr('class', className)
      .attr('transform', _translateXY(x, y, labelPadding))
      .style('text-anchor', _textAnchor(x));
  };
};

export const _halo = (text, strokeWidth) => {
  text.select(function () { return this.parentNode.insertBefore(this.cloneNode(true), this); })
    .style('fill', '#ffffff')
    .style('stroke', '#ffffff')
    .style('stroke-width', strokeWidth)
    .style('stroke-linejoin', 'round')
    .style('opacity', 1);
};
