import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { DataLoader } from "../ui-components/DataLoader/DataLoader";
import { getLocal, setLocal } from "../utils/localStorageCache";
import { TimeUtils } from "../chart-components/ChartUtils/TimeUtils";
import { Municipality } from "../types/Municipality";
import { Passline } from "../types/Passline";
import { Dashboard } from "../types/Dashboard";

type LoadDataFunction = (reload?: boolean) => Promise<any>;

export type DataContextType = {
  passlines: Passline[];
  locations: Location[];
  municipalities: Municipality[];
  dashboards: Dashboard[];
  lastUpdatedMessage: string;
  loadPasslines: LoadDataFunction;
  loadLocations: LoadDataFunction;
  loadMunicipalities: LoadDataFunction;
  loadDashboards: LoadDataFunction;
};

const DataContext = createContext<DataContextType | undefined>(undefined);

export function DataContextProvider({ children }: PropsWithChildren) {
  const [data, setData] = useState<DataContextType | undefined>(undefined);

  const loadLastUpdatedMessage = useCallback((reload: boolean = false) => {
    return DataLoader.postApi("/api/meta/most-recent", {}).then(
      (response: any) => {
        var updated = response.data.map((d: any) => {
          var dataset = "";
          if (["ais", "nsr"].indexOf(d.dataset) > -1) {
            if (d.dataset === "ais") {
              dataset = 'AIS data (posisjoner, "skipshaler"/tracks)';
            } else if (d.dataset === "nsr") {
              dataset =
                "NewShipRep data (skipsinformasjon, havner, seilas, avganger, ankomster, lospliktige seilas)";
            }
            return `Nyeste tilgjengelige ${dataset}: ${TimeUtils.toCompactDate(
              new Date(d.newest)
            )}`;
          } else {
            return null;
          }
        });
        const msg = updated.join(". ");
        setLocal("lastUpdatedMessage", msg);
        if (reload) {
          setData((data: any) => ({
            ...data,
            lastUpdatedMessage: msg,
          }));
        }
        return msg;
      }
    );
  }, []);

  const loadDashboards = useCallback((reload: boolean = false) => {
    const cachedDashboards = getLocal("dashboards", 1);
    if (cachedDashboards) return Promise.resolve(cachedDashboards);
    return DataLoader.getDashboards().then((dashboards: any) => {
      setLocal("dashboards", dashboards);
      if (reload) {
        setData((data) => ({
          ...data!,
          dashboards,
        }));
      }
      return dashboards;
    });
  }, []);

  const loadMunicipalities: LoadDataFunction = useCallback(
    (reload: boolean = false) => {
      const cachedMunicipalities = getLocal("municipalities", 1440);
      if (cachedMunicipalities) return Promise.resolve(cachedMunicipalities);

      return DataLoader.getMunicipalities().then((municipalities: any) => {
        setLocal("municipalities", municipalities);
        if (reload) {
          setData((data) => ({
            ...data!,
            municipalities,
          }));
        }
        return municipalities;
      });
    },
    []
  );

  const loadPasslines: LoadDataFunction = useCallback((reload: boolean = false) => {
    const cachedPasslines = getLocal("passlines", 1);
    if (cachedPasslines) return Promise.resolve(cachedPasslines);
    return DataLoader.getPasslines().then((passlines: any) => {
      setLocal("passlines", passlines);
      if (reload) {
        setData((data) => ({
          ...data!,
          passlines,
        }));
      }
      return passlines;
    });
  }, []);

  const loadLocations: LoadDataFunction = useCallback((reload: boolean = false) => {
    const cachedLocations = getLocal("locations", 1440);
    if (cachedLocations) return Promise.resolve(cachedLocations);
    return DataLoader.getLocations().then((locations: any) => {
      setLocal("locations", locations);
      if (reload) {
        setData((data) => ({
          ...data!,
          locations,
        }));
      }
      return locations;
    });
  }, []);

  useEffect(() => {
    Promise.all([
      loadMunicipalities(),
      loadDashboards(),
      loadPasslines(),
      loadLocations(),
      loadLastUpdatedMessage(),
    ]).then(
      ([
        municipalities,
        dashboards,
        passlines,
        locations,
        lastUpdatedMessage,
      ]) => {
        setData({
          municipalities,
          dashboards,
          passlines,
          locations,
          lastUpdatedMessage,
          loadMunicipalities,
          loadDashboards,
          loadLocations,
          loadPasslines,
        });
      }
    );
  }, [
    loadDashboards,
    loadLastUpdatedMessage,
    loadLocations,
    loadMunicipalities,
    loadPasslines,
  ]);

  if (!data) return null;

  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
}

export function useDataContext() {
  const ctx = useContext(DataContext);
  if (!ctx)
    throw new Error(
      "useData can only be used within a DataContextProvider element"
    );
  return ctx;
}
