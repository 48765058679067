import * as d3 from "d3";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Stack } from "react-bootstrap";
import { PosUtils } from "../ChartUtils/PosUtils";
import { TimeUtils } from "../ChartUtils/TimeUtils";
import "./TimeLine.scss";

export type TimeLineModes = "playing" | "paused" | "stopped";

export type TimeLineProps = {
  fromTime: string;
  toTime: string;
  startTime?: string;
  chartData: any;
  setGeoJson: any;
  framesPerSecond?: number;
  initialSpeed?: number;
  useFlex?: boolean;
  utc?: boolean;
};

const TimeLine: React.FC<TimeLineProps> = ({
  fromTime,
  toTime,
  startTime,
  initialSpeed = 16,
  framesPerSecond = 15,
  useFlex = false,
  utc = false,
  chartData,
  setGeoJson,
}) => {
  // State

  const [mode, setMode] = useState<TimeLineModes>("stopped");

  const [fromTimeD, toTimeD, startTimeD] = useMemo(() => {
    if (utc) {
      return [
        TimeUtils.isoParse(fromTime),
        TimeUtils.isoParse(toTime),
        startTime ? TimeUtils.isoParse(startTime) : null,
      ];
    } else {
      return [
        TimeUtils.localParse(fromTime),
        TimeUtils.localParse(toTime),
        startTime ? TimeUtils.localParse(startTime) : null,
      ];
    }
  }, [fromTime, toTime, startTime, utc]);

  const currentTime = useRef(startTimeD ?? fromTimeD);

  const [playbackSpeed, setPlaybackSpeed] = useState(initialSpeed);
  const timeSliderRef = useRef<any>(null);
  const axisRef = useRef<any>(null);
  const xScale = useRef(
    d3.scaleUtc().domain([fromTimeD, toTimeD]).range([0, 800])
  );
  const getGeoJson = useCallback(
    (currentTime) => {
      return PosUtils.getPositionsUpToTime(
        chartData.allFiltered(),
        currentTime
      );
    },
    [chartData]
  );

  const move = useCallback(
    (offsetOrDate: Date | number, isDate = false) => {
      let geoJsonData;
      if (!isDate) {
        const sec = Math.floor(offsetOrDate as number);
        const ms = ((offsetOrDate as number) % 1) * 1000;
        const tmpDate = new Date(currentTime.current);
        tmpDate.setSeconds(
          tmpDate.getSeconds() + sec,
          tmpDate.getMilliseconds() + ms
        );
        currentTime.current = tmpDate;
        timeSliderRef.current.attr("cx", xScale.current(currentTime.current));
        geoJsonData = getGeoJson(currentTime.current);
      } else {
        const newDate = new Date(offsetOrDate);
        currentTime.current = newDate;
        timeSliderRef.current.attr("cx", xScale.current(newDate));
        geoJsonData = getGeoJson(newDate);
      }
      setGeoJson(geoJsonData);
    },
    [getGeoJson, setGeoJson]
  );

  // Create time axis on mount
  useEffect(() => {
    const svg = d3
      .select(axisRef.current)
      .append("svg")
      .attr("viewBox", "-35, 0, 870, 45")
      .attr("preserveAspectRatio", "xMidYMid meet")
      .attr("flex-grow", 1)
      .attr("padding", "10px 10px 10px 10px")
      .attr("height", 50)
      .attr("width", "100%");

    const xAxis = d3
      .axisBottom(xScale.current)
      .tickPadding(10)
      .tickFormat((d: Date) => {
        console.log(d);
        const tzo = d.getTimezoneOffset();
        console.log(tzo);
        const d0 = new Date(d);
        console.log(d0);
        d0.setMinutes(d0.getMinutes() + tzo);
        if (d0.getHours() === 0) {
          return TimeUtils.locale.format("%d %B")(d0);
        } else {
          return TimeUtils.locale.format("%H:%M")(d0);
        }
      });
    svg.append("g").attr("transform", "translate(0, 15)").call(xAxis);

    const dragged = function (event: any) {
      if (d3.event.x >= 0 && d3.event.x <= 800) {
        timeSliderRef.current.attr("cx", d3.event.x);
        const timeMarkerDate = xScale.current.invert(d3.event.x) as Date;
        currentTime.current = timeMarkerDate;
        move(timeMarkerDate, true);
      }
    };

    timeSliderRef.current = svg
      .append("circle")
      .attr("cx", 0)
      .attr("cy", 15)
      .attr("r", 6)
      .style("fill", "#fff")
      .style("stroke", "#000")
      .style("stroke-width", 2)
      .call(d3.drag().on("drag", dragged));

    return () => {
      svg.remove();
    };
  }, [move]);

  const stop = useCallback(() => {
    setMode("stopped");
    if (startTimeD) {
      move(startTimeD, true);
    } else {
      move(fromTimeD, true);
    }
  }, [startTimeD, move, fromTimeD]);

  const play = useCallback(() => {
    setMode("playing");
  }, []);

  const pause = useCallback(() => {
    setMode("paused");
  }, []);

  useEffect(() => {
    let iv: any = null;

    if (mode === "playing") {
      iv = setInterval(() => {
        if (currentTime.current >= toTimeD || currentTime.current < fromTimeD) {
          stop();
        }
        move(playbackSpeed / framesPerSecond, false);
      }, 1000 / framesPerSecond);
    }

    return () => {
      if (iv !== null) {
        clearInterval(iv);
      }
    };
  }, [framesPerSecond, fromTimeD, mode, move, playbackSpeed, stop, toTimeD]);

  // const forward = () => move(+2);
  const fastForward = () => move(+30);
  // const backward = () => move(-2);
  const fastBackward = () => move(-30);
  const doubleSpeed = () => setPlaybackSpeed(playbackSpeed * 2);
  const halfSpeed = () =>
    setPlaybackSpeed(playbackSpeed / 2 > 1 ? playbackSpeed / 2 : 1);

  const aviTimeLineStyle = useFlex ? { flex: 1 } : { width: "100%" };

  return (
    <div className="avi-time-line" style={aviTimeLineStyle}>
      <div className="controls-container">
        <Stack direction="horizontal" gap={1}>
          <Button onClick={fastBackward} variant="light" className="ms-auto">
            <i className="bi bi-rewind-fill"></i> Tilbake 30 sekund
          </Button>
          <Button onClick={play} variant="light" active={mode === "playing"}>
            <i className="bi bi-play-fill"></i> Spill av
          </Button>
          <Button onClick={pause} variant="light" active={mode === "paused"}>
            <i className="bi bi-pause-fill"></i> Pause
          </Button>
          <Button onClick={stop} variant="light" active={mode === "stopped"}>
            <i className="bi bi-stop-fill"></i> Stop
          </Button>
          <Button onClick={fastForward} variant="light" className="me-auto">
            Fremover 30 sekund <i className="bi bi-fast-forward-fill"></i>
          </Button>
        </Stack>
      </div>
      <div className="axis-container">
        <div className="axis" ref={axisRef} />
      </div>
      <div className="axis-footer">
        <span>
          Tidspunkt:{" "}
          <span className="font-monospace">
            {TimeUtils.isoFormat(currentTime.current)}
          </span>{" "}
          {utc && "UTC"}
        </span>
        <Button variant="light" className="py-0 ms-3" onClick={halfSpeed}>
          <i className="bi bi-dash-circle"></i>
        </Button>
        <span>{` ${playbackSpeed}x hastighet `}</span>
        <Button variant="light" className="py-0" onClick={doubleSpeed}>
          <i className="bi bi-plus-circle"></i>
        </Button>
      </div>
    </div>
  );
};

export default TimeLine;
