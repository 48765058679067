import "./DbrdVoyagesDensity.scss";

import * as d3 from "d3";
import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import BarChartRace from "../../chart-components/Charts/BarChartRace";
import { BarChartStacked } from "../../chart-components/Charts/BarChartStacked";
import { RowChart } from "../../chart-components/Charts/RowChart";
import GeoHeatMap from "../../chart-components/ChartsExt/GeoHeatMap";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { FilterToFromYear } from "../../filters/FilterToFromYear";
import withRouter from "../../hocs/withRouter";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../types/DashboardFilter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";

class DbrdVoyagesDensityBase extends Component<any, any> {
  padNum = d3.format("02d");

  static dashboardRoute = "arrivals-density";

  static dashboardFilters(
    filter: DashboardFilter,
    setFilter: SetDashboardFilterFunc
  ) {
    return {
      helpMessage:
        "Velg fra år og til år. Utvalget er inklusive begge valgte år og alle mellom. Dette dashboardet gjør det mulig å vise data for flere år om gangen.",
      controls: (
        <FilterToFromYear
          key="flt"
          firstYear={2012}
          filter={filter}
          setFilter={setFilter}
        />
      ),
    };
  }

  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: null,
    };
  }

  static dashboardValidation(filter) {
    if (filter.fromYear && filter.toYear) {
      return true;
    }
  }

  static propTypes = {
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.reportProgress = this.reportProgress.bind(this);
    this.reportError = this.reportError.bind(this);
    this.state = {
      chartData: null,
      locationData: [],
      selectedProperty: "loc",
      selectedLabel: "Lokasjon",
    };
  }

  componentDidMount() {
    try {
      const { fromYear, toYear } = this.props.location.state;

      if (fromYear && toYear) {
        DataLoader.postApi(
          "/api/voyage/aggregated/arrivals",
          {
            FromYear: fromYear,
            ToYear: toYear,
          },
          this.reportProgress,
          this.reportError
        )
          .then((res) => {
            let tmpData = res.data.map((r) => {
              var date = new Date(r.eta);
              return {
                wkt: r.lng && r.lat ? `POINT(${r.lng} ${r.lat})` : "_",
                loc: r.loc,
                county: r.countyname,
                council: r.councilname,
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                weekday: date.getDay() + 1,
                shipgroup: r.shipgroup || "n/a",
                shipcategory: r.shipcategory || "n/a",
                tonnagegroup: r.tonnagegroup || "n/a",
                lengthgroup: r.lengthgroup || "n/a",
                arrivals: r.arrivals,
              };
            });
            this.setState({
              chartData: crossfilter(tmpData),
            });
          })
          .catch((error) => {
            console.warn(error);
            this.setState({
              chartData: crossfilter([]),
            });
          });
      } else {
        throw new Error("Missing dashboard selection parameters");
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  reportError(errorData) {
    this.setState({
      errorData: errorData,
    });
  }

  render() {
    const { chartData, progressData, selectedLabel, selectedProperty } =
      this.state;
    const { fromYear, toYear } = this.props.location.state;

    if (!chartData || chartData.size() === 0) {
      return <Loader progressData={progressData} chartData={chartData} />;
    }

    let dimWktLocation = chartData.dimension((d) => [d.wkt, d.loc]);
    let arrivalsByLocation = dimWktLocation
      .group()
      .reduceSum((d) => d.arrivals);

    let dimShipGroup = chartData.dimension((d) => d.shipgroup);
    let arrivalsByShipGroup = dimShipGroup.group().reduceSum((d) => d.arrivals);

    let dimShipCategory = chartData.dimension((d) => d.shipcategory);
    let arrivalsByShipCategory = dimShipCategory
      .group()
      .reduceSum((d) => d.arrivals);

    let dimCounty = chartData.dimension((d) => d.county);
    let arrivalsByCounty = dimCounty.group().reduceSum((d) => d.arrivals);

    let dimTonnageGroup = chartData.dimension((d) => d.tonnagegroup);
    let arrivalsByTonnageGroup = dimTonnageGroup
      .group()
      .reduceSum((d) => d.arrivals);

    let dimLengthGroup = chartData.dimension((d) => d.lengthgroup);
    let arrivalsByLengthGroup = dimLengthGroup
      .group()
      .reduceSum((d) => d.arrivals);

    let dimYearMonth = chartData.dimension(
      (d) => `${d.year}-${this.padNum(d.month)}`
    );

    let dimYear = chartData.dimension((d) => `${d.year}`);

    let dimTimeLoc = chartData.dimension((d) => [
      `${d.year}-${d.month}`,
      d[selectedProperty],
    ]);
    let grpTimeLoc = dimTimeLoc.group().reduceSum((d) => d.arrivals);

    let timeParser = d3.timeParse("%Y-%m");

    return (
      <div className="AppView">
        <AVIDashboard
          title={"Seilastetthet"}
          desc={`Aggregert statistikk over meldepliktige ankomster til  lokasjoner i Norge i tidsrommet fra ${fromYear} til og med ${toYear} som registrerte i SafeSeaNet. Omfatter ikke seilas som er untatt fra meldeplikt slik som innenriks rutegående bil- og persontransport.`}
          spacing={10}
          keyName="arrivals"
          type="sum"
          units="ankomster"
          group={dimShipGroup.groupAll()}
          useFlex
        >
          <AVIRow style={{ height: "800px" }}>
            <AVICol>
              <GeoHeatMap
                chartTitle="Tetthet av ankomster innenfor gjeldende filter"
                dimension={dimShipGroup}
                group={arrivalsByLocation}
                height={2}
                useFlex
              />
            </AVICol>
            <AVICol>
              <RowChart
                chartTitle="Ankomster etter lengegruppe"
                dimension={dimLengthGroup}
                group={arrivalsByLengthGroup}
                height={1.3}
                gap={1}
                filterPrefix="Lengdegruppe"
                useFlex
              />
              <RowChart
                chartTitle="Ankomster etter tonnasjegruppe"
                dimension={dimTonnageGroup}
                group={arrivalsByTonnageGroup}
                height={1.3}
                gap={1}
                filterPrefix="Tonnasjegruppe"
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <RowChart
                chartTitle="Ankomster etter Skipskategori"
                dimension={dimShipGroup}
                group={arrivalsByShipGroup}
                height={1.5}
                gap={1}
                filterPrefix="Skipskategori"
                useFlex
              />
            </AVICol>
            <AVICol>
              <RowChart
                chartTitle="Ankomster etter skipsgruppe"
                dimension={dimShipCategory}
                group={arrivalsByShipCategory}
                height={1.5}
                gap={1}
                filterPrefix="Skipsgruppe"
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <RowChart
              chartTitle="Ankomster etter fylke"
              dimension={dimCounty}
              group={arrivalsByCounty}
              height={1.3}
              gap={1}
              filterPrefix="Fylke"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartStacked
              chartTitle="Ankomster etter skipsgruppe år"
              dimension={dimYear}
              height={1.5}
              gap={2}
              filterPrefix="År"
              stackKey="shipgroup"
              valueKey="arrivals"
              renderLabel
            />
          </AVIRow>
          <AVIRow>
            <BarChartStacked
              chartTitle="Ankomster etter skipsgruppe år og måned"
              dimension={dimYearMonth}
              height={1.5}
              gap={2}
              filterPrefix="År og måned"
              stackKey="shipgroup"
              valueKey="arrivals"
              renderLabel
            />
          </AVIRow>
          <AVIRow>
            <BarChartRace
              title={`${selectedLabel}`}
              description="Ankomster over tid"
              height={640}
              caption="Kilde: NSR"
              group={grpTimeLoc}
              crossfilter={chartData}
              labelAccessor={(d) => d.key[1]}
              timeAccessor={(d) => timeParser(d.key[0])!.getTime()}
              valueAccessor={(d) => d.value}
              startTime={new Date(fromYear, 0, 1).getTime()}
              endTime={new Date(toYear, 11, 1).getTime()}
              timeIncrement={(d) => {
                let d1 = new Date(d);
                let d2 = new Date(d1.setMonth(d1.getMonth() + 1));
                return d2.getTime();
              }}
              timeLabelFormat={(d) => {
                let d0 = new Date(d);
                return d3.timeFormat("%Y-%m")(d0);
              }}
              calcMethod={BarChartRace.CALC_METHOD.ACCUMULATED}
              topN={15}
              tickDuration={1000}
            />
          </AVIRow>
          <AVIRow>
            <select
              onChange={(e) => {
                this.setState({
                  selectedProperty: e.target.value,
                  selectedLabel: e.target.options[e.target.selectedIndex].text,
                });
              }}
            >
              <option value="loc">Lokasjon</option>
              <option value="shipgroup">Skipskategori</option>
              <option value="lengthgroup">Lengdegruppe</option>
              <option value="tonnagegroup">Tonnasjegruppe</option>
            </select>
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdVoyagesDensity = withRouter(DbrdVoyagesDensityBase);

export default DbrdVoyagesDensity;
