export type ArrayIndex<T = any> = {
  [key: string]: T;
};

export function indexArray<T = any>(arr: T[], prop: keyof T): ArrayIndex<T> {
  const indexedArray: ArrayIndex<T> = {};

  // Populate index using values of the specified property as keys
  for (const item of arr) {
    const key = item[prop];
    if (!key) {
      const errorMessage = `indexArray failed, could not find property ${prop as unknown} on object with keys ${Object.keys(item as Object[]).join(", ")}`
      throw new Error(errorMessage);
    }

    if (typeof key === "string" || typeof key === "number") {
      indexedArray[key] = item; // Assign item to `t` at the key `item[prop]`
    }
  }

  return indexedArray;
}
