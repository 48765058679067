import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AuthComponents.scss";
import { useAuthContext } from "./AuthLib";
import AuthRequest from "./AuthRequests";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";

export default function LoginForm() {
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { authDispatch } = useAuthContext();

  const onLogin = useCallback(
    (evt) => {
      AuthRequest.doLogin(email, password).then((res) => {
        if (res?.success === false) {
          setMsg(
            "Innlogging feilet, kontroller brukernavn og passord og prøv igjen. Om du bruker riktig brukernavn og passord men det fremdeles ikke virker er tjenesten sannsynligvis ikke tilgjengelig akkurat nå."
          );
          authDispatch({ type: "logout" });
        } else {
          authDispatch({ type: "login", payload: res });
          navigate("/");
        }
      });
    },
    [email, password, navigate, authDispatch]
  );

  useEffect(() => {
    if (msg === "") return;
    let ti = setTimeout(() => {
      setMsg((msg) => "");
    }, 5000);
    return () => {
      clearTimeout(ti);
    };
  }, [msg, setMsg]);

  const onCancel = useCallback(
    (evt) => {
      navigate("/");
    },
    [navigate]
  );

  return (
    <div className="kyv-auth-form">
      <FormGroup className="mb-3">
        <FormLabel>Brukernavn (E-postadresse)</FormLabel>
        <FormControl
          autoComplete="section-login username"
          type="email"
          name="username"
          value={email}
          onChange={(evt) => setEmail(evt.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Passord</FormLabel>
        <FormControl
          type="password"
          className="flex-3"
          autoComplete="section-login password"
          name="password"
          value={password}
          onChange={(evt) => setPassword(evt.target.value)}
        />
      </FormGroup>
      {msg !== "" && <div className="row error p-1 my-1">{msg}</div>}
      <div className="row align-right">
        <input
          type="button"
          name="cancel"
          onClick={onLogin}
          value={"Logg inn"}
        />
        <input
          type="button"
          name="cancel"
          onClick={onCancel}
          value={"Avbryt"}
        />
      </div>
      <div className="text-gray fw-lighter small">
        <p>
          Kystdatahuset benytter samme brukerdatabase som{" "}
          <a href="https://kystinfo.no" target="_blank" rel="noreferrer">
            Kystinfo.no
          </a>
          . Du kan enten bruke din eksisterende Kystinfo-konto eller opprette en
          ny konto ved å klikke på lenken under. Du kan endre passord ved å
          logge inn på Kystinfo.no. Det vil ta inntil 5 minutter før nye kontoer
          vil gi tilgang til innhold i Kystdatahuset.
        </p>
        <p>
          Tjenesten fungerer i alle moderne nettlesere inkludert Chrome, Edge,
          Internet Explorer (&gt;=11), Firefox, Safari etc. Den er optimalisert
          for PC-klienter men vil kunne fungere på nettbrett.
        </p>
        <p>
          Denne tjenesten laster ned større datamengder til din PC. Disse caches
          ikke og vil således ikke "spise opp" diskplass men de kan medføre mye
          dataoverføring.
        </p>
      </div>
    </div>
  );
}
