import './ShipTypeAhead.scss';

import { useCallback } from 'react';

import AsyncSelect from 'react-select/async';
import { DataLoader } from '../../ui-components/DataLoader/DataLoader';
import { ExecUtils } from '../../chart-components/ChartUtils/ExecUtils';
import { Ship, ShipAis } from '../../types/Ships';
import { WebServiceResponse } from '../../types/WebServiceResponse';
import { prefixIfExits } from '../../utils/string/prefixIfExists';
import { joinByIfExists } from '../../utils/string/joinByIfExists';
import { arrayDropDuplicatesBy } from '../../utils/array/arrayDropDuplicatesBy';
import { isArrayN } from '../../utils/array/isArrayN';
import React from 'react';

type TypeaheadOption<T = any> = {
  key: string,
  value: any,
  label: any,
  data: T
}

type TypeaheadOptionsWrapper<T = any> = {
  label: string,
  options: TypeaheadOption<T>[]
}

type ShipTypeAheadProps = {
  setValue: (v: any) => void
  selectedValue?: undefined,
  isMulti?: boolean,
  label?: string,
  defaultValue?: undefined | any,
}

export function ShipTypeAhead({
  setValue,
  selectedValue = undefined,
  isMulti = false,
  label = 'Velg et skip',
  defaultValue = undefined,
}: ShipTypeAheadProps) {

  const parseComb = useCallback((recs: Ship[]): TypeaheadOption<Ship>[] => {
    var i = 0;
    return recs.map((match): TypeaheadOption<Ship> => {
      return {
        key: 'ship' + i++,
        value: match.shipid,
        label: match.shipname + ' (' + match.mmsi + '/' + match.imo + ')',
        data: {
          mmsi: match.mmsi,
          imo: match.imo,
          callsign: match.callsign
        }
      };
    });
  }, []);

  const parseAis = useCallback((recs: ShipAis[]): TypeaheadOption<Ship>[] => {
    var i = 0;
    return recs.map((match): TypeaheadOption<Ship> => {
      return {
        key: 'ship' + i++,
        value: undefined,
        label: match.ship_name + ' (' + joinByIfExists(", ", prefixIfExits(match.mmsi, "MMSI"), prefixIfExits(match.imo, "IMO")) + ')',
        data: {
          mmsi: match.mmsi,
          imo: match.imo,
          callsign: match.callsign
        }
      };
    });
  }, []);

  const loadShips = useCallback((queryTerm, setOptions) => {
    if (queryTerm && queryTerm.trim().length > 3) {

      Promise.all([DataLoader.postApi<WebServiceResponse<Ship[]>>('/api/ship/free-text', {
        FreeText: queryTerm
      }), DataLoader.getApi<WebServiceResponse<ShipAis[]>>('/api/ship/data/ais/free-text', {
        q: queryTerm,
        y1: 2014,
        y2: 2024
      })]).then(([comb, ais]) => {
        const options: TypeaheadOption[] = [];

        if (comb.success) {
          options.push(...parseComb(comb.data))
        }

        if (ais.success) {
          const t = arrayDropDuplicatesBy<ShipAis>(ais.data, "mmsi", "imo", "ship_name");
          console.log(t);
          options.push(...parseAis(t));
        }

        if (isArrayN(options, 0)) {

          console.log(options);
          const optionsObject: TypeaheadOptionsWrapper<Ship> = {
            label: "Skipsnavn (MMSI/IMO)",
            options
          };
          setOptions([optionsObject]);
        } else {
          setOptions([]);
        }
      });
    }
  }, [parseAis, parseComb]);

  return (
    <div className='filter-control'>
      {label !== undefined && (<label>{label}</label>)}
      <AsyncSelect
        cacheOptions
        placeholder={'Skipsnavn, MMSI# eller IMO#'}
        loadingMessage={d => 'Søker'}
        loadOptions={ExecUtils.debounceArgs(this, loadShips, 500)}
        defaultOptions
        defaultValue={defaultValue}
        isMulti={isMulti}
        onChange={setValue}
      />
    </div>
  );
}
