import reductio from 'reductio';

import React, { Component } from 'react';

import { NumberDisplay } from './NumberDisplay';
import PropTypes from 'prop-types';

export class NumberDisplayMax extends Component {
  static propTypes = {
    keyName: PropTypes.string,
    group: PropTypes.object,
    valueAccessor: PropTypes.number
  };

  render() {
    const { group, keyName, valueAccessor, ...otherProps } = this.props;
    var calcMax = reductio().max(d => d[keyName]);
    calcMax(group);

    return <NumberDisplay group={group} valueAccessor={d => d.max} {...otherProps} />;
  }
}

export default NumberDisplayMax;
