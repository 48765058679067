import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { TimeUtils } from "../chart-components/ChartUtils/TimeUtils";
import DateWithoutYear from "./FilterDateWithoutYear";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../types/DashboardFilter";

export type FilterToFromTime2Props = {
  setFilter: SetDashboardFilterFunc,
  filter: DashboardFilter,
  baseYear?: number,
};

function FilterToFromTime2({ setFilter, filter, baseYear = 2020 }: FilterToFromTime2Props) {
  const defaultTime = useMemo(() => new Date(baseYear, 0, 1), [baseYear]);
  const [fromTime, setFromTime] = useState(
    filter.fromTime ? new Date(filter.fromTime) : defaultTime
  );
  const [toTime, setToTime] = useState(
    filter.fromTime ? new Date(filter.fromTime) : defaultTime
  );

  useEffect(() => {
    setFilter({
      fromTime: fromTime.toISOString(),
      toTime: toTime.toISOString(),
    });
  }, [setFilter, fromTime, toTime]);

  const update = useCallback(
    (key, val) => {
      if (key === "fromTime") {
        if (val > toTime) {
          setToTime(val);
        }
        setFromTime(val);
      }
      if (key === "toTime") {
        if (val < fromTime) {
          val = fromTime;
        }
        setToTime(val);
      }
    },
    [fromTime, toTime, setFromTime, setToTime]
  );

  return (
    <div key="fromTime">
      <DateWithoutYear
        key="fromTime_new"
        name="fromTime"
        label="Startdato (fra og med)"
        value={fromTime ?? defaultTime}
        onSelect={update}
        baseYear={baseYear}
      />
      <DateWithoutYear
        key="toTime_new"
        name="toTime"
        label="Sluttdato (til og med)"
        value={toTime ?? fromTime ?? defaultTime}
        minDate={fromTime}
        onSelect={update}
        baseYear={baseYear}
      />
    </div>
  );
}

FilterToFromTime2.propTypes = {
  setFilter: PropTypes.func.isRequired,
  filter: PropTypes.object,
  format: PropTypes.string,
  fromTime: PropTypes.instanceOf(Date),
  toTime: PropTypes.instanceOf(Date),
};

FilterToFromTime2.defaultProps = {
  fromTime: TimeUtils.getDateDiff(0),
  toTime: TimeUtils.getDateDiff(-7),
};

export { FilterToFromTime2 };
export default FilterToFromTime2;
