import React, { Component } from 'react';

import { GroupUtils } from './../ChartUtils/GroupUtils';
import { NumUtils } from '../ChartUtils/NumUtils';
import { NumberDisplay } from './NumberDisplay';
import PropTypes from 'prop-types';

export class NumberDisplayDim extends Component {
  static propTypes = {
    dimension: PropTypes.object.isRequired,
    keyName: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['max', 'min', 'count']),
    formatNumber: PropTypes.func
  };

  static defaultProps = {
    type: 'max',
    formatNumber: NumUtils.integer
  };

  render () {
    const { dimension, keyName, type, ...otherProps } = this.props;
    let group = {
      all: function () {
        return [];
      }
    };

    function valueAccessorMax (d) {
      var dimTop = dimension.top(1);
      return dimTop.length > 0 ? dimension.top(1)[0][keyName] : 0;
    }
    function valueAccessorMin (d) {
      var dimBottom = dimension.bottom(1);
      return dimBottom.length > 0 ? dimension.bottom(1)[0][keyName] : 0;
    }
    function valueAccessorCount (d) {
      return dimension.group().top(Number.POSITIVE_INFINITY).length;
    }

    let valueAccessor = d => {
      return d[keyName];
    };

    if (type === 'max') {
      group = GroupUtils.wrapArrayInGroup([]);
      valueAccessor = valueAccessorMax;
    } else if (type === 'min') {
      group = GroupUtils.wrapArrayInGroup([]);
      valueAccessor = valueAccessorMin;
    } else if (type === 'count') {
      group = GroupUtils.wrapArrayInGroup([]);
      valueAccessor = valueAccessorCount;
    }

    return <NumberDisplay group={group} valueAccessor={valueAccessor} {...otherProps} />;
  }
}
