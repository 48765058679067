import '../Charts/DataTable.scss';

import * as d3 from 'd3';
import * as dc from 'dc';

import React, { Component } from 'react';

import { AVITableTile } from '../Layout/AVITableTile';
import { DcExtPivotTable } from './../DcExt/DcExtPivotTable';
import PropTypes from 'prop-types';

export class PivotTable extends Component {
  static propTypes = {
    chartTitle: PropTypes.string.isRequired,
    dimension: PropTypes.object.isRequired,
    group: PropTypes.object.isRequired,
    description: PropTypes.string,
    rowKeyIndex: PropTypes.number,
    colKeyIndex: PropTypes.number,
    rowLabel: PropTypes.string,
    colLabel: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    filterPrefix: PropTypes.string,
    useFlex: PropTypes.bool
  };

  static defaultProps = {
    rowsKeyIndex: 0,
    colsKeyIndex: 1,
    rowLabel: '',
    colLabel: '',
    description: '',
    filterPrefix: 'Filter',
    useFlex: true,
  };

  constructor (props) {
    super(props);
    this.chartRef = React.createRef();
    this._options = props;
    this.getChart = this.getChart.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
  }

  componentDidMount () {
    const { dimension, group, rowKeyIndex, colKeyIndex, rowLabel, colLabel, filterPrefix, ...otherProps } = this.props;

    var chart = new DcExtPivotTable({
      parent: d3.select(this.chartRef.current),
      dimension: dimension,
      group: group,
      rowKeyIndex: rowKeyIndex,
      colKeyIndex: colKeyIndex,
      rowLabel: rowLabel,
      colLabel: colLabel,
      filterPrefix: filterPrefix,
      ...otherProps
    });

    chart.draw();

    this.setState({
      chart: chart
    });
  }

  resetFilter() {
    const {dimension} = this.props;
    // const {chart} = this.state;
    // chart.resetFilter();
    dimension.filterAll();
    dc.redrawAll();
  }

  getChart () {
    const { chart } = this.state;
    return chart;
  }

  getTableData () {
    const { chart } = this.state;
    return chart.data();
  }

  render () {
    const { width, height, chartTitle, useFlex,  description = '' } = this.props;
    return (
      <AVITableTile
        title={chartTitle}
        description={description}
        getTableData={this.getTableData}
        getChart={this.getChart}
        table={this.chartRef}
        width={width}
        height={height}
        showToolbar
        useFlex={useFlex}
        resetFilter={this.resetFilter}
      >
        <table className='avi-data-table' ref={this.chartRef} />
      </AVITableTile>
    );
  }
}
