import * as d3 from "d3";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { PieChart } from "../../chart-components/Charts/PieChart";
import { PivotTable } from "../../chart-components/ChartsExt/PivotTable";
import { PointDimMapChart } from "../../chart-components/ChartsExt/PointDimMapChart";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import withRouter from "../../hocs/withRouter";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../types/DashboardFilter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";
import { KYVGroupings } from "../../utils/KYVGroupings";

class DbrdNorVtsIncidentsBase extends Component<any, any> {
  static dashboardRoute = "norvts-incidents";

  static dashboardFilters(
    filter: DashboardFilter,
    setFilter: SetDashboardFilterFunc
  ) {
    return {
      helpMessage:
        "Dette dashboardet gjør det mulig å vise data for flere år om gangen, man trenger ikke angi filtre på nåværende tidspunkt.",
      controls: undefined,
    };
  }

  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: undefined,
    };
  }

  static dashboardValidation(filter) {
    return true;
  }

  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
      geoJsonData: null,
      locationInfo: null,
    };
    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    try {
      var mergeRequests = [
        DataLoader.postApi(
          "/api/kystinfo/norvts-incidents",
          {
            StartTime: new Date(2010, 0, 0),
            EndTime: new Date(2099, 12, 31),
          },
          this.reportProgress
        ),
      ];
      Promise.all(mergeRequests).then((jsonResponses) => {
        // Check that all responses are successful
        var success = true;

        jsonResponses.forEach((r) => {
          if (!r.success) {
            if (success) {
              success = false;
            }
          }
        });

        if (!success) {
          throw new Error("Error loading norvts incidents");
        }

        var incidents = jsonResponses[0].data;

        this.setState({
          chartData: crossfilter(incidents),
        });
      });
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const { chartData, progressData } = this.state;

    if (!chartData || chartData.size() === 0) {
      return <Loader chartData={chartData} progressData={progressData} />;
    }

    var dimYear = chartData.dimension((d) => {
      return new Date(d.dato).getFullYear();
    });

    var incidentsByYear = dimYear.group().reduceCount();

    var dimMonth = chartData.dimension((d) => {
      return new Date(d.dato).getMonth() + 1;
    });
    var incidentsByMonth = dimMonth.group().reduceCount();

    var dimWeekday = chartData.dimension((d) => {
      return new Date(d.dato).getDay() + 1;
    });
    var incidentsByWeekday = dimWeekday.group().reduceCount();

    var dimShipType = chartData.dimension((d) =>
      d.skipstype ? d.skipstype : "ukjent"
    );
    var incidentsByShipType = dimShipType.group().reduceCount();

    var dimTypeYear = chartData.dimension((d) => [
      d.hendelsetype,
      new Date(d.dato).getFullYear(),
    ]);
    var incidentsByTypeYear = dimTypeYear.group().reduceCount();

    var dimType = chartData.dimension((d) =>
      d.hendelsetype ? d.hendelsetype : "ukjent"
    );
    var incidentsByType = dimType.group().reduceCount();

    var dimSizeBt = chartData.dimension((d) =>
      KYVGroupings.getGrossTonnageGroup(d.storrelse_bt)
    );
    var incidentsBySizeBt = dimSizeBt.group().reduceCount();

    var dimPoint = chartData.dimension((d) => [d.lng, d.lat, d.ship_name]);
    var incidentsByPoint = dimPoint.group().reduceSum((d) => 10);

    var dimIncidentId = chartData.dimension((d) => [
      d.hendelsesid,
      d.dato,
      d.mmsi || "",
      d.imo || "",
      d.ship_name || "ikke oppgitt",
      d.hendelsetype || "ikke oppgitt",
      d.skipstype || "n/a",
      d.vindretning || "n/a",
      d.vindstyrke || "n/a",
      d.storrelse_bt || "n/a",
    ]);

    var incidentsById = dimIncidentId.group().reduceCount();

    return (
      <div className="AppView">
        <AVIDashboard
          title="Hendelser (kun for Kystverket)"
          desc={
            "Vardø sjøtrafikksentral (NOR VTS) plotter hendelser hvor man har registrert fartøy i drift, forlis, brann, grunnstøtinger, slagside/ forskyving av last, kollisjon/sammenstøt, forurensning, drivende gjenstand og fartøy som har dregget innen Norsk Økonomisk sone."
          }
          spacing={10}
          useFlex
          keyName="mmsino"
          type="count"
          group={dimYear.groupAll()}
          cfilter={chartData}
          filter={this.props.location.state}
        >
          <AVIRow>
            <PointDimMapChart
              chartTitle="Hendelser etter hendelsessted"
              dimension={dimPoint}
              group={incidentsByPoint}
              useFlex
              height={2}
            />
          </AVIRow>
          <AVIRow>
            <PivotTable
              chartTitle="Hendelser etter hendelsestype og år"
              height={2}
              useFlex
              group={incidentsByTypeYear}
              dimension={dimType}
              rowLabel="Hendelsetype"
              colLabel="År"
              rowKeyIndex={0}
              colKeyIndex={1}
              filterPrefix="Hendelsetype"
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={incidentsByYear}
              dimension={dimYear}
              chartTitle="Hendelser etter år"
              useFlex
              height={1}
              margins={{ left: 50, bottom: 50 }}
              ordering={(d) => d.key}
              yAxisLabel="Antall hendelser"
              xAxisLabel="År"
              filterPrefix="År"
              rotateXAxisLabels={-15}
            />
            <BarChartOrdinal
              group={incidentsByMonth}
              dimension={dimMonth}
              chartTitle="Hendelser etter måned i året"
              useFlex
              height={1}
              margins={{ left: 50, bottom: 50 }}
              ordering={(d) => d.key}
              yAxisLabel="Antall hendelser"
              xAxisLabel="Måned i året (Jan = 1, Des = 12)"
              filterPrefix="Måned i året"
              rotateXAxisLabels={-15}
            />
            <BarChartOrdinal
              group={incidentsByWeekday}
              dimension={dimWeekday}
              chartTitle="Hendelser etter ukedag"
              useFlex
              height={1}
              margins={{ left: 50, bottom: 50 }}
              ordering={(d) => d.key}
              yAxisLabel="Antall hendelser"
              xAxisLabel="Ukedag (Man = 1, Søn = 7)"
              filterPrefix="Ukedag"
              rotateXAxisLabels={-15}
            />
          </AVIRow>
          <AVIRow>
            <PieChart
              chartTitle="Hendelser etter hendelsetype"
              useFlex
              height={1.5}
              margins={{ top: 20, bottom: 50 }}
              innerRadius={50}
              dimension={dimType}
              group={incidentsByType}
              minAngleForLabel={0.15}
              filterPrefix="Hendelsetype"
            />
            <PieChart
              chartTitle="Hendelser etter skipstype"
              useFlex
              height={1.5}
              margins={{ top: 20, bottom: 50 }}
              innerRadius={50}
              dimension={dimShipType}
              group={incidentsByShipType}
              minAngleForLabel={0.15}
              filterPrefix="Skipstype"
            />
            <PieChart
              chartTitle="Hendelser etter bruttotonnasje"
              useFlex
              height={1.5}
              margins={{ top: 20, bottom: 50 }}
              innerRadius={50}
              dimension={dimSizeBt}
              ordering={(d) =>
                KYVGroupings.getGrossTonnageGroupLabel().indexOf(d.key)
              }
              group={incidentsBySizeBt}
              minAngleForLabel={0.15}
              filterPrefix="Bruttotonnasje"
            />
          </AVIRow>
          <AVIRow>
            <DataTable
              chartTitle="Liste over hendelser"
              dimension={GroupUtils.RemoveEmptyBins(incidentsById)}
              sortBy={(d) => d.key[1]}
              useFlex
              size={Infinity}
              order={d3.ascending}
              showSortControls
              columns={[
                {
                  label: "ID",
                  title: "Hendelse ID",
                  format: (d) => d.key[0],
                },
                {
                  label: "Dato",
                  title: "Dato",
                  format: (d) => TimeUtils.formatDate(d.key[1]),
                  value: (d) => d.key[1],
                },
                {
                  label: "MMSI",
                  title: "MMSI",
                  format: (d) => d.key[2],
                },
                {
                  label: "IMO",
                  title: "IMO",
                  format: (d) => d.key[3],
                },
                {
                  label: "Skipsnavn",
                  title: "Skipsnavn",
                  format: (d) => d.key[4],
                },
                {
                  label: "Hendelsetype",
                  title: "Hendelsetype",
                  format: (d) => d.key[5],
                },
                {
                  label: "Skipstype",
                  title: "Skipstype",
                  format: (d) => d.key[6],
                },
                {
                  label: "Størrelse BT",
                  title: "Skipstype",
                  format: (d) => d.key[9],
                },
              ]}
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdNorVtsIncidents = withRouter(DbrdNorVtsIncidentsBase);

export default DbrdNorVtsIncidents;
