function lsTest() {
  var test = "987654321";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

function ssTest() {
  var test = "987654321";
  try {
    sessionStorage.setItem(test, test);
    sessionStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

export type TimedStorageItem<T = any> = {
  tstamp: Date;
  value: T;
};

export function setLocal<T = any>(key: string, val: T) {
  if (!lsTest()) return null;
  let item: TimedStorageItem<T> = {
    tstamp: new Date(),
    value: val,
  };
  localStorage.setItem(key, JSON.stringify(item));
  return val;
}

export function setLocalPrefixed<T = any>(prefix: string, key: string, val: T) {
  return setLocal<T>(`${prefix}--${key}`, val);
}

export function getLocalPrefixed<T = any>(prefix: string, key: string) {
  return getLocal<T>(`${prefix}--${key}`);
}

/**
 *
 * @param {*} key
 * @param {number} maxAgeMinutes
 * @returns
 */
export function getLocal<T>(
  key: string,
  maxAgeMinutes: number | undefined = undefined
) {
  if (!lsTest()) return null;
  let v: string | null = localStorage.getItem(key);
  if (v !== null) {
    const tsi = JSON.parse(v) as TimedStorageItem<T>;
    if (maxAgeMinutes && typeof maxAgeMinutes === "number") {
      tsi.tstamp = new Date(tsi.tstamp); //Create date from serialized string
      var ageMinutes =
        Math.abs(new Date().getTime() - tsi.tstamp.getTime()) / 1000 / 60;
      if (ageMinutes > maxAgeMinutes) {
        return null;
      }
    }
    return tsi.value;
  } else {
    return null;
  }
}

export function setSession<T = any>(key: string, val: T) {
  if (!ssTest()) return null;
  let item: TimedStorageItem = {
    tstamp: new Date(),
    value: val,
  };
  sessionStorage.setItem(key, JSON.stringify(item));
  return val;
}

export function getSession<T = any>(
  key: string,
  maxAgeMinutes: number | null = null
) {
  if (!ssTest()) return null;
  var v = sessionStorage.getItem(key);
  if (v) {
    const tsi = JSON.parse(v) as TimedStorageItem<T>;
    if (maxAgeMinutes !== null && typeof maxAgeMinutes === "number") {
      tsi.tstamp = new Date(tsi.tstamp);
      var ageMinutes =
        Math.abs(new Date().getTime() - tsi.tstamp.getTime()) / 1000 / 60;
      if (ageMinutes > maxAgeMinutes) {
        return null;
      }
    }
    return tsi.value;
  } else {
    return null;
  }
}

export function setCookie(key: string, value: any, maxDays = null) {
  let expiresString = "";
  if (maxDays !== null) {
    let currentDate = new Date();
    let expiresDate = new Date();
    expiresDate.setDate(currentDate.getDate() + maxDays);
    expiresString = `; expires=${expiresDate.toUTCString()};`;
  }
  document.cookie = `${key}=${value}${expiresString}`;
}

export function getCookie(key: string) {
  // Add the = sign
  key = key + "=";

  // Get the decoded cookie
  const decodedCookie = decodeURIComponent(document.cookie);

  // Get all cookies, split on ; sign
  const cookies = decodedCookie.split(";");

  // Loop over the cookies
  for (let i = 0; i < cookies.length; i++) {
    // Define the single cookie, and remove whitespace
    const cookie = cookies[i].trim();

    // If this cookie has the name of what we are searching
    if (cookie.indexOf(key) === 0) {
      // Return everything after the cookies name
      return cookie.substring(key.length, cookie.length);
    }
  }
}
