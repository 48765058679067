import * as d3 from 'd3';

import React, { Component } from 'react';

import { BarChart } from './BarChart';

export class BarChartHour extends Component {
  constructor (props) {
    super(props);
    this.calcDomain = this.calcDomain.bind(this);
  }

  calcDomain (chart) {
    var nullv = new Date(null);
    var filtered = chart
      .group()
      .all()
      .filter(function (v) {
        return v.key > nullv;
      });
    var min = d3.min(filtered, function (kv) {
      return kv.key;
    });
    var max = d3.max(filtered, function (kv) {
      return kv.key;
    });

    max = d3.timeHour.offset(max, 1);
    chart.x().domain([min, max]);
  }

  render () {
    return (
      <BarChart
        x={d3.scaleTime()}
        xUnits={d3.timeHours}
        round={d3.timeHour.round}
        preRender={this.calcDomain}
        preRedraw={this.calcDomain}
        elasticX={false}
        {...this.props}
      />
    );
  }
}
