import "./ReplayMapChart.scss";

import * as olControl from "ol/control";
import * as olExtent from "ol/extent";
import * as olInteraction from "ol/interaction";
import * as olProj from "ol/proj";

import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { FeatureLike } from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import GeoJSONFormat, { GeoJSONFeatureCollection } from "ol/format/GeoJSON";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import olFill from "ol/style/Fill";
import olStyleIcon from "ol/style/Icon";
import olStroke from "ol/style/Stroke";
import olStyle from "ol/style/Style";
import olText from "ol/style/Text";
import { MapProvider } from "../../context/MapProvider";
import { geoJSONFormat } from "../../utils/geomUtils";
import { AVIColorScales } from "../AVIColorScales";
import { PosUtils } from "../ChartUtils/PosUtils";
import { AVIMapTile } from "../Layout/AVIMapTile";
import { LayerSwitcherMixin } from "../Mixins/LayerSwitcherMixin";
import BoatWhite from "./boat-white.svg";

const hexToRgb = (hex) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : [0, 0, 0];
};

export type ReplayMapChartProps = PropsWithChildren & {
  geoJson: GeoJSONFeatureCollection;
  trackGeoJson: GeoJSONFeatureCollection;
  bbox: number[];
  chartTitle: string;
  height: number;
  width?: number;
  labelProperty: string;
  colorProperty: string;
  allowFullscreen?: boolean;
  useFlex?: boolean;
};

export function ReplayMapChart({
  geoJson,
  trackGeoJson,
  bbox,
  chartTitle,
  height,
  width,
  labelProperty = "id",
  colorProperty = "id",
  allowFullscreen = true,
  useFlex = true,
  children,
}: ReplayMapChartProps) {
  const [map, setMap] = useState<Map>();

  const chartRef = useRef<any>(null);

  const getStyle = useCallback(
    (feature: FeatureLike) => {
      var label = feature.getProperties()[labelProperty];
      var colorIdx = "" + feature.getProperties()[colorProperty];
      let cog = feature.getProperties()["cog"];
      let course =
        cog >= 0 && cog < 360 ? cog : feature.getProperties()["bearing"];
      var style = [
        new olStyle({
          image: new olStyleIcon({
            color: hexToRgb(AVIColorScales.colorScaleKyv2024(colorIdx)),
            anchor: [0.5, 0.5],
            anchorXUnits: "fraction",
            anchorYUnits: "fraction",
            scale: 0.1,
            size: [104, 304],
            src: BoatWhite,
            rotation: PosUtils.toRadians(course),
          }),
          text: new olText({
            font: "small-caps bold 14px sans-serif",
            text: label.toString(),
            textBaseline: "bottom",
            offsetY: -16,
            fill: new olFill({
              color: "#fff",
            }),
            stroke: new olStroke({
              color: "#000",
              width: 3,
            }),
          }),
        }),
      ];
      return style;
    },
    [colorProperty, labelProperty]
  );

  const getTrackStyle = useCallback(
    (feature: FeatureLike) => {
      var colorIdx = "" + feature.getProperties()[colorProperty];

      var style = [
        new olStyle({
          stroke: new olStroke({
            color: hexToRgb(AVIColorScales.colorScaleKyv2024(colorIdx)),
            width: 2,
          }),
        }),
      ];
      return style;
    },
    [colorProperty]
  );

  const getTrackGeoJson = useCallback(() => {
    return trackGeoJson;
  }, [trackGeoJson]);

  const getFeatures = useCallback(() => {
    if (geoJson) {
      return new GeoJSONFormat().readFeatures(geoJson, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      });
    } else {
      return null;
    }
  }, [geoJson]);

  const getTrackFeatures = useCallback(() => {
    if (trackGeoJson) {
      return new GeoJSONFormat().readFeatures(trackGeoJson, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      });
    } else {
      return null;
    }
  }, [trackGeoJson]);

  const [bboxSource, vectorSource, trackVectorSource] = useMemo(() => {
    return [new VectorSource(), new VectorSource(), new VectorSource()];
  }, []);

  const draw = useCallback(() => {
    vectorSource.clear();
    var features = getFeatures();
    if (features) {
      vectorSource.addFeatures(features);
    }

    trackVectorSource.clear();
    var trackFeatures = getTrackFeatures();
    if (trackFeatures) {
      trackVectorSource.addFeatures(trackFeatures);
    }
  }, [getFeatures, getTrackFeatures, trackVectorSource, vectorSource]);

  const getMap = useCallback(() => {
    return map;
  }, [map]);

  useEffect(() => {
    var zoomPt = [15, 61];

    const _map = new Map({
      target: chartRef.current,
      interactions: olInteraction.defaults({ mouseWheelZoom: false }),
      controls: olControl.defaults({
        attributionOptions: {
          collapsible: false,
        },
      }),
      view: new View({
        center: olProj.transform(
          [zoomPt[0], zoomPt[1]],
          "EPSG:4326",
          "EPSG:3857"
        ),
        zoom: 8,
      }),
    });

    var extent = olExtent.boundingExtent([
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
    ]);
    extent = olProj.transformExtent(extent, "EPSG:4326", "EPSG:3857");
    _map.getView().fit(extent, {
      size: _map.getSize(),
      padding: [10, 50, 25, 50],
    });

    var bboxLayer = new VectorLayer({
      properties: {
        title: "Utvalgsrektangel",
      },
      source: bboxSource,
      style: new olStyle({
        stroke: new olStroke({
          color: "red",
          width: 2,
          lineDash: [4, 4],
        }),
      }),
    });

    var bboxJson = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [bbox[0], bbox[1]],
            [bbox[0], bbox[3]],
            [bbox[2], bbox[3]],
            [bbox[2], bbox[1]],
            [bbox[0], bbox[1]],
          ],
        ],
      },
    };
    var bboxFeature = geoJSONFormat.readFeature(bboxJson, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    });

    bboxSource.addFeatures([bboxFeature]);
    bboxLayer.setZIndex(1100);

    _map.addLayer(bboxLayer);

    const vectorLayer = new VectorLayer({
      properties: {
        title: "Positions",
      },
      source: vectorSource,
      style: getStyle,
    });

    vectorLayer.setZIndex(1000);
    _map.addLayer(vectorLayer);

    const trackVectorLayer = new VectorLayer({
      properties: {
        title: "Tracks",
      },
      source: trackVectorSource,
      style: getTrackStyle,
    });

    trackVectorLayer.setZIndex(999);
    _map.addLayer(trackVectorLayer);

    LayerSwitcherMixin(_map);
    setMap(_map);
    return () => {
      if (_map) {
        _map.dispose();
      }
    };
  }, [
    bbox,
    bboxSource,
    getStyle,
    getTrackStyle,
    trackVectorSource,
    vectorSource,
  ]);

  useEffect(() => {
    draw();
  }, [draw]);

  return (
    <AVIMapTile
      title={chartTitle}
      height={height}
      width={width}
      getMap={getMap}
      getTrackData={getTrackGeoJson}
      useFlex={useFlex}
      allowFullscreen={allowFullscreen}
    >
      <div className="avi-map" ref={chartRef}>
        {map && <MapProvider map={map}>{children}</MapProvider>}
      </div>
    </AVIMapTile>
  );
}
