import React from "react";

export type OlPanelProps = {
    children?: any;
    top?: number | string;
    left?: number | string;
    bottom?: number | string;
    right?: number | string;
    width?: number | string;
    height?: number | string;
    vertical?: boolean;
    align?: "start" | "end" | "center" | "left" | "right";
    gap?: 1 | 2 | 3 | 4 | 5;
    reverse?: boolean;
    transform?: string;
  };
  
  export function OlPanel({
    children = null,
    top = "unset",
    left = "unset",
    bottom = "unset",
    right = "unset",
    width = "unset",
    height = "unset",
    vertical = false,
    align = "center",
    transform,
    gap = 2,
    reverse = false,
  }: OlPanelProps) {
  
    return (
      <div
        style={{
          position: "absolute",
          top,
          bottom,
          left,
          right,
          width,
          height,
          zIndex: 100,
          display: "flex",
          justifyContent: align,
          alignItems: align,
          flexDirection:
            vertical === false
              ? reverse
                ? "row-reverse"
                : "row"
              : reverse
                ? "column-reverse"
                : "column",
          gap: `${gap * 0.1}em`,
          transform: transform ? transform : undefined
        }}
      >
        {children}
      </div>
    );
  }
  