import "./DbrdSpeed.scss";

import * as d3 from "d3";
import reductio from "reductio";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { NumUtils } from "../../chart-components/ChartUtils/NumUtils";
import { PosUtils } from "../../chart-components/ChartUtils/PosUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartHour } from "../../chart-components/Charts/BarChartHour";
import { BarChartLinear } from "../../chart-components/Charts/BarChartLinear";
import { CBoxMenu } from "../../chart-components/Charts/CBoxMenu";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { LineStringMapChart } from "../../chart-components/ChartsExt/LineStringMapChart";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { CrossfilterRecord } from "../../custom";
import { SimpleTimespan } from "../../filters/SimpleDateTimeControl/SimpleTimespan";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";
import { DrawBoxInMap } from "../../ui-components/filter-view/FilterMap/DrawBoxInMap";
import { KyvLayerSwitcher } from "../../ui-components/filter-view/FilterMap/kyv-layer-switcher/KyvLayerSwitcher";
import { geoJSONFormat, wktFormat } from "../../utils/geomUtils";

type DbrdSpeedProps = any;
type DbrdSpeedState = any;

class DbrdSpeedBase extends Component<DbrdSpeedProps, DbrdSpeedState> {
  static dashboardRoute = "hastighet";

  static dashboardFilters(filter, setFilter) {
    return {
      helpMessage:
        "Seilingshastighet genererer svært mye data. Det er ment benyttet for korte tidsrom og for små områder for å identifisere enkeltskip som har gått med høy hastiget etc. I områder med høy trafikk kan man velge inntil en dag om gangen; i områder med lav trafikk kan man velge inntil en uke eller maksimalt 14 dager. Prøv deg frem. Gå tilbake og begrens utvalget hvis fremdriftsindikatoren blir stående å spinne på skjermen lenge.  Tidspunkt i dataene er angitt som UTC.",
      controls: [
        <SimpleTimespan
          key="flt"
          filter={filter}
          setFilter={setFilter}
          format="d.M.y HH:mm"
          minAge={4}
          maxDays={14}
          utc
        />,
        <DrawBoxInMap key="flt-box" filter={filter} setFilter={setFilter}>
          Tegn rektangel i kart
        </DrawBoxInMap>,
      ],
    };
  }

  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: null,
    };
  }

  static dashboardValidation(filter) {
    if (filter.fromTime && filter.toTime && filter.geom) {
      return true;
    }
  }

  static propTypes = {
    location: PropTypes.object,
  };

  fromTimeD: Date;
  toTimeD: Date;

  constructor(props) {
    super(props);

    const { fromTime, toTime } = this.props.location.state;

    this.reportProgress = this.reportProgress.bind(this);
    this.state = {
      chartData: null,
    };

    this.fromTimeD = TimeUtils.isoParse(fromTime);
    this.toTimeD = TimeUtils.isoParse(toTime);
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  componentDidMount() {
    try {
      const { geom, fromTime, toTime } = this.props.location.state;

      if (geom && fromTime && toTime) {
        let wktGeom: string | undefined = undefined;
        if (Array.isArray(geom) && geom.length === 1) {
          wktGeom = wktFormat.writeFeature(geoJSONFormat.readFeature(geom[0]));
        }

        if (!wktGeom) return;

        DataLoader.post(
          process.env.REACT_APP_DASHBOARD_WS_API +
            "/api/ais/positions/within-geom-time",
          {
            geom: wktGeom,
            start: fromTime,
            end: toTime,
            minSpeed: 0.0,
          },
          this.reportProgress
        ).then((posResponse) => {
          if (!posResponse.success) {
            return;
          }
          var positions = posResponse.data;
          positions.forEach((d: any) => {
            d[1] = TimeUtils.utcParseToScondNoTz(d[1]);
            d.timeFormat = TimeUtils.toCompactTimestring(d[1]);
          });

          var processed = PosUtils.createFeaturesFromPositions(positions);

          // If lines were created, look up ship info for the concerned MMSI ids
          if (processed.mmsiIds.length > 0) {
            DataLoader.post(
              process.env.REACT_APP_DASHBOARD_WS_API +
                "/api/ais_shipreg/statinfo/for-mmsis-time",
              {
                MmsiIds: processed.mmsiIds,
                Start: this.fromTimeD.getFullYear(),
                End: this.toTimeD.getFullYear(),
              },
              this.reportProgress
            )
              .then((jsonResponse) => {
                if (!jsonResponse.success) {
                  return;
                }

                var ships = jsonResponse.data;

                // Copy ship info onto features
                var shipMap = {};
                ships.forEach((ship) => {
                  shipMap[ship.mmsi] = ship;
                });

                processed.features.map((feat) => {
                  var ship = shipMap[feat.properties.mmsi];
                  if (ship) {
                    feat.properties.callsign = ship.callsign;
                    feat.properties.imonumber = ship.imo_num;
                    feat.properties.shipname = ship.name;
                    feat.properties.shiptypelevel5 = ship.shiptypelevel5;
                    feat.properties.grosstonnage = ship.gt;
                    feat.properties.length = ship.length;
                    feat.properties.breadth = ship.breadth;
                    feat.properties.height = ship.height;
                    feat.properties.draught = ship.draught;
                  }
                  return feat;
                });

                // Set the data for the dashboard
                this.setState({
                  chartData: crossfilter(processed.features),
                });
              })
              .catch((error) => {
                console.warn(error);
                this.setState({
                  chartData: crossfilter([]),
                });
              });
          } else {
            this.setState({
              chartData: crossfilter([]),
            });
          }
        });
      } else {
        throw new Error("Missing dashboard selection parameters");
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  render() {
    const { fromTime, toTime } = this.props.location.state;
    const { chartData, progressData } = this.state;

    if (!chartData || chartData.size() < 1) {
      return <Loader progressData={progressData} chartData={chartData} />;
    }

    var countMmsis = (reductio() as any)
      .exception((d: CrossfilterRecord) => d.properties.mmsi)
      .exceptionCount(true);

    var dimCount = chartData.dimension((f) =>
      f.properties.shipname
        ? f.properties.mmsi + " - " + f.properties.shipname
        : f.properties.mmsi + " - ukjent"
    );

    var segmentsPerShip = countMmsis(dimCount.group());

    var dimTimestamp = chartData.dimension((d) => {
      var hourOfDay = new Date(d.properties.endt);
      hourOfDay.setMinutes(0);
      hourOfDay.setSeconds(0);
      return hourOfDay;
    });

    var shipsByHourOfDay = countMmsis(dimTimestamp.group());

    var dimSpeed = chartData.dimension((f) =>
      f.properties.speed < 102.3 ? f.properties.speed : 0
    );

    var dimSpeedGroups = chartData.dimension((f) =>
      Math.floor(f.properties.speed < 102.3 ? f.properties.speed : 0)
    );

    var shipsBySpeed = countMmsis(dimSpeedGroups.group());

    var dimShipTypeGroup = chartData.dimension((f) => {
      return f.properties.shiptypelevel5
        ? f.properties.shiptypelevel5
        : "Ukjent";
    });

    var segmentsByShipTypeGroup = countMmsis(dimShipTypeGroup.group());

    var dimUniqueShips = chartData.dimension((f) => {
      return [
        f.properties.mmsi,
        f.properties.callsign,
        f.properties.imonumber,
        f.properties.shipname,
        f.properties.shiptypelevel5,
        f.properties.grosstonnage,
        f.properties.length,
        f.properties.breadth,
        f.properties.height,
        f.properties.draught,
        f.properties.trackIndex,
      ];
    });

    var uniqueShipGroup = dimUniqueShips.group();

    var uniqueShipStatsReducer = reductio() as any;

    uniqueShipStatsReducer
      .value("speed")
      .count(true)
      .sum((d) => +d.properties.speed)
      .min((d) => +d.properties.speed)
      .max(true)
      .avg(true);

    uniqueShipStatsReducer
      .value("time")
      .min((d) => new Date(d.properties.endt))
      .max(true);

    var uniqueShipStats = GroupUtils.RemoveEmptyBinsByKey(
      uniqueShipStatsReducer(uniqueShipGroup),
      "speed.count"
    );

    return (
      <div className="AppView">
        <AVIDashboard
          title="Seilingshastighet"
          desc={`Vis seilingshastighet for valgt område i tidsrommet ${TimeUtils.formatTime(
            fromTime
          )}-${TimeUtils.formatTime(toTime)}. Alle tidspunkt er angitt i UTC`}
          spacing={20}
          group={dimCount.groupAll()}
          cfilter={chartData}
          valueAccessor={(d) => d.count}
          useFlex
        >
          <AVIRow height={5}>
            <AVICol width={5}>
              <LineStringMapChart
                chartTitle="Kartvisning"
                dimension={dimSpeed}
                chartData={chartData}
                colorScheme={d3
                  .scaleLinear<string, number>()
                  .domain([0, 5, 10, 15, 20])
                  .range(["green", "green", "yellow", "red", "brown"])}
                categoryProperty="speed"
                height={2}
                width={1}
                useFlex
                allowFullscreen
              >
                <KyvLayerSwitcher top="10px" right="10px" />
              </LineStringMapChart>
            </AVICol>
            <AVICol width={1}>
              <div
                className="d-flex flex-column overflow-y-scroll flex-grow-1 kyv--scrollbar"
                style={{ flex: 1 }}
              >
                <div style={{ minHeight: 0 }}>
                  <CBoxMenu
                    chartTitle="Avgrens til skip/mmsi nummer"
                    width={1}
                    height={0.5}
                    dimension={dimCount}
                    group={segmentsPerShip}
                    multiple
                    filterPrefix="Skip"
                    valueAccessor={(d) => d.value.exceptionCount}
                    useFlex
                  />
                </div>
              </div>
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartLinear
                chartTitle="Skip etter hastighet"
                dimension={dimSpeedGroups}
                group={shipsBySpeed}
                xAxisLabel="Seilingshastighet (Knot)"
                yAxisLabel="Antall skip"
                height={1}
                filterPrefix="Hastighet"
                valueAccessor={(d) => d.value.exceptionCount}
                useFlex
              />
            </AVICol>
            <AVICol>
              <RowChart
                chartTitle="Antall skip etter skipstype"
                height={2}
                gap={1}
                dimension={dimShipTypeGroup}
                group={segmentsByShipTypeGroup}
                valueAccessor={(d) => d.value.exceptionCount}
                filterPrefix="Skipstype"
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <BarChartHour
              chartTitle="Skip time for time"
              dimension={dimTimestamp}
              group={shipsByHourOfDay}
              xAxisLabel="Tid"
              yAxisLabel="Antall skip"
              height={1}
              filterPrefix="Tidsrom"
              valueAccessor={(d) => d.value.exceptionCount}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <DataTable
              chartTitle="Liste over seilas i gjeldende utvalg"
              description="Det vises en oppføring for hvert som går gjennom eller starter/slutter innen det valgte området."
              dimension={uniqueShipStats}
              sortBy={(d) => d.value.time.max}
              height={4}
              size={Infinity}
              order={d3.ascending}
              showSortControls={false}
              useFlex
              columns={[
                {
                  label: "Start",
                  title: "Starttidspunkt",
                  format: (d) =>
                    TimeUtils.toCompactTimestring(d.value.time.min),
                  value: (d) => d.value.time.min,
                },
                {
                  label: "Slutt",
                  title: "Sluttidspunkt",
                  format: (d) =>
                    TimeUtils.toCompactTimestring(d.value.time.max),
                  value: (d) => d.value.time.max,
                },
                {
                  label: "MMSI",
                  title: "Maritime Mobile Service Identifier",
                  format: (d) => d.key[0],
                },
                {
                  label: "IMO",
                  title: "International Maritime Organization identifier",
                  format: (d) => d.key[2],
                },
                {
                  label: "Skipsnavn",
                  title: "Skipsnavn",
                  format: (d) => d.key[3],
                },
                {
                  label: "Skipstype",
                  title: "Skipstype",
                  format: (d) => d.key[4],
                },
                {
                  label: "BT",
                  title: "Bruttotonnasje",
                  format: (d) => NumUtils.integer(d.key[5]),
                  value: (d) => d.key[5],
                },
                {
                  label: "L",
                  title: "Lengde",
                  format: (d) => NumUtils.decimal2(d.key[6]),
                  value: (d) => d.key[6],
                },
                {
                  label: "B",
                  title: "Bredde",
                  format: (d) => NumUtils.decimal2(d.key[7]),
                  value: (d) => d.key[7],
                },
                {
                  label: "H",
                  title: "Høyde",
                  format: (d) => NumUtils.decimal2(d.key[8]),
                  value: (d) => d.key[8],
                },
                {
                  label: "D",
                  title: "Dyptgående",
                  format: (d) => NumUtils.decimal2(d.key[9]),
                  value: (d) => d.key[9],
                },
                {
                  label: "Min hast.",
                  format: (d) => NumUtils.decimal2(d.value.speed.min),
                  value: (d) => d.value.speed.min,
                },
                {
                  label: "Gjsn hast.",
                  format: (d) => NumUtils.decimal2(d.value.speed.avg),
                  value: (d) => d.value.speed.avg,
                },
                {
                  label: "Maks hast.",
                  format: (d) => NumUtils.decimal2(d.value.speed.max),
                  value: (d) => d.value.speed.max,
                },
                {
                  label: "Ant.",
                  title: "Antall posisjonsmeldinger i beregning",
                  format: (d) => NumUtils.integer(d.value.speed.count),
                  value: (d) => d.value.speed.count,
                },
              ]}
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdSpeed = withRouter(DbrdSpeedBase);

export default DbrdSpeed;
