import "./DbrdDepartures.scss";

import * as d3 from "d3";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { NumUtils } from "../../chart-components/ChartUtils/NumUtils";
import { TextUtils } from "../../chart-components/ChartUtils/TextUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartLinear } from "../../chart-components/Charts/BarChartLinear";
import { BarChartMonth } from "../../chart-components/Charts/BarChartMonth";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { LocationMapChart } from "../../chart-components/ChartsExt/LocationMapChart";
import { GeoJsonUtils } from "../../chart-components/GeoJsonUtils/GeoJsonUtils";
import GroupedNumberDisplayMax from "../../chart-components/GroupedNumberDisplayMax";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { CrossfilterRecord } from "../../custom";
import { FilterToFromYear } from "../../filters/FilterToFromYear";
import withRouter from "../../hocs/withRouter";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../types/DashboardFilter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";
import { SelectLocationsInMap } from "../../ui-components/filter-view/FilterMap/SelectLocationsInMap";

class DbrdDeparturesBase extends Component<any, any> {
  /**
   * The route to be used for the dashboard
   */
  static dashboardRoute = "avganger";

  /**
   * The help text and filter controls to be used as initial filtering for the dashboard
   * @param {*} filter
   * @param {*} setFilter
   */
  static dashboardFilters(
    filter: DashboardFilter,
    setFilter: SetDashboardFilterFunc
  ) {
    return {
      helpMessage:
        "Dette dashboardet gjør det mulig å analysere flere år samtidig. Velg et eller flere avgangssteder og f.eks. tidsrommet 2015-2018",
      controls: [
        <FilterToFromYear
          key="flt"
          firstYear={2008}
          filter={filter}
          setFilter={setFilter}
        />,
        <SelectLocationsInMap
          key="sel-loc"
          name="locationIds"
          returnProperty="id"
        />,
      ],
    };
  }

  /**
   * The dashboard settings, i.e. map interaction filter controls, selectable layers etc.
   */
  static dashboardSettings() {
    return {
      filterControls: [
        // DashboardConfig.filterControls.selectPoint,
        // DashboardConfig.filterControls.selectPolygon,
        // DashboardConfig.filterControls.selectBox,
        // DashboardConfig.filterControls.municipality
      ],
      // selectableLayer: selectableLayers.locationIds
    };
  }

  /**
   * The dashboard validation function
   * @param {*} filter
   */
  static dashboardValidation(filter: DashboardFilter) {
    if (
      filter.fromYear &&
      filter.toYear &&
      Array.isArray(filter.locationIds) &&
      filter.locationIds.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        toYear: PropTypes.number.isRequired,
        fromYear: PropTypes.number.isRequired,
        locationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      }),
    }),
  };

  constructor(props: any) {
    super(props);
    this.state = {
      chartData: null,
      geoJsonDestinations: null,
      geoJsonOrigins: null,
      locationInfo: null,
    };
    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    const { toYear, fromYear, locationIds } = (this.props as any).location
      .state;

    try {
      if (Array.isArray(locationIds) && toYear && fromYear) {
        var mergeRequests = [
          DataLoader.postApi(
            "/api/voyage/departures/for-locations",
            {
              FromYear: fromYear,
              ToYear: toYear,
              LocationIds: locationIds,
            },
            this.reportProgress
          ),
          DataLoader.postApi("/api/location/for-locations", {
            LocationIds: locationIds,
          }),
          DataLoader.postApi("/api/location/connections/for-locations", {
            LocationIds: locationIds,
          }),
        ];
        Promise.all(mergeRequests).then((jsonResponses) => {
          // Check that all responses are successful
          var success = true;

          jsonResponses.forEach((r) => {
            if (!r.success) {
              if (success) {
                success = false;
              }
            }
          });

          if (!success) {
            throw new Error(
              "Error loading locations, voyages and/or departures"
            );
          }

          var voyages = jsonResponses[0].data;
          var locationData = jsonResponses[1].data;
          var connectedLocations = jsonResponses[2].data;

          var departureLocMap: any[] = [];
          locationData.forEach((d: CrossfilterRecord) => {
            departureLocMap[d.locationid] = d;
          });

          voyages.forEach((d: CrossfilterRecord) => {
            d.departurelocationid = +d.departurelocationid;
            d.eta = d.eta != null ? new Date(d.eta) : "";
            d.etd = d.etd != null ? new Date(d.etd) : "";
            d.etdday = d.eta != null ? d.eta.getDay() : "";
            d.etdhours = d.eta != null ? d.eta.getHours() : "";
            d.departurelocationname =
              departureLocMap[d.departurelocationid].locationnamenor;
            d.etdstr = d.etd != null ? TimeUtils.toCompactDate(d.eta) : "";
          });

          this.setState({
            toYear: toYear,
            fromYear: fromYear,
            chartData: crossfilter(voyages),
            numRecords: voyages.length,
            locationInfo: locationData,
            geoJsonDestinations: connectedLocations,
            geoJsonOrigins: GeoJsonUtils.locationInfoToGeoJson(locationData),
          });
        });
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  reportProgress(progressData: any) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const {
      chartData,
      geoJsonDestinations,
      geoJsonOrigins,
      locationInfo,
      toYear,
      fromYear,
      progressData,
    } = this.state as any;

    if (!chartData || chartData.size() === 0) {
      return <Loader chartData={chartData} progressData={progressData} />;
    }

    var dimCount = chartData.dimension((d: CrossfilterRecord) =>
      d.mmsino ? d.mmsino : -1
    );

    var dimDraught = chartData.dimension((d: CrossfilterRecord) =>
      d.draught ? d.draught : -1
    );
    var maxDraught = dimDraught.groupAll();

    var dimLength = chartData.dimension((d: CrossfilterRecord) =>
      d.length ? d.length : -1
    );
    var maxLength = dimLength.groupAll();

    var dimBreadth = chartData.dimension((d: CrossfilterRecord) =>
      d.breadth ? d.breadth : -1
    );
    var maxBreadth = dimBreadth.groupAll();

    var dimHeight = chartData.dimension((d: CrossfilterRecord) =>
      d.height ? d.height : -1
    );
    var maxHeight = dimHeight.groupAll();

    var dimYear = chartData.dimension((d: CrossfilterRecord) => {
      return d.etd !== null ? d3.timeYear(d.etd).getFullYear() : "";
    });
    var departuresByYear = dimYear.group().reduceCount();

    var dimMonth = chartData.dimension((d: CrossfilterRecord) => {
      return d.etd !== null ? d3.timeMonth(d.etd) : "";
    });
    var departuresByMonth = dimMonth.group().reduceCount();
    var departuresByMonthFiltered =
      GroupUtils.RemoveEmptyBins(departuresByMonth);

    var dimHourOfDay = chartData.dimension(
      (d: CrossfilterRecord) => d.etdhours
    );
    var departuresByHourOfDay = dimHourOfDay.group().reduceCount();

    var dimDepartureLocations = chartData.dimension((d: CrossfilterRecord) =>
      d.departurelocationname ? d.departurelocationname : "Ukjent"
    );
    var departuresByLocation = dimDepartureLocations.group();

    var dimArrivalLocationId = chartData.dimension((d: CrossfilterRecord) =>
      d.arrivallocationid ? d.arrivallocationid : -1
    );
    var arrivalsByLocationId = dimArrivalLocationId.group();

    var dimArrivalLocationName = chartData.dimension((d: CrossfilterRecord) =>
      d.locationnamenor ? d.locationnamenor : "Ukjent"
    );
    var arrivalsByLocationName = dimArrivalLocationName.group();

    var dimWeekDay = chartData.dimension((d: CrossfilterRecord) =>
      d.etd ? TimeUtils.getLocalWeekDayAbb(d.etd) : ""
    );
    var departuresByWeekDay = dimWeekDay.group().reduceCount();

    var dimHasHazMat = chartData.dimension((d: CrossfilterRecord) =>
      d.hashazmat === "T" ? "Farlig last" : "Ordinær last"
    );
    var departuresByHazMat = dimHasHazMat.group().reduceCount();

    var dimShipCategory = chartData.dimension((d: CrossfilterRecord) =>
      d.sgroup2 ? d.sgroup2 : "Ukjent"
    );
    var departuresByShipCategory = dimShipCategory.group().reduceCount();

    var dimStatcode5 = chartData.dimension(
      (d: CrossfilterRecord) => d.shiptypenor || "Ukjent"
    );
    var deaprturesByStatcode5 = dimStatcode5.group().reduceCount();

    var dimArrivalCountry = chartData.dimension(
      (d: CrossfilterRecord) => d.countrynamenor || "Ukjent"
    );
    var departuresByArrivalCountry = dimArrivalCountry.group().reduceCount();

    var dimOriginGroups = chartData.dimension((d: CrossfilterRecord) => {
      switch (d.cc) {
        case "NO":
          return "Norge (NO)";
        case "SJ":
          return "Svalbard (SJ)";
        default:
          return "Utlandet";
      }
    });
    var departuresByOriginGroups = dimOriginGroups.group().reduceCount();

    var dimMMSI = chartData.dimension((d: CrossfilterRecord) => d.mmsino);

    var dbrdTitle = locationInfo
      .map((d: CrossfilterRecord) => {
        return d.locationnamenor + " (" + d.locationtypenor + ")";
      })
      .join(" / ");

    return (
      <div className="AppView">
        <AVIDashboard
          title="Havneinformasjon: Avganger"
          desc={
            "Avganger fra: " +
            dbrdTitle +
            " i tidsrommet " +
            fromYear +
            "-" +
            toYear
          }
          spacing={10}
          keyName="mmsino"
          type="count"
          group={dimCount.groupAll()}
          units="seilas"
          cfilter={chartData}
          filter={this.props.location.state}
          useFlex
        >
          <AVIRow>
            <GroupedNumberDisplayMax
              chartTitle="Nøkkeltall for avganger"
              shipsByDraft={maxDraught}
              maxLength={maxLength}
              maxHeight={maxHeight}
              maxBreadth={maxBreadth}
              height={0.5}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <LocationMapChart
              chartTitle="Avganger til"
              dimension={dimArrivalLocationId}
              group={arrivalsByLocationId}
              geoJson={geoJsonDestinations}
              geoJsonHighlight={geoJsonOrigins}
              useFlex
              height={1.5}
            />
            {locationInfo.length > 1 && (
              <BarChartOrdinal
                group={GroupUtils.RemoveEmptyBins(departuresByLocation)}
                dimension={dimDepartureLocations}
                chartTitle="Avganger etter avgangssted"
                useFlex
                height={1.5}
                yAxisLabel="Avganger"
                filterPrefix="Avgangssted"
                ordering={(d: CrossfilterRecord) => -d.value}
              />
            )}
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(arrivalsByLocationName, 30)}
              dimension={dimArrivalLocationName}
              chartTitle="Avganger etter destinasjon (viser inntil de 30 mest trafikkerte)"
              useFlex
              height={1}
              ordering={(d: CrossfilterRecord) => 0 - d.value}
              yAxisLabel="Destinasjon"
              filterPrefix="Destinasjon"
              rotateXAxisLabels={-15}
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              chartTitle="Avganger etter destinasjonsland (viser inntil de 30 mest traffikerte)"
              group={GroupUtils.RemoveEmptyBinsTopN(
                departuresByArrivalCountry,
                30
              )}
              dimension={dimArrivalCountry}
              useFlex
              height={1}
              ordering={(d: CrossfilterRecord) => -d.value}
              yAxisLabel="Destinasjonsland"
              filterPrefix="Destinasjonsland"
              rotateXAxisLabels={-15}
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={departuresByOriginGroups}
              dimension={dimOriginGroups}
              chartTitle="Ankomster innland/utland"
              useFlex
              height={1}
              ordering={(d: CrossfilterRecord) => -d.value}
              yAxisLabel="Innland/utland"
              filterPrefix="Innland/utland"
              rotateXAxisLabels={-15}
            />
            <BarChartOrdinal
              chartTitle="Avganger med farlig last"
              useFlex
              height={1}
              dimension={dimHasHazMat}
              group={departuresByHazMat}
              margins={[20, 20, 40, 40]}
              yAxisLabel="Avganger"
              xAxisLabel="Farlig last"
              filterPrefix="Farlig last"
            />
            <BarChartOrdinal
              chartTitle="Avganger per år"
              useFlex
              height={1}
              dimension={dimYear}
              group={departuresByYear}
              margins={[20, 20, 40, 40]}
              yAxisLabel="Avganger"
              xAxisLabel="År"
              filterPrefix="År"
            />
          </AVIRow>
          <AVIRow>
            <BarChartMonth
              group={departuresByMonthFiltered}
              dimension={dimMonth}
              chartTitle="Avganger etter måned"
              useFlex
              height={1}
              yAxisLabel="Avganger"
              xAxisLabel="Måned"
              filterPrefix="Tidsrom fra-til"
            />
            <BarChartOrdinal
              group={departuresByWeekDay}
              dimension={dimWeekDay}
              chartTitle="Avganger etter ukedag"
              useFlex
              height={1}
              yAxisLabel="Avganger"
              xAxisLabel="Ukedag"
              filterPrefix="Valgt(e) ukedag(er)"
              ordering={(d: CrossfilterRecord) =>
                TimeUtils.localeSortWeekDayAbb(d.key)
              }
            />
            <BarChartLinear
              group={departuresByHourOfDay}
              dimension={dimHourOfDay}
              chartTitle="Avganger etter time i døgnet"
              useFlex
              height={1}
              yAxisLabel="Avganger"
              xAxisLabel="Time i døgnet"
              filterPrefix="Time i døgnet"
            />
          </AVIRow>
          <AVIRow>
            <RowChart
              chartTitle="Avganger etter skipskategori"
              useFlex
              height={1.5}
              gap={1}
              dimension={dimShipCategory}
              group={departuresByShipCategory}
              filterPrefix="Skipstype"
            />
            <RowChart
              chartTitle="Avganger etter skipstype (viser de 15 største)"
              group={GroupUtils.RemoveEmptyBinsTopN(deaprturesByStatcode5, 15)}
              dimension={dimStatcode5}
              ordering={(d: CrossfilterRecord) => -d.value}
              useFlex
              height={1.5}
              yAxisLabel="Avganger"
              filterPrefix="Skipstype (Statcode5)"
            />
          </AVIRow>
          <AVIRow>
            <DataTable
              chartTitle="Liste over avganger"
              dimension={dimMMSI}
              sortBy={(d: CrossfilterRecord) => d.etd}
              width={4}
              size={Infinity}
              columns={[
                {
                  label: "Dato",
                  title: "Avgangsdato",
                  format: (d: CrossfilterRecord) => d.etdstr,
                },
                {
                  label: "MMSI",
                  title: "Maritime Mobile Service Identifier",
                  format: (d: CrossfilterRecord) => d.mmsino,
                },
                {
                  label: "IMO",
                  title: "International Maritime Organization identifier",
                  format: (d: CrossfilterRecord) => d.imono,
                },
                {
                  label: "Skipsnavn",
                  title: "Skipsnavn",
                  format: (d: CrossfilterRecord) => d.shipname,
                },
                {
                  label: "Skipstype",
                  title: "Skipstype (Statcode5)",
                  format: (d: CrossfilterRecord) => d.shiptypenor,
                },
                {
                  label: "BT",
                  title: "Bruttotonnasje",
                  format: (d: CrossfilterRecord) =>
                    NumUtils.integer(d.grosstonnage),
                  value: (d: CrossfilterRecord) => d.grosstonnage,
                },
                {
                  label: "DWT",
                  title: "Dødvektstonnasje",
                  format: (d: CrossfilterRecord) => NumUtils.integer(d.dwt),
                  value: (d: CrossfilterRecord) => d.dwt,
                },
                {
                  label: "L",
                  title: "Lengde",
                  format: (d: CrossfilterRecord) => NumUtils.decimal2(d.length),
                  value: (d: CrossfilterRecord) => d.length,
                },
                {
                  label: "B",
                  title: "Bredde",
                  format: (d: CrossfilterRecord) =>
                    NumUtils.decimal2(d.breadth),
                  value: (d: CrossfilterRecord) => d.breadth,
                },
                {
                  label: "H",
                  title: "Høyde",
                  format: (d: CrossfilterRecord) => NumUtils.decimal2(d.height),
                  value: (d: CrossfilterRecord) => d.height,
                },
                {
                  label: "D",
                  title: "Dyptgående",
                  format: (d: CrossfilterRecord) =>
                    NumUtils.decimal2(d.draught),
                  value: (d: CrossfilterRecord) => d.draught,
                },
                {
                  label: "HazMat",
                  title: "Farlig gods",
                  format: (d: CrossfilterRecord) =>
                    d.hashazmat === "T" ? "JA" : "NEI",
                },
                {
                  label: "Fra",
                  title: "Avgang fra (avgangssted)",
                  format: (d: CrossfilterRecord) => `${d.ln2} (${d.cc2})`,
                },
                {
                  label: "LC",
                  title: "Fra LOCODE",
                  format: (d: CrossfilterRecord) => d.lc2,
                  value: (d: CrossfilterRecord) => d.lc2,
                },
                {
                  label: "ID",
                  title: "Fra lokasjonsid (hele kommer med ved nedlasting)",
                  format: (d: CrossfilterRecord) =>
                    TextUtils.shorten(d.lid2, 3),
                  value: (d: CrossfilterRecord) => d.lid2,
                },
                {
                  label: "Til",
                  title: "Avgang til (ankomststed)",
                  format: (d: CrossfilterRecord) =>
                    `${d.locationnamenor} (${d.cc})`,
                },
                {
                  label: "ID",
                  title: "Til lokasjonsid (hele kommer med ved nedlasting)",
                  format: (d: CrossfilterRecord) =>
                    TextUtils.shorten(d.locationid, 3),
                  value: (d: CrossfilterRecord) => d.locationid,
                },
                {
                  label: "LC",
                  title: "Til LOCODE",
                  format: (d: CrossfilterRecord) => d.locationcode,
                  value: (d: CrossfilterRecord) => d.locationcode,
                },
              ]}
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdDepartures = withRouter(DbrdDeparturesBase);

export default DbrdDepartures;
