import "./AVIRow.scss";
import React, { PropsWithChildren } from "react";

export type AVIRowProps = PropsWithChildren & {
  style?: any;
  height?: number | "auto";
  className?: string;
};

export function AVIRow({
  children,
  className = "",
  height = "auto",
  style = {},
}: AVIRowProps) {
  if (typeof height === "string" && height === "auto") {
    style.height = "auto";
  } else if (typeof height === "number" && height > 0) {
    style.height = `${height * 33}vh`;
    style.minHeight = "33vh";
  } else {
    style.height = `33vh`;
    style.minHeight = "33vh";
  }

  return (
    <div className={`avi-row my-3 ${className}`} style={style}>
      {children}
    </div>
  );
}
