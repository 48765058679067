import { isSomething } from "../isSomething";

export function prefixIfExits(
  v: string | number | undefined,
  prefix: string,
  suffix?: string
): string {
  if (!isSomething(v)) return "";
  v = prefix + v;
  if (isSomething(suffix)) {
    v += suffix;
  }
  return v;
}
