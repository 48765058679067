import "dc/dist/style/dc.min.css";
import React from "react";
import reductio from "../../utils/reductio-proxy";
import "../AVIChartCommon.scss";
import "./AVIDashboard.scss";

import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { formatDefaultLocale } from "d3-format";
import * as dc from "dc";
import { ArticlePopup } from "../../ui-components/ArticlePopup/ArticlePopup";
import { NumUtils } from "../ChartUtils/NumUtils";
import { TimeUtils } from "../ChartUtils/TimeUtils";
// import { BaseMixin } from "../Mixins/BaseMixin";
import { Badge, Button, Container } from "react-bootstrap";
import { DashboardFilter } from "../../types/DashboardFilter";
import { isArrayN } from "../../utils/array/isArrayN";
import { AVIColorScales } from "../AVIColorScales";
import { AVIDashboardDimContext } from "./AVIDashboardDimContext";
import { AVIDashboardHeader } from "./AVIDashboardHeader";

export type DcHtmlTemplate = {
  some: string;
  one: string;
  none: string;
};

export type AVIDashboardProps = PropsWithChildren & {
  title: string;
  useFlex: boolean;
  cmsSlug?: string;
  keyName?: string;
  location?: any;
  spacing?: number;
  colorScheme?: string[];
  desc?: string;
  group?: any;
  units?: string;
  type?: "sum" | "count";
  formatNumber?: (n: number) => string;
  valueAccessor?: (d: any) => number;
  html?: DcHtmlTemplate;
  filter?: DashboardFilter;
  cfilter?: any;
};

export function AVIDashboard({
  useFlex = true,
  cmsSlug = "",
  location,
  title,
  desc = "",
  keyName = undefined,
  spacing = 20,
  colorScheme = AVIColorScales.KyvColorScheme,
  group = undefined,
  type = undefined,
  formatNumber = NumUtils.integer,
  html = {
    some: "%number",
    one: "%number",
    none: "-",
  },
  filter,
  units = "rader",
  valueAccessor,
  cfilter = undefined,
  children,
}: AVIDashboardProps) {
  const [widthHeight, setWidtheight] = useState<{
    width: number;
    height: number;
  }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [showArticle, setShowArticle] = useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<any[]>([]);
  const [, setNumRecords] = useState<number>(
    cfilter ? cfilter.size() : 0
  );
  // const chartRef = useRef<HTMLDivElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);

  const onUpdateFilter = useCallback(() => {
    const _activeFilters: any[] = [];

    dc.chartRegistry.list().forEach((_chart: any, _chartIdx: number) => {
      if (typeof _chart.hasFilter === "function" && _chart.hasFilter()) {
        var filters = _chart.filters();
        filters = filters.map((_chartFilter: any, _chartFilterIdx: number) => {
          if (
            _chartFilter.filterType === "RangedFilter" &&
            _chartFilter.length > 0
          ) {
            var newRangeFilter: any[] = [];
            for (
              var filterPart = 0;
              filterPart < _chartFilter.length;
              filterPart++
            ) {
              if (TimeUtils.isDate(_chartFilter[filterPart])) {
                var parsedDate = new Date(_chartFilter[filterPart]);
                var options: any = {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                };
                newRangeFilter.push(
                  parsedDate.toLocaleString("nb-NO", options)
                );
              } else {
                newRangeFilter.push(_chartFilter[filterPart]);
              }
            }
            return newRangeFilter.join(" - ");
          }
          return _chartFilter;
        });
        _activeFilters.push(
          <Badge
            bg=""
            className="rounded-0 kyv--fs-dash-body fw-normal kyv--filter-badge me-2 px-2 py-0"
            key={`filter-idx-${_chartIdx}-${_chart.filterPrefix}`}
          >
            <span className="kyv--fs-dash-body">{`${_chart.filterPrefix
              } (${filters.join(", ")})`}</span>
          </Badge>
        );
      }
    });
    setActiveFilters(_activeFilters);
  }, []);

  const clearAllFilters = useCallback(() => {
    var charts = dc.chartRegistry.list();
    for (var i = 0; i < charts.length; ++i) {
      if (typeof charts[i].filterAll === "function") {
        charts[i].filterAll();
      }
    }
    dc.redrawAll();
  }, []);

  const updateWindowDimensions = useCallback(() => {
    setWidtheight({
      width: window.innerHeight,
      height: window.innerHeight,
    });
  }, []);

  var dim = useMemo(
    () => ({
      width: widthHeight.width,
      height: widthHeight.height,
      spacing: spacing,
    }),
    [spacing, widthHeight]
  );

  var aviDashboardStyle = useMemo(() => {
    let style = {};
    if (useFlex) {
      style = {
        display: "flex",
        flexDirection: "row",
        gap: "20px",
      };
    }
    return style;
  }, [useFlex]);

  const counterValue = useMemo(() => {
    if (type === "count") {
      let calcData = reductio().count(true);
      calcData(group);
      return group.value().count;
    } else if (type === "sum" && keyName) {
      let calcData = reductio().sum((d: any) => d[keyName]);
      calcData(group);
      return group.value().sum;
    }
  }, [group, keyName, type]);

  useEffect(() => {
    dc.config.defaultColors(colorScheme);
    formatDefaultLocale(NumUtils.defaultLocaleJson as any);

    return () => {
    };
  }, [
    cfilter,
    colorScheme,
    formatNumber,
    group,
    html,
    keyName,
    onUpdateFilter,
    type,
    updateWindowDimensions,
    valueAccessor,
  ]);

  useEffect(() => {
    let removeCrossfilterListener: null | Function = null;
    if (cfilter) {
      removeCrossfilterListener = cfilter.onChange((a: any) => {
        if (a === "filtered") {
          setNumRecords(cfilter.allFiltered().length);
          onUpdateFilter();
        }
      });
    }
    return () => {
      if (typeof removeCrossfilterListener === "function") {
        removeCrossfilterListener();
      }
    };
  }, [cfilter, onUpdateFilter]);

  return (
    <AVIDashboardDimContext.Provider value={{ dims: dim }}>
      <div
        ref={componentRef}
        className={[
          "avi-dashboard",
          location && location.state ? location.state.dashboard : "",
        ].join(" ")}
        style={aviDashboardStyle}
      >
        <AVIDashboardHeader
          title={title}
          description={desc}
          data={group && "all" in group ? group.all() : []}
          filter={filter!}
        />
        {/* {cmsSlug && (
              <span>
                <button
                  className="no-print"
                  onClick={(e) => setShowArticle(true)}
                >
                  Les mer...
                </button>{" "}
              </span>
            )} */}
        <div className="d-flex gap-2 px-4 w-100">
          {cfilter && counterValue && (
            <div>
              {`Viser ${counterValue}
              ${units}`}
            </div>
          )}
          {isArrayN(activeFilters, 1) && <div>{activeFilters}</div>}
          {isArrayN(activeFilters, 1) && (
            <Button
              variant="link"
              size="sm"
              className="rounded-0 kyv--fs-body py-0"
              onClick={clearAllFilters}
            >
              Nullstill filter
            </Button>
          )}
        </div>
        <Container fluid className="px-4 mt-3">
          {children}
        </Container>
        {cmsSlug && (
          <ArticlePopup
            cmsSlug={cmsSlug}
            visible={showArticle}
            onClose={() => setShowArticle(false)}
          />
        )}
      </div>
    </AVIDashboardDimContext.Provider>
  );
}
