import "./DataCatalogView.scss";

import lunr from "lunr";
import React, { useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, Container, Form, FormControl, InputGroup } from "react-bootstrap";
import InputGroupText from "react-bootstrap/esm/InputGroupText";
import { isArrayN } from "../../utils/array/isArrayN";
import { KyvMetadataRecord, readMetadata } from "../../utils/kystinfo";
import { DataCatalogTile } from "./DataCatalogTile";

type ListMode = "grid" | "list"

export function DataCatalogView() {

  const [records, setRecords] = useState<KyvMetadataRecord[]>([]);
  const [queryMatches, setQueryMatches] = useState<KyvMetadataRecord[]>([]);
  const [query, setQuery] = useState<string>("");
  const [resourceTypes, setResourceTypes] = useState<string[]>([]);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [organisations, setOrganisations] = useState<string[]>([]);
  const [listMode, setListMode] = useState<ListMode>("grid");

  const lunrIndex = useMemo(() => {
    return lunr(function () {
      this.field('title')
      this.field('abstract')
      this.field('contact_organisation')
      this.field('keywords')
      this.field('resource_type')

      records.forEach(function (doc) {
        this.add(doc)
      }, this)
    })

  }, [records]);

  useEffect(() => {
    const load_data = async () => {
      const res = await Promise.all([
        readMetadata("dataset"),
        readMetadata("service"),
        readMetadata("application"),
      ]);

      if (isArrayN(res, 3)) {
        const _records: any[] = [];
        // Add records to common array
        let id = 1;
        res.filter(r => r !== undefined).forEach(resRecs => {
          resRecs.forEach(resRec => {
            resRec.id = id;
            id++;
          })
          _records.push(...resRecs)
        })
        setResourceTypes([...new Set(_records.map(r => r.resource_type))]);
        setOrganisations([...new Set(_records.map(r => r.contact_organisation))]);
        setRecords(_records);
      }
    }

    load_data();

    return () => {
    }
  }, []);

  useEffect(() => {
    const hits = lunrIndex.search(query);
    const ids = hits.map(hit => +hit.ref);
    const matches: KyvMetadataRecord[] = [];
    ids.forEach(id => {
      matches.push(records.find(rec => rec.id === id)!);
    })
    setQueryMatches(matches);
    return () => {
    }
  }, [query, lunrIndex, records]);

  return (
    <>
      <section role="heading" aria-level={2}>
        <Container className="px-5 mb-5 mx-auto text-center">
          <div className="display-1 mb-3">Datakatalog</div>
          <div className="display-3">Hvordan kan vi hjelpe deg?</div>
        </Container>
      </section>
      <section role="form">
        <Container className="px-5 mb-5">
          <Form>
            <InputGroup>
              <InputGroupText><i className="bi bi-search" /></InputGroupText>
              <FormControl value={query} onChange={(evt) => setQuery(evt.target.value)} />
              <Button className="bg-white text-dark border-1 border-secondary"><i className="bi bi-x-lg" /></Button>
            </InputGroup>
          </Form>
        </Container>
      </section>
      <section role="navigation">
        <Container className="px-5 mb-5">
          <div className="d-flex">
            <ButtonGroup size="sm" className="ms-auto me-3">
              <Button variant="secondary" onClick={() => setListMode("grid")} active={listMode === "grid"}>Grid</Button>
              <Button variant="secondary" onClick={() => setListMode("list")} active={listMode === "grid"}>List</Button>
            </ButtonGroup>
          </div>
        </Container>
      </section>
      <section role="grid">
        <Container className="mb-5 px-5">
          {listMode === "grid" && <div className="d-flex flex-wrap gap-0">
            {isArrayN(queryMatches, 0, true) && records.map((rec, i) => {
              return <DataCatalogTile rec={rec} key={`${rec.resource_type}-${i}`} />
            })}
            {isArrayN(queryMatches, 1) && queryMatches.map((rec, i) => {
              return <DataCatalogTile rec={rec} key={`${rec.resource_type}-${i}`} />
            })}
          </div>}
        </Container>
      </section>
      {/* <Disclaimer /> */}
    </>
  );

}