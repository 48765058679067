import React, { PropsWithChildren } from "react";
import { Table } from "react-bootstrap";

export function CruiseMappingTable({ children }: PropsWithChildren) {
    return <Table striped>
        <thead>
            <tr>
                <th>Id</th>
                <th>Kilde</th>
                <th>Mål</th>
                <th>Etikett</th>
                <th>Valg</th>
            </tr>
        </thead>
        <tbody>
            {children}
        </tbody>
    </Table>
}