import React from "react"
import { FormControl, FormControlProps } from "react-bootstrap"
import { NsrLocationProperties } from "../../types/NsrLocation"

type LocationSelectProps = FormControlProps & {
    options: NsrLocationProperties[]
}

export function LocationSelect({ options, ...formControlProps }: LocationSelectProps) {
    return <FormControl as="select" {...formControlProps}>
        <option value="">Select an option</option>
        {options.map((o: NsrLocationProperties, i) => <option key={`option-${o.name}-${i}`} value={o.id}>{o.name}, {o.country} ({o.type})</option>)}
    </FormControl>
}