
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../auth-components/AuthLib";
import { DataLoader } from "../ui-components/DataLoader/DataLoader";

function KyvAnalytics({ ttl = 15 }) {
  /**
   * Current document location
   */
  const location = useLocation();

  /**
   * Get authentication state
   */
  const { authState } = useAuthContext();

  const { gm_session_id } = authState;

  /**
   * Get geolocation promise
   */
  // eslint-disable-next-line no-unused-vars
  const loc = useCallback(() => {
    return new Promise(function _getLoc(resolve, reject) {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          function _handlePos(pos) {
            resolve(pos);
          },
          function _handleError(err) {
            reject(err);
          }
        );
      } else {
        reject(new Error("geolocation API not available"));
      }
    });
  }, []);

  /**
   * Log an entry
   */
  const log = useCallback(() => {
    var userRole = "unknown";

    // Get user role
    if (Array.isArray(authState.roles)) {
      if (authState.roles.indexOf("Kystdatahuset_utviklere") > -1) {
        userRole = "Kystdatahuset_utviklere";
      } else if (authState.roles.indexOf("Kystdatahuset_arbeidsgruppe") > -1) {
        userRole = "Kystdatahuset_arbeidsgruppe";
      } else if (
        authState.roles.indexOf("Kystdatahuset_kystverket_intern") > -1
      ) {
        userRole = "Kystdatahuset_kystverket_intern";
      } else if (
        authState.roles.indexOf("Kystdatahuset_ekstern_forvaltning") > -1
      ) {
        userRole = "Kystdatahuset_ekstern_forvaltning";
      } else if (authState.roles.indexOf("Kystdatahuset_ekstern_alle") > -1) {
        userRole = "Kystdatahuset_ekstern_alle";
      } else if (authState.roles.indexOf("Kystverket_intern") > -1) {
        userRole = "Kystverket_intern";
      } else if (authState.roles.indexOf("Kystverket_ekstern") > -1) {
        userRole = "Kystverket_ekstern";
      }
    }

    // Language
    let userLang = navigator.languages
      ? navigator.languages[0]
      : navigator.language || navigator.userLanguage;

    let analyticsData = {
      url: location ? window.location.href : "",
      path: location ? location.pathname : "",      
      role: userRole,
      lang: userLang,
      sess: gm_session_id,
      uuid: crypto.randomUUID()
    };
        
    DataLoader.writeAnalytics(analyticsData);
  }, [authState.roles, location, gm_session_id]);

  useEffect(() => {
    log();
  }, [log, location]);

  return null;
}

export default KyvAnalytics;
