/* eslint-disable react/no-unused-prop-types */

import '../AVIChartCommon.scss';

import * as d3 from 'd3';

import React, { Component } from 'react';

import { AVIChartTile } from '../Layout/AVIChartTile';
import { BaseMixin } from '../Mixins/BaseMixin';
import { CapMixin } from '../Mixins/CapMixin';
import { ColorMixin } from '../Mixins/ColorMixin';
import { CoordinateGridMixin } from '../Mixins/CoordinateGridMixin';
import { MarginsMixin } from '../Mixins/MarginsMixin';
import PropTypes from 'prop-types';
import { TileMixin } from '../Mixins/TileMixin';
import { TimeUtils } from './../ChartUtils/TimeUtils';
import * as dc from 'dc';

export class SeriesChart extends Component {
  static propTypes = {
    chartTitle: PropTypes.string.isRequired,
    group: PropTypes.object.isRequired,
    dimension: PropTypes.object.isRequired,
    yAxisLabel: PropTypes.string,
    xAxisLabel: PropTypes.string,
    renderLabel: PropTypes.bool,
    rotateXAxisLabels: PropTypes.number,
    clipPadding: PropTypes.number,
    margins: PropTypes.object,
    x: PropTypes.func,
    width: PropTypes.number,
    height: PropTypes.number,
    brushOn: PropTypes.bool,
    onFiltered: PropTypes.func,
    xAxisPadding: PropTypes.number,
    showMapWkt: PropTypes.string,
    showResetFilter: PropTypes.bool,
    xAxisTickFormat: PropTypes.func,
    yAxisTickFormat: PropTypes.func,
    useFlex: PropTypes.bool
  };

  static defaultProps = {
    chartTitle: '',
    x: d3.scaleLinear().domain([1, 20]),
    margins: { top: 40, left: 40, bottom: 50 },
    renderLabel: true,
    clipPadding: 10,
    yAxisLabel: '',
    xAxisLabel: '',
    brushOn: false,
    rotateXAxisLabels: 0,
    height: 1,
    onFiltered: undefined,
    xAxisPadding: 0,
    yAxisTickFormat: v => Math.ceil(v) === v ? v : undefined,
    xAxisTickFormat: v => {
      return TimeUtils.formatDateInYear(v);
    },
    useFlex: false
  };

  constructor (props) {
    super(props);
    this.chartRef = React.createRef();
    this.chart = null;
    this.getChart = this.getChart.bind(this);
  }

  getChart () {
    return this.chart;
  }

  componentDidMount () {
    const {
      dimension,
      group,
      x,
      yAxisLabel,
      xAxisLabel,
      xAxisPadding,
      rotateXAxisLabels,
      onFiltered,
      brushOn,
      clipPadding,
      renderLabel,
      xAxisTickFormat,
      yAxisTickFormat
    } = this.props;

    this.chart = dc.seriesChart(this.chartRef.current);

    BaseMixin(this.chart, this.props);
    CapMixin(this.chart, this.props);
    ColorMixin(this.chart, this.props);
    MarginsMixin(this.chart, this.props);
    CoordinateGridMixin(this.chart, this.props);
    TileMixin(this.chart, this.props);

    this.chart
      .chart(function (c) {
        return new dc.LineChart(c).curve(d3.curveCardinal.tension(0.5));
      })
      .x(x)
      .brushOn(brushOn)
      .yAxisLabel(yAxisLabel)
      .xAxisLabel(xAxisLabel)
      .xAxisPadding(xAxisPadding)
      .clipPadding(clipPadding)
      .elasticY(true)
      .dimension(dimension)
      .group(group)
      .mouseZoomable(false)
      .seriesAccessor(d => '' + d.key[0])
      .keyAccessor(d => d.key[1])
      .valueAccessor(d => +d.value)
      .renderLabel(renderLabel);

    this.chart.legend(
      dc
        .legend()
        .x(40)
        .y(10)
        .itemHeight(13)
        .gap(5)
        .horizontal(1)
    );

    if (typeof xAxisTickFormat === 'function') {
      this.chart.xAxis().tickFormat(xAxisTickFormat);
    }

    if (typeof yAxisTickFormat === 'function') {
      this.chart.yAxis().tickFormat(yAxisTickFormat);
    }

    if (rotateXAxisLabels !== 0) {
      this.chart.on('renderlet', (chart, filter) => {
        chart
          .selectAll('g.x text')
          .attr('transform', `translate(-10,10) rotate(${rotateXAxisLabels})`);
      });
    }

    if (onFiltered && typeof onFiltered === 'function') {
      this.chart.on('filtered', onFiltered);
    }

    this.chart.render();
  }

  render () {
    return (
      <AVIChartTile
        title={this.props.chartTitle}
        getChart={this.getChart}
        width={this.props.width}
        height={this.props.height}
        showMapWkt={this.props.showMapWkt}
        showResetFilter={this.props.showResetFilter}
      >
        <div className='avi-chart' ref={this.chartRef} />
      </AVIChartTile>
    );
  }
}
