import "../AVIChartCommon.scss";
import "./AVIDataCountTileFlex.scss";

import PropTypes from "prop-types";
import React from "react";
import { AVIDashboardDimContext } from "./AVIDashboardDimContext";

class AVIDataCountTileFlexBase extends React.Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    children: PropTypes.any,
    dims: PropTypes.object,
    title: PropTypes.string,
  };

  static defaultProps = {
    width: 1,
    height: 1,
  };

  render() {
    const { children, title, width, height } = this.props;

    const aviDataCountTileStyle = {
      flex: width,
      height: `${height * 25}vh`,
      minHeight: "20vh"
    };

    const aviDataCountContainerStyle = {
      width: "100%",
    };

    return (
      <div className="" style={aviDataCountTileStyle}>
        <div className="px-2 mb-2 kyv--fs-body kyv--text-header">{title}</div>
        <div
          className="d-flex gap-2"
          style={aviDataCountContainerStyle}
        >
          {children}
        </div>
      </div>
    );
  }
}

export const AVIDataCountTileFlex = function (props) {
  return (
    <AVIDashboardDimContext.Consumer>
      {(dims) => <AVIDataCountTileFlexBase {...props} dims={dims} />}
    </AVIDashboardDimContext.Consumer>
  );
};

export default AVIDataCountTileFlex;
