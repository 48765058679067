import { Map } from "ol";
import React, { PropsWithChildren, createContext, useContext } from "react";
type MapContextType = {
  map: Map;
};

const MapContext = createContext<MapContextType | undefined>(undefined);

export type MapProviderProps = PropsWithChildren & {
  map: Map;
};

export function MapProvider({ map, children = null }: MapProviderProps) {
  return (
    <MapContext.Provider
      value={{
        map: map,
      }}
    >
      {children}
    </MapContext.Provider>
  );
}

export function useMap() {
  const ctx = useContext(MapContext);
  if (!ctx)
    throw Error(
      "MapProvider context is empty, useMap can only be used within a MapProvider element"
    );
  return ctx;
}
