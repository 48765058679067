/* eslint-disable camelcase */
import "./ReplayRealtimeMapChart.scss";

import * as Control from "ol/control";
import * as Extent from "ol/extent";
import * as Interaction from "ol/interaction";
import * as Proj from "ol/proj";

import React, { Component } from "react";

import * as d3 from "d3";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import PointGeom from "ol/geom/Point";
import VectorLayer from "ol/layer/Vector";
import Map from "ol/Map";
import Overlay from "ol/Overlay";
import Cluster from "ol/source/Cluster";
import VectorSource from "ol/source/Vector";
import CircleStyle from "ol/style/Circle";
import FillStyle from "ol/style/Fill";
import IconStyle from "ol/style/Icon";
import StrokeStyle from "ol/style/Stroke";
import Style from "ol/style/Style";
import TextStyle from "ol/style/Text";
import View from "ol/View";
import PropTypes from "prop-types";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { PosUtils } from "../ChartUtils/PosUtils";
import { AVIMapTile } from "../Layout/AVIMapTile";
import { LayerSwitcherMixin } from "../Mixins/LayerSwitcherMixin";
import BoatWhite from "./boat-white.svg";

export class ReplayRealtimeMapChart extends Component {
  static propTypes = {
    chartTitle: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    useFlex: PropTypes.bool.isRequired,
    featuresCrossfilter: PropTypes.object.isRequired,
  };

  static defaultProps = {
    useFlex: false,
  };

  constructor(props) {
    super(props);
    this.vectorSource = null;
    this.vectorLayer = null;
    this.shipTrackLayer = null;
    this.lineStringSource = null;
    this.pointSource = null;
    this.clusterSource = null;
    this.removeOnChange = null;

    // Color scale for ships
    this.colorScale = d3
      .scaleOrdinal(d3.schemeCategory10)
      .domain([
        "Fiskefartøy", // Blå
        "Offshorefartøy og spesialfartøy", //Raud-orange
        "Passasjerskip", // Brunt
        "Lasteskip", // Grøn
        "Tankskip", // Gråsvart
        "Fritidsfartøy", // Rosa
        "Ukjent", // Gråblå
      ])
      .range([
        "#2E86C1",
        "#F39C12",
        "#A04000",
        "#229954",
        "#212F3D",
        "#F1948A",
        "#85929E",
      ]);

    // Scale for cluster sizes
    this.clusterSizeScale = d3
      .scaleLog()
      .domain([2, 100])
      .rangeRound([8, 16])
      .clamp(true);

    // Scale for cluster colours
    this.clusterColorScale = d3
      .scaleLinear(d3.interpolateRgb.gamma(0))
      .domain([2, 25, 50, 500])
      .range(["#6497B1", "#035B96", "#04396C", "#031F4B"])
      .clamp(true);

    this.map = null;
    this.chartRef = React.createRef();
    this.popupRef = React.createRef();
    this.popupContentRef = React.createRef();
    this.getMap = this.getMap.bind(this);
    this.getShipPointStyle = this.getShipPointStyle.bind(this);
    this.getShipTrackStyle = this.getShipTrackStyle.bind(this);
    this.getAnnotationStyle = this.getAnnotationStyle.bind(this);
    this.getShipClusterPointStyle = this.getShipClusterPointStyle.bind(this);
    this.loadFeatures = this.loadFeatures.bind(this);
    this.styleCache = {};
    this.geoJsonFmt = new GeoJSON();
  }

  getMap() {
    return this.map;
  }

  iconScale(zoom) {
    if (zoom > 1000) {
      return 0.25;
    } else if (zoom > 500) {
      return 0.5;
    } else if (zoom > 250) {
      return 0.75;
    } else {
      return 1;
    }
  }

  getShipPointStyle(feature, zoom) {
    return [
      new Style({
        image: new IconStyle({
          color: this.colorScale(feature.get("ship_type_label")),
          anchor: [0.5, 0.5],
          anchorXUnits: "fraction",
          anchorYUnits: "fraction",
          scale: 0.1 * this.iconScale(zoom),
          size: [104, 304],
          src: BoatWhite,
          rotation: PosUtils.toRadians(feature.getProperties()["cog"]),
        }),
      }),
    ];
  }

  getShipTrackStyle(feature, zoom) {
    return [
      new Style({
        stroke: new StrokeStyle({
          color: "#00f",
          width: zoom < 100 ? 2 : 1,
        }),
      }),
    ];
  }

  eif(exp, str) {
    if (exp) {
      return str;
    } else {
      return "";
    }
  }

  getAnnotationStyle(feature, zoom) {
    let size = feature.get("features").length;
    if (size !== 1) {
      return;
    }
    let feat = feature.get("features")[0];
    let labelPoint = new PointGeom(
      feat.getGeometry().getLastCoordinate().slice(0, 2)
    );

    var { mmsi, ship_name } = feat.getProperties();

    var label = "";
    var offsetY = 0;
    label = `MMSI: ${mmsi}\nNavn: ${ship_name}`;
    offsetY = -20;

    return [
      new Style({
        geometry: labelPoint,
        text: new TextStyle({
          text: label,
          justify: "left",
          font: "8pt Calibri,sans-serif",
          offsetY: offsetY,
          padding: [10, 10, 10, 10],
          fill: new FillStyle({
            color: "#000",
          }),
          backgroundFill: new FillStyle({
            color: "rgba(255, 255, 255, 0.1)",
          }),
        }),
      }),
    ];
  }

  getShipClusterPointStyle(feature, zoom) {
    const size = feature.get("features").length;
    if (size > 1) {
      let style = this.styleCache[size];
      if (!style) {
        style = new Style({
          image: new CircleStyle({
            radius: this.clusterSizeScale(size),
            stroke: new StrokeStyle({
              color: "#fff",
            }),
            fill: new FillStyle({
              color: this.clusterColorScale(size),
            }),
          }),
          text: new TextStyle({
            text: size.toString(),
            fill: new FillStyle({
              color: "#fff",
            }),
          }),
        });
        this.styleCache[size] = style;
      }
      return style;
    } else if (size === 1) {
      return this.getShipPointStyle(feature.get("features")[0], zoom);
    }
  }

  componentDidMount() {
    const { featuresCrossfilter } = this.props;

    this.removeOnChange = featuresCrossfilter.onChange((state) => {
      if (state === "dataAdded" || state === "filtered") {
        this.loadFeatures();
      }
    });

    var zoomPt = [11, 60];

    this.map = new Map({
      target: this.chartRef.current,
      interactions: Interaction.defaults({ mouseWheelZoom: true }),
      controls: Control.defaults({
        attributionOptions: {
          collapsible: false,
        },
      }),
      view: new View({
        center: Proj.transform(
          [zoomPt[0], zoomPt[1]],
          "EPSG:4326",
          "EPSG:3857"
        ),
        zoom: 6,
      }),
    });

    var extent = new Extent.boundingExtent([
      [-0.206, 57.477],
      [36.155, 71.897],
    ]);

    extent = Proj.transformExtent(extent, "EPSG:4326", "EPSG:3857");
    this.map.getView().fit(extent, this.map.getSize());

    // Create sources
    this.lineStringSource = new VectorSource({
      format: this.geoJsonFmt,
    });

    this.pointSource = new VectorSource({
      format: this.geoJsonFmt,
    });

    this.clusterSource = new Cluster({
      source: this.lineStringSource,
      geometryFunction: (lineStringFeat) =>
        new PointGeom(
          lineStringFeat.getGeometry().getLastCoordinate().slice(0, 2)
        ),
      distance: 20,
      minDistance: 25,
    });

    // Create layers
    this.shipTrackLayer = new VectorLayer({
      minZoom: 10,
      source: this.lineStringSource,
      style: this.getShipTrackStyle,
    });

    this.shipClusterLayer = new VectorLayer({
      source: this.clusterSource,
      style: this.getShipClusterPointStyle,
    });

    this.annotationLayer = new VectorLayer({
      minZoom: 9,
      source: this.clusterSource,
      style: this.getAnnotationStyle,
      declutter: true,
    });

    // Set layer index
    this.shipTrackLayer.setZIndex(1005);
    this.shipClusterLayer.setZIndex(1010);
    this.annotationLayer.setZIndex(1008);

    // Add layers to map
    this.map.addLayer(this.shipTrackLayer);
    this.map.addLayer(this.shipClusterLayer);
    this.map.addLayer(this.annotationLayer);

    // Add popup overlay
    this.popupOverlay = new Overlay({
      element: this.popupRef.current,
      offset: [9, 9],
    });

    this.map.addOverlay(this.popupOverlay);
    // Add popover behavior
    this.map.on("pointermove", (event) => {
      let features = [];
      this.map.forEachFeatureAtPixel(
        event.pixel,
        (feature, layer) => {
          features = feature.get("features");
          this.popupContentRef.current.innerHTML = "";
          const featuresToShow = [];
          if (features && features.length > 0) {
            features.forEach((clusterFeature) => {
              featuresToShow.push(clusterFeature);
            });
            const MAX_FEATURES = 3;
            featuresToShow.slice(0, MAX_FEATURES).forEach((f, i) => {
              this.popupContentRef.current.innerHTML += `<div class="avi-map-popup-content-html">
                  <h4>MMSI: ${f.get("mmsi")}</h4>
                  <p>Navn: ${f.get("ship_name")}</p>
                  <p>Type: ${f.get("ship_type_label")}</p>
                  ${this.eif(
                    f.get("length") && f.get("length") > 0,
                    `<p>Lengde: ${f.get("length")}</p>`
                  )}
                  ${this.eif(
                    f.get("destination"),
                    `<p>Destinasjon: ${f.get("destination")}</p>`
                  )}
                  ${this.eif(
                    f.get("speed"),
                    `<p>Fart: ${f.get("speed")} knots</p>`
                  )}
                  <p>Sist oppdatert: ${TimeUtils.toCompactTimeOnlystring(
                    new Date(f.get("date_time_utc"))
                  )}</p>
                  </div>`;
            });

            if (featuresToShow.length > MAX_FEATURES) {
              this.popupContentRef.current.innerHTML += `<div class="avi-map-popup-content-html">
              <p>og ${featuresToShow.length - MAX_FEATURES} til...</p>
            </div>`;
            }

            this.popupOverlay.setPosition(event.coordinate);
          }
        },
        {
          layerFilter: (layer) => {
            return layer.type === new VectorLayer().type ? true : false;
          },
          hitTolerance: 5,
        }
      );
      if (!features || features.length === 0) {
        this.popupContentRef.current.innerHTML = "";
        this.popupOverlay.setPosition(undefined);
      }
    });

    // Add standard layers
    LayerSwitcherMixin(this.map);

    // Initial load
    this.loadFeatures();
  }

  componentDidUpdate(oldProps, newProps) {
    this.loadFeatures();
  }

  loadFeatures() {
    const { featuresCrossfilter } = this.props;

    if (featuresCrossfilter.allFiltered().length === 0) {
      return;
    }

    // Create line string features from crossfilter
    this.lineStringSource.clear();
    var lineStringFeatures = this.geoJsonFmt.readFeatures(
      {
        type: "FeatureCollection",
        features: featuresCrossfilter
          .allFiltered()
          .filter((f) => f.geometry?.type !== null),
      },
      {
        featureProjection: "EPSG:3857",
      }
    );

    // Add features to source
    this.lineStringSource.addFeatures(lineStringFeatures);

    // Create point features
    this.pointSource.clear();
    var pointFeatures = lineStringFeatures.map((feat) => {
      let featProps = Object.assign({}, feat.getProperties());
      delete featProps["geometry"];
      let newFeat = new Feature({
        geometry: new PointGeom(
          feat.getGeometry().getLastCoordinate().slice(0, 2)
        ),
      });
      newFeat.setProperties(featProps);
      return newFeat;
    });

    // Add features to source
    this.pointSource.addFeatures(pointFeatures);
  }

  componentWillUnmount() {
    if (typeof this.removeOnChange == "function") {
      this.removeOnChange();
    }
    window.clearInterval(this.interval);
  }

  render() {
    const { chartTitle, height, width, useFlex } = this.props;
    return (
      <AVIMapTile
        title={chartTitle}
        height={height}
        width={width}
        getMap={this.getMap}
        allowFullscreen
        useFlex={useFlex}
      >
        <div className="avi-map" ref={this.chartRef} />
        <div className="avi-map-popup" ref={this.popupRef}>
          <div className="avi-map-popup-content" ref={this.popupContentRef} />
        </div>
      </AVIMapTile>
    );
  }
}
