import * as dc from 'dc';
import * as d3 from 'd3';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import GeoJSONFormat from 'ol/format/GeoJSON';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import { GeoJsonUtils } from '../GeoJsonUtils/GeoJsonUtils';
import { DcExtBase } from './DcExtBase';
import { ExecUtils } from '../ChartUtils/ExecUtils';

export class DcExtLineStringMapChart {
  _map = null;
  _geojson;
  _trackGeomLayer = null;
  _chartData = null;
  _scale = null;
  _categoryProperty = null;
  _timeout = null;

  constructor (props) {
    const { map, chartData, colorScheme = d3.schemeCategory10, categoryProperty } = props;

    if (map === undefined) {
      throw new Error('Mandatory property map missing');
    }

    if (chartData === undefined) {
      throw new Error('Mandatory property trackGeom missing');
    }

    if (typeof colorScheme === 'function') {
      this._scale = colorScheme;
    } else {
      this._scale = d3.scaleOrdinal(colorScheme);
    }

    if (categoryProperty === undefined) {
      throw new Error('Mandatory property categoryProperty missing');
    }

    this._map = map;
    this._chartData = chartData;
    this._categoryProperty = categoryProperty;

    this.getStyle = this.getStyle.bind(this);
    this.draw = this.draw.bind(this);

    DcExtBase(this);

    dc.registerChart(this);
  }

  geojson (geojson) {
    if (geojson === undefined) {
      return this._geojson;
    } else {
      this._geojson = geojson;
      return this;
    }
  }

  scale (value) {
    if (typeof this._scale === 'function') {
      return this._scale(value);
    } else {
      return value;
    }
  }

  getStyle (feature, resolution) {
    var props = feature.getProperties();
    var color = this.scale(props[this._categoryProperty]);
    return new Style({
      stroke: new Stroke({
        color: color,
        width: 2
      })
    });
  }

  draw = ExecUtils.debounce(this, this._draw, 500);

  _draw () {
    if (this._trackGeomLayer !== null) {
      this._map.removeLayer(this._trackGeomLayer);
    }

    var filteredGeomCollection = GeoJsonUtils.featureCollection(this._chartData.allFiltered());

    if (filteredGeomCollection.features.length > 0) {
      // Update geojson property of diagram
      this.geojson(filteredGeomCollection);

      var vectorSource = new VectorSource({
        features: new GeoJSONFormat().readFeatures(filteredGeomCollection, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857'
        })
      });

      this._trackGeomLayer = new VectorLayer({
        title: 'Linjer',
        source: vectorSource,
        style: this.getStyle
      });

      this._trackGeomLayer.setZIndex(1000);

      this._map.addLayer(this._trackGeomLayer);
      this._map.getView().fit(vectorSource.getExtent(), this._map.getSize());
    }
  }
}
