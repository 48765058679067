import { Grid } from "../../types/Grid";
import { ceilToNearest, floorToNearest } from "../numbers/round";

export function burnMaxIntoGrid(x: number, y: number, v: number, grid: Grid) {
  x = floorToNearest(x, grid.cellSize);
  y = ceilToNearest(y, grid.cellSize);
  const col = (x - grid.extent.minx) / grid.cellSize;
  const row = (grid.extent.maxy - y) / grid.cellSize;
  const pos = row * grid.cols + col;
  const cval = grid.data[pos];
  if (isNaN(cval) || v > cval) {
    grid.data[pos] = v;
  }
}
