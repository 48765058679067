export function arrayDropDuplicatesBy<T = any>(arr: T[], ...args: (keyof T)[]) {
  const uq: { [key: string]: boolean } = {};

  return arr.filter((e: T) => {
    const key = args.map((a) => e[a]).join("_");
    if (key in uq) {
      return false;
    } else {
      uq[key] = true;
      return true;
    }
  });
}
