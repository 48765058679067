/* eslint-disable react/no-unused-prop-types */

import '../AVIChartCommon.scss';

import * as d3 from 'd3';

import React, { Component } from 'react';

import { BaseMixin } from '../Mixins/BaseMixin';
import { ColorMixin } from '../Mixins/ColorMixin';
import { CoordinateGridMixin } from '../Mixins/CoordinateGridMixin';
import { MarginsMixin } from '../Mixins/MarginsMixin';
import PropTypes from 'prop-types';
import { TileMixin } from '../Mixins/TileMixin';
import * as dc from 'dc';
import AVIChartTile from '../Layout/AVIChartTile';

export class BarChartGroupedNew extends Component {
  static propTypes = {
    /** A Crossfilter dimension made that returns an array key, e.g. crossfilter.dimension(d=>[d.key, d.key2]).
     * The first element in the array will be used as the main group (will be shown on the X-axis),
     * the second value will be used as a subgroup to render one bar for each unique sub-group value within the group. */
    dimension: PropTypes.object.isRequired,
    /** A Crossfilter group made on the same dimension */
    group: PropTypes.object.isRequired,
    /** Either an array of colors or a function that accepts a value and returns a color */
    colors: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    /** Title to render on the chart */
    chartTitle: PropTypes.string,
    /** Width of diagram in pixels */
    width: PropTypes.number,
    /** Height of diagram in pixels */
    height: PropTypes.number,
    /** Gap between bars in a group */
    gap: PropTypes.number,
    /** Gap between groups of bars */
    seriesGap: PropTypes.number,
    /** Render labels on top of each bar */
    renderLabel: PropTypes.bool,
    /** Render tool tip titles on mouseover of bars */
    renderTitle: PropTypes.bool
  };

  static defaultProps = {
    width: 640,
    height: 480,
    renderLabel: true,
    renderTitle: true,
    gap: 0.05,
    seriesGap: 0.1
  };

  _chart = null;

  constructor (props) {
    super(props);
    this.chartRef = React.createRef();
    this.getChart = this.getChart.bind(this);
    this.chart = this.createChart.bind(this);
  }

  createChart (parent, chartGroup) {
    let _chart = new dc.CoordinateGridMixin(new dc.MarginMixin(dc.ColorMixin(dc.BaseMixin)));

    let _g = null;

    let _gap = 0.05;
    _chart.gap = function (gap) {
      if (gap !== undefined) {
        _gap = gap;
        return _chart;
      } else {
        return _gap;
      }
    };

    let _seriesGap = 0.1;
    _chart.seriesGap = function (seriesGap) {
      if (seriesGap !== undefined) {
        _seriesGap = seriesGap;
        return _chart;
      } else {
        return _seriesGap;
      }
    };

    let _renderLabels = true;
    _chart.renderLabels = function (renderLabels) {
      if (renderLabels !== undefined) {
        _renderLabels = renderLabels;
        return _chart;
      } else {
        return _renderLabels;
      }
    };

    let _renderTitles = true;
    _chart.renderTitles = function (renderTitles) {
      if (renderTitles !== undefined) {
        _renderTitles = renderTitles;
        return _chart;
      } else {
        return _renderTitles;
      }
    };

    _chart.draw = function () {
      var _margins = _chart.margins();
      var _width = _chart.width() - (_margins.left + _margins.right);
      var _height = _chart.height() - (_margins.bottom + _margins.top);

      _chart.resetSvg();

      _g = _chart
        .svg()
        .append('g')
        .attr('transform', 'translate(' + _chart.margins().left + ',' + _chart.margins().top + ')');

      // Extract and sort group
      var _group = _chart
        .data()
        .slice(0)
        .sort((a, b) => {
          if (a.key[0] > b.key[0]) {
            return 1;
          } else if (b.key[0] > a.key[0]) {
            return -1;
          } else if (a.key[1] > b.key[1]) {
            return 1;
          } else if (b.key[1] > a.key[1]) {
            return -1;
          } else {
            return 0;
          }
        });

      // Calculate min/max
      var values = _group
        .map(d => d.value)
        .sort((a, b) => {
          if (a > b) return 1;
          if (b > a) return -1;
          return 0;
        });
      var min = values[0];
      var max = values[values.length - 1];
      values.length = 0;

      // List of groups = species here = value of the first column called group -> I show them on the X axis
      var groups = Array.from(new Set(_group.map(g => g.key[0])));

      // List of subgroups = header of the csv files = soil condition here
      var subgroups = Array.from(new Set(_group.map(g => g.key[1])));

      // Add X axis
      _chart.x(d3.scaleBand().domain(groups).range([0, _width]).padding([_seriesGap]));

      _g.append('g')
        .attr('transform', 'translate(0, ' + _height + ')')
        .call(d3.axisBottom(_chart.x()).tickSize(5));

      // Add Y axis
      _chart.y(
        d3
          .scaleLinear()
          .domain([min > 0 ? 0 : min * 1.1, max * 1.1])
          .range([_height, 0])
      );

      _g.append('g').call(d3.axisLeft(_chart.y()));

      // Another scale for subgroup position?
      var xSubgroup = d3
        .scaleBand()
        .domain(subgroups)
        .range([0, _chart.x().bandwidth()])
        .padding([_gap]);

      // Show the bars
      _g.append('g')
        .selectAll('g')
        // Enter in data = loop group per group
        .data(groups)
        .enter()
        .append('g')
        .attr('class', 'barGroup')
        .attr('transform', function (d) {
          return 'translate(' + _chart.x()(d) + ',0)';
        })
        .selectAll('rect.bar')
        .data(function (d) {
          return _group.filter(g => g.key[0] === d);
        })
        .enter()
        .append('rect')
        .attr('class', 'bar')
        .attr('x', function (d) {
          return xSubgroup(d.key[1]);
        })
        .attr('y', function (d) {
          return _chart.y()(d.value);
        })
        .attr('width', xSubgroup.bandwidth())
        .attr('height', function (d) {
          return _height - _chart.y()(d.value);
        })
        .attr('fill', function (d) {
          return _chart.colors()(d.key[1]);
        });

      if (_chart.renderTitle()) {
        _g.selectAll('rect.bar')
          .append('svg:title')
          .attr('class', 'barTitle')
          .attr('fill', '#ffffff')
          .attr('color', '#000000')
          .text(d => `${d.key[0]}/${d.key[1]}: ${d.value}`);
      }

      if (_chart.renderLabel()) {
        _g.selectAll('g.barGroup')
          .selectAll('text.barLabel')
          .data(function (d) {
            return _group.filter(g => g.key[0] === d);
          })
          .enter()
          .append('text')
          .attr('class', 'barLabel')
          .attr('text-anchor', 'middle')
          .attr('x', function (d) {
            return xSubgroup(d.key[1]) + xSubgroup.bandwidth() / 2;
          })
          .attr('y', function (d) {
            return _chart.y()(d.value) - 5;
          })
          .text(d => d.value);
      }
    };

    _chart._doRender = function () {
      _chart.draw();
      return _chart;
    };

    var _redrawTimeout;
    _chart._doRedraw = function () {
      if (_redrawTimeout) {
        clearTimeout(_redrawTimeout);
      }
      _redrawTimeout = setTimeout(d => {
        _chart.draw();
      }, 250);
      return _chart;
    };

    return _chart.anchor(parent, chartGroup);
  }

  componentDidMount () {
    const { gap, renderLabel, renderTitle, seriesGap } = this.props;

    this._chart = this.createChart(this.chartRef.current);

    // Set mixin properties
    BaseMixin(this._chart, this.props);
    ColorMixin(this._chart, this.props);
    MarginsMixin(this._chart, this.props);
    CoordinateGridMixin(this._chart, this.props);
    TileMixin(this._chart, this.props);

    // Set custom properties
    this._chart.gap(gap).seriesGap(seriesGap).renderLabel(renderLabel).renderTitle(renderTitle);

    // Render chart
    this._chart.render();
  }

  getChart () {
    return this._chart;
  }

  render () {
    return (
      <AVIChartTile
        title={this.props.chartTitle}
        getChart={this.getChart}
        width={this.props.width}
        height={this.props.height}
      >
        <div className='avi-chart' ref={this.chartRef} />
      </AVIChartTile>
    );
  }
}
