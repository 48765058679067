export class DbrdUtils {
  static isDashboard (dbrdCand, log = 0) {
    var success = true;

    if (dbrdCand !== undefined && dbrdCand !== null) {
      success = true;
      var dbrdName = dbrdCand.name;
      if (
        !dbrdCand.hasOwnProperty('dashboardRoute') ||
        typeof dbrdCand.dashboardRoute !== 'string'
      ) {
        if (log > 1) console.debug(`${dbrdName} is missing dashboardRoute string property`);
        success = false;
      }
      if (
        !dbrdCand.hasOwnProperty('dashboardFilters') ||
        typeof dbrdCand.dashboardFilters !== 'function'
      ) {
        if (log > 1) console.debug(`${dbrdName} is missing dashboardFilters function`);
        success = false;
      }
      if (
        !dbrdCand.hasOwnProperty('dashboardSettings') ||
        typeof dbrdCand.dashboardSettings !== 'function'
      ) {
        if (log > 1) console.debug(`${dbrdName} is missing dashboardSettings function`);
        success = false;
      }
      if (
        !dbrdCand.hasOwnProperty('dashboardValidation') ||
        typeof dbrdCand.dashboardValidation !== 'function'
      ) {
        if (log > 1) console.debug(`${dbrdName} is missing dashboardValidation function`);
      }
    } else {
      if (log === 1) console.error('Supplied dashboard is null or undefined');
      success = false;
    }

    return success;
  }
}
