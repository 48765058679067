import "./DbrdPilotage.scss";

import * as d3 from "d3";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { NumUtils } from "../../chart-components/ChartUtils/NumUtils";
import { TextUtils } from "../../chart-components/ChartUtils/TextUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartMonth } from "../../chart-components/Charts/BarChartMonth";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { BarChartStacked } from "../../chart-components/Charts/BarChartStacked";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { HeatMap } from "../../chart-components/Charts/HeatMap";
import { PieChart } from "../../chart-components/Charts/PieChart";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { FilterToFromYear } from "../../filters/FilterToFromYear";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";
import { SelectLocationsInMap } from "../../ui-components/filter-view/FilterMap/SelectLocationsInMap";

class DbrdPilotageBase extends Component<any, any> {
  /**
   * The route to be used for the dashboard
   */
  static dashboardRoute = "pilotage";

  /**
   * The help text and filter controls to be used as initial filtering for the dashboard
   * @param {*} filter
   * @param {*} setFilter
   */
  static dashboardFilters(filter, setFilter) {
    return {
      helpMessage:
        "Dette dashboardet gjør det mulig å analysere flere år samtidig. Velg en eller flere avgangs- eller ankomsthavner og f.eks. tidsrommet 2015-2018",
      controls: [
        <FilterToFromYear
          key="flt"
          firstYear={2010}
          filter={filter}
          setFilter={setFilter}
        />,
        <SelectLocationsInMap
          key="sel-loc"
          name="locationIds"
          returnProperty="id"
        />,
      ],
    };
  }

  /**
   * The dashboard settings, i.e. map interaction filter controls, selectable layers etc.
   */
  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: undefined,
    };
  }

  /**
   * The dashboard validation function
   * @param {*} filter
   */
  static dashboardValidation(filter) {
    if (
      filter.fromYear &&
      filter.toYear &&
      Array.isArray(filter.locationIds) &&
      filter.locationIds.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  static propTypes = {
    location: PropTypes.object,
  };

  /**
   * TestRoute
   *
   * @param {*} props An object looking like this {a:, b:}
   */
  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
      locationData: [],
    };
  }

  componentDidMount() {
    const { locationIds, fromYear, toYear } = this.props.location.state;
    try {
      if (Array.isArray(locationIds) && fromYear && toYear) {
        var mergeRequests = [
          DataLoader.post(
            process.env.REACT_APP_DASHBOARD_WS_API +
              "/api/pilotage/for-locations-years",
            {
              LocationIds: locationIds,
              FromYear: fromYear,
              ToYear: toYear,
            }
          ),
          DataLoader.post(
            process.env.REACT_APP_DASHBOARD_WS_API +
              "/api/location/for-locations",
            {
              LocationIds: locationIds,
            }
          ),
        ];

        Promise.all(mergeRequests)
          .then((jsonResponses) => {
            // Check that all responses are successful
            var success = true;

            jsonResponses.forEach((r) => {
              if (!r.success) {
                if (success) {
                  success = false;
                }
              }
            });

            if (!success) {
              throw new Error("Error loading pilotage data");
            }

            jsonResponses[0].data.forEach((pilotage) => {
              pilotage.origtim = new Date(pilotage.origtim);
            });

            var pilotageAssignments = jsonResponses[0].data;
            var locations = jsonResponses[1].data;

            // console.log(pilotageAssignments[0]);

            pilotageAssignments.forEach((pta) => {
              pta.originfo =
                locations.find((loi) => loi.locationid === pta.pflocid) || {};
              pta.destinfo =
                locations.find((loi) => loi.locationid === pta.ptlocid) || {};
            });

            this.setState({
              chartData: crossfilter(pilotageAssignments),
              locationData: locations,
            });
          })
          .catch((error) => {
            console.warn(error);
            this.setState({
              chartData: crossfilter([]),
            });
          });
      } else {
        throw new Error("No data available yet");
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  render() {
    const { chartData, locationData } = this.state;
    const { fromYear, toYear } = this.props.location.state;

    if (!chartData || chartData.size() === 0) {
      return <Loader chartData={chartData} />;
    }

    var selectedDestinations = locationData
      .map((loc) => loc.locationnamenor)
      .join(", ");

    var dimMonth = chartData.dimension((d) => d3.timeMonth(d.origtim));
    var assignByMonth = dimMonth.group().reduceCount();

    var dimYear = chartData.dimension((d) =>
      d3.timeYear(d.origtim).getFullYear()
    );

    var dimShipCategory = chartData.dimension((d) =>
      d.sc2 ? d.sc2 : "Ukjent"
    );
    var assignmentsByShipCategory = dimShipCategory.group().reduceCount();

    var dimShipType = chartData.dimension((d) =>
      d.stype ? d.stype : "Ukjent"
    );
    var assignmentsByShipType = dimShipType.group().reduceCount();

    var dimLengthGroup = chartData.dimension((d) =>
      d.lgrp ? d.lgrp : "Ukjent"
    );
    var assignmentsByLengthGroup = dimLengthGroup.group().reduceCount();

    var dimHazMat = chartData.dimension((d) =>
      d.hazmat === "T" ? "JA" : "NEI"
    );
    var assignByHazMat = dimHazMat.group().reduceCount();

    var dimPordTyp = chartData.dimension((d) =>
      d.isexemp === true ? "Dispensasjon" : d.pordtyp
    );
    var assignByPordTyp = dimPordTyp.group().reduceCount();

    var dimOrig = chartData.dimension((d) => d.orig || "Ukjent");
    var assignmentsByOrigin = dimOrig.group().reduceCount();
    var dimDest = chartData.dimension((d) => d.dest || "Ukjent");
    var assignmentsByDestination = dimDest.group().reduceCount();

    var dimOrigCouncil = chartData.dimension(
      (d) => d.originfo.councilname || "Andre opphavslokasjoner"
    );
    var assignmentsByOrigCouncil = dimOrigCouncil.group().reduceCount();

    var dimDestCouncil = chartData.dimension(
      (d) => d.destinfo.councilname || "Andre ankomstlokasjoner"
    );
    var assignmentsByDestCouncil = dimDestCouncil.group().reduceCount();

    var dimHourDay = chartData.dimension((d) => {
      let hrs = +d.origtim.getHours();
      return [TimeUtils.getLocalWeekDayAbb(d.origtim), hrs];
    });

    var assignByHourDay = dimHourDay.group().reduceCount();

    var dimCallSign = chartData.dimension((d) => d.callsign);

    return (
      <div className="AppView">
        <AVIDashboard
          title="Lospliktige seilas"
          desc={`Statistikk over lospliktige seilas i perioden ${fromYear}-${toYear} til/fra lokasjonene ${selectedDestinations}`}
          spacing={20}
          group={chartData.groupAll()}
          units="Lospliktige seilas"
          cfilter={chartData}
          filter={this.props.location.state}
          useFlex
        >
          <AVIRow>
            <RowChart
              chartTitle="Lospliktige seilas etter skipskategori"
              dimension={dimShipCategory}
              group={assignmentsByShipCategory}
              useFlex
              height={1.5}
            />
            <RowChart
              chartTitle="Lospliktige seilas etter skipstype (10 største)"
              dimension={dimShipType}
              group={GroupUtils.RemoveEmptyBinsTopN(assignmentsByShipType, 10)}
              useFlex
              height={1.5}
            />
          </AVIRow>
          <AVIRow>
            <BarChartMonth
              chartTitle="Lospliktige seilas etter måned"
              dimension={dimMonth}
              group={assignByMonth}
              useFlex
              height={1}
              xAxisLabel="Måned"
              yAxisLabel="Lospliktige seilas"
            />
          </AVIRow>
          <AVIRow>
            <PieChart
              chartTitle="Med farlig gods"
              dimension={dimHazMat}
              group={assignByHazMat}
              useFlex
              height={1.5}
              radius={300}
            />
            <PieChart
              chartTitle="Type seilas"
              dimension={dimPordTyp}
              group={assignByPordTyp}
              useFlex
              height={1.5}
              radius={300}
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              chartTitle="Lospliktige seilas etter avgangskommune (20 mest traffikerte)"
              dimension={dimOrigCouncil}
              group={GroupUtils.RemoveEmptyBinsTopN(
                assignmentsByOrigCouncil,
                20
              )}
              useFlex
              height={1}
              ordering={(d) => -d.value}
              rotateXAxisLabels={-15}
              margins={{ bottom: 60 }}
            />
            <BarChartOrdinal
              chartTitle="Lospliktige seilas etter ankomstkommune (20 mest traffikerte)"
              dimension={dimDestCouncil}
              group={GroupUtils.RemoveEmptyBinsTopN(
                assignmentsByDestCouncil,
                20
              )}
              useFlex
              height={1}
              ordering={(d) => -d.value}
              rotateXAxisLabels={-15}
              margins={{ bottom: 60 }}
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              chartTitle="Lospliktige seilas etter avgangssted (20 mest traffikerte)"
              dimension={dimOrig}
              group={GroupUtils.RemoveEmptyBinsTopN(assignmentsByOrigin, 20)}
              useFlex
              height={1.75}
              ordering={(d) => -d.value}
              rotateXAxisLabels={-45}
              margins={{ bottom: 60 }}
            />
            <BarChartOrdinal
              chartTitle="Lospliktige seilas etter destinasjon (20 mest traffikerte)"
              dimension={dimDest}
              group={GroupUtils.RemoveEmptyBinsTopN(
                assignmentsByDestination,
                20
              )}
              useFlex
              height={1.75}
              ordering={(d) => -d.value}
              rotateXAxisLabels={-45}
              margins={{ bottom: 60 }}
            />
          </AVIRow>
          <AVIRow>
            <HeatMap
              chartTitle="Lospliktig seilas etter time i døgnet og dag i uken"
              dimension={dimHourDay}
              group={assignByHourDay}
              useFlex
              height={2}
              colOrdering={TimeUtils.localeWeekDayAbbSortFunction}
              // colsLabel={d => HeatMap.weekDaysEn[d]}
              // rowsLabel={d => HeatMap.hoursOfDay[d]}
            />
            <PieChart
              chartTitle="Lospliktige seilas etter lengdegruppe"
              dimension={dimLengthGroup}
              group={assignmentsByLengthGroup}
              useFlex
              height={2}
              ordering={TextUtils.firstNumberInString}
            />
          </AVIRow>
          <AVIRow>
            <BarChartStacked
              chartTitle="Lospliktige seilas etter skipstype og år"
              dimension={dimYear}
              useFlex
              height={1.5}
              gap={2}
              filterPrefix="År"
              stackKey="sc2"
              valueKey="*count"
              renderLabel
            />
          </AVIRow>
          <AVIRow>
            <DataTable
              chartTitle="Lospliktige seilas"
              dimension={dimCallSign}
              sortBy={(d) => d.origtim}
              useFlex
              size={Infinity}
              columns={[
                {
                  label: "Dato",
                  format: (d) => TimeUtils.formatDate(d.origtim),
                  value: (d) => d.origtim,
                },
                {
                  label: "Fra",
                  format: (d) => d.orig,
                },
                {
                  label: "Til",
                  format: (d) => d.dest,
                },
                {
                  label: "IMO#",
                  format: (d) => d.imono || "n/a",
                },
                {
                  label: "Type seilas",
                  format: (d) =>
                    d.isexemp === true ? "Dispensasjon" : d.pordtyp,
                },
                {
                  label: "Skip",
                  title: "Skipsnavn",
                  format: (d) => d.sname || "",
                },
                {
                  label: "LOA",
                  title: "Length overall (LOA)",
                  format: (d) => NumUtils.decimal2(d.slength) || "",
                  value: (d) => d.slength,
                },
                {
                  label: "BT",
                  title: "Bruttotonnasje",
                  format: (d) => NumUtils.integer(d.gt),
                  value: (d) => d.gt,
                },
                {
                  label: "DWT",
                  title: "Dødvektstonnasje",
                  format: (d) => NumUtils.integer(d.dwt),
                  value: (d) => d.dwt,
                },
                {
                  label: "HazMat",
                  format: (d) => {
                    switch (d.hazmat) {
                      case "T":
                        return "JA";
                      case "F":
                        return "NEI";
                      default:
                        return "";
                    }
                  },
                },
                {
                  label: "Skipstype",
                  format: (d) => d.stype,
                },
                {
                  label: "Flaggstat",
                  format: (d) => d.sfc,
                },
              ]}
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdPilotage = withRouter(DbrdPilotageBase);

export default DbrdPilotage;
