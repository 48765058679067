import React, { Component } from "react";
import "./Disclaimer.scss";
import { Container } from "react-bootstrap";

export class Disclaimer extends Component {
  render() {
    return (
      <div className="bg-primary text-light">
        <Container className="py-5">
          <h3>Om data i tjenesten</h3>
          <p>
            Denne tjenestene er baserte på data fra mange kilder inkludert men
            ikke avgrenset til AIS, Njord, NewShipRep og SafeSeaNet
          </p>
          <p>
            Vi gjør vårt ytterste for at disse dataene skal ha en så god
            kvalitet som mulig. Det er likevel viktig å vite det er usikkerhet i
            datagrunnlaget som gjør til at det er umulig å eliminere alle feil.
          </p>
          <p>
            Om du gjennom bruk av tjenesten oppdager feil eller mangler er vi
            takknemlige om du rapporterer disse til oss her.
          </p>
          <p>
            Vi har ikke kapasitet til å gi personlig tilbakemelding og
            oppfølging til alle innspill - men alle vil bli analyserte og brukt
            som grunnlag for kontinuerlig arbeid med kvalitetsheving
          </p>
          <p>
            Tjenesten gir adgang til å laste ned seilasdata og havnedata i
            GeoJSON format for videre bruk i for eksempel Kystinfo.no hvor data
            kan lastest inn og visualiseres sammen med annen maritim informasjon
            via modulen ’Mine data’
          </p>
        </Container>
      </div>
    );
  }
}
