import React, { PropsWithChildren, useContext } from "react";
import { AuthContext } from "./AuthLib";

/**
 * Do not display children unless no user is authenticated
 *
 */

export function RequireAnonymous({ children }: PropsWithChildren<any>) {
  const { authState } = useContext(AuthContext);
  if (authState.authenticated === true &&
    authState.expiration_time > new Date())
    return null;

  return <>{children}</>;
}
