import React from "react";
import PropTypes from "prop-types";
import { ShipTypeAhead } from "../../filters/ShipTypeAhead/ShipTypeAhead";
import { DataLoader } from "./../../ui-components/DataLoader/DataLoader";

export class ExclusionRecord extends React.Component {
  static propTypes = {
    rowData: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired,
  };

  constructor(props) {
    super();
    this.state = {
      editable: false,
    };
  }

  options = [
    { value: "exclude", label: "Exclude from cruise" },
    { value: "exclude_voyages", label: "Exclude voyages" },
    { value: "reassign", label: "Reassign ship type" },
  ];

  componentDidMount() {
    this.clearForm();
  }

  clearForm = () => {
    const { rowData } = this.props;
    this.setState({
      rowData: {
        id: null,
        from_date: "",
        to_date: "",
        action: "exclude",
        assigned_ship_type: "",
        ...rowData,
      },
    });
  };

  setEditable = (editableState = true) => {
    this.setState({
      editable: editableState,
    });
    if (editableState === true) {
      this.clearForm();
    }
  };

  insert = () => {
    const { rowData } = this.state;
    const { reload } = this.props;
    // console.log(rowData);
    DataLoader.postApi("/api/ship/exclusion/insert", rowData).then((res) => {
      if (res.success) {
        this.setState({
          rowData: this.props.rowData,
        });
        this.setEditable(false);
        reload();
      }
    });
  };

  update = () => {
    const { rowData } = this.state;
    const { reload } = this.props;
    DataLoader.postApi("/api/ship/exclusion/update", rowData).then((res) => {
      if (res.success) {
        this.setEditable(false);
        reload();
      }
    });
  };

  save = (e) => {
    e.preventDefault();
    const { rowData } = this.state;
    if (rowData.id) {
      this.update();
    } else {
      this.insert();
    }
  };

  delete = (e) => {
    e.preventDefault();
    const { rowData } = this.state;
    const { reload } = this.props;
    DataLoader.getApi(`/api/ship/exclusion/delete/${rowData.id}`).then(
      (res) => {
        this.setEditable(false);
        reload();
      }
    );
  };

  handleChange = (e) => {
    if (e.hasOwnProperty("preventDefault")) {
      e.preventDefault();
    }
    const { rowData } = this.state;
    this.setState({
      rowData: {
        ...rowData,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleSelectShip = (e) => {
    const { rowData } = this.state;
    this.setState({
      rowData: {
        ...rowData,
        ship_id: e.value,
        ship_name: e.label,
      },
    });
  };

  render() {
    const { editable, rowData } = this.state;

    if (!rowData) {
      return null;
    }

    return (
      <form>
        <div className="exclusion-record">
          <div className="field">
            <input
              name="id"
              type="text"
              readOnly
              disabled
              value={rowData.id ? rowData.id : ""}
            />
          </div>
          <div className="field">
            {rowData.ship_id} - {rowData.ship_name}
            {editable && (
              <ShipTypeAhead label="" setValue={this.handleSelectShip} />
            )}
          </div>
          <div className="field">
            <select
              name="action"
              disabled={!editable}
              defaultChecked={rowData.action}
              defaultValue={rowData.action}
              onChange={this.handleChange}
            >
              {this.options.map((o) => (
                <option key={`dd-option-${o.value}`} value={o.value}>
                  {o.label}
                </option>
              ))}
            </select>
          </div>
          <div className="field">
            <input
              name="assigned_ship_type"
              list="ship-types-datalist"
              type="text"
              disabled={!editable}
              defaultValue={rowData.assigned_ship_type}
              onChange={this.handleChange}
            />
          </div>
          <div className="field">
            <input
              name="from_date"
              type="date"
              disabled={!editable}
              defaultValue={rowData.from_date}
              onChange={this.handleChange}
            />
          </div>
          <div className="field">
            <input
              name="to_date"
              type="date"
              disabled={!editable}
              defaultValue={rowData.to_date}
              onChange={this.handleChange}
            />
          </div>
          {editable && (
            <div className="field">
              <button onClick={this.save}>Save</button>
              <button onClick={this.delete}>Delete</button>
            </div>
          )}
          {!editable && (
            <div className="field">
              <button onClick={() => this.setEditable(true)}>Edit</button>
            </div>
          )}
        </div>
      </form>
    );
  }
}
