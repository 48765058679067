import './LocationTypeAhead.scss';

import React, { useCallback } from 'react';

import AsyncSelect from 'react-select/async';
import { DataLoader } from '../../ui-components/DataLoader/DataLoader';
import { ExecUtils } from '../../chart-components/ChartUtils/ExecUtils';
import { FormGroup, FormLabel } from 'react-bootstrap';

type LocationMatch = {
  key: string,
  value: string,
  label: string,
  data: any
}

type TypeaheadOptions<T = any> = {
  label: string;
  options: T[]
}

type LocationTypeAheadProps = {
  setValue: (a: any) => void,
  isMulti?: boolean,
  label?: string,
  placeholder?: string,
  searchingMsg?: string,
  defaultValue?: any[],
  selectedValue?: any,
  omitLabel?: boolean
};

export function LocationTypeAhead({
  setValue,
  isMulti = false,
  label = 'Velg en lokasjon',
  placeholder = 'Navn på anløpssted',
  searchingMsg = 'Søker',
  defaultValue,
  selectedValue,
  omitLabel
}: LocationTypeAheadProps) {

  const parseOptions = useCallback((response: any): TypeaheadOptions<LocationMatch>[] => {
    let i = 0;
    return [
      {
        label: 'Anløpssted',
        options: response.data.map((match: any) : LocationMatch => {
          return {
            key: match.id + '-' + i++,
            value: match.id,
            label: match.name + ', ' + match.country + ', (' + match.code + ')',
            data: {
            }
          };
        })
      }
    ];
  }, []);

  const loadLocations = useCallback(async (queryTerm: string, setOptions) => {
    if (queryTerm && queryTerm.trim().length > 3) {
      DataLoader.postApi('/api/location/free-text', {
        FreeText: queryTerm
      }).then(response => {
        if (response.success === true) {
          setOptions(parseOptions(response));
        }
      });
    }
  }, [parseOptions]);


  return (
    <FormGroup>
      {!omitLabel && <FormLabel>
        {label}
      </FormLabel>}
      <AsyncSelect
        cacheOptions
        placeholder={placeholder}
        loadingMessage={d => searchingMsg}
        loadOptions={ExecUtils.debounceTwoArgs(this, loadLocations, 500)}
        defaultOptions
        defaultValue={defaultValue}
        isMulti={isMulti}
        onChange={setValue}
      />
    </FormGroup>
  );

}
