import "./Loader.scss";

import React, { useMemo } from "react";

import { useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ConvUtils } from "./../../chart-components/ChartUtils/ConvUtils";
import { Spinner, SpinnerIcon } from "./Spinner";
import loaderIcon from "./spinner.svg";
import { isArrayN } from "../../utils/array/isArrayN";

export type ProgressErrorType = {
  success: true;
  msg: "ok";
};

export type SuperagentProgress = {
  direction: "upload" | "download" | "completed";
  percent: number; // may be missing if file size is unknown
  total?: number; // total file size, may be missing
  loaded: number; // bytes downloaded or uploaded so far
  timestamp: Date;
};

const logoAlternatives: SpinnerIcon[] = [
  "ship-1",
  "ship-2",
  "ship-3",
  "ship-4",
  "ship-5",
  "ship-6",
  "ship-7",
];

const randomLogo = () => {
  const t = Math.random() * logoAlternatives.length;
  return logoAlternatives[Math.floor(t)];
};

export type ProgressPhase =
  | "sending request"
  | "request received"
  | "downloading"
  | "downloading-zipped"
  | "processing"
  | "completed"
  | "no data"
  | "just loading"
  | "error";

export type LoaderProps = {
  hideMessage?: () => void;
  progressData?: SuperagentProgress;
  errorData?: ProgressErrorType;
  chartData?: any;
  dataArray?: any[];
};

export function Loader({
  hideMessage,
  progressData,
  errorData,
  chartData,
  dataArray,
}: LoaderProps) {
  const [secElapsed, setSecElapsed] = useState<number>(0);
  const navigate = useNavigate();
  const [spinnerIcon, setSpinnerIcon] = useState<SpinnerIcon>(randomLogo());

  const progressPhase: ProgressPhase = useMemo(() => {
    if (!progressData && !chartData) return "sending request";

    if ((chartData && chartData.size() === 0) || (dataArray !== undefined && !isArrayN(dataArray, 1))) {
      return "no data";
    } else if (
      progressData &&
      progressData.direction === "upload" &&
      (progressData.percent < 1 ||
        isNaN(progressData.percent) ||
        progressData.percent === 100)
    ) {
      return "request received";
    } else if (
      progressData &&
      progressData.direction === "download" &&
      progressData.percent >= 1 &&
      !isNaN(progressData.percent)
    ) {
      return "downloading";
    } else if (
      progressData &&
      progressData.direction === "download" &&
      progressData.loaded >= 1
    ) {
      return "downloading-zipped";
    } else if (
      progressData &&
      progressData.direction === "completed" &&
      !chartData
    ) {
      return "completed";
    } else if (!progressData && !chartData) {
      return "just loading";
    } else {
      return "error";
    }
  }, [chartData, dataArray, progressData]);

  useEffect(() => {
    let iv = setInterval(() => {
      setSecElapsed((msElapsed) => msElapsed + 1);
    }, 1000);
    let lte = setTimeout(() => {
      setSpinnerIcon("delay");
    }, 15000);
    let lte2 = setTimeout(() => {
      setSpinnerIcon("why-1");
    }, 25000);
    let lte3 = setTimeout(() => {
      setSpinnerIcon("error");
    }, 45000);
    return () => {
      clearInterval(iv);
      clearTimeout(lte);
      clearTimeout(lte2);
      clearTimeout(lte3);
    };
  }, []);

  return (
    <Modal show={true} backdrop="static" centered style={{ zIndex: 100000 }}>
      <ModalBody className="p-5">
        <div className="text-center">
          <div className="kyv--logo-container mx-auto mb-3">
            <Spinner icon={spinnerIcon} motion="rocking-motion" />
            <img
              src={loaderIcon}
              className="kyv--loader-icon-lyr2"
              alt="Loading..."
            />
          </div>
          {progressPhase === "completed" && !hideMessage && (
            <div>Nedlasting fullført, behandler lokalt...</div>
          )}
          {["sending request", "request received"].indexOf(progressPhase) >
            -1 &&
            !hideMessage && (
              <>
                <p>
                  Behandler spørring på server i {secElapsed} sekund. Venligst
                  vent.
                </p>
                <p>
                  Hvis fremdriftsindikatoren blir stående å spinne lenge uten at
                  det skjer noe har du trolig gjort et for stort utvalg. Gå
                  tilbake til forrige side, juster utvalgskriteriene og prøv på
                  nytt.
                </p>
              </>
            )}
          {progressData &&
            ["uploading", "downloading"].indexOf(progressPhase) > -1 &&
            !hideMessage && (
              <div>
                Fremdrift: {+progressData.percent}% (
                {+ConvUtils.bytesToMB(+progressData.loaded, 1).toFixed(1)}/
                {+ConvUtils.bytesToMB(+progressData.total!, 1).toFixed(1)}
                MB)
              </div>
            )}
          {progressData &&
            progressPhase === "downloading-zipped" &&
            !hideMessage && (
              <div>{`Fremdrift: lastet ${ConvUtils.bytesToMB(
                progressData.loaded,
                1
              ).toFixed(1)} MB på ${secElapsed} sekund`}</div>
            )}
          {progressPhase === "error" && !hideMessage && (
            <div>
              <p>Det oppstod ein feil under lasting av data.</p>
              <p className="extra">
                (Feilmelding: "{errorData?.msg}
                ")
              </p>
              <Button onClick={() => navigate(-1)}>Tilbake</Button>
            </div>
          )}
          {progressPhase === "no data" && (
            <div>
              <p>
                Ingen data i utvalget. Du kan ha valgt en tidsperiode som det
                ikke finnes data for. Data kommer inn i løsningen med noen dager
                etterslep og data er kunn tilgjengelig noen år tilbake i tid.
              </p>
              <Button onClick={() => navigate(-1)}>
                Gå tilbake til utvalgskjemaet og juster utvalget
              </Button>
            </div>
          )}
          {progressPhase === "just loading" && (
            <div>
              <p>Laster...</p>
              <Button onClick={() => navigate(-1)}>Tilbake</Button>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
  // }
}
