import React from 'react';
import { DataLoader } from './../../ui-components/DataLoader/DataLoader';
import { ExclusionRecord } from './ExclusionRecord';

export class ExclusionEditor extends React.Component {
  constructor () {
    super();
    this.state = {
      data: []
    };
  }

  componentDidMount () {
    this.reload();
  }

  reload = () => {
    Promise.all([
      DataLoader.getApi('/api/ship/exclusion/all'),
      DataLoader.getApi('/api/ship/shiptypes')
    ])
      .then(res => {
        if (res.every(r => r.success === true) && res.every(r => Array.isArray(r.data))) {
          const [exclusions, shiptypes] = res;
          this.setState({
            data: exclusions.data,
            shipTypes: shiptypes.data
          });
        }
      });
  };

  render () {
    const { data, shipTypes } = this.state;
    return (
      <div>
        <div className='exclusion-record'>
          <div className='field'>#ID</div>
          <div className='field'>Skip</div>
          <div className='field'>Handling</div>
          <div className='field'>Tildelt skipstype</div>
          <div className='field'>Fra dato</div>
          <div className='field'>Til dato</div>
          <div className='field'>Alternativer</div>
        </div>
        <ExclusionRecord rowData={{}} reload={this.reload} />
        {Array.isArray(data) && data.length > 0 && (
          data.map(row => {
            return (<ExclusionRecord
              key={`exclusion-record-${row.id}`}
              rowData={row}
              reload={this.reload} />);
          })
        )}
        {Array.isArray(shipTypes) && (
          <datalist id='ship-types-datalist'>
            {shipTypes.map(s => (
              <option key={`ship-type-opt-${s}`} value={s}>{s}</option>
            ))}
          </datalist>
        )}
      </div>
    );
  }
}
