import olCircle from 'ol/style/Circle';
import olFill from 'ol/style/Fill';
import olStroke from 'ol/style/Stroke';
import olStyle from 'ol/style/Style';
import olText from 'ol/style/Text';

export function passlinesStyleFunction (feature) {
  var isPrivate = feature.getProperties().visibility;
  var lineDash = isPrivate ? [5, 5] : null;
  return new olStyle({
    stroke: new olStroke({
      color: '#1f78b4',
      lineDash: lineDash,
      width: 2
    }),
    fill: new olFill({
      color: '#ff000099'
    })
  });
}

export function getLabel (feature, resolution) {
  var clusterFeatures = feature.get('features');
  if (Array.isArray(clusterFeatures)) {
    if (clusterFeatures.length === 1) {
      var p = clusterFeatures[0].getProperties();

      return `${p.code || ''}\n${p.name}\n(${p.type})`.trim();
    } else if (clusterFeatures.length > 1) {
      return '' + clusterFeatures.length;
    }
  } else {
    const {code, name, type} = feature.getProperties();
    return `${code || ''}\n${name}\n(${type})`.trim();
  }
}

export function locationsStyleFunction (feature, resolution) {
  return [
    new olStyle({
    image: new olCircle({
      radius: 6,
      stroke: new olStroke({
        color: '#fff'
      }),
      fill: new olFill({
        color: '#3399CC'
      })
    }),
    declutterMode: "none"
  }),
    new olStyle({
    text: new olText({
      font: '14px sans-serif',
      text: getLabel(feature, resolution),
      offsetX: 10,
      padding: [2, 2, 2, 2],
      textAlign: 'left',
      fill: new olFill({
        color: '#000000'
      }),
      backgroundFill: new olFill({
        color: '#ffffffcc'
      }),
      declutterMode: "declutter"     
    })
  }),
];
}

export const selectionStyle = new olStyle({
  stroke: new olStroke({
    width: 4,
    color: '#ffff00'
  }),
  fill: new olFill({
    color: '#ffff0066'
  }),
  image: new olCircle({
    fill: new olFill({
      color: '#ffff0033'
    }),
    stroke: new olStroke({
      width: 3,
      color: '#ff0000'
    }),
    radius: 10
  })
});
