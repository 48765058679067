import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useReducer,
} from "react";
import { getLocal, setLocal } from "../utils/localStorageCache";

const KEY_AUTH_STATE = "kystdatahuset_auth_state";

const defaultAuthState: AuthStateType = {
  authenticated: false,
  name: null,
  gm_session_id: null,
  jwt: null,
  roles: [],
  expiration_time: new Date(),
};

function setAuthState(state: AuthStateType) {
  return setLocal(KEY_AUTH_STATE, state);
}

export function getAuthState() {
  var savedState = getLocal<AuthStateType>(KEY_AUTH_STATE, 60 * 72);
  if (savedState) {
    savedState.expiration_time = new Date(savedState.expiration_time);
    return savedState;
  } else {
    console.info("No saved authentication data, defaulting to blank credentials");
    savedState = { ...defaultAuthState };
    setAuthState(savedState);
  }
  return savedState;
}

export type AuthStateType = {
  authenticated: boolean;
  name: string | null;
  gm_session_id: string | null;
  jwt: string | null;
  roles: string[];
  expiration_time: Date;
};

function authReducerFunc(
  state: AuthStateType,
  action: { type: string; payload: any }
) {
  var mState;
  switch (action.type) {
    case "login":
      mState = {
        ...state,
        authenticated: true,
        name: action.payload.name,
        roles: action.payload.roles,
        gm_session_id: action.payload.gm_session_id,
        jwt: action.payload.jwt,
        expiration_time: action.payload.expiration_time,
      };
      break;
    case "logout":
    default:
      mState = {
        ...defaultAuthState,
      };
      break;
  }
  setAuthState(mState);
  return mState;
}

/**
 * Auth context
 */
export const AuthContext = createContext<{
  authState: AuthStateType;
  authDispatch: React.Dispatch<any>;
}>({
  authState: defaultAuthState,
  authDispatch: (A: any) => null,
});

/**
 * Context provider component
 * @param {*} param0
 */
export function AuthContextProvider({ children }: PropsWithChildren<any>) {
  const [authState, authDispatch] = useReducer(authReducerFunc, getAuthState());
  return (
    <AuthContext.Provider value={{ authState, authDispatch }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  const authContext = useContext(AuthContext);
  if (!authContext)
    throw new Error(
      "Auth context can only be used within an AuthContextProvider element"
    );
  return authContext;
}


