import './AVITileFlex.scss';

import React, { Component } from 'react';

import PropTypes from 'prop-types';

export class AVITileFlex extends Component {
  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    flex: PropTypes.number
  };

  static defaultProps = {
    flex: 1
  }

  render () {
    const { children, title, flex } = this.props;

    return (
      <div
        className='avi-chart-tile-flex'
        style={{
          flex: flex
        }}
      >
        <div className='header'>
          <h4>{title}</h4>
        </div>
        <div className='content'>
          <div className='avi-chart-container-flex'>{children}</div>
        </div>
      </div>
    );
  }
}

export default AVITileFlex;
