import "./DbrdPilotOffice.scss";

import * as d3 from "d3";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { NumUtils } from "../../chart-components/ChartUtils/NumUtils";
import { BarChartGroupedNew } from "../../chart-components/Charts/BarChartGroupedNew";
import { BarChartStacked } from "../../chart-components/Charts/BarChartStacked";
import { CBoxMenu } from "../../chart-components/Charts/CBoxMenu";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { PieChart } from "../../chart-components/Charts/PieChart";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { FilterToFromYear } from "../../filters/FilterToFromYear";
import withRouter from "../../hocs/withRouter";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../types/DashboardFilter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";

class DbrdPilotOfficeBase extends Component<any, any> {
  static dashboardRoute = "losoldermannskap";

  static dashboardFilters(
    filter: DashboardFilter,
    setFilter: SetDashboardFilterFunc
  ) {
    return {
      helpMessage:
        "Dette dashboardet gjør det mulig å analysere flere år samtidig. Velg et eller flere år, f.eks. 2010-2018",
      controls: (
        <FilterToFromYear
          key="flt"
          firstYear={2010}
          filter={filter}
          setFilter={setFilter}
        />
      ),
    };
  }

  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: null,
    };
  }

  static dashboardValidation(filter) {
    if (filter.fromYear && filter.toYear) {
      return true;
    }
  }

  static propTypes = {
    location: PropTypes.object,
  };

  /**
   * TestRoute
   *
   * @param {*} props An object looking like this {a:, b:}
   */
  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
      locationData: [],
    };
  }

  componentDidMount() {
    const { fromYear, toYear } = this.props.location.state;
    try {
      if (fromYear && toYear) {
        var mergeRequests = [
          DataLoader.post(
            process.env.REACT_APP_DASHBOARD_WS_API +
              "/api/pilotage/aggregated/for-years",
            {
              FromYear: fromYear,
              ToYear: toYear,
            }
          ),
        ];

        Promise.all(mergeRequests)
          .then((jsonResponses) => {
            // Check that all responses are successful
            var success = true;

            jsonResponses.forEach((r) => {
              if (!r.success) {
                if (success) {
                  success = false;
                }
              }
            });

            if (!success) {
              throw new Error("Error loading aggregated pilotage data");
            }

            // console.log(jsonResponses[0].data[0]);

            jsonResponses[0].data.forEach((pilotage) => {
              pilotage.pdate = new Date(pilotage.pdate);
              // TODO: Horten manglar kopling til Losoldermannskap - førebels hardkodast dette
              pilotage.podsname = pilotage.podsname || "Oslofjorden";
              pilotage.year = pilotage.pdate.getFullYear();
              pilotage.month = pilotage.pdate.getMonth() + 1;
            });

            this.setState({
              chartData: crossfilter(jsonResponses[0].data),
            });
          })
          .catch((error) => {
            console.warn(error);
            this.setState({
              chartData: crossfilter([]),
            });
          });
      } else {
        throw new Error("No data available yet");
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }
  /**
   *
   *
   * @returns
   * @memberof DbrdPilotOffice
   */
  render() {
    const { chartData } = this.state;
    const { fromYear, toYear } = this.props.location.state;

    if (!chartData || chartData.size() === 0) {
      return <Loader chartData={chartData} />;
    }

    var dimPods = chartData.dimension((d) => {
      return d.podsname;
    });

    // return;

    var voyagesByPods = dimPods.group().reduceSum((d) => d.voyages);

    var dimType = chartData.dimension((d) => {
      return d.ptype;
    });
    var voyagesByType = dimType.group().reduceSum((d) => d.voyages);

    var dimYearShipType = chartData.dimension((d) => [d.year, d.sgroup2]);
    var voyagesByYearShipType = dimYearShipType
      .group()
      .reduceSum((d) => d.voyages);

    var dimCount = chartData.dimension((d) => d.year);

    var dimYear = chartData.dimension((d) => d.year);

    var dimMonthOfYear = chartData.dimension((d) => d.month);
    var dimStationAndType = chartData.dimension((d) => {
      return [d.podsname, d.psdname, d.ptype];
    });

    var voyagesByStationAndType = dimStationAndType.group().reduce(
      (p, v, nf) => {
        p.sum += v.voyages;
        p[v.year] += v.voyages;
        return p;
      },
      (p, v, nf) => {
        p.sum -= v.voyages;
        p[v.year] -= v.voyages;
        return p;
      },
      () => {
        var o = {
          sum: 0,
        };
        for (var i = fromYear; i <= toYear; i++) {
          o[i] = 0;
        }
        return o;
      }
    );

    var voyagesByStationAndTypeNonEmpty = GroupUtils.RemoveEmptyBinsByKey(
      voyagesByStationAndType,
      "sum"
    );

    var valfunc = (i) => {
      return (d) => d.value[i];
    };
    var fmtfunc = (i) => {
      return (d) => NumUtils.integer(d.value[i]);
    };

    var extraCols: any[] = [];
    var yearRange: any[] = [];
    for (var currentYear = fromYear; currentYear <= toYear; currentYear++) {
      extraCols.push({
        label: "" + currentYear,
        format: fmtfunc(currentYear),
        value: valfunc(currentYear),
      });
      yearRange.push(currentYear);
    }

    return (
      <div className="AppView-noMap">
        <AVIDashboard
          title={"Losoldermannskap "}
          desc={`Statistikk over losoppdrag gruppert etter Losoldermannskap fra ${fromYear} til og med ${toYear}`}
          spacing={10}
          keyName="voyages"
          type="sum"
          units="Losoppdrag"
          group={dimCount.groupAll()}
          cfilter={chartData}
          filter={this.props.location.state}
          useFlex
        >
          <AVIRow>
            <AVICol>
              <CBoxMenu
                chartTitle="Avgrens til losoldermannskap"
                width={4}
                height={0.5}
                dimension={dimPods}
                group={voyagesByPods}
                multiple
                filterPrefix="Losoldermannskap"
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartGroupedNew
                chartTitle="Lospliktige seilas etter skipstype"
                width={2}
                height={3}
                dimension={dimYearShipType}
                group={voyagesByYearShipType}
                useFlex
              />
            </AVICol>
            <AVICol>
              <PieChart
                chartTitle="Type oppdrag"
                dimension={dimType}
                group={voyagesByType}
                width={2}
                height={1.5}
                radius={150}
                filterPrefix="Oppdragstype"
                useFlex
              />
              <BarChartStacked
                chartTitle="Lospliktige seilas etter år"
                dimension={dimYear}
                xAxisTickValues={yearRange}
                width={2}
                height={1.5}
                gap={2}
                filterPrefix="År"
                stackKey="sgroup2"
                valueKey="voyages"
                renderLabel
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartStacked
                chartTitle="Lospliktige seilas etter måned i året"
                dimension={dimMonthOfYear}
                xAxisTickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                width={4}
                height={1.5}
                gap={2}
                filterPrefix="Måned i året"
                stackKey="sgroup2"
                valueKey="voyages"
                renderLabel
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <DataTable
                chartTitle="Lospliktige seilas etter losoldermannskap og år"
                dimension={voyagesByStationAndTypeNonEmpty}
                group={(d) => ""}
                order={d3.ascending}
                showGroups={false}
                sortBy={(d) => d.key[1] + "/" + d.key[2]}
                width={4}
                height={4}
                size={Infinity}
                useFlex
                columns={[
                  {
                    label: "Losoldermannskap",
                    format: (d) => d.key[0],
                  },
                  {
                    label: "Losstasjon",
                    format: (d) => d.key[1],
                  },
                  {
                    label: "Seilastype",
                    format: (d) => d.key[2],
                  },
                  {
                    label: "Antall seilas",
                    format: (d) => NumUtils.integer(d.value.sum),
                    value: (d) => d.value.sum,
                  },
                ].concat(extraCols)}
              />
            </AVICol>
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdPilotOffice = withRouter(DbrdPilotOfficeBase);

export default DbrdPilotOffice;
