import { useCallback } from "react";
import { FormGroup, FormLabel } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { useDataContext } from "../context/DataContext";
import { EventLike } from "../types/Shims";
import { Municipality } from "../types/Municipality";
import { createEventLike } from "../utils/createEventLike";

export type MunicipalityOption = {
  label: string;
  value: string;
  geoJson: Municipality;
};

export type MunicipalityTypeaheadProps = {
  label?: string;
  placeholder?: string;
  multiple?: boolean;
  defaultValue?: MunicipalityOption;
  onChange: (evt: EventLike<MunicipalityOption[]>) => void;
  name: string;
};

export function MunicipalityTypeahead({
  label = "Velg kommune",
  placeholder = "Skriv noen bokstaver...",
  multiple = false,
  defaultValue,
  name,
  onChange,
}: MunicipalityTypeaheadProps) {
  const { municipalities } = useDataContext();

  const loadOptions = useCallback(
    (
      queryTerm: string,
      setOptions: (options: MunicipalityOption[]) => void
    ) => {
      var opts: MunicipalityOption[] = municipalities
        .filter((municipality) =>
          municipality.properties.name
            .toLowerCase()
            .startsWith(queryTerm.toLowerCase())
        )
        .map((municipality) => ({
          label: municipality.properties.name,
          value: municipality.properties.komm,
          geoJson: municipality,
        }));
      setOptions(opts);
    },
    [municipalities]
  );

  return (
    <FormGroup>
      <FormLabel>{label}</FormLabel>
      <AsyncSelect<MunicipalityOption, boolean>
        cacheOptions
        placeholder={placeholder}
        loadingMessage={(d) => "Søker"}
        loadOptions={loadOptions}
        defaultOptions
        defaultValue={defaultValue}
        isMulti={multiple}
        onChange={(value) => {
          const val = multiple
            ? (value as MunicipalityOption[])
            : ([value] as MunicipalityOption[]);
          onChange(createEventLike<MunicipalityOption[]>(name, val));
        }}
      />
    </FormGroup>
  );
}
