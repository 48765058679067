export function roundToNearest(value: number, N: number): number {
  return Math.round(value / N) * N;
}

export function floorToNearest(value: number, N: number): number {
  return Math.floor(value / N) * N;
}

export function ceilToNearest(value: number, N: number): number {
  return Math.ceil(value / N) * N;
}
