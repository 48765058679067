import './ExclusionEditorView.scss';

import React from 'react';
import { ExclusionEditor } from './ExclusionEditor';
import withRouter from '../../hocs/withRouter';

class ExclusionEditorViewBase extends React.Component {
  render () {
    return (
      <div className='exclusion-editor-view'>
        <h1>Ekskluder, reklassifiser skip</h1>
        <ExclusionEditor />
      </div>
    );
  }
}

export const ExclusionEditorView = withRouter(ExclusionEditorViewBase);

export default ExclusionEditorView;
