import BaseLayer from "ol/layer/Base";
import { useMemo } from "react";
import { DropdownDivider, DropdownHeader } from "react-bootstrap";
import { KyvLayerSwitcherItemLayer } from "./KyvLayerSwitcherItemLayer";
import LayerGroup from "ol/layer/Group";

export type KyvLayerSwitcherItemGroupProps = {
  layerGroup: LayerGroup;
};

export function KyvLayerSwitcherItemLayerGroup({
  layerGroup,
}: KyvLayerSwitcherItemGroupProps) {
  const layers = useMemo(() => {
    return layerGroup.getLayersArray();
  }, [layerGroup]);
  return (
    <>
      <DropdownDivider />
      <DropdownHeader>{layerGroup.get("title") ?? ""}</DropdownHeader>
      {layers.length > 0 &&
        layers.map((lyr: BaseLayer, lyrIdx: number) => {
          return (
            <KyvLayerSwitcherItemLayer
              key={`layer-switcher--item-layer-in-group-${lyrIdx}`}
              layer={lyr}
            />
          );
        })}
    </>
  );
}
