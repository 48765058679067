import request from "superagent";
import { getAuthState } from "../auth-components/AuthLib";
import { isArrayN } from "./array/isArrayN";

// curl 'https://kystinfo.no/WebServices/client/DataView.asmx/ReadAny?_dc=1730893809779' \
// -H 'Accept: */*' \
// -H 'Accept-Language: en-US,en;q=0.9' \
// -H 'Connection: keep-alive' \
// -H 'Content-type: application/json; charset=UTF-8 application/json' \
// -H 'Cookie: lng=en' \
// -H 'Origin: https://kystinfo.no' \
// -H 'Referer: https://kystinfo.no/kystdatahuset' \
// -H 'Sec-Fetch-Dest: empty' \
// -H 'Sec-Fetch-Mode: cors' \
// -H 'Sec-Fetch-Site: same-origin' \
// -H 'Sec-GPC: 1' \
// -H 'User-Agent: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/130.0.0.0 Safari/537.36' \
// -H 'X-Adaptive-Gui: 914e96dc-715a-4fc6-820c-06d0784674b7' \
// -H 'X-Adaptive-SRID: 32633' \
// -H 'X-Requested-With: XMLHttpRequest' \
// -H 'gm_lang_code: en' \
// -H 'gm_session_id: 155bc38729b6d91d7b0cb90d71ccbb6670a809cf' \
// -H 'sec-ch-ua: "Chromium";v="130", "Brave";v="130", "Not?A_Brand";v="99"' \
// -H 'sec-ch-ua-mobile: ?0' \
// -H 'sec-ch-ua-platform: "Windows"' \
// --data-raw '{"request":{"srid":"32633","theme_uuid":"0fe4d1b8-a8a4-4912-a395-a6574bfdde6e","columns":["id","title","abstract","usage_description","keywords","url","contact_name","contact_email","contact_organisation","contact_role","_acl","active"],"map_dictionaries":true,"start":0,"limit":100,"filter":{"filterColumns":[],"sortColumns":[],"secondFilter":[],"defaultFilter":[],"distinctColumns":[]},"extraParams":[]}}'

type KystinfoDigiTheme = {
  uuid: string;
  columns: string[];
};

type MetadataThemeType = {
  service: KystinfoDigiTheme;
  dataset: KystinfoDigiTheme;
  application: KystinfoDigiTheme;
};

export type KyvMetadataRecord = {
  title: string;
  abstract: string;
  keywords: string[];
  contact_organisation: string;
  resource_type: "dataset" | "service" | "application";
  [key: string]: any;
};

const MetadataThemes: MetadataThemeType = {
  service: {
    uuid: "47fdc880-d681-4c1e-bd20-c42d1b8cc2d0",
    columns: [
      "id",
      "title",
      "abstract",
      "usage_description",
      "keywords",
      "service_type",
      "url",
      "contact_name",
      "contact_email",
      "contact_organisation",
      "contact_role",
      "_acl",
      "active",
    ],
  },
  dataset: {
    uuid: "28da46a7-ca39-4875-81bb-4c1f20927130",
    columns: [
      "id",
      "title",
      "abstract",
      "usage_description",
      "url",
      "contact_name",
      "contact_email",
      "contact_organisation",
      "contact_role",
      "keywords",
      "geographic_extent",
      "temporal_extent",
      "use_constraints",
      "access_control",
      "is_dok_dataset",
      "publish_geonorge",
      "_acl",
      "active",
    ],
  },
  application: {
    uuid: "0fe4d1b8-a8a4-4912-a395-a6574bfdde6e",
    columns: [
      "id",
      "title",
      "abstract",
      "usage_description",
      "keywords",
      "url",
      "contact_name",
      "contact_email",
      "contact_organisation",
      "contact_role",
      "_acl",
      "active",
    ],
  },
};

/**
 * Load metadata for a specific resource type
 *
 * @param resource_type
 * @returns
 */
export async function readMetadata(
  resource_type: keyof MetadataThemeType
): Promise<KyvMetadataRecord[]> {
  const { gm_session_id } = getAuthState();
  const digiTheme = MetadataThemes[resource_type];

  if (gm_session_id) {
    const res = await request
      .post("https://kystinfo.no/WebServices/client/DataView.asmx/ReadAny")
      .send({
        request: {
          srid: "32633",
          theme_uuid: digiTheme.uuid,
          columns: digiTheme.columns,
          map_dictionaries: true,
          start: 0,
          limit: 1000,
          filter: {
            filterColumns: [],
            sortColumns: [],
            secondFilter: [],
            defaultFilter: [],
            distinctColumns: [],
          },
          extraParams: [],
        },
      })
      .set("accept", "json")
      .set("gm_session_id", gm_session_id);

    if (res.ok && isArrayN(res.body["d"]["records"], 0)) {
      res.body["d"]["records"].forEach((rec) => {
        rec["resource_type"] = resource_type;
      });
      return res.body["d"]["records"];
    } else {
      throw new Error("Error response");
    }
  } else {
    throw new Error("Not authenticated");
  }
}
