export type BaseChartType = {
  dimension: any;
  group?: any;
};

export const SimpleBaseMixin = function (chart: any, props: BaseChartType) {
  const { dimension, group } = props;

  if (!chart.hasOwnProperty("anchorName")) {
    chart.anchorName = () => {
      return `chart-${new Date().getTime()}`;
    };
  }

  chart.dimension(dimension);
  if (group) {
    chart.group(group);
  }

  return chart;
};
