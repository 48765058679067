import "./CBoxMenu.scss";

import React, { Component } from "react";

import { AVISelectTile } from "../Layout/AVISelectTile";
import { BaseMixin } from "../Mixins/BaseMixin";
// import PropTypes from 'prop-types';
import * as dc from "dc";
import { CrossfilterRecord } from "../../custom";

export type CBoxMenuProps = typeof CBoxMenu.defaultProps & {
  filterDisplayed: () => void;
  multiple: boolean;
  order: (a: any, b: any) => number;
  promptText: string;
  chartTitle: string;
  dimension: any;
  group: any;
  filterPrefix: string;
  width: number;
  height: number;
  keyAccessor: (d: any) => string | number;
  valueAccessor: (d: any) => string | number;
  useFlex: boolean;
};
export type CBoxMenuState = {
  chartRef: any;
};

export class CBoxMenu extends Component<CBoxMenuProps, CBoxMenuState> {
  // static propTypes = {
  //   filterDisplayed: PropTypes.func,
  //   multiple: PropTypes.bool,
  //   order: PropTypes.func,
  //   promptText: PropTypes.string,
  //   chartTitle: PropTypes.string,
  //   width: PropTypes.number,
  //   height: PropTypes.number,
  //   keyAccessor: PropTypes.func,
  //   valueAccessor: PropTypes.func,
  //   useFlex: PropTypes.bool
  // };

  static defaultProps = {
    filterDisplayed: function (d: CrossfilterRecord) {
      return true;
    },
    multiple: false,
    order: function (a: any, b: any) {
      return a.key > b.key ? 1 : b.key > a.key ? -1 : 0;
    },
    promptText: "Please choose something",
    width: 1,
    height: 1,
    keyAccessor: (d: CrossfilterRecord) => d.key,
    valueAccessor: (d: CrossfilterRecord) => d.value,
    useFlex: false,
  };

  chartRef: any = null;
  chart: any = null;

  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.chart = null;
    this.getChart = this.getChart.bind(this);
  }

  getChart() {
    return this.chart;
  }

  componentDidMount() {
    this.chart = dc.cboxMenu(this.chartRef.current);

    BaseMixin(this.chart, this.props);

    const {
      filterDisplayed,
      multiple,
      order,
      promptText,
      keyAccessor,
      valueAccessor,
    } = this.props;
    this.chart
      .keyAccessor(keyAccessor)
      .valueAccessor(valueAccessor)
      .filterDisplayed(filterDisplayed)
      .multiple(multiple)
      .order(order)
      .promptText(promptText);
    this.chart.render();
  }

  render() {
    const { chartTitle, width, height, useFlex } = this.props;
    return (
      <AVISelectTile
        title={chartTitle}
        getChart={this.getChart}
        width={width}
        height={height}
        useFlex={useFlex}
      >
        <div className="avi-chart" ref={this.chartRef} />
      </AVISelectTile>
    );
  }
}
