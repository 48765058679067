import "./CesiumMap.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { Viewer, createWorldTerrain } from "cesium";
import * as Cesium from "cesium";
import "cesium/Build/Cesium/Widgets/widgets.css";
import PropTypes from "prop-types";

Cesium.Ion.defaultAccessToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI5Yzk3Y2U0MS1jNzBkLTRmZGQtOGEyNS1hNzdkYjYxMDM1ZTYiLCJpZCI6MTcxODMsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1NzE3NTcxMzZ9.qH3qNhBsNAMgFQmeSafPC-qKUTa72fAdcsLIxNgPO44";

export function CesiumMap({
  featuresCrossfilter,
  showTerrain,
  showShadows,
  showBuildings,
  mmsi,
}) {
  const cref = useRef(null);

  const [init, setInit] = useState(false);

  const drawPoints = useCallback((viewer, cf) => {
    // console.log(cf.allFiltered());
    if (cf.size() > 0) {
      cf.allFiltered().forEach((feature) => {
        if (viewer.entities.getById(feature.properties.mmsi)) {
          // console.log("Exists");
        } else {
          const position = Cesium.Cartesian3.fromDegrees(
            feature.geometry.coordinates[0],
            feature.geometry.coordinates[1]
          );

          const heading = Cesium.Math.toRadians(feature.properties.cog);
          const pitch = 0;
          const roll = 0;
          const hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);
          const orientation = Cesium.Transforms.headingPitchRollQuaternion(
            position,
            hpr
          );

          viewer.entities.add({
            position,
            orientation,
            model: {
              uri: "/fishing_boat/scene.gltf",
              minimumPixelSize: 20,
              maximumScale: 2,
              scale: 0.05,
              heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
            },
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    // Already initialized
    if (init === true) return;

    var f = {
      lon: 5.1136,
      lat: 61.9355,
    };

    let t = featuresCrossfilter
      .allFiltered()
      .find((f) => f.properties.mmsi === mmsi);

    if (t) {
      f.lon = t.geometry.coordinates[0];
      f.lat = t.geometry.coordinates[1];
    }

    // console.log(f);

    let viewerOptions = {
      timeline: false,
      geocoder: false,
      homeButton: false,
      animation: false,
    };

    if (showTerrain) {
      let terrainOptions = {};

      if (showShadows) {
        terrainOptions = {
          requestVertexNormals: true,
          requestWaterMask: true,
        };
      }

      viewerOptions.terrainProvider = createWorldTerrain(terrainOptions);
    }

    const viewer = new Viewer(cref.current, viewerOptions);

    viewer.scene.screenSpaceCameraController.enableCollisionDetection = true;

    if (showShadows) {
      viewer.scene.globe.enableLighting = true;
      viewer.shadows = true;
    }

    if (showBuildings) {
      viewer.scene.primitives.add(
        Cesium.createOsmBuildings()
      );
    }

    const center = Cesium.Cartesian3.fromDegrees(f.lon, f.lat, 50);
    const transform = Cesium.Transforms.eastNorthUpToFixedFrame(center);
    viewer.scene.camera.lookAtTransform(
      transform,
      new Cesium.HeadingPitchRange(
        Cesium.Math.toRadians(325),
        Cesium.Math.toRadians(-20),
        2500
      )
    );

    setInit(true);

    featuresCrossfilter.onChange(() =>
      drawPoints(viewer, featuresCrossfilter)
    );

    drawPoints(viewer, featuresCrossfilter);

    // Cleanup function
    // return () => {
    //   console.log("cleaning up");
    //   // Remove handler from scene
    //   handler.destroy();
    //   handler = null;

    //   // remove handler from crossfilter
    //   fcChangeHandler();
    // };
  }, [
    init,
    setInit,
    featuresCrossfilter,
    drawPoints,
    showShadows,
    showBuildings,
    showTerrain,
    mmsi,
  ]);

  return <div className="cesium-map" ref={cref}></div>;
}

CesiumMap.propTypes = {
  featuresCrossfilter: PropTypes.object.isRequired,
  mmsi: PropTypes.number.isRequired,
  showTerrain: PropTypes.bool,
  showShadows: PropTypes.bool,
  showBuildings: PropTypes.bool,
};

CesiumMap.defaultProps = {
  showTerrain: false,
  showShadows: false,
  showBuildings: false,
};
