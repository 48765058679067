import Select from "react-select";
import AVIChartTile from "../Layout/AVIChartTile";
import React, { useCallback, useMemo } from "react";
import { isArrayN } from "../../utils/array/isArrayN";
import * as dc from "dc";

export type TypeaheadMenuProps = {
  chartTitle: string,
  dimension: any;
  isMulti?: boolean;
};

export function TypeaheadMenu({
  chartTitle,
  dimension,
  isMulti = false,
}: TypeaheadMenuProps) {
  const options = useMemo(() => {
    const _options: any[] = [];
    dimension.top(Infinity).forEach((_dimRow: any) => {
      if (_options.findIndex((_o) => _o.value === _dimRow.arr_loc) === -1) {
        _options.push({
          value: _dimRow.arr_loc,
          label: _dimRow.arr_loc,
        });
      }
    });
    _options.sort((a: any, b: any) => {
      if (a.label > b.label) return 1;
      if (b.label > a.label) return -1;
      return 0;
    });
    return _options;
  }, [dimension]);

  const handleChange = useCallback(
    (selectedOptions: any[]) => {
      if (isArrayN(selectedOptions, 0, true)) {
        dimension.filterAll();
        dc.redrawAll();
      } else if (isArrayN(selectedOptions, 1)) {
        const stringList = selectedOptions.map(
          (selectedOption: any) => selectedOption.value
        );
        dimension.filterFunction((d: any) => {
          return stringList.indexOf(d) > -1;
        });
        dc.redrawAll();
      }
    },
    [dimension]
  );

  return (
    <AVIChartTile
      title={chartTitle}
      getChart={()=>undefined}
      height={1}
      useFlex
      info={""}
      noTools
    >
      <Select
        options={options}
        isMulti={isMulti}
        isClearable
        isSearchable
        onChange={handleChange}
      />
    </AVIChartTile>
  );
}
