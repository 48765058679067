import "../AVIChartCommon.scss";
import "./AVITableTile.scss";

import React, { Component } from "react";

import { AVIDashboardDimContext } from "./AVIDashboardDimContext";
import { DimUtils } from "../ChartUtils/DimUtils";
import PropTypes from "prop-types";
import { json2excel } from "js2excel";
import { AVITileHeader } from "./AVITileHeader";
import { AVITileHeaderDropdownItem } from "./AVITileHeaderDropdownItem";

export class AVITableTileBase extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    getTableData: PropTypes.func.isRequired,
    dims: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
    children: PropTypes.any,
    description: PropTypes.string,
    showToolbar: PropTypes.bool,
    useFlex: PropTypes.bool,
    resetFilter: PropTypes.func
  };

  static defaultProps = {
    width: 4,
    showToolbar: true,
    useFlex: false,
    resetFilter: undefined
  };

  constructor(props) {
    super(props);
    this.downloadTable = this.downloadTable.bind(this);
  }

  pad(s, n) {
    s = "" + s;
    while (s.length < n) {
      s = "0" + s;
    }
    return s;
  }

  getTimestamp() {
    var d = new Date();
    var str = "" + d.getFullYear();
    str += this.pad(d.getMonth() + 1, 2);
    str += this.pad(d.getDay(), 2);
    str += this.pad(d.getHours(), 2);
    str += this.pad(d.getMinutes(), 2);
    return str;
  }

  downloadTable() {
    const { title, getTableData } = this.props;

    var ltitle = title.substring(0, 17) + "-" + this.getTimestamp();

    try {
      var data = getTableData();

      // NB! Varianle carrying data must be named 'data' in object below
      json2excel({
        data,
        name: ltitle,
        formateDate: "yyyy/mm/dd",
      });
    } catch (e) {
      console.error("Error exporting table" + e);
    }
  }

  render() {
    const {
      children,
      title,
      description,
      width,
      height = 1,
      dims,
      showToolbar,
      useFlex,
      resetFilter
    } = this.props;

    var hws = DimUtils.getDims(dims, width, height);
    let tableTileStyle = {};

    if (useFlex) {
      tableTileStyle = {
        flex: width,
        minWidth: "20vw",
      };
    } else {
      tableTileStyle = {
        width: "100%",
        margin: hws.spacing,
      };
    }

    return (
      <div className="p-3 kyv--bg-header" style={tableTileStyle}>
        <AVITileHeader title={title} description={description} resetFilter={resetFilter}>
          {showToolbar && (
            <AVITileHeaderDropdownItem onClick={this.downloadTable}>
              Last ned tabell (MS Excel)
            </AVITileHeaderDropdownItem>
          )}
        </AVITileHeader>
        <div className="kyv--scrollable-x kyv--scrollbar">
          <div className="w-100">{children}</div>
        </div>
      </div>
    );
  }
}

const AVITableTile = function (props) {
  return (
    <AVIDashboardDimContext.Consumer>
      {(dims) => <AVITableTileBase {...props} dims={dims} />}
    </AVIDashboardDimContext.Consumer>
  );
};

export { AVITableTile };
