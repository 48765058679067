import { Geometry } from "ol/geom";
import { useCallback, useState } from "react";
import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
} from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";
import { EventLike } from "../../types/Shims";
import { createEventLike } from "../../utils/createEventLike";
import { validateExtent } from "../../utils/geomUtils";

export type ExtentFieldProps = {
  name: string;
  value: string;
  srid: number;
  onChange: (evt: EventLike<Geometry>) => void;
};

export function ExtentField({ value, onChange, name, srid }: ExtentFieldProps) {
  const [extent, setExtent] = useState(value);
  const [valid, setValid] = useState(false);

  const handleChange = useCallback(
    (evt: EventLike<string>) => {
      setExtent(evt.target.value);
      let geom = validateExtent(evt.target.value, srid);
      if (geom !== false) {
        setValid(true);
        onChange(createEventLike(name, geom));
      } else {
        setValid(false);
      }
    },
    [name, onChange, srid]
  );

  return (
    <FormGroup>
      <FormLabel>Skriv eller lim inn dekningsrektangel</FormLabel>
      <InputGroup hasValidation>
        <FormControl
          as="textarea"
          onChange={handleChange}
          rows={5}
          value={extent}
          isValid={valid}
          isInvalid={!valid}
        ></FormControl>
        <Button
          onClick={async () => {
            if (typeof navigator.clipboard?.readText === "function") {
              let clipboardText = await navigator.clipboard.readText();
              setExtent(clipboardText);
            }
          }}
        >
          <i className="bi bi-clipboard"></i>
        </Button>
        <Feedback type="invalid">
          Oppgitt dekningsrektange er ugyldig. Skriv inn fire tall med punktum
          som desimalseparator og valgfri feltseparator
        </Feedback>
      </InputGroup>
    </FormGroup>
  );
}
