import React, { Component } from "react";

import { AVIFactTileFlex } from "./../Layout/AVIFactTileFlex";
import { BaseMixin } from "../Mixins/BaseMixin";
import { NumUtils } from "../ChartUtils/NumUtils";
import PropTypes from "prop-types";
import * as dc from "dc";

export class NumberDisplay extends Component {
  static propTypes = {
    group: PropTypes.object.isRequired,
    valueAccessor: PropTypes.func,
    formatNumber: PropTypes.func,
    html: PropTypes.object,
    chartTitle: PropTypes.string,
    flex: PropTypes.number,
    units: PropTypes.string,
  };

  static defaultProps = {
    valueAccessor: (d) => d,
    formatNumber: NumUtils.integer,
    html: {
      one: "",
      some: "",
      none: "",
    },
  };

  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.chart = null;
  }

  
  componentDidMount() {
    this.chart = dc.numberDisplay(this.chartRef.current);
    
    BaseMixin(this.chart, this.props);
    
    const { valueAccessor, formatNumber, html } = this.props;
    
    this.chart
    .valueAccessor(valueAccessor)
    .formatNumber(formatNumber)
    .html(html)
    .render();
  }

  render() {
    const { chartTitle, flex, units } = this.props;    
    return (
      <AVIFactTileFlex title={chartTitle} flex={flex} units={units}>
        <div className="avi-chart" ref={this.chartRef} />
      </AVIFactTileFlex>
    );
  }
}
