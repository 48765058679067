import * as olControl from "ol/control";
import * as olInteraction from "ol/interaction";
import * as olProj from "ol/proj";

import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import Map from "ol/Map";
import View from "ol/View";
import { MapProvider } from "../../context/MapProvider";
import { DcExtTrackMapChart } from "../DcExt/DcExtTrackMapChart";
import { AVIMapTile } from "../Layout/AVIMapTile";
import { LayerSwitcherMixin } from "../Mixins/LayerSwitcherMixin";
import { SimpleBaseMixin } from "../Mixins/SimpleBaseMixin";
import { GeoJSONFeature } from "ol/format/GeoJSON";

export type TrackMapChartProps = PropsWithChildren & {
    intersectGeom: string[]|GeoJSONFeature[];
    chartData: any[];
    chartTitle: string;
    colorScale?: ((v: any) => string|d3.ScaleOrdinal<string, any>);
    categoryProperty: string;
    colorScheme?: string[];
    maxFeatures?: number;
    dimension: any;
    useFlex?: boolean;
    allowFullscreen?: boolean;
    height: number,
    width: number,
  };

export function TrackMapChart({
  intersectGeom,
  chartData,
  chartTitle,
  height,
  width,
  colorScale,
  dimension,
  categoryProperty,
  colorScheme,
  maxFeatures,
  useFlex,
  allowFullscreen,
  children = null,
}: TrackMapChartProps) {
  const [chart, setChart] = useState<any>();
  const [map, setMap] = useState<Map>();

  const chartRef = useRef(null);

  const getTrackData = useCallback(() => {
    return chart.geojson();
  }, [chart]);

  const getMap = useCallback(() => {
    return map;
  }, [map]);

  const getChart = useCallback(() => {
    return chart;
  }, [chart]);

  useEffect(() => {
    if (!chartRef.current) return;
    var zoomPt = [15, 61];

    const _map = new Map({
      target: chartRef.current,
      interactions: olInteraction.defaults({ mouseWheelZoom: false }),
      controls: olControl.defaults({
        attributionOptions: {
          collapsible: false,
        },
      }),
      view: new View({
        center: olProj.transform(
          [zoomPt[0], zoomPt[1]],
          "EPSG:4326",
          "EPSG:3857"
        ),
        zoom: 5,
      }),
    });

    LayerSwitcherMixin(_map);

    const _chart: any = new DcExtTrackMapChart({
      map: _map,
      intersectGeom: intersectGeom,
      chartData: chartData,
      colorScale: colorScale,
      colorScheme: colorScheme,
      categoryProperty: categoryProperty,
      maxFeatures: maxFeatures,
    });

    SimpleBaseMixin(_chart, { dimension });

    _chart.render();
    setChart(_chart);
    setMap(_map);

    return () => {
      _map?.setTarget(undefined);
      _map?.dispose();
    };
  }, [
    categoryProperty,
    chartData,
    colorScale,
    colorScheme,
    dimension,
    intersectGeom,
    maxFeatures,
  ]);

  return (
    <AVIMapTile
      title={chartTitle}
      height={height}
      width={width}
      passlineWkt={intersectGeom}
      getTrackData={getTrackData}
      getMap={getMap}
      getChart={getChart}
      useFlex={useFlex}
      allowFullscreen={allowFullscreen}
    >
      <div className="avi-map" ref={chartRef}>
        {map && <MapProvider map={map}>{children}</MapProvider>}
      </div>
    </AVIMapTile>
  );
}
