import "rc-slider/assets/index.css";
import "./DbrdReplayRealtime.scss";

import React, { Component } from "react";

import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { ReplayRealtimeMapChart } from "../../chart-components/ChartsExt/ReplayRealtimeMapChart";
import withRouter from "../../hocs/withRouter";
import crossfilter from "crossfilter2";
import { SelectMenu } from "../../chart-components/Charts/SelectMenu";
import { Loader, SuperagentProgress } from "../../ui-components/Loader/Loader";
import { KYVGroupings } from "../../utils/KYVGroupings";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { NumUtils } from "../../chart-components/ChartUtils/NumUtils";
import { DashboardFilter } from "../../types/DashboardFilter";

class DbrdReplayRealtimeBase extends Component<any, any> {
  static dashboardRoute = "sporing-sanntid";

  static dashboardFilters(filter, setFilter) {
    return {
      helpMessage: "Sporing av skipstrafikk i sanntid",
      controls: [],
    };
  }

  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: null,
    };
  }

  sliderTimeout: any;
  reloadInterval: any;

  constructor(props) {
    super(props);

    this.state = {
      aisClass: [],
      shipType: [],
      minLength: 0,
      maxLength: 1000,
      cf: null,
    };
    this.sliderTimeout = null;
    this.reloadInterval = null;
    this.reloadFeatures = this.reloadFeatures.bind(this);
  }

  static dashboardValidation(filter: DashboardFilter) {
    return true;
  }

  static propTypes = {};

  timeout = null;

  reportProgress(progressData: SuperagentProgress) {
    this.setState({
      progressData: progressData,
    });
  }

  // Scale for ship types
  shipTypeScale(shipType) {
    if (shipType === 30) {
      return "Fiskefartøy";
    } else if (
      (shipType >= 31 && shipType <= 35) ||
      (shipType >= 40 && shipType <= 59)
    ) {
      return "Offshorefartøy og spesialfartøy";
    } else if (shipType >= 60 && shipType <= 69) {
      return "Passasjerskip";
    } else if (shipType >= 70 && shipType <= 79) {
      return "Lasteskip";
    } else if (shipType >= 80 && shipType <= 89) {
      return "Tankskip";
    } else if (shipType === 36 || shipType === 37) {
      return "Fritidsfartøy";
    } else {
      return "Ukjent";
    }
  }

  async reloadFeatures() {
    // Store the max age
    let tMinus20 = new Date();
    tMinus20.setMinutes(tMinus20.getMinutes() - 20);

    // Store the time of the request to use for next call, added to state at end
    let currDate = new Date();

    let { cf, prevDate = null } = this.state;

    // If first load, load all data for past 20 minutes
    if (prevDate === null) {
      prevDate = tMinus20.toISOString();
    }

    // Load data response
    let res = await fetch(
      `https://geomap05.kystverket.no/api/ais/realtime/tracks/${prevDate}`
    );

    // Load json frfom response
    const geojson = await res.json();

    if (Array.isArray(geojson?.features)) {
      // Add ship_type_labels
      geojson.features.forEach((feature) => {
        feature.properties.ship_type_label = this.shipTypeScale(
          feature.properties.ship_type
        );
        return feature;
      });

      if (cf === null && geojson.features.length > 0) {
        // If first load, create crossfilter and set all data
        // console.log("Creating new cf");
        this.setState({
          cf: crossfilter(geojson.features),
        });
      } else {
        // If subsequent load, remove duplicate mmsis from existing crossfilter

        // Get unique MMSIs in the current loaded data
        var mmsis = geojson.features.reduce((p, f) => {
          p[f.properties.mmsi] = undefined;
          return p;
        }, {});

        let tMin20ISO = new Date(tMinus20.toISOString().slice(0, -1));
        // Remove from crossfilter if outdated or if in most recent load
        cf.remove((f) => {
          return (
            mmsis.hasOwnProperty(f.properties.mmsi) ||
            new Date(f.properties.date_time_utc) < tMin20ISO
          );
        });

        // Add new features
        cf.add(geojson.features);
      }
      // Finally, set the timestamp so that it is available from state for the next load
      this.setState({
        prevDate: currDate.toISOString(),
      });
    }
  }

  componentDidMount() {
    this.reloadFeatures();
    this.reloadInterval = setInterval(() => {
      // Replace data in crossfilter
      this.reloadFeatures();
    }, 15000);
  }

  componentWillUnmount() {
    clearTimeout(this.reloadInterval);
  }

  render() {
    const { cf } = this.state;

    if (!cf) {
      return <Loader chartData={cf} />;
    }

    var dimMmsi = cf.dimension((d) => d.properties.mmsi || "ukjent");

    var dimAisClass = cf.dimension((d) =>
      d.properties.hasOwnProperty("ais_class")
        ? d.properties.ais_class || ""
        : "Ukjent"
    );
    var grpShipsByAisClass = dimAisClass.group().reduceCount();

    var dimShipType = cf.dimension((d) => d.properties.ship_type_label);
    var grpShipsByShipType = dimShipType.group().reduceCount();

    var dimLengthGroup = cf.dimension((d) =>
      KYVGroupings.getShipLengthGroup(d.properties.length || -1)
    );
    var grpShipsByLengthGroup = dimLengthGroup.group().reduceCount();
    var shipLengthLabels = KYVGroupings.getShipLengthLabels();

    return (
      <div className="AppView">
        <AVIDashboard
          title="Skipstrafikk i nær sanntid"
          desc="Dette dashboardet gir tilgang til nær sanntidsposisjoner fra AIS meldinger som fanges opp av AIS basestasjoner i Norge. De blå linjestrekkene bak hvert skip indikerer seilaslinjen til skipet for de 20 siste minuttene."
          units="Skip"
          spacing={20}
          useFlex
          cfilter={cf}
          filter={this.props.location.state}
          group={dimMmsi.groupAll()}
        >
          <AVIRow>
            <ReplayRealtimeMapChart
              chartTitle="Skipsposisjoner (hvert skip oppdateres inntil en gang i minuttet, tid i UTC)"
              height={3}
              url={
                "https://kystdatahuset.no/webservices/api/ais/realtime/geojson"
              }
              updateFreq={15}
              featuresCrossfilter={cf}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <AVICol>
              <SelectMenu
                chartTitle="AIS-klasse"
                filterPrefix="AIS-klasse"
                dimension={dimAisClass}
                promptText="Vis alle klasser"
                order={(a, b) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0)}
                group={grpShipsByAisClass}
                numberItems={10}
                multiple
                info="AIS transpondere forekommer i to ulike klasser, klasse A (bedre signalstyrke og prioritet, benyttes av større kommersielle fartøy) og klasse B (lavere signalstyke, mindre fartøy, lavere prioritet, begrenset seilasinformasjon)"
              />
            </AVICol>
            <AVICol>
              <SelectMenu
                chartTitle="Skipskategori"
                filterPrefix="Skipskategori"
                dimension={dimShipType}
                promptText="Vis alle skipskategorier"
                order={(a, b) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0)}
                group={grpShipsByShipType}
                numberItems={10}
                multiple
                info="AIS har en egen skipstypeinndeling som angis som tall mellom 0 og 99. Her kobles disse verdiene til matchende skipskategorier slik de benyttes ellers i Kystdatahuset."
              />
            </AVICol>
            <AVICol>
              <SelectMenu
                chartTitle="Lengdegruppe"
                filterPrefix="Lengdegruppe"
                dimension={dimLengthGroup}
                promptText="Vis alle lengdegrupper"
                order={(a, b) =>
                  shipLengthLabels.indexOf(a.key) >
                  shipLengthLabels.indexOf(b.key)
                    ? 1
                    : shipLengthLabels.indexOf(b.key) >
                      shipLengthLabels.indexOf(a.key)
                    ? -1
                    : 0
                }
                group={grpShipsByLengthGroup}
                numberItems={10}
                multiple
                info="Statiske AIS-meldinger inneholder ikke skipets lengde og må hentes fra tredjepartsregistre. Lengde er her kalkulert ved hjelp av seilasdata fra AIS-meldinger. Disse forteller hvor AIS-transponderen er plassert på skipet i form av avstand fra baug, hekk, babord og styrbord. Lengde er kalkulert som summen av avstand til baug og hekk. Dette kan avvike fra skipets faktiske lengde da disse informasjonselementene er lagt inn i transponderen manuelt."
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <DataTable
                chartTitle="Skip i gjeldende utvalg"
                dimension={dimMmsi}
                sortBy={(d) => d.properties.mmsi}
                useFlex
                size={Infinity}
                columns={[
                  {
                    label: "MMSI#",
                    title: "Maritime Mobile Service Identity",
                    format: (d) => {
                      return d.properties.mmsi || 0;
                    },
                  },
                  {
                    label: "IMO#",
                    title:
                      "Skipsidentifikasjonsnummer fra den Internasonale Maritime Organisasjonen (IMO) ",
                    format: (d) => d.properties.imo || "",
                  },
                  {
                    label: "Kallesignal",
                    title: "Radiokallesignal utstedt av Sjøfartsdirektoratet",
                    format: (d) => d.properties.callsign || "",
                  },
                  {
                    label: "Skipsnavn",
                    title: "Skipets navn",
                    format: (d) => d.properties.ship_name || "",
                  },
                  {
                    label: "Skipskategori",
                    title:
                      "Skipskategori som definert av Kystverket og alminnelig benyttet i Kystdatahuset",
                    format: (d) => d.properties.ship_type_label || "",
                  },
                  {
                    label: "Lengde",
                    title: "Lengde = avstand til hekk + avstand til baug",
                    format: (d) => NumUtils.integer(d.properties.length || 0),
                  },
                  {
                    label: "Bredde",
                    title: "Bredde = avstand til babord + avstand til styrbord",
                    format: (d) => NumUtils.integer(d.properties.breadth || 0),
                  },
                  {
                    label: "Dyptgående",
                    title:
                      "Maksimalt dyptgående som angitt i seilasinformasjon fra AIS-meldinger",
                    format: (d) => NumUtils.integer(d.properties.draught || 0),
                  },
                  {
                    label: "Destinasjon",
                    title:
                      "Destinasjon som angitt i seilasinformasjon fra AIS-meldinger",
                    format: (d) => d.properties.destination || "",
                  },
                  {
                    label: "Sist sett",
                    title:
                      "Tidspunkt da siste AIS-melding ble mottatt fra skip (UTC)",
                    format: (d) =>
                      TimeUtils.toCompactTimestring(
                        new Date(d.properties.date_time_utc)
                      ),
                  },
                ]}
              />
            </AVICol>
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdReplayRealtime = withRouter(DbrdReplayRealtimeBase);

export default DbrdReplayRealtime;
