import "./LoginView.scss";

import { authReducerActions } from "react-adaptive-auth";

import { PropTypes } from "prop-types";
import React from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { connect } from "react-redux";
import LoginForm from "../../auth-components/LoginForm";
import { RegisterUserForm } from "../../auth-components/RegisterUserForm/RegisterUserForm";
import ResetPasswordForm from "../../auth-components/ResetPasswordForm";
import withRouter from "../../hocs/withRouter";
import { setLocal } from "../../utils/localStorageCache";

const mapDispatchToProps = (dispatch) => {
  return {
    loggedIn: () => {
      dispatch(authReducerActions.loggedIn());
    },
  };
};

const mapStateToProps = (state) => ({
  sessionExpired: state.auth.sessionExpired,
});

class LoginViewBase extends React.Component {
  static propTypes = {
    sessionExpired: PropTypes.bool,
    loggedIn: PropTypes.func.isRequired,
  };

  modes = {
    login: "login",
    reset: "reset",
    new: "new",
  };

  messageTypes = {
    info: "info",
    error: "error",
    none: "none",
  };

  messageTimeout = null;

  constructor(props) {
    super(props);

    this.onLoggingIn = this.onLoggingIn.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onClose = this.onClose.bind(this);
    this.setMode = this.setMode.bind(this);
    this.onPasswordReset = this.onPasswordReset.bind(this);
    this.onRegisterError = this.onRegisterError.bind(this);
    this.onRegisterSuccess = this.onRegisterSuccess.bind(this);
    this.showMessage = this.showMessage.bind(this);

    this.state = {
      mode: this.modes.login,
    };
  }

  onLoggingIn(loading) {
    this.setState({ loading });
  }

  onLogin(data) {
    // console.log(data);
    const { loggedIn, navigate } = this.props;
    loggedIn();
    setLocal("auth-time", new Date());
    navigate("/");
  }

  onClose() {
    const { navigate } = this.props;
    navigate("/");
  }

  setMode(mode) {
    return () => {
      this.setState({ mode: mode });
    };
  }

  onPasswordReset() {
    this.setMode(this.modes.login)();
    this.showMessage(
      "En e-post har blitt sendt til e-postadressen du oppgav hvis det finnes en brukerkonto tilknyttet denne. Sjekk innboksen din. Om e-posten uteblir, sjekk også spamkurven.",
      this.messageTypes.info
    );
  }

  onRegisterSuccess() {
    this.setMode(this.modes.login)();
    this.showMessage(
      "En ny konto har blitt opprettet, en e-post med kontodetaljer har blitt sendt til din e-postadresse. Sjekk innboksen din. Om e-posten uteblir, sjekk også spamkurven. Det kan ta inntil 5 minutter etter at du er registrert før du får tilgang til alt innhold i Kystdatahuset.",
      this.messageTypes.info
    );
  }

  onRegisterError(errMsg, errCode) {
    var error = "";
    if (errMsg && errCode) {
      error += ` Feilmelding: "${errMsg} (${errCode})"`;
    }
    this.setMode(this.modes.new)();
    this.showMessage(
      "Det oppstod en feil og det ble ikke registrert en ny brukerkonto." +
        error,
      this.messageTypes.error
    );
  }

  showMessage(message, messageType) {
    if (!messageType) {
      messageType = this.messageTypes.none;
    }

    if (this.messageTimeout !== null) {
      clearTimeout(this.messageTimeout);
    }

    this.setState({
      messageType: messageType,
      message: message,
    });

    var that = this;

    this.messageTimeout = setTimeout(() => {
      that.setState({
        message: null,
        messageType: this.messageTypes.none,
      });
    }, 10000);
  }

  render() {
    const { sessionExpired } = this.props;
    const { mode, message, messageType } = this.state;

    return (
      <Modal
        onHide={this.onClose}
        size="lg"
        show={true}
        centered
        backdrop="static"
      >
        <ModalHeader className="text-dark" closeButton>
          <ModalTitle>Kystdatahuset Login</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {sessionExpired && (
            <p className="small text-dark">
              Du har vært inaktiv for lenge, vennligst logg inn på nytt.
            </p>
          )}
          {mode === this.modes.login && <LoginForm />}
          {mode === this.modes.reset && (
            <ResetPasswordForm
              modes={this.modes}
              setMode={this.setMode}
              showMessage={this.showMessage}
            />
          )}
          {mode === this.modes.new && (
            <RegisterUserForm
              baseUrl={window.kyvDashboardConfig.adaptiveUrl}
              loginUrl={window.kyvDashboardConfig.dvhLoginUrl}
              showLabels
              onCancel={this.setMode(this.modes.login)}
              onRegisterSuccess={this.onRegisterSuccess}
              onRegisterError={this.onRegisterError}
            />
          )}
          {message && (
            <p className={`status-message ${messageType}`}>{message}</p>
          )}
          {mode === this.modes.login && <div></div>}
          <p className="login-info">
            {mode !== this.modes.login && (
              <span>
                <button
                  className="auth-link"
                  onClick={this.setMode(this.modes.login)}
                >
                  Logg inn
                </button>
              </span>
            )}
            {mode !== this.modes.new && (
              <span>
                &nbsp;&nbsp;
                <button
                  className="auth-link"
                  onClick={this.setMode(this.modes.new)}
                >
                  Registrer ny bruker
                </button>
              </span>
            )}
            {mode !== this.modes.reset && (
              <span>
                &nbsp;&nbsp;
                <button
                  className="auth-link"
                  onClick={this.setMode(this.modes.reset)}
                >
                  Glemt passord?
                </button>
              </span>
            )}
          </p>
        </ModalBody>
      </Modal>
    );
  }
}

export const LoginView = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginViewBase))
);

export default LoginView;
