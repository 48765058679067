import reductio from "reductio";

import regression from "regression";

export class GroupUtils {
  static RemoveEmptyBins(src) {
    var f = function () {
      return src.all().filter(function (d) {
        return d.value !== 0;
      });
    };

    return {
      all: f,
      bottom: f,
      top: f,
    };
  }

  static wrapArrayInGroup(a) {
    return {
      all: () => {
        return a;
      },
    };
  }

  static sortByValueDesc(a, b) {
    if (+a.value > +b.value) {
      return -1;
    } else if (+b.value > +a.value) {
      return 1;
    } else {
      return 0;
    }
  }

  static RemoveEmptyBinsTopN(
    src,
    n,
    sortfunc = this.sortByValueDesc,
    va = (d) => d.value
  ) {
    var f = function () {
      var src2 = src.all().filter(function (d) {
        return va(d) !== 0 && va(d) !== "";
      });
      src2.sort(sortfunc);
      if (n !== undefined) {
        return src2.slice(0, n);
      } else {
        return src2;
      }
    };

    return {
      all: f,
      bottom: f,
      top: f,
    };
  }

  static RemoveBinsByFuncTopN(
    src,
    n,
    filterFunc = (d) => d.value !== 0 && d.value !== "",
    sortfunc = this.sortByValueDesc
  ) {
    var f = function () {
      var src2 = src.all().filter(filterFunc);
      src2.sort(sortfunc);
      if (n !== undefined) {
        return src2.slice(0, n);
      } else {
        return src2;
      }
    };

    return {
      all: f,
      bottom: f,
      top: f,
    };
  }

  static RemoveEmptyBinsByKey(src, key) {
    var f = function () {
      var keyParts = key.split(".");

      return src
        .all()
        .slice(0)
        .filter(function (d) {
          if (keyParts.length === 1) {
            return d.value[keyParts[0]] !== 0; // if integers only
          } else if (keyParts.length === 2) {
            return d.value[keyParts[0]][keyParts[1]] !== 0;
          } else if (keyParts.length === 3) {
            return d.value[keyParts[0]][keyParts[1]][keyParts[2]] !== 0;
          } else {
            return true;
          }
        });
    };

    return {
      all: f,
      bottom: f,
      top: f,
    };
  }

  static WrapGroupInDimension(group) {
    return {
      top: function (N) {
        return group.top(N);
      },
      all: function () {
        return group.top(Infinity);
      },
      bottom: function (N) {
        return group.top(Infinity).slice(-N).reverse();
      },
    };
  }

  static CalcMinMaxAvg(valueAccessor) {
    return reductio()
      .count(true)
      .sum(valueAccessor)
      .min(valueAccessor)
      .max(true)
      .avg(true);
  }

  static CalcMinMaxAvgTwoProps(valueAccessor1, valueAccessor2) {
    var reducer = reductio();
    reducer.count();
    reducer
      .value("one")
      .sum(valueAccessor1)
      .value("one")
      .min(valueAccessor1)
      .value("one")
      .max(valueAccessor1)
      .value("one")
      .avg(valueAccessor1)
      .value("two")
      .sum(valueAccessor2)
      .value("two")
      .min(valueAccessor2)
      .value("two")
      .max(valueAccessor2)
      .value("two")
      .avg(valueAccessor2);
    return reducer;
  }
  /**
   * Create a "fake" regression line group
   *
   * @param {object} group an existing group
   * @param {number} min min X value
   * @param {number} max max X value
   * @param {bool} filter True to filter out empty values on x axis
   */
  static regressionGroup(group, min, max, filter = false) {
    return {
      all: function () {
        var _all = group.all();
        var first, last, reg;
        if (filter) {
          reg = regression.linear(
            _all
              .filter(function (k, v) {
                if (k.key[0]) return k.key;
                else return false;
              })
              .map((k, v) => k.key)
          );
        } else {
          reg = regression.linear(_all.map((k, v) => k.key[1]));
        }
        first = reg.predict(min);
        last = reg.predict(max);
        return [
          { key: first[0], value: first[1] },
          { key: last[0], value: last[1] },
        ];
      },
    };
  }
}
