import "./DbrdShipsByYearOfBuild.scss";

import * as d3 from "d3";
import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { SelectMenu } from "../../chart-components/Charts/SelectMenu";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { FilterToFromYear } from "../../filters/FilterToFromYear";
import withRouter from "../../hocs/withRouter";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../types/DashboardFilter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";
import { KYVGroupings } from "../../utils/KYVGroupings";
import { CountryCode } from "../../utils/codelists";

export type DbrdShipsByYearOfBuilProps = any;
export type DbrdShipsByYearOfBuilState = any;

class DbrdShipsByYearOfBuildBase extends Component<
  DbrdShipsByYearOfBuilProps,
  DbrdShipsByYearOfBuilState
> {
  static dashboardRoute = "ships-by-yearofbuild";

  static dashboardFilters(
    filter: DashboardFilter,
    setFilter: SetDashboardFilterFunc
  ) {
    return {
      helpMessage:
        "Dette dashboardet genererer middels datamengder, prøv med et år eller to om gangen",
      controls: [
        <FilterToFromYear
          key="flt-tf-month"
          firstYear={1814}
          setFilter={setFilter}
          filter={filter}
        />,
      ],
    };
  }

  static dashboardSettings() {
    return {};
  }

  static dashboardValidation(filter) {
    if (filter.fromYear && filter.toYear) {
      return true;
    } else {
      return false;
    }
  }

  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        fromYear: PropTypes.number.isRequired,
        toYear: PropTypes.number.isRequired,
      }),
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
    };

    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    try {
      const { fromYear, toYear } = this.props.location.state;
      DataLoader.getApi(
        `/api/ship/builtbetween/${fromYear}/${toYear}`,
        {},
        this.reportProgress
      )
        .then((res) => {
          // Check that all responses are successful
          if (!res.success)
            return Promise.reject(
              new Error("Could not load international voyages data")
            );
          // console.log(res.data[0]);
          this.setState({
            chartData: crossfilter(res.data),
          });
        })
        .catch((error) => {
          console.warn(error);
          this.setState({
            chartData: crossfilter([]),
          });
        });
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const { chartData, progressData } = this.state;

    const { fromYear, toYear } = this.props.location.state;

    var dashboardTitle = "Skipsinformasjon etter byggeår";

    if (!chartData || chartData.size() === 0) {
      return <Loader progressData={progressData} chartData={chartData} />;
    }

    var dimYearOfBuild = chartData.dimension((d) =>
      d.yearofbuild ? d.yearofbuild : ""
    );
    var grpShipsByYearofBuild = dimYearOfBuild.group().reduceCount();

    var dimCountry = chartData.dimension((d) => {
      let tmp = CountryCode.find((c) => c["alpha-3"] === d.cc3);
      return tmp ? tmp["name"] : `Ukjent (${d.cc3 || "-"})`;
    });

    var grpShipsByCountry = dimCountry.group().reduceCount();

    var dimGrossTonnageGroup = chartData.dimension((d) =>
      KYVGroupings.getGrossTonnageGroup(d.gt)
    );
    var grpShipsByGrossTonnageGroup = dimGrossTonnageGroup
      .group()
      .reduceCount();

    var dimLengthGroup = chartData.dimension((d) =>
      KYVGroupings.getShipLengthGroup(d.loa)
    );
    var grpShipsLengthGroup = dimLengthGroup.group().reduceCount();

    var dimShipType = chartData.dimension((d) => d.shiptype || "Ukjent");
    var grpShipsByShiptype = dimShipType.group().reduceCount();

    return (
      <div className="AppView">
        <AVIDashboard
          title={dashboardTitle}
          desc={`Informasjon om skip bygget i årene fra og med ${fromYear} til og med ${toYear}. Basert på data fra NSR`}
          cfilter={chartData}
          filter={this.props.location.state}
          useFlex
        >
          <AVIRow>
            <AVICol>
              <SelectMenu
                chartTitle="Velg registreringsland"
                dimension={dimCountry}
                order={(d) => d.value}
                group={grpShipsByCountry}
                numberItems={10}
                multiple
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartOrdinal
                group={grpShipsByYearofBuild}
                dimension={dimYearOfBuild}
                chartTitle="Skip etter byggeår"
                height={2}
                margins={{ left: 50, bottom: 50 }}
                ordering={(d) => d.key}
                yAxisLabel="Antall skip"
                xAxisLabel="Årstall"
                filterPrefix="Byggeår"
                rotateXAxisLabels={-15}
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartOrdinal
                group={GroupUtils.RemoveEmptyBinsTopN(grpShipsByCountry, 20)}
                dimension={dimCountry}
                chartTitle="Skip etter registerland"
                height={2}
                margins={{ left: 50, bottom: 50 }}
                ordering={(d) => d.value}
                yAxisLabel="Antall skip"
                xAxisLabel="Registerland (3-bokstaverskode)"
                filterPrefix="Registerland"
                rotateXAxisLabels={-15}
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <RowChart
                chartTitle="Skip etter tonnasjegruppe"
                height={1.5}
                dimension={dimGrossTonnageGroup}
                group={GroupUtils.RemoveEmptyBinsTopN(
                  grpShipsByGrossTonnageGroup,
                  15
                )}
                filterPrefix="Tonnasjegruppe"
                useFlex
              />
            </AVICol>
            <AVICol>
              <RowChart
                chartTitle="Skip etter lengdegruppe"
                height={1.5}
                dimension={dimLengthGroup}
                group={GroupUtils.RemoveEmptyBinsTopN(grpShipsLengthGroup, 15)}
                filterPrefix="Lengdegruppe"
                useFlex
              />
            </AVICol>
            <AVICol>
              <RowChart
                chartTitle="Skip etter skipstype"
                height={1.5}
                dimension={dimShipType}
                group={GroupUtils.RemoveEmptyBinsTopN(grpShipsByShiptype, 15)}
                filterPrefix="Skipstype"
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <DataTable
              chartTitle="Liste over seilas"
              dimension={dimCountry}
              sortBy={(d) => `${d.year}-${d.month}`}
              size={Infinity}
              order={d3.ascending}
              showSortControls
              useFlex
              columns={[
                {
                  label: "MMSI",
                  title: "MMSI#",
                  format: (d) => d.mmsi,
                },
                {
                  label: "IMO",
                  title: "IMO#",
                  format: (d) => d.imo,
                },
                {
                  label: "Kallesignal",
                  title: "Kallesignal",
                  format: (d) => d.callsign,
                },
                {
                  label: "Navn",
                  title: "Skipsnavn",
                  format: (d) => d.shipname,
                },
                {
                  label: "Flaggstat",
                  title: "Flaggstat",
                  format: (d) => d.cc3,
                },
                {
                  label: "Byggeår",
                  title: "Byggeår",
                  format: (d) => d.yearofbuild,
                },
                {
                  label: "Skipstype",
                  title: "Skipstype",
                  format: (d) => d.shiptype,
                },
                {
                  label: "Skipskategori",
                  title: "Skipskategori",
                  format: (d) => d.shipgroup,
                },
                {
                  label: "Lengde (LOA)",
                  title: "Lengde (LOA)",
                  format: (d) => d.loa,
                },
                {
                  label: "Bruttotonnasje",
                  title: "Bruttotonnasje",
                  format: (d) => d.gt,
                },
                {
                  label: "Kilde",
                  title: "Kilde",
                  format: (d) => d.src,
                },
              ]}
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdShipsByYearOfBuild = withRouter(DbrdShipsByYearOfBuildBase);

export default DbrdShipsByYearOfBuild;
