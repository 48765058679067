import "./DbrdVoyagesAgg.scss";

import * as d3 from "d3";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { PivotTable } from "../../chart-components/ChartsExt/PivotTable";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { FilterToFromYear } from "../../filters/FilterToFromYear";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { NumUtils } from "../../chart-components/ChartUtils/NumUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartMonth } from "../../chart-components/Charts/BarChartMonth";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { BarChartStacked } from "../../chart-components/Charts/BarChartStacked";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { NumberDisplayDim } from "../../chart-components/Charts/NumberDisplayDim";
import { PieChart } from "../../chart-components/Charts/PieChart";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { AVIDataCountTileFlex } from "../../chart-components/Layout/AVIDataCountTileFlex";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../types/DashboardFilter";
import { CrossfilterRecord } from "../../custom";
import { Exception } from "dc";
import { Loader, SuperagentProgress } from "../../ui-components/Loader/Loader";

export type DbrdVoyagesAggProps = {
  location: any;
};

export type DbrdVoyagesAggState = {
  chartData?: any;
  geoJsonData?: any;
  locationInfo?: any;
  fromYear?: number;
  toYear?: number;
  errorData?: any;
  numRecords?: number;
  geoJsonArrivals?: any;
  geoJsonOrigins?: any;
  locationData?: any;
  progressData?: SuperagentProgress;
};

class DbrdVoyagesAggBase extends Component<
  DbrdVoyagesAggProps,
  DbrdVoyagesAggState
> {
  static dashboardRoute = "seilas";

  static dashboardFilters(
    filter: DashboardFilter,
    setFilter: SetDashboardFilterFunc
  ) {
    return {
      helpMessage:
        "Velg fra år og til år. Utvalget er inklusive begge valgte år og alle mellom. Dette dashboardet gjør det mulig å vise data for flere år om gangen.",
      controls: (
        <FilterToFromYear
          key="flt"
          firstYear={2008}
          filter={filter}
          setFilter={setFilter}
        />
      ),
    };
  }

  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: null,
    };
  }

  static dashboardValidation(filter) {
    if (filter.fromYear && filter.toYear) {
      return true;
    }
  }

  static propTypes = {
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.reportProgress = this.reportProgress.bind(this);
    this.reportError = this.reportError.bind(this);
    this.state = {
      chartData: null,
      locationData: [],
    };
  }

  componentDidMount() {
    try {
      const { fromYear, toYear } = this.props.location.state;

      try {
        if (fromYear && toYear) {
          DataLoader.postApi(
            `/api/voyage/aggregated/${fromYear}/${toYear}`,
            {},
            this.reportProgress,
            this.reportError
          ).then((res: any) => {
            // Check that all responses are successful
            var success = true;

            if (!res.success) {
              if (success) {
                success = false;
              }
            }

            if (!success) {
              throw new Exception("Error loading aggregated voyage data");
            }

            let idx = 1;
            let foundNull = false;
            // Clean data object, add index, remove nulls
            res.data.forEach((row: any) => {
              row.index = idx;
              for (let prop in row) {
                let defaultValue: string | number = "";
                switch (prop) {
                  case "mh":
                  case "ml":
                  case "mb":
                  case "md":
                  case "mw":
                  case "mt":
                    defaultValue = -99;
                    break;
                  case "dlc":
                  case "d0":
                  case "d1":
                  case "d2":
                  case "alc":
                  case "a0":
                  case "a1":
                  case "a2":
                    defaultValue = "(Ikke oppgitt)";
                    break;
                  case "hm":
                    defaultValue = "u";
                    break;
                  case "dwd":
                    defaultValue = row["awd"];
                    break;
                  case "awd":
                    defaultValue = row["dwd"];
                    break;
                  default:
                    defaultValue = "";
                }

                if (!row[prop]) {
                  row[prop] = defaultValue;
                }

                if (
                  (row[prop] === undefined || row[prop] === null) &&
                  !foundNull
                ) {
                  console.warn(
                    "Found null value for a record for property: " +
                      prop +
                      " at index: " +
                      idx
                  );
                  foundNull = true;
                  return;
                }
              }
              idx++;
            });

            this.setState({
              chartData: crossfilter(res.data),
            });
          });
        } else {
          throw new Exception("Missing dashboard selection parameters");
        }
      } catch (error) {
        console.warn(error);
        this.setState({
          chartData: crossfilter([]),
        });
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  reportProgress(progressData: SuperagentProgress) {
    this.setState({
      progressData: progressData,
    });
  }

  reportError(errorData) {
    this.setState({
      errorData: errorData,
    });
  }

  render() {
    const { chartData, progressData, errorData } = this.state;
    const { fromYear, toYear } = this.props.location.state;

    if (!chartData || chartData.size() === 0) {
      return (
        <Loader
          progressData={progressData}
          errorData={errorData}
          chartData={chartData}
        />
      );
    }

    var yearRange: number[] = [];
    for (var currentYear = fromYear; currentYear <= toYear; currentYear++) {
      yearRange.push(currentYear);
    }

    var dimCount = chartData.dimension((d) => {
      return d.index;
    });

    var dimArrivalLocations = chartData.dimension((d) => d.al);

    var dimLength = chartData.dimension((d) => d.ml);
    var dimBreadth = chartData.dimension((d) => d.mb);
    var dimHeight = chartData.dimension((d) => d.mh);
    var dimDraught = chartData.dimension((d) => d.md);
    var dimGrossTonange = chartData.dimension((d) => d.mt);

    var dimArrivalYear = chartData.dimension((d) => d.ay);

    var dimArrivalMonth = chartData.dimension((d) => d.am);

    var dimArrivalWeekDay = chartData.dimension((d) => d.awd);
    var arrivalsByWeekday = dimArrivalWeekDay.group().reduceSum((d) => d.vs);

    var dimDepartureCountry = chartData.dimension((d) => d.d0);

    var dimFlagState = chartData.dimension((d) => d.sf);
    var arrivalsByFlagState = dimFlagState.group().reduceSum((d) => d.vs);

    var dimDomesticInternational = chartData.dimension((d) => {
      if (d.d0 === "NORGE") {
        return "NORGE";
      } else if (d.d0 === "SVALBARD OG JAN MAYEN") {
        return "SVALBARD OG JAN MAYEN";
      } else if (d.d0 === "INTERNASJONALT FARVANN") {
        return "INTERNASJONALT FARVANN";
      } else {
        return "UTLAND";
      }
    });
    var arrivalsByDomesticInternational = dimDomesticInternational
      .group()
      .reduceSum((d: CrossfilterRecord) => d.vs);

    var dimArrivalCounty = chartData.dimension((d: CrossfilterRecord) => d.a1);
    var dimArrivalCountyYear = chartData.dimension((d: CrossfilterRecord) => [
      d.a1,
      d.ay,
    ]);
    var arrivalsByCountyYear = dimArrivalCountyYear
      .group()
      .reduceSum((d: CrossfilterRecord) => d.vs);

    var dimArrivalMunicipality = chartData.dimension(
      (d: CrossfilterRecord) => d.a2
    );
    var arrivalsByMunicipality = dimArrivalMunicipality
      .group()
      .reduceSum((d: CrossfilterRecord) => d.vs);

    var arrivalsByArrivalLocation = dimArrivalLocations
      .group()
      .reduceSum((d: CrossfilterRecord) => d.vs);
    var arrivalsByDepartureCountry = dimDepartureCountry
      .group()
      .reduceSum((d: CrossfilterRecord) => d.vs);

    var dimLengthGroup = chartData.dimension((d: CrossfilterRecord) => d.lg);
    var arrivalsByLengthGroup = dimLengthGroup
      .group()
      .reduceSum((d: CrossfilterRecord) => d.vs);

    var dimTonnageGroup = chartData.dimension((d: CrossfilterRecord) => d.tg);
    var arrivalsByTonnageGroup = dimTonnageGroup
      .group()
      .reduceSum((d: CrossfilterRecord) => d.vs);

    var dimHazMat = chartData.dimension((d: CrossfilterRecord) =>
      d.hm === true ? "Farlig last" : "Ordinær last"
    );
    var arrivalsByHazmat = dimHazMat
      .group()
      .reduceSum((d: CrossfilterRecord) => d.vs);

    var dimYearMonth = chartData.dimension(
      (d: CrossfilterRecord) => new Date(d.ay, d.am, 1)
    );
    var arrivalsByYearMonth = dimYearMonth
      .group()
      .reduceSum((d: CrossfilterRecord) => d.vs);

    var dimShipCategory = chartData.dimension((d: CrossfilterRecord) => d.sg2);
    var arrivalsByShipCategory = dimShipCategory.group().reduceSum((d) => d.vs);

    var dimShipType = chartData.dimension((d: CrossfilterRecord) => d.st);
    var arrivalsByShipType = dimShipType.group().reduceSum((d) => d.vs);

    var dimTable = chartData.dimension((d: CrossfilterRecord) => [
      d.al,
      d.a2,
      d.a1,
      d.ay,
      d.am,
      d.sg2,
    ]);
    var arrivalsByTable = GroupUtils.RemoveEmptyBins(
      dimTable.group().reduceSum((d) => d.vs)
    );

    return (
      <div className="AppView-noMap">
        <AVIDashboard
          title={"Havneinformasjon: meldepliktige ankomster fra SafeSeaNet"}
          desc={`Aggregert statistikk over meldepliktige ankomster til  lokasjoner i Norge i tidsrommet fra ${fromYear} til og med ${toYear} som registrerte i SafeSeaNet. Omfatter ikke seilas som er untatt fra meldeplikt slik som innenriks rutegående bil- og persontransport.`}
          spacing={10}
          keyName="vs"
          type="sum"
          units="ankomster"
          cfilter={chartData}
          filter={this.props.location.state}
          group={dimCount.groupAll()}
          useFlex
        >
          <AVIRow>
            <AVIDataCountTileFlex
              height={0.5}
              width={4}
              title="Nøkkeltall for ankomster"
            >
              <NumberDisplayDim
                chartTitle="Antall lokasjoner"
                height={0.25}
                width={0.5}
                keyName="count"
                type="count"
                dimension={dimArrivalLocations}
                units="havner"
                useFlex
              />
              <NumberDisplayDim
                chartTitle="Største BT"
                height={0.25}
                width={0.5}
                keyName="mt"
                dimension={dimGrossTonange}
                units="tonn"
                useFlex
              />
              <NumberDisplayDim
                chartTitle="Største lengde"
                height={0.25}
                width={0.5}
                keyName="ml"
                dimension={dimLength}
                units="meter"
                useFlex
              />
              <NumberDisplayDim
                chartTitle="Største bredde"
                height={0.25}
                width={0.5}
                keyName="mb"
                dimension={dimBreadth}
                units="meter"
                useFlex
              />
              <NumberDisplayDim
                chartTitle="Største høyde"
                height={0.25}
                width={0.5}
                keyName="mh"
                dimension={dimHeight}
                units="meter"
                useFlex
              />
              <NumberDisplayDim
                chartTitle="Største dyptgående"
                height={0.25}
                width={0.5}
                keyName="md"
                dimension={dimDraught}
                units="meter"
                useFlex
              />
            </AVIDataCountTileFlex>
          </AVIRow>
          <AVIRow>
            <PivotTable
              chartTitle="Ankomster per år etter fylke"
              height={2}
              width={4}
              group={arrivalsByCountyYear}
              dimension={dimArrivalCounty}
              rowLabel="Fylke"
              colLabel="År"
              rowKeyIndex={0}
              colKeyIndex={1}
              filterPrefix="Fylke"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartStacked
              chartTitle="Ankomster etter skipskategori og år"
              dimension={dimArrivalYear}
              xAxisTickValues={yearRange}
              width={2}
              height={1.5}
              gap={2}
              filterPrefix="Ankomstår"
              stackKey="sg"
              valueKey="vs"
              renderLabel
              useFlex
            />
            <BarChartOrdinal
              group={arrivalsByDomesticInternational}
              dimension={dimDomesticInternational}
              chartTitle="Ankomster fra innland/utland"
              margins={{ left: 55, bottom: 100 }}
              width={2}
              height={1.5}
              ordering={(d) => -d.value}
              yAxisLabel="Antall ankomster"
              xAxisLabel="Innland/utland"
              filterPrefix="Innland/utland"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(arrivalsByMunicipality, 40)}
              dimension={dimArrivalMunicipality}
              chartTitle="Ankomster etter kommune (40 mest trafikkerte)"
              margins={{ left: 55, bottom: 100 }}
              width={4}
              height={1.5}
              ordering={(d) => -d.value}
              yAxisLabel="Antall ankomster"
              xAxisLabel="Ankomstkommune"
              filterPrefix="Ankomstkommune"
              rotateXAxisLabels={-45}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartStacked
              chartTitle="Ankomster etter skipskategori og måned"
              dimension={dimArrivalMonth}
              xAxisTickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
              width={2}
              height={1.5}
              gap={2}
              filterPrefix="Ankomstmåned"
              stackKey="sg"
              valueKey="vs"
              renderLabel
              useFlex
            />
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(
                arrivalsByArrivalLocation,
                15
              )}
              dimension={dimArrivalLocations}
              chartTitle="Ankomster etter ankomststed (15 mest trafikkerte)"
              margins={{ left: 55, bottom: 50 }}
              width={2}
              height={1.5}
              ordering={(d) => -d.value}
              yAxisLabel="Antall ankomster"
              xAxisLabel="Ankomststed"
              filterPrefix="Ankomststed"
              rotateXAxisLabels={-15}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(
                arrivalsByDepartureCountry,
                40
              )}
              dimension={dimDepartureCountry}
              chartTitle="Land forrige lokasjon (40 største trafikkilder)"
              width={4}
              height={1.5}
              margins={{ left: 55, bottom: 50 }}
              ordering={(d) => -d.value}
              yAxisLabel="Antall ankomster"
              xAxisLabel="Opphavsland"
              filterPrefix="Opphavsland"
              rotateXAxisLabels={-15}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(arrivalsByFlagState, 40)}
              dimension={dimFlagState}
              chartTitle="Antall ankomster etter skipets flaggstat (40 største)"
              width={4}
              height={1.5}
              margins={{ left: 55, bottom: 50 }}
              ordering={(d) => -d.value}
              yAxisLabel="Antall ankomster"
              xAxisLabel="Flaggstat"
              filterPrefix="Flaggstat"
              rotateXAxisLabels={-15}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              chartTitle="Ankomster med ordinær/farlig last"
              width={2}
              height={1.5}
              group={arrivalsByHazmat}
              dimension={dimHazMat}
              margins={{ left: 55, bottom: 50 }}
              ordering={(d) => -d.value}
              yAxisLabel="Antall ankomster"
              xAxisLabel="Godstype"
              filterPrefix="Godstype"
              rotateXAxisLabels={-15}
              useFlex
            />
            <BarChartOrdinal
              chartTitle="Ankomster etter ukedag"
              width={2}
              height={1.5}
              group={arrivalsByWeekday}
              dimension={dimArrivalWeekDay}
              margins={{ left: 55, bottom: 50 }}
              ordering={(d) => d.key}
              yAxisLabel="Antall ankomster"
              xAxisLabel="Ukedag"
              filterPrefix="Ukedag"
              xAxisTickFormat={(d) => {
                return TimeUtils.getWeekDay(d);
              }}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartMonth
              chartTitle="Ankomster etter måned"
              width={4}
              height={1.5}
              group={arrivalsByYearMonth}
              dimension={dimYearMonth}
              centerBar
              filterPrefix="Fra/til måned"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <RowChart
              chartTitle="Ankomster etter skipskategori"
              width={2}
              height={1.5}
              group={arrivalsByShipCategory}
              dimension={dimShipCategory}
              filterPrefix="Skipskategori"
              useFlex
            />
            <RowChart
              chartTitle="Ankomster etter skipstype (10 største)"
              description="Filtrer på skipskategori for å se de 10 største skipstypene innen kategorien"
              width={2}
              height={1.5}
              group={GroupUtils.RemoveEmptyBinsTopN(arrivalsByShipType, 10)}
              dimension={dimShipType}
              filterPrefix="Skipstype"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <PieChart
              chartTitle="Ankomster per lengdegruppe"
              width={2}
              height={2}
              margins={{ top: 20 }}
              innerRadius={50}
              dimension={dimLengthGroup}
              group={arrivalsByLengthGroup}
              minAngleForLabel={0.15}
              filterPrefix="Lengdegruppe"
              useFlex
            />
            <PieChart
              chartTitle="Ankomster per bruttotonnasjegruppe (BT)"
              width={2}
              height={2}
              margins={{ top: 20 }}
              innerRadius={50}
              dimension={dimTonnageGroup}
              group={arrivalsByTonnageGroup}
              minAngleForLabel={0.15}
              filterPrefix="Bruttotonnasjegruppe"
              useFlex
            />
          </AVIRow>
          <DataTable
            chartTitle="Liste over ankomster"
            dimension={arrivalsByTable}
            sortBy={(d) =>
              `${d.key[0]}-${NumUtils.pad(d.key[3], 4)}-${NumUtils.pad(
                d.key[4],
                2
              )}`
            }
            order={d3.ascending}
            width={4}
            size={Infinity}
            showSortControls={false}
            useFlex
            columns={[
              {
                label: "Lokasjon",
                title: "Lokasjonsnavn",
                format: (d: CrossfilterRecord) => d.key[0],
              },
              {
                label: "Kommune",
                title: "Navn på kommune",
                format: (d: CrossfilterRecord) => d.key[1],
              },
              {
                label: "Fylke",
                title: "Navn på fylke",
                format: (d: CrossfilterRecord) => d.key[2],
              },
              {
                label: "År",
                title: "Ankomstår",
                format: (d: CrossfilterRecord) => d.key[3],
              },
              {
                label: "Måned",
                title: "Ankomstmåned",
                format: (d: CrossfilterRecord) => d.key[4],
              },
              {
                label: "Skipskategori",
                title: "Skipskategori",
                format: (d: CrossfilterRecord) => d.key[5],
              },
              {
                label: "Ankomster",
                title: "Ankomster fra-til",
                format: (d: CrossfilterRecord) => NumUtils.integer(d.value),
                value: (d: CrossfilterRecord) => d.value,
              },
            ]}
          />
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdVoyagesAgg = withRouter(DbrdVoyagesAggBase);

export default DbrdVoyagesAgg;
