import LayerGroup from "ol/layer/Group";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { DropdownItemText, FormCheck } from "react-bootstrap";
import { KyvLayerSwitcherItemLayerGroup } from "./KyvLayerSwitcherItemLayerGroup";
import BaseLayer from "ol/layer/Base";
import { ObjectEvent } from "ol/Object";

export type KyvLayerSwitcherItemLayerProps = {
  layer: BaseLayer;
};

export function KyvLayerSwitcherItemLayer({
  layer,
}: KyvLayerSwitcherItemLayerProps) {
  const [visible, setVisible] = useState<boolean>(layer.getVisible());

  const handleChange = useCallback((evt: ObjectEvent) => {
    setVisible(evt.target.getVisible())
    // console.log(evt.target.getVisible());
  }, []);

  useEffect(() => {
    if (layer instanceof LayerGroup) return;
    layer.on("change:visible", handleChange);
    return () => {
      layer.un("change:visible", handleChange);
    };
  }, [handleChange, layer]);

  if (layer instanceof LayerGroup) {
    return <KyvLayerSwitcherItemLayerGroup layerGroup={layer as LayerGroup} />;
  }

  return (
    <DropdownItemText>
      <FormCheck
        checked={visible ? true : false}
        onChange={(evt : ChangeEvent<HTMLInputElement>) => {
          layer.setVisible(evt.target.checked);
          setVisible(evt.target.checked);
        }}
        label={layer.get("title")}
      />
    </DropdownItemText>
  );
}
