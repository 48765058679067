export function combinePath(...args: string[]) {
  return args.reduce((p: string, c: string, idx: number) => {
    c = c.trim();
    if (p === "") {
        return c;
    } else if (p.endsWith("/") && !c.startsWith("/")) {
      return `${p}${c}`;
    } else if (p.endsWith("/") && c.startsWith("/")) {
      return `${p.slice(0, -1)}${c}`;
    } else {
      return `${p}/${c}`;
    }
  }, "");
}
