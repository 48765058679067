import "./IndexView.scss";

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import ArticleGrid from "../../ui-components/index-view/ArticleGrid/ArticleGrid";
import DashboardGrid from "../../ui-components/index-view/DashboardGrid/DashboardGrid";
import { Disclaimer } from "../../ui-components/index-view/Disclaimer/Disclaimer";
import Lead from "../../ui-components/index-view/Lead/Lead";
import { Loader } from "../../ui-components/Loader/Loader";

const mapStateToProps = (state) => ({
  dashboardsLoading: state.dashboards.loading,
  articlesLoading: state.articles.loading,
});

export class IndexView extends React.Component {
  render() {
    const { dashboardsLoading, articlesLoading } = this.props;

    return (
      <>
        {(dashboardsLoading || articlesLoading) && <Loader hideMessage />}
        <Lead className="mb-5"/>
        <ArticleGrid className="mb-5"/>
        <DashboardGrid />
        <Disclaimer /> 
      </>
    );
  }
}

IndexView.propTypes = {
  articlesLoading: PropTypes.bool,
  dashboardsLoading: PropTypes.bool,
};

export default connect(mapStateToProps)(IndexView);
