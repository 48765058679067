export class StackUtils {
  static selStack = function (i) {
    return function (d) {
      return d.value[i];
    };
  };

  static getUnique = function (dimension, uniqueKey) {
    var types = dimension
      .top(Infinity)
      .map(d => '' + d[uniqueKey])
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
      .sort();
    return types;
  };
}
