import './GeoHeatMap.scss';

import * as olControl from 'ol/control';
import * as olInteraction from 'ol/interaction';
import * as olProj from 'ol/proj';
import * as d3 from 'd3';
import * as dc from 'dc';

import React, { Component } from 'react';

import { AVIMapTile } from '../Layout/AVIMapTile';
import { LayerSwitcherMixin } from '../Mixins/LayerSwitcherMixin';
import Map from 'ol/Map';
import PropTypes from 'prop-types';
import View from 'ol/View';
import WKT from 'ol/format/WKT';
import VectorSource from 'ol/source/Vector';
import { Heatmap as HeatmapLayer } from 'ol/layer';
import { BoundsOfMainlandNorway } from './../ChartUtils/MapUtils';

export class GeoHeatMap extends Component {
  static propTypes = {
    chartTitle: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    // dimension: PropTypes.any.isRequired,
    group: PropTypes.any.isRequired,
    height: PropTypes.number,
    useFlex: PropTypes.bool
  };

  static defaultProps = {
    useFlex: false
  };

  map = null;
  geoHeatMapVectorSource = null;
  wktFormat = new WKT();

  constructor (props) {
    super(props);
    this.chartRef = React.createRef();
    this.chart = null;
    this.getMap = this.getMap.bind(this);
    this.geoHeatMapVectorSource = new VectorSource({
      features: []
    });
  }

  componentDidMount () {
    var zoomPt = [15, 61];

    const heatMap = new HeatmapLayer({
      source: this.geoHeatMapVectorSource,
      blur: 10,
      radius: 20,
      weight: function (feature) {
        const arrivals = feature.get('arrivals');
        return parseFloat(arrivals);
      }
    });

    heatMap.setZIndex(100);

    this.map = new Map({
      target: this.chartRef.current,
      interactions: olInteraction.defaults({ mouseWheelZoom: false }),
      controls: olControl.defaults({
        attributionOptions: {
          collapsible: false
        }
      }),
      view: new View({
        center: olProj.transform([zoomPt[0], zoomPt[1]], 'EPSG:4326', 'EPSG:3857'),
        zoom: 5
      })
    });
    this.map.addLayer(heatMap);
    LayerSwitcherMixin(this.map);
    this.map.getView().fit(BoundsOfMainlandNorway('EPSG:3857'), this.map.getSize());

    this.draw();

    dc.registerChart(this);
  }

  draw () {
    const { group } = this.props;

    let scale = d3.scaleLinear()
      .domain(d3.extent(group.all().map(d => d.value)))
      .range([0, 0.9]);

    var features = group.all().map(d => {
      let f = this.wktFormat.readFeature(d.key[0], {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      });
      f.setProperties({
        name: d.key[1],
        arrivals: scale(d.value)
      });
      return f;
    });
    this.geoHeatMapVectorSource.addFeatures(features);
  }

  redraw () {
    this.geoHeatMapVectorSource.clear();
    this.draw();
  }

  getMap () {
    return this.map;
  }

  render () {
    const { chartTitle, height, width, useFlex } = this.props;
    return (
      <AVIMapTile
        title={chartTitle}
        height={height}
        width={width}
        getMap={this.getMap}
        useFlex={useFlex}
      >
        <div className='avi-geo-heatmap' ref={this.chartRef} />
      </AVIMapTile>
    );
  }
}

export default GeoHeatMap;
