import GeoJSON from "ol/format/GeoJSON";
import { useEffect } from "react";
import { useFilterContext } from "../FilterList/FilterList";

export type AddGeoJSONToDrawingLayerProps = {
  geoJSON: Object[];
};

const geoJSONFormat = new GeoJSON();

export function AddGeoJSONToDrawingLayer({
  geoJSON,
}: AddGeoJSONToDrawingLayerProps) {
  const { drawingLayer } = useFilterContext();

  useEffect(() => {
    if (!Array.isArray(geoJSON) || geoJSON.length < 1) return;
    const features = geoJSON.map((featureObject) => {
      return geoJSONFormat.readFeature(featureObject, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      });
    });
    drawingLayer.getSource()?.addFeatures(features);
    drawingLayer.setVisible(true);

    return () => {
      features.forEach((feature) => {
        drawingLayer.getSource()?.removeFeature(feature);
      });
    };
  }, [drawingLayer, geoJSON]);

  return null;
}
