import * as d3 from 'd3';

import React, { Component } from 'react';

import { BarChart } from './BarChart';
import PropTypes from 'prop-types';

export class BarChartLinear extends Component {
  static propTypes = {
    centerBar: PropTypes.bool
  };

  static defaultProps = {
    centerBar: true
  };

  constructor (props) {
    super(props);
    this.calcDomain = this.calcDomain.bind(this);
  }

  calcDomain (chart) {
    const nullv = new Date(null);
    const filtered = chart
      .group()
      .all()
      .filter(function (v) {
        return v.key > nullv;
      });
    const min = d3.min(filtered, function (kv) {
      return kv.key;
    });
    const max = d3.max(filtered, function (kv) {
      return kv.key;
    });
    chart.x().domain([min, max]);
  }

  render () {
    const {centerBar} = this.props;
    return (
      <BarChart
        x={d3.scaleLinear()}
        round={Math.floor}
        preRender={this.calcDomain}
        preRedraw={this.calcDomain}
        // elasticX={true}
        centerBar={centerBar}
        // barPadding={0.2}
        xAxisPadding={1}
        {...this.props}
      />
    );
  }
}
