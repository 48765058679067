import * as arc from 'arc';
import * as dc from 'dc';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import View from 'ol/View';
import { getWidth } from 'ol/extent';
import LineString from 'ol/geom/LineString';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { getVectorContext } from 'ol/render';
import OSM from 'ol/source/OSM.js';
import VectorSource from 'ol/source/Vector';
import { Stroke, Style } from 'ol/style';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './FlightMap.scss';

export class FlightMap extends Component {
  static propTypes = {
    group: PropTypes.object.isRequired
  }

  constructor (props) {
    super();

    this.mapRef = React.createRef();
    this.to = React.createRef();
    this.to.current = [];
    this.pointsPerMs = 0.02;

    this.style = new Style({
      stroke: new Stroke({
        color: '#4BA1BC',
        width: 2
      })
    });

    this.flightsSource = new VectorSource({
      attributions:
        'Trafikkdata fra SafeSeaNet',
      loader: () => {
        const flightsData = this.data();
        for (let i = 0; i < flightsData.length; i++) {
          const flight = flightsData[i];
          const from = flight[0];
          const to = flight[1];

          // create an arc circle between the two locations
          const arcGenerator = new arc.GreatCircle(
            { x: from[1], y: from[0] },
            { x: to[1], y: to[0] }
          );

          const arcLine = arcGenerator.Arc(100, { offset: 10 });
          // paths which cross the -180°/+180° meridian are split
          // into two sections which will be animated sequentially
          const features = [];
          arcLine.geometries.forEach(function (geometry) {
            const line = new LineString(geometry.coords);
            line.transform('EPSG:4326', 'EPSG:3857');

            features.push(
              new Feature({
                geometry: line,
                finished: false
              })
            );
          });
          // add the features with a delay so that the animation
          // for all features does not start at the same time
          this.addLater(features, i * 50);
        }
        this.tileLayer.on('postrender', (e) => this.animateFlights(e));
      }
    });

    this.flightsLayer = new VectorLayer({
      source: this.flightsSource,
      style: (feature) => {
        // if the animation is still active for a feature, do not
        // render the feature with the layer style
        if (feature.get('finished')) {
          return this.style;
        } else {
          return null;
        }
      }
    });
  }

  data () {
    const { group } = this.props;
    return group.all().filter(g =>
      g.key.every(k =>
        !isNaN(k) &&
        k !== null
      ) &&
      g.value > 0).map(r => [
      [
        r.key[1],
        r.key[0]
      ],
      [
        r.key[3],
        r.key[2]
      ]
    ]);
  }

  draw () {
    this.to.current.forEach(to => window.clearTimeout(to));
    this.flightsSource.refresh();
  }

  redraw () {
    this.draw();
  }

  componentDidMount () {
    this.tileLayer = new TileLayer({
      source: new OSM(),
    })

    this.map = new Map({
      layers: [this.tileLayer],
      target: this.mapRef.current,
      view: new View({
        center: [-11000000, 4600000],
        zoom: 0
      })
    });

    this.map.addLayer(this.flightsLayer);

    dc.registerChart(this);
  }

  componentWillUnmount () {
    window.clearTimeout(this.to.current);
  }

  animateFlights (event) {
    const vectorContext = getVectorContext(event);
    const frameState = event.frameState;
    vectorContext.setStyle(this.style);

    const features = this.flightsSource.getFeatures();
    for (let i = 0; i < features.length; i++) {
      const feature = features[i];
      if (!feature.get('finished')) {
        // only draw the lines for which the animation has not finished yet
        const coords = feature.getGeometry().getCoordinates();
        const elapsedTime = frameState.time - feature.get('start');
        if (elapsedTime >= 0) {
          const elapsedPoints = elapsedTime * this.pointsPerMs;

          if (elapsedPoints >= coords.length) {
            feature.set('finished', true);
          }

          const maxIndex = Math.min(elapsedPoints, coords.length);
          const currentLine = new LineString(coords.slice(0, maxIndex));

          // animation is needed in the current and nearest adjacent wrapped world
          const worldWidth = getWidth(this.map.getView().getProjection().getExtent());
          const offset = Math.floor(this.map.getView().getCenter()[0] / worldWidth);

          // directly draw the lines with the vector context
          currentLine.translate(offset * worldWidth, 0);
          vectorContext.drawGeometry(currentLine);
          currentLine.translate(worldWidth, 0);
          vectorContext.drawGeometry(currentLine);
        }
      }
    }
    // tell OpenLayers to continue the animation
    this.map.render();
  }

  addLater (features, timeout) {
    let to = window.setTimeout(() => {
      let start = Date.now();
      features.forEach((feature) => {
        feature.set('start', start);
        this.flightsSource.addFeature(feature);
        const duration =
          (feature.getGeometry().getCoordinates().length - 1) / this.pointsPerMs;
        start += duration;
      });
    }, timeout);
    this.to.current.push(to);
  }

  render () {
    return (<div ref={this.mapRef} className='flight-map-container' />);
  }
}
