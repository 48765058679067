import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { ShipTypeAhead } from './ShipTypeAhead/ShipTypeAhead';

export type FilterShipNameProps = any;
export type FilterShipNameState = any;

export class FilterShipName extends Component<FilterShipNameProps, FilterShipNameState> {
  static propTypes = {
    setFilter: PropTypes.func.isRequired,
    filter: PropTypes.object
  };

  constructor (props) {
    super(props);
    this.state = {
      filter: props.filter
    };
  }

  update (key, val) {
    const { filter, setFilter } = this.props;
    filter[key] = val;
    setFilter({ 'shipIds': filter.shipIds });
  }

  render () {
    const { filter } = this.props;
    return (
      <div key='B45'>
        <ShipTypeAhead
          key='72'
          label='Velg et eller flere skip'
          isMulti
          setValue={ship => {
            this.update('shipIds', ship);
          }}
          defaultValue={filter.shipIds}
        />
      </div>
    );
  }
}
