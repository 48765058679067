import "./GroupedNumberDisplayMax.scss";

import React from "react";
import { NumberDisplayMax } from "./Charts/NumberDisplayMax";

export type GroupedNumberDisplayMaxProps = {
  shipsByDraft: any;
  maxLength: any;
  maxBreadth: any;
  maxHeight: any;
  chartTitle: string;
  width?: number;
  height?: number;
  useFlex?: boolean;
};

export function GroupedNumberDisplayMax({
  shipsByDraft,
  maxLength,
  maxBreadth,
  maxHeight,
  useFlex = false,
  chartTitle = "",
  width = 1,
  height = 0.5,
}: GroupedNumberDisplayMaxProps) {
  return (
    <div className="w-100" style={{ flex: width }}>
      <div className="mb-3">{chartTitle}</div>
      <div className="d-flex gap-2">
        <NumberDisplayMax
          className="draught"
          chartTitle="Største dyptgående"
          keyName="draught"
          group={shipsByDraft}
          units="meter"
        />
        <NumberDisplayMax
          className="length"
          chartTitle="Største lengde"
          keyName="length"
          group={maxLength}
          units="meter"
        />
        <NumberDisplayMax
          className="breadth"
          chartTitle="Største bredde"
          keyName="breadth"
          group={maxBreadth}
          units="meter"
        />
        <NumberDisplayMax
          className="height"
          chartTitle="Største høyde"
          keyName="height"
          group={maxHeight}
          units="meter"
        />
      </div>
    </div>
  );
}

export default GroupedNumberDisplayMax;
