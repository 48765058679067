import { GeoJSONFeature, GeoJSONGeometry } from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import { useCallback, useEffect, useState } from "react";
import { EventLike } from "../../types/Shims";
import { createEventLike } from "../../utils/createEventLike";
import {
  geomToExtent,
  geomToGeoJSONFeature,
  geomToWkt,
  registerProjections,
} from "../../utils/geomUtils";
import { CoordinateModeControl } from "./CoordinateModeControl";
import { ExtentField } from "./ExtentField";
import { GeoJSONField } from "./GeoJSONField";
import { SelectSrsControl } from "./SelectSrsControl";
import { WktField } from "./WktField";
import { useFilterContext } from "../../ui-components/filter-view/FilterList/FilterList";
import { Feature } from "ol";

export type CoordinateMode = "wkt" | "geoJSON" | "extent";

export type CoordinateEntry = {
  geom: string | GeoJSONGeometry | GeoJSONGeometry[];
};

export type CoordinateEntryControlProps<T = string | GeoJSONFeature[]> = {
  name: string;
  value: string;
  onChange: (evt: EventLike<T>) => void;
  outMode: CoordinateMode;
  entryMode?: CoordinateMode;
  outSrid?: number;
};

registerProjections();

export function CoordinateEntryControl<T>({
  value,
  onChange,
  outMode,
  entryMode = "wkt",
  name,
  outSrid = 4326,
}: CoordinateEntryControlProps<T>) {
  const [srid, setSrid] = useState<number>(4326);
  const [data, _setData] = useState<any>({ [name]: undefined });
  const [mode, setMode] = useState<CoordinateMode>(entryMode);

  const { drawingLayer, map } = useFilterContext();

  const drawFeature = useCallback((geom: Geometry) => {
    if (!geom) return;
    const src = drawingLayer.getSource();
    if (src) {
      const g = geom.clone().transform(`EPSG:${srid}`, "EPSG:3857");
      const f = new Feature(g);
      src.clear();
      src.addFeature(f);
      console.log(map)
      if (src.getFeatures().length > 0 && map) {
        const ext = src.getExtent();
        map.getView().fit(ext, {
          padding: [100, 100, 100, 100],
          maxZoom: 13,
        });
      }
    }
  }, [drawingLayer, map, srid]);
  // Emit change to parent
  const emitChange = useCallback(
    (geom: Geometry) => {
      drawFeature(geom);
      switch (outMode) {
        case "wkt":
          onChange(createEventLike(name, geomToWkt(geom, srid, outSrid) as T));
          break;
        case "geoJSON":
          onChange(
            createEventLike(
              name,
              geomToGeoJSONFeature(geom, srid, outSrid) as T
            )
          );
          break;
        case "extent":
          onChange(
            createEventLike(name, geomToExtent(geom, srid, outSrid) as T)
          );
          break;
      }
    },
    [drawFeature, outMode, onChange, name, srid, outSrid]
  );

  // Validation functions
  const handleData = useCallback((evt: EventLike<Geometry>) => {
    _setData((_data: any) => {
      const updated = {
        ..._data,
        [evt.target.name]: evt.target.value,
      };
      return updated;
    });
  }, []);

  useEffect(() => {
    if (data[name]) {
      emitChange(data[name]);
    }
  }, [outSrid, srid, data, outMode, name, emitChange]);

  return (
    <>
      <SelectSrsControl
        name="srid"
        value={srid}
        onChange={(evt) => {
          setSrid(evt.target.value);
        }}
      />
      <CoordinateModeControl
        name="mode"
        value={mode}
        onChange={(evt) => setMode(evt.target.value)}
      />
      {mode === "wkt" && (
        <WktField
          onChange={handleData}
          name={name}
          srid={srid}
          value={data.wkt ?? ""}
        />
      )}
      {mode === "geoJSON" && (
        <GeoJSONField
          onChange={handleData}
          srid={srid}
          name={name}
          value={data.geoJSON ?? ""}
        />
      )}
      {mode === "extent" && (
        <ExtentField
          onChange={handleData}
          srid={srid}
          name={name}
          value={data.extent ?? ""}
        />
      )}
    </>
  );
}
