import * as d3 from 'd3';
import * as dc from 'dc';

export const CoordinateGridMixin = function (chart, props) {
  const {
    xAxisLabel = '',
    yAxisLabel = '',
    elasticX = true,
    elasticY = true,
    x = d3.scaleLinear(),
    xUnits = dc.units.integers,
    round = chart.round(),
    xAxisTickFormat = null,
    yAxisTickFormat = null,
    xAxisTickValues = null,
    yAxisTickValues = null,
    xAxisPadding = 0,
    yAxisPadding = 0,
    xAxisPaddingUnit = d3.timeDay,
    renderLabel = false
  } = props;

  chart
    .xAxisLabel(xAxisLabel)
    .yAxisLabel(yAxisLabel)
    .elasticX(elasticX)
    .elasticY(elasticY)
    .yAxisPadding(yAxisPadding)
    .x(x)
    .xUnits(xUnits)
    .round(round);

  if (xAxisPadding !== 0) {
    chart.xAxisPadding(xAxisPadding).xAxisPaddingUnit(xAxisPaddingUnit);
  }

  if (renderLabel === true && yAxisPadding === 0) {
    chart.yAxisPadding('10%');
  } else if (yAxisPadding > 0) {
    chart.yAxisPadding(yAxisPadding);
  }

  if (typeof xAxisTickFormat === 'function') {
    chart.xAxis().tickFormat(xAxisTickFormat);
  }

  if (typeof yAxisTickFormat === 'function') {
    chart.yAxis().tickFormat(yAxisTickFormat);
  }

  if (Array.isArray(xAxisTickValues)) {
    chart.xAxis().tickValues(xAxisTickValues);
  }

  if (Array.isArray(yAxisTickValues)) {
    chart.yAxis().tickValues(yAxisTickValues);
  }

  return chart;
};
