export function dateRange(
  fromDate: Date,
  toDate: Date,
  interval: "month" | "day" | "year" = "month"
) {
  // Set hours
  fromDate.setHours(0, 0, 0);
  toDate.setHours(0, 0, 0);

  // Setup array to hold dates
  const dates = [fromDate];

  let currentDate = new Date(fromDate);

  // Increment and add dates until...
  while (currentDate < toDate) {
    switch (interval) {
      case "day":
        currentDate.setDate(currentDate.getDate() + 1);
        break;
      case "month":
        currentDate.setMonth(currentDate.getMonth() + 1);
        break;
      case "year":
        currentDate.setFullYear(currentDate.getFullYear() + 1);
        break;
    }
    if (currentDate < toDate) {
      dates.push(new Date(currentDate));
    }
  }
  dates.push(toDate);
  return dates;
}
