import './Downloader.scss';

import React, { Component } from 'react';

import { DataLoader } from './../DataLoader/DataLoader';
import PropTypes from 'prop-types';

export class Downloader extends Component {
  static propTypes = {
    asyncResponse: PropTypes.object,
    checkProgressInterval: PropTypes.number
  };

  static defaultProps = {
    checkProgressInterval: 15
  };

  checkJobTimeout = null;
  clockTimeout = null;

  checkJobUrl = null;
  firstRun = true;

  constructor (props) {
    super(props);
    const { checkJobUrl } = this.props.asyncResponse.data;

    this.state = {
      msElapsed: 0,
      checkCount: 0,
      timeUntilNextCheck: 0
    };

    this.checkJobUrl = checkJobUrl;

    this.startClock = this.startClock.bind(this);
  }

  startClock () {
    const { timeUntilNextCheck, msElapsed, checkCount } = this.state;
    const { checkProgressInterval } = this.props;

    var mTimeUntilNextCheck =
      timeUntilNextCheck <= 0 ? checkProgressInterval : timeUntilNextCheck - 1;

    if (timeUntilNextCheck === 0 || this.firstRun === true) {
      this.firstRun = false;
      DataLoader.getApi(this.checkJobUrl).then(res => {
        if (res.success) {
          this.setState({
            duration: res.data.Duration,
            state: res.data.State,
            timestamp: res.data.Timestamp,
            waitingTime: res.data.WaitingTime,
            url: res.data.Url,
            checkCount: checkCount + 1
          });
        } else {
          this.setState({
            errorMessage: res.msg
          });
        }
      });
    }

    this.setState({
      timeUntilNextCheck: mTimeUntilNextCheck,
      msElapsed: msElapsed + 1000
    });
    this.clockTimeout = setTimeout(this.startClock, 1000);
  }

  componentDidMount () {
    this.startClock();
  }

  componentWillUnmount () {
    if (this.clockTimeout) {
      clearTimeout(this.clockTimeout);
    }
  }

  getStatus (code) {
    switch (code) {
      case 'Succeeded':
        return 'Fullført';
      case 'Failed':
        return 'Feilet';
      case 'Processing':
        return 'Pågår';
      case 'Enqueued':
      default:
        return 'Lagt i kø';
    }
  }

  render () {
    const { asyncResponse
      // checkProgressInterval
    } = this.props;
    const { state, msElapsed, checkCount, url, errorMessage, timeUntilNextCheck } = this.state;
    if (state === 'Succeeded' || state === 'Failed' || checkCount > 200) {
      clearTimeout(this.checkJobTimeout);
      clearTimeout(this.clockTimeout);
    }
    var status = this.getStatus(state);
    return (
      <div className='avi-downloader'>
        <h2 className='avi-h2'>Det gjøres nå et datauttrekk i Kystdatahuset</h2>
        {state && (
          <p>
            Status: <strong>{status}</strong>{' '}
            {(state === 'Processing' || state === 'Enqueueud') && (
              <span>(status oppdateres automatisk om {timeUntilNextCheck} sekunder)</span>
            )}
          </p>
        )}
        {errorMessage && (
          <div>
            <p>
              Det oppstod en feil. Denne informasjonen kan vere nyttig for feilsøking, legg den
              gjerne ved hvis du tar kontakt med teknisk support:
            </p>
            <p>
              <em>{errorMessage}</em>
            </p>
          </div>
        )}
        {!url && (
          <div>
            <p>
              Denne oppgaven utføres som en bakgrunnsjobb. Tidsbruken vil variere med størrelsen på
              området og tidsperioden du har valgt. Uttrekk kan ta fra noen få sekunder og opp til
              10 minutter. For ikke å overbelaste serveren tillates ikke uttrekk som tar mer enn 10
              minutter. Det tillates heller ikke utvalg som inneholder mer enn 1 000 000 objekter.
            </p>
            <p>
              Hvis jobben ikke er fullført etter 10 minutter kan du justere utvalgsparameterene
              (område/tid) og prøve på igjen med et mindre område eller en kortere tidsperiode. Vi
              har valgt å ikke legge noen forhåndsbegrensninger på utvalget da det i mange tilfeller
              vil vere ok med svært store områder og korte tidsperioder eller omvendt.
            </p>
            <p>
              Det blir sendt e-post med en lenke hvor data data kan lastes ned til E-postadressen:{' '}
              <strong>{asyncResponse.data.userEmail}</strong> når uttrekket på server er ferdig. Du
              kan stenge dette vinduet og arbeide videre med noe annet mens du venter.
            </p>
          </div>
        )}
        {url && (
          <div>
            <p>
              Filen din er klar for nedlasting: <a href={url}>{url}</a>
            </p>
            <p>Det tok {Math.round(msElapsed / 1000)} sekunder å gjennomføre uttrekket</p>
          </div>
        )}
      </div>
    );
  }
}
