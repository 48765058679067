import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DataLoader } from "../ui-components/DataLoader/DataLoader";
import { Spinner } from "react-bootstrap";
import { ShareData } from "../types/Share";
import { WebServiceResponse } from "../types/WebServiceResponse";

export function DbrdRedirector() {
  const navigate = useNavigate();
  const { shortCode } = useParams();

  useEffect(() => {
    async function doAsync() {
      if (!shortCode) return;      
      let res : WebServiceResponse<ShareData> = await DataLoader.decodeShortCode((shortCode));
      if (!res || res.success === false) return;
      navigate(res.data.route, { state: res.data.filter});
    }
    doAsync();
  }, [navigate, shortCode]);

  return <Spinner/>
}
