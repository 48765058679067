import * as d3 from 'd3';

import React, { Component } from 'react';

import { AVIChartTile } from '../Layout/AVIChartTile';
import { BaseMixin } from '../Mixins/BaseMixin';
import { ColorMixin } from '../Mixins/ColorMixin';
import { MarginsMixin } from '../Mixins/MarginsMixin';
import PropTypes from 'prop-types';
import { TileMixin } from '../Mixins/TileMixin';
import * as dc from 'dc';

export class HeatMap extends Component {
  static heatMapColorScale = d3
    .scaleLinear()
    .domain([-100000, 100, 200, 300, 400, 100000])
    .range(['#FFFFFF', '#0099CC', '#00CC00', '#FFFF00', '#FF9900', '#FF0000']);

  static weekDaysJsNo = ["Sø", 'Må', 'Ty', 'On', 'To', 'Fr', 'La'];
  static weekDaysJsEn = ["Su", 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  static weekDaysEn = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  static weekDaysNo = ['Må', 'Ti', 'On', 'To', 'Fr', 'Lø', 'Sø'];
  static weekDaysNoNN = ['Må', 'Ty', 'On', 'To', 'Fr', 'La', 'Sø'];
  static hoursOfDay = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00'
  ];

  static propTypes = {
    keyAccessor: PropTypes.func,
    valueAccessor: PropTypes.func,
    colorAccessor: PropTypes.func,
    title: PropTypes.func,
    xBorderRadius: PropTypes.number,
    yBorderRadius: PropTypes.number,
    rows: PropTypes.any,
    cols: PropTypes.any,
    rowOrdering: PropTypes.func,
    colOrdering: PropTypes.func,
    rowsLabel: PropTypes.func,
    colsLabel: PropTypes.func,
    chartTitle: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    useFlex: PropTypes.bool
  };

  static defaultProps = {
    keyAccessor: d => d.key[0],
    valueAccessor: d => d.key[1],
    colorAccessor: d => +d.value,
    rowOrdering: d3.ascending,
    colOrdering: d3.ascending,
    xBorderRadius: 5,
    yBorderRadius: 5,
    rows: undefined,
    cols: undefined,
    rowsLabel: d => d,
    colsLabel: d => d,
    useFlex: false
  };

  constructor (props) {
    super(props);
    this.chartRef = React.createRef();
    this.chart = null;
    this.getChart = this.getChart.bind(this);
    this.calcDomain = this.calcDomain.bind(this);
    this.dynamicScale = this.dynamicScale.bind(this);
  }

  dynamicScale (min, max) {
    min = min || 0;
    max = max || 500;
    var step = (max - min) / 5;
    var domain = [min];
    for (var i = 1; i <= 5; i++) {
      domain.push(domain[i - 1] + step);
    }
    return d3
      .scaleLinear()
      .domain(domain)
      .range(['#FFFFFF', '#0099CC', '#00CC00', '#FFFF00', '#FF9900', '#FF0000']);
  }

  calcDomain (chart) {
    var max = chart.colorAccessor()(chart.group().top(1)[0]);
    chart.colors(this.dynamicScale(0, max));
  }

  getChart () {
    return this.chart;
  }

  componentDidMount () {
    this.chart = dc.heatMap(this.chartRef.current);

    BaseMixin(this.chart, this.props);
    ColorMixin(this.chart, this.props);
    MarginsMixin(this.chart, this.props);
    TileMixin(this.chart, this.props);

    var {
      keyAccessor,
      valueAccessor,
      colorAccessor,
      title,
      xBorderRadius,
      yBorderRadius,
      rows,
      cols,
      rowOrdering,
      colOrdering,
      rowsLabel,
      colsLabel
    } = this.props;

    if (!title) {
      title = d => this.chart.keyAccessor()(d) + '/' + this.chart.valueAccessor()(d) + '/' + this.chart.colorAccessor()(d);
    }

    this.chart
      .keyAccessor(keyAccessor)
      .valueAccessor(valueAccessor)
      .colorAccessor(colorAccessor)
      .title(title)
      .colors(this.dynamicScale())
      .on('preRender', this.calcDomain)
      .on('preRedraw', this.calcDomain)
      .rowsLabel(rowsLabel)
      .colsLabel(colsLabel)
      .xBorderRadius(xBorderRadius)
      .yBorderRadius(yBorderRadius)
      .calculateColorDomain();

    if (Array.isArray(cols)) {
      this.chart.cols(cols);
    }
    if (Array.isArray(rows)) {
      this.chart.rows(rows);
    }
    if (typeof rowOrdering === 'function') {
      this.chart.rowOrdering(rowOrdering);
    }
    if (typeof colOrdering === 'function') {
      this.chart.colOrdering(colOrdering);
    }

    this.chart.render();
  }

  render () {
    const { chartTitle, width, height, useFlex } = this.props;

    return (
      <AVIChartTile
        title={chartTitle}
        getChart={this.getChart}
        width={width}
        height={height}
        useFlex={useFlex}
      >
        <div className='avi-chart' ref={this.chartRef} />
      </AVIChartTile>
    );
  }
}
