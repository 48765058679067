import './DbrdDownloadVoyages.scss';

import React, { Component } from 'react';

import { DashboardConfig } from '../../config/kyvDashboard';
import { DataLoader } from '../../ui-components/DataLoader/DataLoader';
import { Downloader } from '../../ui-components/Downloader/Downloader';
import { FilterToFromLocation } from '../../filters/FilterToFromLocation';
import { FilterToFromTime } from '../../filters/FilterToFromTime';
import { Loader } from '../../ui-components/Loader/Loader';
import PropTypes from 'prop-types';
import { TimeUtils } from './../../chart-components/ChartUtils/TimeUtils';
import { selectableLayers } from '../../filter-components/Map/selectableLayers';
import withRouter from '../../hocs/withRouter';

class DbrdDownloadVoyagesBase extends Component {
  static dashboardRoute = 'last-ned-seilas';

  static dashboardFilters (filter, setFilter) {
    return {
      helpMessage: 'Nedlasting av "skipshaler" for seilas mellom to steder. Dette er et dashboard under utvikling',
      controls: <div key='flt-time-location'>
        <FilterToFromTime key='flt' minTime={new Date(2010, 0, 1)} maxTime={TimeUtils.getDateDiff(-3)} filter={filter} setFilter={setFilter} />
        <FilterToFromLocation key='locations' filter={filter} setFilter={setFilter} /></div>
    };
  }

  static dashboardSettings () {
    return {
      filterControls: [
        DashboardConfig.filterControls.selectPoint
      ],
      selectableLayer: selectableLayers.locationIds
    };
  }

  static dashboardValidation (filter) {
    if (filter.fromTime &&
      filter.toTime &&
      ((Array.isArray(filter.locationIds) && filter.locationIds.length === 2) || (filter.location1 && filter.location2))) {
      return true;
    }
  }

  // Here we set up an object to receive properties from the previous screen

  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        fromTime: PropTypes.instanceOf(Date).isRequired,
        toTime: PropTypes.instanceOf(Date).isRequired,
        locationIds: PropTypes.array,
        location1: PropTypes.number,
        location2: PropTypes.number
      }).isRequired
    })
  };

  constructor (props) {
    super(props);
    this.state = {
      asyncResponse: null
    };
    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount () {
    const { fromTime, toTime, locationIds, location1, location2 } = this.props.location.state;

    if (!fromTime || !toTime || ((!locationIds || locationIds.length < 2) && (!location1 || !location2))) {
      throw new Error('Not sufficient parameters');
    }

    var tmpLocationIds = [];
    if (locationIds && locationIds.length >= 2) {
      tmpLocationIds = locationIds.slice(0);
    } else {
      tmpLocationIds.push(location1);
      tmpLocationIds.push(location2);
    }

    // Use input variables for the data query
    var mergeRequests = [
      DataLoader.postApi(
        '/api/tracks/async/download/by-voyage-timespan',
        {
          LocationID1: tmpLocationIds[0],
          LocationID2: tmpLocationIds[1],
          StartTime: fromTime,
          EndTime: toTime
        },
        this.reportProgress
      )
    ];

    Promise.all(mergeRequests).then(
      jsonResponses => {
        // Check that all responses are successful
        var success = true;
        jsonResponses.forEach(r => {
          if (!r.success) {
            if (success) {
              success = false;
            }
          }
        });

        if (!success) {
          throw new Error('Not all responses were successful');
        }

        this.setState({
          asyncResponse: jsonResponses[0]
        });
      },
      error => {
        error && console.error('Error occurred');
      }
    );
  }

  reportProgress (progressData) {
    this.setState({
      progressData: progressData
    });
  }

  render () {
    const { asyncResponse, progressData } = this.state;

    if (!asyncResponse) {
      return <Loader progressData={progressData} />;
    }

    return <Downloader asyncResponse={asyncResponse} />;
  }
}

export const DbrdDownloadVoyages = withRouter(DbrdDownloadVoyagesBase);

export default DbrdDownloadVoyages;