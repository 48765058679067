import "./DbrdSailedDistanceSvoOmr.scss";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { BarChartStacked } from "../../chart-components/Charts/BarChartStacked";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { FilterSvoOmr } from "../../filters/FilterSvoOmr";
import { FilterToFromMonth } from "../../filters/FilterToFromMonth";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";

class DbrdSailedDistanceSvoOmrBase extends Component<any, any> {
  /**
   * The route to be used for the dashboard
   */
  static dashboardRoute = "sailed-distance-svoomr";

  /**
   * The help text and filter controls to be used as initial filtering for the dashboard
   * @param {*} filter
   * @param {*} setFilter
   */
  static dashboardFilters(filter, setFilter) {
    return {
      helpMessage:
        "Dette dashboardet gjør det mulig å analysere flere år samtidig. Velg et eller flere spesielt verdifulle områder og et tidsrom",
      controls: [
        <FilterToFromMonth
          key="flt-tf-mnth"
          firstYear={2013}
          filter={filter}
          setFilter={setFilter}
        />,
        <FilterSvoOmr
          key="flt-omr"
          filter={filter}
          isMulti
          setFilter={setFilter}
        />,
      ],
    };
  }

  /**
   * The dashboard settings, i.e. map interaction filter controls, selectable layers etc.
   */
  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: null,
    };
  }

  /**
   * The dashboard validation function
   * @param {*} filter
   */
  static dashboardValidation(filter) {
    console.debug(filter);
    if (
      filter.fromTime &&
      filter.toTime &&
      Array.isArray(filter.svoomr) &&
      filter.svoomr.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        fromTime: PropTypes.any.isRequired,
        toTime: PropTypes.any.isRequired,
        svoomr: PropTypes.arrayOf(PropTypes.object).isRequired,
      }).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
    };
    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    try {
      const { fromTime, toTime, svoomr } = this.props.location.state;

      if (Array.isArray(svoomr) && fromTime && toTime) {
        DataLoader.postApi(
          "/api/ais/sailed-distance/svoomr",
          {
            StartDate: fromTime,
            EndDate: toTime,
            AreaIds: svoomr.map((m) => m.value),
          },
          this.reportProgress
        )
          .then((jsonResponse) => {
            var success = true;

            if (!jsonResponse.success) {
              if (success) {
                success = false;
              }
            }

            if (!success) {
              throw new Error("Error loading sailed distance within svoomr");
            }

            var data = jsonResponse.data;
            this.setState({
              fromTime,
              toTime,
              svoomrNames: svoomr.map((m) => m.label),
              chartData: crossfilter(data),
              numRecords: data.length,
            });
          })
          .catch((error) => {
            console.warn(error);
            this.setState({
              chartData: crossfilter([]),
            });
          });
      } else {
        throw new Error("Error no data returned");
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const { chartData, fromTime, toTime, svoomrNames, progressData } =
      this.state;

    if (!chartData || chartData.size() === 0) {
      return <Loader progressData={progressData} chartData={chartData} />;
    }

    var dimCount = chartData.dimension((d) => d.month);

    var dimArea = chartData.dimension((d) => `${d.area_name} (${d.area_id})`);
    var nmiByArea = dimArea.group().reduceSum((d) => Math.round(d.nmi));

    var dimYear = chartData.dimension((d) => new Date(d.month).getFullYear());
    var nmiByYear = dimYear.group().reduceSum((d) => Math.round(d.nmi));
    var years = nmiByYear.all().map((d) => d.key);

    var dimMonth = chartData.dimension((d) => new Date(d.month).getMonth() + 1);
    var nmiByMonth = dimMonth.group().reduceSum((d) => Math.round(d.nmi));

    var dimAisClass = chartData.dimension((d) => d.aisclass);
    var nmiByAisClass = dimAisClass.group().reduceSum((d) => Math.round(d.nmi));

    var dimShipCategory = chartData.dimension((d) => d.shipcategory);
    var nmiByShipCategory = dimShipCategory
      .group()
      .reduceSum((d) => Math.round(d.nmi));

    return (
      <div className="AppView">
        <AVIDashboard
          title="Utseilt distanse for spesielt verdifulle områder"
          desc={`Utseilt distanse i nautiske mil (NM) i perioden ${TimeUtils.getYearMonth(
            fromTime
          )} - ${TimeUtils.getYearMonth(
            toTime
          )} for (de) spesielt verdifulle område(ne): ${svoomrNames.join(
            ", "
          )}.`}
          spacing={10}
          keyName="mmsino"
          valueAccessor={(d) => d.count}
          group={dimCount.groupAll()}
          cfilter={chartData}
          filter={this.props.location.state}
          useFlex
        >
          <AVIRow>
            <AVICol>
              <BarChartOrdinal
                group={nmiByArea}
                dimension={dimArea}
                chartTitle="Utseilt distanse (NM) etter område"
                width={4}
                height={1}
                margins={{ left: 65 }}
                yAxisLabel="Utseilt distanse (NM)"
                filterPrefix="Kommune"
                ordering={(d) => d.key}
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartOrdinal
                group={nmiByYear}
                dimension={dimYear}
                chartTitle="Utseilt distanse (NM) etter år"
                useFlex
                height={1}
                margins={{ left: 65 }}
                yAxisLabel="Utseilt distanse (NM)"
                filterPrefix="År"
                ordering={(d) => d.key}
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartOrdinal
                group={nmiByMonth}
                dimension={dimMonth}
                chartTitle="Utseilt distanse (NM) etter måned i året"
                useFlex
                height={1}
                margins={{ left: 65 }}
                yAxisLabel="Utseilt distanse (NM)"
                filterPrefix="Måned"
                ordering={(d) => d.key}
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <RowChart
                chartTitle="Utseilt distanse (NM) etter AIS-klasse"
                group={nmiByAisClass}
                dimension={dimAisClass}
                useFlex
                height={1.5}
                gap={1}
                filterPrefix="AIS-klasse"
              />
            </AVICol>
            <AVICol>
              <RowChart
                chartTitle="Utseilt distanse (NM) etter skipskategori"
                group={nmiByShipCategory}
                dimension={dimShipCategory}
                useFlex
                height={1.5}
                gap={1}
                filterPrefix="Skipskategori"
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartStacked
                chartTitle="Utseilt distanse (NM) etter lengdegruppe og år"
                dimension={dimYear}
                xAxisTickValues={years}
                useFlex
                height={1.5}
                margins={{ left: 65, top: 50 }}
                yAxisLabel="Utseilt distanse (NM)"
                gap={2}
                filterPrefix="År"
                stackKey="length_grp"
                valueKey="nmi"
                renderLabel
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartStacked
                chartTitle="Utseilt distanse (NM) etter bruttotonnasjegruppe og år"
                dimension={dimYear}
                xAxisTickValues={years}
                useFlex
                height={1.5}
                margins={{ left: 65, top: 50 }}
                yAxisLabel="Utseilt distanse (NM)"
                gap={2}
                filterPrefix="År"
                stackKey="gt_grp"
                valueKey="nmi"
                renderLabel
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartStacked
                chartTitle="Utseilt distanse (NM) etter dødvektstonnasje og år"
                dimension={dimYear}
                xAxisTickValues={years}
                useFlex
                height={1.5}
                margins={{ left: 65, top: 50 }}
                yAxisLabel="Utseilt distanse (NM)"
                gap={2}
                filterPrefix="År"
                stackKey="dwt_grp"
                valueKey="nmi"
                renderLabel
              />
            </AVICol>
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdSailedDistanceSvoOmr = withRouter(
  DbrdSailedDistanceSvoOmrBase
);

export default DbrdSailedDistanceSvoOmr;
