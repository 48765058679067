import * as d3 from "d3";

import { ExecUtils } from "../ChartUtils/ExecUtils";
import { NumUtils } from "./../ChartUtils/NumUtils";
import * as dc from "dc";

export class DcExtPivotTable {
  _options;
  _table;
  _chart;
  _filteredValues = [];

  constructor(props) {
    this._options = props;
    this._chart = dc.baseMixin({});
    this._chart.group(props.group);
    this._chart.dimension(props.dimension);
    this._chart.on("postRedraw", (chart, filter) => {
      this._draw();
    });
    if (props.filterPrefix) {
      this._chart.filterPrefix = props.filterPrefix;
    }
    dc.registerChart(this._chart);

    // Sort list
    this._chart.group().all().sort(this._sort);
  }

  data() {
    const { chartTitle, rowLabel, colLabel } = this._options;

    return this._chart
      .data()
      .slice(0)
      .map((d) => {
        var o = {};
        o[rowLabel] = d.key[0];
        o[colLabel] = d.key[1];
        o[chartTitle] = d.value;
        return o;
      });
  }

  // Sort function
  _sort(a, b) {
    if (a.key[0] > b.key[0]) {
      return 1;
    } else if (b.key[0] > a.key[0]) {
      return -1;
    } else if (a.key[1] > b.key[1]) {
      return 1;
    } else if (b.key[1] > a.key[1]) {
      return -1;
    } else {
      return 0;
    }
  }

  // resetFilter = ExecUtils.debounce(this, this._resetFilter, 500);
  
  // _resetFilter() {
  //   this._filteredValues = [];
  // }

  draw = ExecUtils.debounce(this, this._draw, 500);

  _draw() {
    const { parent, rowKeyIndex, colKeyIndex, rowLabel, colLabel } =
      this._options;      

    // Clear table content if any
    parent.html("");

    // Filter function

    let theChart = this._chart;

    // let dim = theChart.dimension();
    // if (dim && !dim.hasCurrentFilter()) {
    //   this._filteredValues = [];
    // }

    let theFilteredValues = this._filteredValues;

    function onFilter(e) {
      var val = d3.event.target.value;
      var found = theFilteredValues.indexOf(val);
      if (found === -1) {
        theFilteredValues.push(val);
      } else {
        theFilteredValues = theFilteredValues.splice(found, 1);
      }
      theChart.filter(val);
      dc.redrawAll();
    }

    // Pivot
    var all = this._chart.group().all();
    var pa = {};
    let sumRow = { key: "Sum", value: {} };

    all.forEach((d) => {
      let rowKey = d.key[rowKeyIndex];
      let colKey = d.key[colKeyIndex];
      pa[rowKey] = pa[rowKey] || {};
      pa[rowKey][colKey] = d.value;
      sumRow.value[colKey] = sumRow.value[colKey]
        ? sumRow.value[colKey] + d.value
        : d.value;
    });
    let pivotedRows = [];
    for (let key in pa) {
      pivotedRows.push({ key: key, value: pa[key] });
    }
    pivotedRows.push(sumRow);

    // Get row/col labels
    // var rows = [...new Set(all.map(d => d.key[rowKeyIndex]))];
    var cols = [...new Set(all.map((d) => d.key[colKeyIndex]))];

    // Sort the columns
    cols.sort();

    let header = parent.append("thead");
    let hr = header.append("tr");
    hr.classed("header-row");
    hr.append("th")
      .classed("bottom", true)
      .attr("rowspan", 2)
      .style("width", "10px")
      .text("#");
    hr.append("th").attr("rowspan", 2).classed("bottom", true).text(rowLabel);
    hr.append("th")
      .attr("colspan", cols.length)
      .classed("bottom", true)
      .text(colLabel);
    let hr2 = header.append("tr");
    hr2.classed("header-row");
    for (let col in cols) {
      hr2.append("th").text(cols[col]);
    }

    pivotedRows.forEach((pivotedRow, pivotedRowIdx, a) => {
      let row = parent.append("tr");
      if (pivotedRowIdx === a.length - 1) {
        row.style("font-weight", "bold");
      }
      let checkBoxCell = row.append("td");
      if (pivotedRowIdx < a.length - 1) {
        let checkBox = checkBoxCell
          .append("input")
          .attr("type", "checkbox")
          .attr("value", pivotedRow.key);

        if (theFilteredValues.indexOf(pivotedRow.key) > -1) {
          checkBox.attr("checked", true);
        }

        checkBox.on("click", onFilter);
      }

      row.append("td").html(pivotedRow.key);

      // Loop through columns in sorted order
      for (let col of cols) {
        let colVal = "n/a";
        if (pivotedRow.value.hasOwnProperty(col)) {
          colVal = NumUtils.integer(pivotedRow.value[col]);
        }
        row.append("td").classed("right", true).html(colVal);
      }
    });
  }
}
