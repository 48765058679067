import { Button, Card, CardBody, CardFooter, CardImg, CardText, CardTitle, Col } from "react-bootstrap";
import { KyvMetadataRecord } from "../../utils/kystinfo";
import React from "react";

export type DataCatalogTileProps = {
    rec: KyvMetadataRecord
}

export function DataCatalogTile({ rec }: DataCatalogTileProps) {
    return <Col xs={12} sm={6} md={4} lg={3}>
        <div className="w-100 h-100 pe-3 pb-3">
            <Card bg="white" text="black" className="w-100 h-100">
                <CardImg src="/dashboard.png" style={{ height: "20vh", objectFit: "cover" }} />
                <CardBody>
                    <CardTitle>
                        <div className="text-start">
                            {rec.title}
                        </div>
                    </CardTitle>
                    <CardText className="kyvdvh-truncate-2l text-body-secondary" dangerouslySetInnerHTML={{ __html: rec.abstract }} />
                </CardBody>
                <CardFooter className="border-top-0 bg-white d-flex gap-1 align-items-center">
                    <Button variant="outline-dark" className="me-auto small p-1 border-0"><i className="bi bi-link"/></Button>
                    <div className="small text-body-secondary">
                        {rec.resource_type}
                    </div>
                </CardFooter>
            </Card>
        </div>
    </Col>
}