import { Geometry } from "ol/geom";
import { PropsWithChildren, useCallback } from "react";
import { FormGroup, FormLabel } from "react-bootstrap";
import { CoordinateEntryControl, CoordinateMode } from "../../../filter-components/CoordinateEntryControl/CoordinateEntryControl";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../../types/DashboardFilter";
import { EventLike } from "../../../types/Shims";

export type EnterCoordinatesProps = PropsWithChildren & {
  name?: string;
  setFilter: SetDashboardFilterFunc;
  filter: DashboardFilter;
  outMode?: CoordinateMode;  
};

export function EnterCoordinates({
  filter,
  setFilter,
  name = "geom",
  outMode = "geoJSON",
  children = "Legg inn koordinater manuelt",
}: EnterCoordinatesProps & PropsWithChildren) {

  const handleChange = useCallback(
    (evt: EventLike<Geometry>) => {
      setFilter({
        [name]: [evt.target.value],
      });
    },
    [name, setFilter]
  );

  return (
    <FormGroup className="mb-2">
      <FormLabel>{children}</FormLabel>
      <div className="d-grid">
        <CoordinateEntryControl
          key="flt_geom"
          name="geom"
          value={filter[name]}
          onChange={handleChange}
          outMode={outMode}
        />
      </div>
    </FormGroup>
  );
}
