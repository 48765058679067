export class ConvUtils {
  static bytesToMB(bytes, decimals) {
    if (!decimals || isNaN(+decimals)) {
      decimals = 2;
    }
    return ConvUtils.roundToDecimals(+bytes / 1000000, +decimals);
  }

  static bytesToKB(bytes, decimals) {
    if (!decimals || isNaN(+decimals)) {
      decimals = 2;
    }
    return ConvUtils.roundToDecimals(+bytes / 1000, +decimals);
  }

  static roundToDecimals(number, decimals) {
    if (!decimals || isNaN(+decimals) || decimals === 0) {
      return Math.round(+number);
    } else {
      var factor = Math.pow(10, +decimals);
      return Math.round(+number * factor) / factor;
    }
  }
}
