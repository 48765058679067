import "./BarChartRace.scss";
import React, {
  useRef,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import * as d3 from "d3v7";
import { useD3v7 } from "../../hooks/useD3v7";
import {
  _barX,
  _barY,
  _barWidth,
  _textAnchor,
  _halo,
  _translateXY,
  _drawBars,
  _textSpan,
  _drawLabels,
} from "./BarChartRace.util";

export function BarChartRace(props) {
  let {
    calcMethod,
    crossfilter,
    caption,
    colourAccessor,
    colourScale,
    group,
    description,
    endTime,
    height,
    labelAccessor,
    labelFontSize,
    labelPadding,
    margin,
    onComplete,
    run,
    startTime,
    tickDuration,
    timeAccessor,
    timeIncrement,
    timeLabelFormat,
    title,
    topN,
    useFlex,
    valueAccessor,
    valueLabelFormat,
    width,
  } = props;

  const [recalculate, setRecalculate] = useState(true);

  useEffect(() => {
    crossfilter.onChange((e) => {
      if (e === "filtered") {
        setRecalculate(true);
      }
    });
  }, [crossfilter]);

  // Determine bar padding
  let barPadding = useMemo(() => {
    return (height - (margin.bottom + margin.top)) / (topN * 5);
  }, [height, margin, topN]);

  const chart = useRef({
    currentTime: startTime,
  });

  // Preprocess data
  let procData = useMemo(() => {
    let data = group.all();
    if (!Array.isArray(data)) return [];
    setRecalculate(false);

    let tmpData = data.map((d) => {
      return {
        name: labelAccessor(d),
        value: isNaN(+valueAccessor(d)) ? 0 : +valueAccessor(d),
        lastValue: null, // Dummy value
        timeStep: +timeAccessor(d),
        colour: colourScale(colourAccessor(d)),
      };
    });

    // Calculate previous value
    d3.group(tmpData, (d) => d.name).forEach((nameSlice) => {
      let sortedSlice = nameSlice.sort((a, b) => a.timeStep - b.timeStep);
      for (var i0 = 0, i1 = 1; i1 < sortedSlice.length; i0++, i1++) {
        if (i0 === 0) {
          sortedSlice[i0].lastValue = 0;
        }
        sortedSlice[i1].lastValue = sortedSlice[i0].value;
      }
    });

    // Optional calculation steps
    // Calculate percentage change per time step
    if (calcMethod === BarChartRace.CALC_METHOD.PCT_DIFF) {
      d3.group(tmpData, (d) => d.name).forEach((nameSlice) => {
        let sortedSlice = nameSlice.sort((a, b) => a.timeStep - b.timeStep);
        for (var i0 = 1, i1 = 2; i1 < sortedSlice.length; i0++, i1++) {
          let pctDiffLastValue =
            (sortedSlice[i0].value / sortedSlice[i0].lastValue) * 100;
          sortedSlice[i1].diffLastValue = pctDiffLastValue;
          let pctDiffValue =
            (sortedSlice[i1].value / sortedSlice[i1].lastValue) * 100;
          sortedSlice[i1].diffValue = pctDiffValue;
        }
      });
      tmpData.forEach((d) => {
        d.value = d.diffValue;
        d.lastValue = d.diffLastValue;
        delete d.diffValue;
        delete d.diffLastValue;
      });
      // Calculate cumulative sum per time step
    } else if (calcMethod === BarChartRace.CALC_METHOD.ACCUMULATED) {
      d3.group(tmpData, (d) => d.name).forEach((nameSlice) => {
        let sortedSlice = nameSlice.sort((a, b) => a.timeStep - b.timeStep);
        let accValue = d3.cumsum(sortedSlice, (d) => d.value);
        let accLastValue = d3.cumsum(sortedSlice, (d) => d.lastValue);
        for (var i1 = 0; i1 < sortedSlice.length; i1++) {
          sortedSlice[i1].value = accValue[i1];
          sortedSlice[i1].lastValue = accLastValue[i1];
        }
      });
    } else {
      // console.log('No processing');
    }
    return tmpData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    group,
    labelAccessor,
    valueAccessor,
    timeAccessor,
    calcMethod,
    colourAccessor,
    colourScale,
    recalculate,
  ]);

  const stopRace = useCallback(() => {
    if (chart.current.ticker) {
      clearInterval(chart.current.ticker);
    }
  }, []);

  const draw = useCallback(
    (svg) => {
      // Remove what is there on second load
      svg.selectAll("*").remove();

      // Add title
      svg.append("text").attr("class", "title").attr("y", 24).html(title);

      // Add description (sub-title)
      svg
        .append("text")
        .attr("class", "subTitle")
        .attr("y", 55)
        .html(description);

      // Add caption (below timestep indicator)
      svg
        .append("text")
        .attr("class", "caption")
        .attr("x", width)
        .attr("y", height - 5)
        .style("text-anchor", "end")
        .html(caption);

      // Calculate rank
      let timeSlice = procData
        .filter(
          (d) => d.timeStep === chart.current.currentTime && !isNaN(d.value)
        )
        .sort((a, b) => b.value - a.value)
        .slice(0, topN);

      timeSlice.forEach((d, i) => {
        d.rank = i;
      });

      const [xmin, xmax] = d3.extent(timeSlice, (d) => d.value);

      let x = d3
        .scaleLinear()
        .domain([xmin > 0 ? 0 : xmin, xmax < 0 ? 0 : xmax])
        .range([margin.left, width - margin.right - 65]);

      let y = d3
        .scaleLinear()
        .domain([topN, 0])
        .range([height - margin.bottom, margin.top]);

      let xAxis = d3
        .axisTop()
        .scale(x)
        .ticks(width > 500 ? 5 : 2)
        .tickSize(-(height - margin.top - margin.bottom))
        .tickFormat((d) => d3.format(",")(d));

      svg
        .append("g")
        .attr("class", "axis xAxis")
        .attr("transform", `translate(0, ${margin.top})`)
        .call(xAxis)
        .selectAll(".tick line")
        .classed("origin", (d) => d === 0);

      svg
        .selectAll("rect.bar")
        .data(timeSlice, (d) => d.name)
        .enter()
        .call(_drawBars(x, y, barPadding));

      svg
        .selectAll("text.label")
        .data(timeSlice, (d) => d.name)
        .enter()
        .call(
          _drawLabels(
            x,
            y,
            "label",
            labelPadding,
            labelFontSize,
            valueLabelFormat
          )
        );

      let timeText = svg
        .append("text")
        .attr("class", "timeStep")
        .attr("x", width - margin.right)
        .attr("y", 0 + 50)
        .style("text-anchor", "end")
        .html(timeLabelFormat(chart.current.currentTime))
        .call(_halo, 2);

      chart.current = {
        ...chart.current,
        svg: svg,
        timeText: timeText,
        xAxis: xAxis,
        x: x,
        y: y,
      };
    },
    [
      barPadding,
      chart,
      valueLabelFormat,
      timeLabelFormat,
      procData,
      margin,
      caption,
      description,
      labelFontSize,
      labelPadding,
      title,
      height,
      topN,
      width,
    ]
  );

  /**
   * Run the race
   */
  const startRace = useCallback(() => {
    if (!chart.current) return;

    let { svg, x, y, xAxis, timeText, currentTime } = chart.current;

    if (chart.current.ticker) {
      clearInterval(chart.current.ticker);
    }

    currentTime = startTime;

    chart.current.ticker = setInterval((e) => {
      let timeSlice = procData
        .filter((d) => +d.timeStep === currentTime && !isNaN(d.value))
        .sort((a, b) => b.value - a.value)
        .slice(0, topN);

      timeSlice.forEach((d, i) => {
        d.rank = i;
      });

      const [xmin, xmax] = d3.extent(timeSlice, (d) => d.value);
      x.domain([xmin > 0 ? 0 : xmin, xmax < 0 ? 0 : xmax]);

      svg
        .select(".xAxis")
        .transition()
        .duration(tickDuration)
        .ease(d3.easeLinear)
        .call(xAxis);

      let bars = svg.selectAll(".bar").data(timeSlice, (d) => d.name);

      // Add new bars
      bars.enter().call(_drawBars(x, y, barPadding, tickDuration, true));

      // Transition all bars
      bars
        .transition()
        .duration(tickDuration)
        .ease(d3.easeLinear)
        .attr("width", _barWidth(x))
        .attr("x", _barX(x))
        .attr("y", _barY(y));

      // Remove old bars
      bars
        .exit()
        .transition()
        .duration(tickDuration)
        .ease(d3.easeLinear)
        .attr("width", _barWidth(x))
        .attr("y", (d) => y(topN + 1) + 5)
        .remove();

      let labels = svg.selectAll(".label").data(timeSlice, (d) => d.name);

      // Add new labels
      labels
        .enter()
        .append("text")
        .call(_textSpan(x, labelFontSize, valueLabelFormat))
        .attr("class", "label")
        .attr("transform", _translateXY(x, y, labelPadding))
        .style("text-anchor", _textAnchor(x))
        .transition()
        .duration(tickDuration)
        .ease(d3.easeLinear)
        .attr("transform", _translateXY(x, y, labelPadding));

      // Transition labels
      labels
        .transition()
        .duration(tickDuration)
        .ease(d3.easeLinear)
        .attr("transform", _translateXY(x, y, labelPadding))
        .style("text-anchor", _textAnchor(x))
        .call((s) =>
          s
            .select("tspan.value")
            .call((s2) => !s2.empty())
            .tween("text", (d) => {
              let i = d3.interpolate(d.lastValue, d.value);
              return function (t) {
                this.textContent = valueLabelFormat(i(t));
              };
            })
        );

      // Remove low ranking labels
      labels
        .exit()
        .transition()
        .duration(tickDuration)
        .ease(d3.easeLinear)
        .attr("transform", (d) => {
          let dx = x(d.value) - 8;
          let dy = y(topN + 1) + 5;
          return `translate(${dx} ${dy})`;
        })
        .remove();

      timeText.html(timeLabelFormat(currentTime));

      if (currentTime === endTime) {
        clearInterval(chart.current.ticker);
        onComplete();
      }

      // Increment time
      currentTime = timeIncrement(currentTime);
    }, tickDuration);
  }, [
    startTime,
    endTime,
    tickDuration,
    timeIncrement,
    topN,
    barPadding,
    procData,
    timeLabelFormat,
    valueLabelFormat,
    labelFontSize,
    labelPadding,
    onComplete,
  ]);

  const chartRef = useD3v7(
    (svg) => {
      if (!procData) return;

      if (chart.current.ticker) {
        clearInterval(chart.current.ticker);
      }

      draw(svg);

      if (run === true) {
        startRace();
      }

      return () => {
        if (chart.current.ticker) {
          clearInterval(chart.current.ticker);
        }
        svg.selectAll("*").remove();
      };
    },
    [procData, run]
  );

  let flexStyle = {};
  if (useFlex) {
    flexStyle["flex"] = 1;
  }

  return (
    <div className="polirural-barchart-race" style={flexStyle}>
      <div className="chart-container">
        <svg
          ref={chartRef}
          viewBox={`0 0 ${width} ${height}`}
          xmlns="http://www.w3.org/2000/svg"
        />
      </div>
      <div className="action-buttons">
        <button onClick={() => startRace()}>Start</button>
        <button onClick={() => stopRace()}>Stop</button>
      </div>
    </div>
  );
}

/**
 * Calculation methods
 */
BarChartRace.CALC_METHOD = {
  STANDARD: "standard",
  PCT_DIFF: "pct_diff",
  ACCUMULATED: "accumulated",
};

/**
 * Property types
 */
BarChartRace.propTypes = {
  caption: PropTypes.string.isRequired,
  colourAccessor: PropTypes.func.isRequired,
  crossfilter: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  endTime: PropTypes.PropTypes.number.isRequired,
  labelAccessor: PropTypes.func.isRequired,
  startTime: PropTypes.number.isRequired,
  timeAccessor: PropTypes.func.isRequired,
  timeIncrement: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  valueAccessor: PropTypes.func.isRequired,
  calcMethod: PropTypes.string,
  colourScale: PropTypes.func,
  height: PropTypes.number,
  labelFontSize: PropTypes.number,
  labelPadding: PropTypes.number,
  margin: PropTypes.shape({
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
  }),
  onComplete: PropTypes.func,
  run: PropTypes.bool,
  tickDuration: PropTypes.number,
  timeLabelFormat: PropTypes.func,
  topN: PropTypes.number,
  valueLabelFormat: PropTypes.func,
  width: PropTypes.number,
  useFlex: PropTypes.bool,
};

/**
 * Default properties
 */
BarChartRace.defaultProps = {
  calcMethod: BarChartRace.CALC_METHOD.STANDARD,
  colourAccessor: () => null,
  colourScale: (colourKey) => d3.hsl(Math.random() * 360, 0.75, 0.75),
  height: 480,
  labelFontSize: 12,
  labelPadding: 5,
  margin: {
    bottom: 5,
    left: 0,
    right: 0,
    top: 80,
  },
  onComplete: () => null,
  run: false,
  tickDuration: 500,
  timeLabelFormat: d3.format("d"),
  topN: 12,
  valueLabelFormat: d3.format(",d"),
  width: 640,
  useFlex: true,
};

export default BarChartRace;
