import request from "superagent";
import { getAuthState } from "../../auth-components/AuthLib";
import { GeoJsonUtils } from "../../chart-components/GeoJsonUtils/GeoJsonUtils";
import { DashboardConfig } from "../../config/kyvDashboard";
import { geoJSONFormat, wktFormat } from "../../utils/geomUtils";
import { SuperagentProgress } from "../Loader/Loader";
import { ShareData } from "../../types/Share";
import { WebServiceResponse } from "../../types/WebServiceResponse";

export type SuperagentProgressHandler = (progress: SuperagentProgress) => void;
export type ErrorRepoortHandler = (error: any) => void;
export type SuperagentParams = { [key: string]: any } | string;

export class DataLoader {
  static total: any = 0;
  static percent: any = 0;
  static loaded: any = 0;
  static timeStamp: any = 0;

  static checkApiUrl() {
    if (!process.env.REACT_APP_DASHBOARD_WS_API) {
      throw new Error(
        "Environment variable REACT_APP_DASHBOARD_WS_API not set"
      );
    }
  }

  /**
   * This function can be called with an absolute or relative path to the web service
   * @param url
   * @param params
   * @param reportProgress
   * @param reportError
   * @returns
   */
  static postApi<T = any>(
    url: string,
    params: SuperagentParams = {},
    reportProgress?: SuperagentProgressHandler,
    reportError?: ErrorRepoortHandler
  ) {
    this.checkApiUrl();
    if (!url.startsWith(process.env.REACT_APP_DASHBOARD_WS_API!)) {
      url = process.env.REACT_APP_DASHBOARD_WS_API + url;
    }
    return DataLoader.post(
      url,
      params,
      reportProgress,
      reportError
    ) as Promise<T>;
  }

  /**
   * This function can be called with an absolute or relative path to the web service
   * @param url
   * @param params
   * @param reportProgress
   * @param reportError
   * @returns
   */
  static deleteApi<T = any>(
    url: string,
    params: SuperagentParams = {},
    reportProgress?: SuperagentProgressHandler,
    reportError?: ErrorRepoortHandler
  ) {
    this.checkApiUrl();
    if (!url.startsWith(process.env.REACT_APP_DASHBOARD_WS_API!)) {
      url = process.env.REACT_APP_DASHBOARD_WS_API + url;
    }
    return DataLoader.delete(
      url,
      params,
      reportProgress,
      reportError
    ) as Promise<T>;
  }

  // TODO: Check type of encoding
  static writeAnalytics(logData: SuperagentParams) {
    return DataLoader.postApi(
      "/api/analytics/log/write/2/kystdatahuset",
      JSON.stringify(logData)
    )
      .then(function _handleResponse(res) {
        return true;
      })
      .catch(function _handleError(err) {
        console.warn("Error writing analytics", err);
        return false;
      });
  }

  /**
   * Read analytics log data
   *
   * @param {Date} fromDate YYYY-mm-dd
   * @param {Date} toDate YYYY-mm-dd
   * @returns Log data
   */
  static readAnalytics(fromDate: string, toDate: string) {
    return DataLoader.getApi(
      `/api/analytics/log/write/kystdatahuset/${fromDate}/${toDate}`
    )
      .then(function _handleResponse(res) {
        return res.data;
      })
      .catch(function _handleError(err) {
        console.error("Error logging analytics", err);
        return null;
      });
  }

  // Digitheme related posts
  static savePassline(geom: string, title: string) {
    return DataLoader.postDigiTheme(
      "https://kystinfo.no/WebServices/client/DataView.asmx/Save",
      {
        theme_uuid: DashboardConfig.passlinesDt.uuid,
        data: {
          visibility: true,
          geom_wkt: geom,
          name: title,
          geom_modified: true,
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          var json = res.body.d;
          if (json.success) {
            return json.data;
          } else {
            throw new Error(JSON.stringify(json));
          }
        }
      })
      .catch((err) => {
        let errObj = {
          success: false,
          msg: err.message,
        };
        return errObj;
      });
  }

  static deletePassline(passlineId: string) {
    return DataLoader.postDigiTheme(
      "https://kystinfo.no/WebServices/client/DataView.asmx/Delete",
      {
        theme_uuid: "cc3a9a5c-bcf9-493c-8284-19ce65ced62e",
        id: +passlineId,
      }
    )
      .then((res) => {
        if (res.ok) {
          var json = res.body.d;
          if (json.success) {
            return json;
          } else {
            throw new Error(JSON.stringify(json));
          }
        }
      })
      .catch((err) => {
        let errObj = {
          success: false,
          msg: err.message,
        };
        return errObj;
      });
  }

  static getLocations() {
    return DataLoader.getApi("/api/location/all").then(
      (res) => {
        if (res.success && Array.isArray(res.data)) {
          var locations = res.data
            .filter((r) => r.lat && r.lon)
            .map((r) =>
              GeoJsonUtils.feature(GeoJsonUtils.point([r.lon, r.lat]), r)
            );
          return locations;
        } else {
          // throw new Error('Query for locations did not return anything');
          console.error(
            "Call to location web service did not return any records"
          );
          return [];
        }
      },
      (err: Error) => {
        console.error(err);
      }
    );
  }

  static getPasslines() {
    return DataLoader.postDigiTheme(
      "https://kystinfo.no/WebServices/client/DataView.asmx/ReadAny",
      {
        request: {
          theme_uuid: DashboardConfig.passlinesDt.uuid,
          filter: null,
          columns: ["id", "name", "visibility"],
          srid: "4326",
          start: 0,
          limit: 0,
          extraParams: [],
        },
      }
    ).then((res) => {
      if (
        res.ok &&
        res.body &&
        res.body.d &&
        res.body.d.success === true &&
        Array.isArray(res.body.d.records)
      ) {
        return res.body.d.records.map((r) => {
          var olGeom = wktFormat.readGeometry(r.geom_wkt);
          var geojsonGeom = geoJSONFormat.writeGeometryObject(olGeom);
          return GeoJsonUtils.feature(geojsonGeom, {
            name: r.name,
            visibility: r.visibility,
            id: r.id,
          });
        });
      } else {
        console.debug("An error occurred loading passlines", res);
      }
    });
  }

  static getArticle(articleId) {
    return DataLoader.postDigiTheme(
      "https://kystinfo.no/WebServices/client/DataView.asmx/ReadAny",
      {
        request: {
          theme_uuid: "81cbf677-68e2-4f3a-83f1-60c1f53f259b",
          columns: ["id", "title", "content", "media"],
          only_owned: false,
        },
      }
    ).then((res) => {
      if (
        res.ok &&
        res.body &&
        res.body.d &&
        res.body.d.success === true &&
        Array.isArray(res.body.d.records)
      ) {
        return res.body.d.records.find((a) => +a.id === +articleId);
      } else {
        console.debug("An error occurred loading articles", res);
      }
    });
  }

  static getMunicipalities() {
    return DataLoader.getApi("/api/maps/kommune/wkt")
      .then((res) => {
        if (res?.success === true && Array.isArray(res.data)) {
          return res.data.map((r) => {
            var olGeom = wktFormat.readGeometry(r.wkt_geom);
            var geoJsonGeom = geoJSONFormat.writeGeometryObject(olGeom);
            return GeoJsonUtils.feature(geoJsonGeom, {
              id: r.id,
              name: r.name,
              komm: r.komm,
            });
          });
        } else {
          throw new Error(`Unexpected result: ${JSON.stringify(res.body)}`);
        }
      })
      .catch((err) => {
        console.error("Could not load municipalities without geom", err);
        return Promise.resolve([]);
      });
  }

  static getDashboards() {
    return this.postDigiTheme(
      "https://kystinfo.no/WebServices/client/DataView.asmx/ReadAny",
      {
        request: {
          theme_uuid: "c77aaef4-3c2f-4057-a5ef-269ad43aeee7",
          filter: {
            filterColumns: [{ name: "active", value: true }],
            sortColumns: [{ name: "title" }],
          },
          columns: ["id", "title", "uri"],
          srid: "4326",
          start: 0,
          limit: 0,
          extraParams: [],
        },
      }
    ).then((res) => {
      if (
        res.ok === true &&
        res.body &&
        res.body.d &&
        res.body.d.success === true
      ) {
        return res.body.d.records;
      }
    });
  }

  static getUserDetails() {
    return this.postDigiTheme(
      "https://kystinfo.no/WebServices/generic/Authentication.asmx/GetUserDetails",
      {}
    ).then((res) => {
      if (
        res.ok === true &&
        res.body &&
        res.body.d &&
        res.body.d.success === true
      ) {
        return res.body.d.data;
      }
    });
  }

  static postDigiTheme(url: string, params: SuperagentParams = {}) {
    const { gm_session_id } = getAuthState();

    const req = request
      .post(url)
      .timeout({
        response: 5000, // Allow 20 seconds for server response,
        deadline: 20000, // Allow 4 minutes for data download
      })
      .set("Content-Type", "application/json");

    if (gm_session_id) {
      req.set("gm_session_id", gm_session_id);
    }

    req.send(JSON.stringify(params));
    return req;
  }

  static post(
    url: string,
    params: SuperagentParams = {},
    reportProgress?: SuperagentProgressHandler,
    reportError?: ErrorRepoortHandler,
    customHeaders: { [key: string]: string } = {},
    omitAuthorization: boolean = false
  ) {
    const { gm_session_id, jwt } = getAuthState();

    var req = request
      .post(url)
      .timeout({
        response: 120000, // Allow 20 seconds for server response,
        deadline: 240000, // Allow 4 minutes for data download
      })
      .set("Content-Type", "application/json");

    if (!customHeaders.hasOwnProperty("gm_session_id") && gm_session_id) {
      req.set("gm_session_id", gm_session_id);
    }

    if (!omitAuthorization) {
      req.set("Authorization", `Bearer ${jwt}`);
    }

    Object.keys(customHeaders).forEach((k) => {
      req.set(k, customHeaders[k]);
    });
    return req
      .send(JSON.stringify(params))
      .on("progress", (event) => {
        this.timeStamp = event.timeStamp;
        this.loaded = event.loaded;
        this.total = event.total;

        if (typeof reportProgress === "function") {
          reportProgress({
            direction: event.direction,
            percent: Math.ceil(event.percent),
            total: event.total,
            loaded: event.loaded,
            timestamp: event.timeStamp,
          });
        }
      })
      .then((res) => {
        if (res.ok) {
          if (typeof reportProgress === "function") {
            reportProgress({
              direction: "completed",
              percent: 100,
              total: this.total,
              loaded: this.loaded,
              timestamp: this.timeStamp,
            });
          }
          if (res.body?.success || res.body?.d?.success || res.body?.type === "FeatureCollection") {
            return res.body;
          } else {
            throw new Error("Malformed response");
          }
        }
      })
      .catch((err) => {
        console.error(err);
        let errObj = {
          success: false,
          msg: err.message,
        };
        if (typeof reportError === "function") {
          reportError(errObj);
        }
        return errObj;
      });
  }

  static delete(
    url: string,
    params: SuperagentParams = {},
    reportProgress?: SuperagentProgressHandler,
    reportError?: ErrorRepoortHandler,
    customHeaders: { [key: string]: string } = {},
    omitAuthorization: boolean = false
  ) {
    const { gm_session_id, jwt } = getAuthState();

    var req = request
      .delete(url)
      .timeout({
        response: 120000, // Allow 20 seconds for server response,
        deadline: 240000, // Allow 4 minutes for data download
      })
      .set("Content-Type", "application/json");

    if (!customHeaders.hasOwnProperty("gm_session_id") && gm_session_id) {
      req.set("gm_session_id", gm_session_id);
    }

    if (!omitAuthorization) {
      req.set("Authorization", `Bearer ${jwt}`);
    }

    Object.keys(customHeaders).forEach((k) => {
      req.set(k, customHeaders[k]);
    });

    return req
      .send(JSON.stringify(params))
      .on("progress", (event) => {
        this.timeStamp = event.timeStamp;
        this.loaded = event.loaded;
        this.total = event.total;

        if (typeof reportProgress === "function") {
          reportProgress({
            direction: event.direction,
            percent: Math.ceil(event.percent),
            total: event.total,
            loaded: event.loaded,
            timestamp: event.timeStamp,
          });
        }
      })
      .then((res) => {
        if (res.ok) {
          if (typeof reportProgress === "function") {
            reportProgress({
              direction: "completed",
              percent: 100,
              total: this.total,
              loaded: this.loaded,
              timestamp: this.timeStamp,
            });
          }
          if (res.body?.success || res.body?.d?.success) {
            return res.body;
          }
          throw new Error("Malformed response");
        }
      })
      .catch((err) => {
        console.error(err);
        let errObj = {
          success: false,
          msg: err.message,
        };
        if (typeof reportError === "function") {
          reportError(errObj);
        }
        return errObj;
      });
  }

  static getApi<T = any>(
    url: string,
    params: SuperagentParams = {},
    reportProgress?: SuperagentProgressHandler,
    reportError?: ErrorRepoortHandler,
    auth: boolean = false
  ) {
    this.checkApiUrl();
    if (!url.startsWith(process.env.REACT_APP_DASHBOARD_WS_API!)) {
      url = process.env.REACT_APP_DASHBOARD_WS_API + url;
    }
    return DataLoader.get(
      url,
      params,
      reportProgress,
      reportError,
      auth
    ) as Promise<T>;
  }

  static getApiAuth(
    url: string,
    params: SuperagentParams = {},
    reportProgress?: SuperagentProgressHandler,
    reportError?: ErrorRepoortHandler
  ) {
    return DataLoader.getApi(url, params, reportProgress, reportError, true);
  }

  static get(
    url: string,
    params: SuperagentParams = {},
    reportProgress?: SuperagentProgressHandler,
    reportError?: ErrorRepoortHandler,
    auth: boolean = false
  ) {
    const { gm_session_id, jwt } = getAuthState();

    var req = request
      .get(url)
      .timeout({
        response: 30000, // Allow 20 seconds for server response,
        deadline: 240000, // Allow 4 minutes for data download
      })
      .set("Content-Type", "application/json");
    if (gm_session_id) {
      req.set("gm_session_id", gm_session_id);
    }

    if (auth) {
      req.set("Authorization", `Bearer ${jwt}`);
    }

    return req
      .query(params)
      .on("progress", (event) => {
        this.timeStamp = event.timeStamp;
        this.loaded = event.loaded;
        this.total = event.total;

        if (typeof reportProgress === "function") {
          reportProgress({
            direction: event.direction,
            percent: Math.ceil(event.percent),
            total: event.total,
            loaded: event.loaded,
            timestamp: event.timeStamp,
          });
        }
      })
      .then((res) => {
        if (res.ok) {
          if (typeof reportProgress === "function") {
            reportProgress({
              direction: "completed",
              percent: 100,
              total: this.total,
              loaded: this.loaded,
              timestamp: this.timeStamp,
            });
          }
          var json = res.body;
          if (json.success) {
            return json;
          } else {
            throw new Error(json?.msg);
          }
        }
      })
      .catch((err: Error) => {
        let errObj = {
          success: false,
          msg: err.message,
        };
        if (typeof reportError === "function") {
          reportError(errObj);
        }
        return errObj;
      });
  }

  static query_ssr(
    q: string,
    n: number = 20,
    reportProgress: SuperagentProgressHandler,
    reportError: ErrorRepoortHandler
  ) {
    var req = request
      .get("https://ws.geonorge.no/stedsnavn/v1/navn")
      .timeout({
        response: 10000, // Allow 20 seconds for server response,
        deadline: 20000, // Allow 4 minutes for data download
      })
      .set("Content-Type", "application/json");

    return req
      .query({
        sok: q,
        fuzzy: true,
        utkoordsys: 4326,
        treffPerSide: n,
        side: 1,
      })
      .on("progress", (event) => {
        this.timeStamp = event.timeStamp;
        this.loaded = event.loaded;
        this.total = event.total;

        if (typeof reportProgress === "function") {
          reportProgress({
            direction: event.direction,
            percent: Math.ceil(event.percent),
            total: event.total,
            loaded: event.loaded,
            timestamp: event.timeStamp,
          });
        }
      })
      .then((res) => {
        if (res.ok) {
          if (typeof reportProgress === "function") {
            reportProgress({
              direction: "completed",
              percent: 100,
              total: this.total,
              loaded: this.loaded,
              timestamp: this.timeStamp,
            });
          }
          var json = res.body;
          if (Array.isArray(json.navn) && json.navn.length > 0) {
            return json;
          } else {
            throw new Error(json?.msg);
          }
        }
      })
      .catch((err: Error) => {
        let errObj = {
          success: false,
          msg: err.message,
        };
        if (typeof reportError === "function") {
          reportError(errObj);
        }
        return errObj;
      });
  }

  static async createShortCode(shareData: ShareData) {
    return await DataLoader.postApi("/api/share/create-shortcode", shareData);
  }

  static async decodeShortCode(shortCode: string) {
    let res = await DataLoader.getApi<WebServiceResponse<ShareData>>(
      `/api/share/decode-shortcode/${shortCode}`
    );
    if (res.success && res.data.filter) {
      res.data.filter = JSON.parse(res.data.filter);
    }
    return res;
  }
}
