import "ol-layerswitcher/src/ol-layerswitcher.css";
import "./LayerSwitcherMixin.scss";

import { Map } from "ol";
import WMTSCapabilities from "ol/format/WMTSCapabilities.js";
import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import { WMTS } from "ol/source";
import OSM from "ol/source/OSM";
import TileArcGISRest from "ol/source/TileArcGISRest";
import TileWMS from "ol/source/TileWMS";
import { optionsFromCapabilities } from "ol/source/WMTS";
import { getLocal, setLocal } from "../../utils/localStorageCache";

export const LayerSwitcherMixin = async function (map: Map) {
  let baatGkt: string | null = getLocal("baatGkt", 5);
  if (!baatGkt) {
    const baatResponse = await fetch(
      "https://kystinfo.no/WebServices/generic/Baat.ashx"
    );
    const baatJson = await baatResponse.json();
    baatGkt = baatJson.token;
    setLocal("baatGkt", baatGkt);
  }

  if (!baatGkt)
    console.warn(
      "Could not connect to BAAT - Kartverkets authentication service. Some layers may be unavailable"
    );

  var baseUrl =
    "https://services.geodataonline.no/arcgis/rest/services/Geocache_UTM33_WGS84/";
  // var mapurl = baseUrl + "GeocacheBasis/MapServer/";
  // var graymapurl = baseUrl + "GeocacheGraatone/MapServer/";
  var imageurl = baseUrl + "GeocacheBilder/MapServer/";

  const parser = new WMTSCapabilities();

  async function createKartverketWMTSLayer(
    title: string,
    wmtsServiceUrl: string,
    baatGkt: string,
    layer: string,
    matrixSet: string = "webmercator"
  ) {
    const skrGetCapabilitiesRes = await fetch(
      `${wmtsServiceUrl}&gkt=${baatGkt}?Request=GetCapabilities`
    );
    const skrGetCapabilitiesResText = await skrGetCapabilitiesRes.text();
    const skrWmtsCapabilities = parser.read(skrGetCapabilitiesResText);
    const skrWmtsSourceOptions = optionsFromCapabilities(skrWmtsCapabilities, {
      layer, // The WMTS layer you want to use
      matrixSet, // The matrix set that fits your map projection
    });
    return new TileLayer({
      properties: {
        title: title,
        type: "base",
      },
      visible: false,
      source: new WMTS(skrWmtsSourceOptions!),
    });
  }

  var sjokartLayer = await createKartverketWMTSLayer(
    "Sjøkart",
    "https://cache.kartverket.no/sjokartraster/v1/wmts/1.0.0/",
    baatGkt!,
    "sjokartraster",
    "webmercator"
  );

  var topoGraatoneLayer = await createKartverketWMTSLayer(
    "Grunnkart (gråtone)",
    "https://cache.kartverket.no/topograatone/v1/wmts/1.0.0/",
    baatGkt!,
    "topograatone",
    "webmercator"
  );

  var topoLayer = await createKartverketWMTSLayer(
    "Grunnkart",
    "https://cache.kartverket.no/topo/v1/wmts/1.0.0/",
    baatGkt!,
    "topo",
    "webmercator"
  );

  var layers = [
    new LayerGroup({
      properties: {
        title: "Bakgrunnskart",
      },
      layers: [
        new TileLayer({
          properties: {
            title: "Globalt grunnkart",
            type: "base",
            permanent: true,
          },
          visible: true,
          source: new OSM(),
        }),
        // new TileLayer({
        //   properties: {
        //     title: "Grunnkart",
        //     type: "base",
        //   },
        //   visible: false,
        //   source: new TileArcGISRest({
        //     url: mapurl,
        //     params: {
        //       dpi: 200,
        //     },
        //     crossOrigin: "Anonymous",
        //   }),
        // }),
        topoLayer,
        topoGraatoneLayer,
        sjokartLayer,
        new TileLayer({
          properties: {
            title: "Bilde",
            type: "base",
          },
          visible: false,
          source: new TileArcGISRest({
            url: imageurl,
            crossOrigin: "Anonymous",
          }),
        }),
        // new TileLayer({
        //   properties: {
        //     title: "Gråtone",
        //     type: "base",
        //   },
        //   visible: false,
        //   source: new TileArcGISRest({
        //     url: graymapurl,
        //     crossOrigin: "Anonymous",
        //   }),
        // }),
        new TileLayer({
          properties: {
            title: "AIS tetthetsplott 250m (2017)",
          },
          visible: false,
          source: new TileWMS({
            urls: [
              "https://wms-geo.kystverket.no/density?version=1.3.0&request=GetCapabilities&service=WMS",
            ],
            params: {
              LAYERS: "AIS-density-Europe",
              QUERYABLE: false,
              TILED: true,
              TRANSITIONEFFECT: "resize",
              TRANSPARENT: true,
              SRS: "EPSG:3857",
            },
            hidpi: false,
            serverType: "geoserver",
            transition: 0,
          }),
        }),
        new TileLayer({
          properties: {
            title: "AIS tetthetsplott 50m (2017)",
          },
          visible: false,
          source: new TileWMS({
            urls: [
              "https://wms-geo.kystverket.no/density?version=1.3.0&request=GetCapabilities&service=WMS",
            ],
            params: {
              LAYERS: "AIS-density-Norway-2016",
              QUERYABLE: false,
              TILED: true,
              TRANSITIONEFFECT: "resize",
              TRANSPARENT: true,
              SRS: "EPSG:3857",
            },
            hidpi: false,
            serverType: "geoserver",
            transition: 0,
          }),
        }),
      ],
    }),
    new LayerGroup({
      properties: {
        title: "Temadata",
      },
      layers: [
        new TileLayer({
          properties: {
            title: "ISPS Havneanlegg",
          },
          visible: false,
          source: new TileWMS({
            urls: [
              "https://services.kystverket.no/wms.ashx?version=1.3.0&service=WMS",
            ],
            params: {
              LAYERS: "layer_420",
              QUERYABLE: false,
              TILED: true,
              TRANSITIONEFFECT: "resize",
              TRANSPARENT: true,
              SRS: "EPSG:3857",
            },
            hidpi: false,
            serverType: "geoserver",
            transition: 0,
            crossOrigin: "Anonymous",
          }),
        }),
        new TileLayer({
          properties: {
            title: "Fartsgrenser for næringsfartøy",
          },
          visible: false,
          source: new TileWMS({
            urls: [
              "https://services.kystverket.no/wms.ashx?version=1.3.0&service=WMS",
            ],
            params: {
              LAYERS: "layer_760,layer_761",
              QUERYABLE: false,
              TILED: true,
              TRANSITIONEFFECT: "resize",
              TRANSPARENT: true,
              SRS: "EPSG:3857",
            },
            hidpi: false,
            serverType: "geoserver",
            transition: 0,
            crossOrigin: "Anonymous",
          }),
        }),
        new TileLayer({
          properties: {
            title: "Fartsgrenser for fritidsfartøy",
          },
          visible: false,
          source: new TileWMS({
            urls: [
              "https://services.kystverket.no/wms.ashx?version=1.3.0&service=WMS",
            ],
            params: {
              LAYERS: "layer_759,layer_755",
              QUERYABLE: false,
              TILED: true,
              TRANSITIONEFFECT: "resize",
              TRANSPARENT: true,
              SRS: "EPSG:3857",
            },
            hidpi: false,
            serverType: "geoserver",
            transition: 0,
            crossOrigin: "Anonymous",
          }),
        }),
        new TileLayer({
          properties: {
            title: "Akvakulturanlegg",
          },
          visible: false,
          source: new TileWMS({
            urls: [
              "https://gis.fiskeridir.no/server/services/fiskeridirWMS_akva/MapServer/WMSServer?",
            ],
            params: {
              LAYERS: "akvakultur_lokaliteter",
              QUERYABLE: false,
              TILED: true,
              TRANSITIONEFFECT: "resize",
              TRANSPARENT: true,
              SRS: "EPSG:3857",
            },
            hidpi: false,
            serverType: "mapserver",
            transition: 0,
            crossOrigin: "Anonymous",
          }),
        }),
      ],
    }),
  ];

  map.getLayers().extend(layers);

  return map;
};
