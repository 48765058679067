export class ExecUtils {
  static debounce(that, func, wait, immediate) {
    var timeout;
    return () => {
      var context = that;
      var args = arguments;
      var later = () => {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  static debounceTwoArgs(that, func, wait, immediate) {
    var timeout;
    return (arg, arg2) => {
      var context = that;
      var later = () => {
        timeout = null;
        if (!immediate) func.apply(context, [arg, arg2]);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, [arg, arg2]);
    };
  }

  static debounceArgs(
    context: any,
    func: (...args: any[]) => any,
    wait: number,
    immediate: boolean = false
  ) {
    let timeout;
    return (...args: any[]) => {
      var later = () => {
        timeout = null;
        if (!immediate) func.apply(context, [...args]);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      if (callNow) {
        func.apply(context, [...args]);
      } else {
        timeout = setTimeout(later, wait);
      }
    };
  }
}
