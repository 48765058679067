import { useCallback, useMemo } from "react";

import AsyncSelect from "react-select/async";
import { useDataContext } from "../context/DataContext";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../types/DashboardFilter";
import WKT from "ol/format/WKT";
import GeoJSON from "ol/format/GeoJSON";

export type PasslineTypeaheadProps = {
  setFilter: SetDashboardFilterFunc;
  filter: DashboardFilter;
  name?: string;
  isMulti?: boolean;
  label?: string;
};

export function PasslineTypeahead({
  setFilter,
  filter,
  name = "geom",
  isMulti = false,
  label = "Velg en passeringslinje",
}: PasslineTypeaheadProps) {
  const { passlines } = useDataContext();

  const wktFmt = useMemo(() => new WKT(), []);
  const geoJSONFmt = useMemo(() => new GeoJSON(), []);

  const parseOptions = useMemo(() => {
    return [
      {
        label: "Passeringslinje",
        options: passlines.map((feature) => {
          return {
            value: wktFmt.writeGeometry(geoJSONFmt.readGeometry(feature.geometry)),
            label: feature.properties.name,
          };
        }),
      },
    ];
  }, [geoJSONFmt, passlines, wktFmt]);

  const loadOptions = useCallback(
    (queryTerm: string, setOptions: (options: any) => void) => {
      setOptions(parseOptions);
    },
    [parseOptions]
  );

  return (
    <div className="filter-control">
      {label && <label>{label}</label>}
      <AsyncSelect
        cacheOptions
        placeholder={"Navn på passeringslinje"}
        loadingMessage={(d) => "Søker"}
        loadOptions={loadOptions}
        defaultOptions
        value={filter[name]}
        isMulti={isMulti}
        onChange={(evt) => {
          setFilter({
            [name]: [evt.value],
          });
        }}
      />
    </div>
  );
}
