import * as d3 from 'd3';
import * as format from 'd3-format';

import {timeFormat, timeFormatDefaultLocale} from 'd3-time-format';

export class NumUtils {
  static defaultLocaleJson = {
    decimal: ',',
    thousands: '\xa0',
    grouping: [3],
    currency: ['', 'NOK'],
    dateTime: '%a %b %e %X %Y',
    date: '%d/%m/%Y',
    time: '%H:%M:%S',
    periods: [],
    days: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
    shortDays: ['Søn', 'Mån', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
    months: [
      'Januar',
      'Februar',
      'Mars',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Desember'
    ],
    shortMonths: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'Mai',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Des'
    ]
  };

  static multiFormat (date) {
    timeFormatDefaultLocale(NumUtils.defaultLocaleJson);

    var formatMillisecond = timeFormat('%S');
    var formatSecond = timeFormat(':%S');
    var formatMinute = timeFormat('%I:%M');
    var formatHour = timeFormat('%H');
    var formatDay = timeFormat('%a %d');
    var formatWeek = timeFormat('%b %d');
    var formatMonth = timeFormat('%B');
    var formatYear = timeFormat('%Y');

    return (d3.timeSecond(date) < date
      ? formatMillisecond
      : d3.timeMinute(date) < date
        ? formatSecond
        : d3.timeHour(date) < date
          ? formatMinute
          : d3.timeDay(date) < date
            ? formatHour
            : d3.timeMonth(date) < date
              ? d3.timeWeek(date) < date
                ? formatDay
                : formatWeek
              : d3.timeYear(date) < date
                ? formatMonth
                : formatYear)(date);
  }

  // Create a locale object for norwegian settings
  static noLocale = format.formatLocale(NumUtils.defaultLocaleJson);

  static decimal2 (n) {
    if (NumUtils.isNumber(n)) {
      return NumUtils.noLocale.format(',.2f')(n);
    } else {
      return '';
    }
  }

  static integer (n) {
    if (NumUtils.isNumber(n)) {
      return NumUtils.noLocale.format(',.0f')(n);
    } else {
      return '';
    }
  }

  static mkNo (s) {
    return s.replace(',', '\xa0').replace('.', ',');
  }
  static isNumber (n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  }

  static pad (n, l = 2, c = 0) {
    n = '' + n;
    while (n.length < l) {
      n = c + n;
    }
    return n;
  }
}
