import * as d3 from "d3";

import * as dc from "dc";
import { Map } from "ol";
import GeoJSONFormat, { GeoJSONFeature } from "ol/format/GeoJSON";
import Point from "ol/geom/Point";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Icon from "ol/style/Icon";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import { geoJSONFormat, wktFormat } from "../../utils/geomUtils";
import { ExecUtils } from "../ChartUtils/ExecUtils";
import { GeoJsonUtils } from "../GeoJsonUtils/GeoJsonUtils";
import { DcExtBase } from "./DcExtBase";

export type DcExtTrackMapChartProps = {
  map: Map;
  intersectGeom: string[]|GeoJSONFeature[];
  chartData: any;
  categoryProperty: string;
  colorScheme?: string[];
  colorScale?: (v?: any) => string | ((v: any) => string);
  maxFeatures?: number;
};

export class DcExtTrackMapChart {
  static defaultProps = {
    maxFeatures: 2500,
  };

  _init: any = false;

  _map: any = null;
  _geojson: any;
  _trackGeomLayer: any = null;
  _chartData: any = null;
  _intersectGeomLayer: any = null;
  _intersectGeom: string[]|GeoJSONFeature[] = [];
  _scale: any = null;
  _categoryProperty: any = null;
  _maxFeatures: any = null;
  _directionArrowsVisible: any = false;

  constructor(props: DcExtTrackMapChartProps) {
    const {
      map,
      intersectGeom,
      chartData,
      colorScale,
      colorScheme = d3.schemeCategory10,
      categoryProperty,
      maxFeatures,
    } = props;

    if (typeof colorScale === "function") {
      this._scale = colorScale;
    } else {
      this._scale = d3.scaleOrdinal(colorScheme);
    }

    this._map = map;
    this._intersectGeom = intersectGeom;
    this._chartData = chartData;
    this._categoryProperty = categoryProperty;
    this._maxFeatures = maxFeatures;

    this.getStyle = this.getStyle.bind(this);
    this.toggleDirectionArrows = this.toggleDirectionArrows.bind(this);
    DcExtBase(this);
    dc.registerChart(this as any as dc.BaseMixin<any>);
  }

  toggleDirectionArrows() {
    this._directionArrowsVisible = !this._directionArrowsVisible;
  }

  geojson(geojson) {
    if (geojson === undefined) {
      return this._geojson;
    } else {
      this._geojson = geojson;
      return this;
    }
  }

  scale(value: any) {
    return this._scale(value);
  }

  getStyle(feature, resolution) {
    var featureProps = feature.getProperties();
    var geometry = feature.getGeometry();

    var color;
    var category = !featureProps[this._categoryProperty]
      ? "Ukjent"
      : featureProps[this._categoryProperty];

    if (!category) {
      color = this.scale("");
    } else {
      color = this.scale(category);
    }

    var styles = [
      new Style({
        stroke: new Stroke({
          color: color,
          width: 2,
        }),
      }),
    ];

    if (this._directionArrowsVisible) {
      // Calculate number of points required to get one per N meters
      let numPoints = Math.ceil(geometry.getLength() / 1000);
      let increment = 1 / numPoints;

      for (var currentAt = 0; currentAt <= 1; currentAt += increment) {
        let start, end;
        if (currentAt < 1) {
          start = geometry.getCoordinateAt(currentAt);
          end = geometry.getCoordinateAt(currentAt + increment / 10);
        } else if (currentAt === 1) {
          start = geometry.getCoordinateAt(currentAt - increment / 10);
          end = geometry.getCoordinateAt(currentAt);
        }
        var dx = end[0] - start[0];
        var dy = end[1] - start[1];
        var rotation = Math.atan2(dy, dx);
        styles.push(
          new Style({
            geometry: new Point(currentAt > 0 ? end : start),
            image: new Icon({
              src: "images/arrow4.png",
              anchor: [0.5, 0.5],
              rotateWithView: true,
              rotation: -rotation,
            }),
          })
        );
      }
    }
    return styles;
  }

  draw = ExecUtils.debounce(this, this._draw, 500);

  _draw() {
    if (this._trackGeomLayer !== null) {
      this._map.removeLayer(this._trackGeomLayer);
    }

    if (this._intersectGeomLayer !== null) {
      this._map.removeLayer(this._intersectGeomLayer);
    }

    var intersectGeomFeature = this._intersectGeom.map((_iGeom) => {
      if (typeof _iGeom === "string") {
        return wktFormat.readFeature(_iGeom, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:3857",
        });
      } else if (
        typeof _iGeom === "object" &&
        "type" in _iGeom &&
        _iGeom.type === "Feature"
      ) {
        return geoJSONFormat.readFeature(_iGeom, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:3857",
        });
      } else {
        throw new Error("Geometry format not supported");
      }
    });

    var intersectGeomVectorSource = new VectorSource({
      features: intersectGeomFeature,
    });

    this._intersectGeomLayer = new VectorLayer({
      source: intersectGeomVectorSource,
      style: new Style({
        stroke: new Stroke({
          color: "red",
          width: 5,
        }),
      }),
    });

    var filteredGeomCollection = GeoJsonUtils.featureCollection(
      this._chartData
        .allFiltered()
        .slice(0, this._maxFeatures)
        .map(function (d) {
          const { geometry, ...properties } = d;
          return GeoJsonUtils.feature(geometry, properties);
        })
    );

    // Update geojson property of diagram
    this.geojson(filteredGeomCollection);
    var vectorSource = new VectorSource({
      features: new GeoJSONFormat().readFeatures(filteredGeomCollection, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      }),
    });

    this._trackGeomLayer = new VectorLayer({
      source: vectorSource,
      style: this.getStyle,
    });

    this._trackGeomLayer.setZIndex(1000);
    this._intersectGeomLayer.setZIndex(1100);
    this._map.addLayer(this._trackGeomLayer);
    this._map.addLayer(this._intersectGeomLayer);

    if (!this._init) {
      try {
        this._map
          .getView()
          .fit(intersectGeomVectorSource.getExtent(), this._map.getSize());
      } catch (ex) {
        this._map.getView().fit(vectorSource.getExtent(), this._map.getSize());
      }
      this._init = true;
    }
  }
}
