import { useEffect } from "react";
import { SelectionLayerName, useFilterContext } from "../FilterList/FilterList";

export type ShowLayerProps = {
  selectionLayer: SelectionLayerName;
};

export function ShowLayer({ selectionLayer }: ShowLayerProps) {
  const { passlinesLayer, locationsLayer } = useFilterContext();

  useEffect(() => {
    if (selectionLayer === "passlines") {
      locationsLayer.setVisible(false);
      passlinesLayer.setVisible(true);
    } else if (
      selectionLayer === "locations" ||
      selectionLayer === "locationIds"
    ) {
      passlinesLayer.setVisible(false);
      locationsLayer.setVisible(true);
    }
    return () => {};
  }, [selectionLayer, passlinesLayer, locationsLayer]);

  return null;
}
