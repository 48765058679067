import './Dropdown.scss';

import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

class Dropdown extends React.Component {
  render () {
    const {
      label,
      placeholder,
      items,
      onSelect,
      isLoading,
      searchable,
      loadingMessage,
      onClear,
      isOptionDisabled,
      value,
      isMulti
    } = this.props;

    var selectedValue;

    if (Array.isArray(value)) {
      selectedValue = value.map(v => items.find(i => i && v && i.value === v.value));
    } else {
      selectedValue = typeof value === 'object' ? items.find(v => v && value && ((v.value.id === value.id) || (v.value === value.value))) : items.find(v => v && value && v.value === value);
    }

    return (
      <div className='dropdown--container'>
        {label && <label>{label}</label>}
        <Select
          classNamePrefix='dropdown'
          placeholder={placeholder}
          options={items}
          onChange={(di, action) => {
            if (action.action === 'clear' && onClear) {
              onClear();
            } else {
              onSelect(di);
            }
          }}
          isClearable
          isMulti={isMulti}
          isLoading={isLoading}
          loadingMessagefunction={loadingMessage}
          isSearchable={searchable}
          isOptionDisabled={isOptionDisabled}
          value={selectedValue || null} // requires null to undefine itself, not undefined
          menuShouldScrollIntoView
        />
      </div>
    );
  }
}

Dropdown.propTypes = {
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  label: PropTypes.string,
  searchable: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  value: PropTypes.any,
  isMulti: PropTypes.bool
};

Dropdown.defaultProps = {
  isMulti: false
};

export default Dropdown;
