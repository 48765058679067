import "./DbrdVoyagesAggDeparturesDay.scss";

import * as d3 from "d3";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { NumUtils } from "../../chart-components/ChartUtils/NumUtils";
import { TextUtils } from "../../chart-components/ChartUtils/TextUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { BarChartStacked } from "../../chart-components/Charts/BarChartStacked";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { NumberDisplayDim } from "../../chart-components/Charts/NumberDisplayDim";
import { PieChart } from "../../chart-components/Charts/PieChart";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { SeriesChart } from "../../chart-components/Charts/SeriesChart";
import { PivotTable } from "../../chart-components/ChartsExt/PivotTable";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIDataCountTileFlex } from "../../chart-components/Layout/AVIDataCountTileFlex";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { FilterToFromTime2 } from "../../filters/FilterToFromTime2";
import { FilterToFromYear } from "../../filters/FilterToFromYear";
import withRouter from "../../hocs/withRouter";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../types/DashboardFilter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";

class DbrdVoyagesAggDeparturesDayBase extends Component<any, any> {
  static dashboardRoute = "avganger-per-dag";

  static dashboardFilters(
    filter: DashboardFilter,
    setFilter: SetDashboardFilterFunc
  ) {
    return {
      helpMessage:
        "Velg et eller flere år og en tidsperiode som skal sammenlignes innen hvert av årene. Utvalget inkluderer begge valgte år og alle år mellom. Det hentes ut data for de valgte datoene i hvert av årene. Dette dashboardet gjør det mulig å vise data for inntil 36 måneder om gangen. Kan ta lang tid å laste/generere",
      controls: (
        <div key="flt_year_time_container">
          <FilterToFromYear
            key="flt_year"
            firstYear={2008}
            filter={filter}
            setFilter={setFilter}
          />
          <FilterToFromTime2
            key="flt_time"
            filter={filter}
            setFilter={setFilter}
            format="dd/MM"
          />
        </div>
      ),
    };
  }

  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: null,
    };
  }

  static dashboardValidation(filter) {
    if (filter.fromYear && filter.toYear && filter.fromTime && filter.toTime) {
      return true;
    } else {
      return false;
    }
  }

  static propTypes = {
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.reportProgress = this.reportProgress.bind(this);
    this.reportError = this.reportError.bind(this);
    this.state = {
      chartData: null,
      locationData: [],
    };
  }

  defaultParams: any = {
    fromYear: 2019,
    toYear: 2020,
    fromTime: new Date(2020, 2, 1),
    toTime: new Date(2020, 2, 25),
  };

  componentDidMount() {
    try {
      const { fromYear, toYear, fromTime, toTime } =
        this.props.location.state || this.defaultParams;

      if (fromYear && toYear && fromTime && toTime) {
        this.defaultParams.fromMonth = new Date(fromTime).getMonth() + 1;
        this.defaultParams.toMonth = new Date(toTime).getMonth() + 1;
        this.defaultParams.fromDay = new Date(fromTime).getDate();
        this.defaultParams.toDay = new Date(toTime).getDate();

        DataLoader.postApi(
          "/api/voyage/aggregated/departures-by-day",
          {
            FromYear: fromYear,
            ToYear: toYear,
            FromMonth: this.defaultParams.fromMonth,
            FromDay: this.defaultParams.fromDay,
            ToMonth: this.defaultParams.toMonth,
            ToDay: this.defaultParams.toDay,
          },
          this.reportProgress,
          this.reportError
        ).then((res) => {
          // Check that all responses are successful

          if (!res.success) {
            throw new Error("Error loading aggregated departure voyage data");
          }

          let idx = 1;
          let foundNull = false;
          // Clean data object, add index, remove nulls
          res.data.forEach((row) => {
            row.index = idx;
            for (let prop in row) {
              let defaultValue: any = "";
              switch (prop) {
                case "mh":
                case "ml":
                case "mb":
                case "md":
                case "mw":
                case "mt":
                  defaultValue = -99;
                  break;
                case "dlc":
                case "d0":
                case "d1":
                case "d2":
                case "alc":
                case "a0":
                case "a1":
                case "a2":
                  defaultValue = "(Ikke oppgitt)";
                  break;
                case "hm":
                  defaultValue = "u";
                  break;
                case "dwd":
                  defaultValue = row["awd"];
                  break;
                case "awd":
                  defaultValue = row["dwd"];
                  break;
                default:
                  defaultValue = "";
              }

              if (!row[prop]) {
                row[prop] = defaultValue;
              }

              if (
                (row[prop] === undefined || row[prop] === null) &&
                !foundNull
              ) {
                console.warn(
                  "Found null value for a record for property: " +
                    prop +
                    " at index: " +
                    idx
                );
                foundNull = true;
                return;
              }
            }
            idx++;
          });
          this.setState({
            chartData: crossfilter(res.data),
          });
        });
      } else {
        console.warn("No input parameters specified");
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  reportError(errorData) {
    this.setState({
      errorData: errorData,
    });
  }

  render() {
    const { chartData, progressData } = this.state;

    const { fromYear, toYear, fromMonth, fromDay, toMonth, toDay } =
      this.defaultParams;

    if (!chartData || chartData.size() === 0) {
      return <Loader progressData={progressData} chartData={chartData} />;
    }

    var yearRange: any[] = [];
    for (var currentYear = fromYear; currentYear <= toYear; currentYear++) {
      yearRange.push(currentYear);
    }

    var dimCount = chartData.dimension((d) => {
      return d.index;
    });

    var dimDepartureLocations = chartData.dimension((d) => d.dl);

    var dimLength = chartData.dimension((d) => d.ml);
    var dimBreadth = chartData.dimension((d) => d.mb);
    var dimHeight = chartData.dimension((d) => d.mh);
    var dimDraught = chartData.dimension((d) => d.md);
    var dimGrossTonange = chartData.dimension((d) => d.mt);

    var dimDepartureYear = chartData.dimension((d) => d.dy);
    var dimDepartureWeek = chartData.dimension((d) =>
      TimeUtils.getWeek(new Date(d.dy, d.dm - 1, d.dd))
    );

    var dimDepartureWeekDay = chartData.dimension((d) => d.dwd);
    var departuresByWeekday = dimDepartureWeekDay
      .group()
      .reduceSum((d) => d.vs);

    var dimArrivalCountry = chartData.dimension((d) => d.a0);

    var dimFlagState = chartData.dimension((d) => d.sf);
    var departuresByFlagState = dimFlagState.group().reduceSum((d) => d.vs);

    var dimDomesticInternational = chartData.dimension((d) => {
      if (d.a0 === "NORGE") {
        return "NORGE";
      } else if (d.a0 === "SVALBARD OG JAN MAYEN") {
        return "SVALBARD OG JAN MAYEN";
      } else if (d.a0 === "INTERNASJONALT FARVANN") {
        return "INTERNASJONALT FARVANN";
      } else {
        return "UTLAND";
      }
    });

    var departuresByDomesticInternational = dimDomesticInternational
      .group()
      .reduceSum((d) => d.vs);

    var dimDepartureCounty = chartData.dimension((d) => d.d1);
    var dimDepartureCountyYear = chartData.dimension((d) => [d.d1, d.dy]);
    var departuresByCountyYear = dimDepartureCountyYear
      .group()
      .reduceSum((d) => d.vs);

    // var dimDeparturesByDay = chartData.dimension((d) => [
    //   d.dy,
    //   new Date(toYear, d.dm - 1, d.dd),
    // ]);
    // var departuresByDay = dimDeparturesByDay.group().reduceSum((d) => d.vs);

    var dimDayInYear = chartData.dimension((d) => [
      d.dy,
      new Date(2020, d.dm - 1, d.dd),
    ]);
    var departuresByDayInYear = dimDayInYear.group().reduceSum((d) => d.vs);

    var dimDepartureMunicipality = chartData.dimension((d) => d.d2);
    var departuresByMunicipality = dimDepartureMunicipality
      .group()
      .reduceSum((d) => d.vs);

    var departuresByArrivalLocation = dimDepartureLocations
      .group()
      .reduceSum((d) => d.vs);
    var depaturesByArrivalCountry = dimArrivalCountry
      .group()
      .reduceSum((d) => d.vs);

    var dimLengthGroup = chartData.dimension((d) => d.lg);
    var departuresByLengthGroup = dimLengthGroup.group().reduceSum((d) => d.vs);

    var dimTonnageGroup = chartData.dimension((d) => d.tg);
    var departuresByTonnageGroup = dimTonnageGroup
      .group()
      .reduceSum((d) => d.vs);

    var dimHazMat = chartData.dimension((d) =>
      d.hm === true ? "Farlig last" : "Ordinær last"
    );
    var departuresByHazmat = dimHazMat.group().reduceSum((d) => d.vs);

    var dimShipCategory = chartData.dimension((d) => d.sg2);
    var departuresByShipCategory = dimShipCategory
      .group()
      .reduceSum((d) => d.vs);

    var dimShipType = chartData.dimension((d) => d.st);
    var departuresByShipType = dimShipType.group().reduceSum((d) => d.vs);

    var dimTable = chartData.dimension((d) => [
      d.dl,
      d.al,
      d.d2,
      d.d1,
      new Date(d.dy, d.dm - 1, d.dd),
      d.sg2,
      d.sc,
      d.mmsi,
      d.sn,
    ]);
    var departuresByTable = GroupUtils.RemoveEmptyBins(
      dimTable.group().reduceSum((d) => d.vs)
    );

    return (
      <div className="AppView-noMap">
        <AVIDashboard
          title={
            "Havneinformasjon: meldepliktige avganger fra SafeSeaNet per dag"
          }
          desc={`Aggregert statistikk over meldepliktige avganger fra lokasjoner i Norge per dag i tidsrommet ${fromDay}.${fromMonth} til ${toDay}.${toMonth} i årene fra ${fromYear} til og med ${toYear} som registrerte i SafeSeaNet. Omfatter ikke seilas som er untatt fra meldeplikt slik som innenriks rutegående bil- og persontransport.`}
          spacing={10}
          keyName="vs"
          type="sum"
          useFlex
          units="avganger"
          group={dimCount.groupAll()}
        >
          <AVIRow>
            <AVIDataCountTileFlex
              height={0.5}
              useFlex
              title="Nøkkeltall for avganger"
            >
              <NumberDisplayDim
                chartTitle="Antall lokasjoner"
                height={0.25}
                useFlex
                keyName="count"
                type="count"
                dimension={dimDepartureLocations}
                units="havner"
              />
              <NumberDisplayDim
                chartTitle="Største BT"
                height={0.25}
                useFlex
                keyName="mt"
                dimension={dimGrossTonange}
                units="tonn"
              />
              <NumberDisplayDim
                chartTitle="Største lengde"
                height={0.25}
                useFlex
                keyName="ml"
                dimension={dimLength}
                units="meter"
              />
              <NumberDisplayDim
                chartTitle="Største bredde"
                height={0.25}
                useFlex
                keyName="mb"
                dimension={dimBreadth}
                units="meter"
              />
              <NumberDisplayDim
                chartTitle="Største høyde"
                height={0.25}
                useFlex
                keyName="mh"
                dimension={dimHeight}
                units="meter"
              />
              <NumberDisplayDim
                chartTitle="Største dyptgående"
                height={0.25}
                useFlex
                keyName="md"
                dimension={dimDraught}
                units="meter"
              />
            </AVIDataCountTileFlex>
          </AVIRow>
          <AVIRow>
            <PivotTable
              chartTitle="Avganger per år etter fylke"
              height={2}
              useFlex
              group={departuresByCountyYear}
              dimension={dimDepartureCounty}
              rowLabel="Fylke"
              colLabel="År"
              rowKeyIndex={0}
              colKeyIndex={1}
              filterPrefix="Fylke"
            />
          </AVIRow>
          <AVIRow>
            <SeriesChart
              chartTitle="Avganger etter dag"
              dimension={dimDayInYear}
              group={departuresByDayInYear}
              useFlex
              height={2}
              brushOn
              yAxisLabel="Avganger"
              xAxisLabel="Måned og dag"
              filterPrefix="Tidsrom"
              rotateXAxisLabels={-45}
              xAxisPadding={1}
              x={d3.scaleTime()}
              title={(d) =>
                TimeUtils.formatDateInYear(d.key[1]) +
                "." +
                d.key[0] +
                ": " +
                d.value
              }
            />
          </AVIRow>
          <AVIRow>
            <RowChart
              chartTitle="Avganger etter skipskategori"
              useFlex
              height={1.5}
              group={departuresByShipCategory}
              dimension={dimShipCategory}
              filterPrefix="Skipskategori"
            />
            <RowChart
              chartTitle="Avganger etter skipstype (10 største)"
              description="Filtrer på skipskategori for å se de 10 største skipstypene innen kategorien"
              useFlex
              height={1.5}
              group={GroupUtils.RemoveEmptyBinsTopN(departuresByShipType, 10)}
              dimension={dimShipType}
              filterPrefix="Skipstype"
            />
          </AVIRow>
          <AVIRow>
            <BarChartStacked
              chartTitle="Avganger etter skipskategori og år"
              dimension={dimDepartureYear}
              xAxisTickValues={yearRange}
              useFlex
              height={1.5}
              gap={2}
              filterPrefix="Avgangsår"
              stackKey="sg2"
              valueKey="vs"
              renderLabel
            />
            <BarChartOrdinal
              group={departuresByDomesticInternational}
              dimension={dimDomesticInternational}
              chartTitle="Avganger til innland/utland"
              margins={{ left: 55, bottom: 100 }}
              useFlex
              height={1.5}
              ordering={(d) => -d.value}
              yAxisLabel="Antall avganger"
              xAxisLabel="Innland/utland"
              filterPrefix="Innland/utland"
              rotateXAxisLabels={-45}
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(
                departuresByMunicipality,
                40
              )}
              dimension={dimDepartureMunicipality}
              chartTitle="Avganger etter kommune (40 mest trafikkerte)"
              margins={{ left: 55, bottom: 100 }}
              useFlex
              height={1.5}
              ordering={(d) => -d.value}
              yAxisLabel="Antall avganger ankomster"
              xAxisLabel="Avgangskommune"
              filterPrefix="Avgangskommune"
              rotateXAxisLabels={-45}
            />
          </AVIRow>
          <AVIRow>
            <BarChartStacked
              chartTitle="Avganger etter skipskategori og måned"
              dimension={dimDepartureWeek}
              // xAxisTickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
              xAxisLabel="Uke i året"
              yAxisLabel="Avganger"
              useFlex
              height={1.5}
              gap={2}
              filterPrefix="Avgangsmåned"
              stackKey="sg2"
              valueKey="vs"
              renderLabel
            />
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(
                departuresByArrivalLocation,
                15
              )}
              dimension={dimDepartureLocations}
              chartTitle="Avganger etter avgangssted (15 mest trafikkerte)"
              margins={{ left: 55, bottom: 50 }}
              useFlex
              height={1.5}
              ordering={(d) => -d.value}
              yAxisLabel="Antall avganger"
              xAxisLabel="Avgagangssted"
              filterPrefix="Avgangssted"
              rotateXAxisLabels={-15}
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(
                depaturesByArrivalCountry,
                10
              )}
              dimension={dimArrivalCountry}
              chartTitle="Land neste lokasjon (40 største trafikkilder)"
              useFlex
              height={1.5}
              margins={{ left: 55, bottom: 50 }}
              ordering={(d) => -d.value}
              yAxisLabel="Antall avganger"
              xAxisLabel="Destinasjonsland"
              filterPrefix="Destinasjonsland"
              rotateXAxisLabels={-15}
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(departuresByFlagState, 40)}
              dimension={dimFlagState}
              chartTitle="Avganger etter flaggstat (40 største)"
              useFlex
              height={1.5}
              margins={{ left: 55, bottom: 50 }}
              ordering={(d) => -d.value}
              yAxisLabel="Antall avganger"
              xAxisLabel="Flaggstat"
              filterPrefix="Flaggstat"
              rotateXAxisLabels={-15}
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              chartTitle="Avganger med ordinær/farlig last"
              useFlex
              height={1.5}
              group={departuresByHazmat}
              dimension={dimHazMat}
              margins={{ left: 55, bottom: 50 }}
              ordering={(d) => -d.value}
              yAxisLabel="Antall avganger"
              xAxisLabel="Godstype"
              filterPrefix="Godstype"
              rotateXAxisLabels={-15}
            />
            <BarChartOrdinal
              chartTitle="Avganger etter ukedag"
              useFlex
              height={1.5}
              group={departuresByWeekday}
              dimension={dimDepartureWeekDay}
              margins={{ left: 55, bottom: 50 }}
              ordering={(d) => d.key}
              yAxisLabel="Antall avganger"
              xAxisLabel="Ukedag"
              filterPrefix="Ukedag"
              xAxisTickFormat={(d) => {
                return TimeUtils.getWeekDay(d);
              }}
            />
          </AVIRow>
          <AVIRow>
            <PieChart
              chartTitle="Avganger per lengdegruppe"
              useFlex
              height={2}
              margins={{ top: 20 }}
              innerRadius={50}
              dimension={dimLengthGroup}
              group={departuresByLengthGroup}
              minAngleForLabel={0.15}
              filterPrefix="Lengdegruppe"
              ordering={TextUtils.firstNumberInString}
            />
            <PieChart
              chartTitle="Avganger per bruttotonnasjegruppe (BT)"
              useFlex
              height={2}
              margins={{ top: 20 }}
              innerRadius={50}
              dimension={dimTonnageGroup}
              group={departuresByTonnageGroup}
              minAngleForLabel={0.15}
              filterPrefix="Bruttotonnasjegruppe"
              ordering={TextUtils.firstNumberInString}
            />
          </AVIRow>
          <AVIRow>
            <DataTable
              chartTitle="Liste over avganger"
              dimension={departuresByTable}
              sortBy={(d) =>
                `${d.key[0]}-${NumUtils.pad(d.key[3], 4)}-${NumUtils.pad(
                  d.key[4],
                  2
                )}`
              }
              order={d3.ascending}
              useFlex
              size={Infinity}
              showSortControls={false}
              columns={[
                {
                  label: "Dato",
                  title: "Ankomstår",
                  format: (d) => TimeUtils.formatDate(d.key[4]),
                },
                {
                  label: "Lokasjonsnavn",
                  title: "Lokasjonsnavn",
                  format: (d) => d.key[0],
                },
                {
                  label: "Kommune",
                  title: "Navn på kommune",
                  format: (d) => d.key[2],
                },
                {
                  label: "Fylke",
                  title: "Navn på fylke",
                  format: (d) => d.key[3],
                },
                {
                  label: "Destinasjon",
                  title: "Destinasjon (lokasjonsnavn)",
                  format: (d) => d.key[1],
                },
                {
                  label: "Skipskategori",
                  title: "Skipskategori",
                  format: (d) => d.key[6],
                },
                {
                  label: "Skipstype",
                  title: "Skipstype (Statcode5)",
                  format: (d) => d.key[5],
                },
                {
                  label: "Skipsnavn",
                  title: "MMSI / Skipsnavn",
                  format: (d) => d.key[7] + "-" + d.key[8],
                },
                {
                  label: "Avganger",
                  title: "Antall avganger",
                  format: (d) => NumUtils.integer(d.value),
                  value: (d) => d.value,
                },
              ]}
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdVoyagesAggDeparturesDay = withRouter(
  DbrdVoyagesAggDeparturesDayBase
);

export default DbrdVoyagesAggDeparturesDay;
