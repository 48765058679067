import * as d3 from 'd3';

import Circle from 'ol/style/Circle';
import { DcExtBase } from './DcExtBase';
import Fill from 'ol/style/Fill';
import GeoJSONFormat from 'ol/format/GeoJSON';
import { GeoJsonUtils } from '../GeoJsonUtils/GeoJsonUtils';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import * as dc from 'dc';

export class DcExtLocationMapChart {
  _map = null;
  _vectorLayer = null;
  _vectorLayerHighlight = null;
  _geoJson;
  _geoJsonHighlight;
  _minSize = 3;
  _maxSize = 20;
  _highlightSize = 5;
  _min = null;
  _max = null;
  _scale = null;
  _highlightScale = null;
  _first = true;
  _geoJsonKey = null;
  _geoJsonHighlightKey = null;

  constructor (props) {
    const { map, geoJsonKey, geoJsonHighlightKey } = props;

    if (map === undefined) {
      throw new Error('Mandatory property map missing');
    }

    this._geoJsonKey = geoJsonKey || null;
    this._geoJsonHighlightKey = geoJsonHighlightKey || null;

    this._map = map;

    this.getStyle = this.getStyle.bind(this);
    this.getStyleHighlight = this.getStyleHighlight.bind(this);

    DcExtBase(this);

    dc.registerChart(this);
  }

  geoJson (geoJson) {
    if (geoJson === undefined) {
      return this._geoJson;
    } else {
      this._geoJson = geoJson;
      return this;
    }
  }

  geoJsonHighlight (geoJsonHighlight) {
    if (geoJsonHighlight === undefined) {
      return this._geoJsonHighlight;
    } else {
      this._geoJsonHighlight = geoJsonHighlight;
      return this;
    }
  }

  scale (value) {
    if (typeof this._scale === 'function') {
      return this._scale(value);
    } else {
      return value;
    }
  }

  getStyle (feature, resolution) {
    var props = feature.getProperties();
    var size = this.scale(props.value);
    return new Style({
      image: new Circle({
        radius: size,
        fill: new Fill({ color: 'rgba(255, 51, 71, 0.75)' }),
        stroke: new Stroke({ color: 'rgba(255, 51, 71, 1)', width: 1 })
      })
    });
  }

  getStyleHighlight (feature, resolution) {
    var props = feature.getProperties();
    var size = props.hasOwnProperty('_size') ? this.scale(props._size) : this._highlightSize;
    return new Style({
      image: new Circle({
        radius: size,
        fill: new Fill({ color: 'rgba(51, 51, 255, 0.5' }),
        stroke: new Stroke({ color: 'rgba(51, 51, 2 55, 1', width: 1 })
      })
    });
  }

  draw () {
    if (this._vectorLayer !== null) {
      this._map.removeLayer(this._vectorLayer);
    }
    if (this._vectorLayerHighlight !== null) {
      this._map.removeLayer(this._vectorLayerHighlight);
    }

    if (!Array.isArray(this._geoJson.features)) {
      return;
    }

    var filteredFeatureIds = [];
    this._group.all().forEach(d => {
      if (d.value > 0) {
        filteredFeatureIds[d.key] = d.value;
      }
      if (this._min === null || d.value < this._min) {
        this._min = d.value;
      }
      if (this._max === null || d.value > this._max) {
        this._max = d.value;
      }
    });

    this._scale = d3
      .scaleLinear()
      .domain([this._min, this._max])
      .range([this._minSize, this._maxSize]);

    this._highlightScale = d3
      .scaleLinear()
      .domain([this._min, this._max])
      .range([this._minSize, this._maxSize]);

    var perLocation = {};

    if (this._geoJsonKey && this._geoJsonHighlightKey) {
      this.geoJson().features.forEach(f => {
        perLocation[f.properties.arrivallocationid] = 0;
      });
    }
    var that = this;
    var filteredFeatures = this.geoJson().features.filter(function (feature) {
      if (filteredFeatureIds[feature.id] !== undefined) {
        feature.properties.value = filteredFeatureIds[feature.id];
        if (that._geoJsonKey && that._geoJsonHighlightKey) {
          perLocation[feature[that._geoJsonKey]] += 1;
        }
        return true;
      } else {
        return false;
      }
    });

    var filteredFeatureCollection = GeoJsonUtils.featureCollection(filteredFeatures);

    var vectorSource = new VectorSource({
      features: new GeoJSONFormat().readFeatures(filteredFeatureCollection, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      })
    });

    var vectorSourceHighlight = new VectorSource({
      features: new GeoJSONFormat().readFeatures(this.geoJsonHighlight(), {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      })
    });

    this._vectorLayer = new VectorLayer({
      source: vectorSource,
      style: this.getStyle
    });

    this._vectorLayerHighlight = new VectorLayer({
      source: vectorSourceHighlight,
      style: this.getStyleHighlight
    });

    this._vectorLayer.setZIndex(10000);
    this._vectorLayerHighlight.setZIndex(10010);
    this._map.addLayer(this._vectorLayerHighlight);
    this._map.addLayer(this._vectorLayer);
    if (this.first === true) {
      this._map.getView().fit(vectorSource.getExtent(), this._map.getSize());
      this.false = false;
    }
  }
}
