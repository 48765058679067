import { Map } from "ol";
import { Interaction } from "ol/interaction";

/**
 * Remove all kyv-dvh interactions from map
 * @param map 
 */
export function removeKyvDvhInteractions(map: Map) {
  map
    .getInteractions()
    .getArray()
    .filter(
      (interaction: Interaction) => interaction.get("category") === "kyv-dvh"
    )
    .forEach((interaction) => {
      map.removeInteraction(interaction);
    });
}

/**
 * Add kyv-dvh interaction to map
 * @param map 
 * @param interaction 
 */
export function addKyvDvhInteraction(map: Map, interaction: Interaction) {
  interaction.set("category", "kyv-dvh");
  map.addInteraction(interaction);
}
