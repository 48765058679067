import React from 'react';
import PropTypes from 'prop-types';
import './Icon.scss';

const icons = {
  triangle_up: (
    <svg viewBox='0 0 32 32'>
      <path d='M 4,26 16,6 28,26 Z' />
    </svg>
  ),
  triangle_down: (
    <svg viewBox='0 0 32 32'>
      <path d='M 28,6 16,26 4,6 Z' />
    </svg>
  ),
  cross: (
    <svg viewBox='0 0 16 16'>
      <path d='M13.957 3.457l-1.414-1.414-4.543 4.543-4.543-4.543-1.414 1.414 4.543 4.543-4.543 4.543 1.414 1.414 4.543-4.543 4.543 4.543 1.414-1.414-4.543-4.543z' />
    </svg>
  ),
  chevron_down: (
    <svg viewBox='0 0 24 24'>
      <path d='M18.7 8.3c-0.4-0.4-1-0.4-1.4 0l-5.3 5.3-5.3-5.3c-0.4-0.4-1-0.4-1.4 0s-0.4 1 0 1.4l6 6c0.2 0.2 0.5 0.3 0.7 0.3s0.5-0.1 0.7-0.3l6-6c0.4-0.4 0.4-1 0-1.4z' />
    </svg>
  ),
  chevron_up: (
    <svg viewBox='0 0 24 24'>
      <path d='M18.7 14.3l-6-6c-0.4-0.4-1-0.4-1.4 0l-6 6c-0.4 0.4-0.4 1 0 1.4s1 0.4 1.4 0l5.3-5.3 5.3 5.3c0.2 0.2 0.5 0.3 0.7 0.3s0.5-0.1 0.7-0.3c0.4-0.4 0.4-1 0-1.4z' />
    </svg>
  )
};

const Icon = props => {
  const { name } = props;
  return <span className='icon'>{icons[name]}</span>;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired
};

export default Icon;
