import 'react-datepicker/dist/react-datepicker.css';
import './Filters.scss';

import React, { Component } from 'react';

import Dropdown from '../filter-components/DropDown/Dropdown';
import { FilterCodelists } from '../filter-components/FilterCodelists';
import PropTypes from 'prop-types';

// import { TimeUtils } from './../chart-components/ChartUtils/TimeUtils';
// import nb from 'date-fns/locale/nb';

export class FilterToFromYearWeek extends Component {
  static propTypes = {
    setFilter: PropTypes.func.isRequired,
    filter: PropTypes.object
  };

  static defaultProps = {
    filter: {},
    minYear: 2010,
    maxYear: (new Date()).getFullYear(),
    format: 'dd/MM/yyyy HH:mm'
  };

  constructor (props) {
    super(props);
    this.state = {
      filter: props.filter
    };
    this.update = this.update.bind(this);
  }

  getFirstMondayOfWeek (year, weekNo) {
    var firstMonday = new Date(year, 0, 4, 0, 0, 0, 0);

    while (firstMonday.getDay() !== 1) {
      firstMonday.setDate(firstMonday.getDate() - 1);
    }
    if (weekNo >= 1 && weekNo <= 52) { return new Date(firstMonday.setDate(firstMonday.getDate() + 7 * (weekNo - 1))); }

    firstMonday.setDate(firstMonday.getDate() + 7 * (weekNo - 1));
    if (weekNo === 53 && firstMonday.getDate() >= 22 && firstMonday.getDate() <= 28) { return new Date(firstMonday); }
    return null;
  }

  getFirstMondayOfWeekAfter (year, weekNo) {
    var firstMonday = new Date(this.getFirstMondayOfWeek(year, weekNo));
    if (firstMonday) {
      var nextMonday = firstMonday.setDate(firstMonday.getDate() + 7);
      return new Date(nextMonday);
    } else {
      return null;
    }
  }

  update (key, val) {
    const { setFilter } = this.props;
    var { filter } = this.state;

    filter[key] = val;

    if (filter.fromYear && filter.fromYear > filter.toYear) {
      filter.toYear = filter.fromYear;
    }

    if (filter.fromWeek && filter.fromWeek > filter.toWeek) {
      filter.toWeek = filter.fromWeek;
    }

    if (filter.fromYear && filter.fromWeek && filter.toYear && filter.toWeek) {
      try {
        setFilter(filter);
      } catch (error) {
        console.error('Error', error);
      }
    }

    this.setState(filter);
  }

  render () {
    var { fromYear, toYear, fromWeek, toWeek } = this.state.filter;
    return (
      <div key='fromToWeek'>
        <Dropdown
          items={FilterCodelists.Years(2010)}
          key='fromYear'
          name='fromYear'
          label='Fra år'
          placeholder='Fra år'
          onSelect={(sel) => this.update('fromYear', sel.value)}
          onClear={() => this.update('fromYear', undefined)}
          value={fromYear}
          searchable={false}
        />
        <Dropdown
          items={FilterCodelists.Years(2010)}
          key='toYear'
          name='toYear'
          label='Til år'
          placeholder='Til år'
          onSelect={(sel) => this.update('toYear', sel.value)}
          onClear={() => this.update('toYear', undefined)}
          value={toYear}
          searchable={false}
        />
        <Dropdown
          items={FilterCodelists.Weeks()}
          key='fromWeek'
          name='fromWeek'
          label='Fra uke'
          placeholder='Fra uke'
          onSelect={(sel) => this.update('fromWeek', sel.value)}
          onClear={() => this.update('fromWeek', undefined)}
          value={fromWeek}
          searchable={false}
        />
        <Dropdown
          items={FilterCodelists.Weeks()}
          key='toWeek'
          name='toWeek'
          label='Til uke'
          placeholder='Til uke'
          onSelect={(sel) => this.update('toWeek', sel.value)}
          onClear={() => this.update('toWeek', undefined)}
          value={toWeek}
          searchable={false}
        />
      </div>
    );
  }
}
