import "./DbrdSailedDistanceGrunnlinje.scss";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { BarChartStacked } from "../../chart-components/Charts/BarChartStacked";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { FilterToFromMonth } from "../../filters/FilterToFromMonth";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";
import { County } from "../../utils/codelists";

class DbrdSailedDistanceGrunnlinjeBase extends Component<any, any> {
  /**
   * The route to be used for the dashboard
   */
  static dashboardRoute = "sailed-distance-grunnlinje";

  /**
   * The help text and filter controls to be used as initial filtering for the dashboard
   * @param {*} filter
   * @param {*} setFilter
   */
  static dashboardFilters(filter, setFilter) {
    return {
      helpMessage:
        "Dette dashboardet gjør det mulig å analysere flere år samtidig. Velg en eller flere kommuner og et tidsrom",
      controls: [
        <FilterToFromMonth
          key="flt-month"
          firstYear={2013}
          filter={filter}
          setFilter={setFilter}
        />,
      ],
    };
  }

  /**
   * The dashboard settings, i.e. map interaction filter controls, selectable layers etc.
   */
  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: null,
    };
  }

  /**
   * The dashboard validation function
   * @param {*} filter
   */
  static dashboardValidation(filter) {
    // console.debug(filter);
    if (filter.fromTime && filter.toTime) {
      return true;
    } else {
      return false;
    }
  }

  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        fromTime: PropTypes.any.isRequired,
        toTime: PropTypes.any.isRequired,
      }).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
    };
    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    const { fromTime, toTime } = this.props.location.state;
    try {
      if (fromTime && toTime) {
        DataLoader.postApi(
          "/api/ais/sailed-distance/grunnlinje",
          {
            StartDate: fromTime,
            EndDate: toTime,
          },
          this.reportProgress
        )
          .then((jsonResponse) => {
            var success = true;
            // console.log(jsonResponse);
            if (!jsonResponse.success) {
              if (success) {
                success = false;
              }
            }

            if (!success) {
              throw new Error(
                "Error loading sailed distance within grunnlinje"
              );
            }

            var data = jsonResponse.data;
            if (Array.isArray(data)) {
              data.forEach((row) => {
                row["year"] = new Date(row.month).getFullYear();
                return row;
              });
            }
            this.setState({
              fromTime,
              toTime,
              chartData: crossfilter(data),
              numRecords: data.length,
            });
          })
          .catch((error) => {
            console.warn(error);
            this.setState({
              chartData: crossfilter([]),
            });
          });
      } else {
        throw new Error("No data returned");
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const { chartData, fromTime, toTime, progressData } = this.state;

    if (!chartData || chartData.size() === 0) {
      return <Loader progressData={progressData} chartData={chartData} />;
    }

    var dimCount = chartData.dimension((d) => d.month);

    var dimSuperArea = chartData.dimension((d) => {
      let id = `${d.area_id}`.substring(0, 2);
      if (County.hasOwnProperty(id)) {
        return `${County[id]} (${id})`;
      } else {
        return id;
      }
    });

    var nmiBySuperArea = dimSuperArea
      .group()
      .reduceSum((d) => Math.round(d.nmi));

    var dimArea = chartData.dimension((d) => `${d.area_name} (${d.area_id})`);
    var nmiByArea = dimArea.group().reduceSum((d) => Math.round(d.nmi));

    var dimYear = chartData.dimension((d) => new Date(d.month).getFullYear());
    var nmiByYear = dimYear.group().reduceSum((d) => Math.round(d.nmi));
    var years = nmiByYear.all().map((d) => d.key);

    var dimMonth = chartData.dimension((d) => {
      var dt = new Date(d.month);
      var m = dt.getMonth() + 1;
      return m;
    });

    var nmiByMonth = dimMonth.group().reduceSum((d) => Math.round(d.nmi));
    var months = nmiByMonth.all().map((d) => d.key);

    var dimAisClass = chartData.dimension((d) => d.aisclass);
    var nmiByAisClass = dimAisClass.group().reduceSum((d) => Math.round(d.nmi));

    var dimShipCategory = chartData.dimension((d) => d.shipcategory);
    var nmiByShipCategory = dimShipCategory
      .group()
      .reduceSum((d) => Math.round(d.nmi));

    return (
      <div className="AppView">
        <AVIDashboard
          title="Utseilt distanse for kommuner"
          desc={`Utseilt distanse i nautiske mil (NM) i perioden fra ${TimeUtils.getYearMonth(
            fromTime
          )} til og med ${TimeUtils.getYearMonth(toTime, -1)}`}
          spacing={10}
          keyName="area_id"
          valueAccessor={(d) => d.count}
          group={dimCount.groupAll()}
          useFlex
          cfilter={chartData}
          filter={this.props.location.state}
        >
          <AVIRow>
            <BarChartStacked
              chartTitle="Utseilt distanse (NM) etter skipskategori og år"
              dimension={dimYear}
              xAxisTickValues={years}
              margins={{ top: 50 }}
              height={1.5}
              gap={2}
              filterPrefix="År"
              stackKey="shipcategory"
              valueKey="nmi"
              renderLabel
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartStacked
              chartTitle="Utseilt distanse (NM) etter år og måned"
              dimension={dimMonth}
              xAxisTickValues={months}
              margins={{ top: 50 }}
              height={1.5}
              gap={2}
              filterPrefix="År"
              stackKey="year"
              valueKey="nmi"
              renderLabel
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartOrdinal
                group={nmiByMonth}
                dimension={dimMonth}
                chartTitle="Utseilt distanse (NM) etter måned i året"
                yAxisLabel="Utseilt distanse"
                margins={{ left: 60 }}
                filterPrefix="Måned"
                ordering={(d) => d.key}
                rotateXAxisLabels={-45}
                useFlex
              />
            </AVICol>
            <AVICol>
              <RowChart
                chartTitle="Utseilt distanse (NM) etter AIS-klasse"
                group={nmiByAisClass}
                dimension={dimAisClass}
                gap={1}
                filterPrefix="AIS-klasse"
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <RowChart
              chartTitle="Utseilt distanse (NM) etter skipskategori"
              group={nmiByShipCategory}
              height={2}
              dimension={dimShipCategory}
              gap={1}
              filterPrefix="Skipskategori"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={nmiBySuperArea}
              dimension={dimSuperArea}
              chartTitle="Utseilt distanse etter fylke"
              yAxisLabel="Utseilt distanse (NM)"
              margins={{ left: 60, bottom: 30 }}
              filterPrefix="Kommune"
              rotateXAxisLabels={-25}
              ordering={(d) => d.key}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(nmiByArea, 20)}
              dimension={dimArea}
              chartTitle="Utseilt distanse etter kommune (20 mest trafikkerte)"
              useFlex
              margins={{ left: 60 }}
              yAxisLabel="Utseilt distanse (NM)"
              filterPrefix="Kommune"
              ordering={(d) => d.key}
              rotateXAxisLabels={-25}
            />
          </AVIRow>
          <AVIRow>
            <BarChartStacked
              chartTitle="Utseilt distanse (NM) etter lengdegruppe og år"
              dimension={dimYear}
              xAxisTickValues={years}
              margins={{ top: 50 }}
              height={1.5}
              gap={2}
              filterPrefix="År"
              stackKey="length_grp"
              valueKey="nmi"
              renderLabel
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartStacked
              chartTitle="Utseilt distanse (NM) etter bruttotonnasjegruppe og år"
              dimension={dimYear}
              xAxisTickValues={years}
              gap={2}
              margins={{ top: 50 }}
              height={1.5}
              filterPrefix="År"
              stackKey="gt_grp"
              valueKey="nmi"
              renderLabel
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartStacked
              chartTitle="Utseilt distanse (NM) etter dødvektstonnasje og år"
              dimension={dimYear}
              xAxisTickValues={years}
              margins={{ top: 50 }}
              height={1.5}
              gap={2}
              filterPrefix="År"
              stackKey="dwt_grp"
              valueKey="nmi"
              renderLabel
              useFlex
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdSailedDistanceGrunnlinje = withRouter(
  DbrdSailedDistanceGrunnlinjeBase
);

export default DbrdSailedDistanceGrunnlinje;
