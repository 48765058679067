import { createContext, useContext } from "react";

export type AVIDashboardDimContextType = {
  dims: {
    width: number;
    height: number;
    spacing: number;
  };
};

export const AVIDashboardDimContext = createContext<
  AVIDashboardDimContextType | undefined
>(undefined);

export const useAVIDashboardContext = function () {
  const context = useContext(AVIDashboardDimContext);
  if (!context)
    throw new Error(
      "AVIDashboardDimContext did not yield any value, useAVIDashboardContext can only be used witih a AVIDashboardDimContext.Provider element"
    );
  return context;
};
