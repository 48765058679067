import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AVICol } from "../chart-components/Layout/AVICol";
import { AVIRow } from "../chart-components/Layout/AVIRow";
import PropTypes from "prop-types";
import { TimeUtils } from "../chart-components/ChartUtils/TimeUtils";

export type DateWithoutYearProps = {
  name: string,
  label: string,
  value: any,
  onSelect: any,
  minDate?: Date,
  maxDate?: Date,
  baseYear?: number,
  utc?: boolean,
}

function DateWithoutYear({
  name,
  label,
  value,
  onSelect,
  minDate,
  maxDate,
  baseYear = 2020,
  utc = false,
}: DateWithoutYearProps) {
  const [selectedDate, setSelectedDate] = useState(
    value
      ? TimeUtils.ensureDateUtc(value)
      : TimeUtils.ensureDateUtc(new Date(baseYear, 0, 1))
  );

  const months = useMemo(() => {
    return TimeUtils.getMonthsDays(baseYear, minDate, maxDate);
  }, [baseYear, minDate, maxDate]);

  useEffect(() => {
    setSelectedDate(
      value
        ? TimeUtils.ensureDateUtc(value)
        : TimeUtils.ensureDateUtc(new Date(baseYear, 0, 1))
    );
  }, [value, baseYear]);

  const defaultDate = new Date(
    baseYear,
    minDate ? TimeUtils.ensureDateUtc(minDate).getDate() : 0,
    minDate ? TimeUtils.ensureDateUtc(minDate).getDate() : 1
  );

  // Select value
  const select = useCallback(
    (selectedMonth, selectedDay) => {
      var updatedDate = TimeUtils.ensureDateUtc(
        new Date(baseYear, selectedMonth, selectedDay)
      );
      setSelectedDate(updatedDate);
      onSelect(name, updatedDate);
    },
    [onSelect, setSelectedDate, baseYear, name]
  );

  const monthOptions = useMemo(() => {
    if (!selectedDate) return null;
    return Object.keys(months).map((currentMonth, i) => (
      <option key={`opt-month-${name}-${currentMonth}`} value={currentMonth}>
        {months[currentMonth].name}
      </option>
    ));
  }, [name, months, selectedDate]);

  const dateOptions = useMemo(() => {
    if (!selectedDate) return null;
    return months[selectedDate.getMonth()]?.dates.map((currentDate, i) => (
      <option
        key={`opt-day-${name}-${selectedDate.getDate()}-${currentDate}`}
        value={currentDate}
      >
        {currentDate}
      </option>
    ));
  }, [selectedDate, name, months]);

  if (!selectedDate) return null;

  return (
    <div className="filter-control-simple">
      <AVIRow>
        <label>{label}</label>
      </AVIRow>
      <AVIRow style={{ display: "flex", gap: 5 }}>
        <AVICol width={2}>
          <select
            style={{ padding: ".5em" }}
            onChange={(e) => select(+e.target.value, selectedDate.getDate())}
            value={selectedDate.getMonth()}
            required
          >
            {monthOptions}
          </select>
        </AVICol>
        <AVICol width={1}>
          <select
            style={{ padding: ".5em" }}
            onChange={(e) => select(selectedDate.getMonth(), +e.target.value)}
            value={selectedDate.getDate()}
            required
          >
            {dateOptions}
          </select>
        </AVICol>
        <input
          style={{
            width: "auto",
            lineHeight: "100%",
            fontWeight: "bold",
            color: "#c0c0c0",
            backgroundColor: "transparent",
            border: 0,
          }}
          onClick={(e) => {
            select(defaultDate.getMonth(), defaultDate.getDate());
          }}
          type="button"
          value="X"
        />
      </AVIRow>
    </div>
  );
}

DateWithoutYear.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Date),
  onSelect: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  baseYear: PropTypes.number,
  utc: PropTypes.bool,
};

export { DateWithoutYear };
export default DateWithoutYear;
