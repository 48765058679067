export class TextUtils {
  /**
   * Shorten the string s to n characters
   *
   * @param {string} s String to shorten
   * @param {number} n Max number of characters
   */
  static shorten (s, n, ellipsis = '…') {
    if (typeof s !== 'string') s = '' + s;
    if (s.length <= n) return s;
    return `${s.slice(0, n)}${ellipsis}`;
  }

  /**
   * Extract the first number from a string containing numbers or ranges of numbers
   *
   * @param {string} s String to extract number from
   */
  static firstNumberInString (s) {
    var str = s.key.replace('-', ' ');
    str = str.replace('Under', '-Infinity');
    str = str.replace('Opp til', -1000000000);
    str = str.replace('Mindre enn', -1000000000);
    str = str.replace('Over', 1000000000);
    str = str.replace('Mer enn', 1000000000);
    var pts = str.split(' ');
    var key = Infinity;
    for (var i = 0; i < pts.length; i++) {
      if (!isNaN(+pts[i])) {
        return +pts[i];
      }
    }
    return key;
  }
}
