import "./DbrdArrivals.scss";

import * as d3 from "d3";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartLinear } from "../../chart-components/Charts/BarChartLinear";
import { BarChartMonth } from "../../chart-components/Charts/BarChartMonth";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { LocationMapChart } from "../../chart-components/ChartsExt/LocationMapChart";
import { GeoJsonUtils } from "../../chart-components/GeoJsonUtils/GeoJsonUtils";
import GroupedNumberDisplayMax from "../../chart-components/GroupedNumberDisplayMax";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { FilterToFromYear } from "../../filters/FilterToFromYear";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader, SuperagentProgress } from "../../ui-components/Loader/Loader";
import { NoData } from "../../ui-components/NoData/NoData";
import { SelectLocationsInMap } from "../../ui-components/filter-view/FilterMap/SelectLocationsInMap";
import { NumUtils } from "../../chart-components/ChartUtils/NumUtils";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { CrossfilterRecord } from "../../custom";
import { Exception } from "dc";

export type DbrdArrivalsProps = {
  location: any;
};

export type DbrdArrivalsState = {
  chartData?: any;
  geoJsonData?: any;
  locationInfo?: any;
  fromYear?: number;
  toYear?: number;
  numRecords?: number;
  geoJsonArrivals?: any;
  geoJsonOrigins?: any;
  progressData?: SuperagentProgress;
};

class DbrdArrivalsBase extends Component<DbrdArrivalsProps, DbrdArrivalsState> {
  /**
   * The route to be used for the dashboard
   */
  static dashboardRoute = "ankomster";

  /**
   * The help text and filter controls to be used as initial filtering for the dashboard
   * @param {*} filter
   * @param {*} setFilter
   */
  static dashboardFilters(filter, setFilter) {
    return {
      helpMessage:
        "Dette dashboardet gjør det mulig å analysere flere år samtidig. Velg et eller flere ankomststeder og f.eks. tidsrommet 2015-2018.",
      controls: [
        <FilterToFromYear
          key="flt"
          firstYear={2008}
          filter={filter}
          setFilter={setFilter}
        />,
        <SelectLocationsInMap
          key="sel-loc"
          name="locationIds"
          returnProperty="id"
        />,
      ],
    };
  }

  /**
   * The dashboard settings, i.e. map interaction filter controls, selectable layers etc.
   */
  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayers: null,
    };
  }

  /**
   * The dashboard validation function
   * @param {*} filter
   */
  static dashboardValidation(filter) {
    if (
      filter.fromYear &&
      filter.toYear &&
      Array.isArray(filter.locationIds) &&
      filter.locationIds.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        toYear: PropTypes.number.isRequired,
        fromYear: PropTypes.number.isRequired,
        locationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      }),
    }),
  };

  dims: any[] = [];

  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
      geoJsonData: null,
      locationInfo: null,
    };
    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    const { toYear, fromYear, locationIds } = this.props.location.state;
    try {
      if (Array.isArray(locationIds) && toYear && fromYear) {
        var mergeRequests = [
          DataLoader.postApi(
            "/api/voyage/arrivals/for-locations",
            {
              FromYear: fromYear,
              ToYear: toYear,
              LocationIds: locationIds,
            },
            this.reportProgress
          ),
          DataLoader.postApi("/api/location/for-locations", {
            LocationIds: locationIds,
          }),
          DataLoader.postApi("/api/location/connections/for-locations", {
            LocationIds: locationIds,
          }),
        ];
        Promise.all(mergeRequests).then((jsonResponses) => {
          // Check that all responses are successful
          var success = true;

          jsonResponses.forEach((r) => {
            if (!r.success) {
              if (success) {
                success = false;
              }
            }
          });

          if (jsonResponses.some((r) => !r.success)) {
            throw new Exception("One or more requests failed to load");
          }

          var voyages = jsonResponses[0].data;
          var locationData = jsonResponses[1].data;
          var connectedLocations = jsonResponses[2].data;

          var arrivalLocMap: any[] = [];
          locationData.forEach((d) => {
            arrivalLocMap[d.locationid] = d;
          });

          voyages.forEach((d) => {
            d.departurelocationid = +d.departurelocationid;
            d.eta = d.eta != null ? new Date(d.eta) : "";
            d.etd = d.etd != null ? new Date(d.etd) : "";
            d.etaday = d.eta != null ? d.eta.getDay() : "";
            d.etahours = d.eta != null ? d.eta.getHours() : "";
            d.arrivallocationname =
              arrivalLocMap[d.arrivallocationid].locationnamenor;
            d.etastr = d.eta != null ? TimeUtils.toCompactDate(d.eta) : "";
          });

          this.setState({
            fromYear: fromYear,
            toYear: toYear,
            chartData: crossfilter(voyages),
            numRecords: voyages.length,
            locationInfo: locationData,
            geoJsonArrivals: connectedLocations,
            geoJsonOrigins: GeoJsonUtils.locationInfoToGeoJson(locationData),
          });
        });
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  componentWillUnmount(): void {
    this.dims.forEach((dim: any) => {
      if (typeof dim.dispose === "function") {
        dim.dispose();
      }
    });
  }

  reportProgress(progressData: SuperagentProgress) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const {
      chartData,
      geoJsonArrivals,
      geoJsonOrigins,
      locationInfo,
      fromYear,
      toYear,
      progressData,
      numRecords,
    } = this.state;

    if (!chartData) {
      return <Loader progressData={progressData} chartData={chartData} />;
    }

    if (numRecords === 0) {
      return <NoData />;
    }

    var dimCount = chartData.dimension((d) => (d.year ? d.year : -1));

    var dimDraught = chartData.dimension((d) => (d.draught ? d.draught : -1));
    var maxDraught = dimDraught.groupAll();

    var dimLength = chartData.dimension((d) => (d.length ? d.length : -1));
    var maxLength = dimLength.groupAll();

    var dimBreadth = chartData.dimension((d) => (d.breadth ? d.breadth : -1));
    var maxBreadth = dimBreadth.groupAll();

    var dimHeight = chartData.dimension((d) => (d.height ? d.height : -1));
    var maxHeight = dimHeight.groupAll();

    var dimYear = chartData.dimension((d) => {
      return d.eta !== null ? d3.timeYear(d.eta).getFullYear() : "";
    });
    var arrivalsByYear = dimYear.group().reduceCount();

    var dimMonth = chartData.dimension((d) => {
      return d.eta !== null ? d3.timeMonth(d.eta) : "";
    });
    var arrivalsByMonth = dimMonth.group().reduceCount();
    var arrivalsByMonthFiltered = GroupUtils.RemoveEmptyBins(arrivalsByMonth);

    var dimHourOfDay = chartData.dimension(
      (d: CrossfilterRecord) => d.etahours
    );
    var arrivalsByHourOfDay = dimHourOfDay.group().reduceCount();

    var dimArrivalLocations = chartData.dimension((d: CrossfilterRecord) =>
      d.arrivallocationname ? d.arrivallocationname : "Ukjent"
    );
    var arrivalsByLocation = dimArrivalLocations.group();

    var dimDepartureLocationId = chartData.dimension((d: CrossfilterRecord) =>
      d.departurelocationid ? d.departurelocationid : "Ukjent"
    );
    var departuresByLocationId = dimDepartureLocationId.group();

    var dimDepartureLocationName = chartData.dimension((d: CrossfilterRecord) =>
      d.locationnamenor ? d.locationnamenor : "Ukjent"
    );
    var arrivalsByLocationName = dimDepartureLocationName.group();

    var dimWeekDay = chartData.dimension((d: CrossfilterRecord) =>
      d.eta ? TimeUtils.getLocalWeekDayAbb(d.eta) : ""
    );
    var arrivalsByWeekDay = dimWeekDay.group().reduceCount();

    var dimHasHazMat = chartData.dimension((d: CrossfilterRecord) =>
      d.hashazmat === "T" ? "Farlig last" : "Ordinær last"
    );
    var arrivalsByHazMat = dimHasHazMat.group().reduceCount();

    var dimShipCategory = chartData.dimension(
      (d: CrossfilterRecord) => d.sgroup2 || "Ukjent"
    );
    var arrivalsByShipCategory = dimShipCategory.group().reduceCount();

    var dimStatcode5 = chartData.dimension(
      (d: CrossfilterRecord) => d.shiptypenor || "Ukjent"
    );
    var arrivalsByStatcode5 = dimStatcode5.group().reduceCount();

    var dimDepartureCountry = chartData.dimension(
      (d: CrossfilterRecord) => d.countrynamenor || "Ukjent"
    );
    var arrivalsByDepartureCountry = dimDepartureCountry.group().reduceCount();

    var dimOriginGroups = chartData.dimension((d: CrossfilterRecord) => {
      switch (d.cc) {
        case "NO":
          return "Norge (NO)";
        case "SJ":
          return "Svalbard (SJ)";
        default:
          return "Utlandet";
      }
    });
    var arrivalsByOriginGroups = dimOriginGroups.group().reduceCount();

    var dimMMSI = chartData.dimension((d: CrossfilterRecord) => d.mmsino);

    this.dims.push(
      dimArrivalLocations,
      dimBreadth,
      dimCount,
      dimDepartureCountry,
      dimDepartureLocationId,
      dimDepartureLocationName,
      dimDraught,
      dimHasHazMat,
      dimHeight,
      dimHourOfDay,
      dimLength,
      dimMMSI,
      dimMonth,
      dimOriginGroups,
      dimShipCategory,
      dimStatcode5,
      dimWeekDay,
      dimYear
    );

    var dbrdTitle = locationInfo
      .map((d) => {
        return d.locationnamenor + " (" + d.locationtypenor + ")";
      })
      .join(" / ");

    return (
      <div className="AppView">
        <AVIDashboard
          title="Havneinformasjon: Ankomster"
          desc={
            "Ankomster til: " +
            dbrdTitle +
            " i tidsrommet " +
            fromYear +
            "-" +
            toYear
          }
          filter={this.props.location.state}
          spacing={10}
          keyName="mmsino"
          valueAccessor={(d: CrossfilterRecord) => d.count}
          group={dimCount.groupAll()}
          useFlex
          cfilter={chartData}
        >
          <AVIRow>
            <GroupedNumberDisplayMax
              chartTitle="Nøkkeltall for ankomster"
              shipsByDraft={maxDraught}
              maxLength={maxLength}
              maxHeight={maxHeight}
              maxBreadth={maxBreadth}
              height={0.5}
              width={4}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <LocationMapChart
              chartTitle="Ankomster fra "
              dimension={dimDepartureLocationId}
              locationInfo={locationInfo}
              group={departuresByLocationId}
              geoJson={geoJsonArrivals}
              geoJsonHighlight={geoJsonOrigins}
              width={locationInfo.length < 2 ? 4 : 2}
              height={2}
              geoJsonKey="locationid"
              geoJsonHighlightKey="arrivallocationid"
              useFlex
            />
            {locationInfo.length > 1 && (
              <BarChartOrdinal
                group={GroupUtils.RemoveEmptyBins(arrivalsByLocation)}
                dimension={dimArrivalLocations}
                chartTitle="Ankomster etter ankomststed"
                width={2}
                height={1.5}
                yAxisLabel="Ankomster"
                filterPrefix="Ankomststed"
                ordering={(d) => -d.value}
                useFlex
              />
            )}
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(arrivalsByLocationName, 30)}
              dimension={dimDepartureLocationName}
              chartTitle="Ankomster etter avgangssted (viser inntil de  30 mest trafikkerte)"
              width={4}
              height={1}
              ordering={(d) => -d.value}
              yAxisLabel="Avgangssted"
              filterPrefix="Avgangssted"
              rotateXAxisLabels={-15}
              useFlex
            />
            <BarChartOrdinal
              chartTitle="Ankomster etter avgangsland (viser inntil de 30 mest traffikerte)"
              group={GroupUtils.RemoveEmptyBinsTopN(
                arrivalsByDepartureCountry,
                30
              )}
              dimension={dimDepartureCountry}
              width={4}
              height={1}
              ordering={(d) => -d.value}
              yAxisLabel="Avgangsland"
              filterPrefix="Avgangsland"
              rotateXAxisLabels={-15}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={arrivalsByOriginGroups}
              dimension={dimOriginGroups}
              chartTitle="Ankomster innland/utland"
              width={1.3}
              height={1}
              ordering={(d) => -d.value}
              yAxisLabel="Innland/utland"
              filterPrefix="Innland/utland"
              rotateXAxisLabels={-15}
              useFlex
            />
            <BarChartOrdinal
              chartTitle="Ankomster med farlig last"
              width={1.3}
              height={1}
              dimension={dimHasHazMat}
              group={arrivalsByHazMat}
              margins={[20, 20, 40, 40]}
              yAxisLabel="Ankomster"
              xAxisLabel="Farlig last"
              filterPrefix="Farlig last"
              useFlex
            />
            <BarChartOrdinal
              chartTitle="Ankomster per år"
              width={1.3}
              height={1}
              dimension={dimYear}
              group={arrivalsByYear}
              margins={[20, 20, 40, 40]}
              yAxisLabel="Ankomster"
              xAxisLabel="År"
              filterPrefix="År"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartMonth
              group={arrivalsByMonthFiltered}
              dimension={dimMonth}
              chartTitle="Ankomster etter måned"
              width={1.3}
              height={1}
              yAxisLabel="Ankomster"
              xAxisLabel="Måned"
              filterPrefix="Tidsrom fra-til"
              useFlex
            />
            <BarChartOrdinal
              group={arrivalsByWeekDay}
              dimension={dimWeekDay}
              chartTitle="Ankomster etter ukedag"
              width={1.3}
              height={1}
              yAxisLabel="Ankomster"
              xAxisLabel="Ukedag"
              filterPrefix="Valgt(e) ukedag(er)"
              ordering={(d) => TimeUtils.localeSortWeekDayAbb(d.key)}
              useFlex
            />
            <BarChartLinear
              group={arrivalsByHourOfDay}
              dimension={dimHourOfDay}
              chartTitle="Ankomster etter time i døgnet"
              width={1.3}
              height={1}
              yAxisLabel="Ankomster"
              xAxisLabel="Time i døgnet"
              filterPrefix="Time i døgnet"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <RowChart
              chartTitle="Ankomster etter skipskategori"
              group={arrivalsByShipCategory}
              dimension={dimShipCategory}
              width={2}
              height={1.5}
              gap={1}
              filterPrefix="Skipskategori"
              useFlex
            />
            <RowChart
              chartTitle="Ankomster etter skipstype (viser de 15 største)"
              group={GroupUtils.RemoveEmptyBinsTopN(arrivalsByStatcode5, 15)}
              dimension={dimStatcode5}
              ordering={(d) => -d.value}
              width={2}
              height={1.5}
              yAxisLabel="Ankomster"
              filterPrefix="Skipstype (Statcode5)"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <DataTable
              chartTitle="Liste over ankomster"
              dimension={dimMMSI}
              sortBy={(d: CrossfilterRecord) => d.eta}
              width={4}
              size={Infinity}
              useFlex
              columns={[
                {
                  label: "Dato",
                  title: "Ankomstdato",
                  format: (d: CrossfilterRecord) => d.etastr,
                },
                {
                  label: "MMSI",
                  title: "Maritime Mobile Service Identifier",
                  format: (d: CrossfilterRecord) => d.mmsino,
                },
                {
                  label: "IMO",
                  title: "International Maritime Organization identifier",
                  format: (d: CrossfilterRecord) => d.imono,
                },
                {
                  label: "Skipsnavn",
                  title: "Skipsnavn",
                  format: (d: CrossfilterRecord) => d.shipname,
                },
                {
                  label: "Skipstype",
                  title: "Skipstype (Statcode5)",
                  format: (d: CrossfilterRecord) => d.shiptypenor,
                },
                {
                  label: "BT",
                  title: "Bruttotonnasje",
                  format: (d) => NumUtils.integer(d.grosstonnage),
                  value: (d: CrossfilterRecord) => d.grosstonnage,
                },
                {
                  label: "DWT",
                  title: "Dødvektstonnasje",
                  format: (d) => NumUtils.integer(d.dwt),
                  value: (d: CrossfilterRecord) => d.dwt,
                },
                {
                  label: "L",
                  title: "Lengde",
                  format: (d) => NumUtils.decimal2(d.length),
                  value: (d: CrossfilterRecord) => d.length,
                },
                {
                  label: "B",
                  title: "Bredde",
                  format: (d) => NumUtils.decimal2(d.breadth),
                  value: (d: CrossfilterRecord) => d.breadth,
                },
                {
                  label: "H",
                  title: "Høyde",
                  format: (d) => NumUtils.decimal2(d.height),
                  value: (d: CrossfilterRecord) => d.height,
                },
                {
                  label: "D",
                  title: "Dyptgående",
                  format: (d) => NumUtils.decimal2(d.draught),
                  value: (d: CrossfilterRecord) => d.draught,
                },
                {
                  label: "HazMat",
                  title: "Farlig gods",
                  format: (d) => (d.hashazmat === "T" ? "JA" : "NEI"),
                },
                {
                  label: "Fra",
                  title: "Ankomst fra (opprinnelsessted)",
                  format: (d) => `${d.locationnamenor} (${d.cc})`,
                },
                {
                  label: "ID",
                  format: (d) => "",
                  value: (d: CrossfilterRecord) => d.locationid,
                },
                {
                  label: "LC",
                  format: (d) => "",
                  value: (d: CrossfilterRecord) => d.locationcode,
                },
                {
                  label: "Til",
                  title: "Avgang til (ankomststed)",
                  format: (d) => `${d.ln2} (${d.cc2})`,
                },
                {
                  label: "ID",
                  format: (d) => "",
                  value: (d: CrossfilterRecord) => d.lid2,
                },
                {
                  label: "LC",
                  format: (d) => "",
                  value: (d: CrossfilterRecord) => d.lc2,
                },
              ]}
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdArrivals = withRouter(DbrdArrivalsBase);

export default DbrdArrivals;
