import { GeoJSONFeature } from "ol/format/GeoJSON";
import { GeoJsonUtils } from "../GeoJsonUtils/GeoJsonUtils";
import { AISStatMsg, AisMmsiPosMap, AisPosMsg, AisTimeSeries } from "./AisMsg";
import { TimeUtils } from "./TimeUtils";

export class PosUtils {
  /**
   * Calculates the bounding box for an array of arrays where the sub-array contains
   * indices for latitude and longitude
   *
   * @param {*} positions
   * @param {*} lonIdx
   * @param {*} latIdx
   */
  static getBbox(positions, lonIdx, latIdx) {
    var bbox = [null, null, null, null];

    if (positions.length > 0) {
      // Calculate bounding box
      positions.forEach((pos) => {
        if (bbox[0] === null || bbox[0] > pos[lonIdx]) {
          bbox[0] = pos[lonIdx];
        }
        if (bbox[2] === null || bbox[2] < pos[lonIdx]) {
          bbox[2] = pos[lonIdx];
        }
        if (bbox[1] === null || bbox[1] > pos[latIdx]) {
          bbox[1] = pos[latIdx];
        }
        if (bbox[3] === null || bbox[3] < pos[latIdx]) {
          bbox[3] = pos[latIdx];
        }
      });
    }
    return bbox;
  }

  /**
   * Converts from degrees to radians
   *
   * @param {*} degrees
   */
  static toRadians(degrees) {
    return (degrees * Math.PI) / 180;
  }

  /**
   * Converts from radians to degrees
   *
   * @param {*} radians
   */
  static toDegrees(radians) {
    return (radians * 180) / Math.PI;
  }

  /**
   * Calculates bearing from two sets of latitude/longitude pairs
   */
  static getBearing(startLat, startLng, destLat, destLng) {
    startLat = PosUtils.toRadians(startLat);
    startLng = PosUtils.toRadians(startLng);
    destLat = PosUtils.toRadians(destLat);
    destLng = PosUtils.toRadians(destLng);

    var y = Math.sin(destLng - startLng) * Math.cos(destLat);
    var x =
      Math.cos(startLat) * Math.sin(destLat) -
      Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    var brng = Math.atan2(y, x);
    brng = PosUtils.toDegrees(brng);
    return (brng + 360) % 360;
  }

  /**
   * Get accumulated positions a specific time offset in seconds
   *
   * @param {*} timeSeriesCollection
   * @param {*} start
   * @param {*} secondsFromStart
   */
  static getPositionsForOffset(
    timeSeriesCollection: AisTimeSeries<Date>[],
    start: Date,
    secondsFromStart: number
  ) {
    let currentTime: Date;

    if (secondsFromStart === 0) {
      currentTime = new Date(start.getTime());
    } else {
      currentTime = new Date(start.getTime() + secondsFromStart * 1000);
    }

    return PosUtils.getPositionsUpToTime(timeSeriesCollection, currentTime);
  }

  /**
   * Get accumulated positions up to a specific time
   *
   * @param {*} timeSeriesCollection
   * @param {*} currentTime
   */
  static getPositionsUpToTime(
    timeSeriesCollection: AisTimeSeries<Date>[],
    currentTime: Date
  ) {
    // Array to hold last position
    var positions: GeoJSONFeature[] = [];

    // Array to hold accumulated track
    var tracks: GeoJSONFeature[] = [];

    // Loop through time series
    timeSeriesCollection.forEach((ts) => {
      var lineStringCoords: number[][] = [];
      let firstPos: AisPosMsg<Date>;
      let secondPos: AisPosMsg<Date>;
      for (var i = 1; i < ts.positions.length; i++) {
        firstPos = ts.positions[i - 1];
        secondPos = ts.positions[i];

        if (currentTime >= firstPos[1]) {
          lineStringCoords.push([firstPos[2], firstPos[3]]);
        }

        if (currentTime >= firstPos[1] && currentTime < secondPos[1]) {
          var timeBetweenPos = secondPos[1].getTime() - firstPos[1].getTime();
          var timeSinceFirstPos = currentTime.getTime() - firstPos[1].getTime();
          var fraction = timeSinceFirstPos / timeBetweenPos;
          var deltaLon = secondPos[2] - firstPos[2];
          var deltaLat = secondPos[3] - firstPos[3];
          var newLon = firstPos[2] + deltaLon * fraction;
          var newLat = firstPos[3] + deltaLat * fraction;
          var bearing = PosUtils.getBearing(
            firstPos[3],
            firstPos[2],
            secondPos[3],
            secondPos[2]
          );

          positions.push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [newLon, newLat],
            },
            properties: {
              mmsi: firstPos[0],
              cog: firstPos[4],
              bearing: bearing,
            },
          });

          lineStringCoords.push([newLon, newLat]);
          break;
        }
      }
      if (lineStringCoords.length > 1) {
        tracks.push({
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: lineStringCoords,
          },
          properties: {
            mmsi: firstPos![0],
          },
        });
      }
    });

    return {
      positions: {
        type: "FeatureCollection",
        features: positions.slice(),
      },
      tracks: {
        type: "FeatureCollection",
        features: tracks.slice(),
      },
    };
  }

  static posDistance(pos1, pos2) {
    return Math.sqrt(
      Math.pow(Math.abs(pos2[2] - pos1[2]), 2) +
        Math.pow(Math.abs(pos2[3] - pos1[3]), 2)
    );
  }

  static sameMmsi(pos1, pos2) {
    return pos1 && pos2 && pos1[0] === pos2[0];
  }

  static laterDate(pos1, pos2) {
    return pos1 && pos2 && pos1[1] > pos2[1];
  }

  static sameLocation(pos1, pos2) {
    return pos1 && pos2 && pos1[2] !== pos2[2] && pos1[3] !== pos2[3];
  }

  static minApart(pos1, pos2) {
    return Math.abs(pos1[1] - pos2[1]) / 1000 / 60;
  }

  static lastPosition(positionIndex, numPositions) {
    return positionIndex === numPositions - 1;
  }

  /**
   * Create an array of time series for each unique ship
   *
   * @param {*} positions Array of position messages
   * @param {*} statinfo Array of static messages
   */
  static createTimeSeries(
    positions: AisPosMsg<string|Date>[],
    statinfo: AISStatMsg[],
    minDist: number = 5,
    maxDist: number = 5000,
    maxMinApart: number = 15
  ) {
    // An array with position series for each ship
    var timeSeriesCollection: AisTimeSeries<Date>[] = [];

    // Create temporary position object
    var mmsiPosMap: AisMmsiPosMap = {};

    // Create an array for each mmsi
    positions.forEach((pos) => {
      if (mmsiPosMap[pos[0]] === undefined) {
        mmsiPosMap[pos[0]] = [];
      }
      const d = TimeUtils.utcParseToScondNoTz(pos[1] as string);
      if (!d) throw new Error(`Cannot parse ${pos[1]} as utc date`);      
      pos[1] = d;
      mmsiPosMap[pos[0]].push(pos.slice() as AisPosMsg);
    });

    // Populate each mssi position array
    for (let mmsi in mmsiPosMap) {
      timeSeriesCollection.push({
        mmsi: +mmsi,
        positions: mmsiPosMap[mmsi].slice(0),
      } as AisTimeSeries<Date>);
    }

    // Clean position arrays based distance between positions
    timeSeriesCollection.forEach((ts) => {
      var rawPos = ts.positions;
      var cleanPos: AisPosMsg<Date>[] = [];
      rawPos.forEach((currPos, currPosIdx, rawPosArr) => {
        if (currPosIdx > 0) {
          var pos1 = rawPosArr[currPosIdx - 1];
          var pos2 = rawPosArr[currPosIdx];

          const dist = this.haversineDistance(
            pos1[2],
            pos1[3],
            pos2[2],
            pos2[3]
          );

          if (dist >= minDist && dist < maxDist) {
            cleanPos.push(currPos);
          }
        } else {
          cleanPos.push(currPos);
        }
      });
      ts.positions = cleanPos.slice(0);
    });

    // Split position arrays where too long time between observations
    let splitTracks: any = [];
    timeSeriesCollection.forEach((shipTrack) => {
      var sPos = shipTrack.positions;
      if (sPos.length >= 2) {
        var tmpPos: AisPosMsg[] = [];

        // For each position in the time series
        for (var pIdx = 1; pIdx < sPos.length; pIdx++) {
          // Get first pos
          var pos1 = sPos[pIdx - 1];

          // Get second pos
          var pos2 = sPos[pIdx];

          // If first point in series, keep in any case
          if (pIdx === 1 || tmpPos.length === 0) {
            tmpPos.push(pos1);
          } else if (pIdx === sPos.length - 1) {
            tmpPos.push(pos2);
          }

          // Split tracks and leave gap if positions are more than maxMinApart apart
          if (
            this.minApart(pos1, pos2) > maxMinApart ||
            pIdx === sPos.length - 1
          ) {
            let splitTrack: AisTimeSeries = {
              ...shipTrack,
              positions: [],
            };
            splitTrack.positions = tmpPos.slice(0);
            splitTracks.push(splitTrack);
            tmpPos.length = 0;
          } else {
            tmpPos.push(pos2);
          }
        }
      }
    });
    timeSeriesCollection = splitTracks;

    // Add ship info to series
    if (
      statinfo !== undefined &&
      Array.isArray(statinfo) &&
      statinfo.length > 0
    ) {
      timeSeriesCollection.forEach((timeSeries, idx, arr) => {
        const statInfoMatch = statinfo.find(
          (si) => si.mmsi === timeSeries.mmsi
        );
        if (statInfoMatch) {
          arr[idx] = Object.assign(timeSeries, statInfoMatch);
        }
      });
    }
    return timeSeriesCollection;
  }

  /**
   * Create GeoJSON features from array of positions
   * @param {*} positions
   */
  static createFeaturesFromPositions(positions: any[]) {
    var f: any = {
      features: [],
      mmsiIds: [],
    };

    var trackIndex = 0;

    if (positions.length >= 2) {
      for (var i = 1; i < positions.length - 1; i++) {
        // Select position and position -1
        var p1: any = positions[i - 1];
        if (i === 1) {
          f.mmsiIds.push(p1[0]);
        }
        var p2 = positions[i];

        if (p2 === undefined) {
          // For some reason, a position vas zero
          continue;
        }

        // If the latitude or longitude is the same, replace previous point
        try {
          while (p2[2] === p1[2] || p2[3] === p1[3]) {
            i++;
            p2 = positions[i];
          }
        } catch (error) {
          continue;
        }

        // If they have the same MMSI id, consider them a line
        if (p1[0] === p2[0]) {
          var timeDiff = p2[1] - p1[1];

          var dist = this.haversineDistance(p1[2], p1[3], p2[2], p2[3]);

          // Exclude lines where there is more than 30 minutes between position messages and
          // distance between two points is less than 5km.
          if (timeDiff < 180000 && dist < 2500) {
            // Build GeoJSON features
            var feature = GeoJsonUtils.feature();
            feature.geometry = GeoJsonUtils.lineString([
              [p1[2], p1[3]],
              [p2[2], p2[3]],
            ]);
            feature.properties.trackIndex = trackIndex;
            feature.properties.id = i;
            feature.properties.mmsi = p1[0];
            feature.properties.start = p1.timeFormat;
            feature.properties.end = p2.timeFormat;
            feature.properties.endt = p2[1];
            feature.properties.speed = p2[5];
            feature.properties.msg = p2[6];
            f.features.push(feature);
          } else {
            console.log(timeDiff, dist);
            // Increment track index if a segment is skipped
            trackIndex++;
          }
        } else {
          // Increment track index for each unique mmsi
          trackIndex++;
          f.mmsiIds.push(p1[0]);
        }
      }
    }
    return f;
  }

  /**
   * Calculates the distance between two points specified by their latitude and longitude using the Haversine formula.
   * @param {number} lon1 - Longitude of the first point in decimal degrees.
   * @param {number} lat1 - Latitude of the first point in decimal degrees.
   * @param {number} lon2 - Longitude of the second point in decimal degrees.
   * @param {number} lat2 - Latitude of the second point in decimal degrees.
   * @returns {number} - Distance between the two points in meters.
   */
  static haversineDistance(
    lon1: number,
    lat1: number,
    lon2: number,
    lat2: number
  ): number {
    const toRadians = (degrees: number): number => (degrees * Math.PI) / 180;

    const R = 6371000; // Radius of the Earth in meters
    const φ1 = toRadians(lat1);
    const φ2 = toRadians(lat2);
    const Δφ = toRadians(lat2 - lat1);
    const Δλ = toRadians(lon2 - lon1);

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;

    return distance;
  }
}
