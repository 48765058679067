import * as olProj from "ol/proj";
import { useCallback } from "react";
import AsyncSelect from "react-select/async";
import { ExecUtils } from "../../../chart-components/ChartUtils/ExecUtils";
import { useDataContext } from "../../../context/DataContext";
import { useMap } from "../../../context/MapProvider";
import { NsrLocation } from "../../../types/NsrLocation";
import { SsrNavn, SsrResponse } from "../../../types/Ssr";
import { DataLoader } from "../../DataLoader/DataLoader";
import "./SsrSearchBar.scss";

type SearchOption = {
  value: {
    name: string,
    nameType: string,
    lon: number,
    lat: number
  };
  label: JSX.Element;
};

export function SsrSearchBar() {
  const { map } = useMap();
  const { locations } = useDataContext();

  /**
   * Parse SSR results into options
   * @param {*} response
   * @returns
   */
  const parseSsrOptions = useCallback((response: SsrResponse): SearchOption[] => {
    let options: SearchOption[] = response.navn.map((navn: SsrNavn) => {
      return {
        value: {
          lat: navn.representasjonspunkt["nord"],
          lon: navn.representasjonspunkt["øst"],
          name: navn["skrivemåte"],
          nameType: navn.navneobjekttype
        },
        label: (
          <>
            <strong>{navn.skrivemåte}</strong>
            {" - "}
            <em>
              {navn.navneobjekttype}
              {" - "}({navn.kommuner[0].kommunenavn},{" "}
              {navn.fylker[0].fylkesnavn})
            </em>
          </>
        ),
      };
    });
    return options;
  }, []);

  const searchLocations = useCallback(
    (queryTerm: string) => {
      return new Promise((resolve, reject) => {
        var res = (locations as unknown as NsrLocation[])
          .filter(
            (loc) =>
              loc.properties.name
                .toLowerCase()
                .startsWith(queryTerm.toLowerCase())
          )
          .map((loc: NsrLocation) => {
            let opt : SearchOption = {
              label: <><strong>{loc.properties.name} </strong> - <em>Havn, kai, sted i sjø - (NSR lokasjon)</em></>,
              value: {
                name: loc.properties.name,
                nameType: "NewShipRep Lokasjon",
                lon: loc.properties.lon,
                lat: loc.properties.lat,
              }
            }
            return opt;
          });
        resolve(res);
      });
    },
    [locations]
  );


  /**
   * Search for names in SSR
   * @param {*} queryTerm
   * @param {*} setOptions
   */
  const searchSsr = useCallback(
    (queryTerm: string, setOptions: any): Promise<SearchOption[]> => {
      if (queryTerm && queryTerm.trim().length > 3) {
        return Promise.all([
          DataLoader.query_ssr(queryTerm),
          searchLocations(queryTerm),
        ]).then((res) => {
          var ssr = res[0] as SsrResponse;
          var locOpts = res[1] as SearchOption[];
          if (ssr && Array.isArray(ssr.navn) && ssr.navn.length > 0) {
            const options = parseSsrOptions(ssr);
            setOptions([...locOpts, ...options]);
            return options;
          } else {
            return [];
          }
        });
      } else {
        return Promise.resolve([]);
      }
    },
    [parseSsrOptions, searchLocations]
  );

  return (
    <div className="filter--map--ssr-search">
      <AsyncSelect
        cacheOptions
        placeholder={"Stedsnavn"}
        loadingMessage={(d) => "Søker"}
        loadOptions={ExecUtils.debounceTwoArgs(null, searchSsr, 500)}
        defaultOptions
        isMulti={false}
        onChange={(v: any) => {
          if (!map) return;
          const { lon, lat, nameType } = v.value;

          let zoomLevel;

          switch (nameType) {
            case "Fylke":
              zoomLevel = 8;
              break;
            case "Kommune":
            case "Fjord":
              zoomLevel = 12;
              break;
            case "By":
            case "Tettsted":
              zoomLevel = 14;
              break;
            default:
              zoomLevel = 16;
          }

          const centerPoint = olProj.transform(
            [lon, lat],
            "EPSG:4326",
            "EPSG:3857"
          );
          map.getView().animate({ zoom: zoomLevel, center: centerPoint });
        }}
      />
    </div>
  );
}
