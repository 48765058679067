import { Grid } from "../../types/Grid";
import { interpolateValue } from "./interpolateValue";

// Function to perform IDW interpolation on a grid
export function interpolateGridValues(
  grid: Grid,
  power: number = 2, // IDW power parameter (higher gives closer points more influence)
  radius: number = 3 // Number of cells to search around the missing value
): Grid {
  const interpolatedValues = new Float32Array(grid.data.length); // Output array with interpolated values

  // Loop through each cell in the grid
  for (let currentRow = 0; currentRow < grid.rows; currentRow++) {
    for (let currentCol = 0; currentCol < grid.cols; currentCol++) {
      const currentIndex = currentRow * grid.cols + currentCol;

      // Check if the current cell is missing (e.g., NaN)
      if (isNaN(grid.data[currentIndex])) {
        interpolatedValues[currentIndex] = interpolateValue(
          currentCol,
          currentRow,
          grid,
          power,
          radius
        );
      } else {
        interpolatedValues[currentIndex] = grid.data[currentIndex]; // Copy existing value
      }
    }
  }

  return {
    ...grid,
    data: interpolatedValues,
  };
}


