import React, { Component } from "react";
import PropTypes from "prop-types";
import * as dc from "dc";
import { BaseMixin } from "../Mixins/BaseMixin";
import { TileMixin } from "../Mixins/TileMixin";
import { AVIChartTile } from "./../Layout/AVIChartTile";

export class SelectMenu extends Component {
  static propTypes = {
    filterDisplayed: PropTypes.func,
    multiple: PropTypes.bool,
    numberItems: PropTypes.any,
    order: PropTypes.func,
    promptText: PropTypes.string,
    promptValue: PropTypes.any,
    chartTitle: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    info: PropTypes.string,
  };

  static defaultProps = {
    filterDisplayed: (d) => true,
    multiple: false,
    numberItems: null,
    order: (a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0),
    promptText: "",
    promptValue: null,
    width: 4,
    height: 1,
    info: "",
  };

  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.chart = null;
    this.getChart = this.getChart.bind(this);
  }

  getChart() {
    return this.chart;
  }

  componentDidMount() {
    const {
      filterDisplayed,
      multiple,
      numberItems,
      order,
      promptText,
      promptValue,
    } = this.props;

    this.chart = dc.selectMenu(this.chartRef.current);

    BaseMixin(this.chart, this.props);
    TileMixin(this.chart, this.props);

    this.chart
      .filterDisplayed(filterDisplayed)
      .numberVisible(numberItems)
      .order(order)
      .promptText(promptText)
      .promptValue(promptValue)
      .multiple(multiple)
      .render();
  }

  render() {
    return (
      <AVIChartTile
        title={this.props.chartTitle}
        getChart={this.getChart}
        width={this.props.width}
        height={this.props.height}
        useFlex
        info={this.props.info}
      >
        <div className="avi-chart" ref={this.chartRef} />
      </AVIChartTile>
    );
  }
}
