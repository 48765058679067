import React, { PropsWithChildren, useState } from "react";
import { Button } from "react-bootstrap";

export type InfoRowProps = PropsWithChildren & {
  title: string;
  width?: number;
};

export function InfoRow({ title, width=1, children=null }: InfoRowProps) {
  const [show, setShow] = useState<boolean>(false);
  return (
    <div className="flex-grow-1 p-3 kyv--bg-header rounded-1" style={{flex: width, cursor: "pointer"}}>
      <div className="d-flex" onClick={()=>setShow(!show)}>
        <div className="kyv--fs-header">{title}</div>
        <div className="ms-auto">
          <Button variant="outline-dark" className="border-0">
            {show ? <i className="bi bi-chevron-up"></i>: <i className="bi bi-chevron-down"></i>}
          </Button>
        </div>
      </div>
      {show && <div>{children}</div>}
    </div>
  );
}
