import "rc-slider/assets/index.css";
import "./DbrdReplayRealtime3D.scss";

import React, { Component } from "react";

import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
// import { ReplayRealtime3DMapChart } from "../../chart-components/ChartsExt/ReplayRealtime3DMapChart";
import crossfilter from "crossfilter2";
import { GeoJSONFeatureCollection } from "ol/format/GeoJSON";
import { NumUtils } from "../../chart-components/ChartUtils/NumUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { SelectMenu } from "../../chart-components/Charts/SelectMenu";
import { CesiumMap } from "../../chart-components/ChartsExt/CesiumMap";
import withRouter from "../../hocs/withRouter";
import { Loader } from "../../ui-components/Loader/Loader";
import { KYVGroupings } from "../../utils/KYVGroupings";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../types/DashboardFilter";

class DbrdReplayRealtime3DBase extends Component<any, any> {
  static dashboardRoute = "ship-realtime";

  static dashboardFilters(
    filter: DashboardFilter,
    setFilter: SetDashboardFilterFunc
  ) {
    return {
      helpMessage: "Sporing av skipstrafikk i sanntid",
      controls: [],
    };
  }

  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: null,
    };
  }

  sliderTimeout: any;
  reloadInterval: any;

  constructor(props) {
    super(props);

    this.state = {
      aisClass: [],
      shipType: [],
      minLength: 0,
      maxLength: 1000,
      cf: null,
    };
    this.sliderTimeout = null;
    this.reloadInterval = null;
    this.reloadFeatures = this.reloadFeatures.bind(this);
  }

  static dashboardValidation(filter) {
    return true;
  }

  static propTypes = {};

  timeout = null;

  reportProgress(progressData) {}

  // Scale for ship types
  shipTypeScale(shipType) {
    if (shipType === 30) {
      return "Fiskefartøy";
    } else if (
      (shipType >= 31 && shipType <= 35) ||
      (shipType >= 40 && shipType <= 59)
    ) {
      return "Offshorefartøy og spesialfartøy";
    } else if (shipType >= 60 && shipType <= 69) {
      return "Passasjerskip";
    } else if (shipType >= 70 && shipType <= 79) {
      return "Lasteskip";
    } else if (shipType >= 80 && shipType <= 89) {
      return "Tankskip";
    } else if (shipType === 36 || shipType === 37) {
      return "Fritidsfartøy";
    } else {
      return "Ukjent";
    }
  }

  async reloadFeatures() {
    // Load data response

    let res = await fetch(
      // `https://geomap05.kystverket.no/api/ais/realtime/ships/near-mmsi?mmsi=${257245220}&distance=${2000}`
      `https://geomap05.kystverket.no/api/ais/realtime/ships/near-mmsi?mmsi=${259113000}&distance=${1000}`
    );

    // Load json fom response and convert to GeoJson
    const js = await res.json();

    const geojson: GeoJSONFeatureCollection = {
      type: "FeatureCollection",
      features: [],
    };
    js.forEach((row) => {
      const { geom, geom_point, geom_trajectory, ...properties } = row;
      delete geom_point.crs;
      geojson.features.push({
        type: "Feature",
        geometry: geom_point,
        properties,
      });
    });

    if (Array.isArray(geojson?.features)) {
      // Add ship_type_labels
      geojson.features.forEach((feature) => {
        feature.properties!.ship_type_label = this.shipTypeScale(
          feature.properties!.ship_type
        );
        return feature;
      });

      this.setState({
        cf: crossfilter(geojson.features),
      });
    }
  }

  componentDidMount() {
    this.reloadFeatures();
    // this.reloadInterval = setInterval(() => {
    //   // Replace data in crossfilter
    //   this.reloadFeatures();
    // }, 120000);
  }

  componentWillUnmount() {
    clearTimeout(this.reloadInterval);
  }

  render() {
    const { cf } = this.state;

    if (!cf) {
      return <Loader chartData={cf} />;
    }
    var dimMmsi = cf.dimension((d) => d.properties.mmsi || "ukjent");

    var dimAisClass = cf.dimension((d) =>
      d.properties.hasOwnProperty("ais_class")
        ? d.properties.ais_class || ""
        : "Ukjent"
    );
    var grpShipsByAisClass = dimAisClass.group().reduceCount();

    var dimShipType = cf.dimension((d) => d.properties.ship_type_label);
    var grpShipsByShipType = dimShipType.group().reduceCount();

    var dimLengthGroup = cf.dimension((d) =>
      KYVGroupings.getShipLengthGroup(d.properties.length || -1)
    );
    var grpShipsByLengthGroup = dimLengthGroup.group().reduceCount();
    var shipLengthLabels = KYVGroupings.getShipLengthLabels();

    return (
      <div className="AppView">
        <AVIDashboard
          title="Skipstrafikk i nær sanntid"
          desc="Dette dashboardet gir tilgang til nær sanntidsposisjoner fra AIS meldinger som fanges opp av AIS basestasjoner i Norge. De blå linjestrekkene bak hvert skip indikerer seilaslinjen til skipet for de 20 siste minuttene."
          units="Skip"
          spacing={20}
          useFlex
          cfilter={cf}
          filter={this.props.location.state}
          group={dimMmsi.groupAll()}
        >
          <AVIRow>
            <CesiumMap
              featuresCrossfilter={cf}
              showTerrain
              showBuildings
              mmsi={259113000}
            />
          </AVIRow>
          <AVIRow>
            <AVICol>
              <SelectMenu
                chartTitle="AIS-klasse"
                filterPrefix="AIS-klasse"
                dimension={dimAisClass}
                promptText="Vis alle klasser"
                order={(a, b) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0)}
                group={grpShipsByAisClass}
                numberItems={10}
                multiple
                info="AIS transpondere forekommer i to ulike klasser, klasse A (bedre signalstyrke og prioritet, benyttes av større kommersielle fartøy) og klasse B (lavere signalstyke, mindre fartøy, lavere prioritet, begrenset seilasinformasjon)"
              />
            </AVICol>
            <AVICol>
              <SelectMenu
                chartTitle="Skipskategori"
                filterPrefix="Skipskategori"
                dimension={dimShipType}
                promptText="Vis alle skipskategorier"
                order={(a, b) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0)}
                group={grpShipsByShipType}
                numberItems={10}
                multiple
                info="AIS har en egen skipstypeinndeling som angis som tall mellom 0 og 99. Her kobles disse verdiene til matchende skipskategorier slik de benyttes ellers i Kystdatahuset."
              />
            </AVICol>
            <AVICol>
              <SelectMenu
                chartTitle="Lengdegruppe"
                filterPrefix="Lengdegruppe"
                dimension={dimLengthGroup}
                promptText="Vis alle lengdegrupper"
                order={(a, b) =>
                  shipLengthLabels.indexOf(a.key) >
                  shipLengthLabels.indexOf(b.key)
                    ? 1
                    : shipLengthLabels.indexOf(b.key) >
                      shipLengthLabels.indexOf(a.key)
                    ? -1
                    : 0
                }
                group={grpShipsByLengthGroup}
                numberItems={10}
                multiple
                info="Statiske AIS-meldinger inneholder ikke skipets lengde og må hentes fra tredjepartsregistre. Lengde er her kalkulert ved hjelp av seilasdata fra AIS-meldinger. Disse forteller hvor AIS-transponderen er plassert på skipet i form av avstand fra baug, hekk, babord og styrbord. Lengde er kalkulert som summen av avstand til baug og hekk. Dette kan avvike fra skipets faktiske lengde da disse informasjonselementene er lagt inn i transponderen manuelt."
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <DataTable
                chartTitle="Skip i gjeldende utvalg"
                dimension={dimMmsi}
                sortBy={(d) => d.properties.mmsi}
                useFlex
                size={Infinity}
                columns={[
                  {
                    label: "MMSI#",
                    title: "Maritime Mobile Service Identity",
                    format: (d) => {
                      return d.properties.mmsi || 0;
                    },
                  },
                  {
                    label: "IMO#",
                    title:
                      "Skipsidentifikasjonsnummer fra den Internasonale Maritime Organisasjonen (IMO) ",
                    format: (d) => d.properties.imo || "",
                  },
                  {
                    label: "Kallesignal",
                    title: "Radiokallesignal utstedt av Sjøfartsdirektoratet",
                    format: (d) => d.properties.callsign || "",
                  },
                  {
                    label: "Skipsnavn",
                    title: "Skipets navn",
                    format: (d) => d.properties.ship_name || "",
                  },
                  {
                    label: "Skipskategori",
                    title:
                      "Skipskategori som definert av Kystverket og alminnelig benyttet i Kystdatahuset",
                    format: (d) => d.properties.ship_type_label || "",
                  },
                  {
                    label: "Lengde",
                    title: "Lengde = avstand til hekk + avstand til baug",
                    format: (d) => NumUtils.integer(d.properties.length || 0),
                  },
                  {
                    label: "Bredde",
                    title: "Bredde = avstand til babord + avstand til styrbord",
                    format: (d) => NumUtils.integer(d.properties.breadth || 0),
                  },
                  {
                    label: "Dyptgående",
                    title:
                      "Maksimalt dyptgående som angitt i seilasinformasjon fra AIS-meldinger",
                    format: (d) => NumUtils.integer(d.properties.draught || 0),
                  },
                  {
                    label: "Destinasjon",
                    title:
                      "Destinasjon som angitt i seilasinformasjon fra AIS-meldinger",
                    format: (d) => d.properties.destination || "",
                  },
                  {
                    label: "Sist sett",
                    title:
                      "Tidspunkt da siste AIS-melding ble mottatt fra skip (UTC)",
                    format: (d) =>
                      TimeUtils.toCompactTimestring(
                        new Date(d.properties.date_time_utc)
                      ),
                  },
                ]}
              />
            </AVICol>
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdReplayRealtime3D = withRouter(DbrdReplayRealtime3DBase);

export default DbrdReplayRealtime3D;
