import { GeoJSONFeature } from "ol/format/GeoJSON";
import React, { useCallback, useState } from "react";
import { Stack } from "react-bootstrap";
import {
  MunicipalityOption,
  MunicipalityTypeahead,
} from "../../../filters/MunicipalityTypeahead";
import { Municipality } from "../../../types/Municipality";
import { EventLike } from "../../../types/Shims";
import { useFilterContext } from "../FilterList/FilterList";
import { AddGeoJSONToDrawingLayer } from "./AddGeoJSONToDrawingLayer";
import { ClearDrawingLayer } from "./ClearDrawingLayer";
import { ZoomToDrawingLayer } from "./ZoomToDrawingLayer";

export type SelectMunicipalityProps = {
  name: string;
  returnProperty: "geometry" | "komm";
};

export function SelectMunicipality({
  name: filterName,
  returnProperty: propertyName,
}: SelectMunicipalityProps) {
  const [municipalityFeature, setMunicipalityFeature] = useState<Municipality>();
  const { setFilter } = useFilterContext();

  const handleChange = useCallback(
    (gjFeatures: GeoJSONFeature[]) => {
      if (propertyName !== "geometry") {
        setFilter({
          [filterName]: gjFeatures.map((gjf) => gjf.properties![propertyName]),
        });
      } else {
        setFilter({
          [filterName]: gjFeatures,
        });
      }
    },
    [filterName, propertyName, setFilter]
  );

  return (
    <>
      <Stack direction="vertical" gap={2}>
        <MunicipalityTypeahead
          name="municipality"
          onChange={(evt: EventLike<MunicipalityOption[]>) => {
            setMunicipalityFeature(evt.target.value[0].geoJson);
            handleChange([evt.target.value[0].geoJson])
          }}
        />
      </Stack>
      <>
        {municipalityFeature && (
          <AddGeoJSONToDrawingLayer geoJSON={[municipalityFeature]} />
        )}
        <ClearDrawingLayer />
        <ZoomToDrawingLayer />
      </>
    </>
  );
}
