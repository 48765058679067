export const BaseMixin = function (chart, props) {
  const {
    dimension = null,
    group,
    ordering,
    title,
    filterPrefix = "Filter",
    filterHandler = null,
    legend = null,
    useViewBoxResizing = true,
    label = function (d) {
      if (isNaN(d.key)) {
        return d.key;
      } else {
        d.key = +d.key;
        return d.key.toFixed(2);
      }
    },
    renderLabel = true,
    preRender = null,
    preRedraw = null,
    valueAccessor = (d) => d.value,
    keyAccessor = (d) => d.key,
  } = props;

  if (chart.group() == null && !group) {
    throw new Error("No group, no group set specified");
  } else if (chart.group() == null && group) {
    chart.group(group);
  }

  if (filterPrefix) {
    chart.filterPrefix = filterPrefix;
  }

  chart
    .minHeight(75)
    .dimension(dimension)
    .label(label)
    .renderLabel(renderLabel)
    .useViewBoxResizing(useViewBoxResizing)
    .valueAccessor(valueAccessor)
    .keyAccessor(keyAccessor);

  if (!chart.hasOwnProperty("anchorName")) {
    chart.anchorName = () => {
      return `chart-${new Date().getTime()}`;
    };
  }

  if (typeof title === "function") {
    chart.title(title);
  }

  if (typeof filterHandler === "function") {
    chart.filterHandler(filterHandler.bind(chart));
  }

  if (typeof ordering === "function") {
    chart.ordering(ordering);
  }

  if (typeof preRender === "function") {
    chart.on("preRender", preRender);
  }

  if (typeof preRedraw === "function") {
    chart.on("preRedraw", preRedraw);
  }

  if (legend !== null) {
    chart.legend(legend);
  }

  return chart;
};
