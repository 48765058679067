import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../auth-components/AuthLib";

const mapStateToProps = (state) => ({
  sessionToken: state.auth.sessionToken,
});

const PrivateRoute = ({ children, loginPath }) => {
  var {authState} = useAuthContext();
  const { authenticated } = authState;
  return authenticated ? children : <Navigate to={loginPath} />;
};

PrivateRoute.propTypes = {
  loginPath: PropTypes.string,
};

PrivateRoute.defaultProps = {
  loginPath: "/logginn",
};

export default connect(mapStateToProps)(PrivateRoute);
