import { Collection, Feature } from "ol";
import { Coordinate } from "ol/coordinate";
import { boundingExtent, isEmpty } from "ol/extent";
import { GeoJSONFeature } from "ol/format/GeoJSON";
import { Geometry, LineString, Point, Polygon } from "ol/geom";
import { fromExtent } from "ol/geom/Polygon";
import { DragBox, Draw, Extent, Interaction, Select } from "ol/interaction";
import { DragBoxEvent } from "ol/interaction/DragBox";
import { DrawEvent } from "ol/interaction/Draw";
import { ExtentEvent } from "ol/interaction/Extent";
import { SelectEvent } from "ol/interaction/Select";
import VectorLayer from "ol/layer/Vector";
import Cluster from "ol/source/Cluster";
import { useCallback, useEffect, useMemo } from "react";
import { geoJSONFormat } from "../../../utils/geomUtils";
import { selectionStyle } from "../../../utils/mapStyles";
import {
  addKyvDvhInteraction,
  removeKyvDvhInteractions,
} from "../../../utils/mapUtils";
import { MapInteractionType, SelectionLayerName, useFilterContext } from "../FilterList/FilterList";
import "./AddMapInteraction.scss";

export type AddMapInteractionProps = {
  mapInteraction: MapInteractionType;
  onChange: (feature: GeoJSONFeature[]) => void;
  selectionLayer?: SelectionLayerName;
  selectionGeometry?: GeoJSONFeature;
  multiple?: number;
  sticky?: boolean;
};

export function AddMapInteraction({
  mapInteraction,
  selectionLayer,
  selectionGeometry,
  multiple = 1,
  sticky = false,
  onChange,
}: AddMapInteractionProps) {
  const featureBuffer: Feature[] = useMemo(() => [], []);

  const { passlinesLayer, locationsLayer, drawingLayer, map } =
    useFilterContext();

  const selectedCollection = useMemo(() => new Collection<Feature>(), []);

  const boxCoordinates: Coordinate[] = useMemo(() => {
    return [];
  }, []);

  const handleDraw = useCallback(
    (drawnGeometry: Geometry) => {
      const src = drawingLayer.getSource();
      if (!drawnGeometry || !src) return;
      var drawnFeature = new Feature<Geometry>(drawnGeometry);
      if (src.getFeatures().length > multiple - 1) {
        drawingLayer.getSource()?.clear();
        featureBuffer.length = 0;
      }
      featureBuffer.push(drawnFeature);
      drawingLayer.getSource()?.addFeature(drawnFeature);
      let gjs = featureBuffer.map((f) =>
        geoJSONFormat.writeFeatureObject(f, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:3857",
        })
      );
      if (gjs) {
        onChange(gjs!);
      }
    },
    [drawingLayer, featureBuffer, multiple, onChange]
  );

  const handleSelectPoint = useCallback(
    (evt: SelectEvent) => {
      var src = drawingLayer.getSource();
      if (!src) return;
      let clusteredFeatures: Feature[] = [];
      if (featureBuffer.length > multiple - 1) {
        featureBuffer.length = 0;
      }
      selectedCollection.forEach((feature) => {
        if (Array.isArray(feature.get("features"))) {
          clusteredFeatures.push(...feature.get("features"));
        } else {
          clusteredFeatures.push(feature);
        }
      });
      featureBuffer.push(...clusteredFeatures);
      let gjs = featureBuffer.map((feature) => {
        return geoJSONFormat.writeFeatureObject(feature, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:3857",
        });
      });
      onChange(gjs);
    },
    [drawingLayer, featureBuffer, multiple, onChange, selectedCollection]
  );

  const handleSelect = useCallback(
    (geom: Polygon | null, layer: VectorLayer<Feature<Geometry>>) => {
      let src = layer.getSource();
      let features: Feature[] = [];
      if (!src) return;
      if (geom === null) {
        drawingLayer.getSource()?.clear();
        return;
      }
      if (src instanceof Cluster) {
        const clusterFeatures = src.getFeaturesInExtent(geom.getExtent());
        clusterFeatures.forEach((feature) => {
          features.push(...feature.get("features"));
        });
      } else {
        features.push(...src.getFeaturesInExtent(geom.getExtent()));
      }
      features = features.filter((feature: Feature) => {
        let ptGeom = feature.getGeometry();
        if (ptGeom && ptGeom instanceof Point) {
          return geom.intersectsCoordinate((ptGeom as Point).getCoordinates());
        }
        return true;
      });
      var drawingSource = drawingLayer.getSource();
      if (drawingSource) {
        drawingSource.clear();
        drawingSource.addFeatures([new Feature(geom), ...features]);
      }
      var gjs = features.map((feature) =>
        geoJSONFormat.writeFeatureObject(feature, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:3857",
        })
      );
      onChange(gjs);
    },
    [drawingLayer, onChange]
  );

  useEffect(() => {
    if (!map) return;
    let layerToSelectFrom: VectorLayer<Feature<Geometry>> | undefined =
      undefined;

    switch (selectionLayer) {
      case "passlines":
        layerToSelectFrom = passlinesLayer;
        break;
      case "locationIds":
      case "locations":
        layerToSelectFrom = locationsLayer;
        break;
      case undefined:
        break;
      default:
        throw new Error(`Unknown layer type ${selectionLayer}`);
    }

    const interactions: Interaction[] = [];

    switch (mapInteraction) {
      case "selectPoint":
        const selectPointInteraction = new Select({
          layers:
            selectionLayer === "passlines"
              ? [passlinesLayer]
              : [locationsLayer],
          style: selectionStyle,
          features: selectedCollection,
        });
        selectPointInteraction.on("select", handleSelectPoint);
        interactions.push(selectPointInteraction);
        break;
      case "drawPoint":
        const drawPointInteraction = new Draw({ type: "Point" });
        drawPointInteraction.on("drawend", (evt: DrawEvent) => {
          var pt = evt.feature.getGeometry() as Point;
          handleDraw(pt);
        });
        interactions.push(drawPointInteraction);
        break;
      case "selectBox":
        const selectBoxInteraction = new Extent({});
        let to: any = null;
        selectBoxInteraction.on("extentchanged", (evt: ExtentEvent) => {
          if (to) {
            clearTimeout(to);
          }
          if (!layerToSelectFrom) return;
          to = setTimeout(() => {
            if (!evt.extent) {
              handleSelect(null, layerToSelectFrom);
              return;
            } else {
              const poly = fromExtent(evt.extent);
              handleSelect(poly, layerToSelectFrom);
            }
          }, 500);
        });
        interactions.push(selectBoxInteraction);
        break;
      case "drawBox":
        const drawBoxInteraction = new DragBox({
          className: "kyvdvh-ol-dragbox",
        });
        drawBoxInteraction.on("boxstart", (evt: DragBoxEvent) => {
          boxCoordinates.length = 0;
          if (!evt.coordinate) return;
          boxCoordinates.push(evt.coordinate);
        });
        drawBoxInteraction.on("boxend", (evt: DragBoxEvent) => {
          if (!evt.coordinate) return;
          const ext = boundingExtent([boxCoordinates[0], evt.coordinate]);
          if (!isEmpty(ext)) {
            const poly = fromExtent(ext);
            handleDraw(poly);
          }
        });
        interactions.push(drawBoxInteraction);
        break;
      case "selectPolygon":
        const selectPolygonInteraction = new Draw({ type: "Polygon" });
        selectPolygonInteraction.on("drawend", (evt: DrawEvent) => {
          if (!layerToSelectFrom) return;
          var geom = evt.feature.getGeometry();
          if (geom) {
            handleSelect(geom as Polygon, layerToSelectFrom);
          }
        });
        interactions.push(selectPolygonInteraction);
        break;
      case "drawPolygon":
        const drawPolygonInteraction = new Draw({ type: "Polygon" });
        drawPolygonInteraction.on("drawend", (evt: DrawEvent) => {
          var geom = evt.feature.getGeometry();
          if (geom) {
            handleDraw(geom as Polygon);
          }
        });
        interactions.push(drawPolygonInteraction);
        break;
      case "drawLineString":
        const drawLineStringInteraction = new Draw({ type: "LineString" });
        drawLineStringInteraction.on("drawend", (evt: DrawEvent) => {
          var geom = evt.feature.getGeometry();
          if (geom) {
            handleDraw(geom as LineString);
          }
        });
        interactions.push(drawLineStringInteraction);
        break;
      case "overlayPolygon":
        if (selectionGeometry && layerToSelectFrom) {
          var feat = geoJSONFormat.readFeature(selectionGeometry, {
            dataProjection: "EPSG:4326",
            featureProjection: "EPSG:3857",
          }) as Feature<Polygon>;
          var poly = feat.getGeometry();
          if (poly) {
            handleSelect(poly, layerToSelectFrom);
          }
        }
        break;
    }

    // Turn off existing
    removeKyvDvhInteractions(map);

    interactions.forEach((interaction) => {
      addKyvDvhInteraction(map, interaction);
    });

    return () => {
      removeKyvDvhInteractions(map);
    };
  }, [
    boxCoordinates,
    handleDraw,
    handleSelect,
    handleSelectPoint,
    locationsLayer,
    map,
    mapInteraction,
    passlinesLayer,
    selectedCollection,
    selectionGeometry,
    selectionLayer,
  ]);

  return null;
}
