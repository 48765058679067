import { Feature } from "ol";
import GeoJSON, { GeoJSONFeature } from "ol/format/GeoJSON";
import WKT from "ol/format/WKT";
import { Geometry } from "ol/geom";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import { fromExtent } from "ol/geom/Polygon";
import { Extent } from "ol/extent";

export const wktFormat = new WKT();
export const geoJSONFormat = new GeoJSON();

export function registerProjections() {
  proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs +type=crs");
  proj4.defs(
    "EPSG:32632",
    "+proj=utm +zone=32 +datum=WGS84 +units=m +no_defs +type=crs"
  );
  proj4.defs(
    "EPSG:32633",
    "+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs +type=crs"
  );
  proj4.defs(
    "EPSG:32634",
    "+proj=utm +zone=34 +datum=WGS84 +units=m +no_defs +type=crs"
  );
  proj4.defs(
    "EPSG:3575",
    "+proj=laea +lat_0=90 +lon_0=10 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs +type=crs"
  );
  proj4.defs(
    "EPSG:3857",
    "+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs"
  );
  register(proj4);
}

export function validateWkt(wkt: string, srid: number): Geometry | false {
  try {
    return wktFormat.readGeometry(wkt);
  } catch (error) {
    return false;
  }
}

export function validateGeoJSON(
  geoJSON: string,
  srid: number
): Geometry | false {
  try {
    const feat = geoJSONFormat.readFeature(geoJSON);
    return feat.getGeometry()!;
  } catch (error) {
    return false;
  }
}

export function validateExtent(str: string, srid: number): Geometry | false {
  try {
    const regex = /([-]{0,1}[0-9]*[.]{0,1}[0-9]*)/gm;
    const matches = str.match(regex)?.filter((m) => m !== "");
    if (matches?.length === 4) {
      const ext = matches.map((d) => +d);
      return fromExtent(ext);
    } else {
      throw Error("Could not parse extent");
    }
  } catch (error) {
    return false;
  }
}

export function geomToWkt(
  geom: Geometry | Feature,
  fromSrid: number,
  toSrid: number
): string | undefined {
  try {
    const wktFmtOptions = {
      featureProjection: `EPSG:${fromSrid}`,
      dataProjection: `EPSG:${toSrid}`,
    };
    const geom2 = geom instanceof Geometry ? geom : geom.getGeometry();
    if (geom2 instanceof Geometry) {
      return wktFormat.writeGeometry(geom2, wktFmtOptions);
    } else {
      throw Error("Passed value is not a Geometry");
    }
  } catch (error) {
    console.warn(error);
    return;
  }
}

export function geomToGeoJSONFeature(
  geom: Geometry | Feature,
  fromSrid: number,
  toSrid: number
): GeoJSONFeature | undefined {
  try {
    const geoJSONFmtOptions = {
      featureProjection: `EPSG:${fromSrid}`,
      dataProjection: `EPSG:${toSrid}`,
    };
    const geom2 = geom instanceof Geometry ? geom : geom.getGeometry();
    if (geom2 instanceof Geometry) {
      return geoJSONFormat.writeFeatureObject(
        new Feature(geom),
        geoJSONFmtOptions
      );
    } else {
      throw Error("Passed value is not a geometry");
    }
  } catch (error) {
    console.warn(error);
    return;
  }
}

export function geomToExtent(
  geom: Geometry | Feature,
  fromSrid: number,
  toSrid: number
): Extent | undefined {
  try {
    const geom2 = geom instanceof Geometry ? geom.clone() : geom.getGeometry()?.clone();
    if (geom2 instanceof Geometry) {
      return geom2.transform(`EPSG:${fromSrid}`, `EPSG:${toSrid}`).getExtent();
    } else {
      throw Error("Passed value is not a Geometry");
    }
  } catch (error) {
    console.warn(error);
    return;
  }
}
