import request from 'superagent';

export class WPLoader {
    static wpUrl = 'https://kystdatahuset.no/cms';

    static LoadArticlesByCategoryIds (categoryIds) {
      return request
        .get(`${WPLoader.wpUrl}/wp-json/wp/v2/posts`)
        .query({ '_embed': null })
        .query({ 'categories': categoryIds });
    }

    static LoadArticleBySlug (slug, postType = 'posts') {
      return request
        .get(`${WPLoader.wpUrl}/wp-json/wp/v2/${postType}`)
        .query({ '_embed': null })
        .query({ 'slug': slug });
    }
}

export default WPLoader;
