import { createRoot } from 'react-dom/client';
import App from './App';
import './index.scss';
import createStore from './redux/createStore';
import reportWebVitals from './reportWebVitals';

const initialState = window.__INITIAL_STATE__;
const store = createStore(initialState);

const root = createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App store={store} />
  // </React.React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
