/* eslint-disable react/prop-types */

import * as d3 from 'd3';

import React, { Component } from 'react';

import { BarChart } from './BarChart';
import PropTypes from 'prop-types';
import * as dc from 'dc';

export class BarChartHistogram extends Component {
  static propTypes = {
    chartTitle: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number.isRequired,
    chartCrossfilter: PropTypes.object.isRequired,
    histogramValueAccessor: PropTypes.func.isRequired,
    valueAccessor: PropTypes.func,
    keyAccessor: PropTypes.func,
    xAxisLabel: PropTypes.string,
    yAxisLabel: PropTypes.string,
    thresholds: PropTypes.array,
    useFlex: PropTypes.bool
  };

  static defaultProps = {
    valueAccessor: d => d.value,
    keyAccessor: d => d.key,
    thresholds: [],
    useFlex: false
  };

  histGenerator = null;
  data = null;
  thresholds = [];
  dimension = null;
  group = null;

  constructor (props) {
    super(props);
    const { thresholds } = this.props;
    if (thresholds.length === 0) {
      var hist = d3.histogram()(this.props.group.all().map(d => d.key));
      this.thresholds.push(Math.floor(hist[0].x0), ...hist.map(m => Math.ceil(m.x1)));
    } else {
      this.thresholds = thresholds;
    }

    let ranges = d3.pairs(this.thresholds);
    this.dimension = this.props.chartCrossfilter.dimension(d => {
      for (let r = 0; r < ranges.length; r++) {
        let range = ranges[r];
        if (this.props.histogramValueAccessor(d) >= range[0] &&
          this.props.histogramValueAccessor(d) < range[1]) {
          return `${range[0]} - ${range[1]}`;
        }
      }
    });

    this.group = this.dimension.group().reduceCount();
  }

  componentDidMount () {
  }

  render () {
    const { ordering, ...otherProps } = this.props;
    if (!this.dimension || !this.group) return (<div>...</div>);

    return (
      <BarChart
        dimension={this.dimension}
        group={this.group}
        x={d3.scaleBand()}
        xUnits={dc.units.ordinal}
        barPadding={0.1}
        centerBar={false}
        outerPadding={0.05}
        ordering={d => +d.key.split(' - ')[0]}
        brushOn={false}
        {...otherProps}
      />
    );
  }
}
