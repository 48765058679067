import "./ArticleView.scss";

import PropTypes from "prop-types";
import React from "react";
import { Navigate } from "react-router-dom";
import { Loader } from "../../ui-components/Loader/Loader";
// import request from 'superagent';
import { Container } from "react-bootstrap";
import withRouter from "../../hocs/withRouter";
import { WPLoader } from "./../../ui-components/WPLoader/WPLoader";

class ArticleView extends React.Component {
  static propTypes = {
    params: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isClosing: false,
      isLoading: true,
      wpArticle: null,
    };
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    this.loading = true;
    const { id } = this.props.params;
    this.loadArticle(id);
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.params;
    if (id !== prevProps.params.id) {
      this.loadArticle(id);
    }
  }

  loadArticle(id) {
    this.setState({
      isLoading: true,
    });
    WPLoader.LoadArticleBySlug(id).then((res) => {
      if (Array.isArray(res.body) && res.body.length > 0) {
        this.setState({
          wpArticle: res.body[0],
        });
      }
      this.setState({
        isLoading: false,
      });
      this.loading = false;
    });
  }

  close() {
    this.setState({
      isClosing: true,
    });
  }

  render() {
    const { isClosing, isLoading, wpArticle } = this.state;
    if (isClosing) {
      return <Navigate to="/" />;
    }
    return (
      <Container>
        {isLoading && <Loader hideMessage />}
        {wpArticle && !isLoading && (
          <div className="article-view--article">
            {Array.isArray(wpArticle._embedded["wp:featuredmedia"]) &&
              wpArticle._embedded["wp:featuredmedia"].length > 0 && (
                <img
                  alt="Article feature illustration"
                  className="featured-image"
                  src={
                    wpArticle._embedded["wp:featuredmedia"][0].media_details
                      .sizes.full.source_url
                  }
                />
              )}
            <h2 className="mb-4">{wpArticle.title.rendered}</h2>
            <div
              className="article-view--content"
              dangerouslySetInnerHTML={{ __html: wpArticle.content.rendered }}
            />
          </div>
        )}
        {!wpArticle && !isLoading && (
          <div className="article-view--article error">
            Kunne ikke laste artikkel
          </div>
        )}
      </Container>
    );
  }
}

export default withRouter(ArticleView);
