import { Link, useNavigate } from "react-router-dom";
import Logo from "../Logo/kyv-logo4.svg";

import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
} from "react-bootstrap";
import {
  useAuthContext,
} from "../../auth-components/AuthLib";
import { RequireAnonymous } from "../../auth-components/RequireAnonymous";
import { RequireLogin } from "../../auth-components/RequireLogin";

export type HeaderBarProps = {
  className?: string;
  style?: { [key: string]: string | number | boolean };
};
export function HeaderBar({ className = "", style = {} }: HeaderBarProps) {
  const { authState, authDispatch } = useAuthContext();
  const navigate = useNavigate();
  const { authenticated = false } = authState;
  const [doLogout, setDoLogout] = useState(false);

  const logout = useCallback(() => {
    authDispatch({
      action: "logout",
    });
    setDoLogout(true);
  }, [authDispatch]);

  useEffect(() => {
    if (authenticated === false && doLogout) {
      setDoLogout(false);
      navigate("/logginn", { state: {} });
    }
  }, [authenticated, navigate, doLogout, setDoLogout]);

  return (
    <Navbar
      className={`bg-light px-5 ${className}`}
      expand="lg"
      variant="light"
      style={{ ...style }}
    >
      <Container className="py-4">
        <NavbarBrand as={Link} to="/" className="my-auto" style={{ height: "83px" }}>
          <img src={Logo as any} alt="Kystverket" />
        </NavbarBrand>
        <Nav className="ms-auto fs-5 d-none d-lg-flex">
          <RequireLogin>
            <Nav.Link as={Link} to="/tallogstatistikk">
              Tall og statistikk
            </Nav.Link>
            <Nav.Link
              className="d-none d-xl-block"
              as="a"
              href="https://kystinfo.no/"
              target="_blank"
              rel="noreferrer"
            >
              Kystinfo
            </Nav.Link>
            <Nav.Link
              className="d-none d-xl-block text-truncate"
              as="a"
              href="http://data.kystverket.no"
              target="_blank"
              rel="noreferrer"
            >
              Data og tjenester
            </Nav.Link>
          </RequireLogin>
          <Nav.Link as={Link} to="/artikkel/om-kystdatahuset">
            Om kystdatahuset
          </Nav.Link>
          <Nav.Link as={Link} to="/artikkel/api-tilgang">
            API tilgang
          </Nav.Link>
          <RequireLogin>
            <Nav.Link as={Link} to="/artikkel/hjelp">
              Hjelp
            </Nav.Link>
            <Nav.Link as={Link} to="/" onClick={logout}>
              Logg ut
            </Nav.Link>
          </RequireLogin>
          <RequireAnonymous authenticated={false}>
            <Nav.Link as={Link} to="/logginn">
              Logg inn
            </Nav.Link>
          </RequireAnonymous>
        </Nav>
        <Dropdown className="ms-auto d-lg-none" align="end" drop="down">
          <DropdownToggle
            size="lg"
            variant="link-primary"
            className="p-0 no-caret fs-1"
          >
            <i className="bi bi-list"></i>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem as={Link} to="/">
              Tilbake til startsiden
            </DropdownItem>
            <DropdownDivider />
            <RequireLogin>
              <DropdownItem as={Link} to="/tallogstatistikk">
                Tall og statistikk
              </DropdownItem>
              <DropdownDivider />
              <DropdownItem
                as="a"
                href="https://kystinfo.no/"
                target="_blank"
                rel="noreferrer"
              >
                Kystinfo
              </DropdownItem>
              <DropdownDivider />
              <DropdownItem
                as="a"
                href="http://data.kystverket.no"
                target="_blank"
                rel="noreferrer"
              >
                Data og tjenester
              </DropdownItem>
              <DropdownDivider />
            </RequireLogin>
            <DropdownItem as={Link} to="/artikkel/om-kystdatahuset">
              Om kystdatahuset
            </DropdownItem>
            <DropdownDivider />
            <DropdownItem as={Link} to="/artikkel/api-tilgang">
              API tilgang
            </DropdownItem>
            <DropdownDivider />
            <RequireLogin>
              <DropdownItem as={Link} to="/artikkel/hjelp">
                Hjelp
              </DropdownItem>
              <DropdownDivider />
              <DropdownItem as={Link} to="/" onClick={logout}>
                Logg ut
              </DropdownItem>
            </RequireLogin>
            <RequireAnonymous authenticated={false}>
              <DropdownItem as={Link} to="/logginn">
                Logg inn
              </DropdownItem>
            </RequireAnonymous>
          </DropdownMenu>
        </Dropdown>
      </Container>
    </Navbar>
  );
}

export default HeaderBar;
