import "./DateTimeControl.scss";

import { Component } from "react";

import PropTypes from "prop-types";
import { FormGroup, FormLabel } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";

export class DateTimeControl extends Component {
  static propTypes = {
    setValue: PropTypes.func.isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.object,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    minDetail: PropTypes.string,
    format: PropTypes.string,
    selectRange: PropTypes.bool,
    useUtc: PropTypes.bool,
  };

  static defaultProps = {
    minDate: new Date(2005, 1, 1, 0, 0),
    maxDate: new Date(),
    format: "dd/MM/yyyy HH:mm",
    selectRange: false,
    minDetail: "year",
    useUtc: true,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  onChangeHandler(date) {
    // var d = TimeUtils.dateToUTC(date);
    // console.log('local', date)
    // console.log('iso', d.toISOString());
    this.props.setValue(this.props.name, date);

    this.setState({
      date: date,
    });
  }

  render() {
    const {
      name,
      label,
      value,
      minDate,
      maxDate,
      format,
      selectRange,
      minDetail,
    } = this.props;

    return (
      <FormGroup>
        <FormLabel>{label}</FormLabel>
        <div>
          <DateTimePicker
            name={name}
            className="w-100"
            onChange={this.onChangeHandler}
            value={this.state.date || value}
            maxDate={maxDate || new Date()}
            minDate={minDate || new Date(2005, 1, 1)}
            clearIcon={"X"}
            disableClock
            format={format}
            selectRange={selectRange}
            showLeadingZeros={false}
            minDetail={minDetail}
          />
        </div>
      </FormGroup>
    );
  }
}
