import React, { PropsWithChildren, useMemo, useState } from "react";

export type AbbreviateProps = PropsWithChildren & {
  maxWords: number;
};

export function Abbreviate({ maxWords = 30, children = "" }: AbbreviateProps) {
  const [showAll, setShowAll] = useState<boolean>(false);
  const [abbrev, isAbbrev] = useMemo(() => {
    if (typeof children !== "string") return "";
    const words = (children as string)!.split(" ");
    const abbreviated = words.slice(0, maxWords).join(" ");
    return [abbreviated, words.length > maxWords];
  }, [children, maxWords]);
  return (
    <>
      {!showAll ? abbrev : children}
      {isAbbrev && !showAll && (
        <span
          className="kyv--fs-body ms-1 my-0 p-0 cursor-pointer"
          style={{ cursor: "pointer" }}
          onClick={() => setShowAll(true)}
        >
          [mer...]
        </span>
      )}
      {isAbbrev && showAll && (
        <span
          className="kyv--fs-body ms-1 my-0 p-0 cursor-pointer"
          style={{ cursor: "pointer" }}
          onClick={() => setShowAll(false)}
        >
          [mindre...]
        </span>
      )}
    </>
  );
}
