import { Feature, FeatureCollection } from "geojson";
import { useMap } from "../../context/MapProvider";
import { useEffect, useMemo } from "react";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { geoJSONFormat } from "../../utils/geomUtils";
import { StyleLike } from "ol/style/Style";

type OlMapGeoJsonLayerProps = {
    geoJson: Feature[] | FeatureCollection | undefined,
    zIndex: number,
    title: string,
    visible: boolean,
    styleLike?: StyleLike
}

export function OlMapGeoJsonLayer({ geoJson, title, styleLike, zIndex = 100, visible = false }: OlMapGeoJsonLayerProps) {

    const { map } = useMap();

    const { source, layer } = useMemo(() => {

        const source = new VectorSource();
        const layer = new VectorLayer({
            properties: { title },
            source,
            zIndex,
            visible
        });
        if (styleLike) {
            layer.setStyle(styleLike);
        }
        map.addLayer(layer);
        return { source, layer }
    }, [map, title, visible, zIndex, styleLike])

    useEffect(() => {
        if (geoJson) {
            const features = geoJSONFormat.readFeatures(geoJson, {
                dataProjection: "EPSG:4326",
                featureProjection: "EPSG:3857"
            })
            source.clear();
            source.addFeatures(features)
        }
        return ()=>{
            map.removeLayer(layer)
        }
    }, [source, geoJson, map, layer])

    return null;
}