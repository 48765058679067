import React, { Component } from 'react';

import { LocationTypeAhead } from './LocationTypeAhead/LocationTypeAhead';
import PropTypes from 'prop-types';

export class FilterToFromLocation extends Component {
  static propTypes = {
    setFilter: PropTypes.func.isRequired,
    filter: PropTypes.object
  };

  constructor (props) {
    super(props);
    this.state = {
      filter: props.filter
    };
  }

  update (key, val) {
    const { filter, setFilter } = this.props;
    filter[key] = val;
    setFilter(filter);
  }

  render () {
    // const {filter} = this.props;

    return (
      <div key='B46'>
        <LocationTypeAhead
          key='lta-79'
          label='Velg førete anløpssted'
          setValue={location => {
            this.update('location1', location.value);
          }}
          defaultValue={[]}
        />
        <LocationTypeAhead
          key='lta-80'
          label='Velg andre anløpssted'
          setValue={location => {
            this.update('location2', location.value);
          }}
          defaultValue={[]}
        />
      </div>
    );
  }
}
