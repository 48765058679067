import "../AVIChartCommon.scss";
import "./AVIFactTileFlex.scss";

import React, { Component } from "react";

import PropTypes from "prop-types";

export class AVIFactTileFlex extends Component {
  static propTypes = {
    children: PropTypes.any,
    title: PropTypes.string,
    flex: PropTypes.number,
    units: PropTypes.string,
  };

  static defaultProps = {
    flex: 1,
    units: undefined,
  };

  render() {
    const { children, title, flex, units } = this.props;

    return (
      <div
        className="avi-fact-flex p-3 rounded-1 d-flex flex-column gap-2 text-center"
        style={{
          flex: flex,
        }}
      >
        <div className="kyv--fs-body">{title}</div>
        <div className="kyv--fs-fact-tile">{children}</div>
        {units && <div className="kyv--fs-body">{units}</div>}
      </div>
    );
  }
}

export default AVIFactTileFlex;
