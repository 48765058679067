import * as d3 from 'd3';

import React, { Component } from 'react';

import { BarChart } from './BarChart';
import { PropTypes } from 'prop-types';
import { TimeUtils } from '../ChartUtils/TimeUtils';

export class BarChartMonth extends Component {
  static propTypes = {
    chartTitle: PropTypes.string,
    gap: PropTypes.number,
    alwaysUseRounding: PropTypes.bool,
    centerBar: PropTypes.bool,
    outerPadding: PropTypes.number,
    barPadding: PropTypes.number,
    group: PropTypes.object,
    groupName: PropTypes.string,
    groupSelectionFunction: PropTypes.func,
    stack: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    renderLabel: PropTypes.bool,
    ordering: PropTypes.func,
    rotateXAxisLabels: PropTypes.number,
    xAxisLabel: PropTypes.string,
    yAxisLabel: PropTypes.string
  };

  constructor (props) {
    super(props);
    this.calcDomain = this.calcDomain.bind(this);
  }

  // monthsFull = [
  //   'January',
  //   'February',
  //   'March',
  //   'April',
  //   'May',
  //   'June',
  //   'July',
  //   'August',
  //   'September',
  //   'October',
  //   'November',
  //   'December'
  // ];

  // months3 = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'];

  // months1 = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];

  calcDomain (chart) {
    const nullv = new Date(null);
    const filtered = chart
      .group()
      .all()
      .filter(function (v) {
        return v.key > nullv;
      });
    let min = d3.min(filtered, function (kv) {
      return kv.key;
    });
    let max = d3.max(filtered, function (kv) {
      return kv.key;
    });

    min = d3.timeMonth.offset(min, -1);
    max = d3.timeMonth.offset(max, 1);
    chart.x().domain([min, max]);
  }

  render () {
    return (
      <BarChart
        x={d3.scaleTime()}
        xUnits={d3.timeMonths}
        xAxisTickFormat={d => TimeUtils.locale.format('%b')(d)}
        round={d3.timeMonth.round}
        preRender={this.calcDomain}
        preRedraw={this.calcDomain}
        barPadding={0.2}
        xAxisPadding={1}
        xAxisPaddingUnit={d3.timeMonth}
        elasticY
        yAxisPadding={'20%'}
        {...this.props}
      />
    );
  }
}
