import "../AVIChartCommon.scss";
import "./AVIMapTile.scss";

import React, {
  PropsWithChildren,
  useCallback,
  useRef,
  useState
} from "react";

import { Map } from "ol";
import GeoJSONFormat, { GeoJSONFeature } from "ol/format/GeoJSON";
import WKTFormat from "ol/format/WKT";
import { DimUtils } from "../ChartUtils/DimUtils";
import { GeoJsonUtils } from "../GeoJsonUtils/GeoJsonUtils";
import { useAVIDashboardContext } from "./AVIDashboardDimContext";
import { AVITileHeader } from "./AVITileHeader";
import { AVITileHeaderDropdownItem } from "./AVITileHeaderDropdownItem";

export type AVIMapTileProps = PropsWithChildren & {
  width?: number;
  height?: number;
  title: string;
  info?: string;
  passlineWkt?: string[]|GeoJSONFeature[]; // Array of WKT strings
  getTrackData?: () => any;
  // eslint-disable-next-line react/no-unused-prop-types
  getMap: () => Map | undefined;
  // eslint-disable-next-line react/no-unused-prop-types
  getChart?: () => any;
  useFlex?: boolean;
  allowFullscreen?: boolean;
  downloadable?: boolean;
  backgroundColor?: string;
  color?: string;
};

export function AVIMapTile({
  children,
  width = 1,
  height = 1,
  getMap,
  title,
  info = undefined,
  passlineWkt,
  getTrackData,
  backgroundColor = "#a0a0a0",
  color = "#ffffff",
  getChart = undefined,
  useFlex = false,
  allowFullscreen = false,
  downloadable = true,
}: AVIMapTileProps) {
  const { dims } = useAVIDashboardContext();

  const mapTileRef = useRef<any>();

  const [fullscreenMode, setFullscreenMode] = useState<boolean>(false);
  // }

  const toggleFullscreen = useCallback(
    (currentMode: boolean) => {
      setFullscreenMode(!currentMode);
      setTimeout(() => {
        if (!mapTileRef.current) return;
        let map = getMap();
        if (map && map.updateSize && typeof map.updateSize === "function") {
          map.updateSize();
        }
        mapTileRef.current.scrollIntoView();
      }, 100);
    },
    [getMap]
  );

  const downloadPasslineAsGeoJson = useCallback(() => {
    if (Array.isArray(passlineWkt) && passlineWkt.length > 0) {
      var WTKParser = new WKTFormat();
      var GeoJsonFormat = new GeoJSONFormat();
      var geoJsonFeatures = passlineWkt
        .map((plw) =>
          GeoJsonFormat.writeFeatureObject(
            WTKParser.readFeature(plw, {
              dataProjection: "EPSG:4326",
              featureProjection: "EPSG:4326",
            })
          )
        )
        .map((plw) => {
          plw.properties = { featType: "passline" };
          return plw;
        });

      const geoJsonFeatureColletion =
        GeoJsonUtils.featureCollection(geoJsonFeatures);

      var filename = "passline.geojson";
      var uri = "data:application/json,";
      var link = document.createElement("a");
      link.download = filename;
      link.href = uri + JSON.stringify(geoJsonFeatureColletion);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [passlineWkt]);

  const downloadTracksAsGeoJson = useCallback(() => {
    if (typeof getTrackData !== "function") return;
    var geojson = getTrackData();
    if (geojson) {
      var blob = new Blob([JSON.stringify(geojson)], {
        type: "application/json",
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "tracks.geojson";
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    }
  }, [getTrackData]);

  const getMapStyle = useCallback(() => {
    var hws = DimUtils.getDims(dims, width, height);
    return {
      marginLeft: hws.spacing,
      marginTop: hws.spacing,
    };
  }, [dims, height, width]);

  const toggleDirectionArrows = useCallback(() => {
    if (typeof getChart !== "function") return;
    var chart = getChart();
    if (
      chart &&
      chart.toggleDirectionArrows &&
      typeof chart.toggleDirectionArrows === "function"
    ) {
      chart.toggleDirectionArrows(true);
      chart.draw();
    }
  }, [getChart]);

  const downloadMap = useCallback(() => {
    const map = getMap();
    if (!map) return;
    const mapCanvas = document.createElement("canvas");
    const size = map.getSize();
    if (!size) return;
    mapCanvas.width = size[0];
    mapCanvas.height = size[1];
    const mapContext = mapCanvas.getContext("2d");
    if (!mapContext) return;
    Array.prototype.forEach.call(
      map.getViewport().querySelectorAll(".ol-layer canvas, canvas.ol-layer"),
      function (canvas) {
        if (canvas.width > 0) {
          const opacity =
            canvas.parentNode.style.opacity || canvas.style.opacity;
          mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
          let matrix;
          const transform = canvas.style.transform;
          if (transform) {
            // Get the transform parameters from the style's transform matrix
            matrix = transform
              .match(/^matrix\(([^(]*)\)$/)[1]
              .split(",")
              .map(Number);
          } else {
            matrix = [
              parseFloat(canvas.style.width) / canvas.width,
              0,
              0,
              parseFloat(canvas.style.height) / canvas.height,
              0,
              0,
            ];
          }
          // Apply the transform to the export map context
          CanvasRenderingContext2D.prototype.setTransform.apply(
            mapContext,
            matrix
          );
          const backgroundColor = canvas.parentNode.style.backgroundColor;
          if (backgroundColor) {
            mapContext.fillStyle = backgroundColor;
            mapContext.fillRect(0, 0, canvas.width, canvas.height);
          }
          mapContext.drawImage(canvas, 0, 0);
        }
      }
    );
    mapContext.globalAlpha = 1;
    mapContext.setTransform(1, 0, 0, 1, 0, 0);
    const link = document.createElement("a");
    link.setAttribute("download", "kystdatahuset-map.png");
    link.href = mapCanvas.toDataURL();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [getMap]);

  // let mapTileStyle = {};
  let mapTileStyle: any = getMapStyle();

  if (useFlex) {
    mapTileStyle = {
      flex: width,
      height: `${25 * height}vh`,
    };
  }

  if (fullscreenMode) {
    mapTileStyle = {
      position: "absolute",
      top: "0px",
      left: "0px",
      right: "0px",
      bottom: "0px",
      zIndex: 100000,
    };
  }

  return (
    <div
      className="avi-map-tile d-flex flex-column gap-2 kyv--bg-header flex-grow-1 p-3"
      style={mapTileStyle}
      ref={mapTileRef}
    >
      <AVITileHeader title={title} info={info}>
        {getChart !== null && (
          <AVITileHeaderDropdownItem
            onClick={toggleDirectionArrows}
            className="py-3 border-bottom-1"
          >
            Vis/gjem retningspiler
          </AVITileHeaderDropdownItem>
        )}
        {passlineWkt && (
          <AVITileHeaderDropdownItem
            onClick={downloadPasslineAsGeoJson}
            className="py-2 kyv--text-body border-bottom-1 border-light"
          >
            Last ned passeringslinje (GeoJSON)
          </AVITileHeaderDropdownItem>
        )}
        {getTrackData && (
          <AVITileHeaderDropdownItem
            onClick={downloadTracksAsGeoJson}
            className="py-2 kyv--text-body border-bottom-1 border-light"
          >
            Last ned data (GeoJSON)
          </AVITileHeaderDropdownItem>
        )}
        {allowFullscreen && (
          <AVITileHeaderDropdownItem
            onClick={(evt: any) => toggleFullscreen(fullscreenMode)}
            className="py-2 kyv--text-body border-bottom-1 border-light"
          >
            Vis kart i fullskjerm
          </AVITileHeaderDropdownItem>
        )}
        {downloadable && (
          <AVITileHeaderDropdownItem
            onClick={downloadMap}
            className="py-2 kyv--text-body border-bottom-1 border-light"
          >
            Last ned bilde (*.png)
          </AVITileHeaderDropdownItem>
        )}
      </AVITileHeader>
      <div className="flex-grow-1">{children}</div>
    </div>
  );
}

export default AVIMapTile;
