// import "./CoreLayout.scss";

import PropTypes from "prop-types";
import React from "react";
import HeaderBar from "../ui-components/HeaderBar/HeaderBar";

export class CoreLayout extends React.Component {
  static propTypes = {
    children: PropTypes.any,
  };

  render() {
    const { children } = this.props;
    return (
      <>
        <HeaderBar className="mb-5" />
        {children}
      </>
    );
  }
}

export default CoreLayout;
