import { DataLoader } from "../ui-components/DataLoader/DataLoader";

export default class AuthRequest {
  static makeRequest(urlFragment, params, customHeaders = {}) {
    return DataLoader.post(
      `${window.kyvDashboardConfig.adaptiveUrl}${urlFragment}`,
      params,
      undefined,
      undefined,
      customHeaders,
      true
    );
  }

  static getJwt(username, password) {
    return DataLoader.postApi("/api/auth/login", {
      username,
      password,
    });
  }

  static doLogin(email, password) {
    return AuthRequest.makeRequest("/api/Auth/Authenticate", {
      email,
      password,
    }).then((res) => {
      if (res.success === false) {
        return {
          success: false,
          msg: "Could not log in",
        };
      }
      return Promise.all([
        AuthRequest.getUserDetails(res.session_id),
        AuthRequest.getJwt(email, password),
      ]).then((resp) => {
        return {
          name: resp[0].DisplayName,
          roles: resp[0].roles,
          expiration_time: new Date(res.expiration_time * 1000),
          gm_session_id: res.session_id,
          jwt: resp[1].data.JWT,
        };
      });
    });
  }

  static getUserDetails(gm_session_id) {
    return AuthRequest.makeRequest(
      "/WebServices/generic/Authentication.asmx/GetUserDetails",
      {},
      { gm_session_id: gm_session_id }
    ).then((res) => {
      if (Array.isArray(res.d?.data) && res.d.data.length > 0) {
        var details = res.d.data[0].value;
        details.roles = details.links
          .filter((l) => l.child_type_internal_name === "role")
          .map((r) => r.child_name);
        return details;
      } else {
        return null;
      }
    });
  }

  static doRegister(email, password, firstName, lastName) {
    AuthRequest.makeRequest("/api/Auth/Register", {
      password: password,
      userData: {
        first_name: firstName,
        last_name: lastName,
      },
      email: email,
    }).then((res) => {
      AuthRequest.getJwt(email, password).then((jwt) => {
        // console.log(jwt);
      });
    });
  }

  static doReset(user) {
    return AuthRequest.makeRequest(
      "/WebServices/generic/Authentication.asmx/ResetPass",
      {
        users: [user],
      }
    ).then((res) => {
      return res;
    });
  }
}
