export const ColorMixin = function (chart, props) {
  const { colorAccessor, colors } = props;

  if (typeof colorAccessor === 'function') {
    chart.colorAccessor(colorAccessor);
  }

  if (typeof colors === 'function') {
    chart.colors(colors);
  }

  return chart;
};
