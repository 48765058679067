export class ExportUtils {
  /**
    * Get SVG string from an svg node
    * @param {*} svgNode
    */
  static getSVGString (svgNode) {
    svgNode.setAttribute('xlink', 'http://www.w3.org/1999/xlink');
    var cssStyleText = getCSSStyles(svgNode);
    appendCSS(cssStyleText, svgNode);

    var serializer = new XMLSerializer();
    var svgString = serializer.serializeToString(svgNode);
    svgString = svgString.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink='); // Fix root xlink without namespace
    svgString = svgString.replace(/NS\d+:href/g, 'xlink:href'); // Safari NS namespace fix

    return svgString;

    function getCSSStyles (parentElement) {
      var selectorTextArr = [];

      // Add Parent element Id and Classes to the list
      selectorTextArr.push('#' + parentElement.id);
      for (var c = 0; c < parentElement.classList.length; c++) {
        if (!contains('.' + parentElement.classList[c], selectorTextArr)) { selectorTextArr.push('.' + parentElement.classList[c]); }
      }

      // Add Children element Ids and Classes to the list
      var nodes = parentElement.getElementsByTagName('*');
      for (var i = 0; i < nodes.length; i++) {
        var id = nodes[i].id;
        if (!contains('#' + id, selectorTextArr)) { selectorTextArr.push('#' + id); }

        var classes = nodes[i].classList;
        for (var c2 = 0; c2 < classes.length; c2++) {
          if (!contains('.' + classes[c], selectorTextArr)) { selectorTextArr.push('.' + classes[c2]); }
        }
      }

      // Extract CSS Rules
      var extractedCSSText = '';
      for (var i2 = 0; i2 < document.styleSheets.length; i2++) {
        var s = document.styleSheets[i2];

        try {
          if (!s.cssRules) continue;
        } catch (e) {
          if (e.name !== 'SecurityError') throw e; // for Firefox
          continue;
        }

        var cssRules = s.cssRules;
        for (var r = 0; r < cssRules.length; r++) {
          if (contains(cssRules[r].selectorText, selectorTextArr)) { extractedCSSText += cssRules[r].cssText; }
        }
      }

      return extractedCSSText;

      function contains (str, arr) {
        return arr.indexOf(str) !== -1;
      }
    }

    function appendCSS (cssText, element) {
      var styleElement = document.createElement('style');
      styleElement.setAttribute('type', 'text/css');
      styleElement.innerHTML = cssText;
      var refNode = element.hasChildNodes() ? element.children[0] : null;
      element.insertBefore(styleElement, refNode);
    }
  }

  /**
     * Create image from svg
     * @param {*} svgString
     * @param {*} width
     * @param {*} height
     * @param {*} format
     * @param {*} callback
     */
  static svgString2Image (svgString, width, height, format, callback) {
    format = format || 'png';

    var imgsrc = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString))); // Convert SVG string to data URL

    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;

    var image = new Image();
    image.onload = function () {
      context.fillStyle = 'white';
      context.fillRect(0, 0, width, height);
      //   context.clearRect(0, 0, width, height);
      context.drawImage(image, 0, 0, width, height);

      canvas.toBlob(function (blob) {
        var filesize = Math.round(blob.length / 1024) + ' KB';
        if (callback) callback(blob, filesize);
      });
    };

    image.src = imgsrc;
  }
}
