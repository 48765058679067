/* eslint-disable react/no-unused-prop-types */

import './LocationMapChart.scss';

import * as olControl from 'ol/control';
import * as olInteraction from 'ol/interaction';
import * as olProj from 'ol/proj';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import Map from 'ol/Map';
import olView from 'ol/View';
import { DcExtLocationMapChart } from '../DcExt/DcExtLocationMapChart';
import { AVIMapTile } from '../Layout/AVIMapTile';
import { LayerSwitcherMixin } from '../Mixins/LayerSwitcherMixin';
import { BaseChartType, SimpleBaseMixin } from '../Mixins/SimpleBaseMixin';

export type LocationMapChartProps = {
  geoJson: any,
  geoJsonHighlight?: any,
  chartTitle: string,
  width?: number,
  height: number,
  geoJsonKey?: string | null,
  geoJsonHighlightKey?: string | null,
  useFlex?: boolean,
} & BaseChartType

export function LocationMapChart({
  geoJson,
  geoJsonHighlight,
  chartTitle,
  width,
  height,
  geoJsonKey,
  geoJsonHighlightKey,
  useFlex = false,
  dimension,
  group
}: LocationMapChartProps) {
  const [map, setMap] = useState<Map>();
  const chartRef = useRef<any>();
  const [, setChart] = useState<any>();

  useEffect(() => {
    var zoomPt = [15, 61];

    const map = new Map({
      target: chartRef.current,
      interactions: olInteraction.defaults({ mouseWheelZoom: false }),
      controls: olControl.defaults({
        attributionOptions: {
          collapsible: false
        }
      }),
      view: new olView({
        center: olProj.transform([zoomPt[0], zoomPt[1]], 'EPSG:4326', 'EPSG:3857'),
        zoom: 5
      })
    });

    var chart: any = new DcExtLocationMapChart({
      map: map
    });

    SimpleBaseMixin(chart, {
      group,
      dimension,
    });

    chart.geoJson(geoJson);
    if (geoJsonHighlight !== undefined) {
      chart.geoJsonHighlight(geoJsonHighlight);
    }

    LayerSwitcherMixin(map);
    chart.render();
    setChart(chart);
    setMap(map);

  }, [chartTitle, dimension, geoJson, geoJsonHighlight, geoJsonHighlightKey, geoJsonKey, group, height, useFlex, width]);


  const getMap = useCallback(() => {
    return map!;
  }, [map]);

  return (
    <AVIMapTile
      title={chartTitle}
      height={height}
      width={width}
      getMap={getMap}
      useFlex={useFlex} >
      <div className='avi-map' ref={chartRef} />
    </AVIMapTile>
  );

}