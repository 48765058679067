import '../AVIChartCommon.scss';
import './AVISelectTile.scss';

import React, { Component } from 'react';

import { AVIDashboardDimContext } from './AVIDashboardDimContext';
import { DimUtils } from '../ChartUtils/DimUtils';
import PropTypes from 'prop-types';

export class AVISelectTileBase extends Component {
  static propTypes = {
    getChart: PropTypes.func.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    children: PropTypes.any,
    dims: PropTypes.object,
    title: PropTypes.string,
    useFlex: PropTypes.bool
  };

  static defaultProps = {
    width: 1,
    height: 1,
    bool: false
  };

  constructor (props) {
    super(props);

    this.clearFilter = this.clearFilter.bind(this);
  }

  clearFilter (e) {
    e.stopPropagation();
    const { getChart } = this.props;
    if (getChart().hasFilter()) {
      getChart()
        .filter(null)
        .redrawGroup();
    }
  }

  render () {
    const { children, title, width, height = 1, dims, useFlex } = this.props;

    var hws = DimUtils.getDims(dims, width, height);

    let aviSelectTileStyle = {
      marginLeft: hws.spacing,
      marginTop: hws.spacing
    };

    let aviSelectContainerStyle = {
      width: hws.width
    };

    if (useFlex) {
      aviSelectContainerStyle = {
        width: '100%'
      };
      aviSelectTileStyle = {
        flex: width
      };
    }

    return (
      <div
        className='avi-select-tile p-3 kyv--bg-header flex-grow-1'
        style={aviSelectTileStyle}
      >
        <div className='kyv--fs-body kyv--text-body'>{title}</div>
        <div
          className='avi-select-container'
          style={aviSelectContainerStyle}
        >
          {children}
        </div>
        <div className='avi-clear-both no-print'>
          <button className='avi-chart-action-link' onClick={this.clearFilter}>
            Tilbakestill
          </button>
        </div>
      </div>
    );
  }
}

const AVISelectTile = function (props) {
  return (
    <AVIDashboardDimContext.Consumer>
      {dims => <AVISelectTileBase {...props} dims={dims} />}
    </AVIDashboardDimContext.Consumer>
  );
};

export { AVISelectTile };
