import { Map, View } from "ol";
import { fromLonLat } from "ol/proj";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { LayerSwitcherMixin } from "../../chart-components/Mixins/LayerSwitcherMixin";
import { MapProvider } from "../../context/MapProvider";
import { KyvLayerSwitcher } from "../../ui-components/filter-view/FilterMap/kyv-layer-switcher/KyvLayerSwitcher";
import { isArrayN } from "../../utils/array/isArrayN";

type OlMapProps = PropsWithChildren & {
    bbox: number[]
}

export function OlMap({ bbox, children }: OlMapProps) {

    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<Map>();

    useEffect(() => {
        let _map: Map;
        if (ref.current) {
            _map = new Map({
                target: ref.current,
                view: new View({
                    center: [0, 0],
                    zoom: 2,
                }),
            });
            if (isArrayN(bbox, 4, true)) {
                const [minx, miny, maxx, maxy] = bbox;
                const xy1 = fromLonLat([minx, miny], "EPSG:3857");
                const xy2 = fromLonLat([maxx, maxy], "EPSG:3857");
                _map.getView().fit([xy1[0], xy1[1], xy2[0], xy2[1]]);
            }
            LayerSwitcherMixin(_map);
            setMap(_map);
        }
        return () => {
            if (_map) {
                _map.dispose();
            }
        }
    }, [bbox]);

    return <div ref={ref} style={{ height: "100%", width: "100%", position: "relative" }}>
        {map && <MapProvider map={map}>
            <KyvLayerSwitcher bottom={15} right={15} drop="start" align="start" />
            {children}
        </MapProvider>}
    </div>
}