import "./AVICol.scss";

import React, { Component } from "react";

import PropTypes from "prop-types";

export class AVICol extends Component {
  static propTypes = {
    children: PropTypes.any,
    width: PropTypes.number,
  };

  static defaultProps = {
    width: 1,
  };
  render() {
    const { children, width } = this.props;
    return (
      <div className="avi-col overflow-y-hidden gap-3" style={{ flex: width }}>
        {children}
      </div>
    );
  }
}
