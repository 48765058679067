
import React, { Component } from 'react';

import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import { DataLoader } from '../ui-components/DataLoader/DataLoader';
import { ExecUtils } from '../chart-components/ChartUtils/ExecUtils';

export class FilterSvoOmr extends Component {
    static propTypes = {
      setFilter: PropTypes.func.isRequired,
      filter: PropTypes.object.isRequired,
      isMulti: PropTypes.bool,
      label: PropTypes.string
      // defaultValue: PropTypes.array
    };

    static defaultProps = {
      selectedValue: undefined,
      isMulti: false,
      label: 'Velg et område'
      // defaulValue: undefined
    };

    constructor (props) {
      super(props);
      this.state = {
        'options': []
      };

      this.setValue = this.setValue.bind(this);
      this.filterOptions = this.filterOptions.bind(this);
    }

    componentDidMount () {
      DataLoader.getApi('/api/maps/svoomr/list', {}).then(res => {
        this.setState({
          'options': res.data
        });
      }).catch(err => {
        console.error(err);
      });
    }

    setValue (val) {
      let { filter, setFilter } = this.props;
      setFilter({
        ...filter,
        'svoomr': val
      });
    }

    filterOptions (queryTerm, setOptions) {
      const { options } = this.state;
      var filteredOptions = options
        .filter((o0) => o0.area_name.toLowerCase().indexOf(queryTerm.toLowerCase()) > -1)
        .map((o1, i) => {
          return {
            key: o1.area_id,
            value: o1.area_id,
            label: o1.area_name,
            data: { id: o1.area_id }
          };
        });

      setOptions(filteredOptions);
    }

    render () {
      const { label, isMulti } = this.props;

      return (
        <div className='filter-control'>
          <label>{label}</label>
          <AsyncSelect
            cacheOptions
            placeholder={'Områdenavn'}
            loadingMessage={d => 'Søker'}
            loadOptions={ExecUtils.debounceTwoArgs(this, this.filterOptions, 500)}
            defaultOptions
            // defaultValue={defaultValue}
            isMulti={isMulti}
            onChange={this.setValue}
          />
        </div>
      );
    }
}
