import olGeoJSON, { GeoJSONFeature } from "ol/format/GeoJSON";
import * as olProj from "ol/proj";
import "./FilterList.scss";

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getLocal,
  getSession,
  setSession,
} from "../../../utils/localStorageCache";

import { Feature, Map, View } from "ol";
import { defaults } from "ol/control";
import VectorLayer from "ol/layer/Vector";
import Cluster from "ol/source/Cluster";
import VectorSource from "ol/source/Vector";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { DbrdUtils } from "../../../chart-components/ChartUtils/DbrdUtils";
import { LayerSwitcherMixin } from "../../../chart-components/Mixins/LayerSwitcherMixin";
import { DashboardList } from "../../../config/dashboards";
import { useDataContext } from "../../../context/DataContext";
import { Dashboard } from "../../../types/Dashboard";
import { DashboardFilter } from "../../../types/DashboardFilter";
import { Municipality } from "../../../types/Municipality";
import { Passline } from "../../../types/Passline";
import {
  locationsStyleFunction,
  passlinesStyleFunction,
  selectionStyle,
} from "../../../utils/mapStyles";
import FilterItem from "../FilterItem/FilterItem";
import { KyvLayerSwitcher } from "../FilterMap/kyv-layer-switcher/KyvLayerSwitcher";
import { SsrSearchBar } from "../FilterMap/SsrSearchBar";
import { MapProvider } from "../../../context/MapProvider";
import { Geometry } from "ol/geom";

export type SelectionLayerName = "passlines" | "locationIds" | "locations";

export type MapSelectionType =
  | "selectPoint"
  | "selectPolygon"
  | "selectBox"
  | "overlayPolygon";

export type MapDrawingType =
  | "drawPoint"
  | "drawLineString"
  | "drawPolygon"
  | "drawBox";

export type MapInteractionType = MapSelectionType | MapDrawingType;

export type FilterListProps = {
  match: any;
};

export type FilterContextType = {
  filter: DashboardFilter;
  setFilter: (flt: DashboardFilter) => void;
  numGeom: number;
  map: Map | undefined;
  drawingLayer: VectorLayer<Feature<Geometry>>;
  passlinesLayer: VectorLayer<Feature<Geometry>>;
  locationsLayer: VectorLayer<Feature<Geometry>>;
  selectionLayer: SelectionLayerName | null;
  interactionType?: MapInteractionType;
};

const FilterContext = createContext<FilterContextType | undefined>(undefined);

type FilterListStateType = {
  dashboard?: string;
  dashboards: Dashboard[] | null;
  passlines: Passline[] | null;
  locations: Location[] | null;
  municipalities?: Municipality[] | null;
  interactionType?: MapInteractionType;
  selectionLayer?: SelectionLayerName | null;
  featureProperty: string | null;
  filterKey: null;
  numGeom: number;
  selectionGeometry?: GeoJSONFeature;
};

export function FilterList({ match }: FilterListProps) {
  const navigate = useNavigate();
  const params = useParams();
  const { dashboard } = params;
  const [numGeom, setNumGeom] = useState<number>(1);
  const { dashboards, passlines, locations } = useDataContext();

  const [filter, _setFilter] = useState<DashboardFilter>(
    getSession<DashboardFilter>("filter", 180) || {}
  );

  const projParams = useMemo(
    () => ({
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    }),
    []
  );

  const geoJsonFmt = useMemo(
    () =>
      new olGeoJSON({
        ...projParams,
      }),
    [projParams]
  );

  // const wktFmt = useMemo(() => new olWKT(), []);

  // Get selection layer
  const selectionLayer = useMemo(() => {
    // Set selection layer to null *not* undefined.
    let selectionLayer: SelectionLayerName | null = null;

    let dbrdClass =
      dashboard && Array.isArray(DashboardList[dashboard])
        ? DashboardList[dashboard][0]
        : null;
    if (DbrdUtils.isDashboard(dbrdClass)) {
      selectionLayer = dbrdClass.dashboardSettings().selectableLayer;
      if (dbrdClass.dashboardSettings().hasOwnProperty("numGeom")) {
        setNumGeom(dbrdClass.dashboardSettings().numGeom);
      }
    }
    return selectionLayer;
  }, [dashboard]);

  const [state, setState] = useState<FilterListStateType>({
    dashboard: dashboard,
    dashboards: getLocal("dashboards", 1), // TODO set this to lower value
    passlines: getLocal("passlines", 1),
    locations: getLocal("locations", 720),
    municipalities: getLocal("municipalities", 720),
    interactionType: undefined,
    selectionLayer: selectionLayer,
    featureProperty: null,
    filterKey: null,
    numGeom: numGeom,
    selectionGeometry: undefined,
  });

  const [drawingLayer, passlinesLayer, locationsLayer] = useMemo(() => {
    const _drawingVectorSource = new VectorSource();
    const _drawingVectorLayer = new VectorLayer({
      properties: { title: "Selection" },
      visible: true,
      source: _drawingVectorSource,
      zIndex: 1900,
      style: () => {
        return selectionStyle;
      },
    });
    const _passlinesVectorSource = new VectorSource();
    const _passlinesVectorLayer = new VectorLayer({
      properties: { title: "Passlines" },
      visible: false,
      source: _passlinesVectorSource,
      zIndex: 1000,
      style: passlinesStyleFunction,
    });
    const _locationsVectorSource = new VectorSource();
    const _locationsVectorLayer = new VectorLayer({
      properties: { title: "Locations" },
      visible: false,
      source: _locationsVectorSource,
      // source: new Cluster({
      //   distance: 25,
      //   source: _locationsVectorSource,
      // }),
      zIndex: 1100,
      style: locationsStyleFunction,
      declutter: true,
    });
    return [_drawingVectorLayer, _passlinesVectorLayer, _locationsVectorLayer];
  }, []);

  const resetFilter = useCallback(() => {
    setSession<DashboardFilter>("filter", {});
    _setFilter({});
  }, []);

  const onDashboardChange = useCallback(
    (selection: any, id: any) => {
      if (dashboard === selection.uri) {
        setState((state: any) => ({
          ...state,
          dashboard: null,
          key: null,
          features: [],
          interactionType: null,
          selectionLayer: null,
        }));
        resetFilter();
        navigate("/tallogstatistikk");
      } else {
        let dbrdClass = DashboardList[selection.uri][0];
        let selectionLayer: SelectionLayerName | null = null;
        let numGeom = 1;

        if (DbrdUtils.isDashboard(dbrdClass)) {
          selectionLayer = dbrdClass.dashboardSettings().selectableLayer;
          if (dbrdClass.dashboardSettings().hasOwnProperty("numGeom")) {
            numGeom = dbrdClass.dashboardSettings().numGeom;
          }
        }

        setState((state: any) => ({
          ...state,
          dashboard: selection.uri,
          key: id,
          features: [],
          interactionType: undefined,
          selectionLayer: selectionLayer || null, // Must be null
          filter: {},
          numGeom: numGeom,
        }));

        resetFilter();
        navigate(`/tallogstatistikk/${selection.uri}/filter`);
      }
    },
    [dashboard, resetFilter, navigate]
  );

  const setFilter = useCallback(
    (updatedFilter: DashboardFilter, clearFilter: boolean = false) => {
      _setFilter((currentFilter: DashboardFilter) => {
        let fusedFilter: DashboardFilter;
        if (clearFilter) {
          fusedFilter = { ...updatedFilter };
        } else {
          fusedFilter = { ...currentFilter, ...updatedFilter };
        }
        setSession<DashboardFilter>("filter", fusedFilter);
        return fusedFilter;
      });
    },
    []
  );

  const setMapInteraction = useCallback(
    (
      interactionType: string,
      selectionLayer: SelectionLayerName | null = null,
      selectionGeometry: any | null = null,
      numGeom: number = 1,
      sticky: boolean = false
    ) => {
      setState((state: any) => ({
        ...state,
        interactionType,
        selectionLayer,
        selectionGeometry,
        numGeom,
        sticky,
        passline: undefined,
      }));
    },
    []
  );

  const { interactionType } = state;

  const mapRef = useRef<HTMLDivElement>(null);

  const [map, setMap] = useState<Map | undefined>();

  const populateLayers = useCallback(() => {
    const locationsSource = locationsLayer.getSource();
    if (locationsSource instanceof Cluster) {
      const locationsInnerSource = locationsSource.getSource();
      if (locationsInnerSource) {
        if (
          Array.isArray(locations) &&
          locationsInnerSource.getFeatures().length !== locations.length
        ) {
          locationsInnerSource.clear();
          locationsInnerSource.addFeatures(
            locations.map((location) => geoJsonFmt.readFeature(location))
          );
        }
      }
    } else if (locationsSource instanceof VectorSource) {

      if (
        Array.isArray(locations) &&
        locationsSource.getFeatures().length !== locations.length
      ) {
        locationsSource.clear();
        locationsSource.addFeatures(
          locations.map((location) => geoJsonFmt.readFeature(location))
        );
      }
    }

    if (
      Array.isArray(passlines) &&
      passlinesLayer.getSource()?.getFeatures().length !== passlines.length
    ) {
      passlinesLayer.getSource()?.clear();
      passlinesLayer
        .getSource()
        ?.addFeatures(passlines.map((p) => geoJsonFmt.readFeature(p)));
    }
  }, [geoJsonFmt, locations, locationsLayer, passlines, passlinesLayer]);

  useEffect(() => {
    if (!mapRef.current) return;

    var zoomPt = [15, 61];

    let _map = new Map({
      pixelRatio: 1,
      target: mapRef.current,
      controls: defaults({
        attributionOptions: {
          collapsible: false,
        },
      }),
      view: new View({
        center: olProj.transform(
          [zoomPt[0], zoomPt[1]],
          "EPSG:4326",
          "EPSG:3857"
        ),
        zoom: 4,
      }),
    });

    _map.addLayer(drawingLayer);
    _map.addLayer(passlinesLayer);
    _map.addLayer(locationsLayer);
    LayerSwitcherMixin(_map);
    populateLayers();
    setMap(_map);

    return () => {};
  }, [drawingLayer, locationsLayer, passlinesLayer, populateLayers]);

  return (
    <FilterContext.Provider
      value={{
        filter,
        setFilter,
        numGeom,
        map,
        drawingLayer,
        passlinesLayer,
        locationsLayer,
        selectionLayer,
        interactionType,
      }}
    >
      <div className="d-flex gap-1 h-100 w-100">
        <div className="filter--map-container h-100">
          <div className="filter--map" ref={mapRef}>
            {map && (
              <MapProvider map={map}>
                <KyvLayerSwitcher bottom="10px" right="10px" />
                <SsrSearchBar />
              </MapProvider>
            )}
          </div>
        </div>
        <div className="filter--container">
          <div className="filter--content">
            <Accordion
              className="dashboard-list"
              defaultActiveKey={params.dashboard}
              flush
            >
              {Array.isArray(dashboards) &&
                dashboards.map((currDbrd, currIdx) => {
                  return (
                    <AccordionItem
                      eventKey={`${currDbrd.uri}`}
                      className="dashboard-item"
                      key={`item-${currIdx}`}
                    >
                      <AccordionHeader
                        onClick={() => onDashboardChange(currDbrd, currIdx)}
                      >
                        {currDbrd.title}
                      </AccordionHeader>
                      {dashboard && dashboard === currDbrd.uri && (
                        <AccordionBody>
                          <FilterItem
                            dashboard={dashboard}
                            numGeom={numGeom}
                            interactionType={interactionType}
                            filter={filter}
                            setFilter={setFilter}
                            setMapInteraction={setMapInteraction}
                          />
                        </AccordionBody>
                      )}
                    </AccordionItem>
                  );
                })}
            </Accordion>
          </div>
        </div>
      </div>
    </FilterContext.Provider>
  );
}

export default FilterList;

export function useFilterContext() {
  const ctx = useContext(FilterContext);
  if (!ctx)
    throw new Error(
      "useFilterContext can only be used within a FilterList element"
    );
  return ctx;
}
