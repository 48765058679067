import React from "react";
import "./GdprView.scss";
import { WPLoader } from "./../../ui-components/WPLoader/WPLoader";
import { Loader } from "./../../ui-components/Loader/Loader";
import withRouter from "../../hocs/withRouter";

class GdprViewBase extends React.Component {
  constructor() {
    super();
    this.state = {
      article: null,
    };
  }
  componentDidMount() {
    this.loadArticle();
  }

  loadArticle() {
    WPLoader.LoadArticleBySlug("gdpr", "pages").then((res) => {
      if (Array.isArray(res.body) && res.body.length === 1) {
        this.setState({
          article: res.body[0],
        });
      }
    });
  }

  render() {
    const { article } = this.state;
    return (
      <div>
        {article === null && <Loader hideMessage />}
        {
          article !== null && (
            // <div className='gdpr-view--container'>
            //   <div>
            <>
              <h1>{article.title.rendered}</h1>
              <div
                dangerouslySetInnerHTML={{ __html: article.content.rendered }}
              />
            </>
          )
          //   </div>
          // </div>
        }
      </div>
    );
  }
}

export const GdprView = withRouter(GdprViewBase);

export default GdprView;
