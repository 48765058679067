import * as d3 from 'd3';

import Circle from 'ol/style/Circle';
import { DcExtBase } from './DcExtBase';
import Feature from 'ol/Feature';
import Fill from 'ol/style/Fill';
import Overlay from 'ol/Overlay';
import Point from 'ol/geom/Point';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import * as dc from 'dc';

export class DcExtPointDimMapChart {
  _map = null;
  _vectorLayer = null;
  _vectorSource = null;
  _minSize = 3;
  _maxSize = 20;
  _min = null;
  _max = null;
  _symbolSize = null;
  _transparency = null;
  _first = true;

  constructor (props) {
    const { map } = props;

    if (map === undefined) {
      throw new Error('Mandatory property map missing');
    }

    this._map = map;

    this.getStyle = this.getStyle.bind(this);
    this.zeroToOne = this.zeroToOne.bind(this);

    DcExtBase(this);

    dc.registerChart(this);
  }

  scale (value) {
    if (typeof this._symbolSize === 'function') {
      return this._symbolSize(value);
    } else {
      return value;
    }
  }

  zeroToOne (value) {
    if (typeof this._transparency === 'function') {
      return this._transparency(value);
    } else {
      return value;
    }
  }

  getStyle (feature, resolution) {
    var props = feature.getProperties();
    var size = this.scale(props.value);
    var transparency = this.zeroToOne(props.value);
    var strokeColor = d3.interpolateReds(transparency);
    var fillColor = strokeColor.replace('rgb(', 'rgba(').replace(')', ', 0.75)');
    return new Style({
      image: new Circle({
        radius: size,
        fill: new Fill({ color: fillColor }),
        stroke: new Stroke({ color: strokeColor, width: 1 })
      })
    });
  }

  getFilteredFeatures () {
    // Initialize min/max to impossible values
    this._min = Infinity;
    this._max = 0;

    // Enable access to this context within array functions filter/map
    var that = this;

    // Filter the features
    var filteredFeatures = this.group()
      .all()
      .filter(pointGroup => {
        // Remove empty groups
        return pointGroup.value > 0;
      })
      .map(pointGroup => {
        that._min = pointGroup.value < that._min ? pointGroup.value : that._min;
        that._max = pointGroup.value > that._max ? pointGroup.value : that._max;
        // Make points from non-empty groups
        var pt = new Point([pointGroup.key[0], pointGroup.key[1]]);
        pt.transform('EPSG:4326', 'EPSG:3857');
        return new Feature({
          geometry: pt,
          name: pointGroup.key[2],
          value: pointGroup.value
        });
      });

    // Size scale
    this._symbolSize = d3
      .scaleLinear()
      .domain([this._min, this._max])
      .range([this._minSize, this._maxSize]);

    // Transparency scale
    this._transparency = d3
      .scaleLinear()
      .domain([this._min, this._max])
      .range([0.25, 1.0])
      .clamp(true);

    // Return the filtered features
    return filteredFeatures;
  }

  draw () {
    // Get filtered features
    var filteredFeatures = this.getFilteredFeatures();

    // Setup layers if first load
    if (this._vectorSource == null) {
      var tooltip = document.createElement('div');

      var overlay = new Overlay({
        element: tooltip,
        offset: [10, 0],
        positioning: 'bottom-left'
      });

      this._map.addOverlay(overlay);

      var displayTooltip = evt => {
        var pixel = evt.pixel;
        var feature = this._map.forEachFeatureAtPixel(pixel, function (feature) {
          return feature;
        });
        tooltip.style.display = feature ? '' : 'none';
        if (feature) {
          overlay.setPosition(evt.coordinate);
          tooltip.innerHTML = feature.get('name') + ': ' + feature.get('value');
        }
      };

      this._map.on('pointermove', displayTooltip);

      this._vectorSource = new VectorSource({
        features: filteredFeatures
      });

      var vectorLayer = new VectorLayer({
        source: this._vectorSource,
        style: this.getStyle
      });

      vectorLayer.setZIndex(10000);
      this._map.addLayer(vectorLayer);
    } else {
      // Reload vector features if subsequent load
      this._vectorSource.clear();
      this._vectorSource.addFeatures(filteredFeatures);
    }

    // Zoom to extent if first-timeload
    if (this.first === true) {
      this._map.getView().fit(this._vectorSource.getExtent(), this._map.getSize());
      this.false = false;
    }
  }
}
