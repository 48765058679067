import React, { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { Badge, Button, FormControl, FormGroup, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { WebServiceResponse } from "../types/WebServiceResponse";
import { DataLoader } from "../ui-components/DataLoader/DataLoader";
type AiAssistantProps = {
    dashboards: any[]
}

type Dashboard = {
    id: string;
    name: string;
    description: string;
}

type DashboardQueryRequest = {
    userQuery: string;
    dashboards: Dashboard[];
}

type DashboardQueryResponse = {
    message: string,
    dashboardIds: string[]
}

function stripHtml(html: string): string {
    // Create a new DOM element
    const tempDiv = document.createElement("div");

    // Set the HTML content to the div element
    tempDiv.innerHTML = html;

    // Retrieve the text content (strips the HTML tags)
    return tempDiv.textContent || tempDiv.innerText || "";
}
type MessageBubbleProps = PropsWithChildren & {
    type?: "incoming" | "outgoing",
}

export function MessageBubble({ children, type = "incoming" }: MessageBubbleProps) {

    const extraClasses = useMemo(() => {
        switch (type) {
            case "outgoing":
                return "rounded-start-5 rounded-bottom-5 ms-5";
            case "incoming":
            default:
                return "rounded-end-5 rounded-bottom-5 me-5";
        }

    }, [type]);

    return (<div className={`d-flex gap-2 align-items-start p-3 bg-white border mb-2 ${extraClasses}`}>
        {type === "incoming" && <div className="me-auto"><i className="bi bi-chat-left"></i></div>}
        <div>
            {children}
        </div>
        {type === "outgoing" && <div className="ms-auto"><i className="bi bi-chat-right"></i></div>}
    </div>
    );
}

export function AiAssistant({ dashboards }: AiAssistantProps) {

    const [query, setQuery] = useState<string>("");
    const [response, setResponse] = useState<DashboardQueryResponse>();
    const [loading, setLoading] = useState<boolean>(false);
    const [userMessage, setUserMessage] = useState<string>("");

    const data: Dashboard[] = useMemo(() => {
        return dashboards.map(d => ({
            id: d.uri,
            name: d.title,
            description: stripHtml(d.content)
        }))

    }, [dashboards]);

    const reset = useCallback(() => {
        setUserMessage("");
        setQuery("");
        setResponse(undefined);
    }, []);

    const chat = useCallback(async (query: string, dashboardInfo: Dashboard[]) => {
        try {
            setLoading(true);
            setQuery(query);
            const params: DashboardQueryRequest = {
                userQuery: query,
                dashboards: data
            }
            const res = await DataLoader.postApi<WebServiceResponse<DashboardQueryResponse>>("/api/ai/dashboards", params);
            setResponse(res.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    }, [data]);

    return <FormGroup className="mb-3">
        <div className="d-flex gap-2 w-100 mb-2 align-items-start">
            <h3>Hvordan kan vi hjelpe deg? </h3>
            <Badge className="ms-auto" bg="info" text="white">Beta</Badge>
        </div>
        {query && <MessageBubble type="outgoing">
            {query}
        </MessageBubble>}
        {response && <>
            <MessageBubble type="incoming">
                <p>{response.message}</p>
                <p>Lenker til relevante dashboard</p>
                <ul>

                    {dashboards.filter(d => response.dashboardIds.indexOf(d.uri) > -1)
                        .map(d => <li key={`li-element-${d.title}`}>
                            <Link to={`tallogstatistikk/${d.uri}/filter`}>
                                {d.title}
                            </Link>
                        </li>)}
                </ul>
            </MessageBubble>
        </>}
        <div className="rounded 2 p-3 d-flex w-100 bg-white border align-items-end">
            <FormControl
                as="textarea"
                placeholder="Hva er du interessert i å vite? Her kan du stille spørsmål i vanlig språk"
                disabled={loading}
                className="border-0 w-100"
                value={userMessage}
                onChange={(evt) => setUserMessage(evt.target.value)} />
            <Button variant="link" disabled={loading || !userMessage} className="ms-auto" size="lg" onClick={() => reset()}><i className="bi bi-x-lg"></i></Button>
            <Button variant="link" disabled={loading || !userMessage} className="ms-auto" size="lg" onClick={() => chat(userMessage, data)}><i className="bi bi-send"></i></Button>
        </div>
        {loading && <div><Spinner /></div>}
    </FormGroup>
}