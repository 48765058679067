import "./ArticleGrid.scss";

import React from "react";
import {
  Card,
  CardBody,
  CardImg,
  CardLink,
  CardText,
  CardTitle,
  Container,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { WPLoader } from "../../WPLoader/WPLoader";

class ArticleGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = { wParticles: [] };
  }

  loadArticles() {
    WPLoader.LoadArticlesByCategoryIds([2]).then((res) => {
      this.setState({
        wpArticles: res.body,
      });
    });
  }

  componentDidMount() {
    this.loadArticles();
  }

  componentDidUpdate(prevProps) {}

  render() {
    const { wpArticles } = this.state;

    return (
      <Container className="mb-5">
        <div className="mb-4">
          <h3>Informasjon og rettledning</h3>
        </div>
        <div className="d-flex gap-5">
          {Array.isArray(wpArticles) &&
            wpArticles
              .sort((a, b) => new Date(b) > new Date(a))
              .map((wpa, wpaIdx) => (
                <Card
                  key={`wp-articles-${wpaIdx}`}
                  // className="articles--article"
                >
                  {Array.isArray(wpa._embedded["wp:featuredmedia"]) &&
                    wpa._embedded["wp:featuredmedia"].length > 0 && (
                      <CardImg
                        variant="top"
                        alt={wpa.title.rendered}
                        src={
                          wpa._embedded["wp:featuredmedia"][0].media_details
                            .sizes.medium.source_url
                        }
                      />
                    )}
                  <CardBody>
                    <CardTitle>{wpa.title.rendered}</CardTitle>
                    <CardText
                      dangerouslySetInnerHTML={{
                        __html: wpa.excerpt.rendered.slice(0, 200),
                      }}
                    ></CardText>
                    <div className="d-flex gap-3">
                      <CardLink
                        className="ms-auto"
                        as={Link}
                        to={`/artikkel/${wpa.slug}`}
                      >
                        Les mer
                      </CardLink>
                    </div>
                  </CardBody>
                </Card>
              ))}
        </div>
      </Container>
    );
  }
}

export default ArticleGrid;
