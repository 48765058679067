import React, { Component } from "react";

import Dropdown from "../filter-components/DropDown/Dropdown";
import { FilterCodelists } from "../filter-components/FilterCodelists";
import PropTypes from "prop-types";
import { DataLoader } from "./../ui-components/DataLoader/DataLoader";

export class FilterAnchorage extends Component {
  static propTypes = {
    firstYear: PropTypes.number.isRequired,
    setFilter: PropTypes.func.isRequired,
    filter: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      filter: props.filter,
    };
  }

  componentDidMount() {
    DataLoader.getApi("/api/anchorage/areas", {}, this.reportProgress).then(
      (res) => {
        if (res.success) {
          this.setState({
            options: res.data,
          });
        }
      }
    );
  }

  update(key, val) {
    const { filter, setFilter } = this.props;

    filter[key] = val;

    if (filter.fromYear && !filter.toYear) {
      filter.toYear = filter.fromYear;
    } else if (!filter.fromYear && filter.toYear) {
      filter.toYear = undefined;
    }

    if (filter.fromMonth && !filter.toMonth) {
      filter.toMonth = filter.fromMonth;
    } else if (!filter.fromMonth && filter.toMonth) {
      filter.toMonth = undefined;
    }

    setFilter({
      anchorageLocations: filter.anchorageLocations,
      fromYear: filter.fromYear,
      toYear: filter.toYear,
      fromMonth: filter.fromMonth,
      toMonth: filter.toMonth,
    });
  }

  render() {
    const { filter, firstYear } = this.props;
    const { options } = this.state;

    if (!options) return null;

    return (
      <div key="B45">
        <Dropdown
          key="A0"
          label="Ankringslokalitet"
          name="anchorageLocations"
          items={options.map((o) => {
            return { value: o.id, label: o.label };
          })}
          placeholder="Ankringslokalitet"
          onSelect={(di) => {
            this.update(
              "anchorageLocations",
              di.map((d) => d.value)
            );
          }}
          onClear={() => this.update("anchorageLocations", [])}
          value={
            Array.isArray(filter.anchorageLocations)
              ? filter.anchorageLocations.map((d) => {
                  return { label: d, value: d };
                })
              : []
          }
          searchable
          isMulti
          isOptionDisabled={(item) => {
            if (this.props.filter.toYear) {
              return item.value > this.props.filter.toYear;
            }
            return false;
          }}
        />
        <Dropdown
          key="A1"
          label="Fra år"
          name="fromYear"
          items={FilterCodelists.Years(firstYear)}
          placeholder="Fra år"
          onSelect={(di) => this.update("fromYear", di.value)}
          onClear={() => this.update("fromYear", undefined)}
          value={filter.fromYear}
          searchable={false}
          isOptionDisabled={(item) => {
            if (this.props.filter.toYear) {
              return item.value > this.props.filter.toYear;
            }
            return false;
          }}
        />
        <Dropdown
          key="2"
          label="Fra måned"
          name="fromMonth"
          placeholder="Fra måned"
          items={FilterCodelists.MonthsInYear()}
          onSelect={(di) => this.update("fromMonth", di.value)}
          onClear={() => this.update("fromMonth", undefined)}
          value={filter.fromMonth}
          searchable={false}
          isOptionDisabled={(item) => {
            return (
              item.value > this.props.filter.toMonth &&
              this.props.filter.toYear === this.props.filter.fromYear
            );
          }}
        />
        <Dropdown
          key="A4"
          label="Til år"
          name="toYear"
          placeholder="Til år"
          items={FilterCodelists.Years(firstYear)}
          onSelect={(di) => this.update("toYear", di.value)}
          onClear={() => this.update("toYear", filter.fromYear)}
          searchable={false}
          value={filter.toYear}
          isOptionDisabled={(item) => {
            if (this.props.filter.fromYear) {
              return item.value < this.props.filter.fromYear;
            }
            return false;
          }}
        />
        <Dropdown
          key="5"
          label="Til måned"
          placeholder="Velg måned"
          items={FilterCodelists.MonthsInYear()}
          onSelect={(di) => this.update("toMonth", di.value)}
          onClear={() => this.update("toMonth", undefined)}
          name="toMonth"
          searchable={false}
          value={filter.toMonth}
          isOptionDisabled={(item) => {
            return (
              item.value < this.props.filter.fromMonth &&
              this.props.filter.toYear === this.props.filter.fromYear
            );
          }}
        />
      </div>
    );
  }
}
