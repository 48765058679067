import "./MapLayout.scss";

import HeaderBar from "../ui-components/HeaderBar/HeaderBar";
import PropTypes from "prop-types";
import React from "react";

class MapLayout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div className="d-flex flex-column w-100 h-100 p-0 m-0">
        <HeaderBar />
        <div className="flex-grow-0 w-100 h-100 overflow-none">{children}</div>
      </div>
    );
  }
}

MapLayout.propTypes = {
  children: PropTypes.node,
};

export default MapLayout;
