import "./FilterItem.scss";

import React, { useCallback, useEffect, useMemo } from "react";

import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { DbrdUtils } from "../../../chart-components/ChartUtils/DbrdUtils";
import { DashboardList } from "../../../config/dashboards";
import { useDataContext } from "../../../context/DataContext";
import { DashboardFilter } from "../../../types/DashboardFilter";
import {
  getLocalPrefixed,
  setLocalPrefixed,
} from "../../../utils/localStorageCache";
import { useFilterContext } from "../FilterList/FilterList";

export type SetMapInteractionFunctionType = (
  interactionType: string,
  selectionLayer?: any | null,
  selectionGeometry?: any[] | null,
  numGeom?: number,
  sticky?: boolean
) => void;

export type FilterItemProps = {
  dashboard: string;
  filter: DashboardFilter;
  // passlines: any[];
  // municipalities: any[];
  // locations: any[];
  setFilter: (flt: DashboardFilter) => void;
  setMapInteraction: SetMapInteractionFunctionType;
  interactionType?: string;
  numGeom?: number;
};

export function FilterItem({
  dashboard,
  setMapInteraction,
  interactionType,
}: FilterItemProps) {
  const { passlines, lastUpdatedMessage } = useDataContext();
  const { filter, setFilter } = useFilterContext();
  const { map } = useFilterContext();

  const navigate = useNavigate();

  const [, dbrdClass] = useMemo(() => {
    var dbrdSettings: any = null;
    var dbrdClass: any = (DashboardList as any)[dashboard][0];
    if (DbrdUtils.isDashboard(dbrdClass)) {
      dbrdSettings = dbrdClass.dashboardSettings();
    }
    return [dbrdSettings, dbrdClass];
  }, [dashboard]);

  const loadDashboard = useCallback(() => {
    // const { dashboard, filter, navigate } = this.props;
    var dbrdRoute = `/tallogstatistikk/${dashboard}/dashboard`;
    setLocalPrefixed(dashboard, "filter", filter);

    if (map) {
      const pt = map.getView().getCenter();
      const z = map.getView().getZoom();
      if (pt && z) {
        setLocalPrefixed(dashboard, "centerZoom", [pt[0], pt[1], z]);
      }
    }
    
    navigate(dbrdRoute, {
      state: filter,
    });
  }, [dashboard, filter, map, navigate]);

  const isValid = useCallback(() => {
    let dbrdClass = DashboardList[dashboard][0];
    if (DbrdUtils.isDashboard(dbrdClass)) {
      return dbrdClass.dashboardValidation(filter);
    } else {
      console.debug(
        `There is still a dashboard (${dashboard}) that does not have embedded validation, will never validate`
      );
      return false;
    }
  }, [dashboard, filter]);

  const [controls, helpMessage] = useMemo(() => {
    if (!DbrdUtils.isDashboard(dbrdClass)) return [null, null];
    var dbrdFilters = dbrdClass.dashboardFilters(
      filter,
      setFilter,
      passlines,
      (a: any) => {
        console.debug("Calling redundant function", a);
      }
    );
    return [dbrdFilters.controls, dbrdFilters.helpMessage];
  }, [dbrdClass, filter, setFilter, passlines]);

  useEffect(() => {
    if (!map) return;
    const cachedFilter = getLocalPrefixed<DashboardFilter>(dashboard, "filter");
    const centerZoom = getLocalPrefixed<number[]>(dashboard, "centerZoom");

    if (cachedFilter) {
      setFilter(cachedFilter);
    }

    if (centerZoom) {
      map.getView().setCenter([centerZoom[0], centerZoom[1]]);
      map.getView().setZoom(centerZoom[2]);
    }

    return () => {};
  }, [dashboard, map, setFilter]);

  if (!Array.isArray(passlines)) {
    return <div>Loading</div>;
  }

  if (!DbrdUtils.isDashboard(dbrdClass)) {
    console.error(
      `The dashboard ${
        dbrdClass && dbrdClass.name
      } does not render filters based on embedded method`
    );
  }

  return (
    <>
      <p className="text-gray small">{helpMessage}</p>
      <p className="text-gray small">{lastUpdatedMessage}</p>
      <div>{controls}</div>
      {/* <div>{renderButtons()}</div> */}
      <div className="d-grid">
        <Button
          variant={isValid() ? "success" : "dark"}
          className="text-light d-block mt-3"
          disabled={!isValid()}
          onClick={loadDashboard as any}
        >
          Åpne dashboard
        </Button>
      </div>
    </>
  );
}

export default FilterItem;
