import { useEffect } from "react";
import { useFilterContext } from "../FilterList/FilterList";

export function ClearDrawingLayer() {
  const { drawingLayer } = useFilterContext();

  useEffect(() => {
    drawingLayer.getSource()?.clear();
    return () => {};
  }, [drawingLayer]);

  return null;
}
