import "./DbrdWaveImpact.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";

import crossfilter from "crossfilter2";
// import { useLocation } from "react-router-dom";
import { bbox } from "@turf/turf";
import { BBox, LineString } from "geojson";
import { GeoJSONFeatureCollection } from "ol/format/GeoJSON";
import { Badge } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { OlMap } from "../../components/map/OlMap";
import { OlMapWaveLayer } from "../../components/map/OlMapWaveLayer";
import { OlMapGeoJsonLayer } from "../../components/map/OlMapGeoJsonLayer";
import { SimpleTimespan } from "../../filters/SimpleDateTimeControl/SimpleTimespan";
import withRouter from "../../hocs/withRouter";
import { AisPos, AisPosArray } from "../../types/Ais";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../types/DashboardFilter";
import { WebServiceResponse } from "../../types/WebServiceResponse";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { DrawBoxInMap } from "../../ui-components/filter-view/FilterMap/DrawBoxInMap";
import { Loader, SuperagentProgress } from "../../ui-components/Loader/Loader";
import { ArrayIndex, indexArray } from "../../utils/array/indexArray";
import { isArrayN } from "../../utils/array/isArrayN";
import { uniqueArrayValues } from "../../utils/array/uniqueArrayValues";
import { OlPanel } from "../../components/map/OlPanel";
import { OlMapWaveParamsPanel } from "../../components/map/OlMapWaveParamsPanel";
import { Stroke, Style } from "ol/style";

interface DashboardType extends React.FC {
  dashboardRoute?: string;
  dashboardValidation?: (filter: DashboardFilter) => boolean;
  dashboardSettings?: () => any;
  dashboardFilters?: (
    filter: DashboardFilter,
    setFilter: SetDashboardFilterFunc
  ) => any;
}

export type ShipTypeAis = {
  "id": number,
  "year": number,
  "mmsi": number,
  "imo": number,
  "callsign": string,
  "ship_name": string,
  "ship_type": number,
  "ais_class": string,
  "length": number,
  "breadth": number,
  "is_public": boolean,
}

export type WaveCalcParams = {
  length: number,
  breadth: number,
  speed: number,
  empiricalConstant: number,
  falloff: number,
}

// const CONST_EXT = [10.50300, 59.65524, 10.64729, 59.74789];

export function DbrdWaveImpactLocal() {

  // const [chartData, setChartData] = useState<crossfilter.Crossfilter<AisPos>>();
  const [progressData, setProgress] = useState<SuperagentProgress>();
  const { state = {} } = useLocation();
  const [data, setData] = useState<AisPos[]>([]);
  const [coastline, setCoastline] = useState<GeoJSONFeatureCollection>();

  const [shipIndex, setShipIndex] = useState<ArrayIndex<ShipTypeAis>>({});

  const [selectedMmsi, setSelectedMmsi] = useState<number>();
  const [mmsis, setMmsis] = useState<number[]>([]);
  const [length, setLength] = useState<number>(100);
  const [breadth, setBreadth] = useState<number>(10);
  const [waveHeigthUnderCursor, setWaveHeightUnderCursor] = useState<number>(0);

  const [waveCalcParams, setWaveCalcParams] = useState<WaveCalcParams>({
    length: 100,
    breadth: 10,
    speed: 0,
    empiricalConstant: 1.7,
    falloff: 0.25
  });

  const reportProgress = useCallback((progressData: SuperagentProgress) => {
    setProgress(progressData);
  }, []);

  const currentExtent: BBox | undefined = useMemo(() => {
    if (!state) return undefined;
    const { geom } = state;
    return bbox(geom[0]);
  }, [state]);

  var dashboardTitle = "Analyse av bølgepåvirkning fra seilas";
  useEffect(() => {

    async function loadDataAsync() {

      try {
        if (!state || !currentExtent) return;
        const { fromTime, toTime } = state;

        const _fromTime = TimeUtils.toTimestamp(TimeUtils.isoParse(fromTime));
        const _toTime = TimeUtils.toTimestamp(TimeUtils.isoParse(toTime));

        const posData = await DataLoader.postApi<WebServiceResponse<AisPosArray[]>>(
          `/api/ais/positions/within-bbox-time`,
          {
            Bbox: currentExtent.join(","),
            Start: _fromTime,
            End: _toTime,
            MinSpeed: 0.5
          },
          reportProgress
        );

        if (!posData.success || !isArrayN(posData.data, 1)) return;

        let _mmsis = uniqueArrayValues(posData.data.map(a => a[0]));
        setMmsis(_mmsis);

        const shipData = await DataLoader.postApi<WebServiceResponse<ShipTypeAis[]>>("/api/ship/data/ais/for-mmsis-imos", {
          "mmsi": _mmsis,
          "imo": [],
          "callsign": []
        })
        const _shipIndex = indexArray<ShipTypeAis>(shipData.data.reverse(), "mmsi");
        setShipIndex(_shipIndex);

        const coastlineData = await DataLoader.postApi<GeoJSON.FeatureCollection<LineString>>("/api/osm/coastline", {
          "minX": currentExtent[0],
          "minY": currentExtent[1],
          "maxX": currentExtent[2],
          "maxY": currentExtent[3],
          "srid": 4326
        });

        setCoastline(coastlineData);

        // Check that ais response is successful
        if (!posData.success) throw new Error("Could not load AIS positions");

        if (isArrayN(posData.data, 1)) {
          const _aisPos: AisPos[] = posData.data.map(apa => {
            return {
              mmsi: apa[0],
              date_time_utc: new Date(apa[1]),
              lon: apa[2],
              lat: apa[3],
              cog: apa[4],
              sog: apa[5],
              msg: apa[6],
              calc_speed: apa[7],
              sec_to_prev: apa[8],
              dist_to_prev: apa[9],
            }
          })
          setData(_aisPos);
        } else {
          throw new Error("No data returned");
        }
      } catch (error) {
        console.warn(error);
      }
    }

    loadDataAsync();

    return () => {
    }
  }, [state, reportProgress, currentExtent]);

  const chartData: crossfilter.Crossfilter<AisPos> = useMemo(() => {

    if (isArrayN(data, 1) && selectedMmsi) {
      const _crossfilter = crossfilter<AisPos>(data);
      const _dimMmsi = _crossfilter.dimension(d => d.mmsi);
      _dimMmsi.filterExact(selectedMmsi);
      return _crossfilter;
    } else {
      return crossfilter<AisPos>([]);
    }
  }, [data, selectedMmsi]);

  if (!chartData || !isArrayN(currentExtent, 4, true)) {
    return <Loader chartData={chartData} progressData={progressData} />;
  }

  return (
    <div className="AppView">
      <AVIDashboard
        title={dashboardTitle}
        desc={"Bølgepåvirkning"}
        cfilter={chartData}
        useFlex
        cmsSlug={DbrdWaveImpact.dashboardRoute}
        filter={undefined}
      >

        <h4>Velg et skip</h4>
        {isArrayN(mmsis, 1) && shipIndex && <AVIRow className="flex-wrap gap-3">
          {mmsis.map((m) => {
            const shipInfo = shipIndex[m];
            return <Badge
              key={m}
              bg={m === selectedMmsi ? 'primary' : 'secondary'} text={m === selectedMmsi ? 'light' : 'dark'}
              onClick={() => {
                setSelectedMmsi(m)
                setLength(shipInfo.length);
                setBreadth(shipInfo.breadth);
              }}>
              {m} - {shipInfo?.ship_name || 'N/A'}
            </Badge>
          })}
        </AVIRow>}
        {selectedMmsi && coastline && <AVIRow height={2}>
          <AVICol >
            <OlMap bbox={currentExtent as number[]}>
              <OlMapGeoJsonLayer geoJson={coastline} zIndex={109} title="Coastline" visible styleLike={new Style({
                stroke: new Stroke({
                  color: "#0056AE",
                  width: 2
                })
              })} />
              <OlMapWaveLayer cf={chartData} waveCalcParams={waveCalcParams} setWaveHeight={setWaveHeightUnderCursor} geoJson={coastline} />
              <OlPanel right={15} top={15}>
                <OlMapWaveParamsPanel length={length} breadth={breadth} setWaveCalcParams={setWaveCalcParams} waveHeight={waveHeigthUnderCursor} />
              </OlPanel>
            </OlMap>
          </AVICol>
        </AVIRow>}
      </AVIDashboard>
    </div>
  );
}

export const DbrdWaveImpact: DashboardType = withRouter(
  DbrdWaveImpactLocal
) as DashboardType;

DbrdWaveImpact.dashboardRoute = "wave-impact";

DbrdWaveImpact.dashboardFilters = (
  filter: DashboardFilter,
  setFilter: SetDashboardFilterFunc
) => {
  return {
    helpMessage:
      "Bølgepåvirkning for utvalgte skip genererer svært mye data. Det er ment benyttet for korte tidsrom (~1-2 dager) for å analysere mulig bølgepåvirkning. Tidspunkt i dataene er angitt som UTC.",
    controls: [
      <SimpleTimespan
        key="flt-timespan"
        filter={filter}
        setFilter={setFilter}
        format="d.M.y HH:mm"
        minAge={4}
        maxDays={31}
        utc
      />,
      <DrawBoxInMap
        key="flt-box"
        name="geom"
        setFilter={setFilter}
        filter={filter}
      />,
    ],
  };
}

DbrdWaveImpact.dashboardSettings = () => {
  return {};
}

DbrdWaveImpact.dashboardValidation = (filter: DashboardFilter) => {
  if (filter.fromTime && filter.toTime && isArrayN(filter.geom, 1, true)) {
    return true;
  } else {
    return false;
  }
}

export default DbrdWaveImpact;
