import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { NsrLocationProperties } from "../../types/NsrLocation";
import { WebServiceResponse } from "../../types/WebServiceResponse";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { CruiseMappingRow } from "./CruiseMappingRow";
import { CruiseMappingTable } from "./CruiseMappingTable";
import { isArrayN } from "../../utils/array/isArrayN";

export function CruiseMappingView() {

    const [records, setRecords] = useState<CruiseMapping[]>([]);
    const [locations, setLocations] = useState<NsrLocationProperties[]>([]);

    const loadAsync = useCallback(async (mappingsOnly: boolean = false) => {
        if (!mappingsOnly) {
            const locations = await DataLoader.getApi<WebServiceResponse<NsrLocationProperties[]>>("/api/location/all", {});
            const nsrLocations = locations.data
                .sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0)
                .filter(ld => ld.country === "NO");
            setLocations(nsrLocations);
        }
        const cruiseMappingRes = await DataLoader.getApi<WebServiceResponse<CruiseMapping[]>>(
            `/api/cruise/location-mapping/get`,
            {}
        )
        setRecords(cruiseMappingRes.data);
    }, []);

    const onSaveHandler = useCallback(async (cm: CruiseMapping) => {
        const res = await DataLoader.postApi<WebServiceResponse<CruiseMapping[]>>("/api/cruise/location-mapping/upsert", cm);
        if (res.success) {
            await loadAsync(true);
        }
    }, [loadAsync]);

    const onDeleteHandler = useCallback(async (cmId: number) => {
        const res = await DataLoader.deleteApi<WebServiceResponse<CruiseMapping[]>>(`/api/cruise/location-mapping/delete/${cmId}`);
        if (res.success) {
            await loadAsync(true);
        }
    }, [loadAsync]);

    useEffect(() => {
        loadAsync();
        return () => {
        }
    }, [loadAsync]);

    if (!isArrayN(locations, 1) || !isArrayN(records, 1)) return null;

    return <div>
        <Container>
            <CruiseMappingTable>
                {records.map((cm, i) => {
                    return <CruiseMappingRow key={`key-${cm.Id}-${cm.Source}-${i}`} cruiseMapping={cm} locationOptions={locations} onSave={onSaveHandler} onDelete={onDeleteHandler} />
                })}
                <CruiseMappingRow cruiseMapping={{ Label: "", Source: 0, Target: 0 }} locationOptions={locations} onSave={onSaveHandler} onDelete={onDeleteHandler} editable />
            </CruiseMappingTable>
        </Container>
    </div>
}