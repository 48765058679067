import "./DbrdPasslineFreq.scss";

import * as d3 from "d3";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import * as dc from "dc";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TextUtils } from "../../chart-components/ChartUtils/TextUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { PieChart } from "../../chart-components/Charts/PieChart";
import { SeriesChart } from "../../chart-components/Charts/SeriesChart";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { FilterToFromTime } from "../../filters/FilterToFromTime";
import { FilterToFromYear } from "../../filters/FilterToFromYear";
import withRouter from "../../hocs/withRouter";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../types/DashboardFilter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";

const mapStateToProps = (state) => ({
  // sessionToken: state.auth.sessionToken
  filterReducer: state.filterReducer,
});

class DbrdPasslineFreqBase extends Component<any, any> {
  static dashboardRoute = "passeringslinjer-frekvens";

  static dashboardFilters(
    filter: DashboardFilter,
    setFilter: SetDashboardFilterFunc
  ) {
    return {
      helpMessage:
        "Velg et eller flere år og en tidsperiode som skal sammenlignes innen hvert av årene. Utvalget inkluderer begge valgte år og alle år mellom. Det hentes ut data for de valgte datoene i hvert av årene. Dette dashboardet gjør det mulig å vise data for 2-3 år om gangen og 30-40 dager.",
      controls: (
        <div key="flt_year_time_container">
          <FilterToFromYear
            key="flt_year"
            firstYear={2018}
            filter={filter}
            setFilter={setFilter}
          />
          <FilterToFromTime
            key="flt_time"
            filter={filter}
            setFilter={setFilter}
            format="dd/MM"
            maxTime={TimeUtils.getDateDiff(-3)}
          />
        </div>
      ),
    };
  }

  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: null,
    };
  }

  static dashboardValidation(filter) {
    if (filter.fromYear && filter.toYear && filter.fromTime && filter.toTime) {
      return true;
    }
  }

  static propTypes = {
    location: PropTypes.object,
  };

  defaultProperties = {
    fromYear: 2018,
    toYear: 2020,
    fromTime: new Date(2020, 2, 10),
    toTime: new Date(2020, 2, 18),
  };

  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
      rows: null,
    };

    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    try {
      var { fromYear, toYear, fromTime, toTime } =
        this.props.location.state || this.defaultProperties;

      if (fromYear && toYear && fromTime && toTime) {
        fromTime = new Date(fromTime);
        toTime = new Date(toTime);
        var mergeRequests = [
          DataLoader.postApi(
            "/api/tracks/frequency-counts",
            {
              FromYear: fromYear,
              ToYear: toYear,
              FromMonth: fromTime.getMonth() + 1,
              FromDay: fromTime.getDate(),
              ToMonth: toTime.getMonth() + 1,
              ToDay: toTime.getDate(),
            },
            this.reportProgress
          ),
          DataLoader.postApi("/api/tracks/get-passlines"),
        ];

        Promise.all(mergeRequests)
          .then((jsonResArray) => {
            var dobj = {};
            var passages = jsonResArray[0].data;
            var passlines = jsonResArray[1].data;
            if (passages.length > 0 && passlines.length > 0) {
              passages.forEach((passage) => {
                passage["passage_date"] = new Date(passage["passage_date"]);
                if (!dobj[passage.passline_id]) {
                  dobj[passage.passline_id] = [];
                }
                dobj[passage.passline_id].push(passage);
              });

              this.setState({
                chartData: dobj,
                rows: passages,
                fromYear: fromYear,
                toYear: toYear,
                fromMonth: fromTime.getMonth() + 1,
                toMonth: toTime.getMonth() + 1,
                fromDay: fromTime.getDate(),
                toDay: toTime.getDate(),
                passlines: passlines,
              });
            } else {
              throw new Error("Error loading data or no data");
            }
          })
          .catch((error) => {
            console.warn(error);
            this.setState({
              chartData: [],
            });
          });
      } else {
        throw new Error("Missing selection parameters");
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: [],
      });
    }
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  getFilterFunc(dimArray, activeFilters) {
    return (d, f) => {
      if (f) {
        var filterExists = activeFilters.indexOf(f);
        if (filterExists === -1) {
          activeFilters.push(f);
        } else {
          activeFilters.splice(filterExists, 1);
        }
      } else {
        activeFilters.length = 0;
      }
      dimArray.forEach((dim) => {
        if (activeFilters.length > 0) {
          dim.filter((d) => {
            return activeFilters.indexOf(d) > -1;
          });
        } else {
          dim.filterAll();
        }
      });
      dc.redrawAll();
    };
  }

  render() {
    const {
      chartData,
      progressData,
      rows,
      fromYear,
      toYear,
      fromMonth,
      toMonth,
      fromDay,
      toDay,
      passlines,
    } = this.state;

    if (!chartData) {
      return <Loader chartData={undefined} progressData={progressData} />;
    }

    // Overall filter dims, groups
    var cff = crossfilter(rows);
    var dimDwtGroups = cff.dimension((d: any) => d.dwtg);
    var passagesByDwtGroup = dimDwtGroups
      .group()
      .reduceSum((d: any) => d.passages);

    var dimLengthGroups = cff.dimension((d: any) => d.lgrp);
    var passagesByLengthGroup = dimLengthGroups
      .group()
      .reduceSum((d: any) => d.passages);

    var dimShipCategory = cff.dimension((d: any) => d.shipcategory);
    var passagesByShipCategory = dimShipCategory
      .group()
      .reduceSum((d: any) => d.passages);

    var dimCount = cff.dimension((d: any) => d.id);

    // Individual charts for each passline
    var charts: any[] = [];

    var dims: any[] = [];
    var shipcategoryDims: any[] = [];
    var lgrpDims: any[] = [];
    var dwtgDims: any[] = [];
    var groups: any[] = [];
    var i = 0;

    var activeFilters = {
      shipcategory: [],
      lgrp: [],
      dwtg: [],
    };

    for (var passlineIdx in passlines) {
      var passline = passlines[passlineIdx];
      var cf = crossfilter(chartData[passline.id]);
      shipcategoryDims.push(cf.dimension((d: any) => d.shipcategory));
      lgrpDims.push(cf.dimension((d: any) => d.lgrp));
      dwtgDims.push(cf.dimension((d: any) => d.dwtg));
      dims.push(
        cf.dimension((d: any) => [
          d.passage_date.getFullYear(),
          new Date(2020, d.passage_date.getMonth(), d.passage_date.getDate()),
        ])
      );
      groups.push(dims[i].group().reduceSum((d: any) => d.passages));
      charts.push(
        <SeriesChart
          key={"chart" + i}
          chartTitle={passline.name}
          useFlex
          height={1}
          dimension={dims[i]}
          group={groups[i]}
          yAxisLabel="Passeringer per dag"
          xAxisLabel="Dato"
          filterPrefix="Tidsrom"
          rotateXAxisLabels={-45}
          showMapWkt={passline.geom}
          showResetFilter={false}
          x={d3.scaleTime()}
          title={(d) =>
            TimeUtils.formatDateInYear(d.key[1]) +
            "." +
            d.key[0] +
            ": " +
            d.value
          }
        />
      );
      i++;
    }

    return (
      <div className="AppView-noMap">
        <AVIDashboard
          title="Passeringer for utvalgte passeringslinjer i Norge"
          desc={`Dette dashboardet viser alle passeringer per dag beregnet fra AIS-data i tidsrommet ${fromDay}.${fromMonth} til ${toDay}.${toMonth} i årene fra ${fromYear} til og med ${toYear}. AIS rapporterer tid som UTC.`}
          filter={this.props.location.state}
          keyName="passages"
          type="sum"
          useFlex
          units="passeringer (utvalg)"
          group={dimCount.groupAll()}
        >
          <AVIRow>
            <PieChart
              chartTitle="Passeringer etter skipskategori"
              useFlex
              height={1.33}
              margins={{ top: 20 }}
              innerRadius={50}
              dimension={dimShipCategory}
              group={passagesByShipCategory}
              minAngleForLabel={0.15}
              filterPrefix="Skipskategori"
              onFiltered={this.getFilterFunc(
                shipcategoryDims,
                activeFilters.shipcategory
              )}
            />
            <PieChart
              chartTitle="Passeringer etter lengdegruppe"
              useFlex
              height={1.33}
              margins={{ top: 20 }}
              innerRadius={50}
              dimension={dimLengthGroups}
              group={passagesByLengthGroup}
              minAngleForLabel={0.15}
              filterPrefix="Lengdegruppe"
              ordering={TextUtils.firstNumberInString}
              onFiltered={this.getFilterFunc(lgrpDims, activeFilters.lgrp)}
            />
            <PieChart
              chartTitle="Passeringer etter dødvekttonngruppe (DWT)"
              useFlex
              height={1.33}
              margins={{ top: 20 }}
              innerRadius={50}
              dimension={dimDwtGroups}
              group={passagesByDwtGroup}
              minAngleForLabel={0.15}
              filterPrefix="DWT-gruppe"
              ordering={TextUtils.firstNumberInString}
              onFiltered={this.getFilterFunc(dwtgDims, activeFilters.dwtg)}
            />
          </AVIRow>
          <AVIRow style={{ flexWrap: "wrap" }}>{charts}</AVIRow>
          <AVIRow>
            <DataTable
              chartTitle="Liste over passeringer etter dato, passeringslinke og skipskarakteristika"
              dimension={dimCount}
              sortBy={(d) =>
                d.mmsi +
                "-" +
                TimeUtils.toTimestamp(d.starttime) +
                "-" +
                TimeUtils.toTimestamp(d.endtime)
              }
              useFlex
              size={Infinity}
              columns={[
                {
                  label: "Dato",
                  format: (d) => TimeUtils.toCompactDate(d.passage_date) || 0,
                  value: (d) => d.passage_date,
                },
                {
                  label: "Passeringslinje",
                  format: (d) => d.passline_name || "",
                },
                {
                  label: "Passeringer",
                  format: (d) => d.passages || "",
                },
                {
                  label: "Skipskategori",
                  format: (d) => d.shipcategory || "",
                },
                {
                  label: "Skipstype",
                  format: (d) => d.shiptype || "",
                },
                {
                  label: "Lengdegruppe",
                  format: (d) => d.lgrp || "",
                },
                {
                  label: "Dødvekttonnasjegruppe",
                  format: (d) => d.dwtg || "",
                },
              ]}
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdPasslineFreq = withRouter(
  connect(mapStateToProps)(DbrdPasslineFreqBase)
);

export default DbrdPasslineFreq;
