export class DimUtils {
  static getDims (dims, width, height) {
    // Set fixed paddings
    var tilePadding = 30;
    var tileBorder = 2;
    var scrollBarWidth = 17;
    var popoverPaddingH = 40;
    var popoverPaddingV = 20;

    // Compensate for the percentage size of the popover here
    var availableWidth =
      Math.floor((dims.width / 100) * 96) - dims.spacing - scrollBarWidth - popoverPaddingH;
    var availableHeight =
      Math.floor((dims.height / 100) * 90) - dims.spacing - scrollBarWidth - popoverPaddingV;

    var s = dims.spacing;
    var w = Math.floor((availableWidth / 4) * width) - dims.spacing - tilePadding - tileBorder;
    var h = Math.floor((availableHeight / 3) * height) - dims.spacing - tilePadding - tileBorder;

    // TODO - evaluate necessity
    var w2 = w + s;
    var h2 = h + s;
    return {
      spacing: s,
      width: w,
      height: h,
      width2: w2,
      height2: h2
    };
  }
}
