export const DcExtBase = (chart, options) => {
  chart._group = null;
  chart._dimension = null;

  chart.group = group => {
    if (group === undefined) {
      return chart._group;
    } else {
      chart._group = group;
    }
    return chart;
  };

  chart.dimension = dimension => {
    if (dimension === undefined) {
      return chart._dimension;
    } else {
      chart._dimension = dimension;
    }
    return chart;
  };

  chart.render = () => {
    if (typeof chart.draw === 'function') {
      chart.draw();
    }
    return chart;
  };

  chart.redraw = () => {
    if (typeof chart.draw === 'function') {
      chart.draw();
    }
    return chart;
  };

  return chart;
};
