export function uniqueArrayValues<T=any>(a: T[], accessor?: (d: T) => any | undefined) : T[] {
  if (!accessor) {
    return a.filter((value: any, index: number, array: any[]) => {
      return array.indexOf(value) === index;
    });
  } else {
    return a.reduce((p: T[], c: T, i: number) => {
      const exists = p.findIndex(accessor(c)) > -1;
      if (!exists) {
        p.push(c);
      }
      return p;
    }, []);
  }
}