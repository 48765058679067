export class GeoJsonUtils {
  static featureCollection (features) {
    return {
      type: 'FeatureCollection',
      features: features || []
    };
  }

  static feature (geometry, properties) {
    return {
      type: 'Feature',
      geometry: geometry || {},
      properties: properties || {}
    };
  }

  static lineString (coordinates) {
    return {
      type: 'LineString',
      coordinates: coordinates || []
    };
  }

  static point (coordinates) {
    return {
      type: 'Point',
      coordinates: coordinates || []
    };
  }

  static locationInfoToGeoJson (locationInfo) {
    if (locationInfo === undefined || locationInfo.length === 0) {
      return GeoJsonUtils.featureCollection([]);
    } else {
      var features = locationInfo.map(li => {
        var geometry = li.wktpoint;
        delete li.wktpoint;
        return GeoJsonUtils.feature(geometry, li);
      });
      return GeoJsonUtils.featureCollection(features);
    }
  }
}
