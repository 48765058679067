import './RowChart.scss';

import * as d3 from 'd3';

import * as dc from 'dc';
import PropTypes from 'prop-types';
import React from 'react';
import { AVIColorScales } from '../AVIColorScales';
import { AVIChartTile } from '../Layout/AVIChartTile';
import { BaseMixin } from '../Mixins/BaseMixin';
import { CapMixin } from '../Mixins/CapMixin';
import { ColorMixin } from '../Mixins/ColorMixin';
import { MarginsMixin } from '../Mixins/MarginsMixin';
import { TileMixin } from '../Mixins/TileMixin';

export class RowChart extends React.Component {
  static propTypes = {
    elasticX: PropTypes.bool,
    fixedBarHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    gap: PropTypes.number,
    margins: PropTypes.arrayOf(PropTypes.number),
    labelOffsetX: PropTypes.number,
    labelOffsetY: PropTypes.number,
    renderTitleLabel: PropTypes.bool,
    titleLabelOffsetX: PropTypes.number,
    x: PropTypes.func,
    chartTitle: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    renderLabel: PropTypes.bool,
    useFlex: PropTypes.bool,
    colors: PropTypes.func,
  };

  static defaultProps = {
    elasticX: true,
    fixedBarHeight: false,
    gap: 5,
    labelOffsetX: 10,
    labelOffsetY: 10,
    renderTitleLabel: false,
    titleLabelOffsetX: 2,
    x: d3.scaleBand(),
    renderLabel: true,
    useFlex: false,
    colors: AVIColorScales.colorScaleKyv2024 
  };

  constructor (props) {
    super(props);
    this.chartRef = React.createRef();
    this.chart = null;
  }

  getChart = () => this.chart;

  componentDidMount () {
    const {
      elasticX,
      fixedBarHeight,
      gap,
      labelOffsetX,
      labelOffsetY,
      renderTitleLabel,
      titleLabelOffsetX,
      renderLabel,
      x
    } = this.props;

    this.chart = dc.rowChart(this.chartRef.current);

    BaseMixin(this.chart, this.props);
    CapMixin(this.chart, this.props);
    ColorMixin(this.chart, this.props);
    MarginsMixin(this.chart, this.props);
    TileMixin(this.chart, this.props);
    this.chart._rowCssClass = "dc_row";

    this.chart
      .elasticX(elasticX)
      .fixedBarHeight(fixedBarHeight)
      .gap(gap)
      .labelOffsetX(labelOffsetX)
      .labelOffsetY(labelOffsetY)
      .renderTitleLabel(renderTitleLabel)
      .titleLabelOffsetX(titleLabelOffsetX)
      .label(d => {
        return d.key + ' (' + this.chart.valueAccessor()(d) + ')';
      })
      .renderLabel(renderLabel)
      .x(x)
      .render();
  }

  render () {
    const {chartTitle, width, height, useFlex} = this.props;

    return (
      <AVIChartTile
        title={chartTitle}
        getChart={this.getChart}
        width={width}
        height={height}
        useFlex={useFlex}
      >
        <div className='avi-chart' ref={this.chartRef} />
      </AVIChartTile>
    );
  }
}
