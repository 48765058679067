import "./DbrdPasslineFreqWeekly.scss";

import * as d3 from "d3";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import * as dc from "dc";
import PropTypes from "prop-types";
import { TextUtils } from "../../chart-components/ChartUtils/TextUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { PieChart } from "../../chart-components/Charts/PieChart";
import { SeriesChart } from "../../chart-components/Charts/SeriesChart";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { FilterToFromYearWeek } from "../../filters/FilterToFromYearWeek";
import withRouter from "../../hocs/withRouter";
import { DashboardFilter } from "../../types/DashboardFilter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";

class DbrdPasslineFreqWeeklyBase extends Component<any, any> {
  static dashboardRoute = "passeringslinjer-frekvens-ukentlig";

  static dashboardFilters(filter: DashboardFilter, setFilter: DashboardFilter) {
    return {
      helpMessage:
        "Velg et eller flere år og en tidsperiode som skal sammenlignes innen hvert av årene. Utvalget inkluderer begge valgte år og alle år mellom. Det hentes ut data for de hele uken hvor den valgte start og slutt datoen ligger. Dette dashboardet gjør det mulig å vise data for 2-3 år om gangen.",
      controls: (
        <div key="flt_year_time_container">
          <FilterToFromYearWeek
            key="flt_year"
            firstYear={2018}
            filter={filter}
            setFilter={setFilter}
          />
        </div>
      ),
    };
  }

  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: null,
    };
  }

  static dashboardValidation(filter) {
    if (filter.fromYear && filter.toYear && filter.fromWeek && filter.toWeek) {
      return true;
    }
  }

  static propTypes = {
    location: PropTypes.object,
  };

  defaultProperties = {
    fromYear: 2018,
    toYear: 2020,
    fromWeek: 1,
    toWeek: 12,
  };

  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
      rows: null,
    };

    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    try {
      const { fromYear, toYear, fromWeek, toWeek } =
        this.props.location.state || this.defaultProperties;
      if (fromYear && toYear && fromWeek && toWeek) {
        var mergeRequests = [
          DataLoader.postApi(
            "/api/tracks/frequency-counts/weekly",
            {
              FromYear: fromYear,
              ToYear: toYear,
              FromWeek: fromWeek,
              ToWeek: toWeek,
            },
            this.reportProgress
          ),
          DataLoader.postApi("/api/tracks/get-passlines"),
        ];

        Promise.all(mergeRequests)
          .then((jsonResArray) => {
            var dobj = {};

            var passages = jsonResArray[0].data;
            var passlines = jsonResArray[1].data;
            if (passages.length > 0 && passlines.length > 0) {
              passages.forEach((passage) => {
                passage["passage_date"] = new Date(passage["passage_date"]);
                if (!dobj[passage.passline_id]) {
                  dobj[passage.passline_id] = [];
                }
                dobj[passage.passline_id].push(passage);
              });

              this.setState({
                chartData: dobj,
                rows: passages,
                fromYear: fromYear,
                toYear: toYear,
                fromWeek: fromWeek,
                toWeek: toWeek,
                passlines: passlines,
              });
            } else {
              throw new Error("No data in selected period");
            }
          })
          .catch((error) => {
            console.warn(error);
            this.setState({
              rows: []
            });
          });
      } else {
        throw new Error("Missing selection parameters");
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        rows: []
      });
    }
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  getFilterFunc(dimArray, activeFilters) {
    return (d, f) => {
      if (f) {
        var filterExists = activeFilters.indexOf(f);
        if (filterExists === -1) {
          activeFilters.push(f);
        } else {
          activeFilters.splice(filterExists, 1);
        }
      } else {
        activeFilters.length = 0;
      }
      dimArray.forEach((dim) => {
        if (activeFilters.length > 0) {
          dim.filter((d) => {
            return activeFilters.indexOf(d) > -1;
          });
        } else {
          dim.filterAll();
        }
      });
      dc.redrawAll();
    };
  }

  render() {
    const {
      chartData,
      progressData,
      rows,
      fromYear,
      toYear,
      fromWeek,
      toWeek,
      passlines,
    } = this.state;

    console.log(rows, progressData);
    if (!chartData) {
      return <Loader progressData={progressData} dataArray={rows} />;
    }

    // Overall filter dims, groups
    var cff = crossfilter(rows);
    var dimDwtGroups = cff.dimension((d: any) => d.dwtg);
    var passagesByDwtGroup = dimDwtGroups
      .group()
      .reduceSum((d: any) => d.passages);

    var dimLengthGroups = cff.dimension((d: any) => d.lgrp);
    var passagesByLengthGroup = dimLengthGroups
      .group()
      .reduceSum((d: any) => d.passages);

    var dimShipCategory = cff.dimension((d: any) => d.shipcategory);
    var passagesByShipCategory = dimShipCategory
      .group()
      .reduceSum((d: any) => d.passages);

    var dimCount = cff.dimension((d: any) => d.id);

    // Individual charts for each passline
    var charts: any[] = [];

    var dims: any[] = [];
    var shipcategoryDims: any[] = [];
    var lgrpDims: any[] = [];
    var dwtgDims: any[] = [];
    var groups: any[] = [];
    var i: number = 0;

    var activeFilters = {
      shipcategory: [],
      lgrp: [],
      dwtg: [],
    };

    for (var passlineIdx in passlines) {
      var passline = passlines[passlineIdx];
      var cf = crossfilter(chartData[passline.id]);
      shipcategoryDims.push(cf.dimension((d: any) => d.shipcategory));
      lgrpDims.push(cf.dimension((d: any) => d.lgrp));
      dwtgDims.push(cf.dimension((d: any) => d.dwtg));
      dims.push(cf.dimension((d: any) => [d.year, d.week]));
      groups.push(dims[i].group().reduceSum((d) => d.passages));
      charts.push(
        <SeriesChart
          key={"chart" + i}
          chartTitle={passline.name}
          useFlex
          height={1}
          dimension={dims[i]}
          group={groups[i]}
          yAxisLabel="Passeringer per uke"
          xAxisLabel="Ukenummer"
          filterPrefix="Tidsrom"
          rotateXAxisLabels={-45}
          showMapWkt={passline.geom}
          showResetFilter={false}
          x={d3.scaleLinear().domain([1, 53])}
          xAxisTickFormat={(d) => d}
          xUnits={dc.units.integers}
          title={(d) => `Uke ${d.key[1]}, ${d.key[0]}`}
        />
      );
      i++;
    }

    return (
      <div className="AppView-noMap">
        <AVIDashboard
          title="Passeringer for utvalgte passeringslinjer i Norge"
          desc={`Dette dashboardet viser alle passeringer per uke beregnet fra AIS-data for hele for uke ${fromWeek} til ${toWeek} i årene fra og med ${fromYear} til og med ${toYear}. AIS rapporterer tid som UTC. Ukenummer er basert på ISO 8601 som anvendes i Norge`}
          filter={this.props.location.state}
          keyName="passages"
          type="sum"
          units="passeringer (utvalg)"
          group={dimCount.groupAll()}
          useFlex
        >
          <AVIRow>
            <AVICol>
              <PieChart
                chartTitle="Passeringer etter skipskategori"
                width={1.3}
                height={1.33}
                margins={{ top: 20 }}
                innerRadius={50}
                dimension={dimShipCategory}
                group={passagesByShipCategory}
                minAngleForLabel={0.15}
                filterPrefix="Skipskategori"
                onFiltered={this.getFilterFunc(
                  shipcategoryDims,
                  activeFilters.shipcategory
                )}
              />
            </AVICol>
            <AVICol>
              <PieChart
                chartTitle="Passeringer etter lengdegruppe"
                width={1.3}
                height={1.33}
                margins={{ top: 20 }}
                innerRadius={50}
                dimension={dimLengthGroups}
                group={passagesByLengthGroup}
                minAngleForLabel={0.15}
                filterPrefix="Lengdegruppe"
                ordering={TextUtils.firstNumberInString}
                onFiltered={this.getFilterFunc(lgrpDims, activeFilters.lgrp)}
              />
            </AVICol>
            <AVICol>
              <PieChart
                chartTitle="Passeringer etter dødvekttonngruppe (DWT)"
                width={1.3}
                height={1.33}
                margins={{ top: 20 }}
                innerRadius={50}
                dimension={dimDwtGroups}
                group={passagesByDwtGroup}
                minAngleForLabel={0.15}
                filterPrefix="DWT-gruppe"
                ordering={TextUtils.firstNumberInString}
                onFiltered={this.getFilterFunc(dwtgDims, activeFilters.dwtg)}
              />
            </AVICol>
          </AVIRow>
          {charts}
          <AVIRow>
            <DataTable
              chartTitle="Liste over passeringer etter uke, passeringslinke og skipskarakteristika"
              dimension={dimCount}
              sortBy={(d) =>
                d.mmsi +
                "-" +
                TimeUtils.toTimestamp(d.starttime) +
                "-" +
                TimeUtils.toTimestamp(d.endtime)
              }
              width={4}
              size={Infinity}
              columns={[
                {
                  label: "Dato",
                  format: (d) => TimeUtils.toCompactDate(d.passage_date) || 0,
                  value: (d) => d.passage_date,
                },
                {
                  label: "År",
                  format: (d) => d.year || "",
                  value: (d) => d.year,
                },
                {
                  label: "Uke",
                  format: (d) => d.week || "",
                  value: (d) => d.week,
                },
                {
                  label: "Passeringslinje",
                  format: (d) => d.passline_name || "",
                },
                {
                  label: "Passeringer",
                  format: (d) => d.passages || "",
                },
                {
                  label: "Skipskategori",
                  format: (d) => d.shipcategory || "",
                },
                {
                  label: "Skipstype",
                  format: (d) => d.shiptype || "",
                },
                {
                  label: "Lengdegruppe",
                  format: (d) => d.lgrp || "",
                },
                {
                  label: "Dødvekttonnasjegruppe",
                  format: (d) => d.dwtg || "",
                },
              ]}
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdPasslineFreqWeekly = withRouter(DbrdPasslineFreqWeeklyBase);

export default DbrdPasslineFreqWeekly;
