import "./DataTable.scss";

import * as d3 from "d3";

import React, { Component } from "react";

import { AVITableTile } from "../Layout/AVITableTile";
// import { BaseMixin } from '../Mixins/BaseMixin';
import PropTypes from "prop-types";
import { TileMixin } from "../Mixins/TileMixin";
import * as dc from "dc";
import { Button } from "react-bootstrap";

export class DataTable extends Component {
  static propTypes = {
    dimension: PropTypes.object.isRequired,
    group: PropTypes.func,
    columns: PropTypes.array.isRequired,
    order: PropTypes.func,
    sortBy: PropTypes.any,
    size: PropTypes.number,
    showGroups: PropTypes.bool,
    chartTitle: PropTypes.string.isRequired,
    description: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    showSortControls: PropTypes.bool,
    useFlex: PropTypes.bool,
  };

  static defaultProps = {
    group: (d) => d.key,
    order: d3.ascending,
    sortBy: null,
    size: 250,
    showGroups: false,
    showSortControls: true,
    useFlex: false,
  };

  currentOffset = 0;
  totalRecords = 0;
  pageSize = 50;
  chart = null;
  tableData = [];

  sortByFunc = null;
  sortByOrder = d3.ascending;

  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.infoRef = React.createRef();

    // Bind scope of functions
    this.updateTableInfo = this.updateTableInfo.bind(this);
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
    this.update = this.update.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getChart = this.getChart.bind(this);
    this.alterSort = this.alterSort.bind(this);
    this.setSortOrder = this.setSortOrder.bind(this);
    this.doSort = this.doSort.bind(this);
  }

  componentDidMount() {
    const { size, sortBy, showGroups, columns, order, group, dimension } =
      this.props;

    this.chart = dc.dataTable(this.chartRef.current);

    // Override column functions
    this.chart._doColumnHeaderFormat = (d) => {
      if (d.title !== undefined) {
        return '<span title="' + d.title + '">' + d.label + "</span>";
      } else {
        return d.label;
      }
    };

    // BaseMixin(this.chart, this.props);
    TileMixin(this.chart, this.props);

    this.chart.dimension(dimension).size(size);

    if (sortBy) {
      this.chart.sortBy(sortBy);
    }

    this.chart
      // .showGroups(showGroups)
      .showSections(showGroups)
      .beginSlice(0)
      .endSlice(50)
      .columns(columns)
      .order(order);

    if (group) {
      this.chart.section(group);
    }

    this.chart.render();

    this.chart.on("renderlet", this.updateTableInfo);

    this.chart.redraw();
  }

  componentDidUpdate() {
    this.updateTableInfo();
  }

  updateTableInfo() {
    if (typeof this.chart.dimension().top === "function") {
      this.tableData = this.chart.dimension().top(Infinity);
    } else {
      this.tableData = this.chart.dimension().all();
    }

    this.totalRecords = this.tableData.length;
    this.numPages = Math.ceil(this.totalRecords / this.pageSize);

    if (this.currentOffset > this.totalRecords) {
      this.currentOffset = 0;
      this.update();
      this.chart.redraw();
      return;
    }

    var startSlice = this.currentOffset + 1;
    var endSlice;
    if (this.currentOffset + this.pageSize < this.totalRecords) {
      endSlice = this.currentOffset + this.pageSize;
    } else {
      endSlice = this.totalRecords;
    }

    var dataTableInfo = `Viser ${startSlice} til ${endSlice} av ${this.totalRecords}`;
    if (this.infoRef.current !== null) {
      this.infoRef.current.innerHTML = dataTableInfo;
    }
  }

  update() {
    this.chart.beginSlice(this.currentOffset);
    if (this.currentOffset + this.pageSize > this.totalRecords) {
      this.chart.endSlice(this.totalRecords);
    } else {
      this.chart.endSlice(this.currentOffset + this.pageSize);
    }
  }

  getTableData() {
    const { columns } = this.props;
    var sanitizedTableData = [];
    // Sanitize table data
    if (this.tableData.length > 0) {
      sanitizedTableData = this.tableData.map((row) => {
        let ro = {};
        columns.slice(0).forEach((c) => {
          if (typeof c["value"] === "function") {
            ro[c.label] = c.value(row);
          } else {
            ro[c.label] = c.format(row);
          }
        });
        return ro;
      });
    }
    return sanitizedTableData;
  }

  next() {
    if (this.currentOffset + this.pageSize < this.totalRecords) {
      this.currentOffset += this.pageSize;
      this.update();
      this.chart.redraw();
    }
  }

  previous() {
    if (this.currentOffset >= this.pageSize) {
      this.currentOffset -= this.pageSize;
      this.update();
      this.chart.redraw();
    } else {
      this.currentOffset = 0;
    }
  }

  getChart() {
    return this.chart;
  }

  // Set column to sort table by
  alterSort(event) {
    const { columns } = this.props;
    var colIdx = event.target.value;
    if (columns.length >= colIdx - 1) {
      this.sortByFunc = (d) => {
        var c = columns[colIdx];
        if (typeof c["value"] === "function") {
          return +c.value(d) || -1;
        } else {
          return c.format(d) || "";
        }
      };
      this.doSort();
    }
  }

  // Do actual sorting
  doSort() {
    if (typeof this.sortByFunc === "function") {
      this.chart.sortBy(this.sortByFunc);
    }

    this.chart.redraw();
  }

  // Set ascending or descending sort order
  setSortOrder(event) {
    var order = event.target.value;
    if (order === "asc") {
      this.chart.order(d3.ascending);
    } else {
      this.chart.order(d3.descending);
    }
    this.currentOffset = 0;
    this.doSort();
  }

  render() {
    const { columns, showSortControls, description, chartTitle } = this.props;
    var opts = [];
    columns.forEach((col, colIdx) => {
      opts.push(
        <option key={"col-" + colIdx} value={colIdx}>
          {col.label}
        </option>
      );
    });

    var opts2 = [
      <option key={"order-asc"} value={"asc"}>
        Stigende
      </option>,
      <option key={"order-desc"} value={"desc"}>
        Synkende
      </option>,
    ];
    return (
      <AVITableTile
        title={chartTitle}
        description={description}
        getTableData={this.getTableData}
        getChart={this.getChart}
        table={this.chartRef}
        width={this.props.width}
        height={this.props.height}
        useFlex={this.props.useFlex}
      >
        <div className="d-flex w-100 mb-3">
          <div>
            <span ref={this.infoRef}></span> rader
          </div>
          {showSortControls && (
            <div className="ms-auto me-auto">
              <label>Sorter etter: </label>
              <select className="border-0 rounded-0" onChange={this.alterSort}>{opts}</select>
              <select className="border-0 rounded-0" onChange={this.setSortOrder}>{opts2}</select>
            </div>
          )}
          <Button variant="outline-dark" size="sm" className="py-0 ms-auto border-0 rounded-0"
            onClick={this.previous}
          >
            <i className="bi bi-chevron-left"></i>
          </Button>
          <Button
            variant="outline-dark" size="sm" className="py-0  border-0 rounded-0"
            onClick={this.next}
          >
            <i className="bi bi-chevron-right"></i>
          </Button>
        </div>
        <table className="avi-data-table w-100" ref={this.chartRef} />
      </AVITableTile>
    );
  }
}
