import React from "react";
import { Spinner } from "../ui-components/Loader/Spinner";
import { Loader } from "../ui-components/Loader/Loader";

export function TestView() {
  // const [date, setDate]= useState();
  // const [filter, setFilter] = useState<DashboardFilter>({
  //   singleDate: '2023-01-01T10:01:00.000Z'
  // });
  // const [outSrid, setOutSrid] = useState<number>(4326);
  // const [outMode, setOutMode] = useState<CoordinateMode>("wkt");

  // const handleChangeEvent = useCallback((evt: EventLike<any>) => {
  //   console.log(evt);
  //   setFilter((currentFilter) => {
  //     return {
  //       ...currentFilter,
  //       [evt.target.name]: evt.target.value,
  //     };
  //   });
  // }, []);

  // const updateFilter: SetDashboardFilterFunc = useCallback(
  //   (updatedFilter: DashboardFilter) => {
  //     setFilter((currentFilter) => {
  //       return {
  //         ...currentFilter,
  //         ...updatedFilter,
  //       };
  //     });
  //   },
  //   []
  // );

  return (
    <>
      <h1>Control tests</h1>
      <p>
        Test
      </p>
      <Spinner icon="ship-1" motion="rocking-motion"/>
      <Spinner icon="ship-2" motion="rocking-motion"/>
      <Spinner icon="ship-3" motion="rocking-motion"/>
      <Spinner icon="ship-4" motion="rocking-motion"/>
      <Spinner icon="ship-5" motion="rocking-motion"/>
      <Spinner icon="ship-6" motion="rocking-motion"/>
      <Spinner icon="ship-7" motion="rocking-motion"/>
      <Spinner icon="lighthouse" motion="hflip-motion"/>
      <Spinner icon="rudder" motion="rotate-motion"/>
      <Spinner icon="why-1" motion="rocking-motion"/>
      <Spinner icon="why-2" motion="rocking-motion"/>
      <Spinner icon="delay" motion="shaking-motion"/>
      <Spinner icon="error" motion="shaking-motion"/>
      <Loader chartData={undefined} progressData={undefined}/>
      {/* <div className="d-flex gap-2 flex-row">
        <div style={{ flex: 1 }}>
          <h4>Configuration</h4>
          <div>
            <SelectSrsControl
              name="outSrid"
              value={outSrid}
              onChange={(evt) => setOutSrid(evt.target.value)}
            />
            <CoordinateModeControl
              name="outMode"
              value={outMode}
              onChange={(evt) => setOutMode(evt.target.value)}
            />
          </div>
          <hr style={{ margin: "2em" }}></hr>
          <h4>Controls</h4>
          <CoordinateEntryControl<string>
            name="geom"
            outMode={outMode}
            outSrid={outSrid}
            onChange={handleChangeEvent}
            value={filter.geom}
          />
        </div>
        <div style={{ flex: 1 }}>
          <h5>Single</h5>
          <SimpleDate
            name="singleDate"
            value={filter.singleDate}
            min="2023-01-01"
            max="2025-01-01"
            displayFormat="d.M.y HH:mm"
            onChange={handleChangeEvent}
          ></SimpleDate>
          <h5>Single UTC</h5>
          <SimpleDate
            name="singleDate"
            value={filter.singleDate}
            min="2023-01-01"
            max="2025-01-01"
            onChange={handleChangeEvent}
            displayFormat="d.M.y HH:mm"
            utc
          ></SimpleDate>
          <h5>Timespan</h5>
          <SimpleTimespan
            filter={filter}
            setFilter={updateFilter}
            format="d.M.y HH:mm"
            maxDays={3}
          />
          <h5>Timespan UTC</h5>
          <SimpleTimespan
            filter={filter}
            setFilter={updateFilter}
            format="d.M.y HH:mm"
            maxDays={14}
            minAge={4}
            utc
          />
        </div>
        <div style={{ flex: 1 }}>
          <h4>Control</h4>
          <input type="date" name="standardDate" onChange={handleChangeEvent} value={filter.standardDate}/>
          <input type="time" name="standardTime" onChange={handleChangeEvent} value={filter.standardTime}/>
          <h4>React date time</h4>
          <DateTimePicker
            format="y-M-d"
            value={filter.dtp}
            onChange={(evt) => handleChangeEvent(createEventLike("dtp", evt))}
            showLeadingZeros
          ></DateTimePicker>          
        </div>
      </div>
      <pre>{JSON.stringify(filter, null, 4)}</pre> */}
    </>
  );
}
