import { GeoJSONFeature } from "ol/format/GeoJSON";
import { useCallback, useMemo, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { PasslineTypeahead } from "../../../filters/PasslineTypeahead";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../../types/DashboardFilter";
import { Passline } from "../../../types/Passline";
import { geoJSONFormat, wktFormat } from "../../../utils/geomUtils";
import { MapInteractionType } from "../FilterList/FilterList";
import { AddMapInteraction } from "./AddMapInteraction";
import { ClearDrawingLayer } from "./ClearDrawingLayer";
import { ShowLayer } from "./ShowLayer";

export type SelectPasslinesInMapProps = {
  setFilter: SetDashboardFilterFunc;
  filter: DashboardFilter;
  returnProperty?: "geometry" | keyof Passline["properties"];
  name?: string;
  multiple?: number;
};

export function SelectPasslinesInMap({
  filter,
  setFilter,
  name = "geom",
  returnProperty = "geometry",
  multiple = 1,
}: SelectPasslinesInMapProps) {
  const [interaction, setInteraction] = useState<MapInteractionType>();

  const passlines: any[] = useMemo(() => [], []);

  const handleChange = useCallback(
    (geoJSONFeatures: GeoJSONFeature[]) => {
      while (passlines.length > (multiple - 1)) {
        passlines.shift();
      }
      passlines.length = 0;
      passlines.push(...geoJSONFeatures);
      if (returnProperty !== "geometry") {
        setFilter({
          [name]: passlines.map((gjf) => gjf.properties![returnProperty]),
        });
      } else {
        setFilter({
          [name]: passlines.map((gjf) => {
            return wktFormat.writeFeature(geoJSONFormat.readFeature(gjf));
          }),
        });
      }
    },
    [multiple, name, passlines, returnProperty, setFilter]
  );

  return (
    <>
      <Stack direction="vertical" gap={2}>
        <PasslineTypeahead name="geom" filter={filter} setFilter={setFilter} />
        <Button
          variant="info"
          className="text-white rounded-0"
          active={interaction === "selectPoint"}
          onClick={() => setInteraction("selectPoint")}
        >
          Velg passeringslinje i kart
        </Button>
        <Button
          variant="info"
          className="text-white rounded-0"
          active={interaction === "drawLineString"}
          onClick={() => setInteraction("drawLineString")}
        >
          Tegn passeringslinje i kart
        </Button>
      </Stack>
      <ShowLayer selectionLayer="passlines" />
      {interaction && (
        <>
          <ClearDrawingLayer />
          <AddMapInteraction
            mapInteraction={interaction}
            onChange={handleChange}
            selectionLayer="passlines"
            multiple={multiple}
          />
        </>
      )}
    </>
  );
}
