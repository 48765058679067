import { easeOut } from "ol/easing";
import { isEmpty } from "ol/extent";
import { useCallback, useEffect } from "react";
import { useFilterContext } from "../FilterList/FilterList";

export function ZoomToDrawingLayer() {
  const { drawingLayer, map } = useFilterContext();

  const onAddRemoveFeature = useCallback(() => {
    if (!map) return;
    setTimeout(() => {
      let extent = drawingLayer.getSource()?.getExtent();
      if (extent && !isEmpty(extent)) {
        map.getView().fit(extent, {
          padding: [50, 50, 50, 50],
          easing: easeOut,
          duration: 1500,
          maxZoom: 12,
        });
      }
    }, 250);
  }, [drawingLayer, map]);

  useEffect(() => {
    if (!map) return;

    drawingLayer.getSource()?.on(["addfeature"], onAddRemoveFeature);

    return () => {
      drawingLayer.getSource()?.un(["addfeature"], onAddRemoveFeature);
    };
  }, [drawingLayer, map, onAddRemoveFeature]);

  return null;
}
