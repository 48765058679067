import * as d3 from "d3";

export class AVIColorScales {
  // Default color for values that don't fit in scale
  static unknownColor = "blue";

  /**
   * Ship category, highest level
   */
  static shipCategory = d3
    .scaleOrdinal()
    .domain([
      "Tankskip",
      "Offshorefartøy og spesialfartøy",
      "Lasteskip",
      "Fiskefartøy",
      "Passasjerskip",
      "Ukjent",
    ])
    .range([
      "#7fc97f",
      "#beaed4",
      "#fdc086",
      "#ffff99",
      "#386cb0",
      "#f0027f",
      "#bf5b17",
    ])
    .unknown(AVIColorScales.unknownColor);

  /**
   * Ship group, second level (NSR, norsk)
   */
  static shipTypeNor = d3
    .scaleOrdinal()
    .domain([
      "Auxiliary",
      "Last",
      "Fisk",
      "Passasjer",
      "Tank",
      "Slep",
      "Ukjent",
    ])
    .range([
      "#7fc97f",
      "#beaed4",
      "#fdc086",
      "#ffff99",
      "#386cb0",
      "#f0027f",
      "#bf5b17",
    ])
    .unknown(AVIColorScales.unknownColor);

  /**
   * Ship group, second level (NSR, engelsk)
   */
  static shipType = d3
    .scaleOrdinal()
    .domain([
      "Auxiliary",
      "Cargo",
      "Fish",
      "Passenger",
      "Tanker",
      "Tug",
      "Ukjent",
    ])
    .range([
      "#7fc97f",
      "#beaed4",
      "#fdc086",
      "#ffff99",
      "#386cb0",
      "#f0027f",
      "#bf5b17",
    ])
    .unknown(AVIColorScales.unknownColor);

  static KyvColorScheme = [
    "#a6cee3",
    "#1f78b4",
    "#b2df8a",
    "#33a02c",
    "#fb9a99",
    "#e31a1c",
    "#fdbf6f",
    "#ff7f00",
    "#cab2d6",
    "#6a3d9a",
    "#ffff99",
    "#b15928",
  ];

  static Kyv2024ColorScheme = [
    "#000667",
    "#5263AD",
    "#0995C9",
    "#CC3010",
    "#73061A",
    "#3BA460",
    "#236239",
    "#044E71",
    "#CD3B56",
    "#996B00",
    "#654700",
    "#8D8D8D",
    "#525252",
    "#161616",
  ];

  static colorScaleKyv2024 = d3.scaleOrdinal().range(this.Kyv2024ColorScheme);
  static colorScaleKyv2024Mono = d3.scaleOrdinal().range(["#394597"]);
}
