import "./LineStringMapChart.scss";

import * as olControl from "ol/control";
import * as olInteraction from "ol/interaction";
import * as olProj from "ol/proj";

import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";

import Map from "ol/Map";
import { DcExtLineStringMapChart } from "../DcExt/DcExtLineStringMapChart";
import { AVIMapTile } from "../Layout/AVIMapTile";
import { LayerSwitcherMixin } from "../Mixins/LayerSwitcherMixin";
// import PropTypes from 'prop-types';
import { ScaleLinear } from "d3";
import View from "ol/View";
import { MapProvider } from "../../context/MapProvider";
import { SimpleBaseMixin } from "../Mixins/SimpleBaseMixin";

export type LineStringMapChartProps = PropsWithChildren & {
  chartData: any;
  chartTitle: string;
  height: number;
  dimension: any;
  width: number;
  colorScheme: ScaleLinear<string, number>;
  categoryProperty: string;
  useFlex: boolean;
  allowFullscreen: boolean;
};

export function LineStringMapChart({
  chartData,
  chartTitle,
  height,
  dimension,
  width,
  colorScheme,
  categoryProperty,  
  useFlex = false,
  allowFullscreen = false,
  children
}: LineStringMapChartProps) {
  const chartRef = useRef<any>();
  const [chart, setChart] = useState<any>();
  const [map, setMap] = useState<Map>();

  const getJson = useCallback(() => {
    return chart.geojson();
  }, [chart]);

  useEffect(() => {
    var zoomPt = [15, 61];

    const _map = new Map({
      target: chartRef.current,
      interactions: olInteraction.defaults({ mouseWheelZoom: false }),
      controls: olControl.defaults({
        attributionOptions: {
          collapsible: false,
        },
      }),
      view: new View({
        center: olProj.transform(
          [zoomPt[0], zoomPt[1]],
          "EPSG:4326",
          "EPSG:3857"
        ),
        zoom: 5,
      }),
    });

    LayerSwitcherMixin(_map);

    const _chart = new DcExtLineStringMapChart({
      map: _map,
      chartData: chartData,
      colorScheme: colorScheme,
      categoryProperty: categoryProperty,
    });

    SimpleBaseMixin(_chart, {dimension});

    (_chart as any).render();
    setChart(_chart);
    setMap(_map);

    return () => {
      _map?.dispose();
    };
  }, [categoryProperty, chartData, colorScheme, dimension]);

  const getMap = useCallback(() => {
    return map;
  }, [map]);

  return (
    <AVIMapTile
      title={chartTitle}
      height={height}
      width={width}
      getTrackData={getJson}
      getMap={getMap}
      useFlex={useFlex}
      allowFullscreen={allowFullscreen}
    >
      <div className="avi-map position-relative" ref={chartRef}>
        {map && <MapProvider map={map}>{children}</MapProvider>}
      </div>
    </AVIMapTile>
  );
}
