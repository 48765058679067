import "./ModalLayout.scss";

import React, { useCallback, useState } from "react";
import { Container, Modal, ModalBody } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import HeaderBar from "../ui-components/HeaderBar/HeaderBar";
import { ModalLayoutBackButton } from "./ModalLayoutBackButton";
import { ModalLayoutHeader } from "./ModalLayoutHeader";

export function ModalLayout({ children }: React.PropsWithChildren) {
  const navigate = useNavigate();
  const [visible, show] = useState<boolean>(true);

  const onHide = useCallback(() => {
    show(false);
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <HeaderBar />
      <Modal
        show={visible}
        onHide={() => onHide()}
        centered
        backdrop
        fullscreen
        className="kyvdvh--modal-layout"
      >
        {/* <div className="kyvdvh--modal-layout-header-bar">
          <Col sm="3" className="kyvdvh--modal-layout-header-font">
            <img
              src={logo}
              className="mb-1"
              alt="Kystverket Logo"
              height={40}
            />{" "}
            Kystverket
          </Col>
          <Col sm="6" className="text-center kyvdvh--modal-layout-header-font" style={{lineHeight: "44px"}}>
            Kystdatahuset
          </Col>
          <Col sm="3" className="text-end" style={{lineHeight: "44px"}}>
            <Button variant="link-primary" onClick={() => onHide()}>
              <i className="bi bi-x-lg"></i>
            </Button>
          </Col>
        </div> */}
        {/* <ModalHeader closeButton>...</ModalHeader> */}
        <ModalBody className="p-0 mx-0 kyv--bg-body">
          <ModalLayoutHeader />
          <ModalLayoutBackButton />
          <Container fluid className="px-0">{children}</Container>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ModalLayout;
