import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import makeRootReducer from './reducers';

const CreateStore = (initalState = {}) => {
  
  // Middleware Configuration
  const middleware = [thunk];

  // Store Enhancers
  const enhancers = [];
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  let composeEnhancers = compose;

  // Store Instatiation and HMR Setup
  const store = createStore(
    makeRootReducer(),
    initalState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  );

  store.asyncReducers = {};

  // eslint-disable-next-line
  if (module.hot) {
    // eslint-disable-next-line
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line
      const reducers = require('./reducers').default;
      store.replaceReducer(reducers(store.asyncReducers));
    });
  }
  return store;
};

export default CreateStore;