import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

import { Component } from "react";

import PropTypes from "prop-types";
import { logout } from "react-adaptive-auth";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { AuthContextProvider } from "./auth-components/AuthLib";
import { AppRouter } from "./router/AppRouter";
import { DataLoader } from "./ui-components/DataLoader/DataLoader";

class App extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      minZoom: 4,
      maxZoom: 18,
      initExtent: [-2525327, 7200937, 4656211, 11819636],
    };
  }

  componentDidMount() {
    return DataLoader.postDigiTheme(
      "https://kystinfo.no/WebServices/generic/Authentication.asmx/GetUserDetails",
      {}
    ).then((res) => {
      if (
        res.ok === true &&
        res.body &&
        res.body.d &&
        res.body.d.success === true
      ) {
      } else {
        logout();
      }
    });
  }

  render() {
    const { store } = this.props;
    return (
      <AuthContextProvider>
        <Provider store={store}>
          <AppRouter />
          <ToastContainer />
        </Provider>
      </AuthContextProvider>
    );
  }
}

export default App;
