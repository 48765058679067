import { EventLike } from "../types/Shims";

export function createEventLike<T = any>(name: string, value: T): EventLike<T> {
    return {
        target: {
            name,
            value
        }
    };
}
