import { ScaleLinear } from "d3";
import { Grid } from "../../types/Grid";
import { createImageBitmapFromGridData } from "./createImageBitmapFromGridData";

export async function gridToCanvas(
  grid: Grid,
  colorScale: ScaleLinear<string, string>
) {
  const imageBitmap = await createImageBitmapFromGridData(
    grid,
    colorScale
  );

  // Create a canvas to draw the ImageBitmap
  const canvas = document.createElement("canvas");
  canvas.width = grid.cols;
  canvas.height = grid.rows;
  const ctx = canvas.getContext("2d");
  if (ctx) {
    ctx.drawImage(imageBitmap, 0, 0);
  }
  return canvas;
}
