import { GeoJSONFeature } from "ol/format/GeoJSON";
import React, { useCallback, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import {
  MunicipalityOption,
  MunicipalityTypeahead,
} from "../../../filters/MunicipalityTypeahead";
import { Municipality } from "../../../types/Municipality";
import { NsrLocation } from "../../../types/NsrLocation";
import { EventLike } from "../../../types/Shims";
import { MapSelectionType, useFilterContext } from "../FilterList/FilterList";
import { AddMapInteraction } from "./AddMapInteraction";
import { ClearDrawingLayer } from "./ClearDrawingLayer";
import { ShowLayer } from "./ShowLayer";
import { ZoomToDrawingLayer } from "./ZoomToDrawingLayer";

export type SelectLocationsInMapProps = {
  name: string;
  returnProperty: "geometry" | keyof NsrLocation["properties"];
};

export function SelectLocationsInMap({
  name: filterName,
  returnProperty: propertyName,
}: SelectLocationsInMapProps) {
  const [selectInteraction, setSelectInteraction] =
    useState<MapSelectionType>();
  const [selectionGeometry, setSelectionGeometry] = useState<Municipality>();
  const { setFilter } = useFilterContext();

  const handleChange = useCallback(
    (gjFeatures: GeoJSONFeature[]) => {
      if (propertyName !== "geometry") {
        setFilter({
          [filterName]: gjFeatures.map((gjf) => gjf.properties![propertyName]),
        });
      } else {
        setFilter({
          [filterName]: gjFeatures,
        });
      }
    },
    [filterName, propertyName, setFilter]
  );

  return (
    <>
      <Stack direction="vertical" gap={2}>
        <MunicipalityTypeahead
          name="municipality"
          onChange={(evt: EventLike<MunicipalityOption[]>) => {
            setSelectInteraction("overlayPolygon");
            setSelectionGeometry(evt.target.value[0].geoJson);
          }}
        />
        <Button
          variant="info"
          className="text-white rounded-0"
          active={selectInteraction === "selectPoint"}
          onClick={() => setSelectInteraction("selectPoint")}
        >
          Velg lokasjon(er) i kart
        </Button>
        <Button
          variant="info"
          className="text-white rounded-0"
          active={selectInteraction === "selectBox"}
          onClick={() => setSelectInteraction("selectBox")}
        >
          Velg lokasjoner innen rektangel
        </Button>
        <Button
          variant="info"
          className="text-white rounded-0"
          active={selectInteraction === "selectPolygon"}
          onClick={() => setSelectInteraction("selectPolygon")}
        >
          Velg lokasjoner innen flate
        </Button>
      </Stack>
      <ShowLayer selectionLayer="locations" />
      {selectInteraction && (
        <>
          <ClearDrawingLayer />
          <ZoomToDrawingLayer />
          <AddMapInteraction
            mapInteraction={selectInteraction}
            onChange={handleChange}
            selectionGeometry={selectionGeometry}
            selectionLayer="locations"
          />
        </>
      )}
    </>
  );
}
