import { combineReducers } from 'redux';
import { authReducer } from 'react-adaptive-auth';
import { filterReducer } from './filterReducer';
import digiThemeReducer from './digiThemeReducer';

export const makeRootReducer = asyncReducers => {
  return combineReducers({
    auth: authReducer,
    filterReducer: filterReducer,
    articles: digiThemeReducer('articles', window.kyvDashboardConfig.articlesDt.uuid),
    dashboards: digiThemeReducer('dashboards', window.kyvDashboardConfig.dashboardsDt.uuid),
    municipalitiesNoGeom: digiThemeReducer(
      'municipalitiesNoGeom',
      window.kyvDashboardConfig.municipalitiesNoGeomTheme.uuid
    ),
    municipalityGeom: digiThemeReducer(
      'municipalityGeom',
      window.kyvDashboardConfig.municipalityGeomTheme.uuid
    ),
    passlines: digiThemeReducer('passlines', window.kyvDashboardConfig.passlinesDt.uuid),
    comments: digiThemeReducer('comments', window.kyvDashboardConfig.kyvDashboardComments.uuid),
    ...asyncReducers
  });
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
