// ------------------------------------
// Constants
// ------------------------------------
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
const SET_SOURCES = 'SET_SOURCES';
const RESET_FILTER = 'RESET_FILTER';

// ------------------------------------
// Actions
// ------------------------------------
const updateFilter = props => ({
  type: UPDATE_FILTERS,
  payload: {
    props
  }
});

const setSources = sources => ({
  type: SET_SOURCES,
  payload: { sources }
});

const resetFilter = () => ({
  type: RESET_FILTER,
  payload: {}
});

// ------------------------------------
// Action creators
// ------------------------------------
export const updateFilterState = props => {
  return dispatch => {
    dispatch(updateFilter(props));
  };
};

export const updateActiveSources = sources => {
  return dispatch => {
    dispatch(setSources(sources));
  };
};

export const resetFilterState = () => {
  return dispatch => {
    dispatch(resetFilter());
  };
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  fromYear: undefined,
  toYear: undefined,
  fromMonth: undefined,
  toMonth: undefined,
  locationIds: undefined,
  fromTime: undefined,
  toTime: undefined,
  mmsi: undefined,
  selectedMunicipality: undefined,
  geom: undefined
};

export function filterReducer (state = initialState, action) {
  switch (action.type) {
    case RESET_FILTER: {
      return initialState;
    }
    case SET_SOURCES: {
      const { sources } = action.payload;

      return { ...state, activeSources: sources };
    }
    case UPDATE_FILTERS: {
      const { props } = action.payload;
      return { ...state, ...props };
    }
    default:
      return state;
  }
}
