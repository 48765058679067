import React from "react";
import { useNavigate } from "react-router-dom";

import "./NoData.scss";

function NoData() {
  const navigate = useNavigate();
  return (
    <div className="no-data-area">
      <div className="no-data-area-inner">
        <h3>Ingen data i utvalget</h3>
        <p>
          Det finnes ingen data for valgte kriterier eller område. Hvis du tror
          dette skyldes en feil, gi en tilbakemelding. Om du trur at du kan ha
          satt filtre som er for snevre, gå tilbake til forrige vindu, juster
          utvalget og prøv på nytt.
        </p>
        <button className="dialog" onClick={() => navigate(-1)}>
          Tilbake til utvalgsskjema
        </button>
      </div>
    </div>
  );
}

export { NoData };
