import { Grid } from "../../types/Grid";

// Function to interpolate a specific cell using IDW
export function interpolateValue(
  x: number,
  y: number,
  grid: Grid,
  power: number,
  radius: number): number {
  let weightedSum = 0;
  let weightSum = 0;

  // Search within the window of neighboring cells
  for (let dy = -radius; dy <= radius; dy++) {
    for (let dx = -radius; dx <= radius; dx++) {
      const neighborX = x + dx;
      const neighborY = y + dy;

      // Skip cells that are out of bounds
      if (neighborX < 0 ||
        neighborY < 0 ||
        neighborX >= grid.cols ||
        neighborY >= grid.rows) {
        continue;
      }

      const neighborIndex = neighborY * grid.cols + neighborX;
      const neighborValue = grid.data[neighborIndex];

      // Only consider valid neighbors (non-missing values)
      if (!isNaN(neighborValue)) {
        // Calculate distance between the current cell and the neighbor
        const distance = Math.sqrt(dx * dx + dy * dy) * grid.cellSize;

        // Inverse distance weight
        const weight = 1 / Math.pow(distance, power);

        // Accumulate weighted value and weight sum
        weightedSum += neighborValue * weight;
        weightSum += weight;
      }
    }
  }

  // If no valid neighbors, return a default value (or handle it differently)
  if (weightSum === 0) {
    return 0; // Default value when there are no neighbors
  }

  // Return the interpolated value (weighted average)
  return weightedSum / weightSum;
}
