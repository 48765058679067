import "./DbrdTest.scss";

import React, { Component } from "react";

import PropTypes from "prop-types";
import { DashboardConfig } from "../../config/kyvDashboard";
import { FilterToFromTime } from "../../filters/FilterToFromTime";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Downloader } from "../../ui-components/Downloader/Downloader";
import { Loader } from "../../ui-components/Loader/Loader";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";

class DbrdTestBase extends Component<any, any> {
  static dashboardRoute = "test";

  static dashboardFilters(filter, setFilter) {
    return {
      helpMessage:
        'Nedlasting av "skipshaler" for seilas innen område. Dette er et dashboard under utvikling',
      controls: (
        <FilterToFromTime
          key="flt"
          minTime={new Date(2010, 0, 1)}
          maxTime={TimeUtils.getDateDiff(-3)}
          filter={filter}
          setFilter={setFilter}
        />
      ),
    };
  }

  static dashboardSettings() {
    return {
      filterControls: [
        DashboardConfig.filterControls.drawPolygon,
        DashboardConfig.filterControls.drawBox,
      ],
      selectableLayer: undefined,
    };
  }

  static dashboardValidation(filter) {
    if (filter.fromTime && filter.toTime && filter.geom) {
      return true;
    }
  }

  // Here we set up an object to receive properties from the previous screen

  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        fromTime: PropTypes.instanceOf(Date).isRequired,
        toTime: PropTypes.instanceOf(Date).isRequired,
        geom: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      asyncResponse: null,
    };
    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    try {
      const { fromTime, toTime, geom } = this.props.location.state;

      if (!fromTime || !toTime || !geom) {
        throw new Error("Not sufficient parameters");
      }

      // Use input variables for the data query
      DataLoader.postApi(
        "/api/tracks/async/download/by-wkt-timespan",
        {
          Wkt: geom[0],
          StartTime: fromTime,
          EndTime: toTime,
        },
        this.reportProgress
      ).then(
        (response) => {
          if (!response.success) {
            throw new Error("Error loading tracks by wkt within timespan");
          }

          this.setState({
            asyncResponse: response,
          });
        },
        (error) => {
          error && console.error("Error occurred");
        }
      ).catch((error)=>{
        console.warn(error);
      });
    } catch (error) {
      console.warn(error);
    }
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const { asyncResponse, progressData } = this.state;

    if (!asyncResponse) {
      return <Loader chartData={undefined} progressData={progressData} />;
    }

    return <Downloader asyncResponse={asyncResponse} />;
  }
}

export const DbrdTest = withRouter(DbrdTestBase);

export default DbrdTest;
