import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { CoordinateMode } from "./CoordinateEntryControl";
import { EventLike } from "../../types/Shims";

export type CoordinateModeControlProps = {
  name: string;
  value: CoordinateMode;
  onChange: (evt: EventLike<CoordinateMode>) => void;
};

export function CoordinateModeControl({name, value, onChange}: CoordinateModeControlProps) {
  return (
    <FormGroup className="mb-3">
      <FormLabel>Velg måte å legge inn koordinater på</FormLabel>
      <FormControl
        as="select"
        name={name}
        value={value}
        onChange={(evt: any)=>onChange(evt as EventLike<CoordinateMode>)}
      >
        <option value="wkt">Well-known Text (WKT)</option>
        <option value="geoJSON">GeoJSON</option>
        <option value="extent">Dekningsrektangel</option>
      </FormControl>
    </FormGroup>
  );
}
