import * as olExtent from 'ol/extent';
import * as olProj from 'ol/proj';

export function BoundsOfNorwayIncludingSvalbardAndJanMayen (epsg = null) {
  let e = olExtent.boundingExtent([[-14, 54], [44.58, 81.26]]);

  if (epsg) {
    e = olProj.transformExtent(e, 'EPSG:4326', epsg);
  }

  return e;
}

export function BoundsOfMainlandNorway (epsg = null) {
  let e = olExtent.boundingExtent([[3.268, 56.275], [32.221, 71.466]]);

  if (epsg) {
    e = olProj.transformExtent(e, 'EPSG:4326', epsg);
  }

  return e;
}
