import './HelpView.scss';

import React from 'react';
import fig10 from './images/image10.png';
import fig15 from './images/image15.png';
import fig16 from './images/image16.png';
import fig18 from './images/image18.png';
import fig19 from './images/image19.png';
import fig2 from './images/image2.png';
import fig22 from './images/image22.png';
import fig23 from './images/image23.png';
import fig24 from './images/image24.png';
import fig25 from './images/image25.png';
import fig3 from './images/image3.png';
import fig4 from './images/image4.png';
import fig5 from './images/image5.png';
import fig6 from './images/image6.png';
import fig7 from './images/image7.png';

class HelpView extends React.Component {
  render () {
    return (
      <div className='help-view--container'>
        <h1>Hjelp</h1>
        <div className='section'>
          <img alt="Tittelområde" src={fig10} />
          <h2>Tittelområde</h2>
          <p>
            Alle dashboard har et tittelområde med navnet på "dashboardet" og visning av overordnede
            filterkriterier.
          </p>
          <p>Her finnes det også en lenke hvor man kan skrive ut gjeldende visning.</p>
        </div>
        <div className='section'>
          <img alt="Visning av filtre" src={fig4} />
          <h2>Visning av filtre</h2>
          <p>
            Hvert "dashboard" inneholder også et filterområde som viser hvor mye data som er i
            gjeldende utvalg. Når man først åpner "dashboardet" viser denne alle tilgjengelige data
            i datasettet som er valgt. Når man senerer "filterer" i enkelte diagrammer og kontroller
            i "dashboardet" oppdaterer dette feltet seg med informasjon om hvilke filtre som er
            aktive og hvor mye data som er vist etter filtrering.
          </p>
          <p>
            Det finnes her også en lenke hvor man kan trykke for å fjerne alle filtre fra
            "dashboardet" uten å måtte gå inn på hvert enkelt diagram/hver enkelt kontroll. Ved å
            trykke på denne nullstiller man datautvalget.
          </p>
        </div>
        <div className='section'>
          <h2>Nøkkeltall</h2>
          <img alt="Nøkkeltall" src={fig2} />
          <p>
            Denne typen visning benyttes for å fremheve nøkkeltall typiske grenseverdier,
            gjennomsnitt maks/min verdier etc.. Disse oppdateres løpende etterhvert som det
            filtreres i diagrammer/ kontroller. Ved bruk av disse tallene kan det vere nyttig å ha
            med seg filterutvalget for å kvalifisere tallene
          </p>
        </div>
        <div className='section'>
          <img alt="Avkryssingsbokser" src={fig16} />
          <h2>Avkryssningsbokser</h2>
          <p>
            Denne typen kontroll benyttes hvis du ønsker å filtrere utvalg ved hjelp av en eller
            flere unike verdier. Når ingen bokser er avkrysset vises alle verdier. Når en eller
            flere bokser avkrysses blir utvalget filtrert til disse verdiene. Tittelen sammen med
            merkelappen til hver boks skal være selvforklarende.
          </p>
        </div>
        <div className='section'>
          <img alt="Stolpediagram" src={fig25} />
          <h2>Stolpediagram (stående, liggende)</h2>
          <p>
            Den vanligste diagramtypen er ordinære stolpediagram. Stolpediagram kan filtreres enten
            ved å klikke på enkelte stolper eller ved å "dra" et utvalg over flere stolper ved å
            trykke ned og holde venstre museknapp mens man drar til høyre eller venstre. Utvalget
            kan tilbakestilles ved å trykke på knappen "Tilbakestill".
          </p>
          <p>
            Diagram kan lastes ned ved hjelp av lenkene "Last ned SVG" og "Last ned PNG". Denne
            funksjonen er generell for de fleste diagramvisninger.
          </p>
          <p>
            Liggende stolpediagram fungerer på samme måte som stående, men disse benyttes først og
            fremst for kategorier i stedet for numeriske skalaer. Denne typen diagram kan ikke
            filtreres i ved å dra ut et område over flere stolper, men du kan likevel filtrere ved å
            trykke på en eller flere stolper. En enkelt stolpe kan fjernes fra utvalget ved å klikke
            en gang til på den.
          </p>
        </div>
        <div className='section'>
          <h2>Kakediagram</h2>
          <img alt="Kakediagram" src={fig6} />
          <p>
            Kakediagram benyttes for å vise det relative forholdet mellom to eller flere variabler.
            Verdien som hvert kakestykke representerer er vist i parentes bak navnet. For
            kakestykker som er for små til at merkelappen vises kan man holde musepekeren over
            kakestykket for å se tittel og mengde. Du kan filtrere kakediagram ved å klikke på et
            eller flere kakestykker. Førstegangs klikk velger, andre gangs klikk (på samme
            kakestykke) tar bort fra utvalget. Kakestykker som ikke er med i utvalgte blir vist med
            grå farge.
          </p>
          <p>
            De samme nedlastingskontrollene som for stolpediagram er tilgjengelige. Last ned til
            SVG/PNG.
          </p>
        </div>
        <div className='section'>
          <img alt="Gruppert stolpediagram" src={fig7} />
          <h2>Gruppert stolpediagram</h2>
          <p>
            Disse diagrammene benyttes når du ønsker å vise relasjonen mellom tre eller flere
            variabler. Typisk kategori, tid og verdi som vist i eksempelet under. Når du gjør utvalg
            i grupperte stolpediagram velger du hele gruppen som stolpene tilhører, ikke hver enkelt
            serie eller individuelle stolper.
          </p>
        </div>
        <div className='section'>
          <img alt="Stablet stolpediagram" src={fig5} />
          <h2>Stablet stolpediagram</h2>
          <p>
            På samme måte som for grupperte stolpediagram kan stablede stolpediagram filtreres ved å
            klikke på hver stabel. Filteret fungerer for hele stabelen, ikke for hver enkelt serie
            eller enkelt lag i hver stabel.
          </p>
        </div>
        <div className='section'>
          <img alt="Tetthetsplott ('Heatmap')" src={fig23} />
          <h2>Tetthetsplott ("Heatmap")</h2>
          <p>
            I tetthetsplott er diagramområdet delt inn i rader og kolonner som viser hvordan en
            variabel varierer i forhold til to dimensjoner.
          </p>
          <p>
            I eksempelet nedenfor er det benyttet en fargeskala fra blått (lave verdier) via grønt,
            gult og oransje til rødt (høye verdier) for å illustrere hvordan trafikken varierer for
            timer i døgnet og ukedag.
          </p>
          <p>
            Du kan filtrere i slike diagrammer ved å trykke på enkeltceller. Førstegangs klikk
            velger, andregangs klikk på samme celle tar den bort fra utvalget. Celler som ikke er
            med i utvalget vises med grå farge.
          </p>
        </div>
        <div className='section'>
          <h2>Datatabeller</h2>
          <img alt="Datatabeller (1)" src={fig19} />
          <img alt="Datatabeller (2)" src={fig24} />
          <p>
            Mange "dashboard" inneholder datatabeller som viser de data som til enhver tid er med i
            utvalget etter filtrering i de ulike diagram/kontroller.
          </p>
          <p>
            Som standard viser tabeller 50 rader på hver side og du kan bla i tabeller ved å trykke
            på knappene &nbsp;"Neste side" og "Forrige side" rett over tabellen. En etikett under
            tabelltittelen viser hvilke rader som er synlige og hvor mange rader som finst totalt i
            utvalget.
          </p>
          <p>
            Det er mulig å sortere tabellen på en vilkårlig kolonne. Velg navnet på kolonnen du
            ønsker å sortere etter i nedtrekksmenyen og velg mellom stigende (lav-til-høy) og
            &nbsp;synkende (høy-til-lav) sorteringsrekkefølge.
          </p>
          <p>
            Tabellinnhold kan lastes ned i Microsoft Excel format ved hjelp av å trykke på lenken
            "Last ned tabell (Excel)" under datatabellen.
          </p>
        </div>
        <div className='section'>
          <img alt="Kartvisninger (1)" src={fig3} />
          <img alt="Kartvisninger (2)" src={fig15} />
          <h2>Kartvisninger</h2>
          <p>
            Noen "dashboard" viser kart. Kartvisninger er svært krevende for prosessor og minne på
            brukerens datamaskin og det vises til enhver tid bare et begrenset utvalg av dataene i
            kartet. Etterhvert som man filtrerer vil man kunne begrense utvalget til et volum som
            gjør til at all trafikk i utvalget blir synlig i kartet.
          </p>
        </div>
        <div className='section'>
          <img alt="Kartavspilling (1)" src={fig18} />
          <img alt="Kartavspilling (2)" src={fig22} />
          <h2>Kartavspilling</h2>
          <p>
            På noen "dashboard" kan man tilbakespille trafikken i et område og et tidsrom for alle
            eller et utvalg av de skipene som har vært aktive der. Denne typen visualisering er godt
            egnet for å dokumentere forløpet til en seilas etter en hendelse etc.
          </p>
          <p>
            Hvert kart har en tidslinje (plassert over kartet) hvor du kan dra en sirkel til
            høyre/venstre for å forflytte deg i tid. Mens du flytter markøren oppdateres
            skipstrafikken i kartet slik at du ser hvilke fartøy som har vært hvor til enhver tid.
          </p>
          <p>
            Når du har funnet det tidspunktet du er interessert i, kan du spille av seilasene i
            sanntid ved å trykke på "Play"-knappen under kartet. Du kan trykke "Pause" eller endre
            hastighet også.
          </p>
        </div>
      </div>
    );
  }
}

export default HelpView;
