import "rc-slider/assets/index.css";
import "./DbrdReplay.scss";

import crossfilter from "crossfilter2";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { PosUtils } from "../../chart-components/ChartUtils/PosUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { CBoxMenu } from "../../chart-components/Charts/CBoxMenu";
import { ReplayMapChart } from "../../chart-components/ChartsExt/ReplayMapChart";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import TimeLine from "../../chart-components/TimeLine/TimeLine";
import { CrossfilterRecord } from "../../custom";
import { SimpleTimespan } from "../../filters/SimpleDateTimeControl/SimpleTimespan";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../types/DashboardFilter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader, SuperagentProgress } from "../../ui-components/Loader/Loader";
import { DrawBoxInMap } from "../../ui-components/filter-view/FilterMap/DrawBoxInMap";
import { DrawPolygonInMap } from "../../ui-components/filter-view/FilterMap/DrawPolygonInMap";
import { KyvLayerSwitcher } from "../../ui-components/filter-view/FilterMap/kyv-layer-switcher/KyvLayerSwitcher";
import { isArrayN } from "../../utils/array/isArrayN";
import { geoJSONFormat, wktFormat } from "../../utils/geomUtils";

/* class decorator */
export function DbrdReplay() {
  // constructor(props) {
  //   super(props);
  const { state } = useLocation();

  const { geom, fromTime, toTime } = state as {
    geom: any;
    fromTime: string;
    toTime: string;
  };

  // Initialize properties

  const [startTime, endTime] = useMemo(() => {
    return [TimeUtils.isoParse(fromTime)!, TimeUtils.isoParse(toTime)!];
  }, [fromTime, toTime]);

  // const [currentTime, setCurrentTime] = useState<Date>(startTime);
  const [progressData, setProgressData] = useState<SuperagentProgress | null>();
  const [geoJSON, setGeoJSON] = useState<any>();
  const [trackGeoJSON, setTrackGeoJSON] = useState<any>();
  const [chartData, setChartData] = useState<any>();
  const [bbox, setBbox] = useState<number[]>([]);

  const handleReportProgress = useCallback(
    (progressData: SuperagentProgress) => {
      setProgressData(progressData);
    },
    []
  );

  useEffect(() => {
    var bbox: number[] | undefined = undefined;
    var wktGeom: string = "";
    if (isArrayN(geom, 1)) {
      const feat = geoJSONFormat.readFeature(geom[0]);
      wktGeom = wktFormat.writeFeature(feat);
      const featGeom = geoJSONFormat.readFeature(geom[0]).getGeometry();
      if (!featGeom) return;
      bbox = featGeom.getExtent();
    }

    async function doAsync() {
      try {
        if (!wktGeom || !startTime || !endTime) {
          throw new Error("Missing parameters");
        }
        const posInfo = await DataLoader.post(
          process.env.REACT_APP_DASHBOARD_WS_API +
            "/api/ais/positions/within-geom-time",
          {
            geom: wktGeom,
            start: fromTime,
            end: toTime,
            minSpeed: 0.5,
          },
          handleReportProgress
        );

        if (!isArrayN(posInfo.data, 1)) {
          throw new Error("No positions returned");
        }

        var uniqueMmsis: number[] = [];
        posInfo.data.forEach((pos: any[]) => {
          if (uniqueMmsis.indexOf(pos[0]) === -1) {
            uniqueMmsis.push(pos[0]);
          }
        });

        const statInfo = await DataLoader.postApi(
          "/api/ais_shipreg/statinfo/for-mmsis-time",
          {
            MmsiIds: uniqueMmsis,
            Start: startTime.getFullYear(),
            End: endTime.getFullYear(),
          },
          handleReportProgress
        );
        var timeSeries = PosUtils.createTimeSeries(posInfo.data, statInfo.data);

        setChartData(crossfilter(timeSeries));
        setBbox(bbox ?? []);
      } catch (error) {
        console.warn(error);
        setChartData(crossfilter([]));
      }
    }

    doAsync();

    return () => {};
  }, [endTime, fromTime, geom, handleReportProgress, startTime, toTime]);

  const setGeoJSONs = useCallback((geoJsonData: any) => {
    setGeoJSON(geoJsonData.positions);
    setTrackGeoJSON(geoJsonData.tracks);
  }, []);

  const download = useCallback(() => {
    const sep = ";";

    var fileData = [
      ["lat", "lng", "date_time_utc", "name", "mmsi", "imo", "callsign"].join(
        sep
      ),
    ];
    chartData.all().forEach((ship) => {
      ship.positions.forEach((pos) => {
        fileData.push(
          [
            pos[2],
            pos[3],
            pos[1].toJSON(),
            `"${ship.name}"`,
            ship.mmsi,
            ship.imo_num,
            `"${ship.callsign}"`,
          ].join(sep)
        );
      });
    });

    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(fileData.join("\r\n"))
    );
    element.setAttribute("download", "points.csv");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }, [chartData]);

  const [dimCount, segmentsPerShip] = useMemo(() => {
    if (!chartData) return [null, null];
    const dim = chartData.dimension((d: CrossfilterRecord) => {
      return d.mmsi + " - " + (d.name || "Ukjent navn");
    });
    const grp = dim.group().reduceCount();
    return [dim, grp];
  }, [chartData]);

  useEffect(() => {
    return () => {
      if (dimCount && "dispose" in dimCount) {
        dimCount.dispose();
      }
    };
  }, [dimCount]);

  if (!chartData || chartData.size() === 0) {
    return <Loader progressData={progressData!} chartData={chartData} />;
  }

  return (
    <div className="AppView">
      <AVIDashboard
        title="Skipstrafikk"
        desc={`Vis skipstrafikk i tidsrommet fra ${fromTime} til ${toTime} Alle tidspunkt er angitt i UTC`}
        group={dimCount.groupAll()}
        units="Gjennomseilinger"
        type="count"
        cfilter={chartData}
        spacing={20}
        useFlex
      >
        <AVIRow height={5}>
          <AVICol width={3}>
            <ReplayMapChart
              allowFullscreen={true}
              chartTitle="Kartvisning"
              bbox={bbox}
              geoJson={geoJSON}
              trackGeoJson={trackGeoJSON}
              height={4}
              labelProperty="mmsi"
              colorProperty="mmsi"
              useFlex
            >
              <KyvLayerSwitcher top="10px" right="10px" />
            </ReplayMapChart>
            <TimeLine
              fromTime={fromTime}
              toTime={toTime}
              chartData={chartData}
              setGeoJson={setGeoJSONs}
              startTime={TimeUtils.isoFormat(startTime)}
              utc
            />
            <Button variant="light" className="mx-auto" onClick={download}>
              Last ned punktdata med tidsstempel for videre analyse
            </Button>
          </AVICol>
          <AVICol>
            <div
              className="d-flex flex-column overflow-y-scroll flex-grow-1 kyv--scrollbar"
              style={{ flex: 1 }}
            >
              <div style={{ minHeight: 0 }}>
                <CBoxMenu
                  chartTitle="Avgrens til skip/mmsi nummer"
                  width={1}
                  height={0.5}
                  dimension={dimCount}
                  group={segmentsPerShip}
                  multiple
                  filterPrefix="Skip"
                  useFlex
                />
              </div>
            </div>
          </AVICol>
        </AVIRow>
        <AVIRow>
          <AVICol></AVICol>
        </AVIRow>
      </AVIDashboard>
    </div>
  );
}

DbrdReplay.dashboardRoute = "sporing";

DbrdReplay.dashboardFilters = (
  filter: DashboardFilter,
  setFilter: SetDashboardFilterFunc
) => {
  return {
    helpMessage:
      "Sporing av seilas innen et område genererer svært mye data. Det er ment benyttet for korte tidsrom og for små områder for å analysere hendelsesforløp. I områder med høy trafikk kan man velge inntil en dag om gangen; i områder med lav trafikk kan man velge inntil en 3 dager eller inntil 7 dager. Prøv deg frem. Gå tilbake og begrens utvalget hvis fremdriftsindikatoren blir stående å spinne på skjermen lenge.  Tidspunkt i dataene er angitt som UTC.",
    controls: [
      <SimpleTimespan
        key="flt-timespan"
        filter={filter}
        setFilter={setFilter}
        format="d.M.y HH:mm"
        minAge={4}
        maxDays={7}
        utc
      />,
      <DrawBoxInMap
        key="flt-box"
        name="geom"
        setFilter={setFilter}
        filter={filter}
      />,
      <DrawPolygonInMap
        key="flt-polygon"
        name="geom"
        setFilter={setFilter}
        filter={filter}
      />,
    ],
  };
};

DbrdReplay.dashboardSettings = () => {
  return {
    filterControls: [],
    selectableLayer: null,
  };
};

DbrdReplay.dashboardValidation = (filter) => {
  if (filter.fromTime && filter.toTime && filter.geom) {
    return true;
  }
};

// export const DbrdReplay = withRouter(DbrdReplayBase);

export default DbrdReplay;
