import React from "react";
import "./Spinner.scss";

export type SpinnerIcon =
  | "ship-1"
  | "ship-2"
  | "ship-3"
  | "ship-4"
  | "ship-5"
  | "ship-6"
  | "ship-7"
  | "rudder"
  | "lighthouse"
  | "delay"
  | "error"
  | "why-1"
  | "why-2";

export type SpinnerMotion =
  | "rotate-motion"
  | "rocking-motion"
  | "hflip-motion"
  | "shaking-motion";

export type SpinnerSize = "lg" | "nm" | "sm";

export type SpinnerProps = {
  icon?: SpinnerIcon;
  motion?: SpinnerMotion;
  size?: SpinnerSize;
};

export function Spinner({
  icon = "rudder",
  motion = "rocking-motion",
  size = "nm",
}: SpinnerProps) {
  return <div className={`kyv--spinner ${size} ${motion} ${icon}`}></div>;
}
