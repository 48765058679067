import * as d3 from "d3";

export class TimeUtils {
  static localeDays: [string, string, string, string, string, string, string] =
    ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"];

  static localeShortDays = TimeUtils.localeDays.map((d) =>
    d.substring(0, 3)
  ) as [string, string, string, string, string, string, string];

  static localeMonths: [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string
  ] = [
    "Januar",
    "Februar",
    "Mars",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];
  static localeShortMonths = TimeUtils.localeMonths.map((m) =>
    m.substring(0, 3)
  ) as [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string
  ];

  static locale = d3.timeFormatLocale({
    dateTime: "%a %b %e %X %Y",
    date: "%m/%d/%Y",
    time: "%H:%M:%S",
    periods: ["AM", "PM"],
    days: TimeUtils.localeDays,
    shortDays: TimeUtils.localeShortDays,
    months: TimeUtils.localeMonths,
    shortMonths: TimeUtils.localeShortMonths,
  });

  static localeSortMonthNameAbb(monthNameAbb) {
    return TimeUtils.localeShortMonths.indexOf(monthNameAbb);
  }

  static localeSortWeekDay(weekDay) {
    var i = TimeUtils.localeDays.indexOf(weekDay);
    return i > 0 ? i - 1 : 6;
  }

  static localeWeekDaySortFunction(weekDay1, weekDay2) {
    return (
      TimeUtils.localeSortWeekDay(weekDay1) -
      TimeUtils.localeSortWeekDay(weekDay2)
    );
  }

  static localeSortWeekDayAbb(weekDayAbb) {
    var i = TimeUtils.localeShortDays.indexOf(weekDayAbb);
    return i > 0 ? i - 1 : 6;
  }

  static localeWeekDayAbbSortFunction(weekDayAbb1, weekDayAbb2) {
    return (
      TimeUtils.localeSortWeekDayAbb(weekDayAbb1) -
      TimeUtils.localeSortWeekDayAbb(weekDayAbb2)
    );
  }

  static toTimestamp = TimeUtils.locale.format("%Y%m%d%H%M");

  static toYearMonth = TimeUtils.locale.format("%Y-%m");

  /**
   * Gets a month representation with an optional offset
   *
   * @param {*} datelike A datelike string or a date object
   * @param {*} monthOffset An optional positive or negative number indicating offset months
   * @returns A year-month string in the format "YYYY-mm"
   */
  static getYearMonth = (
    datelike,
    monthOffset: number | undefined | null = undefined
  ) => {
    var d = new Date(datelike);
    if (monthOffset) {
      d.setMonth(d.getMonth() + monthOffset);
    }
    return TimeUtils.toYearMonth(d);
  };

  static toCompactTimestring = TimeUtils.locale.format("%d.%m.%Y %H:%M");

  static toCompactTimeOnlystring = TimeUtils.locale.format("%H:%M");

  static toCompactDate = TimeUtils.locale.format("%Y-%m-%d");

  static getISOWeekNumber = TimeUtils.locale.format("%V");

  static getMondayBasedWeekNumber = TimeUtils.locale.format("%W");

  static weekDayOneChar = ["M", "T", "O", "T", "F", "L", "S"];
  static weekDayThreeChars = ["Man", "Tir", "Ons", "Tor", "Fre", "Lør", "Søn"];
  static weekDays = [
    "Mandag",
    "Tirsdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lørdag",
    "Søndag",
  ];

  static getWeekDay(zeroBasedIndex, zeroIsSunday = true) {
    if (zeroIsSunday) {
      return this.weekDays[zeroBasedIndex !== 0 ? zeroBasedIndex - 1 : 6];
    } else {
      return this.weekDays[zeroBasedIndex - 1];
    }
  }

  static getLocalWeekDayAbb = TimeUtils.locale.format("%a");
  static getLocalWeekDayFull = TimeUtils.locale.format("%A");
  static getLocalMonthNameAbb = TimeUtils.locale.format("%b");
  static getLocalMonthNameFull = TimeUtils.locale.format("%B");

  static getWeek = d3.timeFormat("%V");

  static formatTimeUTC(datelike) {
    datelike = new Date(datelike);
    return datelike.toLocaleString("nb-NO", {
      timeZone: "UTC",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }
  static formatTime(datelike) {
    datelike = new Date(datelike);
    return datelike.toLocaleString("nb-NO", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  static formatDateUTC(datelike) {
    datelike = new Date(datelike);
    return datelike.toLocaleString("nb-NO", {
      timeZone: "UTC",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  static formatDate(datelike) {
    datelike = new Date(datelike);
    return datelike.toLocaleString("nb-NO", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  static formatDateInYear(datelike) {
    datelike = new Date(datelike);
    return d3.timeFormat("%d.%m")(datelike);
  }

  static formatYMD(datelike) {
    datelike = new Date(datelike);
    return d3.timeFormat("%Y-%m-%d")(datelike);
  }

  static toDate(timestamplike) {
    // Convert to string
    timestamplike = timestamplike.toString();

    // Extract parts
    var year = timestamplike.substring(0, 4);
    var month = timestamplike.substring(4, 6);
    var day = timestamplike.substring(6, 8);
    var hour = timestamplike.substring(8, 10);
    var minute = timestamplike.substring(10, 12);

    // Construct date string and create date
    return new Date(year + "-" + month + "-" + day + "T" + hour + ":" + minute);
  }

  /**
   * Return a UTC date
   *
   * @param {*} year Year
   * @param {*} month Month of year 1-12
   * @param {*} day Day of month 1-31
   */
  static utcDate(year, month, day) {
    var d;
    if (day !== undefined && !isNaN(day) && day >= 1 && day <= 31) {
      d = new Date(year, month - 1, day);
    } else {
      d = new Date(year, month - 1);
    }
    d.setTime(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
    return d;
  }

  /**
   * Return a UTC date
   *
   * @param {*} year Year
   * @param {*} month Month of year 1-12
   * @param {*} day Day of month 1-31
   */
  static utcDate2(year, month, day) {
    var d;
    if (day !== undefined && !isNaN(day) && day >= 1 && day <= 31) {
      d = new Date(Date.UTC(year, month - 1, day));
    } else {
      d = new Date(Date.UTC(year, month - 1));
    }
    return d;
  }

  /**
   * Add a month to a date
   *
   * @param {*} currentDate JavaScript date object
   * @param {*} numMonths Number of months to add
   */
  static addMonths(currentDate, numMonths) {
    currentDate.setMonth(currentDate.getMonth() + numMonths);
    return currentDate;
  }

  static isDate(sDate: any) {
    if (sDate.toString() === parseInt(sDate).toString()) return false;
    var tryDate = new Date(sDate);
    return (
      tryDate &&
      tryDate.toString() !== "NaN" &&
      (tryDate as any) !== "Invalid Date"
    );
  }

  static dateSort = (a: Date, b: Date): number => {
    return new Date(a).getTime() - new Date(b).getTime();
  };

  static getDateDiff(dayDiff) {
    var d = new Date();
    d.setDate(d.getDate() + dayDiff);
    return d;
  }

  static getUtcDateDiff(dayDiff) {
    var d = new Date();
    d.setDate(d.getDate() + dayDiff);
    return d;
  }

  /**
   * Convert a local date to an UTC-date by ignoring the time-offset
   *
   * @param {string|Date} datelike A string or an object representing a date
   * @returns The input date corrected for the UTC-time offset
   */
  static dateToUTC(datelike) {
    let d = new Date(datelike);
    let userTimezoneOffset = d.getTimezoneOffset() * 60000;
    return new Date(d.getTime() + userTimezoneOffset);
  }

  static dateToUTC2(datelike) {
    var date = new Date(datelike);
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
    return new Date(now_utc);
  }

  static ensureDateUtc(dateLike) {
    return this.ensureDate(dateLike, true);
  }

  static ensureDate(dateLike, utc = false) {
    return utc ? new Date(dateLike) : TimeUtils.dateToUTC(new Date(dateLike));
  }

  // Calculate dates in year
  static getMonthsDays(
    baseYear: number,
    minDate: Date | undefined = undefined,
    maxDate: Date | undefined = undefined
  ) {
    var tmpMonths = {};
    var baseDate = TimeUtils.ensureDateUtc(new Date(baseYear, 0, 1));
    var baseMaxDate = TimeUtils.ensureDateUtc(new Date(baseYear + 1, 0, 1));
    minDate = minDate ? TimeUtils.ensureDateUtc(minDate) : baseDate;
    if (minDate instanceof Date) {
      baseDate = minDate;
    }
    if (maxDate instanceof Date && maxDate >= minDate) {
      baseMaxDate = maxDate;
    }
    for (
      var currentDate = baseDate;
      currentDate < baseMaxDate;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      // Make a copy of the date object
      var tmpDate = currentDate;
      var jsMonth = tmpDate.getMonth();
      if (!tmpMonths.hasOwnProperty(jsMonth)) {
        var monthName = currentDate.toLocaleString("default", {
          month: "long",
        });
        tmpMonths[jsMonth] = {
          name: monthName,
          dates: [],
        };
      }
      tmpMonths[jsMonth].dates.push(currentDate.getDate());
    }
    return tmpMonths;
  }

  static parseDateUtcYmdHM0 = d3.utcParse("%Y-%m-%dT%H:%M:00");

  static formatUtcTimestampYmdHM = d3.utcFormat("%Y%m%d%H%M");
  static formatUtcYmdTHM = d3.utcFormat("%Y-%m-%dT%H:%M");
  static formatUtcYmd = d3.utcFormat("%Y-%m-%d");

  /**
   * Parse a date tring in the format 2012-01-01T10:02 to a JavaScript date
   */
  static localParse: (s: string) => Date = (s: string) => {
    let d = d3.timeParse("%Y-%m-%dT%H:%M:00")(s);
    if (!d) throw new Error("Error could not parse to date");
    return d;
  };

  /**
   * Format a JavaScript date as a date string in the format 2012-01-01T10:02:00
   */
  static localFormat: (d: Date) => string = d3.timeFormat("%Y-%m-%dT%H:%M:00");

  /**
   * Parse a string in the ISO format to a JavaScript date
   */
  static isoParse: (s: string) => Date = (s: string) => {
    const d = d3.isoParse(s);
    if (!d) throw new Error('Error could not parse to date');
    return d;
  };

  /**
   * Format a JavaScript date as an ISO-string
   */
  static isoFormat: (d: Date) => string = d3.isoFormat;

  /**
   * Parse a string in the format 2012-01-01T10:00:02 to a UTC date
   */
  static utcParseToScondNoTz = d3.utcParse("%Y-%m-%dT%H:%M:%S");
}
