export const County = {
  "03": "Oslo",
  30: "Viken",
  34: "Innlandet",
  38: "Vestfold og Telemark",
  42: "Agder",
  11: "Rogaland",
  46: "Vestland",
  15: "Møre og Romsdal",
  50: "Trøndelag",
  18: "Nordland",
  54: "Troms og Finnmark",
  21: "Svalbard",
  22: "Jan Mayen",
  SV: "Svalbard og Jan Mayen",
};

export const Municipality = {
  4213: "Tvedestrand",
  4214: "Froland",
  3813: "Bamble",
  1112: "Lund",
  4226: "Hægebostad",
  1101: "Eigersund",
  4219: "Evje og Hornnes",
  5056: "Hitra",
  1804: "Bodø",
  4201: "Risør",
  4217: "Åmli",
  5423: "Karlsøy",
  1845: "Sørfold",
  1856: "Røst",
  4212: "Vegårshei",
  5434: "Måsøy",
  1857: "Værøy",
  1119: "Hå",
  4227: "Kvinesdal",
  1114: "Bjerkreim",
  4224: "Åseral",
  5440: "Berlevåg",
  3814: "Kragerø",
  1121: "Time",
  1120: "Klepp",
  4211: "Gjerstad",
  3804: "Sandefjord",
  3011: "Hvaler",
  4206: "Farsund",
  5435: "Nordkapp",
  3811: "Færder",
  3820: "Seljord",
  4215: "Lillesand",
  4204: "Kristiansand",
  5438: "Lebesby",
  1130: "Strand",
  3815: "Drangedal",
  1108: "Sandnes",
  4202: "Grimstad",
  4223: "Vennesla",
  4205: "Lindesnes",
  4225: "Lyngdal",
  5439: "Gamvik",
  4218: "Iveland",
  5031: "Malvik",
  4203: "Arendal",
  1111: "Sokndal",
  4216: "Birkenes",
  1576: "Aure",
  3803: "Tønsberg",
  4207: "Flekkefjord",
  1839: "Beiarn",
  1836: "Rødøy",
  5403: "Alta",
  5427: "Skjervøy",
  5444: "Sør-Varanger",
  1516: "Ulstein",
  5025: "Røros",
  5432: "Loppa",
  1874: "Moskenes",
  5442: "Nesseby",
  1859: "Flakstad",
  5405: "Vadsø",
  5436: "Porsanger",
  1860: "Vestvågøy",
  5433: "Hasvik",
  5441: "Tana",
  5404: "Vardø",
  5406: "Hammerfest",
  5443: "Båtsfjord",
  5028: "Melhus",
  5029: "Skaun",
  1579: "Hustadvika",
  1554: "Averøy",
  5001: "Trondheim",
  1840: "Saltdal",
  1838: "Gildeskål",
  5055: "Heim",
  5034: "Meråker",
  3443: "Vestre Toten",
  3407: "Gjøvik",
  1837: "Meløy",
  5035: "Stjørdal",
  1505: "Kristiansund",
  1841: "Fauske",
  5036: "Frosta",
  5059: "Orkland",
  5037: "Levanger",
  1848: "Steigen",
  5054: "Indre Fosen",
  1573: "Smøla",
  5053: "Inderøy",
  5038: "Verdal",
  5057: "Ørland",
  1875: "Hamarøy",
  1853: "Evenes",
  1865: "Vågan",
  5006: "Steinkjer",
  5041: "Snåsa",
  1806: "Narvik",
  5058: "Åfjord",
  5014: "Frøya",
  1851: "Lødingen",
  5020: "Osen",
  5047: "Overhalla",
  5042: "Lierne",
  1866: "Hadsel",
  5045: "Grong",
  5412: "Tjeldsund",
  5007: "Namsos",
  5049: "Flatanger",
  5043: "Røyrvik",
  5046: "Høylandet",
  1867: "Bø",
  1870: "Sortland",
  5044: "Namsskogan",
  5419: "Sørreisa",
  5414: "Gratangen",
  1828: "Nesna",
  1827: "Dønna",
  5415: "Lavangen",
  1818: "Herøy",
  5060: "Nærøysund",
  5052: "Leka",
  1811: "Bindal",
  5411: "Kvæfjord",
  5413: "Ibestad",
  5437: "Karasjok",
  5429: "Kvænangen",
  5417: "Salangen",
  1812: "Sømna",
  1813: "Brønnøy",
  1825: "Grane",
  5420: "Dyrøy",
  1816: "Vevelstad",
  5416: "Bardu",
  1868: "Øksnes",
  1826: "Hattfjelldal",
  1815: "Vega",
  1820: "Alstahaug",
  1106: "Haugesund",
  5402: "Harstad",
  5418: "Målselv",
  5425: "Storfjord",
  5422: "Balsfjord",
  1824: "Vefsn",
  4228: "Sirdal",
  3048: "Øvre Eiker",
  3819: "Hjartdal",
  1822: "Leirfjord",
  3433: "Skjåk",
  1871: "Andøy",
  5421: "Senja",
  3425: "Engerdal",
  5426: "Gáivuotna",
  5430: "Kautokeino",
  4637: "Hyllestad",
  5428: "Nordreisa",
  1832: "Hemnes",
  1834: "Lurøy",
  1833: "Rana",
  1835: "Træna",
  5424: "Lyngen",
  1560: "Tingvoll",
  5061: "Rindal",
  5401: "Tromsø",
  4638: "Høyanger",
  3453: "Øystre Slidre",
  5022: "Rennebu",
  4647: "Sunnfjord",
  3436: "Nord-Fron",
  1563: "Sunndal",
  1122: "Gjesdal",
  4220: "Bygland",
  3816: "Nome",
  5026: "Holtålen",
  1532: "Giske",
  3824: "Tokke",
  3014: "Indre Østfold",
  3021: "Ås",
  3023: "Nesodden",
  4618: "Ullensvang",
  3007: "Ringerike",
  3037: "Hurdal",
  3414: "Nord-Odal",
  3454: "Vang",
  4612: "Sveio",
  4643: "Årdal",
  3043: "Ål",
  1557: "Gjemnes",
  4601: "Bergen",
  3418: "Åsnes",
  3447: "Søndre Land",
  5027: "Midtre Gauldal",
  5033: "Tydal",
  1566: "Surnadal",
  3022: "Frogn",
  1146: "Tysvær",
  1151: "Utsira",
  3006: "Kongsberg",
  3446: "Gran",
  3035: "Eidsvoll",
  4636: "Solund",
  4646: "Fjaler",
  4648: "Bremanger",
  3421: "Trysil",
  3823: "Fyresdal",
  3416: "Eidskog",
  4640: "Sogndal",
  1507: "Ålesund",
  3806: "Porsgrunn",
  5032: "Selbu",
  1547: "Aukra",
  4222: "Bykle",
  3020: "Nordre Follo",
  3052: "Nore og Uvdal",
  4624: "Bjørnafjorden",
  3039: "Flå",
  3439: "Ringebu",
  4645: "Askvoll",
  3028: "Enebakk",
  1149: "Karmøy",
  3005: "Drammen",
  4619: "Eidfjord",
  3438: "Sør-Fron",
  3423: "Stor-Elvdal",
  3437: "Sel",
  3001: "Halden",
  1124: "Sola",
  3025: "Asker",
  3040: "Nesbyen",
  4622: "Kvam",
  4644: "Luster",
  3435: "Vågå",
  3012: "Aremark",
  3004: "Fredrikstad",
  1127: "Randaberg",
  3822: "Nissedal",
  4623: "Samnanger",
  3442: "Østre Toten",
  4602: "Kinn",
  3413: "Stange",
  4650: "Gloppen",
  3434: "Lom",
  3027: "Rælingen",
  3029: "Lørenskog",
  1134: "Suldal",
  4620: "Ulvik",
  3449: "Sør-Aurdal",
  3424: "Rendalen",
  4651: "Stryn",
  3003: "Sarpsborg",
  3017: "Råde",
  1144: "Kvitsøy",
  3808: "Notodden",
  3049: "Lier",
  3026: "Aurskog-Høland",
  1160: "Vindafjord",
  4630: "Osterøy",
  3044: "Hol",
  3419: "Våler",
  3428: "Alvdal",
  3041: "Gol",
  4627: "Askøy",
  3024: "Bærum",
  3050: "Flesberg",
  1135: "Sauda",
  3030: "Lillestrøm",
  1511: "Vanylven",
  1577: "Volda",
  3412: "Løten",
  3431: "Dovre",
  3429: "Folldal",
  3432: "Lesja",
  3016: "Rakkestad",
  3812: "Siljan",
  3032: "Gjerdrum",
  4626: "Øygarden",
  4628: "Vaksdal",
  3031: "Nittedal",
  3801: "Horten",
  301: "Oslo",
  3038: "Hole",
  4631: "Alver",
  4621: "Voss",
  3450: "Etnedal",
  3420: "Elverum",
  4632: "Austrheim",
  4614: "Stord",
  3047: "Modum",
  3042: "Hemsedal",
  3448: "Nordre Land",
  4633: "Fedje",
  1525: "Stranda",
  4615: "Fitjar",
  3405: "Lillehammer",
  1520: "Ørsta",
  3805: "Larvik",
  3807: "Skien",
  3051: "Rollag",
  4641: "Aurland",
  3403: "Hamar",
  1517: "Hareid",
  1531: "Sula",
  4634: "Masfjorden",
  3426: "Tolga",
  1578: "Fjord",
  1528: "Sykkylven",
  3018: "Våler",
  1514: "Sande",
  4221: "Valle",
  1145: "Bokn",
  3015: "Skiptvet",
  1103: "Stavanger",
  1133: "Hjelmeland",
  3818: "Tinn",
  3034: "Nes",
  3054: "Lunner",
  3002: "Moss",
  4616: "Tysnes",
  4611: "Etne",
  3033: "Ullensaker",
  4613: "Bømlo",
  3825: "Vinje",
  4629: "Modalen",
  4649: "Stad",
  3430: "Os",
  3427: "Tynset",
  3036: "Nannestad",
  3415: "Sør-Odal",
  3401: "Kongsvinger",
  3452: "Vestre Slidre",
  3451: "Nord-Aurdal",
  1539: "Rauma",
  1535: "Vestnes",
  3817: "Midt-Telemark",
  4635: "Gulen",
  5021: "Oppdal",
  3019: "Vestby",
  3013: "Marker",
  3046: "Krødsherad",
  3053: "Jevnaker",
  3411: "Ringsaker",
  4642: "Lærdal",
  1515: "Herøy",
  1506: "Molde",
  4639: "Vik",
  3821: "Kviteseid",
  3802: "Holmestrand",
  3045: "Sigdal",
  4617: "Kvinnherad",
  4625: "Austevoll",
  3417: "Grue",
  3441: "Gausdal",
  3440: "Øyer",
  3422: "Åmot",
};

export const CountryCode = [
  {
    name: "RWANDA",
    "alpha-2": "RW",
    "alpha-3": "RWA",
  },
  {
    name: "THAILAND",
    "alpha-2": "TH",
    "alpha-3": "THA",
  },
  {
    name: "SOMALIA",
    "alpha-2": "SO",
    "alpha-3": "SOM",
  },
  {
    name: "VIETNAM",
    "alpha-2": "VN",
    "alpha-3": "VNM",
  },
  {
    name: "NORDRE MARIANENE",
    "alpha-2": "MP",
    "alpha-3": "MNP",
  },
  {
    name: "LIBANON",
    "alpha-2": "LB",
    "alpha-3": "LBN",
  },
  {
    name: "ANGUILLA",
    "alpha-2": "AI",
    "alpha-3": "AIA",
  },
  {
    name: "ECUADOR",
    "alpha-2": "EC",
    "alpha-3": "ECU",
  },
  {
    name: "NAMIBIA",
    "alpha-2": "NA",
    "alpha-3": "NAM",
  },
  {
    name: "LAOS, DEN DEMOKRATISKE FOLKEREPUBLIKKEN",
    "alpha-2": "LA",
    "alpha-3": "LAO",
  },
  {
    name: "FRANSK GUYANA",
    "alpha-2": "GF",
    "alpha-3": "GUF",
  },
  {
    name: "SAINT MARTIN",
    "alpha-2": "SX",
    "alpha-3": "SXM",
  },
  {
    name: "SAINT VINCENT OG GRENADINENE",
    "alpha-2": "VC",
    "alpha-3": "VCT",
  },
  {
    name: "PALAU",
    "alpha-2": "PW",
    "alpha-3": "PLW",
  },
  {
    name: "NIUE",
    "alpha-2": "NU",
    "alpha-3": "NIU",
  },
  {
    name: "MIKRONESIAFØDERASJONEN",
    "alpha-2": "FM",
    "alpha-3": "FSM",
  },
  {
    name: "ARMENIA",
    "alpha-2": "AM",
    "alpha-3": "ARM",
  },
  {
    name: "VATIKANSTATEN",
    "alpha-2": "VA",
    "alpha-3": "VAT",
  },
  {
    name: "VEST-SAHARA",
    "alpha-2": "EH",
    "alpha-3": "ESH",
  },
  {
    name: "KONGO, REPUBLIKKEN",
    "alpha-2": "CG",
    "alpha-3": "COG",
  },
  {
    name: "ITALIA",
    "alpha-2": "IT",
    "alpha-3": "ITA",
  },
  {
    name: "LATVIA",
    "alpha-2": "LV",
    "alpha-3": "LVA",
  },
  {
    name: "ESTLAND",
    "alpha-2": "EE",
    "alpha-3": "EST",
  },
  {
    name: "ANGOLA",
    "alpha-2": "AO",
    "alpha-3": "AGO",
  },
  {
    name: "JEMEN",
    "alpha-2": "YE",
    "alpha-3": "YEM",
  },
  {
    name: "TSJEKKIA",
    "alpha-2": "CZ",
    "alpha-3": "CZE",
  },
  {
    name: "FRANKRIKE",
    "alpha-2": "FR",
    "alpha-3": "FRA",
  },
  {
    name: "BRASIL",
    "alpha-2": "BR",
    "alpha-3": "BRA",
  },
  {
    name: "TSJAD",
    "alpha-2": "TD",
    "alpha-3": "TCD",
  },
  {
    name: "PAKISTAN",
    "alpha-2": "PK",
    "alpha-3": "PAK",
  },
  {
    name: "KROATIA",
    "alpha-2": "HR",
    "alpha-3": "HRV",
  },
  {
    name: "COLOMBIA",
    "alpha-2": "CO",
    "alpha-3": "COL",
  },
  {
    name: "GUYANA",
    "alpha-2": "GY",
    "alpha-3": "GUY",
  },
  {
    name: "TAIWAN, PROVINS AV KINA",
    "alpha-2": "TW",
    "alpha-3": "TWN",
  },
  {
    name: "NEW ZEALAND",
    "alpha-2": "NZ",
    "alpha-3": "NZL",
  },
  {
    name: "LIBERIA",
    "alpha-2": "LR",
    "alpha-3": "LBR",
  },
  {
    name: "SPANIA",
    "alpha-2": "ES",
    "alpha-3": "ESP",
  },
  {
    name: "MYANMAR",
    "alpha-2": "MM",
    "alpha-3": "MMR",
  },
  {
    name: "KIRGISISTAN",
    "alpha-2": "KG",
    "alpha-3": "KGZ",
  },
  {
    name: "BURKINA FASO",
    "alpha-2": "BF",
    "alpha-3": "BFA",
  },
  {
    name: "MARSHALLØYENE",
    "alpha-2": "MH",
    "alpha-3": "MHL",
  },
  {
    name: "ROMANIA",
    "alpha-2": "RO",
    "alpha-3": "ROU",
  },
  {
    name: "ELFENBENSKYSTEN",
    "alpha-2": "CI",
    "alpha-3": "CIV",
  },
  {
    name: "WALLIS OG FUTUNA",
    "alpha-2": "WF",
    "alpha-3": "WLF",
  },
  {
    name: "VESTBREDDEN/GAZASTRIPEN",
    "alpha-2": "PS",
    "alpha-3": "PSE",
  },
  {
    name: "BARBADOS",
    "alpha-2": "BB",
    "alpha-3": "BRB",
  },
  {
    name: "SERBIA",
    "alpha-2": "RS",
    "alpha-3": "SRB",
  },
  {
    name: "MADAGASKAR",
    "alpha-2": "MG",
    "alpha-3": "MDG",
  },
  {
    name: "SENTRALAFRIKANSKE REPUBLIKK",
    "alpha-2": "CF",
    "alpha-3": "CAF",
  },
  {
    name: "BRUNEI",
    "alpha-2": "BN",
    "alpha-3": "BRN",
  },
  {
    name: "AUSTRALIA",
    "alpha-2": "AU",
    "alpha-3": "AUS",
  },
  {
    name: "MAROKKO",
    "alpha-2": "MA",
    "alpha-3": "MAR",
  },
  {
    name: "MOLDOVA",
    "alpha-2": "MD",
    "alpha-3": "MDA",
  },
  {
    name: "KAMERUN",
    "alpha-2": "CM",
    "alpha-3": "CMR",
  },
  {
    name: "SVALBARD OG JAN MAYEN",
    "alpha-2": "SJ",
    "alpha-3": "SJM",
  },
  {
    name: "MARTINIQUE",
    "alpha-2": "MQ",
    "alpha-3": "MTQ",
  },
  {
    name: "SAINT LUCIA",
    "alpha-2": "LC",
    "alpha-3": "LCA",
  },
  {
    name: "SAINT PIERRE OG MIQUELON",
    "alpha-2": "PM",
    "alpha-3": "SPM",
  },
  {
    name: "MALAWI",
    "alpha-2": "MW",
    "alpha-3": "MWI",
  },
  {
    name: "GEORGIA",
    "alpha-2": "GE",
    "alpha-3": "GEO",
  },
  {
    name: "SAUDI-ARABIA",
    "alpha-2": "SA",
    "alpha-3": "SAU",
  },
  {
    name: "BULGARIA",
    "alpha-2": "BG",
    "alpha-3": "BGR",
  },
  {
    name: "JORDAN",
    "alpha-2": "JO",
    "alpha-3": "JOR",
  },
  {
    name: "FIJI",
    "alpha-2": "FJ",
    "alpha-3": "FJI",
  },
  {
    name: "GUERNSEY",
    "alpha-2": "GG",
    "alpha-3": "GGY",
  },
  {
    name: "SLOVAKIA",
    "alpha-2": "SK",
    "alpha-3": "SVK",
  },
  {
    name: "KOMORENE",
    "alpha-2": "KM",
    "alpha-3": "COM",
  },
  {
    name: "DANMARK",
    "alpha-2": "DK",
    "alpha-3": "DNK",
  },
  {
    name: "KENYA",
    "alpha-2": "KE",
    "alpha-3": "KEN",
  },
  {
    name: "KUWAIT",
    "alpha-2": "KW",
    "alpha-3": "KWT",
  },
  {
    name: "SVEITS",
    "alpha-2": "CH",
    "alpha-3": "CHE",
  },
  {
    name: "SUDAN",
    "alpha-2": "SD",
    "alpha-3": "SDN",
  },
  {
    name: "ERITREA",
    "alpha-2": "ER",
    "alpha-3": "ERI",
  },
  {
    name: "ISLE OF MAN",
    "alpha-2": "IM",
    "alpha-3": "IMN",
  },
  {
    name: "KIRIBATI",
    "alpha-2": "KI",
    "alpha-3": "KIR",
  },
  {
    name: "NEDERLAND",
    "alpha-2": "NL",
    "alpha-3": "NLD",
  },
  {
    name: "USA",
    "alpha-2": "US",
    "alpha-3": "USA",
  },
  {
    name: "SAN MARINO",
    "alpha-2": "SM",
    "alpha-3": "SMR",
  },
  {
    name: "CANADA",
    "alpha-2": "CA",
    "alpha-3": "CAN",
  },
  {
    name: "DE FORENTE ARABISKE EMIRATER",
    "alpha-2": "AE",
    "alpha-3": "ARE",
  },
  {
    name: "ALGERIE",
    "alpha-2": "DZ",
    "alpha-3": "DZA",
  },
  {
    name: "KASAKHSTAN",
    "alpha-2": "KZ",
    "alpha-3": "KAZ",
  },
  {
    name: "MONACO",
    "alpha-2": "MC",
    "alpha-3": "MCO",
  },
  {
    name: "KYPROS",
    "alpha-2": "CY",
    "alpha-3": "CYP",
  },
  {
    name: "KOREA, DEN DEMOKRATISKE FOLKEREPUBLIKKEN (NORD-)",
    "alpha-2": "KP",
    "alpha-3": "PRK",
  },
  {
    name: "MAYOTTE",
    "alpha-2": "YT",
    "alpha-3": "MYT",
  },
  {
    name: "GRØNLAND",
    "alpha-2": "GL",
    "alpha-3": "GRL",
  },
  {
    name: "PORTUGAL",
    "alpha-2": "PT",
    "alpha-3": "PRT",
  },
  {
    name: "ANTARKTIS",
    "alpha-2": "AQ",
    "alpha-3": "ATA",
  },
  {
    name: "TYSKLAND",
    "alpha-2": "DE",
    "alpha-3": "DEU",
  },
  {
    name: "SRI LANKA",
    "alpha-2": "LK",
    "alpha-3": "LKA",
  },
  {
    name: "DEN DOMINIKANSKE REPUBLIKKEN",
    "alpha-2": "DO",
    "alpha-3": "DOM",
  },
  {
    name: "GUATEMALA",
    "alpha-2": "GT",
    "alpha-3": "GTM",
  },
  {
    name: "COOKØYENE",
    "alpha-2": "CK",
    "alpha-3": "COK",
  },
  {
    name: "BELGIA",
    "alpha-2": "BE",
    "alpha-3": "BEL",
  },
  {
    name: "NIGERIA",
    "alpha-2": "NG",
    "alpha-3": "NGA",
  },
  {
    name: "GUADELOUPE",
    "alpha-2": "GP",
    "alpha-3": "GLP",
  },
  {
    name: "ØST-TIMOR",
    "alpha-2": "TL",
    "alpha-3": "TLS",
  },
  {
    name: "FRANSKE SØRLIGE TERRITORIER",
    "alpha-2": "TF",
    "alpha-3": "ATF",
  },
  {
    name: "VENEZUELA",
    "alpha-2": "VE",
    "alpha-3": "VEN",
  },
  {
    name: "SAO TOME OG PRINCIPE",
    "alpha-2": "ST",
    "alpha-3": "STP",
  },
  {
    name: "BHUTAN",
    "alpha-2": "BT",
    "alpha-3": "BTN",
  },
  {
    name: "KONGO, DEN DEMOKRATISKE REPUBLIKKEN",
    "alpha-2": "CD",
    "alpha-3": "COD",
  },
  {
    name: "KINA",
    "alpha-2": "CN",
    "alpha-3": "CHN",
  },
  {
    name: "CUBA",
    "alpha-2": "CU",
    "alpha-3": "CUB",
  },
  {
    name: "MAKEDONIA",
    "alpha-2": "MK",
    "alpha-3": "MKD",
  },
  {
    name: "INTERNASJONALT FARVANN",
    "alpha-2": "XZ",
    "alpha-3": "XZX",
  },
  {
    name: "BRITISK TERRITORIUM I INDIAHAVET",
    "alpha-2": "IO",
    "alpha-3": "IOT",
  },
  {
    name: "USAS YTRE SMÅØYER",
    "alpha-2": "UM",
    "alpha-3": "UMI",
  },
  {
    name: "SØR-AFRIKA",
    "alpha-2": "ZA",
    "alpha-3": "ZAF",
  },
  {
    name: "TUNISIA",
    "alpha-2": "TN",
    "alpha-3": "TUN",
  },
  {
    name: "KOKOSØYENE",
    "alpha-2": "CC",
    "alpha-3": "CCK",
  },
  {
    name: "MAURITIUS",
    "alpha-2": "MU",
    "alpha-3": "MUS",
  },
  {
    name: "LUXEMBOURG",
    "alpha-2": "LU",
    "alpha-3": "LUX",
  },
  {
    name: "NORGE",
    "alpha-2": "NO",
    "alpha-3": "NOR",
  },
  {
    name: "LIECHTENSTEIN",
    "alpha-2": "LI",
    "alpha-3": "LIE",
  },
  {
    name: "MONTSERRAT",
    "alpha-2": "MS",
    "alpha-3": "MSR",
  },
  {
    name: "KAPP VERDE",
    "alpha-2": "CV",
    "alpha-3": "CPV",
  },
  {
    name: "DOMINICA",
    "alpha-2": "DM",
    "alpha-3": "DMA",
  },
  {
    name: "TADSJIKISTAN",
    "alpha-2": "TJ",
    "alpha-3": "TJK",
  },
  {
    name: "VANUATU",
    "alpha-2": "VU",
    "alpha-3": "VUT",
  },
  {
    name: "STORBRITANNIA",
    "alpha-2": "GB",
    "alpha-3": "GBR",
  },
  {
    name: "SURINAM",
    "alpha-2": "SR",
    "alpha-3": "SUR",
  },
  {
    name: "HELLAS",
    "alpha-2": "GR",
    "alpha-3": "GRC",
  },
  {
    name: "TURKS OG CAICOSØYENE",
    "alpha-2": "TC",
    "alpha-3": "TCA",
  },
  {
    name: "GHANA",
    "alpha-2": "GH",
    "alpha-3": "GHA",
  },
  {
    name: "CAYMANØYENE",
    "alpha-2": "KY",
    "alpha-3": "CYM",
  },
  {
    name: "PAPUA NY-GUINEA",
    "alpha-2": "PG",
    "alpha-3": "PNG",
  },
  {
    name: "NICARAGUA",
    "alpha-2": "NI",
    "alpha-3": "NIC",
  },
  {
    name: "ETIOPIA",
    "alpha-2": "ET",
    "alpha-3": "ETH",
  },
  {
    name: "SLOVENIA",
    "alpha-2": "SI",
    "alpha-3": "SVN",
  },
  {
    name: "NEPAL",
    "alpha-2": "NP",
    "alpha-3": "NPL",
  },
  {
    name: "EGYPT",
    "alpha-2": "EG",
    "alpha-3": "EGY",
  },
  {
    name: "MALAYSIA",
    "alpha-2": "MY",
    "alpha-3": "MYS",
  },
  {
    name: "SVERIGE",
    "alpha-2": "SE",
    "alpha-3": "SWE",
  },
  {
    name: "SAMOA",
    "alpha-2": "WS",
    "alpha-3": "WSM",
  },
  {
    name: "BELIZE",
    "alpha-2": "BZ",
    "alpha-3": "BLZ",
  },
  {
    name: "ISRAEL",
    "alpha-2": "IL",
    "alpha-3": "ISR",
  },
  {
    name: "SAINT KITTS OG NEVIS",
    "alpha-2": "KN",
    "alpha-3": "KNA",
  },
  {
    name: "DJIBOUTI",
    "alpha-2": "DJ",
    "alpha-3": "DJI",
  },
  {
    name: "SAINT BARTHELEMY",
    "alpha-2": "BL",
    "alpha-3": "BLM",
  },
  {
    name: "PUERTO RICO",
    "alpha-2": "PR",
    "alpha-3": "PRI",
  },
  {
    name: "EKVATORIAL-GUINEA",
    "alpha-2": "GQ",
    "alpha-3": "GNQ",
  },
  {
    name: "JAMAICA",
    "alpha-2": "JM",
    "alpha-3": "JAM",
  },
  {
    name: "QATAR",
    "alpha-2": "QA",
    "alpha-3": "QAT",
  },
  {
    name: "SWAZILAND",
    "alpha-2": "SZ",
    "alpha-3": "SWZ",
  },
  {
    name: "ANTIGUA OG BARBUDA",
    "alpha-2": "AG",
    "alpha-3": "ATG",
  },
  {
    name: "COSTA RICA",
    "alpha-2": "CR",
    "alpha-3": "CRI",
  },
  {
    name: "JOMFRUØYENE (BRITISKE)",
    "alpha-2": "VG",
    "alpha-3": "VGB",
  },
  {
    name: "ÅLAND",
    "alpha-2": "AX",
    "alpha-3": "ALA",
  },
  {
    name: "JAPAN",
    "alpha-2": "JP",
    "alpha-3": "JPN",
  },
  {
    name: "ZIMBABWE",
    "alpha-2": "ZW",
    "alpha-3": "ZWE",
  },
  {
    name: "POLEN",
    "alpha-2": "PL",
    "alpha-3": "POL",
  },
  {
    name: "TUVALU",
    "alpha-2": "TV",
    "alpha-3": "TUV",
  },
  {
    name: "ASERBAJDSJAN",
    "alpha-2": "AZ",
    "alpha-3": "AZE",
  },
  {
    name: "NY-CALEDONIA",
    "alpha-2": "NC",
    "alpha-3": "NCL",
  },
  {
    name: "MONTENEGRO",
    "alpha-2": "ME",
    "alpha-3": "MNE",
  },
  {
    name: "USBEKISTAN",
    "alpha-2": "UZ",
    "alpha-3": "UZB",
  },
  {
    name: "ISLAND",
    "alpha-2": "IS",
    "alpha-3": "ISL",
  },
  {
    name: "HONGKONG, KINA",
    "alpha-2": "HK",
    "alpha-3": "HKG",
  },
  {
    name: "NAURU",
    "alpha-2": "NR",
    "alpha-3": "NRU",
  },
  {
    name: "MACAO, KINA",
    "alpha-2": "MO",
    "alpha-3": "MAC",
  },
  {
    name: "FÆRØYENE",
    "alpha-2": "FO",
    "alpha-3": "FRO",
  },
  {
    name: "CURACAO",
    "alpha-2": "CW",
    "alpha-3": "CUW",
  },
  {
    name: "AMERIKANSK SAMOA",
    "alpha-2": "AS",
    "alpha-3": "ASM",
  },
  {
    name: "INDONESIA",
    "alpha-2": "ID",
    "alpha-3": "IDN",
  },
  {
    name: "NORFOLKØYA",
    "alpha-2": "NF",
    "alpha-3": "NFK",
  },
  {
    name: "NIGER",
    "alpha-2": "NE",
    "alpha-3": "NER",
  },
  {
    name: "URUGUAY",
    "alpha-2": "UY",
    "alpha-3": "URY",
  },
  {
    name: "ARUBA",
    "alpha-2": "AW",
    "alpha-3": "ABW",
  },
  {
    name: "BAHRAIN",
    "alpha-2": "BH",
    "alpha-3": "BHR",
  },
  {
    name: "TANZANIA, DEN FORENTE REPUBLIKKEN",
    "alpha-2": "TZ",
    "alpha-3": "TZA",
  },
  {
    name: "TRINIDAD OG TOBAGO",
    "alpha-2": "TT",
    "alpha-3": "TTO",
  },
  {
    name: "LITAUEN",
    "alpha-2": "LT",
    "alpha-3": "LTU",
  },
  {
    name: "PARAGUAY",
    "alpha-2": "PY",
    "alpha-3": "PRY",
  },
  {
    name: "FINLAND",
    "alpha-2": "FI",
    "alpha-3": "FIN",
  },
  {
    name: "GABON",
    "alpha-2": "GA",
    "alpha-3": "GAB",
  },
  {
    name: "PERU",
    "alpha-2": "PE",
    "alpha-3": "PER",
  },
  {
    name: "KAMBODSJA",
    "alpha-2": "KH",
    "alpha-3": "KHM",
  },
  {
    name: "TOKELAU",
    "alpha-2": "TK",
    "alpha-3": "TKL",
  },
  {
    name: "LIBYA, DEN SOSIALISTISKE ARABISKE FOLKEREPUBLIKKEN",
    "alpha-2": "LY",
    "alpha-3": "LBY",
  },
  {
    name: "HVITERUSSLAND",
    "alpha-2": "BY",
    "alpha-3": "BLR",
  },
  {
    name: "CHRISTMASØYA",
    "alpha-2": "CX",
    "alpha-3": "CXR",
  },
  {
    name: "Saint Martin (Fransk del)",
    "alpha-2": "MF",
    "alpha-3": "MAF",
  },
  {
    name: "SENEGAL",
    "alpha-2": "SN",
    "alpha-3": "SEN",
  },
  {
    name: "INDIA",
    "alpha-2": "IN",
    "alpha-3": "IND",
  },
  {
    name: "MONGOLIA",
    "alpha-2": "MN",
    "alpha-3": "MNG",
  },
  {
    name: "FRANSK POLYNESIA",
    "alpha-2": "PF",
    "alpha-3": "PYF",
  },
  {
    name: "IRAK",
    "alpha-2": "IQ",
    "alpha-3": "IRQ",
  },
  {
    name: "ALBANIA",
    "alpha-2": "AL",
    "alpha-3": "ALB",
  },
  {
    name: "UGANDA",
    "alpha-2": "UG",
    "alpha-3": "UGA",
  },
  {
    name: "GUINEA",
    "alpha-2": "GN",
    "alpha-3": "GIN",
  },
  {
    name: "PITCAIRNØYA",
    "alpha-2": "PN",
    "alpha-3": "PCN",
  },
  {
    name: "UNGARN",
    "alpha-2": "HU",
    "alpha-3": "HUN",
  },
  {
    name: "SINGAPORE",
    "alpha-2": "SG",
    "alpha-3": "SGP",
  },
  {
    name: "BERMUDA",
    "alpha-2": "BM",
    "alpha-3": "BMU",
  },
  {
    name: "HAITI",
    "alpha-2": "HT",
    "alpha-3": "HTI",
  },
  {
    name: "OMAN",
    "alpha-2": "OM",
    "alpha-3": "OMN",
  },
  {
    name: "FALKLANDSØYENE",
    "alpha-2": "FK",
    "alpha-3": "FLK",
  },
  {
    name: "GAMBIA",
    "alpha-2": "GM",
    "alpha-3": "GMB",
  },
  {
    name: "JOMFRUØYENE (US)",
    "alpha-2": "VI",
    "alpha-3": "VIR",
  },
  {
    name: "SYRIA, DEN ARABISKE REPUBLIKKEN",
    "alpha-2": "SY",
    "alpha-3": "SYR",
  },
  {
    name: "BAHAMAS",
    "alpha-2": "BS",
    "alpha-3": "BHS",
  },
  {
    name: "GUAM",
    "alpha-2": "GU",
    "alpha-3": "GUM",
  },
  {
    name: "MALTA",
    "alpha-2": "MT",
    "alpha-3": "MLT",
  },
  {
    name: "MEXICO",
    "alpha-2": "MX",
    "alpha-3": "MEX",
  },
  {
    name: "BOSNIA-HERCEGOVINA",
    "alpha-2": "BA",
    "alpha-3": "BIH",
  },
  {
    name: "TOGO",
    "alpha-2": "TG",
    "alpha-3": "TGO",
  },
  {
    name: "MAURITANIA, DEN ISLAMSKE REPUBLIKK",
    "alpha-2": "MR",
    "alpha-3": "MRT",
  },
  {
    name: "MALDIVENE",
    "alpha-2": "MV",
    "alpha-3": "MDV",
  },
  {
    name: "GIBRALTAR",
    "alpha-2": "GI",
    "alpha-3": "GIB",
  },
  {
    name: "EL SALVADOR",
    "alpha-2": "SV",
    "alpha-3": "SLV",
  },
  {
    name: "SIERRA LEONE",
    "alpha-2": "SL",
    "alpha-3": "SLE",
  },
  {
    name: "JERSEY",
    "alpha-2": "JE",
    "alpha-3": "JEY",
  },
  {
    name: "BOTSWANA",
    "alpha-2": "BW",
    "alpha-3": "BWA",
  },
  {
    name: "IRLAND",
    "alpha-2": "IE",
    "alpha-3": "IRL",
  },
  {
    name: "BOLIVIA",
    "alpha-2": "BO",
    "alpha-3": "BOL",
  },
  {
    name: "SALOMONØYENE",
    "alpha-2": "SB",
    "alpha-3": "SLB",
  },
  {
    name: "HONDURAS",
    "alpha-2": "HN",
    "alpha-3": "HND",
  },
  {
    name: "TURKMENISTAN",
    "alpha-2": "TM",
    "alpha-3": "TKM",
  },
  {
    name: "ARGENTINA",
    "alpha-2": "AR",
    "alpha-3": "ARG",
  },
  {
    name: "TYRKIA",
    "alpha-2": "TR",
    "alpha-3": "TUR",
  },
  {
    name: "IRAN",
    "alpha-2": "IR",
    "alpha-3": "IRN",
  },
  {
    name: "GUINEA-BISSAU",
    "alpha-2": "GW",
    "alpha-3": "GNB",
  },
  {
    name: "MALI",
    "alpha-2": "ML",
    "alpha-3": "MLI",
  },
  {
    name: "ZAMBIA",
    "alpha-2": "ZM",
    "alpha-3": "ZMB",
  },
  {
    name: "GRENADA",
    "alpha-2": "GD",
    "alpha-3": "GRD",
  },
  {
    name: "SEYCHELLENE",
    "alpha-2": "SC",
    "alpha-3": "SYC",
  },
  {
    name: "LESOTHO",
    "alpha-2": "LS",
    "alpha-3": "LSO",
  },
  {
    name: "PANAMA",
    "alpha-2": "PA",
    "alpha-3": "PAN",
  },
  {
    name: "SØR-GEORGIA OG DE SØNDRE SANDWICHØYENE",
    "alpha-2": "GS",
    "alpha-3": "SGS",
  },
  {
    name: "BENIN",
    "alpha-2": "BJ",
    "alpha-3": "BEN",
  },
  {
    name: "CHILE",
    "alpha-2": "CL",
    "alpha-3": "CHL",
  },
  {
    name: "BURUNDI",
    "alpha-2": "BI",
    "alpha-3": "BDI",
  },
  {
    name: "KOREA, REPUBLIKKEN (SØR-)",
    "alpha-2": "KR",
    "alpha-3": "KOR",
  },
  {
    name: "RUSSLAND",
    "alpha-2": "RU",
    "alpha-3": "RUS",
  },
  {
    name: "MOSAMBIK",
    "alpha-2": "MZ",
    "alpha-3": "MOZ",
  },
  {
    name: "AFGHANISTAN",
    "alpha-2": "AF",
    "alpha-3": "AFG",
  },
  {
    name: "BANGLADESH",
    "alpha-2": "BD",
    "alpha-3": "BGD",
  },
  {
    name: "ST. HELENA",
    "alpha-2": "SH",
    "alpha-3": "SHN",
  },
  {
    name: "UKRAINA",
    "alpha-2": "UA",
    "alpha-3": "UKR",
  },
  {
    name: "REUNION",
    "alpha-2": "RE",
    "alpha-3": "REU",
  },
  {
    name: "HEARD- OG MCDONALDØYENE",
    "alpha-2": "HM",
    "alpha-3": "HMD",
  },
  {
    name: "UKJENT LAND",
    "alpha-2": "ZZ",
    "alpha-3": "ZZZ",
  },
  {
    name: "TONGA",
    "alpha-2": "TO",
    "alpha-3": "TON",
  },
  {
    name: "SABA",
    "alpha-2": "BQ",
    "alpha-3": "BES",
  },
  {
    name: "ANDORRA",
    "alpha-2": "AD",
    "alpha-3": "AND",
  },
  {
    name: "ØSTERRIKE",
    "alpha-2": "AT",
    "alpha-3": "AUT",
  },
  {
    name: "FILIPPINENE",
    "alpha-2": "PH",
    "alpha-3": "PHL",
  },
];

export const GetCountryFor3CharCode = (code) => {
  let ctry = CountryCode.find((c) => c["alpha-3"] === code);
  return ctry ? ctry["name"] : "Ukjent";
};

export const GetCountryFor2CharCode = (code) => {
  let ctry = CountryCode.find((c) => c["alpha-2"] === code);
  return ctry ? ctry["name"] : "Ukjent";
};

export const VesselFlagCodes = {
  ALD: "ALAND ISLANDS",
  ALB: "ALBANIA",
  DZA: "ALGERIA",
  AGO: "ANGOLA",
  AIA: "ANGUILLA",
  ATG: "ANTIGUA & BARBUDA",
  ARG: "ARGENTINA",
  ABW: "ARUBA",
  AUS: "AUSTRALIA",
  AUT: "AUSTRIA",
  AZE: "AZERBAIJAN",
  BHS: "BAHAMAS",
  BHR: "BAHRAIN",
  BGD: "BANGLADESH",
  BRB: "BARBADOS",
  BEL: "BELGIUM",
  BLZ: "BELIZE",
  BEN: "BENIN",
  BMU: "BERMUDA",
  BOL: "BOLIVIA",
  BRA: "BRAZIL",
  BRU: "BRUNEI",
  BGR: "BULGARIA",
  KHM: "CAMBODIA",
  CMR: "CAMEROON",
  CAN: "CANADA",
  CPV: "CAPE VERDE",
  CYM: "CAYMAN ISLANDS",
  CHL: "CHILE",
  COL: "COLUMBIA",
  COM: "COMOROS",
  COK: "COOK ISLANDS",
  CRI: "COSTA RICA",
  HRV: "CROATIA",
  CUB: "CUBA",
  CYP: "CYPRUS",
  CZE: "CZECH REPUBLIC",
  DIS: "DANISH INTERNATIONAL",
  DNK: "DENMARK",
  DJI: "DJIBOUTI",
  DMA: "DOMINICA",
  DOM: "DOMINICAN REPUBLIC",
  ECU: "ECUADOR",
  EGY: "EGYPT",
  SLV: "EL SALVADOR",
  GNQ: "EQUATORIAL GUINEA",
  ERI: "ERITREA",
  EST: "ESTONIA",
  ETH: "ETHIOPIA",
  FRO: "FAROE ISLANDS",
  FIJ: "FIJI",
  FIN: "FINLAND",
  FRA: "FRANCE",
  ATF: "FRENCH KERGUELEN ISL",
  GAB: "GABON",
  GEO: "GEORGIA",
  DEU: "GERMANY",
  GHA: "GHANA",
  GIB: "GIBRALTER",
  GRC: "GREECE",
  GRD: "GRENADA",
  GLP: "GUADALOUPE",
  GTM: "GUATAMALA",
  GIN: "GUINEA",
  GUY: "GUYANA",
  HTI: "HAITI",
  HND: "HONDURAS",
  HKG: "HONG KONG",
  HUN: "HUNGARY",
  ISL: "ICELAND",
  IND: "INDIA",
  IDN: "INDONESIA",
  IRN: "IRAN",
  IRQ: "IRAQ",
  IRL: "IRISH REPUBLIC",
  IOM: "ISLE OF MAN",
  ISR: "ISRAEL",
  ITA: "ITALY",
  CIV: "IVORY COAST",
  JAM: "JAMAICA",
  JPN: "JAPAN",
  JAP: "JAPAN",
  JOR: "JORDAN",
  KEN: "KENYA",
  KIR: "KIRIBATI",
  KWT: "KUWAIT",
  LAO: "LAOS",
  LVA: "LATVIA",
  LBN: "LEBANON",
  LBR: "LIBERIA",
  LBY: "LIBYA",
  LTU: "LITHUANIA",
  LUX: "LUXEMBOURG",
  MDG: "MADAGASCAR",
  PMD: "MADEIRA",
  MYS: "MALAYSIA",
  MDV: "MALDIVES",
  MLT: "MALTA",
  MHL: "MARSHALL ISLANDS",
  MRT: "MAURITANIA",
  MUS: "MAURITIUS",
  MAU: "MAURITIUS",
  MEX: "MEXICO",
  MON: "MONACO",
  MNG: "MONGOLIA",
  MOR: "MOROCCO",
  MOZ: "MOZAMBIQUE",
  MMR: "Myanmar, (Burma)",
  NAM: "NAMIBIA",
  NRU: "NAURU",
  NLD: "NETHERLANDS",
  ANT: "NETHERLANDS ANTILLES",
  NZL: "NEW ZEALAND",
  NIC: "NICARAGUA",
  NIG: "NIGERIA",
  PRK: "NORTH KOREA",
  NOR: "NORWAY",
  NIS: "NORWAY INTERNATIONAL",
  DDR: "OLD EAST GERMANY",
  YUG: "OLD YUGOSLAVIA",
  OMN: "OMAN",
  PAK: "PAKISTAN",
  PAN: "PANAMA",
  PNG: "PAPUA NEW GUINEA",
  PRY: "PARAGUAY",
  PER: "PERU",
  PHL: "PHILIPPINES",
  POL: "POLAND",
  PRT: "PORTUGAL",
  QAT: "QATAR",
  CHN: "REPUBLIC OF CHINA",
  ROM: "ROMANIA",
  RUS: "RUSSIA",
  SAU: "SAUDI ARABIA",
  SEN: "SENEGAL",
  SLE: "SIERRA LEONE",
  SGP: "SINGAPORE",
  SVK: "SLOVAKIA",
  SVN: "SLOVENIA",
  SLB: "SOLOMON ISLANDS",
  SOM: "SOMALIA",
  ZAF: "SOUTH AFRICA",
  KOR: "SOUTH KOREA",
  ESP: "SPAIN",
  LKA: "SRI LANKA",
  LCA: "ST. LUCIA",
  VCT: "ST.VINCENT & GRNADNE",
  SDN: "SUDAN",
  SUR: "SURINAME",
  SWE: "SWEDEN",
  CHE: "SWITZERLAND",
  SYR: "SYRIA",
  TWN: "TAIWAN",
  TZA: "TANZANIA",
  THA: "THAILAND",
  COG: "THE CONGO",
  GMB: "THE GAMBIA",
  TGO: "TOGO",
  TON: "TONGA",
  TTO: "TRINIDAD & TOBAGO",
  TUN: "TUNISIA",
  TUR: "TURKEY",
  TKM: "TURKMENISTAN",
  TUV: "TUVALU",
  UGA: "UGANDA",
  UKR: "UKRAINE",
  ARE: "UNITED ARAB EMIRATES",
  GBR: "UNITED KINGDOM",
  USA: "UNITED STATES",
  VUT: "VANUATU",
  VEN: "VENEZUELA",
  VNM: "VIETNAM",
  ZAR: "ZAIRE  ",
};

const shipFlagCodes = [
  {
    shipflagcode: "ABB",
    countrynameeng: "ANTIGUA AND BARBUDA",
  },
  {
    shipflagcode: "AGL",
    countrynameeng: "ANGUILLA",
  },
  {
    shipflagcode: "ALA",
    countrynameeng: "ANGOLA",
  },
  {
    shipflagcode: "ALB",
    countrynameeng: "ALBANIA",
  },
  {
    shipflagcode: "ALG",
    countrynameeng: "ALGERIA",
  },
  {
    shipflagcode: "ARG",
    countrynameeng: "ARGENTINA",
  },
  {
    shipflagcode: "AST",
    countrynameeng: "AUSTRALIA",
  },
  {
    shipflagcode: "AZE",
    countrynameeng: "AZERBAIJAN",
  },
  {
    shipflagcode: "BAH",
    countrynameeng: "BAHAMAS",
  },
  {
    shipflagcode: "BBD",
    countrynameeng: "BARBADOS",
  },
  {
    shipflagcode: "BEN",
    countrynameeng: "BENIN",
  },
  {
    shipflagcode: "BER",
    countrynameeng: "BERMUDA",
  },
  {
    shipflagcode: "BLG",
    countrynameeng: "BELGIUM",
  },
  {
    shipflagcode: "BNG",
    countrynameeng: "BANGLADESH",
  },
  {
    shipflagcode: "BOL",
    countrynameeng: "BOLIVIA, PLURINATIONAL STATE OF",
  },
  {
    shipflagcode: "BRN",
    countrynameeng: "BAHRAIN",
  },
  {
    shipflagcode: "BRU",
    countrynameeng: "BRUNEI DARUSSALAM",
  },
  {
    shipflagcode: "BRZ",
    countrynameeng: "BRAZIL",
  },
  {
    shipflagcode: "BUL",
    countrynameeng: "BULGARIA",
  },
  {
    shipflagcode: "BVI",
    countrynameeng: "VIRGIN ISLANDS, BRITISH",
  },
  {
    shipflagcode: "BZE",
    countrynameeng: "BELIZE",
  },
  {
    shipflagcode: "CAN",
    countrynameeng: "CANADA",
  },
  {
    shipflagcode: "CAY",
    countrynameeng: "CAYMAN ISLANDS",
  },
  {
    shipflagcode: "CDR",
    countrynameeng: "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
  },
  {
    shipflagcode: "CHL",
    countrynameeng: "CHILE",
  },
  {
    shipflagcode: "CHR",
    countrynameeng: "CHINA",
  },
  {
    shipflagcode: "CHT",
    countrynameeng: "TAIWAN, PROVINCE OF CHINA",
  },
  {
    shipflagcode: "CKI",
    countrynameeng: "COOK ISLANDS",
  },
  {
    shipflagcode: "CMN",
    countrynameeng: "CAMEROON",
  },
  {
    shipflagcode: "CMR",
    countrynameeng: "COMOROS",
  },
  {
    shipflagcode: "COL",
    countrynameeng: "COLOMBIA",
  },
  {
    shipflagcode: "CON",
    countrynameeng: "CONGO",
  },
  {
    shipflagcode: "COS",
    countrynameeng: "COSTA RICA",
  },
  {
    shipflagcode: "CRT",
    countrynameeng: "CROATIA",
  },
  {
    shipflagcode: "CSR",
    countrynameeng: "SPAIN",
  },
  {
    shipflagcode: "CUB",
    countrynameeng: "CUBA",
  },
  {
    shipflagcode: "CUR",
    countrynameeng: "CURAÇAO",
  },
  {
    shipflagcode: "CYP",
    countrynameeng: "CYPRUS",
  },
  {
    shipflagcode: "DEN",
    countrynameeng: "DENMARK",
  },
  {
    shipflagcode: "DIS",
    countrynameeng: "DENMARK",
  },
  {
    shipflagcode: "DJI",
    countrynameeng: "DJIBOUTI",
  },
  {
    shipflagcode: "DMA",
    countrynameeng: "DOMINICA",
  },
  {
    shipflagcode: "DOM",
    countrynameeng: "DOMINICAN REPUBLIC",
  },
  {
    shipflagcode: "ECU",
    countrynameeng: "ECUADOR",
  },
  {
    shipflagcode: "EGY",
    countrynameeng: "EGYPT",
  },
  {
    shipflagcode: "ELS",
    countrynameeng: "EL SALVADOR",
  },
  {
    shipflagcode: "EQG",
    countrynameeng: "EQUATORIAL GUINEA",
  },
  {
    shipflagcode: "ERT",
    countrynameeng: "ERITREA",
  },
  {
    shipflagcode: "ETH",
    countrynameeng: "ETHIOPIA",
  },
  {
    shipflagcode: "ETN",
    countrynameeng: "ESTONIA",
  },
  {
    shipflagcode: "FAL",
    countrynameeng: "FALKLAND ISLANDS (MALVINAS)",
  },
  {
    shipflagcode: "FAR",
    countrynameeng: "FAROE ISLANDS",
  },
  {
    shipflagcode: "FAS",
    countrynameeng: "FAROE ISLANDS",
  },
  {
    shipflagcode: "FIJ",
    countrynameeng: "FIJI",
  },
  {
    shipflagcode: "FIN",
    countrynameeng: "FINLAND",
  },
  {
    shipflagcode: "FIS",
    countrynameeng: "FRANCE",
  },
  {
    shipflagcode: "FNR",
    countrynameeng: "UNKNOWN COUNTRY",
  },
  {
    shipflagcode: "FRA",
    countrynameeng: "FRANCE",
  },
  {
    shipflagcode: "FST",
    countrynameeng: "FRANCE",
  },
  {
    shipflagcode: "GAB",
    countrynameeng: "GABON",
  },
  {
    shipflagcode: "GAM",
    countrynameeng: "GAMBIA",
  },
  {
    shipflagcode: "GBI",
    countrynameeng: "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND",
  },
  {
    shipflagcode: "GEO",
    countrynameeng: "GEORGIA",
  },
  {
    shipflagcode: "GEU",
    countrynameeng: "GERMANY",
  },
  {
    shipflagcode: "GHA",
    countrynameeng: "GHANA",
  },
  {
    shipflagcode: "GIB",
    countrynameeng: "GIBRALTAR",
  },
  {
    shipflagcode: "GNB",
    countrynameeng: "GUINEA-BISSAU",
  },
  {
    shipflagcode: "GRC",
    countrynameeng: "GREECE",
  },
  {
    shipflagcode: "GRE",
    countrynameeng: "GRENADA",
  },
  {
    shipflagcode: "GUA",
    countrynameeng: "GUATEMALA",
  },
  {
    shipflagcode: "GUE",
    countrynameeng: "GUERNSEY",
  },
  {
    shipflagcode: "GUI",
    countrynameeng: "GUINEA",
  },
  {
    shipflagcode: "GUY",
    countrynameeng: "GUYANA",
  },
  {
    shipflagcode: "HAI",
    countrynameeng: "HAITI",
  },
  {
    shipflagcode: "HKG",
    countrynameeng: "HONG KONG",
  },
  {
    shipflagcode: "HON",
    countrynameeng: "HONDURAS",
  },
  {
    shipflagcode: "ICL",
    countrynameeng: "ICELAND",
  },
  {
    shipflagcode: "IDA",
    countrynameeng: "INDONESIA",
  },
  {
    shipflagcode: "IND",
    countrynameeng: "INDIA",
  },
  {
    shipflagcode: "IOM",
    countrynameeng: "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND",
  },
  {
    shipflagcode: "IRN",
    countrynameeng: "IRAN, ISLAMIC REPUBLIC OF",
  },
  {
    shipflagcode: "IRP",
    countrynameeng: "IRELAND",
  },
  {
    shipflagcode: "IRQ",
    countrynameeng: "IRAQ",
  },
  {
    shipflagcode: "ISR",
    countrynameeng: "ISRAEL",
  },
  {
    shipflagcode: "ITL",
    countrynameeng: "ITALY",
  },
  {
    shipflagcode: "IVC",
    countrynameeng: "CÔTE D'IVOIRE",
  },
  {
    shipflagcode: "JAM",
    countrynameeng: "JAMAICA",
  },
  {
    shipflagcode: "JER",
    countrynameeng: "JERSEY",
  },
  {
    shipflagcode: "JOR",
    countrynameeng: "JORDAN",
  },
  {
    shipflagcode: "JPN",
    countrynameeng: "JAPAN",
  },
  {
    shipflagcode: "KAZ",
    countrynameeng: "KAZAKHSTAN",
  },
  {
    shipflagcode: "KEN",
    countrynameeng: "KENYA",
  },
  {
    shipflagcode: "KIR",
    countrynameeng: "KIRIBATI",
  },
  {
    shipflagcode: "KRN",
    countrynameeng: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
  },
  {
    shipflagcode: "KRS",
    countrynameeng: "KOREA, REPUBLIC OF",
  },
  {
    shipflagcode: "KUW",
    countrynameeng: "KUWAIT",
  },
  {
    shipflagcode: "LAO",
    countrynameeng: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
  },
  {
    shipflagcode: "LAV",
    countrynameeng: "LATVIA",
  },
  {
    shipflagcode: "LBY",
    countrynameeng: "LIBYA",
  },
  {
    shipflagcode: "LEB",
    countrynameeng: "LEBANON",
  },
  {
    shipflagcode: "LIB",
    countrynameeng: "LIBERIA",
  },
  {
    shipflagcode: "LTH",
    countrynameeng: "LITHUANIA",
  },
  {
    shipflagcode: "LUX",
    countrynameeng: "LUXEMBOURG",
  },
  {
    shipflagcode: "MAI",
    countrynameeng: "MARSHALL ISLANDS",
  },
  {
    shipflagcode: "MAL",
    countrynameeng: "MALAYSIA",
  },
  {
    shipflagcode: "MAR",
    countrynameeng: "PORTUGAL",
  },
  {
    shipflagcode: "MAU",
    countrynameeng: "MAURITANIA",
  },
  {
    shipflagcode: "MDV",
    countrynameeng: "MALDIVES",
  },
  {
    shipflagcode: "MEX",
    countrynameeng: "MEXICO",
  },
  {
    shipflagcode: "MGL",
    countrynameeng: "MONGOLIA",
  },
  {
    shipflagcode: "MGY",
    countrynameeng: "MADAGASCAR",
  },
  {
    shipflagcode: "MIC",
    countrynameeng: "MICRONESIA, FEDERATED STATES OF",
  },
  {
    shipflagcode: "MOL",
    countrynameeng: "MOLDOVA, REPUBLIC OF",
  },
  {
    shipflagcode: "MOR",
    countrynameeng: "MOROCCO",
  },
  {
    shipflagcode: "MOZ",
    countrynameeng: "MOZAMBIQUE",
  },
  {
    shipflagcode: "MTA",
    countrynameeng: "MALTA",
  },
  {
    shipflagcode: "MTN",
    countrynameeng: "MONTENEGRO",
  },
  {
    shipflagcode: "MTS",
    countrynameeng: "MAURITIUS",
  },
  {
    shipflagcode: "MYA",
    countrynameeng: "MYANMAR",
  },
  {
    shipflagcode: "NAU",
    countrynameeng: "NAURU",
  },
  {
    shipflagcode: "NBA",
    countrynameeng: "NAMIBIA",
  },
  {
    shipflagcode: "NIC",
    countrynameeng: "NICARAGUA",
  },
  {
    shipflagcode: "NIG",
    countrynameeng: "NIGERIA",
  },
  {
    shipflagcode: "NIS",
    countrynameeng: "NORWAY",
  },
  {
    shipflagcode: "NIU",
    countrynameeng: "NEW ZEALAND",
  },
  {
    shipflagcode: "NOR",
    countrynameeng: "NORWAY",
  },
  {
    shipflagcode: "NTH",
    countrynameeng: "NETHERLANDS",
  },
  {
    shipflagcode: "NZL",
    countrynameeng: "NEW ZEALAND",
  },
  {
    shipflagcode: "OMN",
    countrynameeng: "OMAN",
  },
  {
    shipflagcode: "PAK",
    countrynameeng: "PAKISTAN",
  },
  {
    shipflagcode: "PAL",
    countrynameeng: "PALAU",
  },
  {
    shipflagcode: "PAN",
    countrynameeng: "PANAMA",
  },
  {
    shipflagcode: "PAR",
    countrynameeng: "PARAGUAY",
  },
  {
    shipflagcode: "PER",
    countrynameeng: "PERU",
  },
  {
    shipflagcode: "PHI",
    countrynameeng: "PHILIPPINES",
  },
  {
    shipflagcode: "PNG",
    countrynameeng: "PAPUA NEW GUINEA",
  },
  {
    shipflagcode: "POL",
    countrynameeng: "POLAND",
  },
  {
    shipflagcode: "PST",
    countrynameeng: "SAO TOME AND PRINCIPE",
  },
  {
    shipflagcode: "PTG",
    countrynameeng: "PORTUGAL",
  },
  {
    shipflagcode: "QAT",
    countrynameeng: "QATAR",
  },
  {
    shipflagcode: "RCV",
    countrynameeng: "CAPE VERDE",
  },
  {
    shipflagcode: "RUM",
    countrynameeng: "ROMANIA",
  },
  {
    shipflagcode: "RUS",
    countrynameeng: "RUSSIAN FEDERATION",
  },
  {
    shipflagcode: "SAF",
    countrynameeng: "SOUTH AFRICA",
  },
  {
    shipflagcode: "SAU",
    countrynameeng: "SAUDI ARABIA",
  },
  {
    shipflagcode: "SEN",
    countrynameeng: "SENEGAL",
  },
  {
    shipflagcode: "SEY",
    countrynameeng: "SEYCHELLES",
  },
  {
    shipflagcode: "SIE",
    countrynameeng: "SIERRA LEONE",
  },
  {
    shipflagcode: "SKN",
    countrynameeng: "SAINT KITTS AND NEVIS",
  },
  {
    shipflagcode: "SLI",
    countrynameeng: "SOLOMON ISLANDS",
  },
  {
    shipflagcode: "SLO",
    countrynameeng: "SLOVENIA",
  },
  {
    shipflagcode: "SMO",
    countrynameeng: "SAMOA",
  },
  {
    shipflagcode: "SNG",
    countrynameeng: "SINGAPORE",
  },
  {
    shipflagcode: "SNM",
    countrynameeng: "SURINAME",
  },
  {
    shipflagcode: "SPN",
    countrynameeng: "SPAIN",
  },
  {
    shipflagcode: "SRI",
    countrynameeng: "SRI LANKA",
  },
  {
    shipflagcode: "STH",
    countrynameeng: "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA",
  },
  {
    shipflagcode: "SUD",
    countrynameeng: "SUDAN",
  },
  {
    shipflagcode: "SVC",
    countrynameeng: "SAINT VINCENT AND THE GRENADINES",
  },
  {
    shipflagcode: "SWD",
    countrynameeng: "SWEDEN",
  },
  {
    shipflagcode: "SWZ",
    countrynameeng: "SWITZERLAND",
  },
  {
    shipflagcode: "SYR",
    countrynameeng: "SYRIAN ARAB REPUBLIC",
  },
  {
    shipflagcode: "TAN",
    countrynameeng: "TANZANIA, UNITED REPUBLIC OF",
  },
  {
    shipflagcode: "THA",
    countrynameeng: "THAILAND",
  },
  {
    shipflagcode: "TLU",
    countrynameeng: "TUVALU",
  },
  {
    shipflagcode: "TOG",
    countrynameeng: "TOGO",
  },
  {
    shipflagcode: "TON",
    countrynameeng: "TONGA",
  },
  {
    shipflagcode: "TRI",
    countrynameeng: "TRINIDAD AND TOBAGO",
  },
  {
    shipflagcode: "TRK",
    countrynameeng: "TURKEY",
  },
  {
    shipflagcode: "TUN",
    countrynameeng: "TUNISIA",
  },
  {
    shipflagcode: "TUR",
    countrynameeng: "TURKMENISTAN",
  },
  {
    shipflagcode: "TZS",
    countrynameeng: "TANZANIA, UNITED REPUBLIC OF",
  },
  {
    shipflagcode: "TZZ",
    countrynameeng: "TANZANIA, UNITED REPUBLIC OF",
  },
  {
    shipflagcode: "UAE",
    countrynameeng: "UNITED ARAB EMIRATES",
  },
  {
    shipflagcode: "UKE",
    countrynameeng: "UKRAINE",
  },
  {
    shipflagcode: "UNK",
    countrynameeng: "UNKNOWN COUNTRY",
  },
  {
    shipflagcode: "URG",
    countrynameeng: "URUGUAY",
  },
  {
    shipflagcode: "USA",
    countrynameeng: "UNITED STATES",
  },
  {
    shipflagcode: "VAN",
    countrynameeng: "VANUATU",
  },
  {
    shipflagcode: "VEN",
    countrynameeng: "VENEZUELA, BOLIVARIAN REPUBLIC OF",
  },
  {
    shipflagcode: "VNM",
    countrynameeng: "VIET NAM",
  },
  {
    shipflagcode: "XXX",
    countrynameeng: "UNKNOWN COUNTRY",
  },
  {
    shipflagcode: "YEM",
    countrynameeng: "YEMEN",
  },
];

export function  getCountryForShipFlagCode(flagCode) {
  let exists = shipFlagCodes.find(s=>s.shipflagcode === flagCode);
  return exists ? exists.countrynameeng : "Ukjent";
}