import * as d3 from 'd3v7';

import React from 'react';

export const useD3v7 = (renderChartFn, dependencies) => {
  const ref = React.useRef();
  React.useEffect(() => {
    renderChartFn(d3.select(ref.current));
    return () => { };
    // eslint-disable-next-line
    }, dependencies);
  return ref;
};
