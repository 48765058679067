import './RegisterUserForm.scss';

import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SHAUtils } from './SHAUtils';
import request from 'superagent';

export class RegisterUserForm extends Component {
  static propTypes = {
    baseUrl: PropTypes.string.isRequired,
    loginUrl: PropTypes.string.isRequired,
    onRegisterError: PropTypes.func.isRequired,
    onRegisterSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);
    this.register = this.register.bind(this);
    this.setValue = this.setValue.bind(this);

    this.state = {
      email: '',
      pass: '',
      firstName: '',
      lastName: '',
      privacyAccepted: 'false',
      isValid: false
    };
  }

  register (evt) {
    const { baseUrl, loginUrl } = this.props;
    const { email, pass, firstName, lastName, privacyAccepted } = this.state;

    evt.preventDefault();

    request
      .post(baseUrl + 'WebServices/generic/Authentication.asmx/registerUser')
      .set('Content-Type', 'application/json')
      .set('gm_lang_code', 'en')
      .send(
        JSON.stringify({
          url: loginUrl,
          email: email,
          pass: SHAUtils.SHA512(pass),
          first_name: firstName,
          last_name: lastName,
          privacy_accepted: '' + privacyAccepted
        })
      )
      .then(
        res => {
          var json = res.body.d;
          if (json.success === true) {
            this.props.onRegisterSuccess(json);
          } else {
            if (json.exception && json.exception.msg && json.exception.code) {
              this.props.onRegisterError(json.exception.msg, json.exception.code);
            } else {
              this.props.onRegisterError();
            }
          }
        },
        err => {
          // console.debug(err);
          this.props.onRegisterError();
        }
      );
  }

  isValid () {
    const { email, pass, privacyAccepted } = this.state;
    if (email && pass && privacyAccepted === true) {
      return true;
    } else {
      return false;
    }
  }

  setValue (evt, key) {
    var change = {};
    change[key] = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    this.setState(change, () => {
      var isValid = this.isValid();
      this.setState({ isValid: isValid });
    });
  }

  render () {
    return (
      <form className='login-form' onSubmit={this.register}>
        <div className='login-form--control'>
          <label>E-postadresse</label>
          <input
            type='email'
            value={this.state.email}
            onChange={evt => this.setValue(evt, 'email')}
            className='login-form--email'
            required
          />
        </div>
        <div className='login-form--control'>
          <label>Passord</label>
          <input
            type='password'
            value={this.state.pass}
            onChange={evt => this.setValue(evt, 'pass')}
            className='login-form--password'
            required
          />
        </div>
        <div className='login-form--control'>
          <label>Fornavn</label>
          <input
            type='text'
            value={this.state.firstName}
            onChange={evt => this.setValue(evt, 'firstName')}
          />
        </div>
        <div className='login-form--control'>
          <label>Etternavn</label>
          <input
            type='text'
            value={this.state.lastName}
            onChange={evt => this.setValue(evt, 'lastName')}
          />
        </div>
        <div className='login-form--control'>
          <label>Denne tjenesten er underlagt Personopplysningsloven med personvernforordningen (GDPR). Ved å krysse av denne boksen aksepterer du <Link to='/gdpr'>brukervilkårene</Link></label>
          <input
            type='checkbox'
            checked={this.state.privacyAccepted === true}
            onChange={evt => this.setValue(evt, 'privacyAccepted')}
            className='login-form--checkbox'
            required
          />
        </div>
        <div className='login-form--control'>
          <button className='width-auto' type='submit' disabled={!this.state.isValid}>
            Opprett bruker
          </button>
          <button className='width-auto' type='cancel' onClick={this.props.onCancel}>
            Avbryt
          </button>
        </div>
      </form>
    );
  }
}
