import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const withRouter = (Component) => {
  function Wrapping(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const WrapperComponent = (
      <Component
        {...props}
        location={location}
        navigate={navigate}
        params={params}
      />
    );

    return WrapperComponent;
  }

  [
    "dashboardRoute",
    "dashboardFilters",
    "dashboardValidation",
    "dashboardSettings",
  ].forEach((staticProperty) => {
    if (typeof Component[staticProperty] !== "undefined") {
      // console.log(Component[staticProperty]);
      const srcDescriptor = Object.getOwnPropertyDescriptor(
        Component,
        staticProperty
      );
      Object.defineProperty(Wrapping, staticProperty, srcDescriptor);
      Wrapping[staticProperty] = Component[staticProperty];
    }
  });

  return Wrapping;
};

export default withRouter;
