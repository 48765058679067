import "./PointDimMapChart.scss";

import * as olControl from "ol/control";
import * as olInteraction from "ol/interaction";
import * as olProj from "ol/proj";

import React, { Component } from "react";

import Map from "ol/Map";
import View from "ol/View";
import PropTypes from "prop-types";
import { AVIMapTile } from "../Layout/AVIMapTile";
import { LayerSwitcherMixin } from "../Mixins/LayerSwitcherMixin";
import { SimpleBaseMixin } from "../Mixins/SimpleBaseMixin";
import { DcExtPointDimMapChart } from "./../DcExt/DcExtPointDimMapChart";

export class PointDimMapChart extends Component {
  static propTypes = {
    chartTitle: PropTypes.string.isRequired,
    group: PropTypes.object.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    useFlex: PropTypes.bool,
  };

  static defaultProps = {
    useFlex: false,
  };

  map = null;

  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.chart = null;
    this.getMap = this.getMap.bind(this);
  }

  componentDidMount() {
    var zoomPt = [15, 61];

    this.map = new Map({
      target: this.chartRef.current,
      interactions: olInteraction.defaults({ mouseWheelZoom: false }),
      controls: olControl.defaults({
        attributionOptions: {
          collapsible: false,
        },
      }),
      view: new View({
        center: olProj.transform(
          [zoomPt[0], zoomPt[1]],
          "EPSG:4326",
          "EPSG:3857"
        ),
        zoom: 5,
      }),
    });

    this.chart = new DcExtPointDimMapChart({
      map: this.map,
    });

    SimpleBaseMixin(this.chart, this.props);
    LayerSwitcherMixin(this.map);

    this.chart.render();
  }

  getMap() {
    return this.map;
  }

  render() {
    return (
      <AVIMapTile
        title={this.props.chartTitle}
        height={this.props.height}
        width={this.props.width}
        getMap={this.getMap}
        useFlex={this.props.useFlex}
      >
        <div className="avi-map" ref={this.chartRef} />
      </AVIMapTile>
    );
  }
}
