import React, { useCallback, useEffect, useState } from "react";
import { Button, FormControl } from "react-bootstrap";
import { NsrLocationProperties } from "../../types/NsrLocation";
import { LocationSelect } from "./LocationSelect";
import { CruiseMapping } from "../../types/CruiseMapping";

type CruiseMappingRowProps = {
    cruiseMapping: CruiseMapping
    onSave: (cruiseMapping: CruiseMapping) => void
    onDelete: (id: number) => void
    locationOptions: NsrLocationProperties[]
    editable?: boolean
}

export function CruiseMappingRow({ cruiseMapping, locationOptions, onSave, onDelete, editable = false }: CruiseMappingRowProps) {

    const [isEditable, setEditable] = useState<boolean>(false);
    const [value, setValue] = useState<CruiseMapping>(cruiseMapping);

    const onChangeHandler = useCallback((evt: any) => {
        setValue(v => {
            const nv = {
                ...v,
                [evt.target.name]: evt.target.value
            }
            return nv;
        });
    }, []);

    const resetRow = useCallback(() => {
        setValue(cruiseMapping);
    }, [cruiseMapping]);

    useEffect(() => {
        return () => {
        }
    }, [cruiseMapping, onSave]);

    if (isEditable || editable) {
        return <>
            <tr>
                <th>
                    {value.Id}
                </th>
                <td>
                    <LocationSelect name="Source" value={value.Source} onChange={onChangeHandler} options={locationOptions} required />
                </td>
                <td>
                    <LocationSelect name="Target" value={value.Target} onChange={onChangeHandler} options={locationOptions} />
                </td>
                <td>
                    <FormControl name="Label" value={value.Label} onChange={onChangeHandler} required />
                </td>
                <td>
                    <div className="d-flex gap-1">
                        <Button
                            variant="secondary"
                            onClick={() => setEditable(false)}>Avbryt</Button>
                        {value.Id && value.Id > 0 && <Button
                            disabled={!value.Id}
                            variant="danger"
                            onClick={async () => {
                                if (window.confirm("Er du sikker på at du vil slette denne koblingen?")) {
                                    await onDelete(value.Id!);
                                    resetRow()
                                    setEditable(false)
                                }
                            }}>Slett</Button>}
                        <Button
                            disabled={!(value.Source > 0) || !(value.Target > 0) || value.Label === ""}
                            variant="primary"
                            onClick={async () => {
                                await onSave(value);
                                resetRow()
                                setEditable(false);
                            }}>Lagre</Button>
                    </div>
                </td>
            </tr>
        </>;
    } else {
        const src = locationOptions.find(lo => lo.id === cruiseMapping.Source);
        const tgt = locationOptions.find(lo => lo.id === cruiseMapping.Target);

        return <tr>
            <th>{cruiseMapping.Id}</th>
            <td>{src ? `${src.name}, ${src.country} (${src.type})` : "-"}</td>
            <td>{tgt ? `${tgt.name}, ${tgt.country} (${tgt.type})` : "-"}</td>
            <td>{cruiseMapping.Label}</td>
            <td>
                <div className="d-flex gap-1">
                    <Button variant="primary" onClick={() => setEditable(true)}>Rediger</Button>
                </div>
            </td>
        </tr>
    }
}