import React from 'react';
import logo from "../static/kyv-logo-dashboard.svg";

export function ModalLayoutHeader() {
  return (
    <div
      className="w-100 px-4 py-2 mb-2"
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        background: "white",
        boxShadow: "0px 1px 2px rgba(0, 6, 103, 0.10)",
        justifyContent: "space-between",
        alignItems: "center",
        display: "inline-flex",
      }}
    >
      <div
        style={{
          flex: "1 1 0",
          height: 40.24,
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div
          style={{
            flex: "1 1 0",
            height: 40.24,
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 10,
            display: "flex",
          }}
        >
          <div
            style={{
              width: 160,
              height: 40.24,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img src={logo} alt="Logo" />
          </div>
        </div>
        <div
          style={{
            width: 118,
            alignSelf: "stretch",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 10,
            display: "flex",
          }}
        >
          <div
            style={{
              textAlign: "justify",
              color: "#000667",
              fontSize: 18,
              fontFamily: "museo-sans",
              fontWeight: "500",
              lineHeight: 24,
              wordWrap: "break-word",
            }}
          >
            Kystdatahuset
          </div>
        </div>
        <div style={{ flex: "1 1 0", height: 40 }} />
      </div>
    </div>
  );
}

export default ModalLayoutHeader;
