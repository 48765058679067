import "./DbrdAnchorage.scss";

import * as d3 from "d3";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { TrackMapChart } from "../../chart-components/ChartsExt/TrackMapChart";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { FilterAnchorage } from "../../filters/FilterAnchorage";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";
import { KyvLayerSwitcher } from "../../ui-components/filter-view/FilterMap/kyv-layer-switcher/KyvLayerSwitcher";
import { KYVGroupings } from "../../utils/KYVGroupings";
import { NumUtils } from "../../chart-components/ChartUtils/NumUtils";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIRow } from "../../chart-components/Layout/AVIRow";

class DbrdAnchorageBase extends Component<any, any> {
  static dashboardRoute = "anchorage";

  static dashboardFilters(filter, setFilter, passlines, renderSelection) {
    return {
      helpMessage: "Ankring genererer mye data",
      controls: [
        <FilterAnchorage
          key="flt-anchorage"
          firstYear={2015}
          filter={filter}
          setFilter={setFilter}
        />,
      ],
    };
  }

  static dashboardSettings() {
    return {};
  }

  static dashboardValidation(filter) {
    if (
      filter.fromYear &&
      filter.fromMonth &&
      filter.toYear &&
      filter.toMonth &&
      Array.isArray(filter.anchorageLocations) &&
      filter.anchorageLocations.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  static propTypes = {
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
      geom: null,
    };

    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    try {
      const { params, location } = this.props ?? {};

      var getAreaUrl = "/api/anchorage/area/geojson";
      var getAreaBody = {};
      var getAnchorageUrl = "/api/anchorage/for-location-time";
      var getAnchorageBody = {};

      if (params?.anchoragesysid) {
        // If direct access using sysid route
        getAreaBody = {
          anchorageSysIds: [params?.anchoragesysid.toString() ?? ""],
        };

        getAnchorageBody = {
          anchorageSysIds: [params?.anchoragesysid.toString() ?? ""],
          startTime: new Date(new Date().getFullYear() - 4, 0, 1),
          endTime: new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            1
          ),
        };
      } else if (params?.dok_status && params?.dok_id) {
        //  If direct access via dokId, docStatus route
        getAreaUrl = "/api/anchorage/area/geojson/by-dokid-dokstatus";
        getAreaBody = {
          dokId: params?.dok_id.toString(),
          dokStatus: params?.dok_status.toString(),
        };

        getAnchorageUrl = "/api/anchorage/anchorage/by-dokid-dokstatus";
        getAnchorageBody = {
          dokId: params?.dok_id.toString(),
          dokStatus: params?.dok_status.toString(),
          startTime: new Date(new Date().getFullYear() - 4, 0, 1),
          endTime: new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            1
          ),
        };
      } else if (Array.isArray(location?.state?.anchorageLocations)) {
        //  If ordinary access via location and time-range selector
        const { fromYear, fromMonth, toYear, toMonth, anchorageLocations } =
          location.state;
        getAreaBody = {
          AnchorageSysIds: anchorageLocations.map((al) => al.toString()),
        };

        getAnchorageBody = {
          AnchorageSysIds: anchorageLocations.map((al) => al.toString()),
          startTime: new Date(fromYear, fromMonth - 1, 1),
          endTime: new Date(toYear, toMonth, 1),
        };
      }

      Promise.all([
        DataLoader.postApi(getAreaUrl, getAreaBody, this.reportProgress),
        DataLoader.postApi(getAnchorageUrl, getAnchorageBody),
      ])
        .then((jsonResponses) => {
          // Check that all responses are successful
          if (!jsonResponses.every((r) => r.success))
            return Promise.reject(
              new Error("Could not load anchoring areas or anchoring data")
            );
          this.setState({
            anchorageAreaData: jsonResponses[0].data,
            chartData: crossfilter(jsonResponses[1].data),
            getAreaBody,
            getAnchorageBody,
          });
        })
        .catch((error) => console.error(error));
    } catch (error) {
      this.setState({
        anchorageAreaData: null,
        chartData: crossfilter([]),
      });
    }
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const { chartData, progressData, anchorageAreaData, getAnchorageBody } =
      this.state;

    if (
      !chartData ||
      chartData.size() === 0 ||
      !anchorageAreaData ||
      !getAnchorageBody
    ) {
      return <Loader progressData={progressData} chartData={chartData} />;
    }
    var dashboardTitle = "Ankringsområder";
    // console.log(getAnchorageBody);

    let { startTime, endTime } = getAnchorageBody;

    startTime = TimeUtils.formatDate(startTime);
    endTime = TimeUtils.formatDate(endTime);

    var intersectWKT = anchorageAreaData.map((a) => a.properties.wkt);

    var anchorageAreaNames = anchorageAreaData.map(
      (a) => a.properties.anchoragename
    );

    var dimTracks = chartData.dimension(function (d) {
      return [d3.timeDay(new Date(d.start_time)), d.mmsi];
    }, true);

    var dimMmsi = chartData.dimension((d) => d.mmsi);

    var dimLengthGroup = chartData.dimension((d) =>
      KYVGroupings.getShipLengthGroup(d.length)
    );
    var anchoringsByLength = dimLengthGroup.group().reduceCount();
    var shipLengthLabels = KYVGroupings.getShipLengthLabels();

    var dimShipType = chartData.dimension((d) =>
      d.shiptypelevel5 ? d.shiptypelevel5.trim() : "Ukjent"
    );
    var anchoringsPerShipType = dimShipType.group().reduceCount();

    var dimMonth = chartData.dimension(
      (d) => new Date(d.start_time).getMonth() + 1
    );
    var anchoringsPerMonth = dimMonth.group().reduceCount();

    var dimYear = chartData.dimension((d) =>
      new Date(d.start_time).getFullYear()
    );
    var anchoringsPerYear = dimYear.group().reduceCount();

    return (
      <div className="AppView">
        <AVIDashboard
          title={dashboardTitle}
          desc={`Antall ankringer fra ${startTime} til ${endTime} for ankringsområdet/-ene ${anchorageAreaNames.join(
            ", "
          )}. En ankring er et opphold innen området på mer enn 2 t med  hastighet på <= 3 knop. Hvis det ikke foreligger data for skip i mer enn 4 t, kan ankringer bli splittet.`}
          units="Ankringer"
          type="count"
          group={dimMmsi.groupAll()}
          filter={this.props.location.state}
          cfilter={chartData}
          useFlex
        >
          <AVIRow>
            <AVICol>
              <TrackMapChart
                chartTitle="Ankringer (viser inntil 5 000, filterer for å se ønsket utvalg)"
                dimension={dimTracks}
                intersectGeom={intersectWKT}
                chartData={chartData}
                colorScale={(d) => {
                  return `rgba(255, 0, 0, ${Math.random() * 0.01 + 0.15})`;
                }}
                categoryProperty="shiptypelevel5"
                maxFeatures={5000}
                height={3}
                width={2}
                useFlex
              >
                <KyvLayerSwitcher top="10px" right="10px" />
              </TrackMapChart>
            </AVICol>
            <AVICol>
              <BarChartOrdinal
                chartTitle="Antall ankringer etter lengdegruppe"
                dimension={dimLengthGroup}
                group={anchoringsByLength}
                ordering={(d) => shipLengthLabels.indexOf(d.key)}
                width={2}
                height={1.5}
                filterPrefix="Lengdegruppe"
                useFlex
              />
              <RowChart
                chartTitle="Antall ankringer etter skipstype"
                width={2}
                height={1.5}
                // colors={d => {
                //   return AVIColorScales.shipCategory(d);
                // }}
                dimension={dimShipType}
                group={anchoringsPerShipType}
                filterPrefix="Skipstype"
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              chartTitle="Antall ankringer etter år"
              dimension={dimYear}
              group={anchoringsPerYear}
              width={2}
              height={1.5}
              filterPrefix="År"
              xAxisLabel="År"
              useFlex
            />
            <BarChartOrdinal
              chartTitle="Antall ankringer etter måned"
              dimension={dimMonth}
              group={anchoringsPerMonth}
              width={2}
              height={1.5}
              filterPrefix="Måned"
              xAxisLabel="Måned i året (Jan=1, Des=12)"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <DataTable
              chartTitle="Liste over ankringer"
              useFlex
              dimension={dimMmsi}
              sortBy={(d) =>
                d.mmsi +
                "-" +
                TimeUtils.toTimestamp(d.starttime) +
                "-" +
                TimeUtils.toTimestamp(d.endtime)
              }
              width={4}
              size={Infinity}
              columns={[
                {
                  label: "MMSI#",
                  format: (d) => d.mmsi || 0,
                },
                {
                  label: "IMO¤",
                  format: (d) => d.imo || "",
                },
                {
                  label: "Kallesignal",
                  format: (d) => d.callsign || "",
                },
                {
                  label: "Skipsnavn",
                  format: (d) => d.name || "",
                },
                {
                  label: "Starttidspunkt (UTC)",
                  format: (d) =>
                    TimeUtils.toCompactTimestring(new Date(d.start_time)),
                  value: (d) => new Date(d.start_time),
                },
                {
                  label: "Sluttidspunkt (UTC)",
                  format: (d) =>
                    TimeUtils.toCompactTimestring(new Date(d.end_time)),
                  value: (d) => new Date(d.end_time),
                },
                {
                  label: "Varighet (timer)",
                  format: (d) => d.duration,
                },
                {
                  label: "Skipstype",
                  format: (d) => d.shiptypelevel5 || "",
                },
                {
                  label: "BT",
                  title: "Bruttotonnasje",
                  format: (d) => NumUtils.integer(d.gt),
                  value: (d) => d.gt,
                },
                {
                  label: "L",
                  title: "LOA (Length Over All)",
                  format: (d) => NumUtils.decimal2(0 + d.length),
                  value: (d) => +d.length,
                },
                {
                  label: "D",
                  title: "Dyptgående",
                  format: (d) => NumUtils.decimal2(d.draught),
                  value: (d) => d.draught,
                },
              ]}
            />
          </AVIRow>
        </AVIDashboard>
        <div>
          Alle tidspunkt er angitt i UTC (Norsk tid minus 1-2 timer
          sommertid/vintertid).
        </div>
      </div>
    );
  }
}

export const DbrdAnchorage = withRouter(DbrdAnchorageBase);

export default DbrdAnchorage;
