import { Component } from "react";

import PropTypes from "prop-types";
import { TimeUtils } from "./../chart-components/ChartUtils/TimeUtils";
import { DateTimeControl } from "./../filter-components/DateTimeControl/DateTimeControl";

export class FilterToFromTime extends Component {
  static propTypes = {
    setFilter: PropTypes.func.isRequired,
    filter: PropTypes.object,
    minTime: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    maxTime: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    format: PropTypes.string,
  };

  static defaultProps = {
    filter: {},
    minTime: new Date(2005, 0, 1),
    maxTime: TimeUtils.getDateDiff(0),
    format: "dd/MM/yyyy HH:mm",
  };

  constructor(props) {
    super(props);
    this.state = {
      filter: props.filter,
    };
    // this.update = this.update.bind(this);
  }

  update = (key, val) => {
    const { filter, setFilter } = this.props;
    // eslint-disable-next-line no-unused-vars
    var { minTime, maxTime } = this.props;
    // minTime = minTime ? new Date(minTime) : null;
    maxTime = maxTime ? new Date(maxTime) : null;

    filter[key] = val;
    if (filter.fromTime && !filter.toTime) {
      let toTime = new Date(filter.fromTime);
      toTime.setDate(toTime.getDate() + 1);
      filter.toTime = toTime;
    } else if (!filter.fromTime && filter.toTime) {
      filter.toTime = maxTime;
    } else if (!filter.fromTime && !filter.toTime) {
      filter.toTime = maxTime;
    } else if (
      filter.fromTime &&
      filter.toTime &&
      filter.fromTime >= filter.toTime
    ) {
      let toTime = new Date(filter.fromTime);
      toTime.setDate(toTime.getDate() + 1);
      filter.toTime = toTime;
    }

    var today = new Date();

    if (filter.toTime > today) {
      filter.toTime = new Date(today);
    }

    if (filter.fromTime > today) {
      filter.fromTime = new Date(today).setDate(today.getDate() - 1);
    }

    setFilter({
      fromTime: TimeUtils.dateToUTC(filter.fromTime).toISOString(),
      toTime: TimeUtils.dateToUTC(filter.toTime).toISOString(),
    });
  };

  render() {
    const { format } = this.props;
    var { minTime, maxTime } = this.props;
    var { fromTime, toTime } = this.props.filter;

    fromTime = fromTime ? new Date(fromTime) : null;
    toTime = toTime ? new Date(toTime) : null;
    maxTime = maxTime ? new Date(maxTime) : null;

    return (
      <>
        <DateTimeControl
          key="fromTime"
          label="Starttidspunkt"
          name="fromTime"
          minDate={minTime}
          maxDate={maxTime}
          value={fromTime}
          setValue={this.update}
          format={format}
        />
        <DateTimeControl
          key="toTime"
          label="Sluttidspunkt"
          name="toTime"
          minDate={fromTime}
          maxDate={maxTime}
          value={toTime}
          setValue={this.update}
          format={format}
        />
      </>
    );
  }
}
