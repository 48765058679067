import * as d3 from 'd3';

import React, { Component } from 'react';

import { AVIChartTile } from '../Layout/AVIChartTile';
import { BaseMixin } from '../Mixins/BaseMixin';
import { ColorMixin } from '../Mixins/ColorMixin';
import { MarginsMixin } from '../Mixins/MarginsMixin';
import PropTypes from 'prop-types';
import { TileMixin } from '../Mixins/TileMixin';
import * as dc from 'dc';

export class BoxPlot extends Component {
  static propTypes = {
    group: PropTypes.object,
    dimension: PropTypes.object,
    chartTitle: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    showOutliers: PropTypes.bool,
    minY: PropTypes.number
  };

  static defaultProps = {
    showOutliers: false,
    minY: null
  };

  constructor (props) {
    super(props);
    this.chartRef = React.createRef();
    this.chart = null;
    this.getChart = this.getChart.bind(this);
    this.calcDomain = this.calcDomain.bind(this);
  }

  calcDomain (chart) {
    const { minY } = this.props;
    // Calculate quartiles 1 and 3 for all boxes
    var values = chart.group().all().map(d => [d3.quantile(d.value, 0.25), d3.quantile(d.value, 0.75)]);

    // Take the max q3
    var q1 = d3.min(values.map(d => d[0]));

    // Take the min q1
    var q3 = d3.max(values.map(d => d[1]));

    // Determine the interquartile range
    var iqr = q3 - q1;

    // Set the buffer
    var factor = 1.5;

    // Calculate min/max scale values
    var min = minY !== null ? minY : q1 - (factor * iqr);
    var max = q3 + (factor * iqr);

    // Set corresponding y-axis domain
    chart.y(d3.scaleLinear().domain([min, max]));
  }

  getChart () {
    return this.chart;
  }

  componentDidMount () {
    const { group, dimension, showOutliers } = this.props;

    this.chart = dc.boxPlot(this.chartRef.current);

    BaseMixin(this.chart, this.props);
    ColorMixin(this.chart, this.props);
    MarginsMixin(this.chart, this.props);
    TileMixin(this.chart, this.props);
    // CoordinateGridMixin(this.chart, this.props);

    this.chart
      .dimension(dimension)
      .group(group)
      .margins({ top: 10, right: 50, bottom: 30, left: 50 })
      .on('preRender', this.calcDomain)
      .on('preRedraw', this.calcDomain)
      // .elasticY(true)
      .elasticX(true)
      .showOutliers(showOutliers);

    this.chart.render();
  }

  render () {
    return (
      <AVIChartTile
        title={this.props.chartTitle}
        getChart={this.getChart}
        width={this.props.width}
        height={this.props.height}
      >
        <div className='avi-chart' ref={this.chartRef} />
      </AVIChartTile>
    );
  }
}
