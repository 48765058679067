import React, { useEffect, useState } from "react";
import { FormControl, FormGroup, InputGroup } from "react-bootstrap";
import InputGroupText from "react-bootstrap/esm/InputGroupText";
import { WaveCalcParams } from "../../views/DbrdWaveImpact/DbrdWaveImpact";

type OlMapWaveParamsPanelProps = {
    length: number,
    breadth: number,
    waveHeight?: number
    setWaveCalcParams: (params: WaveCalcParams) => any
}

export function OlMapWaveParamsPanel({ length, breadth, waveHeight, setWaveCalcParams }: OlMapWaveParamsPanelProps) {
    const [speed, setSpeed] = useState<number>(0);
    const [_length, setLength] = useState<number>(length);
    const [_breadth, setBreadth] = useState<number>(breadth);

    // Empirical constant to scale the wave height (adjusted for larger waves)
    const [empiricalConstant, setEmpiricalConstant] = useState<number>(1.7);

    // Pow factor for wave depletion
    const [falloff, setFalloff] = useState<number>(0.25);

    useEffect(() => {
        setLength(length);
        setBreadth(breadth);
        return () => {
        }
    }, [length, breadth]);

    useEffect(() => {
        setWaveCalcParams({
            speed,
            length: _length,
            breadth: _breadth,
            falloff,
            empiricalConstant
        });
        return () => {
        }
    }, [_breadth, _length, empiricalConstant, falloff, setWaveCalcParams, speed]);

    return (
        <div className="d-flex flex-column gap-0">
            <FormGroup>
                <InputGroup>
                    <InputGroupText>Skipets fart (knop)</InputGroupText>
                    <FormControl type="number" step={0.5} value={speed} onChange={(evt) => setSpeed(+evt.target.value)}></FormControl>
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <InputGroup>
                    <InputGroupText>Skipets lengde (m)</InputGroupText>
                    <FormControl type="number" step={1} value={_length} onChange={(evt) => setLength(+evt.target.value)}></FormControl>
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <InputGroup>
                    <InputGroupText>Skipets bredde (m)</InputGroupText>
                    <FormControl type="number" step={1} value={_breadth} onChange={(evt) => setBreadth(evt.target.value ? +evt.target.value : 0)}></FormControl>
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <InputGroup>
                    <InputGroupText>Bølgehøyde ved baug (faktor)</InputGroupText>
                    <FormControl type="number" step={0.01} value={empiricalConstant} onChange={(evt) => setEmpiricalConstant(Number(evt.target.value))}></FormControl>
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <InputGroup>
                    <InputGroupText>Energitap for bølge (faktor)</InputGroupText>
                    <FormControl type="number" step={0.01} value={falloff} onChange={(evt) => setFalloff(Number(evt.target.value))}></FormControl>
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <InputGroup>
                    <InputGroupText>Bølgehøyde under peker</InputGroupText>
                    <FormControl value={waveHeight} disabled readOnly></FormControl>
                </InputGroup>
            </FormGroup>
        </div>
    )
}