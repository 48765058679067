import "./DbrdSailedDistanceTime.scss";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import * as d3 from "d3";
import PropTypes from "prop-types";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { BarChartStacked } from "../../chart-components/Charts/BarChartStacked";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { PivotTable } from "../../chart-components/ChartsExt/PivotTable";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { FilterToFromMonth } from "../../filters/FilterToFromMonth";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";
import { County } from "../../utils/codelists";

class DbrdSailedDistanceTimeBase extends Component<any, any> {
  /**
   * The route to be used for the dashboard
   */
  static dashboardRoute = "sailed-distance-time";

  /**
   * The help text and filter controls to be used as initial filtering for the dashboard
   * @param {*} filter
   * @param {*} setFilter
   */
  static dashboardFilters(filter, setFilter) {
    return {
      helpMessage:
        "Dette dashboardet gjør det mulig å analysere inntil et år om gangen. Velg et tidsrom (hele måneder)",
      controls: [
        <FilterToFromMonth
          key="flt-month"
          firstYear={2013}
          filter={filter}
          setFilter={setFilter}
        />,
      ],
    };
  }

  /**
   * The dashboard settings, i.e. map interaction filter controls, selectable layers etc.
   */
  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: null,
    };
  }

  /**
   * The dashboard validation function
   * @param {*} filter
   */
  static dashboardValidation(filter) {
    if (filter.fromTime && filter.toTime) {
      return true;
    } else {
      return false;
    }
  }

  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        fromTime: PropTypes.any.isRequired,
        toTime: PropTypes.any.isRequired,
      }).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
    };
    this.reportProgress = this.reportProgress.bind(this);
  }

  padZero = d3.format("02d");

  componentDidMount() {
    try {
      const { fromTime, toTime } = this.props.location.state;
      if (fromTime && toTime) {
        DataLoader.postApi(
          "/api/ais/sailed-distance/admin/dates",
          {
            StartDate: fromTime,
            EndDate: toTime,
          },
          this.reportProgress
        ).then((jsonResponse) => {
          var success = true;

          if (!jsonResponse.success) {
            if (success) {
              success = false;
            }
          }

          if (!success) {
            throw new Error("Error loading sailed distance time");
          }

          var data = jsonResponse.data;
          this.setState({
            fromTime,
            toTime,
            chartData: crossfilter(data),
            numRecords: data.length,
          });
        });
      } else {
        throw new Error("Error no data returned");
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const { chartData, fromTime, toTime, progressData } = this.state;

    if (!chartData || chartData.size() === 0) {
      return <Loader progressData={progressData} chartData={chartData} />;
    }

    var dimCount = chartData.dimension((d) => d.month);

    var dimFylke = chartData.dimension((d) => County[`${d.fylke}`]);
    var nmiByFylke = dimFylke.group().reduceSum((d) => Math.round(d.nmi));

    var dimCountyMonth = chartData.dimension((d) => [
      County[`${d.fylke}`],
      this.padZero(new Date(d.month).getMonth() + 1),
    ]);

    var nmiByCountyMonth = dimCountyMonth
      .group()
      .reduceSum((d) => Math.round(d.nmi));

    var dimArea = chartData.dimension((d) => `${d.area_name} (${d.area_id})`);
    var nmiByArea = dimArea.group().reduceSum((d) => Math.round(d.nmi));

    var dimYear = chartData.dimension((d) => new Date(d.month).getFullYear());
    var nmiByYear = dimYear.group().reduceSum((d) => Math.round(d.nmi));
    var years = nmiByYear.all().map((d) => d.key);

    var dimMonth = chartData.dimension((d) => new Date(d.month).getMonth() + 1);
    var nmiByMonth = dimMonth.group().reduceSum((d) => Math.round(d.nmi));

    var dimAisClass = chartData.dimension((d) => d.aisclass);
    var nmiByAisClass = dimAisClass.group().reduceSum((d) => Math.round(d.nmi));

    var dimShipCategory = chartData.dimension((d) => d.shipcategory);
    var nmiByShipCategory = dimShipCategory
      .group()
      .reduceSum((d) => Math.round(d.nmi));

    return (
      <div className="AppView">
        <AVIDashboard
          title="Utseilt distanse for hele landet"
          desc={`Utseilt distanse i nautiske mil (NM) i perioden fra ${TimeUtils.getYearMonth(
            fromTime
          )} til og med ${TimeUtils.getYearMonth(
            toTime,
            -1
          )}. Arealet som er benyttet går ut til grunnlinjen (12 nautiske mil).`}
          spacing={10}
          keyName="mmsino"
          valueAccessor={(d) => d.count}
          group={dimCount.groupAll()}
          cmsSlug={DbrdSailedDistanceTimeBase.dashboardRoute}
          cfilter={chartData}
          filter={this.props.location.state}
          useFlex
        >
          <AVIRow>
            <AVICol>
              <PivotTable
                chartTitle="Ankomster per år etter fylke"
                height={2}
                useFlex
                group={nmiByCountyMonth}
                dimension={dimCountyMonth}
                rowLabel="Fylke"
                colLabel="Måned"
                rowKeyIndex={0}
                colKeyIndex={1}
                filterPrefix="Fylke"
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartOrdinal
                group={nmiByFylke}
                dimension={dimFylke}
                chartTitle="Utseilt distanse etter fylke"
                height={1}
                margins={{ left: 65 }}
                yAxisLabel="Utseilt distanse (NM)"
                filterPrefix="Fylke"
                ordering={(d) => d.key}
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartOrdinal
                group={GroupUtils.RemoveEmptyBinsTopN(nmiByArea, 15)}
                dimension={dimArea}
                chartTitle="Utseilt distanse etter kommune (15 største innen filter)"
                height={1}
                margins={{ left: 65 }}
                yAxisLabel="Utseilt distanse (NM)"
                filterPrefix="Kommune"
                ordering={(d) => d.key}
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartOrdinal
                group={nmiByYear}
                dimension={dimYear}
                chartTitle="Utseilt distanse etter år"
                height={1}
                margins={{ left: 65 }}
                yAxisLabel="Utseilt distanse (NM)"
                filterPrefix="År"
                ordering={(d) => d.key}
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartOrdinal
                group={nmiByMonth}
                dimension={dimMonth}
                chartTitle="Utseilt distanse (NM) etter måned i året"
                useFlex
                height={1}
                margins={{ left: 65 }}
                yAxisLabel="Utseilt distanse"
                filterPrefix="Måned"
                ordering={(d) => d.key}
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <RowChart
                chartTitle="Utseilt distanse (NM) etter AIS-klasse"
                group={nmiByAisClass}
                dimension={dimAisClass}
                useFlex
                height={1.5}
                gap={1}
                filterPrefix="AIS-klasse"
              />
            </AVICol>
            <AVICol>
              <RowChart
                chartTitle="Utseilt distanse (NM) etter skipskategori"
                group={nmiByShipCategory}
                dimension={dimShipCategory}
                useFlex
                height={1.5}
                gap={1}
                filterPrefix="Skipskategori"
              />
            </AVICol>{" "}
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartStacked
                chartTitle="Utseilt distanse (NM) etter lengdegruppe og år"
                dimension={dimYear}
                xAxisTickValues={years}
                useFlex
                height={1.5}
                margins={{ top: 50 }}
                gap={2}
                filterPrefix="År"
                stackKey="length_grp"
                valueKey="nmi"
                renderLabel
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartStacked
                chartTitle="Utseilt distanse (NM) etter bruttotonnasjegruppe og år"
                dimension={dimYear}
                xAxisTickValues={years}
                useFlex
                height={1.5}
                margins={{ top: 50 }}
                gap={2}
                filterPrefix="År"
                stackKey="gt_grp"
                valueKey="nmi"
                renderLabel
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartStacked
                chartTitle="Utseilt distanse (NM) etter dødvektstonnasje og år"
                dimension={dimYear}
                xAxisTickValues={years}
                useFlex
                height={1.5}
                margins={{ top: 50 }}
                gap={2}
                filterPrefix="År"
                stackKey="dwt_grp"
                valueKey="nmi"
                renderLabel
              />
            </AVICol>
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdSailedDistanceTime = withRouter(DbrdSailedDistanceTimeBase);

export default DbrdSailedDistanceTime;
