import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { DashboardFilter } from "../types/DashboardFilter";
import { combinePath } from "../utils/path/combinePath";
import { DataLoader } from "./DataLoader/DataLoader";

export type ShareDialogProps = {
  shareVisible: boolean;
  setShareVisible: Dispatch<SetStateAction<boolean>>;
  filter: DashboardFilter;
};

export function ShareDialog({
  shareVisible,
  setShareVisible,
  filter,
}: ShareDialogProps) {
  const { pathname } = useLocation();
  const [title, setTitle] = useState<string>("");
  const [link, setLink] = useState<string>("");

  const onShare = useCallback(async () => {
    try {
      const res = await DataLoader.createShortCode({
        title: "Bookmark",
        route: pathname,
        filter: filter,
      });
      if (!res || res.success === false) {
        throw new Error("Could not find data for supplied shortcode");
      };
      const shareLink = combinePath(window.document.location.origin, process.env.PUBLIC_URL||"" ,"link", res.data.shortcode);
      setLink(shareLink);
      setShareVisible(true);
      if ("clipboard" in navigator) {
        await navigator.clipboard.writeText(shareLink);
      }
    } catch (error) {
      console.warn(error);
    }
  }, [filter, pathname, setShareVisible]);

  useEffect(() => {
    return () => {
      setTitle("");
      setLink("");
    };
  }, []);

  return (
    <Modal
      show={shareVisible}
      onHide={() => setShareVisible(false)}
      centered
      backdrop="static"
    >
      <ModalHeader closeButton>Del lenke til dashboard</ModalHeader>
      <ModalBody>
        <p>Linken vil ta med seg filtre du har satt på utvalgstidspunktet</p>
        <FormGroup>
          <FormLabel>Tittel (valgfritt)</FormLabel>
          <FormControl
            value={title}
            onChange={(evt) => setTitle(evt.target.value)}
          ></FormControl>
        </FormGroup>
        <FormGroup>
          <FormLabel>Lenke</FormLabel>
          <div className="d-flex gap-2">
          <FormControl value={link} readOnly></FormControl>
          <Button variant="light" disabled={link === ""} onClick={async ()=>{
            if ("clipboard" in navigator) {
              await navigator.clipboard.writeText(link);
            }
          }}><i className="bi bi-copy"></i></Button>
          </div>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" onClick={() => setShareVisible(false)}>
          Avbryt
        </Button>
        <Button onClick={() => onShare()}>
          Opprett lenke, kopier og lagre
        </Button>
      </ModalFooter>
    </Modal>
  );
}
