import { DataContextProvider } from "../../context/DataContext";
import FilterList from "../../ui-components/filter-view/FilterList/FilterList";

export function FilterView({ props }) {
  return (
    <DataContextProvider>
      <FilterList {...props} />
    </DataContextProvider>
  );
}

export default FilterView;
