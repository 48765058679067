import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { EventLike } from "../../types/Shims";
import { createEventLike } from "../../utils/createEventLike";

export type SelectSrsControlProps = {
  value: number;
  name: string;
  onChange: (evt: EventLike<number>) => void;
};

export function SelectSrsControl({
  value,
  onChange,
  name,
}: SelectSrsControlProps) {
  return (
    <FormGroup className="mb-3">
      <FormLabel>Velg koordinatsystem</FormLabel>
      <FormControl
        as="select"
        value={value}
        name={name}
        onChange={(evt: any) =>
          onChange(createEventLike(evt.target.name, evt.target.value))
        }
      >
        <option value={4326}>Lengde- og breddegrader - EPSG:4326</option>
        <option value={32632}>UTM sone 32N - EPSG:32632</option>
        <option value={32633}>UTM sone 33N - EPSG:32633</option>
        <option value={32634}>UTM sone 34N - EPSG:32634</option>
        <option value={3575}>Polarprojeksjon - EPSG:3575</option>
        <option value={3857}>Web Mercator - EPSG:3857</option>
      </FormControl>
    </FormGroup>
  );
}
