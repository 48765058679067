import React, { Component } from "react";
import "./ArticlePopup.scss";
import { PropTypes } from "prop-types";
import { WPLoader } from "./../WPLoader/WPLoader";
import Icon from "./../../filter-components/Icon/Icon";

export class ArticlePopup extends Component {
  static propTypes = {
    cmsSlug: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static defaulProps = {
    cmsSlug: null,
    visible: false,
    onClose: () => console.warn("Close, not implemented"),
  };

  constructor() {
    super();
    this.state = {
      article: null,
    };
  }

  componentDidMount() {
    const { cmsSlug } = this.props;
    if (cmsSlug !== null) {
      WPLoader.LoadArticleBySlug(cmsSlug)
        .then((res) => {
          this.setState({
            article: res.body[0],
          });
        })
        .catch((err) => {
          console.warn(`No article found for supplied slug: ${cmsSlug}`, err);
        });
    }
  }

  render() {
    const { visible, onClose } = this.props;
    const { article } = this.state;
    if (!visible) return null;

    return (
      <div className="article-popup-static-background">
        <div className="content">
          <div className="header">
            <div className="title">
              <h2
                className="mb-3"
                dangerouslySetInnerHTML={{ __html: article.title.rendered }}
              />
            </div>
            <div className="close-btn">
              <button className="close-btn" onClick={onClose}>
                <Icon name="cross" />
              </button>
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: article.content.rendered }} />
          {/* <pre>
            {JSON.stringify(article, null, 2)}
          </pre> */}
        </div>
      </div>
    );
  }
}
