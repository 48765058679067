import { useState, useCallback } from "react";
import "./AuthComponents.scss";
import AuthRequest from "./AuthRequests";

export default function ResetPasswordForm({ setMode, modes, showMessage }) {
  const [email, setEmail] = useState("");

  const onCancel = useCallback(
    (e) => {
      setMode(modes.login)();
    },
    [setMode, modes]
  );

  const onSubmit = useCallback(
    (e) => {
      AuthRequest.doReset(email).then((res) => {
        setMode(modes.login)();
        showMessage("Sjekk e-posten din for å finne det nye passordet");
      });
    },
    [email, showMessage, modes, setMode]
  );

  return (
    <form className="kyv-auth-form">
      <div className="row">
        <label className="flex-1">
          E-postadresse tilknyttet eksisterende konto
        </label>
      </div>
      <div className="row">
        <input
          className="flex-3"
          type="email"
          name="email"
          value={email}
          onChange={(evt) => setEmail(evt.target.value)}
        />
      </div>
      <div className="row align-right">
        <input
          type="button"
          name="cancel"
          onClick={onSubmit}
          value={"Tilbakestill passord"}
        />
        <input
          type="button"
          name="cancel"
          onClick={onCancel}
          value={"Avbryt"}
        />
      </div>
    </form>
  );
}
