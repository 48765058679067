// import "./Lead.scss";

import { useCallback, useEffect, useState } from "react";

import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  useAuthContext,
} from "../../../auth-components/AuthLib";
import { RequireAnonymous } from "../../../auth-components/RequireAnonymous";
import { RequireLogin } from "../../../auth-components/RequireLogin";
import { WPLoader } from "../../WPLoader/WPLoader";

export type LeadProps = {
  className?: string;
};

export default function Lead({ className }: LeadProps) {
  const [article, setArticle] = useState<any>(null);
  const { authState } = useAuthContext();
  const { authenticated } = authState;

  const loadArticle = useCallback((slug: string) => {
    WPLoader.LoadArticleBySlug(slug, "pages").then((res: any) => {
      if (Array.isArray(res.body) && res.body.length === 1) {
        setArticle(res.body[0]);
      }
    });
  }, []);

  useEffect(() => {
    if (authenticated === true) {
      loadArticle("heading-content-auth");
    } else {
      loadArticle("heading-content-public");
    }
  }, [authenticated, loadArticle]);

  return (
    <div className={`bg-secondary p-5 ${className}`}>
      <Container>
        <div className="d-flex gap-5">
          <div className="flex-grow-1" style={{ flex: 1 }}>
            <iframe
              title="Introduction video to Kystdatahuset"
              height="300"
              width="100%"
              src="https://www.youtube.com/embed/4g0QvRqVXwc"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <div className="flex-grow-1" style={{ flex: 1 }}>
            {article !== null && (
              <>
                <div className="mb-4">
                  <h2>{article.title.rendered}</h2>
                </div>
                <div
                  className="w-100 mb-4"
                  dangerouslySetInnerHTML={{ __html: article.content.rendered }}
                ></div>
                <RequireAnonymous authenticated={false}>
                  <Link
                    className="btn ms-auto btn-lg btn-danger text-light"
                    role="button"
                    to="/logginn"
                  >
                    Logg inn eller registrer deg
                  </Link>
                </RequireAnonymous>
                <RequireLogin>
                  <div className="d-flex">
                    <Link
                      className="btn btn-danger ms-auto btn-lg text-light"
                      role="button"
                      to="/tallogstatistikk"
                    >
                      Utforsk tall og statistikk
                    </Link>
                  </div>
                </RequireLogin>
              </>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
