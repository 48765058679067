import React, { PropsWithChildren, useContext } from "react";
import { AuthContext } from "./AuthLib";

/**
 * Do not display children unless user is authenticated
 *
 */

export function RequireLogin({ children }: PropsWithChildren<any>) {
  const { authState } = useContext(AuthContext);
  if (authState.authenticated !== true ||
    authState.expiration_time <= new Date())
    return null;

  return <>{children}</>;
}
