export class ShipDataPropertyMapping {
  static nsrShip = {
    'imono': 'IMO-nummer',
    'mmsino': 'MMSI-nummer',
    'callsign': 'Kallesignal',
    'shipname': 'Fartøysnavn',
    'yearofbuild': 'Byggeår',
    'grosstonnage': 'Bruttotonnasje (BT)',
    'dwt': 'Dødvekttonn (DWT)',
    'length': 'Lengde',
    'breadth': 'Bredde',
    'speed': 'Hastighet',
    'power': 'Kraft',
    'draught': 'Dyptgående',
    'shiptypenor': 'Skipstype',
    'shiptypeeng': 'Skipstype (Engelsk)',
    'shipflagcode': 'Flaggkode',
    'countrynamenor': 'Flaggstat',
    'countrynameeng': 'Flaggstat (Engelsk)',
    'twocharcode': 'Flaggstat tobokstavskode',
    'threecharcode': 'Flaggstat trebokstavskode',
    'height': 'Height',
    // Har ikkje rett omsetjing
    'shiptypegroupno': 'Skipsgruppe',
    'shiptypegroupen': 'Skipsgruppe (Engelsk)',
    'hasonshorepower': 'Har landstrømaggregat'
  };

  static fairplay = {
    'id': 'Id nummer Fairplay',
    'lrimoshipno': 'IMO-nummer',
    'shipname': 'Fartøysnavn',
    'shipstatus': 'Skipets status',
    'mmsi': 'MMSI-nummer',
    'flagname': 'Flaggstat (Engelsk)',
    'flagcode': 'Flaggkode',
    'statcode5': 'Statkode 5',
    'shiptypelevel2': 'Skipstype nivå 2',
    'shiptypelevel3': 'Skipstype nivå 3',
    'shiptypelevel4': 'Skipstype nivå 4',
    'shiptypelevel5': 'Skipstype nivå 5',
    'classificationsocietycode': 'Klassifiserings-selskapskode',
    'dateofbuild': 'Byggedato',
    'yearofbuild': 'Byggeår',
    'breadth': 'Bredde',
    'breadthextreme': 'Bredde max',
    'deadweight': 'Dødvekttonn (DWT)',
    'depth': 'Dybde',
    'displacement': 'Deplasement',
    'draught': 'Dyptgående',
    'formuladwt': 'Formula dødvekttonn',
    'length': 'Lengde',
    'lengthbetweenperpendicularslbp': 'Lengde mellom perpendikulær',
    'lengthoverallloa': 'Lengde over alt (LOA)',
    'lengthregistered': 'Registrert lengde',
    'lightdisplacementtonnage': 'Lett displasement',
    'nettonnage': 'Nettotonnasje (NT)',
    'grosstonnage': 'Bruttotonnasje (BT)',
    'compensatedgrosstonnagecgt': 'Bruttotonnasje fratrukket SEG',
    'cargocapacitiesnarrative': 'Kapasiteter ballasttanker, lugarer, passasjerer, mannskap og last',
    'bollardpull': 'Bollardpull',
    'liquidcapacity': 'Kapasitet flytende last',
    'passengercapacity': 'Passasjerkapasitet',
    'teu': 'Kapasitet antall konteinere (Teu)',
    'registeredowner': 'Registrert eier',
    'registeredownercode': 'Registrert eier kode',
    'registeredownercountryofdomicilecode': 'Registrert eier landkode',
    'registeredownercountryofcontrol': 'Registrert eier land for kontroll',
    'operatorcompanycode': 'Operatørselskapskode',
    'operatorcountryofregistration': 'Operatør registrert i land',
    'shipmanagercompanycode': 'Skipsmanager selskapskode',
    'shipmanagercoutryofdomicilecode': 'Skipsmanager landkode',
    'technicalmanager': 'Teknisk manager',
    'mainenginebuildercode': 'Hovedmotor produsentkode',
    'gascapacity': 'Kapasitet gass',
    'pandiclubcode': 'Pandiklubbkode',
    'totalpowerofallengines': 'Total motorkraft alle motorer',
    'totalhorsepowerofmainengines': 'Totalt antall hestekrefter hovedmotor',
    'totalkilowattsofmainengines': 'Totalt antall kilowattimer hovedmotor',
    'powerkwservice': 'Kraft kw ved normal drift',
    'powerkwmax': 'Maksimum kw',
    'fueltype1capacity': 'Drivstofftype1 kapasitet',
    'fueltype1first': 'Drivstofftype1 type',
    'fueltype1code': 'Drivstofftype1 kode',
    'fueltype2capacity': 'Drivstofftype2 kapasitet',
    'fueltype2second': 'Drivstofftype2 type',
    'numberofallengines': 'Antall motorer totalt',
    'numberofauxiliaryengines': 'Antall hjelpemotorer',
    'powerbhpihpshpmax': 'powerbhpihpshpmax',
    'powerbhpihpshpservice': 'powerbhpihpshpservice',
    'speed': 'Fart',
    'speedmax': 'Maksimum fart',
    'speedservice': 'Fart ved normal drift',
    'maritimemobileserviceidentitymmsinumber': 'MMSI nummer',
    'fishingnumber': 'Fiskerinummer',
    'hulltype': 'Skrogtype',
    'shiptypegroup': 'Skipsgruppe',
    'imochemicalclassi': 'IMO kjemikalieklasse I',
    'imochemicalclassii': 'IMO kjemikalieklasse II',
    'imochemicalclassiii': 'IMO kjemikalieklasse III',
    'numberoftanks': 'Antall tanker',
    'flashpointover60c': 'Antennelsestemperatur over 60 grader C',
    'flashpointunder60c': 'Antennelsestemperatur under 60 grader C',
    'numberofholds': 'Antall lasterom',
    'sloptankcapacity': 'Slopptankkapasitet',
    'specialtanksdescriptivenarrative': 'Spesialtank beskrivelse',
    'tanksdescriptivenarrative': 'Tanker beskrivelse',
    'tempmaximum': 'Temperatur maksimum',
    'tempminimum': 'Temperatur minimum'
  };

  static shipinfo = {
    'ins_no': 'Id nummer Ship-Info',
    'imo_no': 'IMO-nummer',
    'mssi_no': 'MMSI-nummer',
    'vessel_name': 'Fartøysnavn',
    'area_no': 'Områdenummer',
    'gt_grt': 'Bruttotonnasje (BT)',
    'depth': 'Dybde',
    'mdwt': 'Dødvekttonn (DWT)',
    'vessel_category': 'Skipskategori',
    'vessel_type': 'Skipstype',
    'loa': 'Lengde over alt (LOA)',
    'lpp': 'Lengde mellom perpendikulær',
    'eng_total_kw': 'Totalt antall kw',
    'eng_total_bhp': 'Totalt antall hestekrefter',
    'br_moulded': 'Bredde mellom spant (moulded)',
    'vessel_nation_id': 'Flaggstat trebokstavskode',
    'air_draught': 'Høyde luftspenn (Air Draught)',
    'port_of_reg': 'Registreringshavn',
    'draught': 'Dyptgående',
    'diesel_oil': 'Mengde dieselolje',
    'diesel_oil_unit': 'Måleenhet dieselolje',
    'water…': 'Mengde vann',
    'water_unit…': 'Måleenhet vanntank',
    'fuel_oil': 'Mengde drivstoff',
    'fuel_oil_unit': 'Måleenhet drivstofftank',
    'built_year': 'Byggeår',
    'yard': 'Byggeverft',
    'class': 'Klasseselskap',
    'nt': 'Nettotonnasje (NT)',
    'man_owner': 'Hovedeier',
    'owner_address': 'Eiers gateadresse',
    'owner_post_no': 'Eiers post nr og navn',
    'owner_nation': 'Eiers land navn',
    'owner_enterprise_no': 'Eiers organisasjonsnummer',
    'owner_telephone': 'Eiers telefonnummer',
    'owner_telefax': 'Eiers telefaks nummer',
    'owner_email': 'Eiers e-post-adresse',
    'owner_internet': 'Eiers internett-adresse',
    'agent_owner': 'Agent morselskap',
    'agent_owner_address': 'Agent morselskapgateadresse',
    'agent_owner_post_no': 'Agent morselskap post nr og navn',
    'agent_owner_nation': 'Agent morselskap nasjonalitet',
    'agent_owner_enterprise_no': 'Agent morselskap selskapsnummer',
    'agent_owner_telephone': 'Agent morselskap telefonnummer',
    'yard_id': 'Verft ID',
    'man_owner_id': 'Eier ID',
    'agent_owner_id': 'Agent morselskap ID',
    // Har ikkje rett omsetjing
    'fish_id': 'Fiskeri Id',
    'ballast': 'Ballast',
    'ballast_unit': 'Ballastenhet',
    'water': 'Vannmengde',
    'water_unit': 'Vann måleenhet',
    'gas_oil': 'Gassolje mengde',
    'gas_oil_unit': 'Gassolje måleenhet',
    'owner_mob_phone': 'Eier mobiltelefonnummer',
    'man_agent': 'Agent',
    'agent_adress': 'Agentens adresse',
    'agent_post_no': 'Agentens postnummer og -navn',
    'agent_nation': 'Agentens nasjonalitet',
    'agent_enterprise_no': 'Agentens selskapsnummer',
    'agent_telephone': 'Agentenss telefonnummer',
    'agent_telefax': 'Agentens telefaksnummer',
    'agent_mob_phone': 'Agentens mobiltelefonnummer',
    'agent_email': 'Agentens epostadresse',
    'agent_internet': 'Agentens internettadresse',
    'agent_owner_telefax': 'Agent Owner Telefax',
    'agent_owner_mob_phone': 'Agent morselskap mobiltelefonnummer',
    'agent_owner_email': 'Agent morselskap epostadresse',
    'agent_owner_internet': 'Agent morselskap internettadresse',
    'tech_man': 'Tekninsk manager',
    'tech_address': 'Teknisk managers adresse',
    'tech_post_no': 'Teknisk managers postnummer og -navn',
    'tech_nation': 'Teknisk managers nasjonalitet',
    'tech_enterprise_no': 'Teknisk managers selskapsnummer',
    'tech_telephone': 'Teknisk managers telefonnummer',
    'tech_telefax': 'Teknisk managers telefaksnummer',
    'tech_mob_phone': 'Teknisk managers mobiltelefonnummer',
    'email': 'Epostadresse',
    'internet': 'Internettadresse',
    'vessel_mob_phone': 'Skipets mobiltelefonnummer',
    'vessel_mob_phone_remark': 'Merknad til skipets mobiltelefonnummer',
    'vessel_satcom': 'Skipets satellittkommunikasjon',
    'vessel_satcom_remark': 'Merknad til skipets satellittkommunikasjon',
    'man_agent_id': 'Agent ID',
    'tech_man_id': 'Teknisk manager ID'
  };

  static combinedShip = {
    "mmsi": "MMSI",
    "imo": "IMO",
    "callsign": "Kallesignal",
    "shipname": "Skipsnavn",
    "shiptype": "Skipstype",
    "loa": "Lengde (LOA)",
    "breadthextreme": "Bredde (extreme)",
    "breadthmoulded": "Bredde (moulded)",
    "height": "Høyde",
    "draught": "Dyptgående",
    "gt": "Bruttotonnasje",
    "dwt": "Dødvektstonnasje",
    "teu": "Containerkapasitet (TEU)",
    "cc3": "Landskode, 3 tegn",
    "liquidcapacity": "Veskekapasitet",
    "gascapacity": "Gasskapasitet",
    "yearofbuild": "Byggeår",
    "powerkwmax": "Maks motorytelse (kw)",
    "speedservice": "Service hastighet",
    "speedmax": "Makshastighet",
    "mainenginerpm": "RPM hovedmotor",
    "bollardpull": "Bollard pull",
    "statcode5": "Statcode 5",
    "shiptypelevel5": "Statcode 5 beskrivelse",
    "src": "Kilde",
    "fairplay_id": "Fairplay ID (IMO)",
    "fiskdir_id": "Fiskeridir ID",
    "nornis_id": "NORNIS ID",
    "nsr_id": "NSR ID",
    "shipinfo_id": "Shipinfo ID",
    "combined_id": "Kombinert ID",
    "csid": "Kombinert numerisk ID",
    "maru_type": "MARU typebetegnelse"
};  
}
