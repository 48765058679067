import React, { PropsWithChildren } from "react";
import { DropdownItem, DropdownItemProps } from "react-bootstrap";

export function AVITileHeaderDropdownItem({
  children,
  className,
  ...dropdownProps
}: PropsWithChildren & DropdownItemProps) {
  return (
    <DropdownItem
      className={`${className} kyv--text-body py-2 border-bottom-1`}
      style={{textDecoration: "none"}}
      {...dropdownProps}
    >
      {children}
    </DropdownItem>
  );
}
