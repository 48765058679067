import "./DbrdPasslineMulti.scss";

import * as d3 from "d3";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { AVIColorScales } from "../../chart-components/AVIColorScales";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { TrackMapChart } from "../../chart-components/ChartsExt/TrackMapChart";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { FilterToFromMonth } from "../../filters/FilterToFromMonth";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";
import { SelectPasslinesInMap } from "../../ui-components/filter-view/FilterMap/SelectPasslinesInMap";
import { KyvLayerSwitcher } from "../../ui-components/filter-view/FilterMap/kyv-layer-switcher/KyvLayerSwitcher";
import { isArrayN } from "../../utils/array/isArrayN";
import { uniqueArrayValues } from "../../utils/array/uniqueArrayValues";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { NumUtils } from "../../chart-components/ChartUtils/NumUtils";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { KYVGroupings } from "../../utils/KYVGroupings";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../types/DashboardFilter";

class DbrdPasslineMultiBase extends Component<any, any> {
  static dashboardRoute = "passeringslinjer-multi";

  static dashboardFilters(
    filter: DashboardFilter,
    setFilter: SetDashboardFilterFunc
  ) {
    return {
      helpMessage:
        "Dashboardet to passeringslinjer genererer mye data og stpre spørringer. For områder med mye trafikk kan du velge noen få dager i gangen. Prøv deg frem. Gå tilbake og begrens utvalget hvis fremdriftsindikatoren blir stående å spinne på skjermen lenge. Tidspunkt i dataene er angitt som UTC.",
      controls: [
        <FilterToFromMonth
          firstYear={2013}
          key="flt-to-from-month"
          filter={filter}
          setFilter={setFilter}
        />,
        <SelectPasslinesInMap
          key="flt-sel-pass-map"
          name="geom"
          filter={filter}
          setFilter={setFilter}
          multiple={2}
        />,
      ],
    };
  }

  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: null,
    };
  }

  static dashboardValidation(filter) {
    if (filter.fromTime && filter.toTime && isArrayN(filter.geom, 2, true)) {
      return true;
    }
    return false;
  }

  static propTypes = {
    location: PropTypes.object,
  };

  defaultParams = {
    geom1:
      "LineString (5.21596816109962269 61.86617076260246506, 5.21831593812578465 61.88025742475943503)",
    geom2:
      "LineString (5.16062770262579829 61.80177459274201368, 5.16129849606184443 61.83464347110828641)",
    startTime: new Date("2018-01-01T00:00:00"),
    endTime: new Date("2018-01-05T00:00:00"),
  };

  constructor(props) {
    super(props);
    this.state = {
      trackCf: null,
      passCf: null,
      geom: null,
    };

    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    const { geom, fromTime, toTime } = this.props.location.state;
    try {
      var geom1 = geom[0];
      var geom2 = geom[1];
      var startTime = new Date(fromTime);
      startTime.setHours(0);
      startTime.setMinutes(0);
      startTime.setSeconds(0);
      startTime.setMilliseconds(0);
      var endTime = new Date(toTime);
      endTime.setHours(0);
      endTime.setMinutes(0);
      endTime.setSeconds(0);
      endTime.setMilliseconds(0);

      if (geom1 !== undefined && geom2 !== undefined && startTime && endTime) {
        Promise.all([
          DataLoader.postApi("/api/tracks/intersects-line", {
            Geom: geom1,
            StartTime: fromTime,
            EndTime: toTime,
          }),
          DataLoader.postApi(
            "/api/tracks/intersects-line",
            {
              Geom: geom2,
              StartTime: fromTime,
              EndTime: toTime,
            },
            this.reportProgress
          ),
        ])
          .then((jsonResponse) => {
            // Check that all responses are ok
            if (!jsonResponse.every((res) => res && isArrayN(res.data, 1))) {
              throw new Error(
                "Error loading passline intersections at first and/or second passline"
              );
            }
            // Process results
            const [pl1Res, pl2Res] = jsonResponse;

            // Calculate unique mmsis and dates for passline 1
            let uqPl1: any[] = [];
            pl1Res.data.forEach((row) => {
              const uqStr = `${row.mmsi}|${new Date(
                row.starttime
              ).toDateString()}`;
              row.uqStr = uqStr;
              uqPl1.push(uqStr);
            });
            uqPl1 = uniqueArrayValues(uqPl1);

            let uqPl2: any[] = [];
            // Calculate unique mmsis and dates for passline 2
            pl2Res.data.forEach((row) => {
              const uqStr: string = `${row.mmsi}|${new Date(
                row.starttime
              ).toDateString()}`;
              row.uqStr = uqStr;
              uqPl2.push(uqStr);
            });
            uqPl2 = uniqueArrayValues(uqPl2);

            this.setState({
              passCf: crossfilter(
                pl2Res.data.filter((row) => uqPl1.indexOf(row.uqStr) > -1)
              ),
              trackCf: crossfilter(
                pl2Res.data
                  .filter((row) => uqPl1.indexOf(row.uqStr) > -1)
                  .concat(
                    pl1Res.data.filter((row) => uqPl2.indexOf(row.uqStr) > -1)
                  )
              ),
              geom: [geom1, geom2],
            });
          })
          .catch((error) => {
            console.warn(error);
            this.setState({
              trackCf: crossfilter([]),
            });
          });
      } else {
        throw new Error("Missing selection parameters");
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        trackCf: crossfilter([]),
      });
    }
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const { trackCf, passCf, geom, progressData } = this.state;

    var dashboardTitle = "Brukerdefinerte passeringslinjer";

    if (!trackCf || trackCf.size() === 0) {
      return <Loader progressData={progressData} chartData={trackCf} />;
    }

    // Setup event handler, filter synchronization
    passCf.onChange((ev) => {
      if (ev !== "filtered") return;
      dims.forEach((d: any[]) => {
        if (d[0].hasCurrentFilter()) {
          // d[1].filterAll();
          d[1].filter(d[0].currentFilter());
        } else {
          d[1].filterAll();
        }
      });
    });

    var dims: any[] = [];

    var dimTracks = trackCf.dimension(function (d) {
      return [d3.timeDay(new Date(d.starttime)), d.id];
    }, true);

    var dimCount = passCf.dimension(
      (d) => d.mmsi + "/" + d.callsign + "/" + d.shipname
    );
    var dimCount2 = trackCf.dimension(
      (d) => d.mmsi + "/" + d.callsign + "/" + d.shipname
    );

    dims.push([dimCount, dimCount2]);

    var dimDraft = passCf.dimension((d) => Math.round(d.draught));
    var dimDraft2 = trackCf.dimension((d) => Math.round(d.draught));
    var sailingsByDraft = dimDraft.group().reduceSum((d) => d.crossings);
    dims.push([dimDraft, dimDraft2]);

    var dimLength = passCf.dimension((d) => (d.length ? d.length : -1));
    var dimLength2 = trackCf.dimension((d) => (d.length ? d.length : -1));
    dims.push([dimLength, dimLength2]);

    var dimLengthGroup = passCf.dimension((d) =>
      KYVGroupings.getShipLengthGroup(d.length)
    );
    var sailingsByLength = dimLengthGroup.group().reduceSum((d) => d.crossings);
    var shipLengthLabels = KYVGroupings.getShipLengthLabels();

    var dimLengthGroup2 = trackCf.dimension((d) =>
      KYVGroupings.getShipLengthGroup(d.length)
    );
    dims.push([dimLengthGroup, dimLengthGroup2]);

    var dimShipCategory = passCf.dimension((d) =>
      d.sgroup2 ? d.sgroup2.trim() : "Ukjent"
    );
    var voyagesPerShipCategory = dimShipCategory
      .group()
      .reduceSum((d) => d.crossings);
    var dimShipCategory2 = trackCf.dimension((d) =>
      d.sgroup2 ? d.sgroup2.trim() : "Ukjent"
    );
    dims.push([dimShipCategory, dimShipCategory2]);

    var dimShipType = passCf.dimension((d) =>
      d.shiptypenor2 ? d.shiptypenor2.trim() : "Ukjent"
    );
    var voyagesPerShipType = dimShipType.group().reduceSum((d) => d.crossings);
    var dimShipType2 = trackCf.dimension((d) =>
      d.shiptypenor2 ? d.shiptypenor2.trim() : "Ukjent"
    );
    dims.push([dimShipType, dimShipType2]);

    var dimWeekday = passCf.dimension((d) => {
      let jsdn = new Date(d.endtime).getDay();
      return jsdn > 0 ? jsdn + 1 : 1;
    });
    var voyagesPerWeekday = dimWeekday.group().reduceSum((d) => d.crossings);
    var dimWeekday2 = trackCf.dimension((d) => {
      let jsdn = new Date(d.endtime).getDay();
      return jsdn > 0 ? jsdn + 1 : 1;
    });

    dims.push([dimWeekday, dimWeekday2]);

    var dimMonth = passCf.dimension((d) => new Date(d.endtime).getMonth() + 1);
    var voyagesPerMonth = dimMonth.group().reduceSum((d) => d.crossings);
    var dimMonth2 = trackCf.dimension(
      (d) => new Date(d.endtime).getMonth() + 1
    );
    dims.push([dimMonth, dimMonth2]);

    // Dimension to support table listing
    var dimCallSign = passCf.dimension((d) => d.mmsi);

    var dimCallSign2 = trackCf.dimension((d) => d.mmsi);

    dims.push([dimCallSign, dimCallSign2]);

    return (
      <div className="AppView">
        <AVIDashboard
          title={dashboardTitle}
          desc={
            "Passeringer for skip som har krysset begge passeringslinjer innen samme 24-timersperiode. Alle tidspunkt er angitt i UTC."
          }
          cfilter={trackCf}
          filter={this.props.location.state}
          keyName="crossings"
          units="Passeringer"
          type="sum"
          group={dimCount.groupAll()}
          useFlex
        >
          {/* <GroupedNumberDisplayMax
            height={0.5}
            width={4}
            shipsByDraft={shipsByDraft}
            maxLength={maxLength}
            maxHeight={maxHeight}
            maxBreadth={maxBreadth}
          /> */}
          <AVIRow>
            <TrackMapChart
              chartTitle="Seilaslinjer (viser inntil 5 000)"
              dimension={dimTracks}
              intersectGeom={geom}
              chartData={trackCf}
              colorScale={AVIColorScales.shipCategory}
              categoryProperty="sgroup2"
              maxFeatures={5000}
              height={3}
              width={1}
              useFlex
            >
              <KyvLayerSwitcher top="10px" right="10px" />
            </TrackMapChart>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartOrdinal
                chartTitle="Passeringer etter lengdegruppe"
                dimension={dimLengthGroup}
                group={sailingsByLength}
                ordering={(d) => shipLengthLabels.indexOf(d.key)}
                width={2}
                height={1.5}
                filterPrefix="Lengdegruppe"
                useFlex
              />
            </AVICol>
            <AVICol>
              <BarChartOrdinal
                chartTitle="Passeringer etter draft"
                dimension={dimDraft}
                group={sailingsByDraft}
                height={1.5}
                filterPrefix="Draft"
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <RowChart
                chartTitle="Passeringer etter skipskategori"
                height={1.5}
                // gap={5}
                // fixedBarHeight={20}
                colors={(d) => {
                  return AVIColorScales.shipCategory(d);
                }}
                dimension={dimShipCategory}
                group={voyagesPerShipCategory}
                filterPrefix="Skipskategori"
                useFlex
              />
            </AVICol>
            <AVICol>
              <RowChart
                chartTitle="Passeringer etter skipstype (10 største)"
                description="Filtrer på skipskategori for å se de 10 største skipstypene innen kategorien"
                height={1.5}
                dimension={dimShipType}
                group={GroupUtils.RemoveEmptyBinsTopN(voyagesPerShipType, 10)}
                filterPrefix="Skipstype"
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <AVICol>
              <BarChartOrdinal
                chartTitle="Passeringer etter måned"
                dimension={dimMonth}
                group={voyagesPerMonth}
                height={1.5}
                filterPrefix="Måned"
                xAxisLabel="Måned"
                useFlex
              />
            </AVICol>
            <AVICol>
              <BarChartOrdinal
                chartTitle="Passeringer etter ukedag"
                dimension={dimWeekday}
                group={voyagesPerWeekday}
                height={1.5}
                filterPrefix="Ukedag"
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <DataTable
              chartTitle="Passeringer for skip på dager hvor skipene har krysset begge passeringslinjer"
              dimension={dimCallSign}
              useFlex
              sortBy={(d) =>
                d.mmsi +
                "-" +
                TimeUtils.toTimestamp(d.starttime) +
                "-" +
                TimeUtils.toTimestamp(d.endtime)
              }
              width={4}
              size={Infinity}
              columns={[
                {
                  label: "MMSI#",
                  format: (d) => d.mmsi || 0,
                },
                {
                  label: "Kallesignal",
                  format: (d) => d.callsign || "",
                },
                {
                  label: "Skipsnavn",
                  format: (d) => d.shipname || "",
                },
                {
                  label: "Tidspunkt (UTC)",
                  format: (d) => TimeUtils.toCompactDate(new Date(d.starttime)),
                },
                {
                  label: "Skipstype",
                  format: (d) => d.shiptypenor || "",
                },
                {
                  label: "BT",
                  title: "Bruttotonnasje",
                  format: (d) => NumUtils.integer(d.grosstonnage),
                  value: (d) => d.grosstonnage,
                },
                {
                  label: "DWT",
                  title: "Dødvektstonnasje",
                  format: (d) => NumUtils.integer(d.dwt),
                  value: (d) => d.dwt,
                },
                {
                  label: "L",
                  title: "LOA (Length Over All)",
                  format: (d) => NumUtils.decimal2(0 + d.length),
                  value: (d) => +d.length,
                },
                {
                  label: "B",
                  title: "Skipsbredde",
                  format: (d) => NumUtils.decimal2(d.breadth),
                  value: (d) => d.breadth,
                },
                {
                  label: "H",
                  title: "Skipshøyde (air draught)",
                  format: (d) => NumUtils.decimal2(d.height),
                  value: (d) => d.height,
                },
                {
                  label: "D",
                  title: "Dyptgående",
                  format: (d) => NumUtils.decimal2(d.draught),
                  value: (d) => d.draught,
                },
                {
                  label: "Land",
                  format: (d) => d.countrynamenor || "",
                },
              ]}
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdPasslineMulti = withRouter(DbrdPasslineMultiBase);

export default DbrdPasslineMulti;
