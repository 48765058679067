import { DbrdAnchorage } from "../views/DbrdAnchorage/DbrdAnchorage";
import { DbrdAreaTraffic } from "../views/DbrdAreaTraffic/DbrdAreaTraffic";
import { DbrdArrivals } from "../views/DbrdArrivals/DbrdArrivals";
import { DbrdArrivalsDepartures } from "../views/DbrdArrivalsDepartures/DbrdArrivalsDepartures";
import { DbrdDepartures } from "../views/DbrdDepartures/DbrdDepartures";
import { DbrdDownloadVoyages } from "../views/DbrdDownloadVoyages/DbrdDownloadVoyages";
import { DbrdFollow } from "../views/DbrdFollow/DbrdFollow";
import { DbrdNorVtsIncidents } from "../views/DbrdNorVtsIncidents/DbrdNorVtsIncidents";
import { DbrdNorVtsIncidentsPublic } from "../views/DbrdNorVtsIncidentsPublic/DbrdNorVtsIncidentsPublic";
// import { DbrdPassline } from "../views/DbrdPassline/DbrdPassline";
import React from "react";
import { DbrdAnalytics } from "../views/DbrdAnalytics/DbrdAnalytics";
import DbrdAnalyticsWebservices from "../views/DbrdAnalyticsWebServices/DbrdAnalyticsWebServices";
import DbrdCruiseDetailed from "../views/DbrdCruiseDetailed/DbrdCruiseDetailed";
import DbrdDangerousGoods from "../views/DbrdDangerousGoods/DbrdDangerousGoods";
import DbrdPassengers from "../views/DbrdPassengers/DbrdPassengers";
import { DbrdPasslineFreq } from "../views/DbrdPasslineFreq/DbrdPasslineFreq";
import { DbrdPasslineFreqWeekly } from "../views/DbrdPasslineFreqWeekly/DbrdPasslineFreqWeekly";
import { DbrdPasslineMulti } from "../views/DbrdPasslineMulti/DbrdPasslineMulti";
import { DbrdPilotOffice } from "../views/DbrdPilotOffice/DbrdPilotOffice";
import { DbrdPilotage } from "../views/DbrdPilotage/DbrdPilotage";
import { DbrdReplay } from "../views/DbrdReplay/DbrdReplay";
import { DbrdReplayRealtime } from "../views/DbrdReplayRealtime/DbrdReplayRealtime";
import { DbrdSailedDistanceFvpOmr } from "../views/DbrdSailedDistanceFvpOmr/DbrdSailedDistanceFvpOmr";
import DbrdSailedDistanceGrunnlinje from "../views/DbrdSailedDistanceGrunnlinje/DbrdSailedDistanceGrunnlinje";
import { DbrdSailedDistanceMunicipality } from "../views/DbrdSailedDistanceMunicipality/DbrdSailedDistanceMunicipality";
import { DbrdSailedDistanceMunicipalityFairway } from "../views/DbrdSailedDistanceMunicipalityFairway/DbrdSailedDistanceMunicipalityFairway";
import { DbrdSailedDistanceSvoOmr } from "../views/DbrdSailedDistanceSvoOmr/DbrdSailedDistanceSvoOmr";
import { DbrdSailedDistanceTime } from "../views/DbrdSailedDistanceTime/DbrdSailedDistanceTime";
import { DbrdShip } from "../views/DbrdShip/DbrdShip";
import { DbrdShipVoyages } from "../views/DbrdShipVoyages/DbrdShipVoyages";
import { DbrdShipsByYearOfBuild } from "../views/DbrdShipsByYearOfBuild/DbrdShipsByYearOfBuild";
import { DbrdSpeed } from "../views/DbrdSpeed/DbrdSpeed";
import { DbrdTest } from "../views/DbrdTest/DbrdTest";
import { DbrdTimeInPort } from "../views/DbrdTimeInPort/DbrdTimeInPort";
import { DbrdTimeInPortAllLocations } from "../views/DbrdTimeInPortAllLocations/DbrdTimeInPortAllLocations";
import { DbrdTimeInPortOSPS } from "../views/DbrdTimeInPortOSPS/DbrdTimeInPortOSPS";
import { DbrdToFromCountries } from "../views/DbrdToFromCountries/DbrdToFromCountries";
import { DbrdVoyagesAgg } from "../views/DbrdVoyagesAgg/DbrdVoyagesAgg";
import { DbrdVoyagesAggDay } from "../views/DbrdVoyagesAggDay/DbrdVoyagesAggDay";
import { DbrdVoyagesAggDeparturesDay } from "../views/DbrdVoyagesAggDeparturesDay/DbrdVoyagesAggDeparturesDay";

import { DbrdVoyagesDensity } from "../views/DbrdVoyagesDensity/DbrdVoyagesDensity";
import { DbrdPassline } from "../views/DbrdPassline/DbrdPassline";
import { DbrdReplayRealtime3D } from "../views/DbrdReplayRealtime3D/DbrdReplayRealtime3D";
import { DbrdCruise } from "../views/DbrdCruise/DbrdCruise";
import DbrdVoyagesAggV2 from "../views/DbrdVoyagesAggV2/DbrdVoyagesAggV2";
import DbrdWaveImpact from "../views/DbrdWaveImpact/DbrdWaveImpact";

export const DashboardList: { [key: string]: [any, any] } = {
  ankomster: [DbrdArrivals, <DbrdArrivals />],
  avganger: [DbrdDepartures, <DbrdDepartures />],
  losoldermannskap: [DbrdPilotOffice, <DbrdPilotOffice />],
  lospliktigeseilas: [DbrdPilotage, <DbrdPilotage />],
  passeringslinjer: [DbrdPassline, null],
  hastighet: [DbrdSpeed, <DbrdSpeed />],
  ship: [DbrdShip, <DbrdShip />],
  sporskip: [DbrdFollow, <DbrdFollow />],
  sporing: [DbrdReplay, <DbrdReplay />],
  "sporing-sanntid": [DbrdReplayRealtime, <DbrdReplayRealtime />],
  trafikkiomrade: [DbrdAreaTraffic, <DbrdAreaTraffic />],
  seilas: [DbrdVoyagesAgg, <DbrdVoyagesAgg />],
  "seilas-v2": [DbrdVoyagesAggV2, <DbrdVoyagesAggV2 />],
  "seilas-per-dag": [DbrdVoyagesAggDay, <DbrdVoyagesAggDay />],
  "ankomster-per-dag": [DbrdVoyagesAggDay, <DbrdVoyagesAggDay />],
  "avganger-per-dag": [
    DbrdVoyagesAggDeparturesDay,
    <DbrdVoyagesAggDeparturesDay />,
  ],
  cruise: [DbrdCruise, null],
  "last-ned-seilas": [DbrdDownloadVoyages, <DbrdDownloadVoyages />],
  "passeringslinjer-frekvens": [DbrdPasslineFreq, <DbrdPasslineFreq />],
  "passeringslinjer-multi": [DbrdPasslineMulti, <DbrdPasslineMulti />],
  "passeringslinjer-frekvens-ukentlig": [
    DbrdPasslineFreqWeekly,
    <DbrdPasslineFreqWeekly />,
  ],
  "tid-i-havn": [DbrdTimeInPort, <DbrdTimeInPort />],
  "tid-i-havn-alle-lokasjoner": [
    DbrdTimeInPortAllLocations,
    <DbrdTimeInPortAllLocations />,
  ],
  "tid-i-havn-osps": [DbrdTimeInPortOSPS, <DbrdTimeInPortOSPS />],
  test: [DbrdTest, <DbrdTest />],
  "ankomster-avganger": [DbrdArrivalsDepartures, <DbrdArrivalsDepartures />],
  "seilas-for-skip": [DbrdShipVoyages, <DbrdShipVoyages />],
  "norvts-incidents": [DbrdNorVtsIncidents, <DbrdNorVtsIncidents />],
  "norvts-incidents-public": [
    DbrdNorVtsIncidentsPublic,
    <DbrdNorVtsIncidentsPublic />,
  ],
  "sporing-sanntid-3d": [DbrdReplayRealtime3D, null],
  anchorage: [DbrdAnchorage, <DbrdAnchorage />],
  "sailed-distance-municipality": [
    DbrdSailedDistanceMunicipality,
    <DbrdSailedDistanceMunicipality />,
  ],
  "sailed-distance-svoomr": [
    DbrdSailedDistanceSvoOmr,
    <DbrdSailedDistanceSvoOmr />,
  ],
  "sailed-distance-fvpomr": [
    DbrdSailedDistanceFvpOmr,
    <DbrdSailedDistanceFvpOmr />,
  ],
  "sailed-distance-time": [DbrdSailedDistanceTime, <DbrdSailedDistanceTime />],
  "sailed-distance-municipality-fairway": [
    DbrdSailedDistanceMunicipalityFairway,
    <DbrdSailedDistanceMunicipalityFairway />,
  ],
  "sailed-distance-grunnlinje": [
    DbrdSailedDistanceGrunnlinje,
    <DbrdSailedDistanceGrunnlinje />,
  ],
  "arrivals-density": [DbrdVoyagesDensity, <DbrdVoyagesDensity />],
  analytics: [DbrdAnalytics, <DbrdAnalytics />],
  "analytics-webservices": [
    DbrdAnalyticsWebservices,
    <DbrdAnalyticsWebservices />,
  ],
  "to-from-countries": [DbrdToFromCountries, <DbrdToFromCountries />],
  "ships-by-yearofbuild": [DbrdShipsByYearOfBuild, <DbrdShipsByYearOfBuild />],
  "cruise-detailed": [DbrdCruiseDetailed, <DbrdCruiseDetailed />],
  "dangerous-goods": [DbrdDangerousGoods, <DbrdDangerousGoods />],
  passengers: [DbrdPassengers, <DbrdPassengers />],
  "wave-impact": [DbrdWaveImpact, <DbrdWaveImpact />],
};

export default DashboardList;
