import "./DbrdVoyagesAggDay.scss";

import * as d3 from "d3";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { PivotTable } from "../../chart-components/ChartsExt/PivotTable";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { FilterToFromTime2 } from "../../filters/FilterToFromTime2";
import { FilterToFromYear } from "../../filters/FilterToFromYear";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";
import { GroupUtils } from "./../../chart-components/ChartUtils/GroupUtils";
import { NumUtils } from "./../../chart-components/ChartUtils/NumUtils";
import { TextUtils } from "./../../chart-components/ChartUtils/TextUtils";
import { TimeUtils } from "./../../chart-components/ChartUtils/TimeUtils";
import { BarChartOrdinal } from "./../../chart-components/Charts/BarChartOrdinal";
import { BarChartStacked } from "./../../chart-components/Charts/BarChartStacked";
import { DataTable } from "./../../chart-components/Charts/DataTable";
import { NumberDisplayDim } from "./../../chart-components/Charts/NumberDisplayDim";
import { PieChart } from "./../../chart-components/Charts/PieChart";
import { RowChart } from "./../../chart-components/Charts/RowChart";
import { SeriesChart } from "./../../chart-components/Charts/SeriesChart";
import { AVIDataCountTileFlex } from "./../../chart-components/Layout/AVIDataCountTileFlex";

class DbrdVoyagesAggDayBase extends Component {
  /**
   * The route to be used for the dashboard
   */
  static dashboardRoute = "seilas-per-dag";

  /**
   * The help text and filter controls to be used as initial filtering for the dashboard
   * @param {*} filter
   * @param {*} setFilter
   */
  static dashboardFilters(filter, setFilter) {
    return {
      helpMessage:
        "Velg et eller flere år og en tidsperiode som skal sammenlignes innen hvert av årene. Utvalget inkluderer begge valgte år og alle år mellom. Det hentes ut data for de valgte datoene i hvert av årene. Dette dashboardet gjør det mulig å vise data for inntil 36 måneder om gangen. Kan ta lang tid å laste/generere",
      controls: [
        <div key="flt_year_time_container">
          <FilterToFromYear
            key="flt_year"
            firstYear={2008}
            filter={filter}
            setFilter={setFilter}
          />
          <FilterToFromTime2
            key="flt_time"
            filter={filter}
            setFilter={setFilter}
            format="dd/MM"
            maxTime={TimeUtils.getDateDiff(-1)}
          />
        </div>,
      ],
    };
  }

  /**
   * The dashboard settings, i.e. map interaction filter controls, selectable layers etc.
   */
  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: null,
    };
  }

  /**
   * The dashboard validation function
   * @param {*} filter
   */
  static dashboardValidation(filter) {
    if (filter.fromYear && filter.toYear && filter.fromTime && filter.toTime) {
      return true;
    } else {
      return false;
    }
  }

  static propTypes = {
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.reportProgress = this.reportProgress.bind(this);
    this.reportError = this.reportError.bind(this);
    this.state = {
      chartData: null,
      locationData: [],
    };
  }

  defaultParams = {
    fromYear: new Date().getFullYear(),
    toYear: new Date().getFullYear(),
    fromTime: new Date(2020, 1, 18),
    toTime: new Date(2020, 2, 18),
  };

  componentDidMount() {
    try {
      const { fromYear, toYear, fromTime, toTime } =
        this.props.location.state || this.defaultParams;

      if (fromYear && toYear && fromTime && toTime) {
        this.defaultParams.fromYear = fromYear;
        this.defaultParams.toYear = toYear;
        this.defaultParams.fromMonth = new Date(fromTime).getMonth() + 1;
        this.defaultParams.toMonth = new Date(toTime).getMonth() + 1;
        this.defaultParams.fromDay = new Date(fromTime).getDate();
        this.defaultParams.toDay = new Date(toTime).getDate();

        DataLoader.postApi(
          "/api/voyage/aggregated/arrivals-by-day",
          {
            FromYear: fromYear,
            ToYear: toYear,
            FromMonth: this.defaultParams.fromMonth,
            FromDay: this.defaultParams.fromDay,
            ToMonth: this.defaultParams.toMonth,
            ToDay: this.defaultParams.toDay,
          },
          this.reportProgress,
          this.reportError
        )
          .then((res) => {
            // Check that all responses are successful
            if (!res.success) {
              throw new Error("Error loading aggregated arrivals by day data");
            }
            let idx = 1;
            let foundNull = false;
            // Clean data object, add index, remove nulls
            res.data.forEach((row) => {
              row.index = idx;
              for (let prop in row) {
                let defaultValue = "";
                switch (prop) {
                  case "mh":
                  case "ml":
                  case "mb":
                  case "md":
                  case "mw":
                  case "mt":
                    defaultValue = -99;
                    break;
                  case "dlc":
                  case "d0":
                  case "d1":
                  case "d2":
                  case "alc":
                  case "a0":
                  case "a1":
                  case "a2":
                    defaultValue = "(Ikke oppgitt)";
                    break;
                  case "hm":
                    defaultValue = "u";
                    break;
                  case "dwd":
                    defaultValue = row["awd"];
                    break;
                  case "awd":
                    defaultValue = row["dwd"];
                    break;
                  default:
                    defaultValue = "";
                }

                if (!row[prop]) {
                  row[prop] = defaultValue;
                }

                if (
                  (row[prop] === undefined || row[prop] === null) &&
                  !foundNull
                ) {
                  console.warn(
                    "Found null value for a record for property: " +
                      prop +
                      " at index: " +
                      idx
                  );
                  foundNull = true;
                  return;
                }
              }
              idx++;
            });

            this.setState({
              chartData: crossfilter(res.data),
            });
          })
          .catch((error) => {
            console.warn(error);
            this.setState({
              chartData: crossfilter([]),
            });
          });
      } else {
        throw new Error("Missing dashboard selection parameters");
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  reportError(errorData) {
    this.setState({
      errorData: errorData,
    });
  }

  render() {
    const { chartData, progressData } = this.state;

    const { fromYear, toYear, fromMonth, fromDay, toMonth, toDay } =
      this.defaultParams;

    if (!chartData || chartData.size() === 0) {
      return <Loader progressData={progressData} chartData={chartData} />;
    }

    var yearRange = [];
    for (var currentYear = fromYear; currentYear <= toYear; currentYear++) {
      yearRange.push(currentYear);
    }

    var dimCount = chartData.dimension((d) => {
      return d.index;
    });

    var dimArrivalLocations = chartData.dimension((d) => d.al);

    var dimLength = chartData.dimension((d) => d.ml);
    var dimBreadth = chartData.dimension((d) => d.mb);
    var dimHeight = chartData.dimension((d) => d.mh);
    var dimDraught = chartData.dimension((d) => d.md);
    var dimGrossTonange = chartData.dimension((d) => d.mt);

    var dimArrivalYear = chartData.dimension((d) => d.ay);
    var dimArrivalWeek = chartData.dimension((d) =>
      TimeUtils.getWeek(new Date(d.ay, d.am - 1, d.ad))
    );

    var dimArrivalWeekDay = chartData.dimension((d) => {
      // console.log(new Date(d.ay, d.am - 1, d.ad).getDay(), d.dwd, d.awd);
      return TimeUtils.getWeekDay(
        new Date(d.ay, d.am - 1, d.ad).getDay(),
        true
      );
    });

    var dimDepartureCountry = chartData.dimension((d) => d.d0);

    var dimLocationType = chartData.dimension((d) => {
      switch (d.alt) {
        case "Havn":
        case "Kai":
        case "Havneanlegg":
          return "Havn";
        case "Ankerplass":
          return "Ankerplass";
        case "Losbordingspunkt":
          return "Losbordingspunkt";
        case "Sted i sjøen":
        case "Offshoreinstallasjon":
          return "Andre i sjø";
        case "Land":
        default:
          return "Andre";
      }
    });
    var arrivalsByLocationType = dimLocationType.group().reduceSum((d) => d.vs);

    var dimFlagState = chartData.dimension((d) => d.sf);
    var arrivalsByFlagState = dimFlagState.group().reduceSum((d) => d.vs);

    var dimDomesticInternational = chartData.dimension((d) => {
      if (d.d0 === "NORGE") {
        return "NORGE";
      } else if (d.d0 === "SVALBARD OG JAN MAYEN") {
        return "SVALBARD OG JAN MAYEN";
      } else if (d.d0 === "INTERNASJONALT FARVANN") {
        return "INTERNASJONALT FARVANN";
      } else {
        return "UTLAND";
      }
    });

    var arrivalsByDomesticInternational = dimDomesticInternational
      .group()
      .reduceSum((d) => d.vs);

    var dimArrivalCounty = chartData.dimension((d) => d.a1);
    var dimArrivalCountyYear = chartData.dimension((d) => [d.a1, d.ay]);
    var arrivalsByCountyYear = dimArrivalCountyYear
      .group()
      .reduceSum((d) => d.vs);

    var dimArrivalsByDay = chartData.dimension((d) => [
      d.ay,
      new Date(toYear, d.am - 1, d.ad),
    ]);
    var arrivalsByDay = dimArrivalsByDay.group().reduceSum((d) => d.vs);

    var dimDayInYear = chartData.dimension(
      (d) => new Date(2020, d.am - 1, d.ad)
    );

    var dimArrivalMunicipality = chartData.dimension((d) => d.a2);
    var arrivalsByMunicipality = dimArrivalMunicipality
      .group()
      .reduceSum((d) => d.vs);

    var arrivalsByArrivalLocation = dimArrivalLocations
      .group()
      .reduceSum((d) => d.vs);
    var arrivalsByDepartureCountry = dimDepartureCountry
      .group()
      .reduceSum((d) => d.vs);

    var dimLengthGroup = chartData.dimension((d) => d.lg);
    var arrivalsByLengthGroup = dimLengthGroup.group().reduceSum((d) => d.vs);

    var dimTonnageGroup = chartData.dimension((d) => d.tg);
    var arrivalsByTonnageGroup = dimTonnageGroup.group().reduceSum((d) => d.vs);

    var dimHazMat = chartData.dimension((d) =>
      d.hm === true ? "Farlig last" : "Ordinær last"
    );
    var arrivalsByHazmat = dimHazMat.group().reduceSum((d) => d.vs);

    var dimShipCategory = chartData.dimension((d) => d.sg2);
    var arrivalsByShipCategory = dimShipCategory.group().reduceSum((d) => d.vs);

    var dimShipType = chartData.dimension((d) => d.st);
    var arrivalsByShipType = dimShipType.group().reduceSum((d) => d.vs);

    var dimTable = chartData.dimension((d) => [
      d.al,
      d.a2,
      d.a1,
      new Date(d.ay, d.am - 1, d.ad),
      d.sg2,
      d.st,
      d.mmsi,
      d.imo,
      d.sn,
    ]);
    var arrivalsByTable = GroupUtils.RemoveEmptyBins(
      dimTable.group().reduceSum((d) => d.vs)
    );

    return (
      <div className="AppView-noMap">
        <AVIDashboard
          title={
            "Havneinformasjon: meldepliktige ankomster fra SafeSeaNet per dag"
          }
          desc={`Aggregert statistikk over meldepliktige ankomster til lokasjoner i Norge per dag i tidsrommet ${fromDay}.${fromMonth}.${fromYear} til ${toDay}.${toMonth}.${toYear} som registrerte i SafeSeaNet. Omfatter ikke seilas som er untatt fra meldeplikt slik som innenriks rutegående bil- og persontransport.`}
          spacing={10}
          keyName="vs"
          type="sum"
          units="ankomster"
          group={dimCount.groupAll()}
          useFlex
        >
          <AVIRow>
            <AVIDataCountTileFlex
              height={0.5}
              width={4}
              title="Nøkkeltall for ankomster"
            >
              <NumberDisplayDim
                chartTitle="Antall lokasjoner"
                height={0.25}
                width={0.5}
                keyName="count"
                type="count"
                dimension={dimArrivalLocations}
                units="havner"
              />
              <NumberDisplayDim
                chartTitle="Største BT"
                height={0.25}
                width={0.5}
                keyName="mt"
                dimension={dimGrossTonange}
                units="tonn"
              />
              <NumberDisplayDim
                chartTitle="Største lengde"
                height={0.25}
                width={0.5}
                keyName="ml"
                dimension={dimLength}
                units="meter"
              />
              <NumberDisplayDim
                chartTitle="Største bredde"
                height={0.25}
                width={0.5}
                keyName="mb"
                dimension={dimBreadth}
                units="meter"
              />
              <NumberDisplayDim
                chartTitle="Største høyde"
                height={0.25}
                width={0.5}
                keyName="mh"
                dimension={dimHeight}
                units="meter"
              />
              <NumberDisplayDim
                chartTitle="Største dyptgående"
                height={0.25}
                width={0.5}
                keyName="md"
                dimension={dimDraught}
                units="meter"
              />
            </AVIDataCountTileFlex>
          </AVIRow>
          <AVIRow>
            <PivotTable
              chartTitle="Ankomster per år etter fylke"
              height={2}
              useFlex
              group={arrivalsByCountyYear}
              dimension={dimArrivalCounty}
              rowLabel="Fylke"
              colLabel="År"
              rowKeyIndex={0}
              colKeyIndex={1}
              filterPrefix="Fylke"
            />
          </AVIRow>
          <AVIRow>
            <SeriesChart
              chartTitle="Ankomster etter dag"
              dimension={dimDayInYear}
              group={arrivalsByDay}
              useFlex
              height={2}
              brushOn
              yAxisLabel="Ankomster"
              xAxisLabel="Måned og dag"
              filterPrefix="Tidsrom"
              rotateXAxisLabels={-45}
              xAxisPadding={1}
              x={d3.scaleTime()}
              title={(d) =>
                TimeUtils.formatDateInYear(d.key[1]) +
                "." +
                d.key[0] +
                ": " +
                d.value
              }
            />
          </AVIRow>
          <AVIRow>
            <RowChart
              chartTitle="Ankomster etter skipskategori"
              useFlex
              height={1.5}
              group={arrivalsByShipCategory}
              dimension={dimShipCategory}
              filterPrefix="Skipskategori"
            />
            <RowChart
              chartTitle="Ankomster etter skipstype (10 største)"
              description="Filtrer på skipskategori for å se de 10 største skipstypene innen kategorien"
              useFlex
              height={1.5}
              group={GroupUtils.RemoveEmptyBinsTopN(arrivalsByShipType, 10)}
              dimension={dimShipType}
              filterPrefix="Skipstype"
            />
          </AVIRow>
          <AVIRow>
            <BarChartStacked
              chartTitle="Ankomster etter skipskategori og år"
              dimension={dimArrivalYear}
              xAxisTickValues={yearRange}
              useFlex
              height={1.5}
              gap={2}
              filterPrefix="Ankomstår"
              stackKey="sg2"
              valueKey="vs"
              renderLabel
            />
            <BarChartOrdinal
              group={arrivalsByDomesticInternational}
              dimension={dimDomesticInternational}
              chartTitle="Ankomster fra innland/utland"
              margins={{ left: 55, bottom: 100 }}
              useFlex
              height={1.5}
              ordering={(d) => -d.value}
              yAxisLabel="Antall ankomster"
              xAxisLabel="Innland/utland"
              filterPrefix="Innland/utland"
              rotateXAxisLabels={-45}
            />
            <BarChartOrdinal
              group={arrivalsByLocationType}
              dimension={dimLocationType}
              chartTitle="Ankomster etter lokasjonstype"
              margins={{ left: 55, bottom: 100 }}
              useFlex
              height={1.5}
              ordering={(d) => -d.value}
              yAxisLabel="Antall ankomster"
              xAxisLabel="Lokasjonstype"
              filterPrefix="Lokasjonstype"
              rotateXAxisLabels={-45}
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(arrivalsByMunicipality, 40)}
              dimension={dimArrivalMunicipality}
              chartTitle="Ankomster etter kommune (40 mest trafikkerte)"
              margins={{ left: 55, bottom: 100 }}
              useFlex
              height={1.5}
              ordering={(d) => -d.value}
              yAxisLabel="Antall ankomster"
              xAxisLabel="Ankomstkommune"
              filterPrefix="Ankomstkommune"
              rotateXAxisLabels={-45}
            />
          </AVIRow>
          <AVIRow>
            <BarChartStacked
              chartTitle="Ankomster etter skipskategori og uke i året"
              dimension={dimArrivalWeek}
              // xAxisTickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
              xAxisLabel="Uke i året"
              yAxisLabel="Ankomster"
              useFlex
              height={1.5}
              gap={2}
              filterPrefix="Ankomstmåned"
              stackKey="sg2"
              valueKey="vs"
              renderLabel
            />
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(
                arrivalsByArrivalLocation,
                15
              )}
              dimension={dimArrivalLocations}
              chartTitle="Ankomster etter ankomststed (15 mest trafikkerte)"
              margins={{ left: 55, bottom: 50 }}
              useFlex
              height={1.5}
              ordering={(d) => -d.value}
              yAxisLabel="Antall ankomster"
              xAxisLabel="Ankomststed"
              filterPrefix="Ankomststed"
              rotateXAxisLabels={-15}
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(
                arrivalsByDepartureCountry,
                40
              )}
              dimension={dimDepartureCountry}
              chartTitle="Land forrige lokasjon (40 største trafikkilder)"
              useFlex
              height={1.5}
              margins={{ left: 55, bottom: 50 }}
              ordering={(d) => -d.value}
              yAxisLabel="Antall ankomster"
              xAxisLabel="Opphavsland"
              filterPrefix="Opphavsland"
              rotateXAxisLabels={-15}
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(arrivalsByFlagState, 40)}
              dimension={dimFlagState}
              chartTitle="Ankomststed etter flaggstat (40 største)"
              useFlex
              height={1.5}
              margins={{ left: 55, bottom: 50 }}
              ordering={(d) => -d.value}
              yAxisLabel="Antall ankomster"
              xAxisLabel="Flaggstat"
              filterPrefix="Flaggstat"
              rotateXAxisLabels={-15}
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              chartTitle="Ankomster med ordinær/farlig last"
              useFlex
              height={1.5}
              group={arrivalsByHazmat}
              dimension={dimHazMat}
              margins={{ left: 55, bottom: 50 }}
              ordering={(d) => -d.value}
              yAxisLabel="Antall ankomster"
              xAxisLabel="Godstype"
              filterPrefix="Godstype"
              rotateXAxisLabels={-15}
            />
            <BarChartStacked
              chartTitle="Ankomster etter ukedag"
              useFlex
              height={1.5}
              dimension={dimArrivalWeekDay}
              margins={{ left: 55, bottom: 50, top: 50 }}
              ordering={(d) => TimeUtils.weekDays.indexOf(d.key)}
              yAxisLabel="Antall ankomster"
              xAxisLabel="Ukedag"
              filterPrefix="Ukedag"
              xAxisTickFormat={(d) => {
                return d;
              }}
              gap={2}
              stackKey="sg2"
              valueKey="vs"
              renderLabel
            />
          </AVIRow>
          <AVIRow>
            <PieChart
              chartTitle="Ankomster per lengdegruppe"
              useFlex
              height={2}
              margins={{ top: 20 }}
              innerRadius={50}
              dimension={dimLengthGroup}
              group={arrivalsByLengthGroup}
              minAngleForLabel={0.15}
              filterPrefix="Lengdegruppe"
              ordering={TextUtils.firstNumberInString}
            />
            <PieChart
              chartTitle="Ankomster per bruttotonnasjegruppe (BT)"
              useFlex
              height={2}
              margins={{ top: 20 }}
              innerRadius={50}
              dimension={dimTonnageGroup}
              group={arrivalsByTonnageGroup}
              minAngleForLabel={0.15}
              filterPrefix="Bruttotonnasjegruppe"
              ordering={TextUtils.firstNumberInString}
            />
          </AVIRow>
          <AVIRow>
            <DataTable
              chartTitle="Liste over ankomster"
              dimension={arrivalsByTable}
              sortBy={(d) =>
                `${d.key[0]}-${NumUtils.pad(d.key[3], 4)}-${NumUtils.pad(
                  d.key[4],
                  2
                )}`
              }
              order={d3.ascending}
              size={Infinity}
              showSortControls={false}
              columns={[
                {
                  label: "Dato",
                  title: "Ankomstår",
                  format: (d) => TimeUtils.formatDate(d.key[3]),
                },
                {
                  label: "Ukedag",
                  title: "Ukedag",
                  format: (d) => TimeUtils.getWeekDay(d.key[3].getDay(), true),
                },
                {
                  label: "Lokasjon",
                  title: "Lokasjonsnavn",
                  format: (d) => d.key[0],
                },
                {
                  label: "Kommune",
                  title: "Navn på kommune",
                  format: (d) => d.key[1],
                },
                {
                  label: "Fylke",
                  title: "Navn på fylke",
                  format: (d) => d.key[2],
                },
                {
                  label: "Skipskategori",
                  title: "Skipskategori",
                  format: (d) => d.key[4],
                },
                {
                  label: "Skipstype",
                  title: "Skipstype (Statcode5)",
                  format: (d) => d.key[5],
                },
                {
                  label: "MMSI",
                  title: "MMSI",
                  format: (d) => d.key[6],
                },
                {
                  label: "IMO",
                  title: "IMO",
                  format: (d) => d.key[7],
                },
                {
                  label: "Skipsnavn",
                  title: "Skipsnavn",
                  format: (d) => d.key[8],
                },
                {
                  label: "Ankomster",
                  title: "Antall ankomster",
                  format: (d) => NumUtils.integer(d.value),
                  value: (d) => d.value,
                },
              ]}
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdVoyagesAggDay = withRouter(DbrdVoyagesAggDayBase);

export default DbrdVoyagesAggDay;
