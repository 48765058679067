export const DashboardConfig = {
  dvhLoginUrl: 'https://kystdatahuset.no',
  wsUrl: process.env.REACT_APP_DASHBOARD_WS_API,
  adaptiveUrl: process.env.REACT_APP_ADAPTIVE_INSTANCE,
  projCode: '3857',
  articlesDt: {
    uuid: '81cbf677-68e2-4f3a-83f1-60c1f53f259b',
    columns: ['id', 'title', 'content', 'media']
  },
  dashboardsDt: {
    uuid: 'c77aaef4-3c2f-4057-a5ef-269ad43aeee7',
    columns: ['id', 'title', 'content', 'media', 'uri']
  },
  municipalitiesNoGeomTheme: {
    // uuid: '674e2da6-32d3-470a-937a-23a8698f830a',
    uuid: 'a4b7ab02-9f80-41be-9fd2-ab3779d956b5',
    columns: ['navn', 'komm']
  },
  municipalityGeomTheme: {
    // uuid: '674e2da6-32d3-470a-937a-23a8698f830a',
    // uuid: '90f58a5e-bac5-4795-8e5f-35cf92e63dce',
    // uuid: '90f58a5e-bac5-4795-8e5f-asdasfas',
    uuid: '0177ff44-19b4-479f-900f-8fee88b9a2fb',
    // columns: ['navn']
    columns: ['navn', 'kommunenummer', 'geom_wkt']
  },
  passlinesDt: {
    uuid: 'cc3a9a5c-bcf9-493c-8284-19ce65ced62e',
    columns: ['id', 'geom', 'name', 'visibility']
  },
  kyvDashboardComments: {
    uuid: 'cddbed79-c977-4f4e-bf5d-eae8672ef4e8',
    columns: ['id', 'dashboard', 'title', 'email', 'comment', 'date_created']
  },
  filterControls: {
    drawPoint: 'drawPoint',
    drawLine: 'drawLineString',
    drawPolygon: 'drawPolygon',
    drawBox: 'drawBox',
    selectPoint: 'selectPoint',
    selectPolygon: 'selectPolygon',
    selectBox: 'selectBox',
    municipality: 'municipality',
    passline: 'passline'
    // fromYear: 'fromYear',
    // toYear: 'toYear',
    // fromMonth: 'fromMonth',
    // toMonth: 'toMonth',
    // fromTime: 'fromTime',
    // toTime: 'toTime',
    // plusMinusHours: 'plusMinusHours',
    // passingLine: 'PassingLine',
    // mmsi: 'mmsi',
    // shipIds: 'shipIds',
    // shipIdsAIS: 'shipIdsAIS'
  },
  // filterContainer: [
  //   { title: 'Passeringlinjer', uri: 'passingline' },
  //   { title: 'Trafikk i område', uri: 'areatraffic' },
  //   { title: 'Ankomster', uri: 'arrivals' },
  //   { title: 'Avganger', uri: 'departures' },
  //   { title: 'Losoldermannskap', uri: 'pilotoffice' },
  //   { title: 'Lospliktige seilas', uri: 'pilotage' },
  //   { title: 'Hastighet', uri: 'speed' },
  //   { title: 'Sporing', uri: 'replay' }
  // ],
  // mapInteractions: {
  //   selectPolygon: 'SelectPolygon',
  //   selectPoint: 'SelectPoint',
  //   lineString: 'LineString',
  //   polygon: 'Polygon',
  //   drawBox: 'DrawBox'
  // },
  layers: {
    ports: {
      style: {
        fillColor: '#3399CC',
        fillOpacity: 90,
        strokeWidth: 2,
        strokeColor: '#ffffff',
        strokeOpacity: 90,
        modifyStrokeWidth: 20,
        zIndex: 10,
        activeColor: '#ff0000',
        activeOpacity: 90,
        hoverColor: '#ffff00'
      }
    },
    passlines: {
      style_public: {
        fillColor: '#00008B',
        strokeWidth: 2,
        strokeColor: '#00008B',
        zIndex: 10,
        activeColor: '#FFA500',
        activeOpacity: 90,
        hoverColor: '#FFA500',
        hoverOpacity: 90
      },
      style_private: {
        fillColor: '#00008B',
        strokeWidth: 2,
        strokeColor: '#ff0000',
        zIndex: 10,
        activeColor: '#FFA500',
        activeOpacity: 90,
        hoverColor: '#FFA500',
        hoverOpacity: 90
      }
    },
    municipality: {
      style: {
        fillColor: '#3399CC',
        strokeWidth: 2,
        fillOpacity: 50,
        strokeColor: '#3399CC',
        strokeOpacity: 90,
        zIndex: -10,
        hoverColor: '#ff0000',
        activeColor: '#00ff00'
      }
    },
    draw: {
      style: {
        fillColor: '#3399CC',
        fillOpacity: 50,
        strokeWidth: 3,
        strokeColor: '#ff0000',
        strokeOpacity: 90,
        modifyStrokeColor: '#3399CC',
        modifyFillColor: '#3399CC',
        modifyFillOpacity: 90,
        zIndex: -10
      }
    }
  }
};

window.kyvDashboardConfig = DashboardConfig;

export default window.kyvDashboardConfig;
