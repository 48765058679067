import React, { Component } from "react";

import Dropdown from "../filter-components/DropDown/Dropdown";
import { FilterCodelists } from "../filter-components/FilterCodelists";
import PropTypes from "prop-types";

export class FilterToFromYear extends Component {
  static propTypes = {
    firstYear: PropTypes.number.isRequired,
    setFilter: PropTypes.func.isRequired,
    filter: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      filter: props.filter,
    };
  }

  update(updatedFilter) {
    const { filter, setFilter } = this.props;
    updatedFilter = Object.assign(filter, updatedFilter);
    if (updatedFilter.fromYear && !updatedFilter.toYear) {
      updatedFilter.toYear = updatedFilter.fromYear;
    } else if (!updatedFilter.fromYear && updatedFilter.toYear) {
      updatedFilter.toYear = undefined;
    }
    // console.log(updatedFilter);
    setFilter(updatedFilter);
  }

  render() {
    const { filter, firstYear } = this.props;

    return (
      <div key="B45">
        <Dropdown
          key="fromYear"
          label="Fra år"
          name="fromYear"
          items={FilterCodelists.Years(firstYear)}
          placeholder="Fra år"
          onSelect={(sel) => this.update({ fromYear: sel.value })}
          onClear={() => this.update({ fromYear: undefined })}
          value={filter.fromYear}
          searchable={false}
          isOptionDisabled={(obj) => {
            if (this.props.filter.toYear) {
              return obj.value > this.props.filter.toYear;
            }
            return false;
          }}
        />
        <Dropdown
          key="toYear"
          label="Til år"
          name="toYear"
          placeholder="Til år"
          items={FilterCodelists.Years(firstYear)}
          onSelect={(sel) => this.update({ toYear: sel.value })}
          onClear={() => this.update({ toYear: filter.fromYear })}
          searchable={false}
          value={filter.toYear}
          isOptionDisabled={(obj) => {
            if (this.props.filter.fromYear) {
              return obj.value < this.props.filter.fromYear;
            }
            return false;
          }}
        />
      </div>
    );
  }
}
