import React, { Component } from 'react';

import Dropdown from '../filter-components/DropDown/Dropdown';
import { FilterCodelists } from '../filter-components/FilterCodelists';
import PropTypes from 'prop-types';
import { TimeUtils } from './../chart-components/ChartUtils/TimeUtils';

export class FilterToFromMonth extends Component {
  static propTypes = {
    firstYear: PropTypes.number.isRequired,
    setFilter: PropTypes.func.isRequired,
    filter: PropTypes.object
  };

  constructor (props) {
    super(props);
    this.state = {
      filter: props.filter
    };
  }

  update (key, val) {
    const { filter, setFilter } = this.props;

    let fromTime, toTime;

    // const dateOptions = { timeZone: 'UTC' };

    filter[key] = val;

    if (filter.fromYear && !filter.toYear) {
      filter.toYear = filter.fromYear;
    } else if (!filter.fromYear && filter.toYear) {
      filter.toYear = undefined;
    }

    if (filter.fromMonth && !filter.toMonth) {
      filter.toMonth = filter.fromMonth;
    } else if (!filter.fromMonth && filter.toMonth) {
      filter.toMonth = undefined;
    }

    if (filter.fromYear && filter.fromMonth) {
      fromTime = TimeUtils.utcDate2(filter.fromYear, filter.fromMonth, 1).toISOString();
    }

    if (filter.toYear && filter.toMonth) {
      toTime = TimeUtils.utcDate2(filter.toYear, filter.toMonth + 1, 1).toISOString();
    }

    setFilter({
      'fromYear': filter.fromYear,
      'toYear': filter.toYear,
      'fromMonth': filter.fromMonth,
      'toMonth': filter.toMonth,
      fromTime,
      toTime
    });
  }

  render () {
    const { filter, firstYear } = this.props;

    return (
      <div key='B45'>
        <Dropdown
          key='A1'
          label='Fra år'
          name='fromYear'
          items={FilterCodelists.Years(firstYear)}
          placeholder='Fra år'
          onSelect={di => this.update('fromYear', di.value)}
          onClear={() => this.update('fromYear', undefined)}
          value={filter.fromYear}
          searchable={false}
          isOptionDisabled={item => {
            if (filter.toYear) {
              return item.value > filter.toYear;
            }
            return false;
          }}
        />
        <Dropdown
          key='2'
          label='Fra måned'
          name='fromMonth'
          placeholder='Fra måned'
          items={FilterCodelists.MonthsInYear()}
          onSelect={di => this.update('fromMonth', di.value)}
          onClear={() => this.update('fromMonth', undefined)}
          value={filter.fromMonth}
          searchable={false}
          isOptionDisabled={item => {
            return (
              item.value > filter.toMonth &&
              filter.toYear === filter.fromYear
            );
          }}
        />
        <Dropdown
          key='A4'
          label='Til år'
          name='toYear'
          placeholder='Til år'
          items={FilterCodelists.Years(firstYear)}
          onSelect={di => this.update('toYear', di.value)}
          onClear={() => this.update('toYear', filter.fromYear)}
          searchable={false}
          value={filter.toYear}
          isOptionDisabled={item => {
            if (filter.fromYear) {
              return item.value < filter.fromYear;
            }
            return false;
          }}
        />
        <Dropdown
          key='5'
          label='Til måned'
          placeholder='Velg måned'
          items={FilterCodelists.MonthsInYear()}
          onSelect={di => this.update('toMonth', di.value)}
          onClear={() => this.update('toMonth', undefined)}
          name='toMonth'
          searchable={false}
          value={filter.toMonth}
          isOptionDisabled={item => {
            return (
              item.value < filter.fromMonth &&
              filter.toYear === filter.fromYear
            );
          }}
        />
      </div>
    );
  }
}
