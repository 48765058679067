import React from 'react';
import { GeoJSONFeature } from "ol/format/GeoJSON";
import { PropsWithChildren, useCallback, useState } from "react";
import { Button } from "react-bootstrap";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../../types/DashboardFilter";
import { MapInteractionType } from "../FilterList/FilterList";
import { AddMapInteraction } from "./AddMapInteraction";
import { ClearDrawingLayer } from "./ClearDrawingLayer";

export type DrawBoxInMapProps = PropsWithChildren & {
  setFilter: SetDashboardFilterFunc;
  filter: DashboardFilter;
  name?: string;
  sticky?: boolean;
  children?: any;
};

export function DrawBoxInMap({
  filter,
  setFilter,
  name = "geom",
  sticky = false,
  children = "Tegn rektangel i kart",
}: DrawBoxInMapProps & PropsWithChildren) {
  const [interaction, setInteraction] = useState<MapInteractionType>();

  const handleChange = useCallback(
    (gjFeatures: GeoJSONFeature[]) => {
      setFilter({
        [name]: gjFeatures,
      });
      // setInteraction(undefined);
    },
    [name, setFilter]
  );

  return (
    <div className="d-grid mb-2">
      <Button
        variant="info"
        className="text-white rounded-0"
        active={interaction === "drawBox"}
        onClick={() => setInteraction("drawBox")}
      >
        {children}
      </Button>
      {interaction && (
        <>
          <ClearDrawingLayer />
          <AddMapInteraction
            mapInteraction={interaction}
            onChange={handleChange}
          />
        </>
      )}
    </div>
  );
}
