export const MarginsMixin = function (chart, props) {
  const defaultMargins = {
    top: 10,
    right: 10,
    bottom: 30,
    left: 30
  };

  const { margins = [defaultMargins.top, defaultMargins.right, defaultMargins.bottom, defaultMargins.left] } = props;

  
  if (Array.isArray(margins)) {
    var marginObj = {
      top: margins[0] || defaultMargins.top,
      right: margins[1] || defaultMargins.right,
      bottom: margins[2] || defaultMargins.bottom,
      left: margins[3] || defaultMargins.left
    };
    chart.margins(marginObj);
  } else if (typeof margins === 'object') {
    var marginObj2 = Object.assign({}, defaultMargins);
    var marginObj3 = Object.assign(marginObj2, margins);
    
    chart.margins(marginObj3);
  }

  return chart;
};
